import React, { useCallback, useState } from 'react';
import { bool, func, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { i18PAGES } from '../../../../config/i18n/constants';
import WarningModal from '../../../../style/WarningModal/WarningModal';

const BbCardSendRequestButton = (props) => {
    const { isActive, className, onSendProviderRequest, isEditMode, setIsEditMode } = props;

    const { t } = useTranslation(i18PAGES.OPTIONS);

    const [isShowCloseEditAlertModal, setIsShowCloseEditAlertModal] = useState(false);

    const onShowEditAlertModal = useCallback(() => {
        setIsShowCloseEditAlertModal(true);
    }, []);

    const onCloseEditAlertModal = useCallback(() => {
        setIsShowCloseEditAlertModal(false);
    }, []);

    const onOffEditMode = useCallback(() => {
        setIsEditMode(false);
        setIsShowCloseEditAlertModal(false);
    }, [setIsEditMode]);

    if (isEditMode) {
        return (
            <>
                <button
                    type="button"
                    className={`h-[50px] pr-[32px] rounded-[8px] pl-[28px] bg-int-gray-secondary font-Lexend400 text-[18px] leading-[18px] ${className}`}
                    onClick={onShowEditAlertModal}
                >
                    {t('Close editing')}
                </button>
                <WarningModal
                    isOpen={isShowCloseEditAlertModal}
                    setIsOpen={setIsShowCloseEditAlertModal}
                    warningText={i18n.t('Are you sure you want to close editing mode? Your result will not be saved')}
                    acceptButtonText={i18n.t('Close')}
                    declineButtonText={i18n.t('Cancel')}
                    accept={onOffEditMode}
                    decline={onCloseEditAlertModal}
                />
            </>
        );
    }

    return (
        <button
            type="button"
            className={`${
                isActive ? 'bg-int-grapefruit' : 'opacity-[0.6] bg-int-gray-secondary'
            } h-[50px] pr-[32px] rounded-[8px] pl-[28px] font-Lexend400 text-[18px] leading-[18px] ${className}`}
            disabled={!isActive}
            onClick={onSendProviderRequest}
        >
            {t('Send provider request')}
        </button>
    );
};

export default BbCardSendRequestButton;

BbCardSendRequestButton.propTypes = {
    isActive: bool,
    className: string,
    onSendProviderRequest: func,
    isEditMode: bool.isRequired,
    setIsEditMode: func.isRequired,
};
