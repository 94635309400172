import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { ReactComponent as UserManagementEditIcon } from '../../assets/icons/userManagementEditIcon.svg';
import { ReactComponent as UserManagementDeleteIcon } from '../../assets/icons/userManagementDeleteIcon.svg';
import { ReactComponent as UserManagementIcon } from '../../assets/icons/userManagementIcon.svg';
import { ReactComponent as ExternalUserApprovedIcon } from '../../assets/icons/externalUserApprovedIcon.svg';
import { ReactComponent as ExternalUserInProgressIcon } from '../../assets/icons/externalUserInProgressIcon.svg';
import Pagination from '../../style/Pagination/Pagination';
import EditUserModalForm from './EditUserModalForm';
import { useUserManagementListQuery } from '../../store/API/userManagementAPI';
import { PAGINATION_PAGE_SIZE } from '../util/constants';
import Preloader from '../Preloader/Preloader';
import DeleteUser from './DeleteUser';
import { i18PAGES } from '../../config/i18n/constants';
import StringShorterTooltip from '../../style/StringShorterTooltip/StringShorterTooltip';
import { useAuth } from '../../store/hooks/useAuth';
import { useSort } from '../../hooks/useSort';
import TableHeaderSortItem from '../../style/Table/TableHeaderSortItem';

const UserManagementMainBlock = () => {
    const { t } = useTranslation(i18PAGES.USER_MANAGEMENT);
    const [searchParams, setSearchParams] = useSearchParams();

    const { orderFromUrl, pageFromUrl, sortByFromUrl } = useSort();

    const { profileData } = useAuth();
    const [activePage, setActivePage] = useState(Number(pageFromUrl) || 1);
    const [isOpenEditUserModal, setIsOpenEditUserModal] = useState(false);
    const [isOpenDeleteUserModal, setIsOpenDeleteUserModal] = useState(false);
    const [editUserModalData, setEditUserModalData] = useState(null);
    const [deleteUserModalData, setDeleteUserModalData] = useState(null);

    useEffect(() => {
        searchParams.set('page', String(activePage));
        setSearchParams(searchParams);
    }, [activePage, searchParams, setSearchParams]);

    const { data: userList, isLoading } = useUserManagementListQuery(
        { page: activePage - 1, sortBy: sortByFromUrl, order: orderFromUrl },
        {
            refetchOnMountOrArgChange: true,
            skip: !activePage,
        }
    );

    const handleClickEditUser = (data) => {
        setIsOpenEditUserModal(true);
        setEditUserModalData(data);
    };

    const handleClickDeleteUser = (data) => {
        setIsOpenDeleteUserModal(true);
        setDeleteUserModalData(data);
    };

    return (
        <>
            <div className="w-[1020px] flex flex-col items-center p-[40px] rounded-lg bg-int-gray-main">
                <div className="flex w-[100%] py-[8px] bg-[#2A3143]">
                    <TableHeaderSortItem className="min-w-[210px]" urlName="fullName">
                        {t('Full name')}
                    </TableHeaderSortItem>
                    <TableHeaderSortItem className="min-w-[230px]" urlName="email">
                        {t('Email')}
                    </TableHeaderSortItem>
                    <TableHeaderSortItem
                        className="max-w-[180px] min-w-[200px] text-center"
                        textStyles="ml-[45px]"
                        urlName="activated"
                    >
                        {t('Status')}
                    </TableHeaderSortItem>
                    <TableHeaderSortItem className="min-w-[190px]" urlName="isAdmin">
                        {t('Admin rights')}
                    </TableHeaderSortItem>

                    <div className="max-w-[150px] w-[100%] pr-[12px] text-right">{t('Actions')}</div>
                </div>

                {isLoading ? (
                    <div className="mt-[40px]">
                        <Preloader />
                    </div>
                ) : (
                    <>
                        {userList?.content?.length > 0 &&
                            userList?.content?.map((item) => (
                                <div
                                    className="flex w-[100%] py-[18px] text-[14px] leading-[18px] font-Lexend500 text-int-white-main border-b-[1px] border-int-gray-secondary"
                                    key={item.id}
                                >
                                    <div className="max-w-[220px] w-[100%] pl-[12px]">
                                        <StringShorterTooltip string={item.fullName} maxLength={22} />
                                    </div>
                                    <div className="max-w-[310px] w-[100%] pl-[12px]">
                                        <StringShorterTooltip string={item.email} maxLength={26} />
                                    </div>
                                    <div className="max-w-[160px] w-[100%] text-center text-[12px] font-Lexend400">
                                        {item.isActivated ? (
                                            <div className="flex items-center justify-center">
                                                <div className="mr-[7px]">{t('Approved')}</div>
                                                <ExternalUserApprovedIcon fill="#47E273" width={13} height={12} />
                                            </div>
                                        ) : (
                                            <div className="flex items-center justify-center">
                                                <div className="mr-[7px]">{t('In progress')}</div>
                                                <ExternalUserInProgressIcon fill="#5679F7" width={13} height={12} />
                                            </div>
                                        )}
                                    </div>
                                    <div className="max-w-[150px] w-[100%] text-center">
                                        <div className="bg-int-gray-secondary rounded inline py-[2px] px-[6px]">
                                            {item.isAdmin ? t('Allowed') : '-'}
                                        </div>
                                    </div>
                                    <div className="flex justify-end items-center max-w-[150px] w-[100%] pr-[12px]">
                                        <div
                                            className="flex justify-center items-center w-[16px] h-[16px] mr-[16px] cursor-pointer"
                                            onClick={() => handleClickEditUser(item)}
                                        >
                                            <UserManagementEditIcon />
                                        </div>
                                        <div
                                            className={`flex justify-center items-center w-[16px] h-[16px] ${
                                                profileData?.id !== item.id && 'cursor-pointer'
                                            } `}
                                            onClick={() => handleClickDeleteUser(item)}
                                        >
                                            {profileData?.id !== item.id && <UserManagementDeleteIcon />}
                                        </div>
                                    </div>
                                </div>
                            ))}
                    </>
                )}

                {userList?.content?.length === 0 && (
                    <>
                        <div className="mt-[30px] mb-[12px]">
                            <UserManagementIcon />
                        </div>
                        <div className="text-[14px] leading-[16px] font-Lexend300 text-int-white-secondary">
                            {t('There are no users in the list. Please add new users.')}
                        </div>
                    </>
                )}
            </div>
            <div className="flex justify-center mt-[30px] mb-[90px]">
                <Pagination
                    count={userList?.totalElements}
                    pageSize={PAGINATION_PAGE_SIZE}
                    activePage={activePage}
                    setActivePage={setActivePage}
                />
            </div>

            <EditUserModalForm
                setIsOpenEditUserModal={setIsOpenEditUserModal}
                isOpenEditUserModal={isOpenEditUserModal}
                id={editUserModalData?.id}
                fullName={editUserModalData?.fullName}
                email={editUserModalData?.email}
                isAdmin={editUserModalData?.isAdmin}
            />

            <DeleteUser
                isOpenDeleteUserModal={isOpenDeleteUserModal}
                setIsOpenDeleteUserModal={setIsOpenDeleteUserModal}
                id={deleteUserModalData?.id}
                fullName={deleteUserModalData?.fullName}
            />
        </>
    );
};

export default UserManagementMainBlock;
