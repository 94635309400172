import React from 'react';
import { FieldArray, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import { bool, func, object, number, arrayOf, string } from 'prop-types';
import Step3OfficeItem from './Step3OfficeItem';
import { i18PAGES } from '../../../config/i18n/constants';
import Preloader from '../../Preloader/Preloader';
import CompaniesListViewer from '../../ProjectsList/CompaniesListViewer/CompaniesListViewer';

const Step3FormProject = ({
    handleSubmit,
    onBackButtonPress,
    isSubmitting,
    handleTabChoose,
    currentTab,
    values,
    setFieldValue,
    isLoading,
    isDisabledAllInForm = false,
    allProvidersSubmitted = false,
    sentBuildingBlocks,
    projectStatus,
}) => {
    const { t } = useTranslation(i18PAGES.PROJECTS_SETUP);
    return (
        <Form onSubmit={handleSubmit}>
            <div className="flex justify-between items-center">
                <div className="font-Lexend500 text-[24px] leading-[24px] text-int-white-main">{t('Add Offices')}</div>
                <div>
                    <button
                        className="text-int-white-main border border-int-gray-secondary rounded-[8px] w-[140px] h-[42px] font-Lexend400"
                        type="button"
                        onClick={onBackButtonPress}
                    >
                        {t('Go Back')}
                    </button>
                    <button
                        className={`text-int-white-main border border-int-grapefruit bg-int-grapefruit ml-[20px] rounded-[8px] w-[140px] h-[42px] font-Lexend400 ${
                            isSubmitting && 'opacity-[0.5]'
                        }`}
                        type="submit"
                        disabled={isSubmitting}
                    >
                        {t('Finish')}
                    </button>
                </div>
            </div>
            {isLoading ? (
                <div className="mt-[40px] flex justify-center">
                    <Preloader />
                </div>
            ) : (
                <>
                    <div className="flex font-Lexend400 text-[16px] leading-[18px] mt-[22px] mb-[30px]">
                        <CompaniesListViewer
                            chooseCompany={handleTabChoose}
                            companiesList={values?.companies}
                            chosenCompanyId={currentTab}
                            isTabIndex
                        />
                    </div>
                    <div className="text-[20px] leading-[25px] font-Lexend500 text-int-white-secondary">
                        {t('Select Offices')}
                    </div>
                    <FieldArray
                        name="companies"
                        render={() => (
                            <div className="mr-[-20px] pr-[20px]" id="primary-scroll">
                                {values?.companies?.map((company, companiesIndex) => (
                                    <div
                                        key={companiesIndex}
                                        className={` ${companiesIndex === currentTab ? 'block' : `hidden`}`}
                                    >
                                        {company.countries?.map((country, countryIndex) => (
                                            <div key={countryIndex}>
                                                <div className="mt-[30px] mb-[12px] text-[18px] text-int-white-main">
                                                    {country.name}
                                                </div>
                                                <div className="flex w-[100%] py-[8px] bg-[#2A3143] text-[12px] leading-[18px] font-Lexend500 text-int-white-secondary">
                                                    <div className="max-w-[230px] w-[100%] pl-[30px]">
                                                        {t('Office name')}
                                                    </div>
                                                    <div className="max-w-[170px] w-[100%] pl-[12px]">{t('City')}</div>
                                                    <div className="w-[100%] pl-[12px]">
                                                        <div className="ml-[160px]">{t('Building blocks')}</div>
                                                    </div>
                                                </div>

                                                {country.offices?.map((office, officesIndex) => (
                                                    <div key={officesIndex}>
                                                        <Step3OfficeItem
                                                            officesIndex={officesIndex}
                                                            companiesIndex={companiesIndex}
                                                            office={office}
                                                            countryIndex={countryIndex}
                                                            step3values={values}
                                                            setFieldValue={setFieldValue}
                                                            isDisabledAllInForm={isDisabledAllInForm}
                                                            allProvidersSubmitted={allProvidersSubmitted}
                                                            sentBuildingBlocks={sentBuildingBlocks}
                                                            projectStatus={projectStatus}
                                                        />
                                                    </div>
                                                ))}
                                            </div>
                                        ))}
                                    </div>
                                ))}
                            </div>
                        )}
                    />
                </>
            )}
        </Form>
    );
};

export default Step3FormProject;

Step3FormProject.propTypes = {
    handleSubmit: func,
    isSubmitting: bool,
    onBackButtonPress: func,
    values: object,
    isLoading: bool,
    setFieldValue: func,
    handleTabChoose: func,
    currentTab: number,
    isDisabledAllInForm: bool,
    allProvidersSubmitted: bool,
    sentBuildingBlocks: arrayOf(number),
    projectStatus: string,
};
