import { configureStore } from '@reduxjs/toolkit';
import companySetupReducer from './reducers/companySetupReducer';
import createProjectReducer from './reducers/createProjectReducer';
import authSlice from './reducers/authReducer';
import isShowFullMenuSlice from './reducers/menuShowReducer';
import isPlatformEditModeSlice from './reducers/platformEditModeReducer';
import tabsMenuReducer from './reducers/tabsMenuReducer';
import { authAPI } from './API/authAPI';
import { userManagementAPI } from './API/userManagementAPI';
import { rtkQueryErrorLogger } from '../components/util/rtkQueryErrorLogger';
import { createProjectAPI } from './API/createProjectAPI';
import { companiesAPI } from './API/companiesAPI';
import { commentsAPI } from './API/commentsAPI';
import { profileAPI } from './API/profileAPI';
import { userModulesAPI } from './API/userModulesAPI';
import { projectInternalManagementAPI } from './API/projectInternalManagementAPI';
import { projectExternalManagementAPI } from './API/projectExternalManagementAPI';
import { modulesAPI } from './API/modulesAPI';
import { userPageAPI } from './API/userPageAPI';
import { modulesStrategiesAPI } from './API/modulesStrategiesAPI';
import { projectInfoAPI } from './API/projectsInfoAPI';
import { workersInformationAPI } from './API/workersInformationAPI';
import { fteProcessAPI } from './API/fteProcessAPI';
import { projectRolesAPI } from './API/projectRolesApi';
import { toBeApplicationAPI } from './API/toBeApplicationAPI';
import { providerSelectionAPI } from './API/providerSelectionAPI';
import { asIsApplicationsAPI } from './API/asIsApplicationsAPI';
import { asIsIntegrationsAPI } from './API/asIsIntegrationsAPI';
import { toBeIntegrationsAPI } from './API/toBeIntegrationsAPI';
import { subscriptionsAPI } from './API/subscriptionsAPI';
import { invoiceAPI } from './API/invoiceAPI';
import { optionsAPI } from './API/optionsAPI';
import { hrAPI } from './API/hrAPI';
import { surveysAPI } from './API/surveysAPI';

export default configureStore({
    reducer: {
        companySetup: companySetupReducer,
        [companiesAPI.reducerPath]: companiesAPI.reducer,
        [createProjectAPI.reducerPath]: createProjectAPI.reducer,
        createProject: createProjectReducer,
        auth: authSlice,
        tabsMenu: tabsMenuReducer,
        isShowFullMenu: isShowFullMenuSlice,
        isPlatformEditMode: isPlatformEditModeSlice,
        [authAPI.reducerPath]: authAPI.reducer,
        [userManagementAPI.reducerPath]: userManagementAPI.reducer,
        [commentsAPI.reducerPath]: commentsAPI.reducer,
        [profileAPI.reducerPath]: profileAPI.reducer,
        [userModulesAPI.reducerPath]: userModulesAPI.reducer,
        [projectInternalManagementAPI.reducerPath]: projectInternalManagementAPI.reducer,
        [projectExternalManagementAPI.reducerPath]: projectExternalManagementAPI.reducer,
        [modulesAPI.reducerPath]: modulesAPI.reducer,
        [userPageAPI.reducerPath]: userPageAPI.reducer,
        [modulesStrategiesAPI.reducerPath]: modulesStrategiesAPI.reducer,
        [projectInfoAPI.reducerPath]: projectInfoAPI.reducer,
        [workersInformationAPI.reducerPath]: workersInformationAPI.reducer,
        [fteProcessAPI.reducerPath]: fteProcessAPI.reducer,
        [projectRolesAPI.reducerPath]: projectRolesAPI.reducer,
        [toBeApplicationAPI.reducerPath]: toBeApplicationAPI.reducer,
        [providerSelectionAPI.reducerPath]: providerSelectionAPI.reducer,
        [asIsApplicationsAPI.reducerPath]: asIsApplicationsAPI.reducer,
        [asIsIntegrationsAPI.reducerPath]: asIsIntegrationsAPI.reducer,
        [toBeIntegrationsAPI.reducerPath]: toBeIntegrationsAPI.reducer,
        [subscriptionsAPI.reducerPath]: subscriptionsAPI.reducer,
        [invoiceAPI.reducerPath]: invoiceAPI.reducer,
        [optionsAPI.reducerPath]: optionsAPI.reducer,
        [hrAPI.reducerPath]: hrAPI.reducer,
        [surveysAPI.reducerPath]: surveysAPI.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(
            rtkQueryErrorLogger,
            companiesAPI.middleware,
            authAPI.middleware,
            userManagementAPI.middleware,
            createProjectAPI.middleware,
            commentsAPI.middleware,
            profileAPI.middleware,
            userModulesAPI.middleware,
            projectInternalManagementAPI.middleware,
            projectExternalManagementAPI.middleware,
            modulesAPI.middleware,
            userPageAPI.middleware,
            modulesStrategiesAPI.middleware,
            projectInfoAPI.middleware,
            workersInformationAPI.middleware,
            fteProcessAPI.middleware,
            projectRolesAPI.middleware,
            toBeApplicationAPI.middleware,
            providerSelectionAPI.middleware,
            providerSelectionAPI.middleware,
            asIsApplicationsAPI.middleware,
            asIsIntegrationsAPI.middleware,
            toBeIntegrationsAPI.middleware,
            subscriptionsAPI.middleware,
            invoiceAPI.middleware,
            optionsAPI.middleware,
            hrAPI.middleware,
            surveysAPI.middleware
        ),
});
