import { array, number, object, string } from 'yup';
import { REGEX } from '../../util/constants';

export const getTableHeaderData = (t) => [
    {
        minWidth: 300,
        name: t('MODUL'),
        isSortable: true,
        queryName: 'module',
    },
    {
        minWidth: 300,
        name: t('BAUSTEIN'),
    },
    {
        minWidth: 250,
        name: t('APPLICATION NAME'),
        isSortable: true,
        queryName: 'applicationName',
    },
    {
        minWidth: 200,
        name: t('Application language'),
    },
    {
        minWidth: 200,
        name: t('BEDARFSKATEGORIE'),
    },
    {
        minWidth: 200,
        name: t('ZUFFRIEDENHEIT MIT SYSTEM'),
    },
    {
        minWidth: 200,
        name: t('Reason to change'),
    },

    {
        minWidth: 200,
        name: t('Lizenzkosten pro Jahr'),
    },
    {
        minWidth: 200,
        name: t('Betriebskosten intern pro Jahr'),
    },
    {
        minWidth: 200,
        name: t('Supportkosten Extern pro Jahr'),
    },
    {
        minWidth: 200,
        name: t('Kosten Schnittstellen pro Jahr intern'),
    },
    {
        minWidth: 200,
        name: t('Schulungskosten pro Jahr'),
    },
];
export const getEmptyFormDataItem = () => ({
    module: {
        id: 0,
        name: '',
    },
    buildingBlock: {
        name: '',
        id: 0,
    },
    applicationName: '',
    applicationLanguages: [],
    status: '',
    systemSatisfactions: '',
    reasonToChange: '',
    licenseCostsPerYear: '',
    operatingCostsInternalPerYear: '',
    supportCostsExternalPerYear: '',
    interfaceCostsPerYear: '',
    trainingCostsPerYear: '',
    key: 0,
});

export const stringStatusToEnum = (status) => {
    if (status === 'Behalten') {
        return 'KEEP';
    }
    if (status === 'Erstzen') {
        return 'REPLACE';
    }
    if (status === 'Nicht relevant') {
        return 'NOT_RELEVANT';
    }

    return status;
};

export const AsIsApplicationValidationSchema = object().shape({
    applicationsInfo: array().of(
        object().shape({
            module: object().shape({
                id: number(),
                name: string().required('Module should be selected'),
            }),
            buildingBlock: object().shape({
                id: number(),
                name: string().required('Building Block should be selected'),
            }),
            applicationName: string(),
            applicationLanguages: array().min(1, 'At least one language should be selected'),
            status: string().required('Status should be selected'),
            systemSatisfactions: string().required('Value should be selected'),
            reasonToChange: string().max(30, 'Max length is 30 symbols'),
            licenseCostsPerYear: string()
                .required('Number should be stated')
                .matches(REGEX.DECIMAL, 'Invalid value')
                .test('Cant be zero only', 'Invalid value', (value) => value !== '0'),
            operatingCostsInternalPerYear: string()
                .required('Number should be stated')
                .matches(REGEX.DECIMAL, 'Invalid value')
                .test('Cant be zero only', 'Invalid value', (value) => value !== '0'),
            supportCostsExternalPerYear: string()
                .required('Number should be stated')
                .matches(REGEX.DECIMAL, 'Invalid value')
                .test('Cant be zero only', 'Invalid value', (value) => value !== '0'),
            interfaceCostsPerYear: string()
                .required('Number should be stated')
                .matches(REGEX.DECIMAL, 'Invalid value')
                .test('Cant be zero only', 'Invalid value', (value) => value !== '0'),
            trainingCostsPerYear: string()
                .required('Number should be stated')
                .matches(REGEX.DECIMAL, 'Invalid value')
                .test('Cant be zero only', 'Invalid value', (value) => value !== '0'),
        })
    ),
});
