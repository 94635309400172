import React from 'react';
import { useTranslation } from 'react-i18next';
import { bool, func } from 'prop-types';
import { i18PAGES } from '../../../../config/i18n/constants';
import { ReactComponent as UpChangeIcon } from '../../../../assets/icons/UpChangeIcon.svg';
import { useAuth } from '../../../../store/hooks/useAuth';

const OptionsDescriptionAnButtonsBlock = (props) => {
    const { t } = useTranslation(i18PAGES.OPTIONS);
    const { onOpenGeneratingVariations, isGenerateButtonDisabled } = props;
    const { isEditablePagesProject } = useAuth();

    return (
        <div className="flex justify-between mt-[40px] w-[100%] mb-[40px] ">
            <div className="flex font-Lexend400 text-[14px] text-int-white-main">
                <div className="w-[600px] min-h-[50px] flex items-center px-[16px] bg-[#29323F80] rounded-[8px]">
                    {t('Explanation on how to fill in information on this tab')}
                </div>
            </div>

            {isEditablePagesProject && (
                <button
                    type="button"
                    className={`${
                        isGenerateButtonDisabled && 'opacity-[0.6]'
                    } flex items-center px-[26px] h-[50px] bg-[#354153] rounded-[8px] cursor-pointer`}
                    onClick={onOpenGeneratingVariations}
                    disabled={isGenerateButtonDisabled}
                >
                    <div className="mr-[8px]">{t('Generate variation of options')}</div>
                    <UpChangeIcon width={10} height={10} fill="#EDF1FD" />
                </button>
            )}
        </div>
    );
};

export default OptionsDescriptionAnButtonsBlock;

OptionsDescriptionAnButtonsBlock.propTypes = {
    onOpenGeneratingVariations: func.isRequired,
    isGenerateButtonDisabled: bool,
};
