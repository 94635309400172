export const getNextProjectPageLink = (tabsMenuData, currentPage, projectId) => {
    const currentPageIndex = Object.keys(tabsMenuData).findIndex((pageName) => pageName === currentPage);
    if (Object.keys(tabsMenuData).length > currentPageIndex + 1) {
        const nextPageInfo = tabsMenuData[Object.keys(tabsMenuData)[currentPageIndex + 1]];
        const nextPageFirstItem = nextPageInfo[0];

        if (nextPageInfo?.length === 0) {
            return `/${Object.keys(tabsMenuData)[currentPageIndex + 1].toLowerCase()}?projectId=${projectId}`;
        }

        if (nextPageFirstItem?.enumName) {
            return `/${Object.keys(tabsMenuData)[
                currentPageIndex + 1
            ].toLowerCase()}?projectId=${projectId}&current=${nextPageFirstItem?.enumName.toLowerCase()}`;
        }

        if (nextPageFirstItem?.officeId) {
            return `/${Object.keys(tabsMenuData)[currentPageIndex + 1].toLowerCase()}?companyId=${
                nextPageFirstItem.companyId
            }&countryId=${nextPageFirstItem.countryId}&cityId=${nextPageFirstItem.cityId}&officeId=${
                nextPageFirstItem.officeId
            }&projectId=${projectId}`;
        }
    }

    return false;
};
