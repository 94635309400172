import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { arrayOf, number, shape, string } from 'prop-types';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { i18PAGES } from '../../../../config/i18n/constants';
import { useAuth } from '../../../../store/hooks/useAuth';
import { projectManagersUserRoles, USER_ROLES } from '../../../util/constants';
import SendModuleExpirationMessage from './SendModuleExpirationMessage';
import { SEND_ASK_MESSAGES_STATUSES } from '../constants';

const ExpirationModulesPopUp = (props) => {
    const { t } = useTranslation(i18PAGES.OPTIONS);
    const { expiredModulesList } = props;
    const { profileData, projectRole } = useAuth();
    const navigate = useNavigate();
    const [isSendModalOpened, setIsSendModalOpened] = useState(false);
    const [modalAskStatus, setModalAskStatus] = useState(undefined);

    const [searchParams] = useSearchParams();
    const projectId = searchParams.get('projectId');

    const isAdmin = useMemo(() => profileData?.userRoles?.includes(USER_ROLES.ROLE_ADMIN), [profileData?.userRoles]);
    const isManager = useMemo(() => projectRole === projectManagersUserRoles.ROLE_MANAGER, [projectRole]);

    const onRenewClick = useCallback(() => {
        if (isAdmin) {
            navigate('/subscription');
        } else {
            setModalAskStatus(SEND_ASK_MESSAGES_STATUSES.RENEW);
            setIsSendModalOpened(true);
        }
    }, [isAdmin, navigate]);

    const onDeleteClick = useCallback(() => {
        if (isAdmin || isManager) {
            navigate(`/edit-project?projectId=${projectId}&isModulesEditing=true`);
        } else {
            setModalAskStatus(SEND_ASK_MESSAGES_STATUSES.DELETE);
            setIsSendModalOpened(true);
        }
    }, [isAdmin, isManager, navigate, projectId]);

    return (
        <>
            <div className="absolute h-[36px] bg-[#5679F6] flex items-center justify-center left-0 top-header-height w-[100%] text-int-white-main text-[14px] leading-[16px] font-Lexend400">
                <div>
                    <span>{t('Your subscription for')}</span>
                    <span>
                        {expiredModulesList?.map((m, index) => (
                            <span key={index}>
                                {' '}
                                {m.name}
                                {index !== expiredModulesList.length - 1 ? ',' : ' '}
                            </span>
                        ))}
                    </span>
                    <span>{t('has expired. Renew it to save the progress or delete it from the scope')}</span>
                </div>
                <div className="ml-[24px] flex items-center">
                    <div className="border-b border-int-white-main cursor-pointer" onClick={onRenewClick}>
                        {t('Renew')}
                    </div>
                    <div className="mx-[12px]">{t('or')}</div>
                    <div className="border-b border-int-white-main cursor-pointer" onClick={onDeleteClick}>
                        {t('Delete')}
                    </div>
                </div>
            </div>
            <SendModuleExpirationMessage
                isOpen={isSendModalOpened}
                setIsOpen={setIsSendModalOpened}
                modalAskStatus={modalAskStatus}
                expiredModulesList={expiredModulesList}
                projectId={projectId}
            />
        </>
    );
};

export default ExpirationModulesPopUp;

ExpirationModulesPopUp.propTypes = {
    expiredModulesList: arrayOf(
        shape({
            id: number,
            name: string,
        })
    ),
};
