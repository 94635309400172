import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from '../style/Button/Button';
import { ReactComponent as ModalIcon } from '../assets/icons/companyCreatedIcon.svg';
import { ReactComponent as ModalWarningIcon } from '../assets/icons/modalWarningIcon.svg';
import CompaniesListToastModal from '../components/CompaniesList/CompaniesListToastModal';
import CountriesList from '../components/CompaniesList/CountriesList';
import BoughtModulesList from '../components/CompaniesList/BoughtModulesList';
import { useGetCompaniesQuery } from '../store/API/companiesAPI';
import { LayoutLoader } from '../components/Preloader/Preloader';
import EmptyCompanyShows from '../components/CompaniesList/EmptyCompanyShows';
import ChooseList from '../components/ProjectsList/ChooseList';
import { i18PAGES } from '../config/i18n/constants';
import { useGetBoughtUserModulesListQuery } from '../store/API/userModulesAPI';
import { useCompanySetup } from '../store/hooks/useCompanySetup';
import { useToast } from '../hoc/toast/ToastProvider';
import NotFoundPage from './NotFoundPage';

const CompanyList = () => {
    const { t } = useTranslation(i18PAGES.COMPANIES_LIST);
    const { successToast } = useToast();
    const { clearCompanySetupState } = useCompanySetup();

    const navigate = useNavigate();
    const isNewCompanyCreated = localStorage.getItem('isNewCompanyCreated');

    const [isNeedBuyModuleOpened, setIsNeedBuyModuleOpened] = useState(false);
    const [sortParams, setSortParams] = useState('');

    const { data, isLoading, isFetching } = useGetCompaniesQuery({ sortParams }, { refetchOnMountOrArgChange: true });
    const { data: boughtUserModulesList, isModulesLoading } = useGetBoughtUserModulesListQuery(
        {},
        { refetchOnMountOrArgChange: true }
    );

    const [searchParams, setSearchParams] = useSearchParams();
    const selectedCompanyId = searchParams.get('companyId');

    const [chosenCompanyId, setChosenCompanyId] = useState(Number(selectedCompanyId) || undefined);

    const companyList = useMemo(() => data?.map((company) => ({ id: company?.id, name: company?.name })), [data]);

    const currentCompany = useMemo(() => {
        if (data && chosenCompanyId) {
            return data?.filter((company) => company.id === chosenCompanyId)[0];
        }
    }, [data, chosenCompanyId]);

    const selectCountry = useCallback((id) => {
        setChosenCompanyId(id);
    }, []);

    const changeUrl = useCallback(
        (id) => {
            setSearchParams({ companyId: String(id) });
        },
        [setSearchParams]
    );

    const linkToCreationNewCompany = () => {
        clearCompanySetupState();
        navigate('/setup-companies');
    };

    useEffect(() => {
        if (boughtUserModulesList && boughtUserModulesList?.length === 0 && !isModulesLoading) {
            setIsNeedBuyModuleOpened(true);
        } else {
            setIsNeedBuyModuleOpened(false);
        }
    }, [boughtUserModulesList, isModulesLoading]);

    useEffect(() => {
        if (data && !chosenCompanyId) {
            setChosenCompanyId(companyList[0]?.id);
            changeUrl(companyList[0]?.id);
        }
    }, [changeUrl, chosenCompanyId, companyList, data]);

    useEffect(() => {
        if (isNewCompanyCreated) {
            const timeout = setTimeout(() => {
                localStorage.removeItem('isNewCompanyCreated');
                successToast(
                    t('Company was successfully created'),
                    t('It will be displayed on the homepage, you can add other people in it, edit data and modules'),
                    <ModalIcon />,
                    8000
                );
            }, 150);

            return () => clearTimeout(timeout);
        }
    }, [isNewCompanyCreated, successToast, t]);

    if (isLoading || (isFetching && data?.length === 0) || isModulesLoading) {
        return <LayoutLoader />;
    }

    if (!data?.length) {
        return <EmptyCompanyShows />;
    }

    if (!currentCompany && !isFetching && !isNewCompanyCreated && data && chosenCompanyId) {
        return <NotFoundPage isNavigate />;
    }

    return (
        <div className="px-[111px] m-auto mt-[30px] mb-[80px]">
            <div className="w-[1019px]">
                <div className="flex items-center justify-between">
                    <div className="text-int-white-main font-Lexend500 text-[24px] leading-[24px]">
                        {t('List of companies')}
                    </div>

                    <PrimaryButton
                        handleClick={linkToCreationNewCompany}
                        className="w-[172px] h-[42px] font-Lexend400"
                        isPink
                    >
                        {t('Add company')}
                    </PrimaryButton>
                </div>
            </div>
            <div className="flex justify-between items-start mt-[40px]">
                <div className="w-[1019px]">
                    <CountriesList currentCompany={currentCompany} />
                    <BoughtModulesList modulesList={boughtUserModulesList} />
                </div>

                <ChooseList
                    chosenId={chosenCompanyId}
                    setChosenId={selectCountry}
                    data={companyList}
                    name="Companies"
                    changeUrl={changeUrl}
                    maxHeight="max-h-[470px]"
                    handleDefaultSortClick={() => setSortParams('')}
                    handleAZSortClick={() => setSortParams('name,ASC')}
                    handleZASortClick={() => setSortParams('name,DESC')}
                    handleNewToOldSortClick={() => setSortParams('createdDate,DESC')}
                    handleOldToNewSortClick={() => setSortParams('createdDate,ASC')}
                />
            </div>

            <CompaniesListToastModal
                isOpen={isNeedBuyModuleOpened}
                isShowCloseIcon
                handleCloseIconClick={() => setIsNeedBuyModuleOpened(false)}
            >
                <div className="text-int-white-main">
                    <div className="flex items-center text-[16px] font-Lexend500">
                        <ModalWarningIcon />
                        <div className="ml-[8px]">{t('Modules needed')}</div>
                    </div>
                    <div className="text-[14px] leading-[22px] font-Lexend400 flex items-center">
                        <div className="w-[414px] text-[14px] mr-[30px]">
                            {t('You have no modules. Please, buy more modules to continue')}
                        </div>
                        <Link to="/modules">
                            <div className="rounded-[8px] border border-int-white-main w-[100px] h-[34px] flex items-center justify-center">
                                {t('Buy More')}
                            </div>
                        </Link>
                    </div>
                </div>
            </CompaniesListToastModal>
        </div>
    );
};

export default CompanyList;
