import React from 'react';
import PropTypes, { bool, func } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from '../../style/Button/Button';
import ModalWindow from '../../style/ModalWindow/ModalWindow';
import { i18PAGES } from '../../config/i18n/constants';

const DeleteUserModal = ({
    isOpenDeleteUserModal,
    setIsOpenDeleteUserModal,
    id,
    fullName,
    isLoading,
    handleDeleteClick,
}) => {
    const { t } = useTranslation(i18PAGES.USER_MANAGEMENT);

    return (
        <ModalWindow
            isOpen={isOpenDeleteUserModal}
            setIsOpen={setIsOpenDeleteUserModal}
            className="-top-[210px] w-[580px]"
            isCloseClickOutside
        >
            <div className="flex flex-col items-center">
                <div className="text-[24px] leading-[24px] font-Lexend500 text-int-white-main">{t('Delete User')}</div>
                <div className="text-[18px] leading-[24px] font-Lexend400 text-int-white-main my-[50px] text-center">
                    {t('Are you sure you want to delete user')} {`${fullName} ?`}
                </div>

                <div className="w-[100%] flex justify-around">
                    <PrimaryButton
                        className="w-[210px] h-[50px]"
                        handleClick={() => {
                            setIsOpenDeleteUserModal(false);
                        }}
                    >
                        <div className="text-[18px] font-Lexend400">{t('Cancel')}</div>
                    </PrimaryButton>
                    <PrimaryButton
                        isPink
                        className="w-[210px] h-[50px]"
                        handleClick={() => {
                            handleDeleteClick(id);
                        }}
                        disabled={isLoading}
                    >
                        <div className="text-[18px] font-Lexend400">{t('Delete')}</div>
                    </PrimaryButton>
                </div>
            </div>
        </ModalWindow>
    );
};

DeleteUserModal.propTypes = {
    isOpenDeleteUserModal: PropTypes.bool.isRequired,
    setIsOpenDeleteUserModal: PropTypes.func.isRequired,
    id: PropTypes.number,
    fullName: PropTypes.string,
    isLoading: bool,
    handleDeleteClick: func.isRequired,
};

export default DeleteUserModal;
