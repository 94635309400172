import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ArrowLeft } from '../../assets/icons/arrow-left.svg';

const BackButton = () => {
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const projectId = searchParams.get('projectId');

    const navigate = useNavigate();

    const navigateBack = () => {
        navigate(`/projects-list?projectId=${projectId}`);
    };

    return (
        <div className="inline-flex items-center cursor-pointer" onClick={navigateBack}>
            <ArrowLeft width={5} height={8} fill="#9BA1B2" />
            <div className="ml-[7px]">{t('Back')} </div>
        </div>
    );
};

export default BackButton;
