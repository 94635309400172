import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { HEADERS } from '../../components/util/constants';

export const projectRolesAPI = createApi({
    reducerPath: 'projectRolesAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api',
        prepareHeaders: HEADERS,
    }),
    tagTypes: ['projectRoles'],
    endpoints: (build) => ({
        projectRolesInfo: build.query({
            query: ({ projectId }) => `projects/${projectId}/roles`,
            providesTags: ['projectRoles'],
        }),
    }),
});

export const { useProjectRolesInfoQuery } = projectRolesAPI;
