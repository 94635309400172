import React, { useCallback, useMemo, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import OptionsVariations from '../components/Platform/Options/OptionsVariations/OptionsVariations';
import GenerateVariationsOfOptions from '../components/Platform/Options/GenerateVariationsOfOptions/GenerateVariationsOfOptions';
import { useGetVariationsOptionsQuery, useGetVariationsQuery } from '../store/API/optionsAPI';
import { LayoutLoader } from '../components/Preloader/Preloader';
import { i18PAGES } from '../config/i18n/constants';
import ToastWindow from '../style/ToastWindow/ToastWindow';
import { ReactComponent as ModalWarningIcon } from '../assets/icons/modalWarningIcon.svg';
import { useTabsMenu } from '../store/hooks/useTabsMenu';

const OptionsPage = () => {
    const { t } = useTranslation(i18PAGES.OPTIONS);
    const [searchParams] = useSearchParams();
    const projectId = searchParams.get('projectId');
    const { tabsMenuData } = useTabsMenu();

    const [isGeneratingVariations, setIsGeneratingVariations] = useState(false);
    const [selectedEditVariationId, setSelectedEditVariationId] = useState(undefined);

    const { data: variationsData, isLoading } = useGetVariationsQuery(projectId);
    const {
        data: variationsOptions,
        isLoading: isOptionsLoading,
        error,
        isError,
    } = useGetVariationsOptionsQuery(projectId, { refetchOnMountOrArgChange: true });

    const onOpenGeneratingVariations = useCallback(() => {
        setIsGeneratingVariations(true);
    }, []);

    const onCloseGeneratingVariations = useCallback(() => {
        setIsGeneratingVariations(false);
        setSelectedEditVariationId(undefined);
    }, []);

    const oneEditVariationClick = useCallback(
        (variationId) => {
            setSelectedEditVariationId(variationId);
            onOpenGeneratingVariations();
        },
        [onOpenGeneratingVariations]
    );

    const selectedEditVariationData = useMemo(
        () => variationsData?.filter((v) => v.variationId === selectedEditVariationId)[0],
        [selectedEditVariationId, variationsData]
    );

    const alertMessage = useMemo(() => {
        if (error?.data?.message === 'Variations can not be provided, as none providers are linked to the project') {
            return (
                <div>
                    {t('To start generate variations you need to select providers first on the')}{' '}
                    {tabsMenuData?.SELECT_PROVIDER ? (
                        <Link className="underline font-Lexend600" to={`/select_provider?projectId=${projectId}`}>
                            {t('previous step')}
                        </Link>
                    ) : (
                        t('previous step')
                    )}
                </div>
            );
        }

        if (error?.data?.message) {
            return error?.data?.message;
        }

        return t('For linked building blocks there are no providers. Visit Select Provider pages to change it');
    }, [error?.data?.message, projectId, t, tabsMenuData.SELECT_PROVIDER]);

    if (isLoading || isOptionsLoading) {
        return <LayoutLoader />;
    }

    return (
        <div className="pl-[50px] pb-[80px] pr-[25px] pt-[70px] min-h-[100vh] text-int-white-main">
            {isGeneratingVariations ? (
                <GenerateVariationsOfOptions
                    onCloseGeneratingVariations={onCloseGeneratingVariations}
                    selectedEditVariationData={selectedEditVariationData}
                    options={variationsOptions}
                    variationsData={variationsData}
                />
            ) : (
                <OptionsVariations
                    variationsData={variationsData}
                    onOpenGeneratingVariations={onOpenGeneratingVariations}
                    oneEditVariationClick={oneEditVariationClick}
                    isGenerateButtonDisabled={isError || variationsOptions?.length === 0}
                />
            )}
            {(!!error?.data?.message || variationsOptions?.length === 0) && (
                <ToastWindow isOpen width={900} isShowCloseIcon={false}>
                    <div className="flex flex-col items-center justify-center text-int-white-main">
                        <div className="flex items-center text-[16px] font-Lexend500">
                            <ModalWarningIcon />
                            <div className="ml-[10px]">{t('Variations can not be provided')}</div>
                        </div>
                        <div className="text-14px]">{alertMessage}</div>
                    </div>
                </ToastWindow>
            )}
        </div>
    );
};

export default OptionsPage;
