import React, { useEffect, useMemo, useState } from 'react';
import { array, func, number } from 'prop-types';
import CompaniesCountryItem from '../CompaniesList/CompaniesCountryItem';
import { useGetCompanyInProjectQuery } from '../../store/API/companiesAPI';
import Preloader from '../Preloader/Preloader';
import NotFilledCompany from '../CompaniesList/NotFilledCompany';
import CompaniesListViewer from './CompaniesListViewer/CompaniesListViewer';
import { COMPANY_STATUS } from '../util/constants';

const ProjectCompaniesList = ({ chosenCompanyId, setChosenCompanyId, companiesList, projectId }) => {
    const [openedTabCityId, setOpenedTabCityId] = useState(undefined);

    useEffect(() => {
        setOpenedTabCityId(undefined);
    }, [chosenCompanyId]);

    const { data: countriesList, isLoading } = useGetCompanyInProjectQuery(
        { projectId, companyId: chosenCompanyId },
        { skip: !chosenCompanyId || !projectId, refetchOnMountOrArgChange: true }
    );

    const isCompanyFilled = useMemo(() => {
        if (countriesList) {
            return countriesList?.status === COMPANY_STATUS.COMPLETED;
        }
        return undefined;
    }, [countriesList]);

    const chooseCompany = (id) => {
        setChosenCompanyId(id);
    };

    const setOpenTabIndex = (index) => {
        setOpenedTabCityId(index);
    };

    if (isLoading) {
        return (
            <div className="mt-[80px] flex items-center justify-center">
                <Preloader />
            </div>
        );
    }

    return (
        <div>
            <CompaniesListViewer
                companiesList={companiesList}
                chooseCompany={chooseCompany}
                chosenCompanyId={chosenCompanyId}
            />

            {isCompanyFilled && (
                <div>
                    {countriesList?.countries?.length > 0 &&
                        countriesList.countries.map((country, index) => (
                            <div key={index}>
                                {country.cities.map((city, i) => (
                                    <div key={city.id}>
                                        <CompaniesCountryItem
                                            countryName={country.name}
                                            cityItem={city}
                                            companyChanged={chosenCompanyId}
                                            isShowModules
                                            setOpenTabIndex={setOpenTabIndex}
                                            index={i}
                                            openedTabCityId={openedTabCityId}
                                        />
                                    </div>
                                ))}
                            </div>
                        ))}
                </div>
            )}

            {isCompanyFilled === false && (
                <div className="h-[200px]">
                    <NotFilledCompany companyId={chosenCompanyId} />
                </div>
            )}
        </div>
    );
};

export default ProjectCompaniesList;

ProjectCompaniesList.propTypes = {
    companiesList: array.isRequired,
    chosenCompanyId: number,
    projectId: number,
    setChosenCompanyId: func.isRequired,
};
