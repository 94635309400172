import React, { Fragment, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { number } from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import { ReactComponent as PlusIcon } from '../../assets/icons/moreIcon.svg';
import { ReactComponent as ShapeIcon } from '../../assets/icons/shapeIcon.svg';
import CommentAvatar from '../Comments/CommentAvatar';
import { i18PAGES } from '../../config/i18n/constants';
import { useProjectUsersQuery } from '../../store/API/projectInternalManagementAPI';
import { stringShorter } from '../util/stringShorter';

const ManagementBlock = ({ projectId }) => {
    const { data: usersList, isLoading: isUsersLoading } = useProjectUsersQuery(projectId, {
        refetchOnMountOrArgChange: true,
        skip: !projectId,
    });

    const { t } = useTranslation(i18PAGES.PROJECT_LIST);

    const [isHoveringHiddenUsers, setIsHoveringHiddenUsers] = useState(false);

    const handleMouseOver = () => {
        setIsHoveringHiddenUsers(true);
    };

    const handleMouseOut = () => {
        setIsHoveringHiddenUsers(false);
    };

    const shownUsers = useMemo(() => {
        if (usersList && usersList?.length) {
            return usersList?.slice(0, 3);
        }
    }, [usersList]);

    const hiddenUsers = useMemo(() => {
        if (usersList && usersList?.length) {
            return usersList?.slice(3, usersList.length);
        }
    }, [usersList]);

    if (isUsersLoading) {
        return <Skeleton duration={1} borderRadius="40px" width={298} height={30} baseColor="#354153" />;
    }

    return (
        <div className="text-[14px] leading-[18px] font-Lexend400 flex items-center bg-int-gray-secondary rounded-[40px] h-[30px] px-[13px]">
            <div>{t('User Management')}</div>
            <div className="ml-[8px] mr-[12px] flex items-center">
                {shownUsers?.length > 0 &&
                    shownUsers?.map((u) => (
                        <div className="mr-[4px]" key={u.id}>
                            <CommentAvatar name={u.fullName[0]} size={22} />
                        </div>
                    ))}
                {hiddenUsers?.length > 0 && (
                    <div
                        onMouseOver={handleMouseOver}
                        onMouseOut={handleMouseOut}
                        className="relative flex w-[22px] h-[22px] items-center justify-center text-[14px] rounded-full uppercase border border-int-white-secondary"
                    >
                        <div>{hiddenUsers?.length}</div>
                        {isHoveringHiddenUsers && (
                            <div className="absolute z-10 top-[40px] right-[-15px] bg-int-gray-secondary py-[10px] rounded-[8px] w-[224px]">
                                <div className="absolute top-[-9px] right-[15px]">
                                    <ShapeIcon fill="#354153" />
                                </div>
                                {hiddenUsers?.map((u, index) => {
                                    if (index < 7) {
                                        return (
                                            <div key={u.id} className="flex items-center h-[40px] pl-[20px]">
                                                <CommentAvatar name={u.fullName[0]} size={22} />
                                                <div className="ml-[10px] text-int-white font-Lexend-400 leading-[18px] capitalize">
                                                    {stringShorter(u.fullName, 18)}
                                                </div>
                                            </div>
                                        );
                                    }
                                    return <Fragment key={u.id} />;
                                })}
                                {hiddenUsers?.length > 7 && <div className="text-center">...</div>}
                            </div>
                        )}
                    </div>
                )}
            </div>
            <Link to={`/projects-management?projectId=${projectId}`}>
                <PlusIcon />
            </Link>
        </div>
    );
};
export default ManagementBlock;

ManagementBlock.propTypes = {
    projectId: number,
};
