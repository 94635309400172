import React, { useMemo, useState } from 'react';
import { bool, func, number, string } from 'prop-types';
import classnames from 'classnames';
import { Field } from 'formik';
import { ReactComponent as ErrorIcon } from '../../assets/icons/errorIcon.svg';
import { ReactComponent as DeleteTextIcon } from '../../assets/icons/deleteText.svg';

const TextArea = ({
    isError,
    errorText,
    placeholder,
    name,
    label,
    className,
    value,
    handleChange,
    handleBlur,
    handleFocus,
    height = 108,
    errorIconPosition = 'top-[35px]',
    setFieldValue,
    limit,
}) => {
    const [focused, setFocused] = useState(false);
    const [isOpenHelpErrorInfo, setIsOpenHelpErrorInfo] = useState(false);

    const onFocusHandler = () => {
        if (handleFocus) {
            handleFocus();
        }
        setFocused(true);
    };

    const onBlurHandler = (e) => {
        handleBlur(e);
        setFocused(false);
    };

    const onDeleteText = () => {
        setFieldValue(name, '');
    };

    const labelClass = useMemo(
        () =>
            classnames('absolute left-0 top-[-20px] text-[14px] leading-[16px] font-Lexend400 text-int-white-main', {
                'text-error': isError,
                'text-int-white-main': !isError,
            }),
        [isError]
    );

    const inputClass = useMemo(
        () =>
            classnames(
                'border block w-[100%] text-[14px] rounded-[6px] bg-[#2A3143] pl-[8px] pr-[28px] py-[6px] leading-[19px]',
                {
                    'border-[#EDF1FD]': !isError && focused,
                    'border-error': isError,
                }
            ),
        [isError, focused]
    );

    return (
        <div className={`relative ${className || ''}`}>
            <div className={labelClass}>{label}</div>
            <div className="relative mb-[6px]">
                <div className="bg-[#354153] px-[6px] py-[8px] rounded-[8px]">
                    <Field
                        id="thin"
                        as="textarea"
                        placeholder={placeholder}
                        name={name}
                        value={value}
                        onChange={handleChange}
                        onBlur={onBlurHandler}
                        onFocus={onFocusHandler}
                        className={inputClass}
                        style={{ resize: 'none', height: `${height - 14}px` }}
                    />
                </div>
                {limit && String(value).length > 0 && (
                    <div className="absolute right-[10px] bottom-[10px] text-int-white-secondary text-[10px] leading-[10px] font-Lexend400">
                        {String(value).length}/{limit}
                    </div>
                )}
            </div>

            {isError ? (
                <div className={`absolute right-[15px] ${errorIconPosition}`}>
                    <div
                        onMouseEnter={() => setIsOpenHelpErrorInfo(true)}
                        onMouseLeave={() => setIsOpenHelpErrorInfo(false)}
                    >
                        <ErrorIcon />
                    </div>
                    {isOpenHelpErrorInfo && errorText && (
                        <div
                            className="text-[12px] text-error font-Lexend300 absolute
                     bg-[#222730] p-[10px] rounded-[2px] z-10 bottom-[23px] right-0 whitespace-nowrap"
                        >
                            {errorText}
                        </div>
                    )}
                </div>
            ) : (
                <>
                    {String(value).length > 0 && (
                        <div
                            className={`absolute right-[18px] ${errorIconPosition} h-[14px] w-[14px] hover:bg-int-gray-secondary flex items-center justify-center cursor-pointer`}
                            onClick={onDeleteText}
                        >
                            <DeleteTextIcon width={10} height={10} fill="#EDF1FD" />
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default TextArea;

TextArea.propTypes = {
    isError: bool,
    errorText: string,
    placeholder: string,
    name: string.isRequired,
    label: string.isRequired,
    className: string,
    value: string.isRequired,
    handleChange: func.isRequired,
    handleBlur: func,
    handleFocus: func,
    height: number,
    errorIconPosition: string,
    setFieldValue: func.isRequired,
    limit: number,
};
