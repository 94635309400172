import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { HEADERS, HR_PAGINATION_PAGE_SIZE } from '../../components/util/constants';

export const hrAPI = createApi({
    reducerPath: 'hrAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api',
        prepareHeaders: HEADERS,
    }),
    tagTypes: ['hrInfo'],
    endpoints: (build) => ({
        hrAllBuildingBlocks: build.query({
            query: () => `/building-blocks/all`,
            keepUnusedDataFor: 0.0001,
        }),
        hrList: build.query({
            query: ({ page = 0, ids = '', search = '' }) =>
                `/tools/search?size=${HR_PAGINATION_PAGE_SIZE}&sort=name,ASC&sort=description,DESC&page=${page}${
                    ids !== '' ? `&ids=${ids}` : ''
                }${search !== '' ? `&search=${search}` : ''}`,
            keepUnusedDataFor: 0.0001,
        }),
    }),
});

export const { useHrAllBuildingBlocksQuery, useHrListQuery } = hrAPI;
