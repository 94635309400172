import React from 'react';
import { useTranslation } from 'react-i18next';
import SideList from '../components/HRInfo/SideList';
import PhotoViewer from '../components/HRInfo/PhotoViewer/PhotoViewer';
import Breadcrumb from '../style/Breadcrumb/Breadcrumb';
import { i18PAGES } from '../config/i18n/constants';
import BonusPricingBlock from '../components/HRInfo/BonusPricingBlock';
import BonusFeaturesBlock from '../components/HRInfo/BonusFeaturesBlock';
import SimilarToolsBlock from '../components/HRInfo/SimilarToolsBlock';

const BreadcrumbArray = [
    { name: 'Übersicht HR Tools', path: '/hr' },
    { name: 'Bonusly HR Tool', path: '/hr/1' },
];
const HrInfoPage = () => {
    const { t } = useTranslation(i18PAGES.HR_TOOLS);

    return (
        <div className="mt-[52px] pb-[90px] px-[130px]">
            <div className="flex justify-between items-center mb-[50px]">
                <Breadcrumb breadCrumbDetail={BreadcrumbArray} />
                {/* todo what search do on this page */}
                {/* <SearchInput type="text" placeholder="Tool suchen" className="w-[410px]" /> */}
            </div>

            <div className="flex justify-between min-w-[1180px] w-[100%]">
                <div className="w-[860px]">
                    <div className="bg-int-gray-main rounded-[8px] p-[20px]">
                        <div className="flex">
                            <div className="pl-[10px] pt-[20px]">
                                <div className="text-int-white-main font-Lexend500 text-[24px] leading-[24px]">
                                    {t('Bonusly')}
                                </div>
                                <div className="mt-[32px] text-int-white-main leading-[24px] w-[335px] text-[14px]">
                                    {t(
                                        'Bonusly helps HR, team leads, and small business owners create engaging environments by putting recognition in the hands of those closest to the work. With an extensive international digital rewards catalog, Bonusly minimizes the administration time needed to turn recognition into tangible perks.'
                                    )}
                                </div>
                            </div>
                            <div className="w-[380px] ml-[55px]">
                                <PhotoViewer />
                            </div>
                        </div>
                    </div>
                    <BonusPricingBlock />
                    <BonusFeaturesBlock />
                </div>

                <div className="w-[260px]">
                    <SideList />
                </div>
            </div>

            <SimilarToolsBlock />
        </div>
    );
};

export default HrInfoPage;
