import React, { useMemo, useRef, useState } from 'react';
import { bool, func, string, number, object } from 'prop-types';
import useOutsideAlerter from '../../../util/useOutsideAlerter';
import { ReactComponent as ArrowBottomIcon } from '../../../../assets/icons/bottom-arrow.svg';
import { ReactComponent as ArrowUpIcon } from '../../../../assets/icons/up-arrow.svg';
import { ReactComponent as ErrorIcon } from '../../../../assets/icons/errorIcon.svg';
import TableCompaniesOfficesCheckboxDropdownItem from './TableCompaniesOfficesCheckboxDropdownItem';
import OfficeModuleItem from '../../../CreateProject/Step3CreateProject/OfficeModuleItem';
import OtherModulesHelper from '../../../CreateProject/Step3CreateProject/OtherModulesHelper';
import { stringShorter } from '../../../util/stringShorter';

export default function TableCompaniesOfficesCheckboxDropdown({
    name,
    placeholder,
    isError,
    errorText,
    className,
    handleBlur,
    handleFocus,
    values,
    index,
    setFieldValue,
}) {
    const container = useRef(null);
    const [open, setOpen] = useState(false);
    const [isOpenHelpErrorInfo, setIsOpenHelpErrorInfo] = useState(false);
    useOutsideAlerter({ ref: container, callback: () => setOpen(false) });

    const onBlur = (e) => {
        if (!open) {
            handleBlur(e);
        }
    };

    const onFocus = () => {
        if (handleFocus) {
            handleFocus();
        }
    };

    const filteredCompanies = useMemo(
        () =>
            values.toBeApplicationsList[index].companyOffices
                .map((company) => ({
                    ...company,
                    officeList: company.officeList.filter((office) => office.isChosen === true),
                }))
                .filter((company) => company.officeList.length > 0),
        [values.toBeApplicationsList[index].companyOffices]
    );

    const visibleFilteredCompanies = filteredCompanies.slice(0, 2);

    const notVisibleFilteredCompanies = filteredCompanies.slice(2).map((company) => ({
        title: company.companyName,
        buildingBlocks: company.officeList.map((office) => ({
            name: `${office.countryName}, ${office.cityName}, ${office.officeName}`,
        })),
    }));

    return (
        <div className={`relative ${className || ''}`} ref={container}>
            <input
                type="text"
                name={name}
                onClick={() => setOpen((prev) => !prev)}
                placeholder={visibleFilteredCompanies?.length === 0 ? placeholder : ''}
                onBlur={onBlur}
                onFocus={onFocus}
                className={`border ${
                    isError ? 'border-error' : `${open ? 'border-int-white-main' : 'border-[#2A3143]'}`
                } 
                   cursor-pointer bg-[#2A3143] rounded-[4px] px-[8px] py-[12px] w-[100%]`}
                aria-hidden="true"
                autoComplete="off"
                style={{
                    caretColor: 'transparent',
                    cursor: 'pointer',
                }}
                readOnly
            />
            {open && (
                <div
                    className="absolute top-[calc(100%+8px)] bg-[#2A3143] w-full text-int-white-main text-[14px]
                 font-Lexend400 rounded z-50 max-h-[272px] pt-[16px] pb-[8px] pl-[16px] shadow-dropdown"
                >
                    <div className="overflow-y-auto max-h-[240px]" id="primary-scroll">
                        {values.toBeApplicationsList[index].companyOffices.map((company, companyIndex) => (
                            <TableCompaniesOfficesCheckboxDropdownItem
                                key={companyIndex}
                                companyIndex={companyIndex}
                                index={index}
                                values={values}
                                company={company}
                                setFieldValue={setFieldValue}
                            />
                        ))}
                    </div>
                </div>
            )}
            <div className="pointer-events-none absolute right-0 top-[20px] flex items-center px-2">
                {open ? <ArrowUpIcon fill="#EDF1FD" /> : <ArrowBottomIcon fill="#EDF1FD" />}
            </div>
            <div
                className="flex justify-between items-center w-[90%] absolute top-[11px] ml-[10px] mr-[20px] cursor-pointer"
                onClick={() => setOpen((prev) => !prev)}
            >
                <div className="flex">
                    {visibleFilteredCompanies.map((company, index) => (
                        <OfficeModuleItem
                            name={stringShorter(company.companyName, 10)}
                            count={company.officeList.length}
                            className="bg-[#354153] mr-[6px]"
                            key={index}
                        />
                    ))}
                </div>
                <OtherModulesHelper notVisibleModules={notVisibleFilteredCompanies} />
            </div>
            {isError && (
                <div className="absolute right-[25px] top-[14px]">
                    <div
                        className={errorText && 'cursor-pointer'}
                        onMouseEnter={() => setIsOpenHelpErrorInfo(true)}
                        onMouseLeave={() => setIsOpenHelpErrorInfo(false)}
                    >
                        <ErrorIcon />
                    </div>
                    {isOpenHelpErrorInfo && errorText && (
                        <div
                            className="text-[12px] text-error font-Lexend300 absolute bg-[#222730]
                         p-[10px] rounded-[2px] z-10 bottom-[23px] left-[-20px] whitespace-nowrap"
                        >
                            {errorText}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

TableCompaniesOfficesCheckboxDropdown.propTypes = {
    name: string,
    placeholder: string,
    isError: bool,
    errorText: string,
    className: string,
    handleBlur: func,
    handleFocus: func,
    values: object,
    index: number,
    setFieldValue: func,
};
