import React from 'react';
import { bool, func } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { i18PAGES } from '../../config/i18n/constants';
import ModalWindow from '../../style/ModalWindow/ModalWindow';
import { PrimaryButton } from '../../style/Button/Button';

const ArchiveProjectModal = ({
    isOpenArchiveProjectModalModal,
    setIsOpenArchiveProjectModalModal,
    handleArchiveProject,
}) => {
    const { t } = useTranslation(i18PAGES.PROJECT_LIST);

    return (
        <ModalWindow
            isOpen={isOpenArchiveProjectModalModal}
            setIsOpen={setIsOpenArchiveProjectModalModal}
            className="-top-[180px] w-[580px]"
            isCloseClickOutside
        >
            <div className="flex flex-col items-center">
                <div className="text-[24px] leading-[24px] font-Lexend500 text-int-white-main">
                    {t('Archive the project')}
                </div>
                <div className="text-[18px] px-[20px] leading-[24px] font-Lexend400 text-int-white-main my-[50px] text-center">
                    <div>
                        {t(
                            'Are you sure you want to archive the project? This action is irreversible and you will not be able to make any changes'
                        )}
                    </div>
                </div>

                <div className="w-[100%] flex justify-around mb-[20px]">
                    <PrimaryButton
                        className="w-[210px] h-[50px]"
                        handleClick={() => {
                            setIsOpenArchiveProjectModalModal(false);
                        }}
                    >
                        <div className="text-[18px] font-Lexend400">{t('Cancel')}</div>
                    </PrimaryButton>
                    <PrimaryButton isPink className="w-[210px] h-[50px]" handleClick={handleArchiveProject}>
                        <div className="text-[18px] font-Lexend400">{t('Archive')}</div>
                    </PrimaryButton>
                </div>
            </div>
        </ModalWindow>
    );
};

export default ArchiveProjectModal;

ArchiveProjectModal.propTypes = {
    isOpenArchiveProjectModalModal: bool.isRequired,
    setIsOpenArchiveProjectModalModal: func.isRequired,
    handleArchiveProject: func,
};
