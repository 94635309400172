import React from 'react';
import { arrayOf, number, shape, string } from 'prop-types';
import TableHeader from '../../../../style/Table/TableHeader';
import ToBeIntegrationsViewTableItem from './ToBeIntegrationsViewTableItem';

const ToBeIntegrationsViewTable = ({ tableHeaderData, data }) => (
    <div id="bold-scroll" className="overflow-auto max-w-[1450px]">
        <table className="table-fixed">
            <TableHeader data={tableHeaderData} isReadMode />
            <tbody>
                {data?.length > 0 ? (
                    data?.map((integration, index) => (
                        <ToBeIntegrationsViewTableItem integrationItem={integration} key={index} index={index} />
                    ))
                ) : (
                    <ToBeIntegrationsViewTableItem />
                )}
            </tbody>
        </table>
    </div>
);

export default ToBeIntegrationsViewTable;

ToBeIntegrationsViewTable.propTypes = {
    tableHeaderData: arrayOf(
        shape({
            minWidth: number,
            name: string,
        })
    ),
    data: arrayOf(
        shape({
            outgoingBuildingBlock: shape({
                id: number,
                name: string,
            }),
            ingoingBuildingBlock: shape({
                id: number,
                name: string,
            }),

            outgoingApp: string,
            ingoingApp: string,
            dataType: string,
            integrationType: string,
            operationType: string,
            supportEffort: number,
            frequency: string,
            numberOfFrequency: number,
        })
    ),
};
