import { autoUpdate, flip, offset, size, useDismiss, useFloating, useInteractions, useRole } from '@floating-ui/react';
import { useCallback } from 'react';

export function useChangeDirectionDropdown(props) {
    const { isOpen, setIsOpen, maxHeightDropDown = 360, offsetValue, widthDropDown } = props;

    const { refs, floatingStyles, context } = useFloating({
        placement: 'bottom-end',
        open: isOpen,
        onOpenChange: setIsOpen,
        whileElementsMounted: autoUpdate,
        middleware: [
            offset(offsetValue || 8),
            flip({ padding: 10 }),
            size({
                apply({ rects, elements }) {
                    Object.assign(elements.floating.style, {
                        maxHeight: `${maxHeightDropDown}px`,
                        minWidth: `${widthDropDown || rects.reference.width}px`,
                        maxWidth: `${widthDropDown || rects.reference.width}px`,
                    });
                },
                padding: 10,
            }),
        ],
    });

    const role = useRole(context, { role: 'listbox' });
    const dismiss = useDismiss(context);

    const onInputClick = useCallback(
        (e) => {
            e.preventDefault();
            if (!isOpen) {
                setIsOpen(true);

                setTimeout(() => {
                    e.target.click();
                    e.target.focus();
                }, 0);
            }
        },
        [isOpen, setIsOpen]
    );

    const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions([role, dismiss]);

    return { getReferenceProps, getFloatingProps, getItemProps, refs, context, floatingStyles, onInputClick };
}
