import React, { useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import i18n from 'i18next';
import ProjectInfoPageHeader from '../components/Platform/Common/ProjectInfoPageHeader';
import { PAGE_IDENTIFIER } from '../components/util/constants';
import ProjectInfoTabSwitcher from '../components/Platform/Common/ProjectInfoTabSwitcher';
import { i18PAGES } from '../config/i18n/constants';
import DescriptionAndButtonsBlock from '../components/Platform/Common/DescriptionAndButtonsBlock';
import {
    AsIsApplicationValidationSchema,
    getEmptyFormDataItem,
    getTableHeaderData,
    stringStatusToEnum,
} from '../components/Platform/AsIsApplications/constants';
import AsIsApplicationsViewTable from '../components/Platform/AsIsApplications/ViewMode/AsIsApplicationsViewTable';
import AsIsApplicationEditTable from '../components/Platform/AsIsApplications/EditMode/AsIsApplicationEditTable';
import { generateKey } from '../components/util/generateKey';
import { useTabsMenu } from '../store/hooks/useTabsMenu';
import {
    useAsIsApplicationInfoQuery,
    useAsIsApplicationsOptionsQuery,
    useSendAsIsApplicationsMutation,
} from '../store/API/asIsApplicationsAPI';
import { useToast } from '../hoc/toast/ToastProvider';
import { LayoutLoader } from '../components/Preloader/Preloader';
import PageNotAvailable from '../components/Platform/PageNotAvailable';
import { useEditMode } from '../components/util/useEditMode';
import WarningModal from '../style/WarningModal/WarningModal';
import { useSort } from '../hooks/useSort';
import { useWarningModalControl } from '../store/hooks/useWarningModalControl';

const AsIsApplicationsPage = () => {
    const { t } = useTranslation(i18PAGES.AS_IS_APPLICATION);
    const { successToast, errorToast } = useToast();
    const { orderFromUrl, sortByFromUrl } = useSort();

    const [searchParams] = useSearchParams();
    const projectId = searchParams.get('projectId');
    const officeId = searchParams.get('officeId');

    const { data, isLoading, refetch } = useAsIsApplicationInfoQuery(
        { projectId, officeId, sortBy: sortByFromUrl, orderBy: orderFromUrl },
        { skip: !projectId || !officeId }
    );

    const { data: options, isLoading: isOptionsLoading } = useAsIsApplicationsOptionsQuery(
        { projectId, officeId },
        { skip: !projectId || !officeId }
    );

    const [sendAsIsApplications] = useSendAsIsApplicationsMutation();

    const dataWithKeys = useMemo(() => {
        if (data) {
            return data?.map((d, index) => ({
                ...d,
                key: generateKey(index),
                status: t(d.status),
            }));
        }
        return [];
    }, [data, t]);

    const formikRef = useRef();

    const {
        isEditMode,
        isShowCloseEditAlertModal,
        openEditAlertModal,
        acceptOffEditMode,
        declineOffEditMode,
        setIsEditMode,
        setIsShowCloseEditAlertModal,
    } = useWarningModalControl(formikRef);

    useEditMode(isEditMode);

    const isAllOptionsFilled = useMemo(
        () => options?.modules?.length > 0 && options?.buildingBlocks?.length > 0 && options?.languages?.length > 0,
        [options?.buildingBlocks?.length, options?.languages?.length, options?.modules?.length]
    );

    const onEditMode = () => {
        if (isAllOptionsFilled) {
            setIsEditMode(true);
        } else {
            errorToast(t('Fill company information before fill As-is Applications page'));
        }
    };

    const tableHeaderData = useMemo(() => getTableHeaderData(t), [t]);

    const { tabsMenuData } = useTabsMenu();

    if (!projectId) {
        return <PageNotAvailable />;
    }

    if (isLoading || isOptionsLoading) {
        return <LayoutLoader />;
    }

    return (
        <div className="pl-[50px] pb-[80px] pr-[25px] pt-[70px] min-h-[100vh] text-int-white-main">
            <ProjectInfoPageHeader
                nextLink="/to_be_applications"
                pageIdentifier={PAGE_IDENTIFIER.AS_IS_APPLICATIONS}
                projectId={Number(projectId)}
                pageName={t('As-is Applications')}
            />

            <ProjectInfoTabSwitcher
                itemsArray={tabsMenuData?.AS_IS_APPLICATIONS}
                projectId={projectId}
                rootUrl="as_is_applications"
            />

            <Formik
                onSubmit={(values, { setSubmitting }) => {
                    const sendData = {
                        asIsApplications: values.applicationsInfo.map((a) => ({
                            ...a,
                            id: a.id || null,
                            moduleId: a.module.id,
                            buildingBlockId: a.buildingBlock.id,
                            status: stringStatusToEnum(a.status),
                        })),
                    };

                    sendAsIsApplications({ projectId, officeId, body: sendData })
                        .unwrap()
                        .then((res) => {
                            refetch().then(() => {
                                successToast(t('As-is Applications data were successfully updated'));
                                setIsEditMode(false);
                            });
                        })
                        .catch((err) => {
                            errorToast(i18n.t('Something went wrong, try again later'));
                        })
                        .finally(() => {
                            setSubmitting(false);
                        });
                }}
                validationSchema={AsIsApplicationValidationSchema}
                initialValues={{
                    applicationsInfo: dataWithKeys?.length > 0 ? dataWithKeys : [getEmptyFormDataItem()],
                }}
                enableReinitialize
                innerRef={formikRef}
            >
                {({ values, isSubmitting, handleSubmit, errors, touched, handleChange, handleBlur, setFieldValue }) => (
                    <Form>
                        <DescriptionAndButtonsBlock
                            isEditMode={isEditMode}
                            descriptionText="Explanation on how to fill in information on this tab"
                            onEditMode={onEditMode}
                            offEditMode={openEditAlertModal}
                            isSubmitting={isSubmitting}
                            handleSave={handleSubmit}
                        />
                        {isEditMode ? (
                            <AsIsApplicationEditTable
                                tableHeaderData={tableHeaderData}
                                values={values}
                                errors={errors}
                                touched={touched}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                setFieldValue={setFieldValue}
                                options={options}
                            />
                        ) : (
                            <AsIsApplicationsViewTable tableHeaderData={tableHeaderData} data={dataWithKeys} />
                        )}
                    </Form>
                )}
            </Formik>
            <WarningModal
                isOpen={isShowCloseEditAlertModal}
                setIsOpen={setIsShowCloseEditAlertModal}
                warningText={t('Are you sure you want to close editing mode? Your result will not be saved')}
                acceptButtonText={t('Close')}
                declineButtonText={t('Cancel')}
                accept={acceptOffEditMode}
                decline={declineOffEditMode}
            />
        </div>
    );
};
export default AsIsApplicationsPage;
