import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import DeleteUserModal from './DeleteUserModal';
import { useDeleteUserMutation } from '../../store/API/userManagementAPI';
import { useToast } from '../../hoc/toast/ToastProvider';

const DeleteUser = ({ isOpenDeleteUserModal, setIsOpenDeleteUserModal, id, fullName }) => {
    const [deleteUser, { isDeleteLoading }] = useDeleteUserMutation();

    const { successToast, errorToast } = useToast();

    const handleDeleteClick = (id) => {
        deleteUser(id)
            .unwrap()
            .then((res) => {
                successToast(i18n.t('User was successfully deleted'));
            })
            .catch((err) => {
                errorToast(i18n.t('Something went wrong, try again later'));
            })
            .finally(() => {
                setIsOpenDeleteUserModal(false);
            });
    };

    return (
        <DeleteUserModal
            isOpenDeleteUserModal={isOpenDeleteUserModal}
            setIsOpenDeleteUserModal={setIsOpenDeleteUserModal}
            id={id}
            fullName={fullName}
            isLoading={isDeleteLoading}
            handleDeleteClick={handleDeleteClick}
        />
    );
};

export default DeleteUser;

DeleteUser.propTypes = {
    isOpenDeleteUserModal: PropTypes.bool.isRequired,
    setIsOpenDeleteUserModal: PropTypes.func.isRequired,
    id: PropTypes.number,
    fullName: PropTypes.string,
};
