import { array, object, string } from 'yup';
import { REGEX } from '../../util/constants';

export const workersValidationSchema = object().shape({
    workerList: array().of(
        object().shape({
            fullName: string()
                .required('Name should be stated')
                .matches(REGEX.STRING_ONLY, 'Invalid name')
                .max(50, 'Max length is 50 symbols'),
            role: string().max(50, 'Max length is 50 symbols'),
            email: string().required('Email should be stated').email('Invalid email'),
        })
    ),
});

export const workersEditValidationSchema = object({
    fullName: string()
        .required('Name should be stated')
        .matches(REGEX.STRING_ONLY, 'Invalid name')
        .max(50, 'Max length is 50 symbols'),
    role: string().max(50, 'Max length is 50 symbols'),
    email: string().required('Email should be stated').email('Invalid email'),
});
