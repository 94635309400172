import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import i18n from 'i18next';
import http from '../components/util/http';
import Invoice from '../components/Invoice/Invoice';
import { PageLoader } from '../components/Preloader/Preloader';
import FailedConfirmation from '../components/Confirmation/FailedConfirmation';

const InvoicePage = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const { token } = useParams();
    const [dataInvoice, setDataInvoice] = useState({});

    useEffect(() => {
        http.get(`/invoices?key=${token}`)
            .then((res) => {
                setDataInvoice(res.data);
            })
            .catch(() => {
                setIsError(true);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    if (isLoading) return <PageLoader />;

    if (isError)
        return (
            <FailedConfirmation
                buttonText={i18n.t('Go to Homepage')}
                linkPath="/"
                text={i18n.t('Token is expired or not valid. Please try again or return to the Home page')}
            />
        );

    return (
        <Invoice
            typeInvoiceByToken
            token={token}
            fileLoaded={dataInvoice?.fileLoaded}
            invoiceNumber={dataInvoice?.invoiceNumber}
            supplierCompanyName={dataInvoice?.supplierCompanyName}
            supplierCompanyAddress={dataInvoice?.supplierCompanyAddress}
            createdDate={dataInvoice?.createdDate}
            customerCompanyName={dataInvoice?.customerCompanyName}
            customerCompanyAddress={dataInvoice?.customerCompanyAddress}
            subjectMatter={dataInvoice?.subjectMatter}
            modules={dataInvoice?.modules}
            subscriptionAmount={dataInvoice?.subscriptionAmount}
            tax={dataInvoice?.tax}
            totalAmount={dataInvoice?.totalAmount}
            wahlerCompanyEmail={dataInvoice?.wahlerCompanyEmail}
            supplierBankInformation={dataInvoice?.supplierBankInformation}
        />
    );
};

export default InvoicePage;
