export const chatDateHelper = (isoDate) => {
    const date = new Date(isoDate);
    const currentDate = new Date();
    const mSecondsDifference = Math.abs(currentDate - date);
    const diffMinutes = mSecondsDifference / (1000 * 60);
    const diffHours = diffMinutes / 60;
    const diffDays = diffHours / 24;
    const diffMonth = diffDays / 30;
    const diffYears = diffMonth / 12;

    if (diffYears > 1) {
        return `${Math.round(diffYears)} years ago`;
    }
    if (diffMonth > 1) {
        return `${Math.round(diffMonth)} month ago`;
    }
    if (diffDays > 1) {
        return `${Math.round(diffDays)} days ago`;
    }
    if (diffHours > 1) {
        return `${Math.round(diffHours)} hours ago`;
    }
    if (diffMinutes > 5) {
        return `${Math.round(diffMinutes)} minutes ago`;
    }

    return `Few minutes ago`;
};
