import { useDispatch, useSelector } from 'react-redux';
import {
    setAuth,
    setIsEditableListProject,
    setIsEditablePagesProject,
    setIsPermission,
    setIsProjectRoleLoading,
    setPending,
    setProfileData,
    setProjectRole,
    setServerError,
} from '../reducers/authReducer';
import http, { AUTH_TOKEN } from '../../components/util/http';
import { authAPI } from '../API/authAPI';
import { commentsAPI } from '../API/commentsAPI';
import { companiesAPI } from '../API/companiesAPI';
import { createProjectAPI } from '../API/createProjectAPI';
import { fteProcessAPI } from '../API/fteProcessAPI';
import { modulesAPI } from '../API/modulesAPI';
import { modulesStrategiesAPI } from '../API/modulesStrategiesAPI';
import { profileAPI } from '../API/profileAPI';
import { projectExternalManagementAPI } from '../API/projectExternalManagementAPI';
import { projectInternalManagementAPI } from '../API/projectInternalManagementAPI';
import { projectInfoAPI } from '../API/projectsInfoAPI';
import { userManagementAPI } from '../API/userManagementAPI';
import { userModulesAPI } from '../API/userModulesAPI';
import { userPageAPI } from '../API/userPageAPI';
import { workersInformationAPI } from '../API/workersInformationAPI';
import { projectRolesAPI } from '../API/projectRolesApi';
import { toBeApplicationAPI } from '../API/toBeApplicationAPI';
import { providerSelectionAPI } from '../API/providerSelectionAPI';
import { asIsApplicationsAPI } from '../API/asIsApplicationsAPI';
import { asIsIntegrationsAPI } from '../API/asIsIntegrationsAPI';
import { toBeIntegrationsAPI } from '../API/toBeIntegrationsAPI';
import { subscriptionsAPI } from '../API/subscriptionsAPI';
import { invoiceAPI } from '../API/invoiceAPI';
import { optionsAPI } from '../API/optionsAPI';
import { hrAPI } from '../API/hrAPI';
import { surveysAPI } from '../API/surveysAPI';

export const useAuth = () => {
    const dispatch = useDispatch();

    const _isAuth = useSelector((state) => state.auth.isAuth);

    const _isPending = useSelector((state) => state.auth.isPending);

    const _profileData = useSelector((state) => state.auth.profileData);

    const _isPermission = useSelector((state) => state.auth.isPermission);

    const _isEditableListProject = useSelector((state) => state.auth.isEditableListProject);

    const _isEditablePagesProject = useSelector((state) => state.auth.isEditablePagesProject);

    const _projectRole = useSelector((state) => state.auth.projectRole);

    const _isServerError = useSelector((state) => state.auth.isServerError);

    const _isProjectRoleLoading = useSelector((state) => state.auth.isProjectRoleLoading);

    const _setAuth = (status) => {
        dispatch(setAuth(status));
    };
    const _setPending = (status) => {
        dispatch(setPending(status));
    };

    const _setProfileData = (data) => {
        dispatch(setProfileData(data));
    };

    const _setIsPermission = (status) => {
        dispatch(setIsPermission(status));
    };

    const _setIsEditableListProject = (data) => {
        dispatch(setIsEditableListProject(data));
    };

    const _setIsEditablePagesProject = (status) => {
        dispatch(setIsEditablePagesProject(status));
    };

    const _setProjectRole = (status) => {
        dispatch(setProjectRole(status));
    };

    const _setServerError = (status) => {
        dispatch(setServerError(status));
    };
    const _setIsProjectRoleLoading = (loadingStatus) => {
        dispatch(setIsProjectRoleLoading(loadingStatus));
    };

    const resetRTKCash = () => {
        dispatch(authAPI.util.resetApiState());
        dispatch(commentsAPI.util.resetApiState());
        dispatch(companiesAPI.util.resetApiState());
        dispatch(createProjectAPI.util.resetApiState());
        dispatch(fteProcessAPI.util.resetApiState());
        dispatch(modulesAPI.util.resetApiState());
        dispatch(modulesStrategiesAPI.util.resetApiState());
        dispatch(profileAPI.util.resetApiState());
        dispatch(projectExternalManagementAPI.util.resetApiState());
        dispatch(projectInternalManagementAPI.util.resetApiState());
        dispatch(projectRolesAPI.util.resetApiState());
        dispatch(projectInfoAPI.util.resetApiState());
        dispatch(userManagementAPI.util.resetApiState());
        dispatch(userModulesAPI.util.resetApiState());
        dispatch(userPageAPI.util.resetApiState());
        dispatch(workersInformationAPI.util.resetApiState());
        dispatch(toBeApplicationAPI.util.resetApiState());
        dispatch(providerSelectionAPI.util.resetApiState());
        dispatch(asIsApplicationsAPI.util.resetApiState());
        dispatch(asIsIntegrationsAPI.util.resetApiState());
        dispatch(toBeIntegrationsAPI.util.resetApiState());
        dispatch(subscriptionsAPI.util.resetApiState());
        dispatch(invoiceAPI.util.resetApiState());
        dispatch(optionsAPI.util.resetApiState());
        dispatch(hrAPI.util.resetApiState());
        dispatch(surveysAPI.util.resetApiState());
    };

    const login = (token) => {
        _setAuth(true);
        localStorage.setItem(AUTH_TOKEN, token);
        http.setAuthHeader(token);
    };

    const logout = () => {
        resetRTKCash();
        _setAuth(false);
        localStorage.removeItem(AUTH_TOKEN);
        _setProfileData(null);
    };

    return {
        isAuth: _isAuth,
        isPending: _isPending,
        profileData: _profileData,
        isPermission: _isPermission,
        isEditableListProject: _isEditableListProject,
        isEditablePagesProject: _isEditablePagesProject,
        projectRole: _projectRole,
        isServerError: _isServerError,
        isProjectRoleLoading: _isProjectRoleLoading,
        setAuth: _setAuth,
        setPending: _setPending,
        setProfileData: _setProfileData,
        setIsPermission: _setIsPermission,
        setIsEditableListProject: _setIsEditableListProject,
        setIsEditablePagesProject: _setIsEditablePagesProject,
        setProjectRole: _setProjectRole,
        setServerError: _setServerError,
        setIsProjectRoleLoading: _setIsProjectRoleLoading,
        login,
        logout,
    };
};
