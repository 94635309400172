import React from 'react';
import { useTranslation } from 'react-i18next';
import { string } from 'prop-types';
import { i18PAGES } from '../../../../config/i18n/constants';

const OptionsEmptyState = ({ text }) => {
    const { t } = useTranslation(i18PAGES.OPTIONS);
    return (
        <div className="flex items-center justify-center w-[100%] h-[600px]">
            <div className="w-[475px] text-center">
                <div className="mb-[24px] font-Lexend500 text-[24px] leading-[24px]">
                    {t('Start generate variations of options')}
                </div>
                <div className="font-Lexend400 text-[18px] leading-[28px]">{text}</div>
            </div>
        </div>
    );
};

export default OptionsEmptyState;

OptionsEmptyState.propTypes = {
    text: string,
};
