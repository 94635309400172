import React from 'react';
import { bool, func, string } from 'prop-types';
import { PrimaryButton } from '../../../style/Button/Button';
import { ReactComponent as EditIcon } from '../../../assets/icons/editIcon.svg';
import { useAuth } from '../../../store/hooks/useAuth';
import i18n from '../../../config/i18n/i18n';

const DescriptionAndButtonsBlock = ({
    isEditMode,
    offEditMode,
    onEditMode,
    descriptionText,
    handleSave,
    isSubmitting,
    showButtonCopy = false,
    handleCopy,
    openGraphModal,
    isGraphModalActive,
}) => {
    const { isEditablePagesProject } = useAuth();

    return (
        <div className="flex justify-between mt-[30px] w-[100%] mb-[40px]">
            <div className="flex font-Lexend400 text-[14px] text-int-white-main">
                <div className="w-[600px] min-h-[50px] flex items-center px-[16px] bg-[#29323F80] rounded-[8px]">
                    {descriptionText}
                </div>
            </div>

            {isEditablePagesProject && (
                <>
                    {isEditMode ? (
                        <div
                            className={`flex items-center ${
                                showButtonCopy ? 'justify-between' : 'justify-end'
                            } w-[100%] ml-[20px]`}
                        >
                            {showButtonCopy && (
                                <div className="flex items-center">
                                    <PrimaryButton
                                        className="h-[50px] mr-[10px] px-[32px] flex items-center"
                                        isGrey
                                        handleClick={handleCopy}
                                    >
                                        <div className="text-[18px] font-Lexend400 whitespace-nowrap ">
                                            {i18n.t('Duplicate information')}
                                        </div>
                                    </PrimaryButton>
                                    <div className="w-[230px] text-[12px] leading-[19px] text-int-white-secondary flex items-center">
                                        {i18n.t(
                                            'You can duplicate integrations from the step As-Is Integrations to this table'
                                        )}
                                    </div>
                                </div>
                            )}

                            <div className="flex">
                                <PrimaryButton
                                    className="w-[170px] h-[50px] ml-[20px] flex items-center"
                                    isGrey
                                    handleClick={offEditMode}
                                >
                                    <div className="text-[18px] font-Lexend400">{i18n.t('Close editing')}</div>
                                </PrimaryButton>

                                <PrimaryButton
                                    className="h-[50px] px-[32px] ml-[20px] flex items-center min-w-[170px]"
                                    isPink
                                    handleClick={handleSave}
                                    disabled={isSubmitting}
                                >
                                    <div className="text-[18px] font-Lexend400">{i18n.t('Save')}</div>
                                </PrimaryButton>
                            </div>
                        </div>
                    ) : (
                        <div className="flex items-center">
                            {openGraphModal && (
                                <PrimaryButton
                                    className="w-[170px] h-[50px] flex items-center"
                                    isGrey
                                    handleClick={openGraphModal}
                                    disabled={!isGraphModalActive}
                                >
                                    {i18n.t('Visualization')}
                                </PrimaryButton>
                            )}

                            <PrimaryButton
                                className="w-[170px] h-[50px] ml-[20px] flex items-center"
                                isGrey
                                handleClick={onEditMode}
                            >
                                <EditIcon />
                                <div className="ml-[5px] text-[18px] font-Lexend400">{i18n.t('Edit')}</div>
                            </PrimaryButton>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default DescriptionAndButtonsBlock;

DescriptionAndButtonsBlock.propTypes = {
    isEditMode: bool.isRequired,
    offEditMode: func.isRequired,
    onEditMode: func.isRequired,
    handleSave: func.isRequired,
    descriptionText: string.isRequired,
    isSubmitting: bool,
    showButtonCopy: bool,
    isGraphModalActive: bool,
    handleCopy: func,
    openGraphModal: func,
};
