import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { HEADERS } from '../../components/util/constants';

export const fteProcessAPI = createApi({
    reducerPath: 'fteProcessAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api',
        prepareHeaders: HEADERS,
    }),
    tagTypes: ['fteProcess'],
    endpoints: (build) => ({
        getFteProcess: build.query({
            query: ({ projectId, officeId }) => `/projects/${projectId}/offices/${officeId}/fte-process`,
            keepUnusedDataFor: 0.0001,
            providesTags: (result) =>
                result?.content
                    ? [
                          ...result.comments.content.map(({ id }) => ({ type: 'fteProcess', id })),
                          { type: 'fteProcess', id: 'LIST' },
                      ]
                    : [{ type: 'fteProcess', id: 'LIST' }],
        }),
        getFteFiltersOptions: build.query({
            query: ({ projectId }) => `/projects/${projectId}/fte-process/filters`,
            keepUnusedDataFor: 0.0001,
        }),
        getFteTable: build.query({
            query: ({ projectId }) => `/projects/${projectId}/fte-process`,
            keepUnusedDataFor: 0.0001,
        }),
        getFteGraph: build.mutation({
            query: ({ projectId, body }) => ({
                url: `/projects/${projectId}/fte-process/graph`,
                method: 'POST',
                body,
            }),
        }),
    }),
});

export const { useGetFteProcessQuery, useGetFteFiltersOptionsQuery, useGetFteTableQuery, useGetFteGraphMutation } =
    fteProcessAPI;
