import React from 'react';
import { bool, func, object } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { i18PAGES } from '../../../../../config/i18n/constants';
import OrganizationStructureEditItem from './OrganizationStructureEditItem';
import MarketSituationEditItem from './MarketSituationEditItem';
import StrategyEditItem from './StrategyEditItem';

const OrganizationStructureEditOnly = ({ setFieldValue, values, errors, isSubmitPressed = false }) => {
    const { t } = useTranslation(i18PAGES.COMPANIES_INFO);

    return (
        <div className="mt-[30px]">
            <div className="text-[24px] leading-[24px] font-Lexend500">{t('Organization structure')}</div>

            <div className="text-[14px] leading-[14px] mt-[20px] text-int-white-secondary font-Lexend400">
                {t('Fill this block to get a comment on current situation in the office')}
            </div>

            <div className="px-[10px] py-[30px] rounded-[8px] bg-int-gray-main mt-[30px] flex">
                <OrganizationStructureEditItem
                    setFieldValue={setFieldValue}
                    values={values}
                    errors={errors}
                    isSubmitPressed={isSubmitPressed}
                />
                <MarketSituationEditItem
                    setFieldValue={setFieldValue}
                    values={values}
                    errors={errors}
                    isSubmitPressed={isSubmitPressed}
                />
                <StrategyEditItem
                    setFieldValue={setFieldValue}
                    values={values}
                    errors={errors}
                    isSubmitPressed={isSubmitPressed}
                />
            </div>
        </div>
    );
};

export default OrganizationStructureEditOnly;

OrganizationStructureEditOnly.propTypes = {
    setFieldValue: func.isRequired,
    values: object.isRequired,
    errors: object.isRequired,
    isSubmitPressed: bool,
};
