import React from 'react';
import { useTranslation } from 'react-i18next';

const HomePage = () => {
    const { t } = useTranslation();

    return (
        <div className="min-h-[calc(100vh-300px)] h-[100%] flex flex-col items-center justify-center">
            <div>{t('Home content')}</div>
        </div>
    );
};

export default HomePage;
