import { COUNTRIES } from './countriesList';

export const findCountryOptions = () => {
    const countriesList = [];
    COUNTRIES.forEach((continent) =>
        continent.countries.forEach((country) => {
            countriesList.push({ label: country.name, value: country.name });
        })
    );
    return countriesList.sort((a, b) => a.label.localeCompare(b.label));
};

export const findCountryCode = (countryName) => {
    let countryCode = '';
    COUNTRIES.forEach((continent) =>
        continent.countries.forEach((country) => {
            if (country.name === countryName) {
                countryCode = country.code.toLowerCase();
            }
        })
    );
    return countryCode;
};
