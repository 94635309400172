import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { bool, func, number, string } from 'prop-types';
import { useAuth } from '../../store/hooks/useAuth';
import { i18PAGES } from '../../config/i18n/constants';
import { useGetCommentsQuery } from '../../store/API/commentsAPI';
import CommentsLoader from './CommentsLoader/CommentsLoader';
import CommentItem from './CommentItem';
import SendMessage from './SendMessage';
import { ReactComponent as CloseModalIcon } from '../../assets/icons/closeToastIcon.svg';

const CommentsContent = ({ isOpen, setIsOpen, pageIdentifier, subPageIdentifier, projectId }) => {
    const { profileData } = useAuth();

    const { t } = useTranslation(i18PAGES.COMPANIES_INFO);

    const [comments, setComments] = useState([]);
    const [pageNumber, setPageNumber] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const { data, isLoading, isFetching } = useGetCommentsQuery(
        { pageIdentifier, projectId, pageNumber, subPageIdentifier },
        { skip: !projectId || !pageIdentifier }
    );

    const onCloseHandler = () => {
        setIsOpen(false);
    };

    const scrollHandler = (e) => {
        if (e.target.scrollTop < 3) {
            e.target.scrollTop = 3;
        }

        if (e.target.scrollTop < 100 && comments?.length < totalCount && !isLoading && !isFetching) {
            setPageNumber((prev) => prev + 1);
        }
    };

    useEffect(() => {
        if (data?.comments?.content?.length > 0) {
            setTotalCount(data?.comments?.totalElements);

            if (pageNumber === 0) {
                setComments((prev) => {
                    const oldCommentIdArray = prev.map((c) => c.id);
                    const newComments = data?.comments?.content?.filter((com) => !oldCommentIdArray.includes(com.id));
                    return [...prev, ...newComments.reverse()];
                });
            }
            setComments((prev) => {
                const oldCommentIdArray = prev.map((c) => c.id);
                const newComments = data?.comments?.content?.filter((com) => !oldCommentIdArray.includes(com.id));
                return [...newComments.reverse(), ...prev];
            });
        }
    }, [data?.comments, pageNumber]);

    useEffect(() => {
        const messagesDiv = document.getElementById('primary-scroll');
        if (isOpen && pageNumber === 0 && !isLoading) {
            messagesDiv.scrollTop = messagesDiv.scrollHeight;
        }
    }, [isOpen, pageNumber, isLoading, comments?.length]);
    return (
        <div className="mt-[30px] top-[25px] w-[400px] bg-int-gray-main right-[-20px] absolute rounded-[8px] p-[30px] z-20 shadow-dropdown">
            <div className="flex justify-between items-center pb-[13px] border-b border-int-gray-secondary">
                <div className="text-[18px] leading-[18px] font-Lexend400 text-int-white-main">{t('Comments')}</div>
                <div
                    className="cursor-pointer flex items-center justify-center w-[25px] h-[25px]"
                    onClick={onCloseHandler}
                >
                    <CloseModalIcon />
                </div>
            </div>
            <div
                id="primary-scroll"
                className="mt-[5px] h-[380px] ml-[-30px] mr-[-30px] overflow-y-auto"
                onScroll={scrollHandler}
            >
                {(isLoading || isFetching) && (
                    <div className="flex h-[20px] items-center justify-center flex-row relative">
                        <div className="absolute top-[8px] h-[20px]">
                            <CommentsLoader />
                        </div>
                    </div>
                )}

                {comments?.length > 0 ? (
                    comments.map((message) => <CommentItem message={message} key={message.id} />)
                ) : (
                    <div className="text-int-white-secondary text-center text-[20px] font-Lexend500 flex items-center justify-center h-[100%] w-[100%]">
                        {t('There are no comments')}
                    </div>
                )}
            </div>
            <div className="mt-[18px]">
                <SendMessage
                    name={profileData?.fullName ? profileData?.fullName[0] : profileData?.email[0]}
                    pageIdentifier={pageIdentifier}
                    subPageIdentifier={subPageIdentifier}
                    projectId={projectId}
                    setPageNumber={setPageNumber}
                />
            </div>
        </div>
    );
};

export default CommentsContent;

CommentsContent.propTypes = {
    pageIdentifier: string.isRequired,
    subPageIdentifier: string,
    projectId: number,
    isOpen: bool.isRequired,
    setIsOpen: func.isRequired,
};
