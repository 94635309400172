import React, { useRef, useState } from 'react';
import { arrayOf, func, string, object } from 'prop-types';
import { Form, Formik } from 'formik';
import useOutsideAlerter from '../../../../util/useOutsideAlerter';
import { ReactComponent as ArrowBottomIcon } from '../../../../../assets/icons/bottom-arrow.svg';
import { ReactComponent as ArrowUpIcon } from '../../../../../assets/icons/up-arrow.svg';
import RadioInput from '../../../../../style/RadioInput/RadioInput';

export default function FTEFilterRadioDropdown({ name, className, setData, optionsList }) {
    const container = useRef(null);
    const [open, setOpen] = useState(false);
    useOutsideAlerter({ ref: container, callback: () => setOpen(false) });

    return (
        <Formik
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(false);
                setData(values.filter);
            }}
            initialValues={{ filter: optionsList[0].value }}
            enableReinitialize
        >
            {({ values, handleSubmit, setFieldValue }) => (
                <Form>
                    <div className={`relative ${className || ''}`} ref={container}>
                        <input
                            type="text"
                            name={name}
                            onClick={() => setOpen((prev) => !prev)}
                            placeholder=""
                            className={`border ${
                                open ? 'border-int-white-main' : 'border-[#2A3143]'
                            } cursor-pointer bg-[#2A3143] rounded-[4px] px-[8px] h-[38px] w-[100%]`}
                            aria-hidden="true"
                            autoComplete="off"
                            style={{
                                caretColor: 'transparent',
                                cursor: 'pointer',
                            }}
                            readOnly
                        />
                        {open && (
                            <div
                                className="absolute top-[calc(100%+8px)] bg-[#2A3143] w-full text-int-white-main text-[14px]
                 font-Lexend400 rounded z-50 max-h-[272px] pt-[8px] pb-[8px] pl-[16px] shadow-dropdown"
                            >
                                <div className="overflow-y-auto max-h-[240px]" id="primary-scroll">
                                    {optionsList?.map((item, index) => (
                                        <div className="py-[6px]" key={index}>
                                            <RadioInput
                                                setFieldValue={setFieldValue}
                                                name="filter"
                                                label={item.name}
                                                value={item.value}
                                                isChecked={values.filter === item.value}
                                                handleSubmit={handleSubmit}
                                                setOpen={setOpen}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                        <div className="pointer-events-none absolute right-[10px] top-[16px] flex items-center px-2">
                            {open ? <ArrowUpIcon fill="#EDF1FD" /> : <ArrowBottomIcon fill="#EDF1FD" />}
                        </div>
                        <div
                            className="absolute top-[6px] ml-[16px] cursor-pointer text-[14px] font-Lexend400"
                            onClick={() => setOpen((prev) => !prev)}
                        >
                            <div>{optionsList.find((i) => i.value === values.filter).name}</div>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    );
}

FTEFilterRadioDropdown.propTypes = {
    name: string,
    className: string,
    setData: func,
    optionsList: arrayOf(object),
};
