import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { bool, func } from 'prop-types';
import { ReactComponent as ArrowLeft } from '../../../../assets/icons/arrow-left.svg';
import WarningModal from '../../../../style/WarningModal/WarningModal';

const VariationOptionsBackButton = (props) => {
    const { navigateBack, isFormTouched } = props;
    const [isShowBackModal, setIsShowBackModal] = useState(false);

    const onCloseShowBackModal = useCallback(() => {
        setIsShowBackModal(false);
    }, []);

    const onBackButtonClick = useCallback(() => {
        if (isFormTouched) {
            setIsShowBackModal(true);
        } else {
            navigateBack();
        }
    }, [isFormTouched, navigateBack]);

    const { t } = useTranslation();

    return (
        <>
            <div
                className="text-[14px] leading-[18px] font-Lexend400 inline-flex items-center text-int-white-main cursor-pointer"
                onClick={onBackButtonClick}
            >
                <ArrowLeft fill="#EDF1FD" width={5} height={8} />
                <div className="ml-[13px]"> {t('Back')}</div>
            </div>
            {isShowBackModal && (
                <WarningModal
                    isOpen={isShowBackModal}
                    setIsOpen={setIsShowBackModal}
                    warningText={t('Are you sure you want to leave? Options that you selected will not be saved')}
                    headerText={t('Leave the page')}
                    acceptButtonText={t('Leave')}
                    declineButtonText={t('Cancel')}
                    accept={navigateBack}
                    decline={onCloseShowBackModal}
                />
            )}
        </>
    );
};

export default VariationOptionsBackButton;

VariationOptionsBackButton.propTypes = {
    navigateBack: func.isRequired,
    isFormTouched: bool,
};
