import React from 'react';
import { arrayOf, shape, string } from 'prop-types';
import HrDigitalizationTableLabels from '../HrDigitalizationTableLabels';
import HrDigitalizationReadItem from './HrDigitalizationReadItem';
import { hrDigitalizationsEmptyObject, weaknessExpectationsEmptyObject } from '../../Common/constants';

const HrDigitalizationReadMode = ({ hrDigitalizations }) => (
    <div className="bg-int-gray-main p-[28px] pr-[56px] rounded-[8px]">
        <HrDigitalizationTableLabels />
        {hrDigitalizations?.length > 0 &&
            hrDigitalizations?.map((p, index) => <HrDigitalizationReadItem key={index} hrDigitalizations={p} />)}
        {hrDigitalizations?.length === 0 && (
            <HrDigitalizationReadItem hrDigitalizations={hrDigitalizationsEmptyObject} />
        )}
    </div>
);

export default HrDigitalizationReadMode;

HrDigitalizationReadMode.propTypes = {
    hrDigitalizations: arrayOf(
        shape({
            stageName: string.isRequired,
            description: string.isRequired,
        }).isRequired
    ),
};
