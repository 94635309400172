import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { PAGE_IDENTIFIER } from '../components/util/constants';
import { i18PAGES } from '../config/i18n/constants';
import ProjectInfoPageHeader from '../components/Platform/Common/ProjectInfoPageHeader';
import { useGetFteFiltersOptionsQuery, useGetFteGraphMutation, useGetFteTableQuery } from '../store/API/fteProcessAPI';
import Preloader from '../components/Preloader/Preloader';
import PageNotAvailable from '../components/Platform/PageNotAvailable';
import FteResultsBlock from '../components/Platform/FTEProcess/FTEResultsBlock';
import FteToggleSwitcher from '../components/Platform/FTEProcess/FTEToggleSwitcher';
import FteNoDataBlock from '../components/Platform/FTEProcess/FTENoDataBlock';
import FteTableView from '../components/Platform/FTEProcess/FTETableView/FTETableView';
import FTEGraphView from '../components/Platform/FTEProcess/FTEGraphView/FTEGraphView';
import { useGetProgressWorkerQuery } from '../store/API/workersInformationAPI';
import useDebounce from '../components/util/useDebounce';
import { useToast } from '../hoc/toast/ToastProvider';

const FTEProcessPage = () => {
    const [searchParams] = useSearchParams();
    const projectId = searchParams.get('projectId');
    const { t } = useTranslation(i18PAGES.FTE_PROCESS);
    const { errorToast } = useToast();

    const [isTableView, setIsTableView] = useState(true);

    const { data: progressData, isLoading: isLoadingProgressData } = useGetProgressWorkerQuery(
        { projectId },
        { refetchOnMountOrArgChange: true, skip: !projectId }
    );

    const { data: filtersOptionsData, isLoading: isLoadingFiltersOptionsData } = useGetFteFiltersOptionsQuery(
        { projectId },
        { refetchOnMountOrArgChange: true, skip: !projectId }
    );

    const { data: fteTableData, isLoading: isLoadingFteTableData } = useGetFteTableQuery(
        { projectId },
        { refetchOnMountOrArgChange: true, skip: !projectId }
    );

    const [getFteGraph, { isLoading: isRequestFteGraphSending }] = useGetFteGraphMutation();

    const [isResetForm, setIsResetForm] = useState(false);

    const [filterDataCompanies, setFilterDataCompanies] = useState([]);
    const [forApiFilterDataCompanies, setForApiFilterDataCompanies] = useState([]);
    const [filterDataCountries, setFilterDataCountries] = useState([]);
    const [forApiFilterDataCountries, setForApiFilterDataCountries] = useState([]);
    const [filterDataCities, setFilterDataCities] = useState([]);
    const [forApiFilterDataCities, setForApiFilterDataCities] = useState([]);
    const [filterDataOffices, setFilterDataOffices] = useState([]);
    const [forApiFilterDataOffices, setForApiFilterDataOffices] = useState([]);
    const [sortData, setSortData] = useState('A_Z');

    const FilteredFormatForApiArray = (filterData, setForApiFilterData) => {
        setForApiFilterData(
            filterData?.filter((item) => item.isChecked === true).map((item) => ({ id: item.id, name: item.name }))
        );
    };

    const [firstRender, setFirstRender] = useState(true);

    useEffect(() => setFirstRender(false), []);

    useDebounce(
        () => {
            if (!firstRender) {
                FilteredFormatForApiArray(filterDataCompanies, setForApiFilterDataCompanies);
                FilteredFormatForApiArray(filterDataCountries, setForApiFilterDataCountries);
                FilteredFormatForApiArray(filterDataCities, setForApiFilterDataCities);
                FilteredFormatForApiArray(filterDataOffices, setForApiFilterDataOffices);
            }
        },
        [filterDataCompanies, filterDataCountries, filterDataCities, filterDataOffices],
        800
    );

    const clearFilters = () => {
        setIsResetForm(true);
        setFilterDataCompanies([]);
        setFilterDataCountries([]);
        setFilterDataCities([]);
        setFilterDataOffices([]);
    };

    const [fteGraphData, setFteGraphData] = useState([]);

    useEffect(() => {
        const sendData = {
            projectId,
            body: {
                companies: forApiFilterDataCompanies,
                countries: forApiFilterDataCountries.map((country) => country.name),
                cities: forApiFilterDataCities.map((city) => city.name),
                offices: forApiFilterDataOffices.map((office) => office.name),
                orderings: [
                    {
                        name: filtersOptionsData?.orderings.find((item) => item.value === sortData).name,
                        value: sortData,
                    },
                ],
            },
        };
        getFteGraph(sendData)
            .unwrap()
            .then((date) => {
                setFteGraphData(date);
            })
            .catch(() => {
                errorToast(i18n.t('Something went wrong, try again later'));
            });
    }, [
        forApiFilterDataCompanies,
        forApiFilterDataCountries,
        forApiFilterDataCities,
        forApiFilterDataOffices,
        sortData,
    ]);

    if (!projectId) {
        return <PageNotAvailable />;
    }

    return (
        <div className="pl-[50px] pb-[80px] pr-[25px] pt-[70px] min-h-[100vh] text-int-white-main">
            <ProjectInfoPageHeader
                nextLink="/select_provider"
                pageIdentifier={PAGE_IDENTIFIER.FTE_PROCESS}
                projectId={Number(projectId)}
                pageName={t('FTE process')}
            />

            <div className="w-[600px] h-[52px] mt-[30px] mb-[20px] flex items-center px-[16px] bg-[#29323F80] rounded-[8px]">
                {t('Explanation on how to fill in information on this tab')}
            </div>

            {isLoadingProgressData || isLoadingFiltersOptionsData || isLoadingFteTableData ? (
                <div className="flex items-center justify-center pt-[40px]">
                    <Preloader />
                </div>
            ) : (
                <>
                    <FteResultsBlock completed={progressData?.completed} all={progressData?.all} />
                    <div>
                        {fteTableData?.length === 0 ? (
                            <FteNoDataBlock projectId={projectId} />
                        ) : (
                            <div className="flex justify-between">
                                {isTableView ? (
                                    <FteTableView projectId={projectId} fteTableData={fteTableData} />
                                ) : (
                                    <FTEGraphView
                                        filtersOptionsData={filtersOptionsData}
                                        fteGraphData={fteGraphData}
                                        setFilterDataOffices={setFilterDataOffices}
                                        setFilterDataCities={setFilterDataCities}
                                        filterDataCountries={filterDataCountries}
                                        filterDataCities={filterDataCities}
                                        clearFilters={clearFilters}
                                        filterDataOffices={filterDataOffices}
                                        setFilterDataCompanies={setFilterDataCompanies}
                                        setSortData={setSortData}
                                        setIsResetForm={setIsResetForm}
                                        isResetForm={isResetForm}
                                        isRequestFteGraphSending={isRequestFteGraphSending}
                                        setFilterDataCountries={setFilterDataCountries}
                                        filterDataCompanies={filterDataCompanies}
                                    />
                                )}
                                <FteToggleSwitcher isTableView={isTableView} setIsTableView={setIsTableView} />
                            </div>
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

export default FTEProcessPage;
