import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { arrayOf, object, func, bool } from 'prop-types';
import { i18PAGES } from '../../../../config/i18n/constants';
import FteGraphViewPercentIndicator from './FTEGraphViewPercentIndicator';
import FteGraphViewItem from './FTEGraphViewItem';
import FteGraphViewFilter from './FTEGraphViewFilter/FTEGraphViewFilter';
import FteSelectedFilters from './FTEGraphViewFilter/FTESelectedFilters';
import { filteredArray } from '../fteConst';
import Preloader from '../../../Preloader/Preloader';
import EmptyStateTableFTE from '../EmptyStateTableFTE';

const FteGraphView = ({
    filtersOptionsData,
    fteGraphData,
    filterDataCompanies,
    filterDataCountries,
    filterDataCities,
    filterDataOffices,
    clearFilters,
    isResetForm,
    setIsResetForm,
    setFilterDataCompanies,
    setFilterDataCities,
    setFilterDataCountries,
    setFilterDataOffices,
    setSortData,
    isRequestFteGraphSending,
}) => {
    const { t } = useTranslation(i18PAGES.FTE_PROCESS);

    const maximumXGraph = useMemo(() => {
        const maxSumArr = fteGraphData?.length > 0 ? Math.max(...fteGraphData?.map((i) => i.value)) : 0;
        if (maxSumArr % 5 > 0) {
            return (Math.floor(maxSumArr / 5) + 1) * 5;
        }
        return maxSumArr;
    }, [fteGraphData]);

    const sumLength = useMemo(
        () =>
            filteredArray(filterDataCompanies).length +
            filteredArray(filterDataCountries).length +
            filteredArray(filterDataCities).length +
            filteredArray(filterDataOffices).length,
        [filterDataCompanies, filterDataCountries, filterDataCities, filterDataOffices]
    );

    const fteGraphDataFiltered = useMemo(() => fteGraphData?.filter((item) => item.value !== 0), [fteGraphData]);

    return (
        <div className="w-[1250px] p-[30px] bg-int-gray-main rounded-lg mr-[20px]">
            <div className="mb-[30px] text-[24px] leading-[24px] font-Lexend500">
                {t('Module priority - FTE based')}
            </div>
            <FteGraphViewFilter
                clearFilters={clearFilters}
                isResetForm={isResetForm}
                setIsResetForm={setIsResetForm}
                filterDataCompanies={filterDataCompanies}
                setFilterDataCompanies={setFilterDataCompanies}
                setFilterDataCities={setFilterDataCities}
                filterDataCities={filterDataCities}
                setFilterDataCountries={setFilterDataCountries}
                filterDataCountries={filterDataCountries}
                setFilterDataOffices={setFilterDataOffices}
                filterDataOffices={filterDataOffices}
                setSortData={setSortData}
                sumLength={sumLength}
                filtersOptionsData={filtersOptionsData}
            />

            <FteSelectedFilters
                clearFilters={clearFilters}
                filterDataCompanies={filterDataCompanies}
                setFilterDataCompanies={setFilterDataCompanies}
                setFilterDataCountries={setFilterDataCountries}
                filterDataCountries={filterDataCountries}
                filterDataCities={filterDataCities}
                setFilterDataCities={setFilterDataCities}
                filterDataOffices={filterDataOffices}
                setFilterDataOffices={setFilterDataOffices}
                sumLength={sumLength}
            />

            {isRequestFteGraphSending ? (
                <div className="flex justify-center items-center pb-[40px] pt-[40px]">
                    <Preloader />
                </div>
            ) : (
                <div>
                    {fteGraphDataFiltered?.length === 0 ? (
                        <EmptyStateTableFTE />
                    ) : (
                        <div>
                            <div>
                                {fteGraphDataFiltered.map((item, index) => (
                                    <FteGraphViewItem
                                        maximumXGraph={maximumXGraph}
                                        item={item}
                                        index={index}
                                        key={index}
                                    />
                                ))}
                            </div>

                            <FteGraphViewPercentIndicator maximumXGraph={maximumXGraph} />
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default FteGraphView;

FteGraphView.propTypes = {
    filtersOptionsData: object,
    fteGraphData: arrayOf(object),
    filterDataCompanies: arrayOf(object),
    filterDataCountries: arrayOf(object),
    filterDataCities: arrayOf(object),
    filterDataOffices: arrayOf(object),
    clearFilters: func,
    isResetForm: bool,
    setIsResetForm: func,
    setFilterDataCompanies: func,
    setFilterDataCities: func,
    setFilterDataCountries: func,
    setFilterDataOffices: func,
    setSortData: func,
    isRequestFteGraphSending: bool,
};
