import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { HEADERS } from '../../components/util/constants';

export const userPageAPI = createApi({
    reducerPath: 'userPageAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api',
        prepareHeaders: HEADERS,
    }),
    tagTypes: ['userPage'],
    endpoints: (build) => ({
        getUserPage: build.query({
            query: (projectId) => `/projects/${projectId}/users/pages`,
            keepUnusedDataFor: 0.0001,
        }),
        getPagesOptions: build.query({
            query: (projectId) => `/projects/${projectId}/pages/options`,
        }),
    }),
});

export const { useGetUserPageQuery, useGetPagesOptionsQuery } = userPageAPI;
