import React from 'react';
import { func, object } from 'prop-types';
import Checkbox from '../../../../style/Checkbox/Checkbox';
import FInput from '../../../../style/Input/Input';
import OnlyViewFieldInput from '../../../Profile/OnlyViewFieldInput';
import BbStatusItem from './BbStatusItem';
import { BUILDING_BLOCKS_STATUSES } from '../constants';

const BbCardContentEditMode = (props) => {
    const { values, touched, errors, setFieldValue, handleBlur, handleChange } = props;
    return (
        <>
            {values.buildingBlock?.tools?.map((tool, index) => (
                <div
                    className={`py-[20px] flex items-center ${
                        index !== values.buildingBlock.tools.length - 1 && 'border-b border-int-gray-secondary'
                    }`}
                    key={index}
                >
                    <div className="w-[260px] pr-[24px]">
                        <Checkbox
                            label={tool.name}
                            value={values?.buildingBlock?.tools[index]?.selected}
                            name={`buildingBlock.tools.${index}.selected`}
                            id={`buildingBlock.tools.${index}.selected`}
                            className="font-Lexend400 text-[16px] leading-[24px] text-int-white-main"
                        />
                    </div>
                    {values?.buildingBlock?.tools[index]?.selected === true ? (
                        <>
                            <FInput
                                name={`buildingBlock.tools.${index}.email`}
                                placeholder="Email"
                                value={values?.buildingBlock?.tools[index]?.email || ''}
                                handleBlur={handleBlur}
                                handleChange={handleChange}
                                className="w-[420px]"
                                type="text"
                                label=""
                                isError={
                                    !!(
                                        touched.buildingBlock?.tools[index]?.email &&
                                        errors.buildingBlock?.tools[index]?.email
                                    )
                                }
                                errorText={errors.buildingBlock?.tools[index]?.email}
                                setFieldValue={setFieldValue}
                            />
                            {tool.status !== BUILDING_BLOCKS_STATUSES.CREATED && (
                                <div className="flex flex-1 items-center justify-between">
                                    <BbStatusItem status={values?.buildingBlock?.tools[index]?.status} />
                                </div>
                            )}
                        </>
                    ) : (
                        <>
                            <div className="w-[420px] opacity-[0.6]">
                                <OnlyViewFieldInput label="" value={tool?.email || 'Email'} />
                            </div>
                            {tool.status !== BUILDING_BLOCKS_STATUSES.CREATED && (
                                <div className="flex flex-1 items-center justify-between">
                                    <BbStatusItem
                                        status={tool?.isRejected ? BUILDING_BLOCKS_STATUSES.REJECTED : tool?.status}
                                    />
                                </div>
                            )}
                        </>
                    )}
                </div>
            ))}
        </>
    );
};

export default BbCardContentEditMode;

BbCardContentEditMode.propTypes = {
    values: object,
    touched: object,
    errors: object,
    setFieldValue: func,
    handleBlur: func,
    handleChange: func,
};
