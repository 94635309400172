import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { func, number, oneOfType, string } from 'prop-types';
import { i18PAGES } from '../../../../config/i18n/constants';
import { useToast } from '../../../../hoc/toast/ToastProvider';
import { BUILDING_BLOCKS_STATUSES } from '../constants';
import {
    useRejectProviderMutation,
    useResendInvitationMutation,
    useRestoreProviderMutation,
} from '../../../../store/API/providerSelectionAPI';
import i18n from '../../../../config/i18n/i18n';
import DropdownItem from '../../../../style/DropdownItem/DropdownItem';

const BbActionSelector = (props) => {
    const { t } = useTranslation(i18PAGES.OPTIONS);
    const { successToast, errorToast } = useToast();
    const { status, projectId, buildingBlockId, toolId, refetchBuildingBlockInfo } = props;
    const [resendInvitation] = useResendInvitationMutation();
    const [rejectProvider] = useRejectProviderMutation();
    const [restoreProvider] = useRestoreProviderMutation();

    const onResendInvitation = useCallback(() => {
        resendInvitation({ projectId, buildingBlockId, toolId })
            .unwrap()
            .then(() => {
                successToast(t('Invitation was resent'), '', '', 2000);
                refetchBuildingBlockInfo();
            })
            .catch((err) => {
                if (err?.data?.message === 'Extend a deadline to resend an invitation.') {
                    errorToast(t('Extend a deadline to resend an invitation.'));
                } else {
                    errorToast(i18n.t('Something went wrong, try again later'));
                }
            });
    }, [buildingBlockId, errorToast, projectId, refetchBuildingBlockInfo, resendInvitation, successToast, t, toolId]);

    const onRejectInvitation = useCallback(() => {
        rejectProvider({ projectId, buildingBlockId, toolId })
            .unwrap()
            .then(() => {
                successToast(t('Provider was rejected'), '', '', 2000);
                refetchBuildingBlockInfo();
            })
            .catch(() => {
                errorToast(i18n.t('Something went wrong, try again later'));
            });
    }, [buildingBlockId, errorToast, projectId, refetchBuildingBlockInfo, rejectProvider, successToast, t, toolId]);

    const onRestoreProvider = useCallback(() => {
        restoreProvider({ projectId, buildingBlockId, toolId })
            .unwrap()
            .then(() => {
                successToast(t('Provider was restored'), '', '', 2000);
                refetchBuildingBlockInfo();
            })
            .catch(() => {
                errorToast(i18n.t('Something went wrong, try again later'));
            });
    }, [buildingBlockId, errorToast, projectId, refetchBuildingBlockInfo, restoreProvider, successToast, t, toolId]);

    const onSendInvitation = useCallback(() => {
        resendInvitation({ projectId, buildingBlockId, toolId })
            .unwrap()
            .then(() => {
                successToast(t('Invitation was sent'), '', '', 2000);
                refetchBuildingBlockInfo();
            })
            .catch((err) => {
                if (err?.data?.message === 'Extend a deadline to resend an invitation.') {
                    errorToast(t('Extend a deadline to resend an invitation.'));
                } else {
                    errorToast(i18n.t('Something went wrong, try again later'));
                }
            });
    }, [buildingBlockId, errorToast, projectId, refetchBuildingBlockInfo, resendInvitation, successToast, t, toolId]);

    const menuOptions = useMemo(() => {
        switch (status) {
            case BUILDING_BLOCKS_STATUSES.WAITING_FOR_RESPONSE:
                return [
                    {
                        text: t('Resend invitation'),
                        handleClick: onResendInvitation,
                    },
                    {
                        text: t('Reject Provider'),
                        handleClick: onRejectInvitation,
                    },
                ];
            case BUILDING_BLOCKS_STATUSES.DEADLINE_EXPIRED:
                return [
                    {
                        text: t('Resend invitation'),
                        handleClick: onResendInvitation,
                    },
                    {
                        text: t('Reject Provider'),
                        handleClick: onRejectInvitation,
                    },
                ];
            case BUILDING_BLOCKS_STATUSES.SAVED_RESULT:
                return [
                    {
                        text: t('Reject Provider'),
                        handleClick: onRejectInvitation,
                    },
                ];
            case BUILDING_BLOCKS_STATUSES.REJECTED:
                return [
                    {
                        text: t('Restore provider'),
                        handleClick: onRestoreProvider,
                    },
                    {
                        text: t('Send invitation'),
                        handleClick: onSendInvitation,
                    },
                ];
            case BUILDING_BLOCKS_STATUSES.REJECTED_BY_PROVIDER:
                return [
                    {
                        text: t('Resend invitation'),
                        handleClick: onResendInvitation,
                    },
                    {
                        text: t('Reject provider'),
                        handleClick: onRejectInvitation,
                    },
                ];
            default:
                return [];
        }
    }, [onRejectInvitation, onResendInvitation, onRestoreProvider, onSendInvitation, status, t]);

    return (
        <DropdownItem menuOptions={menuOptions} isIcon widthDropDown={180}>
            <div className="mr-[10px] text-[14px] leading-[14px] font-Lexend400">{t('Select action')}</div>
        </DropdownItem>
    );
};

export default BbActionSelector;

BbActionSelector.propTypes = {
    status: string.isRequired,
    projectId: oneOfType([string, number]),
    buildingBlockId: oneOfType([string, number]),
    toolId: oneOfType([string, number]),
    refetchBuildingBlockInfo: func,
};
