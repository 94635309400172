import React, { useEffect, useState } from 'react';
import { arrayOf, bool, func, number, shape, string } from 'prop-types';
import { FloatingFocusManager, FloatingPortal } from '@floating-ui/react';
import { ReactComponent as ArrowBottomIcon } from '../../../../assets/icons/bottom-arrow.svg';
import { ReactComponent as ArrowUpIcon } from '../../../../assets/icons/up-arrow.svg';
import { ReactComponent as ErrorIcon } from '../../../../assets/icons/errorIcon.svg';
import Checkbox from '../../../Checkbox/Checkbox';
import { useChangeDirectionDropdown } from '../../../../hooks/useChangeDirectionDropdown';

export default function TableListChooseDropdown({
    options,
    placeholder,
    handleBlur,
    name,
    isError,
    errorText,
    label,
    className,
    value,
    isSearch = true,
    handleFocus,
    setFieldValue,
    maxHeightDropDown = 360,
}) {
    const [isOpen, setIsOpen] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [optionsArr, setOptionsArr] = useState(options || []);
    const [isOpenHelpErrorInfo, setIsOpenHelpErrorInfo] = useState(false);

    const onDeleteClick = (itemValue) => {
        const otherValues = value.filter((item) => item !== itemValue);
        setFieldValue(name, otherValues);
    };

    const { getReferenceProps, getFloatingProps, getItemProps, refs, context, floatingStyles, onInputClick } =
        useChangeDirectionDropdown({
            isOpen,
            setIsOpen,
            maxHeightDropDown,
        });

    const onFocus = () => {
        if (handleFocus) {
            handleFocus();
        }
    };

    const handleClick = ({ label, optionValue }) => {
        if (!value.includes(optionValue)) {
            setFieldValue(name, [...value, optionValue]);
        } else {
            onDeleteClick(optionValue);
        }
    };

    const onSearch = (e) => {
        setSearchText(e.target.value);
    };

    useEffect(() => {
        setOptionsArr(options);
    }, [options]);

    useEffect(() => {
        const arr = options.filter((o) => o.label.toLowerCase().includes(searchText.toLowerCase()));
        setOptionsArr(arr);
    }, [searchText, errorText, isError, options]);

    const onBlur = (e) => {
        if (!isOpen) {
            handleBlur(e);
        }
    };

    return (
        <div className="flex items-center">
            <div className={`relative ${className || ''}`}>
                <div
                    className={`absolute left-0 top-[-20px] ${
                        isError ? 'text-error' : 'text-int-white-main'
                    } text-[14px] leading-[16px] font-Lexend400`}
                >
                    {label}
                </div>
                <div className="relative">
                    <input
                        {...getReferenceProps({
                            ref: refs.setReference,
                        })}
                        name={name}
                        type="text"
                        onClick={onInputClick}
                        className={`${className} min-w-[160px] border ${!isError && isOpen && 'border-int-white-main'}
                flex items-center bg-[#2A3143] py-[11px] appearance-none rounded-[4px] 
                text-int-grey-40 pl-[24px]`}
                        style={{
                            border: `${isError ? '1px solid #FF5F5F' : ''}`,
                            caretColor: `${!isSearch && 'transparent'}`,
                            cursor: `${!isSearch && 'pointer'}`,
                        }}
                        value={
                            searchText ||
                            (value.length > 0 ? `${value[0]} ${value.length > 1 ? `+ ${value.length - 1}` : ''}` : '')
                        }
                        placeholder={placeholder}
                        onChange={isSearch ? onSearch : () => {}}
                        onBlur={onBlur}
                        onFocus={onFocus}
                        role="presentation"
                        autoComplete="off"
                    />
                    {isOpen && (
                        <FloatingPortal>
                            <FloatingFocusManager context={context} modal={false}>
                                <ul
                                    ref={refs.setFloating}
                                    {...getFloatingProps()}
                                    id="primary-scroll"
                                    className="bg-[#2A3143] left-0 text-int-grey-40 rounded-[8px] z-50 max-h-[360px] overflow-auto shadow-dropdown"
                                    style={{ ...floatingStyles, outline: 'none' }}
                                >
                                    {optionsArr &&
                                        optionsArr.map(({ value: val, label, icon }, index) => (
                                            <li
                                                key={index}
                                                className="hover:bg-[#354053] py-[8px] px-[13px] cursor-pointer flex justify-between items-center text-int-white-main font-Lexend400"
                                                {...getItemProps({
                                                    onClick() {
                                                        handleClick({ label, optionValue: val });
                                                    },
                                                })}
                                            >
                                                <div>{label}</div>
                                                <Checkbox
                                                    name={name}
                                                    id={val}
                                                    value={value.includes(val)}
                                                    className="z-[-10]"
                                                />
                                            </li>
                                        ))}
                                </ul>
                            </FloatingFocusManager>
                        </FloatingPortal>
                    )}
                </div>

                <div className="pointer-events-none absolute right-[8px]">
                    {isOpen ? <ArrowUpIcon fill="#EDF1FD" /> : <ArrowBottomIcon fill="#EDF1FD" />}
                </div>
                {isError && (
                    <div className="absolute right-[25px] top-[14px]">
                        <div
                            className={errorText && 'cursor-pointer'}
                            onMouseEnter={() => setIsOpenHelpErrorInfo(true)}
                            onMouseLeave={() => setIsOpenHelpErrorInfo(false)}
                        >
                            <ErrorIcon />
                        </div>
                        {isOpenHelpErrorInfo && errorText && (
                            <div
                                className="text-[12px] text-error font-Lexend300 absolute
                     bg-[#222730] p-[10px] rounded-[2px] z-10 bottom-[23px] right-0 whitespace-nowrap"
                            >
                                {errorText}
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
}

TableListChooseDropdown.propTypes = {
    options: arrayOf(
        shape({
            value: string.isRequired,
            label: string.isRequired,
            icon: string,
        })
    ).isRequired,
    label: string,
    placeholder: string,
    name: string,
    isError: bool,
    isSearch: bool,
    errorText: string,
    className: string,
    handleBlur: func,
    handleFocus: func,
    value: arrayOf(string),
    setFieldValue: func.isRequired,
    maxHeightDropDown: number,
};
