import React, { useEffect, useState } from 'react';
import { FieldArray, Form, Formik } from 'formik';
import { func } from 'prop-types';
import { useTranslation } from 'react-i18next';
import StepLayout from './StepLayout';
import FInput from '../../style/Input/Input';
import { SETUP_PAGES, STEP_TYPE } from '../util/constants';
import { useCompanySetup } from '../../store/hooks/useCompanySetup';
import { step3InitialValues, step3ValidationSchema } from './stepValidation';
import ModalWindow from '../../style/ModalWindow/ModalWindow';
import ChangeStepInfoModalContent from './ChangeStepInfoModalContent';
import { useCreateCitiesMutation } from '../../store/API/companiesAPI';
import http from '../util/http';
import { i18PAGES } from '../../config/i18n/constants';
import i18n from '../../config/i18n/i18n';
import { useToast } from '../../hoc/toast/ToastProvider';

const Step3Cities = ({ setSetupCurrentPage }) => {
    const { companySetupInfo, setCities, clearOffices, setCompanyTree } = useCompanySetup();
    const [createCities] = useCreateCitiesMutation();
    const { errorToast } = useToast();

    const { t } = useTranslation(i18PAGES.SETUP_COMPANIES);

    const [isModalOpened, setIsModalOpened] = useState(false);
    const [isChangeAgree, setIsChangeAgree] = useState(false);

    const [isBackButtonPressed, setIsBackButtonPressed] = useState(false);
    const [isStepBackAgree, setIsStepBackAgree] = useState(false);

    const showModalOnFocusNewData = () => {
        if (companySetupInfo?.officesInfo?.length > 0 && !isChangeAgree) {
            setIsModalOpened(true);
        } else {
            setIsModalOpened(false);
        }
    };

    const onBackButtonPress = (cities) => {
        setIsBackButtonPressed(true);
        setCities(cities);
    };

    useEffect(() => {
        if (isStepBackAgree === true) {
            setSetupCurrentPage(SETUP_PAGES.STEP_2);
        }
    }, [isStepBackAgree, setSetupCurrentPage]);

    return (
        <StepLayout currentStepNumber={3} height={662} type={STEP_TYPE.COMPANIES}>
            <Formik
                onSubmit={async (values, { setSubmitting }) => {
                    if (
                        (companySetupInfo.companyTree?.countries[0]?.cities[0]?.offices?.length && isChangeAgree) ||
                        !companySetupInfo.companyTree?.countries[0]?.cities[0]?.offices?.length
                    ) {
                        const cities = values.cities.map((c) => ({ ...c, countryId: c.countryId || null }));

                        await createCities({
                            countryCities: cities,
                        })
                            .unwrap()
                            .then((data) => {
                                setCities(data.countryCities);

                                http.get(`/companies/${companySetupInfo.companyTree.id}`)
                                    .then((res) => {
                                        setCompanyTree(res.data);
                                        setSetupCurrentPage(SETUP_PAGES.STEP_4);
                                    })
                                    .catch((err) => {
                                        errorToast(err.response.data.message);
                                    })
                                    .finally(() => {
                                        setSubmitting(false);
                                    });
                            })
                            .catch((err) => {
                                errorToast(err.data.message);
                            });
                    } else {
                        setSubmitting(false);
                        setSetupCurrentPage(SETUP_PAGES.STEP_4);
                    }
                }}
                validationSchema={step3ValidationSchema}
                initialValues={{
                    cities: step3InitialValues(companySetupInfo),
                }}
            >
                {({ values, touched, errors, isSubmitting, handleSubmit, handleBlur, handleChange, setFieldValue }) => (
                    <Form onSubmit={handleSubmit}>
                        <div className="flex justify-between items-center">
                            <div className="font-Lexend500 text-[24px] leading-[24px] text-int-white-main">
                                {t('Setup Cities')}
                            </div>
                            <div>
                                <button
                                    className="text-int-white-main border border-int-gray-secondary rounded-[8px] w-[140px] h-[42px] font-Lexend400"
                                    type="button"
                                    onClick={() =>
                                        onBackButtonPress(
                                            values.cities.map((c) => ({ ...c, countryId: c.countryId || null }))
                                        )
                                    }
                                >
                                    {t('Go Back')}
                                </button>

                                <button
                                    className={`text-int-white-main border border-int-grapefruit bg-int-grapefruit ml-[20px] rounded-[8px] w-[140px] h-[42px] font-Lexend400 ${
                                        isSubmitting && 'opacity-[0.5]'
                                    }`}
                                    type="submit"
                                    disabled={isSubmitting}
                                >
                                    {t('Save & Next')}
                                </button>
                            </div>
                        </div>
                        <div className="text-[14px] leading-[22px] mt-[8px] mb-[20px]">
                            {t('All fields are required')}
                        </div>

                        <div id="primary-scroll" className="overflow-y-auto h-[500px] mr-[-20px]">
                            <FieldArray name="cities">
                                {() =>
                                    values.cities.map((item, index) => (
                                        <div
                                            key={index}
                                            className="mt-[40px]"
                                            style={{
                                                borderLeft: index % 2 === 0 ? '1px solid #FF4F66' : '1px solid #5679F6',
                                            }}
                                        >
                                            <div className="ml-[11px]">
                                                <div className="text-int-white-secondary font-Lexend400 text-[18px] leading-[22px]">
                                                    {item.countryName}
                                                </div>

                                                {item.cities.map((city, i) => {
                                                    const citiesErrors =
                                                        (errors.cities?.length &&
                                                            errors.cities[index]?.cities?.length &&
                                                            errors.cities[index].cities[i]) ||
                                                        {};

                                                    const citiesTouched =
                                                        (touched.cities?.length &&
                                                            touched.cities[index]?.cities?.length &&
                                                            touched.cities[index].cities[i]) ||
                                                        {};

                                                    return (
                                                        <div
                                                            className="mt-[40px] ml-[10px] flex justify-between"
                                                            key={i}
                                                        >
                                                            <FInput
                                                                name={`cities.${index}.cities.${i}.name`}
                                                                placeholder="City name"
                                                                value={values.cities[index].cities[i].name}
                                                                handleBlur={handleBlur}
                                                                handleChange={handleChange}
                                                                handleFocus={showModalOnFocusNewData}
                                                                className="w-[447px] mr-[20px]"
                                                                type="text"
                                                                label={`City ${i + 1}`}
                                                                isError={!!(citiesTouched.name && citiesErrors.name)}
                                                                errorText={citiesErrors.name}
                                                                setFieldValue={setFieldValue}
                                                                limit={50}
                                                            />

                                                            <FInput
                                                                name={`cities.${index}.cities.${i}.officesQuantity`}
                                                                placeholder="Enter offices quantity"
                                                                value={values.cities[index].cities[i].officesQuantity}
                                                                handleBlur={handleBlur}
                                                                handleChange={handleChange}
                                                                handleFocus={showModalOnFocusNewData}
                                                                className="w-[447px] mr-[20px]"
                                                                type="text"
                                                                label={`Quantity of offices in City ${i + 1}`}
                                                                isError={
                                                                    !!(
                                                                        citiesTouched.officesQuantity &&
                                                                        citiesErrors.officesQuantity
                                                                    )
                                                                }
                                                                errorText={citiesErrors.officesQuantity}
                                                                setFieldValue={setFieldValue}
                                                                limit={1}
                                                            />
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    ))
                                }
                            </FieldArray>
                        </div>
                    </Form>
                )}
            </Formik>

            <ModalWindow isOpen={isModalOpened} setIsOpen={setIsModalOpened}>
                <ChangeStepInfoModalContent
                    setIsAgree={setIsChangeAgree}
                    setIsModalOpened={setIsModalOpened}
                    clearNextSteps={clearOffices}
                />
            </ModalWindow>

            <ModalWindow isOpen={isBackButtonPressed} setIsOpen={setIsBackButtonPressed}>
                <ChangeStepInfoModalContent
                    setIsAgree={setIsStepBackAgree}
                    setIsModalOpened={setIsBackButtonPressed}
                    text={i18n.t('Are you sure you want to go back? Your current data can be lost')}
                />
            </ModalWindow>
        </StepLayout>
    );
};
export default Step3Cities;

Step3Cities.propTypes = {
    setSetupCurrentPage: func,
};
