import React from 'react';
import { FieldArray } from 'formik';
import { func, object } from 'prop-types';
import WeaknessExpectationsEditItem from './WeaknessExpectationsEditItem';
import WeaknessExpectationsTableLabels from '../WeaknessExpectationsTableLabels';
import ModulesStrategyAddLineButton from '../../Common/Modules&StrategyAddLineButton';
import { weaknessExpectationsEmptyObject } from '../../Common/constants';

const WeaknessExpectationsEditMode = ({ values, errors, touched, handleBlur, handleChange, setFieldValue }) => (
    <div className="bg-int-gray-main p-[28px] pr-[56px] rounded-[8px]">
        <WeaknessExpectationsTableLabels />
        <FieldArray
            name="weaknessExpectations"
            render={(ArrayHelpers) => (
                <>
                    {values.weaknessExpectations?.map((we, index) => {
                        const weaknessExpectationsErrors =
                            (errors.weaknessExpectations?.length && errors.weaknessExpectations[index]) || {};

                        const weaknessExpectationsTouched =
                            (touched.weaknessExpectations?.length && touched.weaknessExpectations[index]) || {};

                        return (
                            <WeaknessExpectationsEditItem
                                key={index}
                                weaknessExpectationsTouched={weaknessExpectationsTouched}
                                weaknessExpectationsErrors={weaknessExpectationsErrors}
                                handleBlur={handleBlur}
                                handleChange={handleChange}
                                ArrayHelpers={ArrayHelpers}
                                values={values}
                                index={index}
                                setFieldValue={setFieldValue}
                            />
                        );
                    })}
                    <ModulesStrategyAddLineButton
                        ArrayHelpers={ArrayHelpers}
                        pushObject={weaknessExpectationsEmptyObject}
                    />
                </>
            )}
        />
    </div>
);

export default WeaknessExpectationsEditMode;

WeaknessExpectationsEditMode.propTypes = {
    values: object.isRequired,
    errors: object.isRequired,
    touched: object.isRequired,
    handleBlur: func.isRequired,
    handleChange: func.isRequired,
    setFieldValue: func.isRequired,
};
