import React, { useMemo } from 'react';
import { array, arrayOf, bool, func, number, object, oneOfType, shape, string } from 'prop-types';
import FTableInput from './TableInputs/TableInput/TableInput';
import TableSearchDropDown from './TableInputs/TableSearchDropDown/TableSearchDropDown';
import TableListChooseDropdown from './TableInputs/TableListChooseDropdown/TableListChooseDropdown';

export const TABLE_EDIT_ROW_ITEM_TYPE = {
    SEARCH_DROPDOWN: 'SEARCH_DROPDOWN',
    INPUT: 'INPUT',
    LIST_CHOOSE_DROPDOWN: 'LIST_CHOOSE_DROPDOWN',
};
const TableEditItem = (props) => {
    const {
        type,
        handleChange,
        handleBlur,
        name,
        value,
        isError,
        errorText,
        options,
        setFieldValue,
        limit,
        isChangeOnValue,
    } = props;

    const children = useMemo(() => {
        if (type === TABLE_EDIT_ROW_ITEM_TYPE.INPUT) {
            return (
                <FTableInput
                    label=""
                    type="text"
                    placeholder="Type"
                    className="min-w-[204px]"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    name={name}
                    value={value}
                    isError={isError}
                    errorText={errorText}
                    setFieldValue={setFieldValue}
                    limit={limit}
                />
            );
        }
        if (type === TABLE_EDIT_ROW_ITEM_TYPE.SEARCH_DROPDOWN) {
            return (
                <TableSearchDropDown
                    options={options}
                    placeholder="Select"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    name={name}
                    value={value}
                    isError={isError}
                    errorText={errorText}
                    isAutoCompleteOff
                    isChangeOnValue={isChangeOnValue}
                />
            );
        }
        if (type === TABLE_EDIT_ROW_ITEM_TYPE.LIST_CHOOSE_DROPDOWN) {
            return (
                <TableListChooseDropdown
                    name={name}
                    className="w-[204px] flex justify-center items-center"
                    placeholder="Select"
                    value={value}
                    isError={isError}
                    errorText={errorText}
                    options={options}
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                />
            );
        }
        return <></>;
    }, [
        errorText,
        handleBlur,
        handleChange,
        isChangeOnValue,
        isError,
        limit,
        name,
        options,
        setFieldValue,
        type,
        value,
    ]);

    return (
        <td className="px-[6px] border-r-[1px] border-int-main-black text-left bg-int-gray-secondary">{children}</td>
    );
};

export default TableEditItem;

TableEditItem.propTypes = {
    type: string.isRequired,
    handleChange: func,
    handleBlur: func.isRequired,
    setFieldValue: func,
    name: string.isRequired,
    value: oneOfType([string, number, object, array]),
    isError: bool,
    errorText: string,
    options: arrayOf(
        shape({
            value: oneOfType([string, number]).isRequired,
            label: string.isRequired,
            icon: string,
        })
    ),
    limit: number,
    isChangeOnValue: bool,
};
