import React from 'react';
import { bool, object } from 'prop-types';
import OfficeInfoReadMode from './OfficeItemReadMode/OfficeInfoReadMode';
import OfficeItemEditMode from './OfficeItemEditMode/OfficeItemEditMode';

const OfficeInfoItem = ({ officeItem, isEditMode = false, isSubmitted, formik }) => (
    <>
        {isEditMode ? (
            <OfficeItemEditMode
                touched={formik.touched}
                handleBlur={formik.handleBlur}
                handleChange={formik.handleChange}
                values={formik.values}
                errors={formik.errors}
                setFieldValue={formik.setFieldValue}
                isSubmitted={isSubmitted}
            />
        ) : (
            <OfficeInfoReadMode officeItem={officeItem} />
        )}
    </>
);

export default OfficeInfoItem;

OfficeInfoItem.propTypes = {
    officeItem: object.isRequired,
    isEditMode: bool.isRequired,
    isSubmitted: bool.isRequired,
    formik: object.isRequired,
};
