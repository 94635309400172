import React, { useMemo } from 'react';
import { number, string } from 'prop-types';

const ICONS_COLORS = ['#5679F6', '#FF4F66', '#F18725', '#B056F6'];
const ALPHABET = 'abcdefghijklmnopqrstuvwxyz'.split('');
const CommentAvatar = ({ name, size = 24 }) => {
    const currentColor = useMemo(() => {
        const index = ALPHABET.findIndex((letter) => letter === name?.toLowerCase());
        if (index % 5 === 0) {
            return ICONS_COLORS[2];
        }
        if (index % 3 === 0) {
            return ICONS_COLORS[1];
        }
        if (index % 2 === 0) {
            return ICONS_COLORS[0];
        }
        return ICONS_COLORS[3];
    }, [name]);

    return (
        <div
            className="flex items-center justify-center text-[14px] rounded-full uppercase border border-int-white-secondary"
            style={{
                minWidth: `${size}px`,
                minHeight: `${size}px`,
                width: `${size}px`,
                height: `${size}px`,
                backgroundColor: currentColor,
            }}
        >
            {name}
        </div>
    );
};

export default CommentAvatar;

CommentAvatar.propTypes = {
    name: string.isRequired,
    size: number,
};
