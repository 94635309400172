import React from 'react';
import { useTranslation } from 'react-i18next';
import { object } from 'prop-types';
import ReadOnlyRadioItem from '../ReadOnlyRadioItem';
import { LineOrganizationType, OrganisationStructure } from '../../../../util/constants';
import { i18PAGES } from '../../../../../config/i18n/constants';

const OrganizationStructureReadItem = ({ office }) => {
    const { t } = useTranslation(i18PAGES.COMPANIES_INFO);
    return (
        <div className="pr-[30px] border-r border-int-gray-secondary">
            <div className="text-[14px] leading-[19px] font-Lexend600 uppercase">{t('Organisation structure')}</div>
            <div className="flex items-center mt-[30px]">
                <div>
                    <div>
                        <ReadOnlyRadioItem
                            label="Linienorganisation"
                            isActive={office?.organizationStructure === OrganisationStructure.LINE_ORGANIZATION}
                        />
                    </div>
                    <div className="mt-[16px]">
                        <ReadOnlyRadioItem
                            label="Matrixorganisation"
                            isActive={office?.organizationStructure === OrganisationStructure.MATRIX_ORGANISATION}
                        />
                    </div>
                    <div className="mt-[16px]">
                        <ReadOnlyRadioItem
                            label="Agile Organisation"
                            isActive={office?.organizationStructure === OrganisationStructure.AGILE_ORGANISATION}
                        />
                    </div>
                </div>
                <div className="ml-[80px]">
                    <div
                        className={`text-[14px] leading-[18px] font-Lexend600 ${
                            office?.lineOrganizationType &&
                            office?.organizationStructure === OrganisationStructure.LINE_ORGANIZATION
                                ? 'text-int-white-main'
                                : 'text-int-gray-secondary'
                        } `}
                    >
                        {t('Art der Linienorganisation')}
                    </div>
                    <div className="mt-[16px]">
                        <ReadOnlyRadioItem
                            label="Funktionale Organisation"
                            isEnabled={
                                !!office?.lineOrganizationType &&
                                office?.organizationStructure === OrganisationStructure.LINE_ORGANIZATION
                            }
                            isActive={
                                office?.lineOrganizationType === LineOrganizationType.FUNCTIONAL_ORGANIZATION &&
                                office?.organizationStructure === OrganisationStructure.LINE_ORGANIZATION
                            }
                        />
                    </div>
                    <div className="mt-[16px]">
                        <ReadOnlyRadioItem
                            label="Divisionale Organisation"
                            isEnabled={
                                !!office?.lineOrganizationType &&
                                office?.organizationStructure === OrganisationStructure.LINE_ORGANIZATION
                            }
                            isActive={office?.lineOrganizationType === LineOrganizationType.DIVISIONAL_ORGANIZATION}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OrganizationStructureReadItem;

OrganizationStructureReadItem.propTypes = {
    office: object.isRequired,
};
