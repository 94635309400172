import { node } from 'prop-types';
import { useSearchParams } from 'react-router-dom';
import PlatformLayout from '../Platform/PlatformLayout';
import ProjectInfoMenu from '../../components/ProjectInfoMenu/ProjectInfoMenu';
import { useShowMenu } from '../../store/hooks/useShowMenu';
import { useGetUserPageQuery } from '../../store/API/userPageAPI';
import { PageLoader } from '../../components/Preloader/Preloader';

const ProjectInfoLayout = ({ children }) => {
    const { showFullMenu } = useShowMenu();
    const [searchParams] = useSearchParams();
    const projectId = searchParams.get('projectId');

    const { data: userPageList, isLoading: isLoadingUserPageList } = useGetUserPageQuery(projectId, {
        refetchOnMountOrArgChange: true,
        skip: !projectId,
    });

    if (isLoadingUserPageList) return <PageLoader />;

    return (
        <PlatformLayout isFullWidth>
            <div className="m-auto -my-[15px]">
                <div className="flex min-h-[calc(calc(100vh-theme(spacing.header-height))-theme(spacing.platform-footer-height))]">
                    <div className={showFullMenu ? 'w-[theme(menuWidth.open)]' : 'w-[theme(menuWidth.close)]'}>
                        <ProjectInfoMenu userPageList={userPageList} />
                    </div>
                    <div className={showFullMenu ? 'w-[1620px]' : 'w-[1866px]'}>{children}</div>
                </div>
            </div>
        </PlatformLayout>
    );
};

export default ProjectInfoLayout;

ProjectInfoLayout.propTypes = {
    children: node.isRequired,
};
