export const getTableAsIsIntegrationsHeaderData = (t) => [
    {
        minWidth: 40,
        name: '',
    },
    {
        minWidth: 300,
        name: t('Outgoing building block'),
        isSortable: true,
        queryName: 'outgoingBuildingBlock',
    },
    {
        minWidth: 280,
        name: t('Ingoing building block'),
        isSortable: true,
        queryName: 'ingoingBuildingBlock',
    },
    {
        minWidth: 210,
        name: t('Outgoing app'),
        isSortable: true,
        queryName: 'outgoingApp',
    },
    {
        minWidth: 210,
        name: t('Ingoing app'),
        isSortable: true,
        queryName: 'ingoingApp',
    },
    {
        minWidth: 210,
        name: t('Data type'),
    },
    {
        minWidth: 210,
        name: t('Integration type'),
    },
    {
        minWidth: 210,
        name: t('Operation type'),
    },
    {
        minWidth: 210,
        name: t('Support effort (hours per week)'),
    },
    {
        minWidth: 210,
        name: t('Frequency'),
    },
    {
        minWidth: 210,
        name: t('Number of frequence'),
    },
];

export const INITIAL_AS_IS_INTEGRATIONS_VALUE_DATA = {
    outgoingBuildingBlock: {
        id: 0,
        name: '',
    },
    ingoingBuildingBlock: {
        id: 0,
        name: '',
    },
    outgoingApp: '',
    ingoingApp: '',
    dataType: '',
    integrationType: '',
    operationType: '',
    supportEffort: '',
    frequency: '',
    numberOfFrequency: 1,
};
