import { array, object, string } from 'yup';

export const validationStep1ProjectSchema = object({
    name: string().required('Project name should be stated').max(30, 'Project name should be max 30 characters'),
    description: string()
        .required('Project description should be stated')
        .max(300, 'Project description should be max 300 characters'),
    users: array().of(
        object().shape({
            label: string().required('User should be selected').min(1, 'User should be selected'),
        })
    ),
});
