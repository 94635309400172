import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { HEADERS } from '../../components/util/constants';

export const projectInfoAPI = createApi({
    reducerPath: 'projectInfoAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api',
        prepareHeaders: HEADERS,
    }),
    tagTypes: ['projectInfoList'],
    endpoints: (build) => ({
        officeDetailInfo: build.query({
            query: ({ officeId, projectId, isNeedRefetch }) => `projects/${projectId}/offices/${officeId}/details`,
            keepUnusedDataFor: 0.0001,
            providesTags: ['projectInfoList'],
        }),
        updateOfficeDetailInfo: build.mutation({
            query: ({ projectId, officeId, body }) => ({
                url: `/projects/${projectId}/offices/${officeId}`,
                method: 'PUT',
                body,
            }),
            invalidatesTags: [{ type: 'projectInfoList', id: 'LIST' }],
        }),
        buildingBlockInfo: build.query({
            query: (projectId) => `/building-blocks`,
        }),
    }),
});

export const { useOfficeDetailInfoQuery, useUpdateOfficeDetailInfoMutation, useBuildingBlockInfoQuery } =
    projectInfoAPI;
