import React, { useEffect, useMemo, useState } from 'react';
import { array as propArray, bool, func, number } from 'prop-types';
import { FieldArray, Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { array, object, string } from 'yup';
import i18n from 'i18next';
import CloseWarning from '../../../UserManagement/CloseWarning';
import { PrimaryButton } from '../../../../style/Button/Button';
import ModalWindow from '../../../../style/ModalWindow/ModalWindow';
import { ReactComponent as AddUserIcon } from '../../../../assets/icons/addIcon.svg';
import { i18PAGES } from '../../../../config/i18n/constants';
import InternalAddItem from './InternalAddItem';
import { generateKey } from '../../../util/generateKey';
import { useAddInternalUserMutation } from '../../../../store/API/projectInternalManagementAPI';
import { useToast } from '../../../../hoc/toast/ToastProvider';

const validationSchema = object().shape({
    users: array().of(
        object().shape({
            fullName: string().required('User should be selected'),
        })
    ),
});

const AddInternalUserModalForm = ({ isOpened, setIsOpened, usersOptions, chosenProjectId }) => {
    const { t } = useTranslation(i18PAGES.PROJECTS_MANAGEMENT);
    const { successToast, errorToast } = useToast();

    const [addInternalUser] = useAddInternalUserMutation();

    const [usedUsers, setUsedUsers] = useState([]);

    const unusedOptions = useMemo(
        () =>
            usersOptions?.filter((u) => {
                if (!usedUsers?.includes(u.value)) {
                    return u;
                }
                return undefined;
            }),
        [usedUsers, usersOptions]
    );

    useEffect(() => {
        setUsedUsers([]);
    }, [usersOptions]);

    useEffect(() => {
        if (isOpened === false) {
            setUsedUsers([]);
        }
    }, [isOpened]);

    const [isShowCloseWarning, setIsShowCloseWarning] = useState(false);

    const handleClickCancelModal = () => {
        if (isShowCloseWarning === false) {
            setIsShowCloseWarning(true);
        } else {
            setIsOpened(false);
            setIsShowCloseWarning(false);
        }
    };

    return (
        <ModalWindow
            isOpen={isOpened}
            setIsOpen={setIsOpened}
            handleClickClose={handleClickCancelModal}
            className="w-[511px]"
        >
            <div className="flex flex-col items-center">
                <div className="text-[24px] leading-[24px] font-Lexend500 text-int-white-main">{t('Add user')}</div>
                <Formik
                    onSubmit={(values, { setSubmitting }) => {
                        const sendingData = values.users.map((u) => ({
                            userId: u.id,
                            projectManager: u.projectManager,
                        }));

                        addInternalUser({
                            projectId: chosenProjectId,
                            body: { users: sendingData },
                        })
                            .unwrap()
                            .then((res) => {
                                successToast(i18n.t('User was successfully added'));
                                setIsOpened(false);
                            })
                            .catch((err) => {
                                errorToast(t('Error adding users!'));
                            })
                            .finally(() => {
                                setSubmitting(false);
                            });
                    }}
                    validationSchema={validationSchema}
                    initialValues={{
                        users: [
                            {
                                id: null,
                                fullName: '',
                                projectManager: false,
                                key: 0,
                            },
                        ],
                    }}
                >
                    {({ values, touched, errors, setFieldValue, handleSubmit, handleBlur, isSubmitting }) => (
                        <Form className="w-[100%] mt-[10px]">
                            <FieldArray
                                autoСomplete="off"
                                name="users"
                                render={(ArrayHelpers) => (
                                    <>
                                        <div
                                            className={` ${
                                                values.users?.length > 3 && 'overflow-y-auto'
                                            } pt-[20px] max-h-[500px] mr-[-20px] pr-[20px]`}
                                            id="primary-scroll"
                                        >
                                            {values.users.map((item, index) => {
                                                const usersErrors = (errors.users?.length && errors.users[index]) || {};
                                                const usersTouched =
                                                    (touched.users?.length && touched.users[index]) || {};

                                                return (
                                                    <div className="mt-[30px]" key={item.key}>
                                                        <InternalAddItem
                                                            values={values}
                                                            handleBlur={handleBlur}
                                                            setFieldValue={setFieldValue}
                                                            index={index}
                                                            options={unusedOptions}
                                                            usersTouched={usersTouched}
                                                            usersErrors={usersErrors}
                                                            setUsedUsers={setUsedUsers}
                                                            ArrayHelpers={ArrayHelpers}
                                                        />
                                                        <div className="w-[100%] h-[1px] bg-int-gray-secondary mt-[10px]" />
                                                    </div>
                                                );
                                            })}
                                        </div>

                                        <div className="w-[100%] flex justify-start pt-[20px] pb-[41px] relative">
                                            {values.users.length < 15 ? (
                                                <div
                                                    className="flex items-center cursor-pointer"
                                                    onClick={() =>
                                                        ArrayHelpers.push({
                                                            id: null,
                                                            fullName: '',
                                                            projectManager: false,
                                                            key: generateKey(values.users.length - 1),
                                                        })
                                                    }
                                                >
                                                    <div className="mr-[6px]">
                                                        <AddUserIcon width={16} height={16} fill="#9BA1B2" />
                                                    </div>
                                                    <div>{t('Add user')}</div>
                                                </div>
                                            ) : (
                                                <div className="h-[24px]" />
                                            )}

                                            {isShowCloseWarning && (
                                                <CloseWarning
                                                    styles="w-[451px]"
                                                    handleCloseClick={() => setIsShowCloseWarning(false)}
                                                />
                                            )}
                                        </div>
                                    </>
                                )}
                            />
                            <div className="w-[100%] flex justify-between">
                                <PrimaryButton
                                    isGrey
                                    className="w-[170px] h-[50px]"
                                    handleClick={handleClickCancelModal}
                                >
                                    <div className="text-[18px] font-Lexend400">{t('Cancel')}</div>
                                </PrimaryButton>
                                <PrimaryButton
                                    isPink
                                    className="w-[170px] h-[50px]"
                                    handleClick={handleSubmit}
                                    disabled={isSubmitting}
                                >
                                    <div className="text-[18px] font-Lexend400">{t('Add')}</div>
                                </PrimaryButton>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </ModalWindow>
    );
};

export default React.memo(AddInternalUserModalForm);

AddInternalUserModalForm.propTypes = {
    isOpened: bool.isRequired,
    setIsOpened: func.isRequired,
    usersOptions: propArray.isRequired,
    chosenProjectId: number,
};
