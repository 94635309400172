import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { arrayOf, bool, shape, string, number, func } from 'prop-types';
import CurrentSubscriptionsHeader from './CurrentSubscriptionsHeader';
import CurrentSubscriptionsItem from './CurrentSubscriptionsItem';
import { CURRENT_SUBSCRIPTIONS_ACTIONS_TYPE } from '../constants';
import TerminateCurrentSubscriptionsModal from './TerminateCurrentSubscriptionsModal';
import { i18PAGES } from '../../../config/i18n/constants';
import SubscriptionSelectDropdown from '../SubscriptionSelectDropdown';
import Preloader from '../../Preloader/Preloader';
import { useToast } from '../../../hoc/toast/ToastProvider';
import { useTerminateSubscriptionsMutation } from '../../../store/API/subscriptionsAPI';

const CurrentSubscriptionsTable = ({
    currentSubscriptions,
    isLoadingSubscriptionsList,
    refetchSubscriptionsList,
    renewSubscriptionsApi,
}) => {
    const { t } = useTranslation(i18PAGES.SUBSCRIPTION_MANAGEMENT);
    const { successToast, errorToast } = useToast();
    const [isOpenTerminateCurrentSubscription, setIsOpenTerminateCurrentSubscription] = useState(false);
    const [isOpenTerminateCurrentSelectedSubscription, setIsOpenTerminateCurrentSelectedSubscription] = useState(false);
    const [terminateCurrentSubscriptionId, setTerminateCurrentSubscriptionId] = useState(0);
    const [actionType, setActionType] = useState(CURRENT_SUBSCRIPTIONS_ACTIONS_TYPE.RENEW);
    const [isLoading, setIsLoading] = useState(false);

    const [terminateSubscriptions] = useTerminateSubscriptionsMutation();

    const openTerminateModal = (id) => {
        setTerminateCurrentSubscriptionId(id);
        setIsOpenTerminateCurrentSubscription(true);
    };

    const terminateSubscriptionsApi = (data) => {
        setIsLoading(true);
        terminateSubscriptions({
            body: {
                subscriptions: data,
            },
        })
            .unwrap()
            .then(() => {
                successToast(t('Subscription terminate success'));
                setIsOpenTerminateCurrentSubscription(false);
                setIsOpenTerminateCurrentSelectedSubscription(false);
                refetchSubscriptionsList();
            })
            .catch(() => {
                errorToast(t('Subscription terminate error'));
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <div className="w-[1020px] p-[30px] bg-int-gray-main rounded-lg mb-[30px]">
            <Formik
                onSubmit={(values, { setSubmitting }) => {
                    const checkedModulesIdArray = values
                        .filter((module) => module.isChecked === true)
                        .map((module) => module.subscriptionId);

                    if (actionType === CURRENT_SUBSCRIPTIONS_ACTIONS_TYPE.RENEW) {
                        renewSubscriptionsApi(checkedModulesIdArray);
                    }
                    if (actionType === CURRENT_SUBSCRIPTIONS_ACTIONS_TYPE.TERMINATE) {
                        terminateSubscriptionsApi(checkedModulesIdArray);
                    }

                    setSubmitting(false);
                }}
                initialValues={currentSubscriptions?.map((module) => ({ ...module, isChecked: false }))}
                enableReinitialize
            >
                {({ values, handleSubmit, setFieldValue }) => {
                    const selectAllModules = () => {
                        values.map((module, index) => setFieldValue(`${index}.isChecked`, true));
                    };

                    const noneSelectAllModules = () => {
                        values.map((module, index) => setFieldValue(`${index}.isChecked`, false));
                    };

                    const tableDataRender = () => {
                        if (isLoadingSubscriptionsList)
                            return (
                                <div className="h-[70px] flex items-center justify-center">
                                    <Preloader />
                                </div>
                            );
                        if (values?.length === 0)
                            return (
                                <div className="flex items-center justify-center pt-[20px] text-[16px]">
                                    {t(
                                        `You have no current subscriptions. Renew recent subscriptions or create a new one`
                                    )}
                                </div>
                            );
                        return values?.map((item, index) => (
                            <CurrentSubscriptionsItem
                                index={index}
                                length={values.length}
                                handleRenew={renewSubscriptionsApi}
                                openTerminateModal={openTerminateModal}
                                key={index}
                                values={values}
                            />
                        ));
                    };

                    return (
                        <Form>
                            <div className=" text-int-white-main mb-[40px] flex justify-between items-center">
                                <div className="text-[24px] leading-[24px] font-Lexend500">
                                    {t('Current subscriptions')}
                                </div>
                                <div className="flex items-center text-[14px] leading-[18px] font-Lexend500 text-int-white-main">
                                    {values?.filter((i) => i.isChecked === true).length > 0 && (
                                        <>
                                            <div
                                                className="cursor-pointer"
                                                onClick={() => {
                                                    setActionType(CURRENT_SUBSCRIPTIONS_ACTIONS_TYPE.RENEW);
                                                    handleSubmit();
                                                }}
                                            >
                                                {t('Renew')}
                                            </div>
                                            <div
                                                className="cursor-pointer text-int-grapefruit mx-[20px]"
                                                onClick={() => setIsOpenTerminateCurrentSelectedSubscription(true)}
                                            >
                                                {t('Terminate')}
                                            </div>
                                        </>
                                    )}
                                    {values?.length > 0 && (
                                        <SubscriptionSelectDropdown
                                            handleSelectAll={selectAllModules}
                                            handleNonSelectAll={noneSelectAllModules}
                                            disableAllItem={values?.filter((i) => i.isChecked === false).length === 0}
                                            disableNotAllItem={values?.filter((i) => i.isChecked === true).length === 0}
                                        />
                                    )}
                                </div>
                            </div>
                            <CurrentSubscriptionsHeader />
                            {tableDataRender()}
                            <TerminateCurrentSubscriptionsModal
                                isOneType
                                isOpenTerminateCurrentSubscription={isOpenTerminateCurrentSubscription}
                                setIsOpenTerminateCurrentSubscription={setIsOpenTerminateCurrentSubscription}
                                id={terminateCurrentSubscriptionId}
                                handleTerminateClickOne={terminateSubscriptionsApi}
                                isLoading={isLoading}
                            />
                            <TerminateCurrentSubscriptionsModal
                                isOneType={false}
                                isOpenTerminateCurrentSubscription={isOpenTerminateCurrentSelectedSubscription}
                                setIsOpenTerminateCurrentSubscription={setIsOpenTerminateCurrentSelectedSubscription}
                                handleTerminateClickTwo={() => {
                                    setActionType(CURRENT_SUBSCRIPTIONS_ACTIONS_TYPE.TERMINATE);
                                    handleSubmit();
                                }}
                                isLoading={isLoading}
                            />
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
};

export default CurrentSubscriptionsTable;

CurrentSubscriptionsTable.propTypes = {
    currentSubscriptions: arrayOf(
        shape({
            status: string,
            endDate: string,
            moduleId: number,
            moduleName: string,
            startDate: string,
            subscriptionId: number,
        })
    ),
    isLoadingSubscriptionsList: bool.isRequired,
    refetchSubscriptionsList: func.isRequired,
    renewSubscriptionsApi: func.isRequired,
};
