import React from 'react';
import { arrayOf, object, string, number } from 'prop-types';
import OfficeModuleItem from '../../../CreateProject/Step3CreateProject/OfficeModuleItem';
import OtherModulesHelper from '../../../CreateProject/Step3CreateProject/OtherModulesHelper';
import { stringShorter } from '../../../util/stringShorter';

const AggregationOfYearViewTableItem = ({ buildingBlockName, foundedYear, companies }) => {
    const visibleFilteredCompanies = companies.slice(0, 2);

    const notVisibleFilteredCompanies = companies.slice(2)?.map((company) => ({
        title: company.companyName,
        buildingBlocks: company.officeList?.map((office) => ({
            name: `${office.countryName}, ${office.cityName}, ${office.officeName}`,
        })),
    }));

    return (
        <tr className="h-[60px] border-b-[1px] border-int-main-black text-left">
            <td className="px-[10px] border-r-[1px] border-int-main-black">{buildingBlockName}</td>
            <td className="px-[10px] border-r-[1px] border-int-main-black">
                <div className="flex justify-between items-center w-[100%]">
                    <div className="flex">
                        {visibleFilteredCompanies?.map((company, index) => (
                            <OfficeModuleItem
                                name={stringShorter(company.companyName, 10)}
                                count={company.officeList?.length}
                                className="bg-int-gray-main mr-[6px]"
                                key={index}
                            />
                        ))}
                    </div>
                    <OtherModulesHelper notVisibleModules={notVisibleFilteredCompanies} />
                </div>
            </td>
            <td className="px-[10px] border-r-[1px] border-int-main-black">{foundedYear}</td>
        </tr>
    );
};

export default AggregationOfYearViewTableItem;

AggregationOfYearViewTableItem.propTypes = {
    buildingBlockName: string.isRequired,
    companies: arrayOf(object),
    foundedYear: number.isRequired,
};
