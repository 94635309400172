import React from 'react';
import { node, number, oneOf } from 'prop-types';
import StepView from './StepView';
import { STEP_TYPE } from '../util/constants';

const StepLayout = ({ currentStepNumber, height = 662, children, type }) => (
    <div className="w-[1019px] m-auto mt-[40px] mb-[30px]">
        <StepView currentStepNumber={currentStepNumber} type={type} />
        <div
            className="p-[30px] mt-[70px] bg-int-gray-main rounded-[8px]"
            style={{ height: height > 0 ? height : null }}
        >
            <div>{children}</div>
        </div>
    </div>
);

export default StepLayout;

StepLayout.propTypes = {
    currentStepNumber: number.isRequired,
    height: number,
    children: node.isRequired,
    type: oneOf([STEP_TYPE.COMPANIES, STEP_TYPE.PROJECT]).isRequired,
};
