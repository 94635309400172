import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { HEADERS } from '../../components/util/constants';

export const userModulesAPI = createApi({
    reducerPath: 'userModulesAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api',
        prepareHeaders: HEADERS,
    }),
    tagTypes: ['userModulesList'],
    endpoints: (build) => ({
        getBoughtUserModulesList: build.query({
            query: () => `/users/modules?statuses=BOUGHT,TERMINATED`,
            providesTags: (result) =>
                result
                    ? [
                          ...result.map(({ moduleId }) => ({ type: 'userModulesList', id: moduleId })),
                          { type: 'userModulesList', id: 'LIST' },
                      ]
                    : [{ type: 'userModulesList', id: 'LIST' }],
        }),
        getProjectBoughtUserModulesList: build.query({
            query: (projectId) => `/projects/${projectId}/users/modules?statuses=BOUGHT,TERMINATED`,
            providesTags: (result) =>
                result
                    ? [
                          ...result.map(({ moduleId }) => ({ type: 'userModulesList', id: moduleId })),
                          { type: 'userModulesList', id: 'LIST' },
                      ]
                    : [{ type: 'userModulesList', id: 'LIST' }],
        }),
    }),
});

export const { useGetBoughtUserModulesListQuery, useGetProjectBoughtUserModulesListQuery } = userModulesAPI;
