import React from 'react';
import { array, func, number, oneOfType, string } from 'prop-types';

const ProjectManagementTabSwitcher = ({ handleTabChoose, chosenId, itemsArray }) => (
    <div className="flex font-Lexend400 text-[16px] leading-[18px]">
        {itemsArray?.map((item, index) => (
            <div
                key={index}
                onClick={() => handleTabChoose(index)}
                className={`px-[16px] mr-[15px] border-b-[1px] cursor-pointer ${
                    index === chosenId
                        ? 'text-int-white-main border-int-white-main pb-[19px]'
                        : 'text-int-white-secondary border-int-gray-secondary'
                }`}
            >
                {item.name}
            </div>
        ))}
    </div>
);

export default ProjectManagementTabSwitcher;

ProjectManagementTabSwitcher.propTypes = {
    chosenId: oneOfType([string, number]),
    handleTabChoose: func.isRequired,
    itemsArray: array.isRequired,
};
