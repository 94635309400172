import { useSearchParams } from 'react-router-dom';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export function useSort() {
    const { t } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();
    const sortByFromUrl = searchParams.get('sortBy');
    const pageFromUrl = searchParams.get('page');
    const orderFromUrl = searchParams.get('order');

    const getMenuOptions = useCallback(
        (sortName, isDateSorting = false) => [
            {
                text: isDateSorting ? t('Old to new') : t('Alphabetically, A-Z'),
                handleClick: () => {
                    searchParams.set('order', 'ASC');
                    searchParams.set('sortBy', sortName);
                    setSearchParams(searchParams);
                },
            },
            {
                text: isDateSorting ? t('New to old') : t('Alphabetically, Z-A'),
                handleClick: () => {
                    searchParams.set('order', 'DESC');
                    searchParams.set('sortBy', sortName);
                    setSearchParams(searchParams);
                },
            },
            {
                text: t('By defalut'),
                handleClick: () => {
                    searchParams.delete('order');
                    searchParams.delete('sortBy');
                    setSearchParams(searchParams);
                },
            },
        ],
        [searchParams, setSearchParams, t]
    );

    return { pageFromUrl, sortByFromUrl, orderFromUrl, getMenuOptions };
}
