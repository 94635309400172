import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Pagination from '../style/Pagination/Pagination';
import HRToolsFilterBlock from '../components/HRTools/HRToolsFilterBlock';
import { SearchInput } from '../style/Input/Input';
import Breadcrumb from '../style/Breadcrumb/Breadcrumb';
import HRToolsCard from '../components/HRTools/HRToolsCard';
import { ReactComponent as HRDeleteIcon } from '../assets/icons/HRDeleteIcon.svg';
import { ReactComponent as HRCompareIcon } from '../assets/icons/HRCompareIcon.svg';
import HRCompareImg1 from '../assets/temp/comparehr1.png';
import HRCompareImg2 from '../assets/temp/comparehr2.png';
import HRGetConsultationBlock from '../components/HRTools/HRGetConsultationBlock';
import HRAddCompareBlock from '../components/HRTools/HRAddCompareBlock';
import HRCompareItem from '../components/HRTools/HRCompareItem';
import { i18PAGES } from '../config/i18n/constants';
import useDebounce from '../components/util/useDebounce';
import FunctionalityBlockAccordion from '../components/HRTools/FunctionalityBlockAccordion';
import ToolModulePromoteBlockAccordion from '../components/HRTools/ToolModulePromoteBlockAccordion';
import ToolModuleDetailBlockAccordeon from '../components/HRTools/ToolModuleDetailBlockAccordeon';
import { useHrAllBuildingBlocksQuery, useHrListQuery } from '../store/API/hrAPI';
import { HR_PAGINATION_PAGE_SIZE } from '../components/util/constants';
import { PageLoader } from '../components/Preloader/Preloader';
import { ReactComponent as SearchFolderIcon } from '../assets/icons/searchFolderIcon.svg';

const BreadcrumbArray = [{ name: 'Übersicht HR Tools', path: '/hr' }];
const HRToolsPage = () => {
    const { t } = useTranslation(i18PAGES.HR_TOOLS);
    const navigate = useNavigate();

    const [searchText, setSearchText] = useState('');
    const [filterData, setFilterData] = useState([]);

    const [activePage, setActivePage] = useState(1);
    const [forApiSearchText, setForApiSearchText] = useState('');
    const [forApiFilterData, setForApiFilterData] = useState('');

    const { data: hrAllBuildingBlocks, isLoading: isLoadingHrAllBuildingBlocks } = useHrAllBuildingBlocksQuery({
        refetchOnMountOrArgChange: true,
    });

    const { data: hrList, isLoading: isLoadingHrList } = useHrListQuery(
        { page: activePage - 1, ids: forApiFilterData, search: forApiSearchText },
        {
            refetchOnMountOrArgChange: true,
        }
    );

    const moveToHrToolInfo = (id) => {
        navigate(`/hr/${id}`);
    };

    // todo favorite functional
    const clickFavoriteToolInfo = (id) => {
        console.log('click favorite', id);
    };

    useDebounce(
        () => {
            if (searchText.length > 1 || searchText === '') {
                setForApiSearchText(searchText);
                setActivePage(1);
            }
        },
        [searchText],
        800
    );

    useDebounce(
        () => {
            setForApiFilterData(
                filterData
                    ?.filter((item) => item.isChecked === true)
                    .map((item) => item.id)
                    .join()
            );
            setActivePage(1);
        },
        [filterData],
        800
    );

    if (isLoadingHrAllBuildingBlocks || isLoadingHrList) return <PageLoader />;

    return (
        <div className="flex flex-col py-[50px] min-w-[1440px] w-[100%] px-[130px]">
            <div className="flex justify-between items-center mb-[50px]">
                <Breadcrumb breadCrumbDetail={BreadcrumbArray} />
                <SearchInput
                    inputText={searchText}
                    setInputText={setSearchText}
                    type="text"
                    placeholder="Tool suchen"
                    className="w-[410px]"
                />
            </div>
            <div className="flex justify-between">
                <div className="max-w-[287px] w-[100%] mr-[0px]">
                    <HRToolsFilterBlock setFilterData={setFilterData} hrAllBuildingBlocks={hrAllBuildingBlocks} />
                </div>
                <div className="w-[100%] max-w-[846px]">
                    {hrList?.content?.length > 0 ? (
                        <div className="grid grid-cols-3 gap-x-[33px] gap-y-[40px] mb-[40px] min-h-[716px]">
                            {hrList?.content?.map((hr) => (
                                <HRToolsCard
                                    key={hr.id}
                                    image={hr.imageUrls[0]}
                                    handleClickInfo={() => moveToHrToolInfo(hr.id)}
                                    handleClickFavorite={() => clickFavoriteToolInfo(hr.id)}
                                    title={hr.name}
                                    description={hr.description}
                                    isFavorite={hr.isFavorite}
                                />
                            ))}
                        </div>
                    ) : (
                        <div className="flex flex-col items-center text-[18px] leading-[30px] font-Lexend400 text-int-white-secondary">
                            <div className="mt-[140px] mb-[24px]">
                                <SearchFolderIcon width={74} height={74} />
                            </div>
                            <div>{t('No results match.')}</div>
                            <div>{t('Try to use more general words or remove some filters')}</div>
                        </div>
                    )}

                    <div className="flex justify-center mt-[10px]">
                        <Pagination
                            count={hrList?.totalElements}
                            pageSize={HR_PAGINATION_PAGE_SIZE}
                            activePage={activePage}
                            setActivePage={setActivePage}
                        />
                    </div>
                </div>
            </div>
            <div className="flex justify-between items-center mt-[70px] mb-[70px] text-[24px] font-Lexend500 text-int-white-main">
                <div>{t('Vergleichsliste')}</div>
                <div className="flex text-[16px] font-Lexend400">
                    <div className="flex justify-center items-center rounded-lg pl-[24px] pr-[32px] h-[44px] bg-int-gray-secondary cursor-pointer">
                        <HRDeleteIcon />
                        <div className="ml-[16px]">{t('Alle Tools löschen')}</div>
                    </div>

                    <div className="flex justify-center items-center rounded-lg pl-[24px] pr-[32px] h-[44px] bg-int-gray-secondary cursor-pointer ml-[30px]">
                        <HRCompareIcon />
                        <div className="ml-[16px]">{t('Nur Unterschiede')}</div>
                    </div>
                </div>
            </div>
            {/* todo code underline will be change */}
            {/* todo condition rendering */}
            {1 > 0 ? (
                <>
                    <div className="flex justify-between mb-[30px] min-w-[1140px]">
                        <HRCompareItem title="Bamboo HR Software with Heart" image={HRCompareImg1} />
                        <HRCompareItem title="Namely makes life easier" image={HRCompareImg2} />
                        <HRCompareItem addLinkBlock />
                    </div>

                    <FunctionalityBlockAccordion />
                    <ToolModulePromoteBlockAccordion />

                    <ToolModuleDetailBlockAccordeon />
                </>
            ) : (
                <HRAddCompareBlock />
            )}
            <HRGetConsultationBlock />
        </div>
    );
};

export default HRToolsPage;
