import React, { useState } from 'react';
import PhotoItem from './PhotoItem';
import { ReactComponent as RightArrow } from '../../../assets/icons/right-small-arrow.svg';
import { ReactComponent as LeftArrow } from '../../../assets/icons/left-small-arrow.svg';

const TempPhotoArray = [
    {
        id: 1,
        url: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSzh5p6XbuaraxKhSnBFl_aRscNNE80hb9HEkWtkOrKlA&s',
    },
    {
        id: 2,
        url: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQusqYU3_XYaEiyzWVAUnsrTfTIKPx2JZyAyZo9rCyURA&s',
    },
    {
        id: 3,
        url: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQuwMN7e3bn9Pr3eKif2iLvTUeFRSy3wZ-jC8DToR3d8hAZlP3wbEBuirZkJsQG0BDHuvs&usqp=CAU',
    },
    {
        id: 4,
        url: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQfLav626znen5yeT7TlCwi0Ip-PQbjIhaGggDfvhUn0X-3NC8qOG_uoOVq92KJaKj_zFs&usqp=CAU',
    },
    {
        id: 5,
        url: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS9NXje0bJu3JDf-AdE88RrwM8Xz4q3iyMVxmnMKRhBOTKP1LHqtS4O2NA1Om9Y8A8xUKM&usqp=CAU',
    },
];

const PhotoViewer = () => {
    const [chosenId, setIsChosenId] = useState(TempPhotoArray[0].id);

    const chooseImage = (photoId) => {
        setIsChosenId(photoId);
    };

    const onLeftArrowClick = () => {
        if (currentPhoto[0].id !== 1) {
            setIsChosenId(currentPhoto[0].id - 1);
        }
    };

    const onRightArrowClick = () => {
        if (currentPhoto[0].id !== 5) {
            setIsChosenId(currentPhoto[0].id + 1);
        }
    };

    const currentPhoto = TempPhotoArray.filter((photo) => photo.id === chosenId);

    return (
        <>
            <div className="relative">
                <div
                    className="w-[100%] h-[240px] mb-[10px] relative"
                    style={{
                        backgroundImage: `url(${currentPhoto[0].url})`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                    }}
                />

                <div className="absolute left-[-22px] top-[50%] cursor-pointer" onClick={onLeftArrowClick}>
                    <LeftArrow fill={currentPhoto[0].id === 1 ? '#9BA1B2' : '#EDF1FD'} />
                </div>

                <div className="absolute right-[-22px] top-[50%] cursor-pointer" onClick={onRightArrowClick}>
                    <RightArrow fill={currentPhoto[0].id === 5 ? '#9BA1B2' : '#EDF1FD'} />
                </div>
            </div>
            <div className="flex justify-between">
                {TempPhotoArray.map((photo) => (
                    <PhotoItem
                        key={photo.id}
                        url={photo.url}
                        isActive={chosenId === photo.id}
                        chooseImage={() => chooseImage(photo.id)}
                    />
                ))}
            </div>
        </>
    );
};

export default PhotoViewer;
