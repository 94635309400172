import React from 'react';
import HRcompareListItem from './HRcompareListItem';
import HRCompareInfoAccordion from './HRCompareInfoAccordion';

const FunctionalityBlockAccordion = () => (
    <HRCompareInfoAccordion headerText="Funktionalität">
        <div className="grid grid-cols-3 gap-x-[60px] min-w-[1140px] text-[16px] font-Lexend300 leading-[24px] text-int-white-secondary">
            <div className="pl-[20px]">
                <HRcompareListItem title="Recruiting" />
                <HRcompareListItem title="Payroll" />
                <HRcompareListItem title="Learning" />
                <HRcompareListItem title="Analytics & Reporting" />
            </div>
            <div className="pl-[20px]">
                <HRcompareListItem title="Recruiting" />
                <HRcompareListItem title="Payroll" />
                <HRcompareListItem title="Analytics & Reporting" />
            </div>
            <div className="pl-[20px]">
                <HRcompareListItem title="Recruiting" />
                <HRcompareListItem title="Case Management" />
                <HRcompareListItem title="Digitale Personalakte" />
            </div>
        </div>
    </HRCompareInfoAccordion>
);

export default FunctionalityBlockAccordion;
