import React from 'react';
import { Link } from 'react-router-dom';
import { string } from 'prop-types';
import { PrimaryButton } from '../../style/Button/Button';
import { ReactComponent as SuccessIcon } from '../../assets/icons/success-big-icon.svg';

const SuccessConfirmation = ({ text, linkPath, buttonText }) => (
    <div className="bg-int-gray-main w-[580px] p-[30px] flex flex-col items-center rounded-[20px]">
        <SuccessIcon />
        <div className="mt-[30px] text-int-white-main font-Lexend500 text-[30px]">Success</div>
        <div className="mt-[10px] w-[350px] text-center leading-[24px] font-Lexend500 text-int-white-main mb-[50px]">
            {text}
        </div>
        <Link to={linkPath}>
            <PrimaryButton className="h-[50px] w-[420px]" isPink>
                {buttonText}
            </PrimaryButton>
        </Link>
    </div>
);

export default SuccessConfirmation;

SuccessConfirmation.propTypes = {
    text: string.isRequired,
    linkPath: string.isRequired,
    buttonText: string.isRequired,
};
