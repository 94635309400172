import React, { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { SETUP_PAGES } from '../components/util/constants';
import Step1Company from '../components/SetupCompanies/Step1Company';
import Step2Countries from '../components/SetupCompanies/Step2Countries';
import Step3Cities from '../components/SetupCompanies/Step3Cities';
import Step4Offices from '../components/SetupCompanies/Step4Offices/Step4Offices';
import { LayoutLoader } from '../components/Preloader/Preloader';
import { useCompanySetup } from '../store/hooks/useCompanySetup';
import { useGetCompaniesQuery } from '../store/API/companiesAPI';

const SetupCompaniesPage = () => {
    const [searchParams] = useSearchParams();
    const companyId = searchParams.get('companyId');

    const [setupCurrentPage, setSetupCurrentPage] = useState(SETUP_PAGES.STEP_1);
    const [isStartScreenSelected, setIsStartScreenSelected] = useState(false);

    const { setCompanyTree, setOffices } = useCompanySetup();

    const { data, isLoading, isFetching } = useGetCompaniesQuery({}, { refetchOnMountOrArgChange: true });

    const currentCompany = useMemo(() => {
        if (data?.length > 0) {
            if (companyId) {
                return data.filter((company) => company.id === Number(companyId))[0];
            }

            return null;
        }
    }, [companyId, data]);

    useEffect(() => {
        if (!isStartScreenSelected && currentCompany?.id) {
            setCompanyTree(currentCompany);

            const readyCountries = [];
            for (let i = 0; i < currentCompany?.countries?.length; i++) {
                const filledCityOffices = [];
                for (let j = 0; j < currentCompany?.countries[i]?.cities?.length; j++) {
                    if (currentCompany?.countries[i]?.cities[j]?.offices?.length) {
                        filledCityOffices.push(currentCompany?.countries[i]?.cities[j].id);
                    }
                }

                if (
                    filledCityOffices?.length !== 0 &&
                    filledCityOffices?.length === currentCompany?.countries[i]?.cities?.length
                ) {
                    readyCountries.push(currentCompany?.countries[i].id);
                }
            }

            if (readyCountries?.length !== 0 && readyCountries?.length === currentCompany?.countries?.length) {
                setCompanyTree({});
                setSetupCurrentPage(SETUP_PAGES.STEP_1);
                setIsStartScreenSelected(true);
            } else if (readyCountries?.length !== currentCompany?.countries?.length && readyCountries?.length > 0) {
                setOffices(['1']);
                setSetupCurrentPage(SETUP_PAGES.STEP_4);
                setIsStartScreenSelected(true);
            } else if (currentCompany?.countries?.length > 0) {
                setSetupCurrentPage(SETUP_PAGES.STEP_3);
                setIsStartScreenSelected(true);
            } else if (currentCompany?.countries?.length === 0) {
                setSetupCurrentPage(SETUP_PAGES.STEP_2);
                setIsStartScreenSelected(true);
            }
        }
    }, [isStartScreenSelected, setupCurrentPage, currentCompany, currentCompany?.id, data]);

    if (isLoading || isFetching || (!isStartScreenSelected && currentCompany?.id)) {
        return <LayoutLoader />;
    }

    switch (setupCurrentPage) {
        case SETUP_PAGES.STEP_1:
            return <Step1Company setSetupCurrentPage={setSetupCurrentPage} />;
        case SETUP_PAGES.STEP_2:
            return <Step2Countries setSetupCurrentPage={setSetupCurrentPage} />;
        case SETUP_PAGES.STEP_3:
            return <Step3Cities setSetupCurrentPage={setSetupCurrentPage} />;
        case SETUP_PAGES.STEP_4:
            return <Step4Offices setSetupCurrentPage={setSetupCurrentPage} />;
        default:
            return <></>;
    }
};

export default SetupCompaniesPage;
