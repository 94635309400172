import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isAuth: false,
    isPending: true,
    profileData: null,
    isPermission: true,
    isServerError: false,
    isEditableListProject: false,
    isEditablePagesProject: false,
    projectRole: null,
    isProjectRoleLoading: false,
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setAuth(state, action) {
            state.isAuth = action.payload;
        },
        setPending(state, action) {
            state.isPending = action.payload;
        },
        setProfileData(state, action) {
            state.profileData = action.payload;
        },
        setIsPermission(state, action) {
            state.isPermission = action.payload;
        },
        setIsEditableListProject(state, action) {
            state.isEditableListProject = action.payload;
        },
        setIsEditablePagesProject(state, action) {
            state.isEditablePagesProject = action.payload;
        },
        setProjectRole(state, action) {
            state.projectRole = action.payload;
        },
        setServerError(state, action) {
            state.isServerError = action.payload;
        },
        setIsProjectRoleLoading(state, action) {
            state.isProjectRoleLoading = action.payload;
        },
    },
});

export const {
    setAuth,
    setPending,
    setProfileData,
    setIsPermission,
    setIsEditableListProject,
    setIsEditablePagesProject,
    setProjectRole,
    setServerError,
    setIsProjectRoleLoading,
} = authSlice.actions;

export default authSlice.reducer;
