import React from 'react';
import { arrayOf, shape, string } from 'prop-types';
import ChallengesTableLabels from '../ChallengesTableLabels';
import ChallengesReadItem from './ChallengesReadItem';
import { challengesEmptyObject } from '../../Common/constants';

const ChallengesReadMode = ({ challenges }) => (
    <div className="bg-int-gray-main p-[28px] pr-[56px] rounded-[8px]">
        <ChallengesTableLabels />
        {challenges?.length > 0 && challenges?.map((ch, index) => <ChallengesReadItem key={index} challenge={ch} />)}
        {challenges?.length === 0 && <ChallengesReadItem challenge={challengesEmptyObject} />}
    </div>
);

export default ChallengesReadMode;

ChallengesReadMode.propTypes = {
    challenges: arrayOf(
        shape({
            content: string.isRequired,
        }).isRequired
    ),
};
