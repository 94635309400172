import React from 'react';
import { shape, string } from 'prop-types';
import { OnlyViewFieldTextArea } from '../../../../Profile/OnlyViewFieldInput';

const GoalsReadItem = ({ hrDigitalizationGoals }) => (
    <div className="flex mb-[16px]">
        <div className="w-[370px] mr-[10px]">
            <OnlyViewFieldTextArea styles="h-[84px]" label="" value={hrDigitalizationGoals.goal || ''} />
        </div>
        <div className="w-[1000px]">
            <OnlyViewFieldTextArea styles="h-[84px]" label="" value={hrDigitalizationGoals.contribution || ''} />
        </div>
    </div>
);

export default GoalsReadItem;

GoalsReadItem.propTypes = {
    hrDigitalizationGoals: shape({
        goal: string.isRequired,
        contribution: string.isRequired,
    }),
};
