import React from 'react';
import { bool, func } from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ModalWindow from '../../../../style/ModalWindow/ModalWindow';
import { i18PAGES } from '../../../../config/i18n/constants';

const NoUsersModal = ({ isOpen, setIsOpen }) => {
    const { t } = useTranslation(i18PAGES.PROJECTS_MANAGEMENT);

    const navigate = useNavigate();
    const onCancel = () => {
        setIsOpen(false);
    };

    const onGoToPage = () => {
        setIsOpen(true);
        navigate('/user-management');
    };

    return (
        <ModalWindow isOpen={isOpen} setIsOpen={setIsOpen}>
            <div className="w-[470px] m-auto font-Lexend400 text-[18px] leading-[28px] text-int-white-main">
                <div className="text-center w-[460px]">
                    {t(
                        'To add Project Managers you need to add users to the general User Management. Do you want to go to the User Management Page?'
                    )}
                </div>
                <div className="flex justify-between leading-[18px] mt-[40px] mb-[15px]">
                    <button
                        className={`text-int-white-main border border-int-white-main rounded-[8px] w-[210px] h-[50px] font-Lexend400 `}
                        type="button"
                        onClick={onCancel}
                    >
                        {t('Cancel')}
                    </button>

                    <button
                        className={`text-int-white-main border border-int-grapefruit bg-int-grapefruit rounded-[8px] w-[210px] h-[50px] font-Lexend400 `}
                        type="button"
                        onClick={onGoToPage}
                    >
                        {t('Go to page')}
                    </button>
                </div>
            </div>
        </ModalWindow>
    );
};

export default NoUsersModal;

NoUsersModal.propTypes = {
    isOpen: bool.isRequired,
    setIsOpen: func.isRequired,
};
