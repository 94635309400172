import React, { useState } from 'react';
import { array, arrayOf, bool, func, number, object, shape, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FieldArray, Formik } from 'formik';
import { useSearchParams } from 'react-router-dom';
import i18n from 'i18next';
import VariationOptionsBackButton from './VariationOptionsBackButton';
import { i18PAGES } from '../../../../config/i18n/constants';
import VariationOptionsHeader from './VariationOptionsHeader';
import { useShowMenu } from '../../../../store/hooks/useShowMenu';
import RadioInput from '../../../../style/RadioInput/RadioInput';
import DeleteRowButton from '../../AsIsApplications/EditMode/DeleteRowButton';
import AddRowButton from './AddRowButton';
import VariationsWarningModal, { VARIATION_WARNING_TYPES } from './VariationsWarningModal';
import { useToast } from '../../../../hoc/toast/ToastProvider';
import { useSendVariationMutation } from '../../../../store/API/optionsAPI';
import { areOptionsEqual, areOptionsUnique } from './helper';

const GenerateVariationsOfOptions = (props) => {
    const { t } = useTranslation(i18PAGES.OPTIONS);
    const { onCloseGeneratingVariations, selectedEditVariationData, options, variationsData } = props;
    const { showFullMenu } = useShowMenu();
    const [searchParams] = useSearchParams();
    const projectId = searchParams.get('projectId');

    const [warningMessageType, setWarningMessageType] = useState(undefined);
    const [isFormTouched, setIsFormTouched] = useState(false);
    const [sendVariations] = useSendVariationMutation();
    const { successToast, errorToast } = useToast();

    return (
        <>
            <Formik
                onSubmit={async (values, { setSubmitting }) => {
                    setWarningMessageType(undefined);
                    const isUniqueVariation = areOptionsUnique(values, variationsData);

                    const allProvidersActive = values.options.every((option) =>
                        option.every((buildingBlock) =>
                            buildingBlock.providers.some((provider) => provider.active === true)
                        )
                    );

                    const uniqueOptionsSet = new Set(values.options.map(JSON.stringify));
                    const hasUniqueOptions = uniqueOptionsSet.size === values.options.length;

                    if (!allProvidersActive) {
                        setWarningMessageType(VARIATION_WARNING_TYPES.NOT_ALL_PROVIDERS_SELECTED);
                        setSubmitting(false);
                    } else if (!hasUniqueOptions) {
                        setWarningMessageType(VARIATION_WARNING_TYPES.SIMILAR_OPTIONS);
                        setSubmitting(false);
                    } else if (!isUniqueVariation && !areOptionsEqual(values, selectedEditVariationData)) {
                        setWarningMessageType(VARIATION_WARNING_TYPES.SIMILAR_VARIANTS);
                        setSubmitting(false);
                    } else {
                        await sendVariations({ projectId, body: values })
                            .unwrap()
                            .then(() => {
                                if (selectedEditVariationData) {
                                    successToast(t('Variations of options were successfully updated'), '', '', 3000);
                                } else {
                                    successToast(t('Variations of options were successfully added'), '', '', 3000);
                                }

                                onCloseGeneratingVariations();
                            })
                            .catch((err) => {
                                errorToast(i18n.t('Something went wrong, try again later'));
                            })
                            .finally(() => {
                                setSubmitting(false);
                            });
                    }
                }}
                initialValues={
                    selectedEditVariationData || {
                        variationId: null,
                        options: [options],
                    }
                }
                enableReinitialize
            >
                {({ isSubmitting, handleSubmit, values, setFieldValue }) => (
                    <div className="mt-[-20px]">
                        <VariationOptionsBackButton
                            navigateBack={onCloseGeneratingVariations}
                            isFormTouched={isFormTouched}
                        />
                        <VariationOptionsHeader handleSubmit={handleSubmit} isSubmitting={isSubmitting} />
                        <FieldArray
                            name="options"
                            render={(ArrayHelpers) => (
                                <>
                                    {values?.options?.map((optionArray, index) => (
                                        <div className="flex items-center" key={index}>
                                            <div
                                                className={`bg-[#1F2937] p-[30px] mt-[30px] rounded-[8px] overflow-auto ${
                                                    showFullMenu
                                                        ? 'max-w-[calc(100vw-470px)]'
                                                        : 'max-w-[calc(100vw-230px)]'
                                                } `}
                                                id="bold-scroll"
                                            >
                                                <div className="font-Lexend500 text-[18px] leading-[24px] mb-[30px]">
                                                    {t('Option')} {index + 1}
                                                </div>

                                                <div className="flex">
                                                    {optionArray?.map((option, ind) => (
                                                        <div
                                                            className={`mr-[120px] text-[16px] leading-[24px] ${
                                                                ind === optionArray.length - 1 && 'pr-[30px]'
                                                            }`}
                                                            key={ind}
                                                        >
                                                            <div className="font-Lexend500 flex">
                                                                {option?.buildingBlockName
                                                                    .split(' ')
                                                                    .map((word, wordKey) => (
                                                                        <div className="mr-[5px]" key={wordKey}>
                                                                            {word}
                                                                        </div>
                                                                    ))}
                                                            </div>
                                                            <div className="font-Lexend400">
                                                                {option?.providers?.map((p, i) => {
                                                                    const toggleRadioButton = (name, value) => {
                                                                        if (!isFormTouched) {
                                                                            setIsFormTouched(true);
                                                                        }
                                                                        option?.providers?.forEach((_, provIndex) => {
                                                                            if (provIndex !== i) {
                                                                                setFieldValue(
                                                                                    `options.${index}.${ind}.providers.${provIndex}.active`,
                                                                                    false
                                                                                );
                                                                            } else {
                                                                                setFieldValue(name, !value);
                                                                            }
                                                                        });
                                                                        setFieldValue(name, !value);
                                                                    };

                                                                    return (
                                                                        <div className="mt-[20px]" key={i}>
                                                                            <RadioInput
                                                                                name={`options.${index}.${ind}.providers.${i}.active`}
                                                                                value={p.active}
                                                                                label={
                                                                                    <div className="flex">
                                                                                        {p.providerName
                                                                                            .split(' ')
                                                                                            .map((w, wordIndex) => (
                                                                                                <div
                                                                                                    className="mr-[5px]"
                                                                                                    key={wordIndex}
                                                                                                >
                                                                                                    {w}
                                                                                                </div>
                                                                                            ))}
                                                                                    </div>
                                                                                }
                                                                                setFieldValue={toggleRadioButton}
                                                                                isChecked={p.active}
                                                                            />
                                                                        </div>
                                                                    );
                                                                })}
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            {values?.options?.length > 1 && (
                                                <div>
                                                    <DeleteRowButton ArrayHelpers={ArrayHelpers} index={index} />
                                                </div>
                                            )}
                                        </div>
                                    ))}

                                    <AddRowButton
                                        pushFunction={() => {
                                            ArrayHelpers.push(options);
                                        }}
                                        text={t('Add option')}
                                    />
                                </>
                            )}
                        />
                    </div>
                )}
            </Formik>
            <VariationsWarningModal
                warningMessageType={warningMessageType}
                setWarningMessageType={setWarningMessageType}
            />
        </>
    );
};

export default GenerateVariationsOfOptions;

GenerateVariationsOfOptions.propTypes = {
    onCloseGeneratingVariations: func.isRequired,
    selectedEditVariationData: object,
    options: arrayOf(
        shape({
            buildingBlockName: string,
            buildingBlockId: number,
            providers: arrayOf(
                shape({
                    providerId: number,
                    providerName: string,
                    active: bool,
                })
            ),
        })
    ),
    variationsData: array,
};
