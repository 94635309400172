import { useState } from 'react';

export function useWarningModalControl(formikRef, additionalAccept, additionalDecline) {
    const [isEditMode, setIsEditMode] = useState(false);
    const [isShowCloseEditAlertModal, setIsShowCloseEditAlertModal] = useState(false);

    const openEditAlertModal = () => {
        setIsShowCloseEditAlertModal(true);
    };

    const acceptOffEditMode = () => {
        additionalAccept?.();
        setIsShowCloseEditAlertModal(false);
        setIsEditMode(false);
        formikRef.current.resetForm();
    };

    const declineOffEditMode = () => {
        additionalDecline?.();
        setIsShowCloseEditAlertModal(false);
    };

    return {
        isEditMode,
        isShowCloseEditAlertModal,
        openEditAlertModal,
        acceptOffEditMode,
        declineOffEditMode,
        setIsEditMode,
        setIsShowCloseEditAlertModal,
    };
}
