import React, { useRef, useState } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { bool } from 'prop-types';
import { ReactComponent as LogoIcon } from '../../assets/icons/logo-big.svg';
import { ReactComponent as ArrowBottomIcon } from '../../assets/icons/bottom-arrow.svg';
import { ReactComponent as ArrowUpIcon } from '../../assets/icons/up-arrow.svg';
import { ReactComponent as LogoutIcon } from '../../assets/icons/logoutIcon.svg';
import useOutsideAlerter from '../../components/util/useOutsideAlerter';
import ModalWindow from '../../style/ModalWindow/ModalWindow';
import { PrimaryButton } from '../../style/Button/Button';
import { useAuth } from '../../store/hooks/useAuth';
import { USER_ROLES } from '../../components/util/constants';
import { useLogoutUserMutation } from '../../store/API/authAPI';
import { useToast } from '../../hoc/toast/ToastProvider';
import CommentAvatar from '../../components/Comments/CommentAvatar';

const PlatformHeader = ({ isFullWidth = false }) => {
    const navigate = useNavigate();
    const { logout, profileData } = useAuth();
    const { errorToast } = useToast();

    const [logoutUser] = useLogoutUserMutation();

    const isAdmin = profileData?.userRoles?.includes(USER_ROLES.ROLE_ADMIN);

    const [isMenuOpened, setIsMenuOpened] = useState(false);
    const [isLogoutModalOpened, setIsLogoutModalOpened] = useState(false);

    const menuToggle = () => {
        setIsMenuOpened((prev) => !prev);
    };

    const handleClickCancelModal = () => {
        setIsLogoutModalOpened(false);
    };

    const handleClickLogoutModal = () => {
        logoutUser({})
            .unwrap()
            .then(() => {
                logout();
                navigate('/login');
            })
            .catch(() => {
                errorToast('Error logout user!');
            });
    };

    const container = useRef(null);
    useOutsideAlerter({ ref: container, callback: () => setIsMenuOpened(false) });

    const ROUTES = isAdmin
        ? [
              {
                  title: 'My profile',
                  handleClick: () => navigate('/profile'),
              },
              {
                  title: 'User management',
                  handleClick: () => navigate('/user-management'),
              },
              {
                  title: 'Subscription',
                  handleClick: () => navigate('/subscription'),
              },
              {
                  title: 'Logout',
                  icon: <LogoutIcon />,
                  handleClick: () => setIsLogoutModalOpened(true),
              },
          ]
        : [
              {
                  title: 'My profile',
                  handleClick: () => navigate('/profile'),
              },
              {
                  title: 'Logout',
                  icon: <LogoutIcon />,
                  handleClick: () => setIsLogoutModalOpened(true),
              },
          ];

    return (
        <header className="h-header-height bg-int-gray-main">
            <div
                className={` ${
                    !isFullWidth && 'max-w-container-size'
                } h-[100%] m-auto flex items-center justify-between px-[100px]`}
            >
                <div className="flex items-center">
                    <div className="cursor-pointer">
                        <Link to="/">
                            <LogoIcon />
                        </Link>
                    </div>
                    <div className="ml-[70px] flex items-center justify-center">
                        {isAdmin && (
                            <NavLink to="/companies-list" className="ml-[30px]">
                                {({ isActive }) => (
                                    <div
                                        className={
                                            isActive ? 'text-int-white-main font-Lexend500' : 'text-int-white-secondary'
                                        }
                                    >
                                        Company
                                    </div>
                                )}
                            </NavLink>
                        )}
                    </div>

                    <NavLink to="/projects-list" className="ml-[30px]">
                        {({ isActive }) => (
                            <div
                                className={isActive ? 'text-int-white-main font-Lexend500' : 'text-int-white-secondary'}
                            >
                                Projects
                            </div>
                        )}
                    </NavLink>
                </div>

                <div className="flex items-center">
                    {isAdmin && (
                        <div className="cursor-pointer mr-[80px] text-int-white-main font-Lexend500">
                            <Link to="/modules">Module und Funktionen</Link>
                        </div>
                    )}

                    <div ref={container} className="flex items-center cursor-pointer relative" onClick={menuToggle}>
                        <div className="text-int-white-main mr-[10px]">
                            <CommentAvatar
                                name={profileData?.fullName?.length ? profileData?.fullName[0] : 'X'}
                                size={30}
                            />
                        </div>
                        <div className="mr-[18px]">{profileData?.email}</div>
                        {isMenuOpened ? <ArrowUpIcon fill="#EDF1FD" /> : <ArrowBottomIcon fill="#EDF1FD" />}
                        {isMenuOpened && (
                            <div className="absolute top-[110%] right-[-10px] w-52 z-50 rounded-[11px] bg-[#2A3143] overflow-hidden">
                                {ROUTES &&
                                    ROUTES.length &&
                                    ROUTES.map((route, index) => (
                                        <div key={index} onClick={route.handleClick}>
                                            <li className="hover:bg-[#354053] py-[10px] pl-[30px] pr-[5px] cursor-pointer flex items-center">
                                                {route.icon && <div className="mr-[12px]">{route.icon}</div>}
                                                <div>{route.title}</div>
                                            </li>
                                        </div>
                                    ))}
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <ModalWindow
                isOpen={isLogoutModalOpened}
                setIsOpen={setIsLogoutModalOpened}
                className="-top-[180px] w-[578px]"
                isCloseClickOutside
            >
                <div className="flex flex-col items-center">
                    <div className="text-[32px] font-Lexend500 text-int-white-main">Log out</div>
                    <div className="text-[18px] font-Lexend400 text-int-white-main my-[50px]">
                        Are you sure you want to leave the platform?
                    </div>
                    <div className="w-[100%] flex justify-around">
                        <PrimaryButton className="w-[210px] h-[50px]" handleClick={handleClickCancelModal}>
                            <div className="text-[18px] font-Lexend400">Cancel</div>
                        </PrimaryButton>
                        <PrimaryButton isPink className="w-[210px] h-[50px]" handleClick={handleClickLogoutModal}>
                            <div className="text-[18px] font-Lexend400">Log out</div>
                        </PrimaryButton>
                    </div>
                </div>
            </ModalWindow>
        </header>
    );
};

export default PlatformHeader;

PlatformHeader.propTypes = {
    isFullWidth: bool,
};
