import React from 'react';
import { number, oneOf, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ReactComponent as SuccessIcon } from '../../assets/icons/success.svg';
import { OPTIONS_STEP_COMPANIES, OPTIONS_STEP_PROJECT, STEP_TYPE } from '../util/constants';
import { i18PAGES } from '../../config/i18n/constants';

const StepView = ({ currentStepNumber, type }) => {
    let title;
    let optionsSteps;

    if (type === STEP_TYPE.COMPANIES) {
        title = 'Setup a company';
        optionsSteps = OPTIONS_STEP_COMPANIES;
    }
    if (type === STEP_TYPE.PROJECT) {
        title = 'Create a Project';
        optionsSteps = OPTIONS_STEP_PROJECT;
    }

    return (
        <div>
            <div className="text-int-white-main text-[24px] leading-[18px] font-Lexend400 mb-[50px]">{title}</div>
            <div className="flex justify-between">
                {optionsSteps.map((item) => (
                    <StepViewItem
                        stepNumber={item.number}
                        name={item.name}
                        currentStepNumber={currentStepNumber}
                        countSteps={optionsSteps.length}
                        key={item.number}
                    />
                ))}
            </div>
        </div>
    );
};
export default StepView;

StepView.propTypes = {
    currentStepNumber: number.isRequired,
    type: oneOf([STEP_TYPE.COMPANIES, STEP_TYPE.PROJECT]).isRequired,
};

export const StepViewItem = ({ currentStepNumber, stepNumber, name, countSteps }) => {
    const { t } = useTranslation(i18PAGES.SETUP_COMPANIES);

    return (
        <div
            className={`${currentStepNumber >= stepNumber ? 'text-int-white-main' : 'text-int-white-secondary'} `}
            style={{ width: `calc(96% / ${countSteps})` }}
        >
            <div
                className={` 
                    ${currentStepNumber === stepNumber && 'bg-int-white-main'}
                    ${currentStepNumber > stepNumber && 'bg-[#10B981]'} 
                    ${currentStepNumber < stepNumber && 'bg-int-white-secondary'} 
                    h-[3px] w-[100%]
                    `}
            />

            <div className="uppercase mt-[8px] mb-[8px] font-Lexend600 leading-[24px] flex items-center">
                <div className="mr-[12px]">
                    {t('STEP')} {stepNumber}
                </div>
                {currentStepNumber > stepNumber && <SuccessIcon />}
            </div>
            <div className="font-Lexend400 leading-[24px]">{name}</div>
        </div>
    );
};

StepViewItem.propTypes = {
    currentStepNumber: number.isRequired,
    stepNumber: number.isRequired,
    name: string.isRequired,
    countSteps: number.isRequired,
};
