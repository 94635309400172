import { findCountryOptions } from './countryHelper';
import { AUTH_TOKEN } from './http';

export const countryOptions = findCountryOptions();

export const PAGINATION_PAGE_SIZE = 10;

export const HR_PAGINATION_PAGE_SIZE = 6;

export const WORKERS_PAGINATION_PAGE_SIZE = 10;

export const REGEX = {
    INTEGER_POSITIVE: /^\d+$/,
    STRING_ONLY: /^[a-zA-ZäöüÄÖÜß ]*$/,
    NUMBERS_ONLY: /^[0-9]*$/,
    PHONE: /^[+][0-9]*$/,
    PASSWORD: /^(?=.*?[A-Z])(?=.*?[a-z]).{8,}$/,
    DECIMAL: /^(0|[1-9]\d*)(\.\d{1,2})?$/,
    EMAIL: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
};
export const SETUP_PAGES = {
    STEP_1: 'STEP_1',
    STEP_2: 'STEP_2',
    STEP_3: 'STEP_3',
    STEP_4: 'STEP_4',
};

export const OPTIONS_STEP_COMPANIES = [
    { number: 1, name: 'Company' },
    { number: 2, name: 'Countries' },
    { number: 3, name: 'Cities' },
    { number: 4, name: 'Offices' },
];

export const OPTIONS_STEP_PROJECT = [
    { number: 1, name: 'General Info' },
    { number: 2, name: 'Add Company' },
    { number: 3, name: 'Add Offices' },
];

export const STEP_TYPE = {
    COMPANIES: 'COMPANIES',
    PROJECT: 'PROJECT',
};

export const HEADERS = (headers) => {
    const token = localStorage.getItem(AUTH_TOKEN);

    if (!headers.has('Authorization') && token) {
        headers.set('Authorization', token);
    }
    return headers;
};

export const OrganisationStructure = {
    LINE_ORGANIZATION: 'LINE_ORGANIZATION',
    MATRIX_ORGANISATION: 'MATRIX_ORGANISATION',
    AGILE_ORGANISATION: 'AGILE_ORGANISATION',
};

export const LineOrganizationType = {
    FUNCTIONAL_ORGANIZATION: 'FUNCTIONAL_ORGANIZATION',
    DIVISIONAL_ORGANIZATION: 'DIVISIONAL_ORGANIZATION',
};
export const Orientation = {
    EMPLOYEE_FRIENDLY: 'EMPLOYEE_FRIENDLY',
    EMPLOYER_FRIENDLY: 'EMPLOYER_FRIENDLY',
    BALANCED: 'BALANCED',
};
export const MarketGrowthStrategy = {
    DIVERSIFICATION: 'DIVERSIFICATION',
    MARKET_PENETRATION: 'MARKET_PENETRATION',
    PRODUCT_DEVELOPMENT: 'PRODUCT_DEVELOPMENT',
    MARKET_DEVELOPMENT: 'MARKET_DEVELOPMENT',
};
export const TimingStrategy = {
    FOLLOW_UP_STRATEGY: 'FOLLOW_UP_STRATEGY',
    PIONEER_STRATEGY: 'PIONEER_STRATEGY',
};
export const CompetitiveStrategy = {
    QUALITY_LEADERSHIP: 'QUALITY_LEADERSHIP',
    NICHE_STRATEGY: 'NICHE_STRATEGY',
    COST_LEADERSHIP: 'COST_LEADERSHIP',
};

export const USER_ROLES = {
    ROLE_OWNER: 'ROLE_OWNER',
    ROLE_USER: 'ROLE_USER',
    ROLE_ADMIN: 'ROLE_ADMIN',
};

export const projectManagersUserRoles = {
    ROLE_REVIEWER: 'ROLE_REVIEWER',
    ROLE_CONTRIBUTOR: 'ROLE_CONTRIBUTOR',
    ROLE_MANAGER: 'ROLE_MANAGER',
    ROLE_ADMIN: 'ROLE_ADMIN',
    ROLE_USER: 'ROLE_USER',
};

export const PAGE_IDENTIFIER = {
    COMPANIES: 'COMPANIES',
    AS_IS_APPLICATIONS: 'AS_IS_APPLICATIONS',
    TO_BE_APPLICATIONS: 'TO_BE_APPLICATIONS',
    AS_IS_INTEGRATIONS: 'AS_IS_INTEGRATIONS',
    TO_BE_INTEGRATIONS: 'TO_BE_INTEGRATIONS',
    APPLICATION_REQUIREMENTS: 'APPLICATION_REQUIREMENTS',
    MODULES_AND_STRATEGY: 'MODULES_AND_STRATEGY',
    WORKERS_INFORMATION: 'WORKERS_INFORMATION',
    FTE_PROCESS: 'FTE_PROCESS',
    SELECT_PROVIDER: 'SELECT_PROVIDER',
    OPTIONS: 'OPTIONS',
    FINANCIAL_COMPARISON: 'FINANCIAL_COMPARISON',
    MODULES_EFFICIENCIES: 'MODULES_EFFICIENCIES',
    EFFICIENCIES_COMPARISON: 'EFFICIENCIES_COMPARISON',
    PRESENTATION: 'PRESENTATION',
};

export const MODULES_AND_STRATEGY_TABS_IDENTIFIERS = {
    PROBLEMS_OVERVIEW: 'PROBLEMS_OVERVIEW',
    WEAKNESSES_AND_EXPECTATIONS: 'WEAKNESSES_AND_EXPECTATIONS',
    ACTIONS: 'ACTIONS',
    HR_DIGITALIZATION: 'HR_DIGITALIZATION',
    GOALS: 'GOALS',
    HR_STRATEGY: 'HR_STRATEGY',
    CHALLENGES: 'CHALLENGES',
};

export const PROJECT_TOOL_STATUS = {
    CREATED: 'CREATED',
    IN_PROGRESS: 'IN_PROGRESS',
    SUBMITTED: 'SUBMITTED',
};

export const PROJECT_STATUS = {
    INITIAL: 'INITIAL',
    IN_PROGRESS: 'IN_PROGRESS',
    EDITED: 'EDITED',
    COMPLETED: 'COMPLETED',
    SENT_TO_PROVIDERS: 'SENT_TO_PROVIDERS',
    ARCHIVED: 'ARCHIVED',
};

export const COMPANY_STATUS = {
    INITIAL: 'INITIAL',
    IN_PROGRESS: 'IN_PROGRESS',
    COMPLETED: 'COMPLETED',
};

export const SUBSCRIPTION_STATUS = {
    INITIAL: 'INITIAL',
    ADDED: 'ADDED',
    BOUGHT: 'BOUGHT',
    TERMINATED: 'TERMINATED',
    DELETED: 'DELETED',
};

export const SUBSCRIPTION_INVOICE_STATUS = {
    CREATED: 'CREATED',
    IN_PROGRESS: 'IN_PROGRESS',
    DENY: 'DENY',
    CONFIRMED: 'CONFIRMED',
};

export const WORKERS_STATUS = {
    CREATED: 'CREATED',
    WAITING_FOR_RESPONSE: 'WAITING_FOR_RESPONSE',
    RESULT_IS_SAVED: 'RESULT_IS_SAVED',
};
