import React from 'react';
import { useTranslation } from 'react-i18next';
import { array, func, number, object, string } from 'prop-types';
import { FieldArray } from 'formik';
import { i18PAGES } from '../../config/i18n/constants';
import { ReactComponent as InfoIcon } from '../../assets/icons/filledInfo.svg';
import ProgressBar from '../../style/ProgressBar/ProgressBar';
import AddRowButton from '../Platform/Options/GenerateVariationsOfOptions/AddRowButton';
import { generateKey } from '../util/generateKey';
import { emptyModuleDistributionItem } from './helper';
import WorkingTimeDistributionItem from './WorkingTimeDistributionItem';

const WorkingTimeDistribution = (props) => {
    const { t } = useTranslation(i18PAGES.QUESTIONNAIRE);
    const {
        progressValue,
        values,
        setProgressValue,
        setFieldValue,
        modulesList,
        setReadyModulesCount,
        handleBlur,
        handleChange,
        modulesOptions,
        touched,
        errors,
        setIsSubmitAnswer,
        className,
    } = props;

    return (
        <div className={className}>
            <div className="mt-[60px] font-Lexend500 text-[18px] leading-[26px] text-int-white-main">
                {t('Working time distribution')}
            </div>
            <div className="mt-[16px] text-int-white-main mb-[10px]">
                <div className="flex items-center mb-[5px]">
                    <div className="text-[14px] leading-[26px] font-Lexend400 mr-[6px]">{t('Overall FTE')}</div>
                    <InfoIcon width={18} height={18} fill="#9BA1B2" />
                </div>
                <ProgressBar progressValue={progressValue} />
            </div>

            <FieldArray
                name="workingTimeDistributionDTOS"
                render={(ArrayHelpers) => (
                    <>
                        {values?.workingTimeDistributionDTOS?.map((distribution, index) => (
                            <WorkingTimeDistributionItem
                                key={distribution.key || distribution.moduleId}
                                setReadyModulesCount={setReadyModulesCount}
                                setProgressValue={setProgressValue}
                                setFieldValue={setFieldValue}
                                distribution={distribution}
                                modulesOptions={modulesOptions}
                                modulesList={modulesList}
                                values={values}
                                touched={touched}
                                errors={errors}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                ArrayHelpers={ArrayHelpers}
                                index={index}
                                setIsSubmitAnswer={setIsSubmitAnswer}
                            />
                        ))}
                        {modulesList?.length > values?.workingTimeDistributionDTOS?.length && (
                            <AddRowButton
                                pushFunction={() => {
                                    ArrayHelpers.push({
                                        ...emptyModuleDistributionItem,
                                        key: generateKey(values?.workingTimeDistributionDTOS.length - 1),
                                    });
                                }}
                                text={t('Add 1 more module')}
                            />
                        )}
                    </>
                )}
            />
        </div>
    );
};

export default WorkingTimeDistribution;

WorkingTimeDistribution.propTypes = {
    progressValue: number,
    values: object,
    setProgressValue: func,
    setFieldValue: func,
    modulesList: array,
    setReadyModulesCount: func,
    modulesOptions: array,
    handleBlur: func,
    handleChange: func,
    touched: object,
    errors: object,
    setIsSubmitAnswer: func,
    className: string,
};
