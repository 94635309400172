import React from 'react';
import { shape, string } from 'prop-types';
import { OnlyViewFieldTextArea } from '../../../../Profile/OnlyViewFieldInput';

const ProblemsOverviewReadItem = ({ problem }) => (
    <div className="flex mb-[16px]">
        <div className="w-[370px] mr-[10px]">
            <OnlyViewFieldTextArea styles="h-[84px]" label="" value={problem.name || ''} />
        </div>
        <div className="w-[976px]">
            <OnlyViewFieldTextArea styles="h-[84px]" label="" value={problem.description || ''} />
        </div>
    </div>
);

export default ProblemsOverviewReadItem;

ProblemsOverviewReadItem.propTypes = {
    problem: shape({
        name: string.isRequired,
        description: string.isRequired,
    }),
};
