import React from 'react';
import { FieldArray, Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ReactComponent as AddUserIcon } from '../../../assets/icons/addIcon.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/userManagementBigDeleteIcon.svg';
import { i18PAGES } from '../../../config/i18n/constants';
import { useToast } from '../../../hoc/toast/ToastProvider';
import ModalWindow from '../../../style/ModalWindow/ModalWindow';
import FInput from '../../../style/Input/Input';
import { PrimaryButton } from '../../../style/Button/Button';
import { workersValidationSchema } from './workersValidation';
import { useCreateWorkersInformationMutation } from '../../../store/API/workersInformationAPI';
import { errorEmailIsUsedStringToArrayEmails } from '../../util/errorEmailIsUsedStringToArrayEmails';

const AddWorkersModalForm = ({ isAddWorkersModalOpened, setIsAddWorkersModalOpened, projectId, setActivePage }) => {
    const { t } = useTranslation(i18PAGES.WORKERS_EFFICIENCY);
    const { successToast, errorToast } = useToast();

    const initialValueWorker = {
        fullName: '',
        email: '',
        role: '',
    };

    const [createWorkers] = useCreateWorkersInformationMutation();

    return (
        <ModalWindow
            isOpen={isAddWorkersModalOpened}
            setIsOpen={setIsAddWorkersModalOpened}
            className="-top-[0px] w-[1064px]"
        >
            <div className="flex flex-col items-center">
                <div className="text-[24px] leading-[24px] font-Lexend500 text-int-white-main">{t('Add workers')}</div>
                <Formik
                    onSubmit={(values, { setSubmitting, setFieldError }) => {
                        createWorkers({
                            projectId,
                            body: {
                                workerDetails: values?.workerList,
                            },
                        })
                            .unwrap()
                            .then(() => {
                                successToast(t('Workers was successfully added'));
                                setIsAddWorkersModalOpened(false);
                                setActivePage(1);
                            })
                            .catch((err) => {
                                if (err.data.message.includes('Duplication of emails is present in the project')) {
                                    errorToast(t('Duplication of emails is present in the project.'));
                                    const errorArray = errorEmailIsUsedStringToArrayEmails(err.data.message);
                                    values.workerList
                                        .map((user, index) => ({ index, ...user }))
                                        .filter((user) => errorArray.includes(user.email))
                                        .forEach((user) => {
                                            setFieldError(
                                                `workerList.${user.index}.email`,
                                                'Email address already in use'
                                            );
                                        });
                                } else {
                                    errorToast(t('Error workers added'));
                                }
                            })
                            .finally(() => {
                                setSubmitting(false);
                            });
                    }}
                    validationSchema={workersValidationSchema}
                    initialValues={{
                        workerList: [initialValueWorker],
                    }}
                    enableReinitialize
                >
                    {({
                        values,
                        touched,
                        errors,
                        handleSubmit,
                        handleBlur,
                        handleChange,
                        isSubmitting,
                        setFieldValue,
                    }) => (
                        <Form className="w-[100%] mt-[10px]">
                            <FieldArray
                                name="workerList"
                                render={(ArrayHelpers) => (
                                    <>
                                        <div
                                            className="overflow-y-auto max-h-[500px] mr-[-20px] mb-[10px] pr-[20px]"
                                            id="primary-scroll"
                                        >
                                            {values.workerList.map((item, index) => {
                                                const usersErrors =
                                                    (errors.workerList?.length && errors.workerList[index]) || {};
                                                const usersTouched =
                                                    (touched.workerList?.length && touched.workerList[index]) || {};
                                                return (
                                                    <div className="mt-[20px] mb-[20px]" key={index}>
                                                        <div className="flex justify-start items-center">
                                                            <FInput
                                                                name={`workerList.${index}.fullName`}
                                                                placeholder="Type"
                                                                value={values.workerList[index].fullName}
                                                                handleBlur={handleBlur}
                                                                handleChange={handleChange}
                                                                className="min-w-[320px] mt-[22px] mr-[12px]"
                                                                type="text"
                                                                label="Name"
                                                                isError={
                                                                    !!(usersTouched.fullName && usersErrors.fullName)
                                                                }
                                                                errorText={usersErrors.fullName}
                                                                setFieldValue={setFieldValue}
                                                                limit={50}
                                                            />

                                                            <FInput
                                                                name={`workerList.${index}.role`}
                                                                placeholder="Type"
                                                                value={values.workerList[index].role}
                                                                handleBlur={handleBlur}
                                                                handleChange={handleChange}
                                                                className="min-w-[320px] mt-[22px] mr-[12px]"
                                                                type="text"
                                                                label="Role"
                                                                isError={!!(usersTouched.role && usersErrors.role)}
                                                                errorText={usersErrors.role}
                                                                setFieldValue={setFieldValue}
                                                                limit={50}
                                                            />

                                                            <FInput
                                                                name={`workerList.${index}.email`}
                                                                placeholder="Type email"
                                                                value={values.workerList[index].email}
                                                                handleBlur={handleBlur}
                                                                handleChange={handleChange}
                                                                className="min-w-[320px] mt-[22px] mr-[12px]"
                                                                type="text"
                                                                label="Email"
                                                                isError={!!(usersTouched.email && usersErrors.email)}
                                                                errorText={usersErrors.email}
                                                                setFieldValue={setFieldValue}
                                                            />
                                                            {index > 0 ? (
                                                                <div
                                                                    className="flex justify-center items-center w-[20px] mt-[22px] h-[42px] mr-[12px] cursor-pointer"
                                                                    onClick={() => ArrayHelpers.remove(index)}
                                                                >
                                                                    <DeleteIcon />
                                                                </div>
                                                            ) : (
                                                                <div className="w-[20px] mt-[22px] h-[42px]" />
                                                            )}
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                        <div className="w-[100%] flex justify-start relative">
                                            {values.workerList.length < 15 ? (
                                                <div
                                                    className="flex items-center cursor-pointer mb-[20px]"
                                                    onClick={() => ArrayHelpers.push(initialValueWorker)}
                                                >
                                                    <div className="mr-[6px]">
                                                        <AddUserIcon width={16} height={16} fill="#9BA1B2" />
                                                    </div>
                                                    <div>{t('Add worker')}</div>
                                                </div>
                                            ) : (
                                                <div className="h-[44px]" />
                                            )}
                                        </div>
                                    </>
                                )}
                            />
                            <div className="w-[100%] flex justify-between">
                                <PrimaryButton
                                    isGrey
                                    className="w-[170px] h-[50px]"
                                    handleClick={() => setIsAddWorkersModalOpened(false)}
                                >
                                    <div className="text-[18px] font-Lexend400">{t('Cancel')}</div>
                                </PrimaryButton>
                                <PrimaryButton
                                    isPink
                                    className="w-[170px] h-[50px]"
                                    handleClick={handleSubmit}
                                    disabled={isSubmitting}
                                >
                                    <div className="text-[18px] font-Lexend400">{t('Add')}</div>
                                </PrimaryButton>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </ModalWindow>
    );
};

AddWorkersModalForm.propTypes = {
    isAddWorkersModalOpened: PropTypes.bool.isRequired,
    setIsAddWorkersModalOpened: PropTypes.func.isRequired,
    projectId: PropTypes.string.isRequired,
    setActivePage: PropTypes.func.isRequired,
};

export default AddWorkersModalForm;
