import React, { useEffect, useRef, useState } from 'react';
import { func, number, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import CommentAvatar from './CommentAvatar';
import { ReactComponent as SendIcon } from '../../assets/icons/sendIcon.svg';
import { useSendCommentMutation } from '../../store/API/commentsAPI';
import { i18PAGES } from '../../config/i18n/constants';

const SendMessage = ({ name, pageIdentifier, subPageIdentifier, projectId, setPageNumber }) => {
    const { t } = useTranslation(i18PAGES.COMPANIES_INFO);

    const textareaRef = useRef(null);
    const [messageText, setMessageText] = useState('');
    const [isValidationError, setIsValidationError] = useState(false);
    const [sendComment, { isError, isLoading }] = useSendCommentMutation();

    const onChangeHandler = (e) => {
        setMessageText(e.target.value);

        if (messageText.length < 410) {
            const { target } = e;
            textareaRef.current.style.height = '40px';
            textareaRef.current.style.height = `${target.scrollHeight + 2}px`;
        }
    };

    const sendMessage = () => {
        if (messageText.replace(/^\s+|\s+$/g, '').length > 0 && !isValidationError) {
            sendComment({
                projectId,
                content: messageText.replace(/^\s+|\s+$/g, ''),
                pageIdentifier,
                subPageIdentifier,
            })
                .unwrap()
                .then((data) => {
                    setPageNumber(0);
                    setMessageText('');
                    textareaRef.current.style.height = '40px';
                });
        }
    };

    useEffect(() => {
        if (messageText.length > 200) {
            setIsValidationError(true);
        } else {
            setIsValidationError(false);
        }
    }, [messageText.length]);

    return (
        <>
            <div className="flex items-center">
                <CommentAvatar name={name} size={32} />
                <div className="ml-[10px] relative">
                    <textarea
                        id="primary-scroll"
                        className="pr-[50px] pl-[10px] pt-[10px] flex items-center justify-center break-words text-int-white-main text-[14px] leading-[19px] rounded-[8px] bg-[#2A3143] h-[40px] w-[296px] max-h-[70px]"
                        onChange={onChangeHandler}
                        value={messageText}
                        ref={textareaRef}
                        placeholder="Add a comment"
                    />
                </div>
                <button
                    type="button"
                    className={`-ml-[36px] z-10 ${isLoading && 'opacity-[0.2]'}`}
                    onClick={sendMessage}
                    disabled={isLoading}
                >
                    <SendIcon />
                </button>
            </div>
            {isError && (
                <div className="text-center mt-[10px] text-[14px] text-error">{t("Can't send message, try later")}</div>
            )}

            {isValidationError && (
                <div className="text-center mt-[10px] text-[14px] text-error">{t('Max length is 200symbols')}</div>
            )}
        </>
    );
};

export default SendMessage;

SendMessage.propTypes = {
    name: string.isRequired,
    pageIdentifier: string.isRequired,
    subPageIdentifier: string,
    projectId: number.isRequired,
    setPageNumber: func.isRequired,
};
