import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { HEADERS } from '../../components/util/constants';

export const asIsIntegrationsAPI = createApi({
    reducerPath: 'asIsIntegrationsAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api',
        prepareHeaders: HEADERS,
    }),
    tagTypes: ['asIsIntegrations'],
    endpoints: (build) => ({
        getAsIsIntegrations: build.query({
            query: ({ projectId, sortBy, orderBy }) =>
                `/projects/${projectId}/as-is-integrations?${sortBy ? `sort=${sortBy}` : ''}${
                    sortBy && orderBy ? `,${orderBy || 'DESC'}` : ''
                }`,
            keepUnusedDataFor: 0.0001,
            providesTags: (result) =>
                result?.content
                    ? [
                          ...result.content.map(({ id }) => ({ type: 'asIsIntegrations', id })),
                          { type: 'asIsIntegrations', id: 'LIST' },
                      ]
                    : [{ type: 'asIsIntegrations', id: 'LIST' }],
        }),
        createAsIsIntegrations: build.mutation({
            query: ({ projectId, body }) => ({
                url: `/projects/${projectId}/as-is-integrations`,
                method: 'POST',
                body,
            }),
            invalidatesTags: [{ type: 'asIsIntegrations', id: 'LIST' }],
        }),

        getAsIsIntegrationsOptions: build.query({
            query: () => `/projects/as-is-integrations/options`,
        }),

        getBuildingBlocksCountries: build.query({
            query: (projectId) => `/projects/${projectId}/building-blocks`,
        }),

        sendAsIsIntegrationsScheme: build.mutation({
            query: ({ projectId, body }) => ({
                url: `/projects/${projectId}/as-is-integrations/save-scheme`,
                method: 'POST',
                body,
            }),
        }),
    }),
});

export const {
    useGetAsIsIntegrationsQuery,
    useCreateAsIsIntegrationsMutation,
    useGetAsIsIntegrationsOptionsQuery,
    useGetBuildingBlocksCountriesQuery,
    useSendAsIsIntegrationsSchemeMutation,
} = asIsIntegrationsAPI;
