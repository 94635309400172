import React from 'react';
import { func, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseModalIcon } from '../../assets/icons/closeToastIcon.svg';
import { ReactComponent as ModalWarningIcon } from '../../assets/icons/modalWarningIcon.svg';

const CloseWarning = ({ handleCloseClick, styles = 'w-[492px]' }) => {
    const { t } = useTranslation();

    return (
        <div className={`${styles} p-[10px] rounded-lg absolute top-[22px] bg-[#5679F7]`}>
            <div
                className="w-[10px] h-[10px] cursor-pointer absolute top-[10px] right-[10px]"
                aria-hidden="true"
                onClick={handleCloseClick}
            >
                <CloseModalIcon />
            </div>
            <div className="flex items-center">
                <ModalWarningIcon />
                <div className="text-[14px] text-int-white-main leading-[17px] ml-[8px] mr-[20px]">
                    {t('Are you sure you want to cancel? All your data will be lost. If you sure press Cancel')}
                </div>
            </div>
        </div>
    );
};

CloseWarning.propTypes = {
    handleCloseClick: func.isRequired,
    styles: string,
};

export default CloseWarning;
