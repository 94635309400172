import { Navigate, useLocation } from 'react-router-dom';
import { node } from 'prop-types';
import { useAuth } from '../../store/hooks/useAuth';

export function RequireAuth({ children }) {
    const { isAuth, isPending } = useAuth();
    const location = useLocation();

    if (!isAuth && !isPending) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return <>{children}</>;
}

RequireAuth.propTypes = {
    children: node,
};
