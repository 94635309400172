import React, { useState } from 'react';
import { node, number, string } from 'prop-types';
import { ReactComponent as PlusIcon } from '../../assets/icons/plusIcon.svg';
import { ReactComponent as MinusIcon } from '../../assets/icons/minusIcon.svg';

const TabItem = ({ children, openHeight = 262, headerText }) => {
    const [isOpened, setIsOpened] = useState(true);

    const openToggle = () => {
        setIsOpened((prev) => !prev);
    };

    return (
        <div>
            <div
                className="bg-int-gray-main mt-[30px] w-[100%] h-[700px] rounded-[8px] px-[20px] pt-[13px]"
                style={{
                    height: isOpened ? `${openHeight}px` : '45px',
                    transition: 'height 0.2s ease-out',
                }}
            >
                <div
                    className="flex items-center justify-between mt-[-13px] h-[45px] pl-[10px] cursor-pointer"
                    onClick={openToggle}
                >
                    <div className="text-int-white-main text-[18px] leading-[18px] font-Lexend600">{headerText}</div>
                    <div className="h-[20px] w-[20px] flex items-center justify-center">
                        {isOpened ? <MinusIcon /> : <PlusIcon />}
                    </div>
                </div>
                {isOpened && <div className="text-int-white-main">{children}</div>}
            </div>
        </div>
    );
};

export default TabItem;

TabItem.propTypes = {
    children: node.isRequired,
    headerText: string.isRequired,
    openHeight: number,
};
