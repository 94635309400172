import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { arrayOf, bool, func, number, oneOfType, shape, string } from 'prop-types';
import ModalWindow from '../../../../style/ModalWindow/ModalWindow';
import { i18PAGES } from '../../../../config/i18n/constants';
import { useToast } from '../../../../hoc/toast/ToastProvider';
import { SEND_ASK_MESSAGES_STATUSES } from '../constants';
import {
    useDeleteModulesRequestMutation,
    useRenewModulesRequestMutation,
} from '../../../../store/API/providerSelectionAPI';
import i18n from '../../../../config/i18n/i18n';

const SendModuleExpirationMessage = (props) => {
    const { t } = useTranslation(i18PAGES.OPTIONS);
    const { successToast, errorToast } = useToast();
    const { isOpen, setIsOpen, modalAskStatus, expiredModulesList, projectId } = props;
    const [sendRenewRequest, { isLoading: isRenewLoading }] = useRenewModulesRequestMutation();
    const [sendDeleteRequest, { isLoading: isDeleteLoading }] = useDeleteModulesRequestMutation();

    const textareaRef = useRef(null);
    const [messageText, setMessageText] = useState('');
    const [isValidationError, setIsValidationError] = useState(false);

    const onCancel = useCallback(() => {
        setIsOpen(false);
    }, [setIsOpen]);

    const onSend = useCallback(() => {
        const sendData = {
            modules: expiredModulesList.map((m) => m.id),
            message: messageText,
        };

        if (modalAskStatus === SEND_ASK_MESSAGES_STATUSES.RENEW) {
            sendRenewRequest({ projectId, body: sendData })
                .unwrap()
                .then(() => {
                    successToast(t('Email was sent to manager successfully to ask for renew'), '', '', 3000);
                    setIsOpen(false);
                })
                .catch(() => {
                    errorToast(i18n.t('Something went wrong, try again later'));
                })
                .finally(() => {
                    setMessageText('');
                });
        }

        if (modalAskStatus === SEND_ASK_MESSAGES_STATUSES.DELETE) {
            sendDeleteRequest({ projectId, body: sendData })
                .unwrap()
                .then(() => {
                    successToast(t('Email was sent to manager successfully to ask for delete'), '', '', 3000);
                    setIsOpen(false);
                })
                .catch(() => {
                    errorToast(i18n.t('Something went wrong, try again later'));
                })
                .finally(() => {
                    setMessageText('');
                });
        }
    }, [
        errorToast,
        expiredModulesList,
        messageText,
        modalAskStatus,
        projectId,
        sendDeleteRequest,
        sendRenewRequest,
        setIsOpen,
        successToast,
        t,
    ]);

    const onChangeHandler = (e) => {
        setMessageText(e.target.value);

        if (messageText.length < 410) {
            const { target } = e;
            textareaRef.current.style.height = '56px';
            textareaRef.current.style.height = `${target.scrollHeight + 2}px`;
        }
    };

    useEffect(() => {
        if (messageText.length > 300) {
            setIsValidationError(true);
        } else {
            setIsValidationError(false);
        }
    }, [messageText.length]);

    return (
        <ModalWindow isOpen={isOpen} setIsOpen={setIsOpen}>
            <div className="w-[470px] m-auto font-Lexend400 text-[18px] leading-[28px] text-int-white-main">
                <div className="text-center mt-[-20px] font-Lexend500 text-[24px] leading-[24px] mb-[50px]">
                    {t('Send Request')}
                </div>

                <div className="text-center w-[460px]">
                    {t('Would you like to add comment to your request? This step is optional')}
                </div>
                <div className="mt-[30px]">
                    <div className="flex text-[14px] leading-[16px] font-Lexend400 mb-[6px]">
                        <div className="text-int-white-main mr-[3px]">{t('Message')}</div>
                        <div className="text-int-white-secondary">{t('(optional)')}</div>
                    </div>
                    <textarea
                        id="primary-scroll"
                        className="px-[10px] pt-[10px] flex items-center focus:border focus:border-int-white-main justify-center break-words text-int-white-main text-[14px] leading-[19px] rounded-[8px] bg-[#2A3143] h-[56px] w-[460px] max-h-[100px]"
                        onChange={onChangeHandler}
                        value={messageText}
                        ref={textareaRef}
                        placeholder="Type"
                    />
                    {isValidationError && (
                        <div className="text-center mt-[10px] mb-[-30px] text-[14px] text-error">
                            {t('Max length is 300 symbols')}
                        </div>
                    )}
                </div>
                <div className="flex justify-between leading-[18px] mt-[50px] mb-[15px]">
                    <button
                        className={`text-int-white-main border border-int-gray-secondary bg-int-gray-secondary rounded-[8px] w-[210px] h-[50px] font-Lexend400 `}
                        type="button"
                        onClick={onCancel}
                    >
                        {t('Cancel')}
                    </button>

                    <button
                        className={`text-int-white-main border border-int-grapefruit bg-int-grapefruit rounded-[8px] w-[210px] h-[50px] font-Lexend400 ${
                            (isRenewLoading || isDeleteLoading) && 'opacity-[0.7]'
                        } `}
                        type="button"
                        onClick={onSend}
                        disabled={isRenewLoading || isDeleteLoading}
                    >
                        {t('Send')}
                    </button>
                </div>
            </div>
        </ModalWindow>
    );
};

export default SendModuleExpirationMessage;

SendModuleExpirationMessage.propTypes = {
    isOpen: bool.isRequired,
    setIsOpen: func.isRequired,
    modalAskStatus: string,
    expiredModulesList: arrayOf(
        shape({
            id: number,
            name: string,
        })
    ),
    projectId: oneOfType([string, number]),
};
