import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { HEADERS } from '../../components/util/constants';

export const toBeIntegrationsAPI = createApi({
    reducerPath: 'toBeIntegrationsAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api',
        prepareHeaders: HEADERS,
    }),
    tagTypes: ['toBeIntegrations'],
    endpoints: (build) => ({
        getToBeIntegrations: build.query({
            query: ({ projectId, sortBy, orderBy }) =>
                `/projects/${projectId}/to-be-integrations?${sortBy ? `sort=${sortBy}` : ''}${
                    sortBy && orderBy ? `,${orderBy || 'DESC'}` : ''
                }`,
            keepUnusedDataFor: 0.0001,
            providesTags: (result) =>
                result?.content
                    ? [
                          ...result.content.map(({ id }) => ({ type: 'toBeIntegrations', id })),
                          { type: 'toBeIntegrations', id: 'LIST' },
                      ]
                    : [{ type: 'toBeIntegrations', id: 'LIST' }],
        }),
        createToBeIntegrations: build.mutation({
            query: ({ projectId, body }) => ({
                url: `/projects/${projectId}/to-be-integrations`,
                method: 'POST',
                body,
            }),
            invalidatesTags: [{ type: 'toBeIntegrations', id: 'LIST' }],
        }),

        getToBeIntegrationsOptions: build.query({
            query: (projectId) => `/projects/${projectId}/to-be-integrations/options`,
        }),

        sendToBeIntegrationsScheme: build.mutation({
            query: ({ projectId, body }) => ({
                url: `/projects/${projectId}/to-be-integrations/save-scheme`,
                method: 'POST',
                body,
            }),
        }),
    }),
});

export const {
    useGetToBeIntegrationsQuery,
    useCreateToBeIntegrationsMutation,
    useGetToBeIntegrationsOptionsQuery,
    useSendToBeIntegrationsSchemeMutation,
} = toBeIntegrationsAPI;
