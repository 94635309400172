import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { i18PAGES } from '../config/i18n/constants';
import QuestionnaireHeader from '../components/Questionnaire/QuestionnaireHeader';
import {
    checkIfPersonalInformationFilled,
    emptyModuleDistributionItem,
    validationSchema,
} from '../components/Questionnaire/helper';
import PersonalInformation from '../components/Questionnaire/PersonalInformation';
import { useToast } from '../hoc/toast/ToastProvider';
import WorkingTimeDistribution from '../components/Questionnaire/WorkingTimeDistribution';
import WarningModal from '../style/WarningModal/WarningModal';
import ToastWindow from '../style/ToastWindow/ToastWindow';
import { ReactComponent as ModalWarningIcon } from '../assets/icons/modalWarningIcon.svg';
import PersonalInformationReadOnly from '../components/Questionnaire/PersonalInformationReadOnly';
import WorkingTimeDistributionReadModeOnly from '../components/Questionnaire/WorkingTimeDistributionReadModeOnly';
import {
    useGetSurveyInfoQuery,
    useGetSurveyOptionsQuery,
    useSendSurveyPersonalInformationMutation,
    useSendSurveyResultMutation,
} from '../store/API/surveysAPI';
import { LayoutLoader } from '../components/Preloader/Preloader';
import i18n from '../config/i18n/i18n';
import FailedConfirmation from '../components/Confirmation/FailedConfirmation';
import { twoDecimalSum } from '../components/util/twoDecimalSum';

const QuestionnairePage = () => {
    const { t } = useTranslation(i18PAGES.QUESTIONNAIRE);
    const { successToast, errorToast } = useToast();

    const formRef = useRef();
    const [searchParams] = useSearchParams();
    const key = searchParams.get('key');
    const [isSubmitAnswer, setIsSubmitAnswer] = useState(false);
    const [isRepeatedPersonalInfoSave, setIsRepeatedPersonalInfoSave] = useState(false);
    const [formikRerenderCounter, setFormikRerenderCounter] = useState(0);

    const [isSendModalOpened, setIsSendModalOpened] = useState(false);
    const [isWarningModal, setIsWarningModal] = useState(false);
    const [progressValue, setProgressValue] = useState(0);
    const [readyModulesCount, setReadyModulesCount] = useState(0);
    const [isPersonalInfoSent, setIsPersonalInfoSent] = useState(false);

    const [isPersonalInfoFilled, setIsPersonalInfoFilled] = useState(false);

    const {
        data: options,
        isLoading: isOptionsLoading,
        error: optionsError,
    } = useGetSurveyOptionsQuery(key, { skip: !key, refetchOnMountOrArgChange: true });
    const { data, isLoading, error, refetch } = useGetSurveyInfoQuery(key, {
        skip: !key,
        refetchOnMountOrArgChange: true,
    });

    const [sendResult, { isLoading: isResultSending }] = useSendSurveyResultMutation();

    const [sendPersonalInformation, { isLoading: isPersonalInformationSending }] =
        useSendSurveyPersonalInformationMutation();

    const modulesOptions = useMemo(
        () => options?.modules?.map((m) => ({ label: m.title, value: m.moduleId })),
        [options?.modules]
    );

    const getProjectDropdownsDefaultNames = useCallback(
        (initialData) => {
            const companyName = options?.officesInfo?.companies.find(
                (company) => company.companyId === initialData?.companyId
            )?.companyName;

            const countryName = options?.officesInfo?.countries.find(
                (country) => country.countryId === initialData?.countryId
            )?.countryName;

            const cityName = options?.officesInfo?.cities.find((city) => city.cityId === initialData?.cityId)?.cityName;

            const officeName = options?.officesInfo?.offices.find(
                (office) => office.officeId === initialData?.officeId
            )?.officeName;

            return { companyName, countryName, cityName, officeName };
        },
        [options?.officesInfo]
    );

    const projectDropdownsDefaultNames = useMemo(
        () => getProjectDropdownsDefaultNames(data),
        [data, getProjectDropdownsDefaultNames]
    );

    const onCloseSendModalWindow = useCallback(() => {
        setIsSendModalOpened(false);
    }, []);

    const onDeclinedRepeatedPersonalInfoModal = useCallback(() => {
        setIsRepeatedPersonalInfoSave(false);
    }, []);

    const onAcceptRepeatedPersonalInfoModal = useCallback(() => {
        const personalInformationSendData = {
            key,
            body: {
                role: formRef.current?.values.role,
                companyId: formRef.current?.values.companyId,
                countryId: formRef.current?.values.countryId,
                cityId: formRef.current?.values.cityId,
                officeId: formRef.current?.values.officeId,
                fte: formRef.current?.values.fte,
            },
        };

        sendPersonalInformation(personalInformationSendData)
            .unwrap()
            .then(() => {
                refetch()
                    .unwrap()
                    .then(() => {
                        setFormikRerenderCounter((prev) => prev + 1);
                        setIsRepeatedPersonalInfoSave(false);
                    })
                    .catch((err) => {
                        errorToast(err?.data?.message || i18n.t('Something went wrong, try again later'));
                    });
            })
            .catch((err) => {
                errorToast(err?.data?.message || i18n.t('Something went wrong, try again later'));
            });
    }, [errorToast, key, refetch, sendPersonalInformation]);

    const onConfirmSendModal = useCallback(() => {
        sendResult({ key })
            .unwrap()
            .then(() => {
                refetch()
                    .unwrap()
                    .then(() => {
                        successToast(t('Result saved successfully'));
                    })
                    .catch((err) => {
                        errorToast(err?.data?.message || i18n.t('Something went wrong, try again later'));
                    })
                    .finally(() => {
                        setIsSendModalOpened(false);
                    });
            })
            .catch((err) => {
                errorToast(err?.data?.message || i18n.t('Something went wrong, try again later'));
            });
    }, [errorToast, key, refetch, sendResult, successToast, t]);

    const formikInitialValues = useMemo(() => {
        let readyModules = 0;
        setProgressValue(0);

        data?.workingTimeDistributionDTOS.forEach((distribution) => {
            const isBuildingBlocksFilled = distribution?.buildingBlocks.find(
                (bb) => bb.workPercent && bb.workPercent > 0
            );

            if (isBuildingBlocksFilled) {
                readyModules += 1;
                const result = distribution?.buildingBlocks?.reduce(
                    (res, item) => twoDecimalSum(res, item.workPercent),
                    0
                );
                setProgressValue((prev) => twoDecimalSum(prev, result));
            }
        });

        setReadyModulesCount(readyModules);

        return (
            {
                ...data,
                workingTimeDistributionDTOS:
                    data?.workingTimeDistributionDTOS?.length === 0
                        ? [emptyModuleDistributionItem]
                        : data?.workingTimeDistributionDTOS?.map((distr) => {
                              const isSaved = !!distr?.buildingBlocks.find(
                                  (bb) => bb.workPercent && bb.workPercent > 0
                              );
                              return { ...distr, isSaved };
                          }),
            } || {
                role: '',
                companyId: '',
                fte: '',
                countryId: '',
                cityId: '',
                officeId: '',
                workingTimeDistributionDTOS: [emptyModuleDistributionItem],
            }
        );
    }, [data, formikRerenderCounter]);

    useEffect(() => {
        if (progressValue > 100) {
            setIsWarningModal(true);
        } else {
            setIsWarningModal(false);
        }
    }, [progressValue]);

    useEffect(() => {
        if (checkIfPersonalInformationFilled(data)) {
            setIsPersonalInfoFilled(true);
        }
    }, [data]);

    if (!key || optionsError?.status === 422 || error?.status === 422) {
        return (
            <div className="min-h-[calc(100vh-300px)] h-[100%] flex items-center justify-center">
                <FailedConfirmation text={t('Link is not valid')} linkPath="/" buttonText={t('Back home')} />
            </div>
        );
    }

    if (isOptionsLoading || isLoading) {
        return <LayoutLoader />;
    }

    return (
        <div className="py-[55px] w-[100%]" key={formikRerenderCounter}>
            <Formik
                innerRef={formRef}
                onSubmit={(values, { setSubmitting }) => {
                    if (isSubmitAnswer) {
                        if (progressValue !== 100) {
                            setIsWarningModal(true);
                        } else {
                            setIsSendModalOpened(true);
                        }
                    }

                    if (isPersonalInfoSent) {
                        setIsPersonalInfoSent(false);
                        if (isPersonalInfoFilled) {
                            setIsRepeatedPersonalInfoSave(true);
                        } else {
                            const personalInformationSendData = {
                                key,
                                body: {
                                    role: values.role,
                                    companyId: values.companyId,
                                    countryId: values.countryId,
                                    cityId: values.cityId,
                                    officeId: values.officeId,
                                    fte: values.fte,
                                },
                            };

                            sendPersonalInformation(personalInformationSendData)
                                .unwrap()
                                .then(() => {
                                    setIsPersonalInfoFilled(true);
                                })
                                .catch((err) => {
                                    errorToast(err?.data?.message || i18n.t('Something went wrong, try again later'));
                                });
                        }
                    }

                    setSubmitting(false);
                }}
                validationSchema={validationSchema}
                initialValues={formikInitialValues}
            >
                {({ values, touched, setFieldValue, errors, isSubmitting, handleSubmit, handleBlur, handleChange }) => (
                    <Form onSubmit={handleSubmit}>
                        <QuestionnaireHeader
                            isSubmitting={
                                isResultSending ||
                                isSubmitting ||
                                readyModulesCount !== values?.workingTimeDistributionDTOS?.length ||
                                progressValue > 100
                            }
                            isReadMode={!!data?.sendRequest}
                            handleSubmit={handleSubmit}
                            setIsSubmitAnswer={setIsSubmitAnswer}
                            userData={{ fullName: data?.fullName, email: data?.email }}
                        />
                        {data?.sendRequest === true ? (
                            <>
                                <PersonalInformationReadOnly
                                    data={data}
                                    projectDropdownsDefaultNames={projectDropdownsDefaultNames}
                                />
                                <WorkingTimeDistributionReadModeOnly data={data} />
                            </>
                        ) : (
                            <>
                                <PersonalInformation
                                    setFieldValue={setFieldValue}
                                    handleBlur={handleBlur}
                                    touched={touched}
                                    errors={errors}
                                    handleChange={handleChange}
                                    values={values}
                                    options={options?.officesInfo}
                                    getProjectDropdownsDefaultNames={getProjectDropdownsDefaultNames}
                                    setIsPersonalInfoSent={setIsPersonalInfoSent}
                                />

                                <WorkingTimeDistribution
                                    className={isPersonalInfoFilled ? '' : 'opacity-[0.2]'}
                                    setIsSubmitAnswer={setIsSubmitAnswer}
                                    progressValue={progressValue}
                                    values={values}
                                    touched={touched}
                                    errors={errors}
                                    setFieldValue={setFieldValue}
                                    handleBlur={handleBlur}
                                    handleChange={handleChange}
                                    setProgressValue={setProgressValue}
                                    modulesList={options?.modules}
                                    modulesOptions={modulesOptions}
                                    setReadyModulesCount={setReadyModulesCount}
                                />
                            </>
                        )}
                    </Form>
                )}
            </Formik>

            <WarningModal
                isOpen={isSendModalOpened}
                setIsOpen={setIsSendModalOpened}
                warningText={t(
                    'Note that after sending you will not have access to any changes of the document. Do you want to continue?'
                )}
                headerText={t('Save the result')}
                acceptButtonText={t('Send')}
                declineButtonText={t('Cancel')}
                accept={onConfirmSendModal}
                decline={onCloseSendModalWindow}
                isAcceptDisabled={isResultSending}
            />

            <WarningModal
                isOpen={isRepeatedPersonalInfoSave}
                setIsOpen={setIsRepeatedPersonalInfoSave}
                warningText={t(
                    'Are you sure you want to save again the information? All the information  below will be changed'
                )}
                acceptButtonText={t('Save and next')}
                declineButtonText={t('Cancel')}
                accept={onAcceptRepeatedPersonalInfoModal}
                decline={onDeclinedRepeatedPersonalInfoModal}
                isAcceptDisabled={isPersonalInformationSending}
            />

            <ToastWindow isShowCloseIcon={false} isOpen={isWarningModal}>
                <div className="flex flex-col items-center justify-center text-int-white-main">
                    <div className="flex items-center text-[16px] font-Lexend500">
                        <ModalWarningIcon />
                        <div className="ml-[10px]">{t('Overfilled')}</div>
                    </div>
                    <div className="text-[14px]">{t('Total percentage amount should be 100%')}</div>
                </div>
            </ToastWindow>
        </div>
    );
};

export default QuestionnairePage;
