import { Suspense } from 'react';
import { useRoutes } from 'react-router-dom';
import routes from './routes';
import './config/i18n/i18n';
import './fonts/fonts.css';
import { PageLoader } from './components/Preloader/Preloader';

function App() {
    const element = useRoutes(routes);

    return (
        <Suspense fallback={<PageLoader />}>
            <div className="min-h-screen bg-int-main-black">{element}</div>
        </Suspense>
    );
}

export default App;
