import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import ProjectInfoPageHeader from '../components/Platform/Common/ProjectInfoPageHeader';
import { PAGE_IDENTIFIER, projectManagersUserRoles } from '../components/util/constants';
import { i18PAGES } from '../config/i18n/constants';
import BuildingBlockCard from '../components/Platform/ProviderSelection/BuildingBlockCard/BuildingBlockCard';
import BuildingBlocksList from '../components/Platform/ProviderSelection/BuildingBlocksList';
import ExpirationModulesPopUp from '../components/Platform/ProviderSelection/ExpirationModules/ExpirationModulesPopUp';
import { useGetBuildingBlockListQuery, useGetExpiredModulesQuery } from '../store/API/providerSelectionAPI';
import { LayoutLoader } from '../components/Preloader/Preloader';
import { useEditMode } from '../components/util/useEditMode';
import { useAuth } from '../store/hooks/useAuth';

const ProviderSelectionPage = () => {
    const { t } = useTranslation(i18PAGES.OPTIONS);

    const [searchParams] = useSearchParams();
    const projectId = searchParams.get('projectId');
    const { projectRole } = useAuth();

    const [currentBuildingBlockId, setCurrentBuildingBlockId] = useState();
    const { data: buildingBlocksList, isLoading: isBuildingBlockListLoading } = useGetBuildingBlockListQuery(projectId);
    const { data: expiredModulesList, isLoading: isExpiredModulesListLoading } = useGetExpiredModulesQuery(projectId);
    const [isEditMode, setIsEditMode] = useState(false);

    useEditMode(isEditMode);

    useEffect(() => {
        if (buildingBlocksList?.length && !currentBuildingBlockId) {
            setCurrentBuildingBlockId(buildingBlocksList[0].id);
        }
    }, [currentBuildingBlockId, buildingBlocksList]);

    if (isBuildingBlockListLoading || isExpiredModulesListLoading) {
        return <LayoutLoader />;
    }

    return (
        <>
            <div className="pl-[50px] pb-[80px] pr-[25px] pt-[70px] min-h-[100vh] text-int-white-main">
                <ProjectInfoPageHeader
                    nextLink="/options"
                    pageIdentifier={PAGE_IDENTIFIER.SELECT_PROVIDER}
                    projectId={Number(projectId)}
                    pageName={t('Provider selection')}
                />

                <div className="mt-[30px] py-[8px] px-[16px] mb-[40px] w-[781px] min-h-[50px] flex items-center bg-[#29323F80] rounded-[8px] font-Lexend400 text-[14px] leading-[24px] text-int-white-main">
                    {t(
                        'Here you have to select providers that would cover the selected building blocks. Though you are not restricted but we recommend to choose up to 5 providers to make the result more consistent.'
                    )}
                </div>

                <div className="text-int-white-main flex items-start">
                    <BuildingBlocksList
                        buildingBlocksList={buildingBlocksList}
                        currentBuildingBlockId={currentBuildingBlockId}
                        setCurrentBuildingBlockId={setCurrentBuildingBlockId}
                        isEditMode={isEditMode}
                    />
                    <BuildingBlockCard
                        projectId={projectId}
                        buildingBlockId={currentBuildingBlockId}
                        isEditMode={isEditMode}
                        setIsEditMode={setIsEditMode}
                    />
                </div>
            </div>
            {expiredModulesList?.length > 0 && projectRole !== projectManagersUserRoles.ROLE_REVIEWER && (
                <ExpirationModulesPopUp expiredModulesList={expiredModulesList} />
            )}
        </>
    );
};

export default ProviderSelectionPage;
