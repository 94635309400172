import React from 'react';
import { bool, func, string } from 'prop-types';
import ModalWindow from '../ModalWindow/ModalWindow';

const WarningModal = ({
    acceptButtonText,
    declineButtonText,
    warningText,
    headerText,
    isOpen,
    setIsOpen,
    accept,
    decline,
    isAcceptDisabled,
}) => (
    <ModalWindow isOpen={isOpen} setIsOpen={setIsOpen}>
        <div className="w-[470px] m-auto font-Lexend400 text-[18px] leading-[28px] text-int-white-main">
            {headerText && (
                <div className="text-center mt-[-20px] font-Lexend500 text-[24px] leading-[24px] mb-[40px]">
                    {headerText}
                </div>
            )}
            <div className="text-center w-[460px]">{warningText}</div>
            <div className="flex justify-between leading-[18px] mt-[40px] mb-[15px]">
                <button
                    className={`text-int-white-main hover:opacity-[0.8] border border-int-white-main rounded-[8px] w-[210px] h-[50px] font-Lexend400 `}
                    type="button"
                    onClick={decline}
                >
                    {declineButtonText}
                </button>

                <button
                    className={`text-int-white-main hover:opacity-[0.8] border border-int-grapefruit bg-int-grapefruit rounded-[8px] w-[210px] h-[50px] font-Lexend400 ${
                        isAcceptDisabled && 'opacity-[0.7]'
                    } `}
                    type="button"
                    onClick={accept}
                    disabled={isAcceptDisabled}
                >
                    {acceptButtonText}
                </button>
            </div>
        </div>
    </ModalWindow>
);

export default WarningModal;

WarningModal.propTypes = {
    acceptButtonText: string.isRequired,
    declineButtonText: string.isRequired,
    warningText: string.isRequired,
    headerText: string,
    isOpen: bool.isRequired,
    setIsOpen: func.isRequired,
    accept: func.isRequired,
    decline: func.isRequired,
    isAcceptDisabled: bool,
};
