import React, { useMemo, useState } from 'react';
import { bool, func, number, oneOfType, string } from 'prop-types';
import classnames from 'classnames';
import { ReactComponent as ErrorIcon } from '../../../../assets/icons/errorIcon.svg';
import { ReactComponent as OpenEyeIcon } from '../../../../assets/icons/openEyeIcon.svg';
import { ReactComponent as CloseEyeIcon } from '../../../../assets/icons/closeEyeIcon.svg';
import { ReactComponent as DeleteTextIcon } from '../../../../assets/icons/deleteText.svg';

const FTableInput = ({
    limit,
    isError,
    errorText,
    type,
    placeholder,
    name,
    className,
    isPassword,
    value,
    handleChange,
    handleBlur,
    handleFocus,
    isNoAutoComplete = false,
    setFieldValue,
}) => {
    const [showPassword, setShowPassword] = useState(false);
    const [focused, setFocused] = useState(false);
    const [isOpenHelpErrorInfo, setIsOpenHelpErrorInfo] = useState(false);

    const showPasswordToggle = () => {
        setShowPassword((prev) => !prev);
    };

    const onBlurHandler = (e) => {
        handleBlur(e);
        setFocused(false);
    };

    const onFocusHandler = () => {
        if (handleFocus) {
            handleFocus();
        }
        setFocused(true);
    };

    const inputClass = useMemo(
        () =>
            classnames(
                `border inline-block w-[100%] rounded-[4px] bg-[#2A3143] pl-[8px] ${
                    isError ? 'pr-[32px]' : 'pr-[25px]'
                } py-[12px]`,
                {
                    'border-[#EDF1FD]': !isError && focused,
                    'border-error': isError,
                }
            ),
        [isError, focused]
    );

    const onDeleteText = () => {
        setFieldValue(name, '');
    };

    return (
        <div className={`relative ${className || ''}`}>
            <input
                autoComplete={isNoAutoComplete ? 'new-password' : ''}
                type={!isPassword ? type : `${showPassword ? 'text' : 'password'}`}
                placeholder={placeholder}
                name={name}
                value={value}
                onChange={handleChange}
                onBlur={onBlurHandler}
                onFocus={onFocusHandler}
                className={inputClass}
            />

            {isError && (
                <div className={`absolute ${isPassword ? 'right-[30px]' : 'right-[8px]'}  top-[14px]`}>
                    <div
                        className={errorText && 'cursor-pointer'}
                        onMouseEnter={() => setIsOpenHelpErrorInfo(true)}
                        onMouseLeave={() => setIsOpenHelpErrorInfo(false)}
                    >
                        <ErrorIcon />
                    </div>
                    {isOpenHelpErrorInfo && errorText && (
                        <div
                            className="text-[12px] text-error font-Lexend300 absolute
                     bg-[#222730] p-[10px] rounded-[2px] z-10 bottom-[23px] right-0 whitespace-nowrap"
                        >
                            {errorText}
                        </div>
                    )}
                </div>
            )}
            {isPassword && (
                <div className="absolute right-[8px] top-[17px] cursor-pointer" onClick={showPasswordToggle}>
                    {showPassword ? <CloseEyeIcon /> : <OpenEyeIcon />}
                </div>
            )}
            {limit && String(value).length > 0 && (
                <div className="absolute right-[4px] bottom-[3px] text-int-white-secondary text-[10px] leading-[10px] font-Lexend400">
                    {String(value).length}/{limit}
                </div>
            )}
            {!isError && String(value).length > 0 && (
                <div
                    className="absolute right-[8px] top-[14px] h-[14px] w-[14px] hover:bg-int-gray-secondary flex items-center justify-center cursor-pointer"
                    onClick={onDeleteText}
                >
                    <DeleteTextIcon width={10} height={10} fill="#EDF1FD" />
                </div>
            )}
        </div>
    );
};

export default FTableInput;

FTableInput.propTypes = {
    isError: bool,
    isPassword: bool,
    errorText: string,
    type: string.isRequired,
    placeholder: string,
    name: string.isRequired,
    className: string,
    value: oneOfType([string, number]).isRequired,
    handleChange: func.isRequired,
    handleBlur: func,
    handleFocus: func,
    isNoAutoComplete: bool,
    limit: number,
    setFieldValue: func.isRequired,
};
