import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PrimaryButton } from '../style/Button/Button';
import i18n from '../config/i18n/i18n';
import FailedConfirmation from '../components/Confirmation/FailedConfirmation';
import { useCancelInvoiceByTokenMutation } from '../store/API/invoiceAPI';
import { useToast } from '../hoc/toast/ToastProvider';
import http from '../components/util/http';
import { PageLoader } from '../components/Preloader/Preloader';

const PaymentNotificationPage = () => {
    const { token } = useParams();
    const navigate = useNavigate();
    const [isTokenValid, setIsTokenValid] = useState(false);
    const [isCancelPayment, setIsCancelPayment] = useState(false);
    const [isLoadingCancel, setIsLoadingCancel] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const { errorToast } = useToast();

    const [cancelInvoiceByToken] = useCancelInvoiceByTokenMutation();

    useEffect(() => {
        http.post('/payment-remind/verify', { key: token })
            .then((res) => {
                if (res.status === 200) {
                    setIsTokenValid(true);
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    const handleCancelRequest = () => {
        setIsLoadingCancel(true);
        cancelInvoiceByToken({ token })
            .unwrap()
            .then(() => {
                setIsCancelPayment(true);
            })
            .catch(() => {
                errorToast(i18n.t('Error cancel invoice or token expired'));
            })
            .finally(() => {
                setIsLoadingCancel(false);
            });
    };

    const handleOpenInvoice = () => {
        navigate(`/invoice/${token}`);
    };

    const handleGoToHomePage = () => {
        navigate(`/`);
    };

    if (isLoading) return <PageLoader />;

    if (isTokenValid)
        return (
            <div className="min-h-[calc(calc(calc(100vh-theme(spacing.header-height))-theme(spacing.external-footer-height))-30px)] h-[100%] flex items-center justify-center">
                {isCancelPayment ? (
                    <div className="bg-int-gray-main m-auto px-[30px] pt-[30px] pb-[40px] relative rounded-[20px] max-w-[500px]">
                        <div className="text-[24px] leading-[24px] font-Lexend500 text-int-white-main mb-[16px] text-center">
                            {i18n.t('Request is canceled')}
                        </div>
                        <div className="text-[14px] leading-[22px] font-Lexend300 text-int-white-main text-center">
                            {i18n.t('Your request on Subscription was successfully canceled.')}{' '}
                            {i18n.t('You can always come back and resubscribe if you change your mind in the future.')}{' '}
                            {i18n.t(
                                'We appreciate your business and hope that you will consider using our service again in the future.'
                            )}
                        </div>
                        <div className="flex justify-center mt-[50px]">
                            <PrimaryButton isPink className="h-[50px] w-[210px]" handleClick={handleGoToHomePage}>
                                <div className="text-[18px] leading-[18px] font-Lexend400">
                                    {i18n.t('Go to Main page')}
                                </div>
                            </PrimaryButton>
                        </div>
                    </div>
                ) : (
                    <div className="bg-int-gray-main m-auto px-[30px] pt-[30px] pb-[40px] relative rounded-[20px] max-w-[500px]">
                        <div className="text-[24px] leading-[24px] font-Lexend500 text-int-white-main mb-[16px] text-center">
                            {i18n.t('Waiting for Payment')}
                        </div>
                        <div className="text-[14px] leading-[22px] font-Lexend300 text-int-white-main mb-[50px] text-center">
                            {i18n.t('If you want to continue subscription please send Invoice to your Bank.')}{' '}
                            {i18n.t('If you do not want to continue - cancel the Subscription request.')}
                        </div>
                        <div className="flex justify-between">
                            <PrimaryButton
                                isGrey
                                className="h-[50px] w-[210px]"
                                disabled={isLoadingCancel}
                                handleClick={handleCancelRequest}
                            >
                                <div className="text-[18px] leading-[18px] font-Lexend400">
                                    {i18n.t('Cancel Request')}
                                </div>
                            </PrimaryButton>
                            <PrimaryButton isPink className="h-[50px] w-[210px]" handleClick={handleOpenInvoice}>
                                <div className="text-[18px] leading-[18px] font-Lexend400">
                                    {i18n.t('Open Invoice')}
                                </div>
                            </PrimaryButton>
                        </div>
                    </div>
                )}
            </div>
        );

    return (
        <div className="min-h-[calc(calc(calc(100vh-theme(spacing.header-height))-theme(spacing.external-footer-height))-30px)] h-[100%] flex items-center justify-center">
            <FailedConfirmation
                buttonText={i18n.t('Go to Homepage')}
                linkPath="/"
                text={i18n.t('Token is expired or not valid. Please try again or return to the Home page')}
            />
        </div>
    );
};

export default PaymentNotificationPage;
