import React from 'react';
import HRcompareListItem from './HRcompareListItem';
import HRCompareInfoAccordion from './HRCompareInfoAccordion';

const ToolModulePromoteBlockAccordion = () => (
    <HRCompareInfoAccordion headerText="Diese Module bietet das Tool an">
        <div className="grid grid-cols-3 gap-x-[60px] min-w-[1140px] text-[16px] font-Lexend300 leading-[24px] text-int-white-secondary">
            <div className="pl-[20px]">
                <HRcompareListItem title="Arbeitszeitmanagement" />
                <HRcompareListItem title="Workforce Analytics" />
                <HRcompareListItem title="Vergütungsmanagement" />
                <HRcompareListItem title="Personaladministration" />
                <HRcompareListItem title="Prozessmanagement" />
                <HRcompareListItem title="Personaladministration" />
                <HRcompareListItem title="Employer Branding" />
            </div>
            <div className="pl-[20px]">
                <HRcompareListItem title="Arbeitszeitmanagement" />
                <HRcompareListItem title="Workforce Analytics" />
                <HRcompareListItem title="Vergütungsmanagement" />
                <HRcompareListItem title="Personaladministration" />
                <HRcompareListItem title="Prozessmanagement" />
                <HRcompareListItem title="Personaladministration" />
                <HRcompareListItem title="Employer Branding" />
                <HRcompareListItem title="Entgeltabrechnung" />
                <HRcompareListItem title="Gesundheitsmanagement" />
                <HRcompareListItem title="Kompetenzmanagement" />
            </div>
            <div className="pl-[20px]">
                <HRcompareListItem title="Entgeltabrechnung" />
                <HRcompareListItem title="Gesundheitsmanagement" />
                <HRcompareListItem title="Kompetenzmanagement" />
                <HRcompareListItem title="Learning Management" />
                <HRcompareListItem title="Mitarbeitereinbindung" />
                <HRcompareListItem title="Personaladministration" />
                <HRcompareListItem title="Personaladministration" />
                <HRcompareListItem title="Karriere&Nachfolgemanagement" />
                <HRcompareListItem title="Onboarding" />
            </div>
        </div>
    </HRCompareInfoAccordion>
);

export default ToolModulePromoteBlockAccordion;
