import React from 'react';
import { bool, func, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ReactComponent as HRCloseCompareIcon } from '../../assets/icons/HRCloseCompareIcon.svg';
import { i18PAGES } from '../../config/i18n/constants';

const HRCompareItem = ({ addLinkBlock, title, image, handleAddCompareBlockClick, handleCloseIconClick }) => {
    const { t } = useTranslation(i18PAGES.HR_TOOLS);

    if (addLinkBlock === true) {
        return (
            <div
                className="flex justify-center items-center w-[340px] h-[330px] bg-int-gray-main rounded-[10px] shadow-block cursor-pointer"
                onClick={handleAddCompareBlockClick}
                aria-hidden="true"
            >
                <div className="text-[18px] font-Lexend400">{`+ ${t('Weiteres Tool hinzufügen')}`}</div>
            </div>
        );
    }

    return (
        <div className="flex flex-col justify-between  w-[340px] h-[330px] bg-int-gray-main rounded-[10px] shadow-block p-[20px] relative">
            <div
                className="absolute right-[20px] top-[20px] cursor-pointer"
                onClick={handleCloseIconClick}
                aria-hidden="true"
            >
                <HRCloseCompareIcon />
            </div>

            <div className="text-[18px] text-int-white-main font-Lexend400 pr-[55px]">{title}</div>
            <div className="h-[219px] w-[300px]" style={{ backgroundImage: `url(${image})` }} />
        </div>
    );
};

HRCompareItem.propTypes = {
    addLinkBlock: bool,
    image: string,
    title: string,
    handleAddCompareBlockClick: func,
    handleCloseIconClick: func,
};

export default HRCompareItem;
