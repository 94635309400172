import React from 'react';
import { useTranslation } from 'react-i18next';
import { bool, func, number } from 'prop-types';
import ModulesBuyButton from './ModulesBuyButton';
import { i18PAGES } from '../../config/i18n/constants';

const ModulesBuyBlock = (props) => {
    const { addedModules, price, totalPrice, handlePurchase, isAdminModules } = props;

    const { t } = useTranslation(i18PAGES.MODULES);

    return (
        <div className="rounded-lg overflow-hidden shadow-filters w-[260px]">
            <div className="bg-int-gray-main p-[20px]">
                <div className="text-int-white-main text-[16px] font-Lexend500 leading-[24px]">{t('Module Price')}</div>
                <div className="text-[48px] text-int-white-main font-Righteous400">€ {price}</div>
                <div className="text-[14px] mt-[-10px] mb-[30px] font-Lexend300 leading-[24px] text-int-white-secondary">
                    {t('for 1 module')}
                </div>

                <div className="mb-[30px]">
                    <div className="font-Lexend400 text-[14px] leading-[16px] text-int-white-main mb-[4px]">
                        {t('Modules added')}
                    </div>
                    <div className="w-[220px] h-[42px] rounded-[8px] bg-int-gray-secondary flex items-center pl-[24px] text-int-white-main font-Lexend400 leading-[14px]">
                        {addedModules}
                    </div>
                </div>

                {!isAdminModules && addedModules < 2 && (
                    <div className="mb-[30px] font-Lexend300 text-[16px] leading-[24px] text-int-white-secondary">
                        {t('Buying only 1 module you get a')}{' '}
                        <span className="text-int-white-main font-Lexend500">{t('50% discount')}</span>
                    </div>
                )}

                <div className="flex items-center mb-[6px]">
                    <div className="font-Lexend500 text-[16px] leading-[24px] text-int-white-main mr-[5px]">
                        {t('Subscription')}
                    </div>
                    <div className="text-int-white-secondary font-Lexend300 text-[16px] leading-[24px]">
                        {t('(fixed)')}
                    </div>
                </div>
                <div className="font-Lexend600 text-[24px] leading-[24px] text-int-white-main">{t('6 months')}</div>
                <div className="mt-[45px] flex justify-between text-int-white-main">
                    <div className="font-Lexend400">{t('Total price')}</div>
                    <div className="text-[20px] text-int-white-main font-Righteous400 leading-[26px]">
                        {totalPrice ? `€ ${totalPrice}` : '-'}
                    </div>
                </div>
            </div>
            <ModulesBuyButton isActive={!!totalPrice} handlePurchase={handlePurchase} />
        </div>
    );
};

export default ModulesBuyBlock;

ModulesBuyBlock.propTypes = {
    addedModules: number.isRequired,
    price: number.isRequired,
    totalPrice: number,
    handlePurchase: func.isRequired,
    isAdminModules: bool,
};
