import React, { useEffect, useMemo, useState } from 'react';
import { Formik } from 'formik';
import { func } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { COMPANY_STATUS, SETUP_PAGES, STEP_TYPE } from '../../util/constants';
import StepLayout from '../../SetupCompanies/StepLayout';
import { useCreateProject } from '../../../store/hooks/useCreateProject';
import ToastWindow from '../../../style/ToastWindow/ToastWindow';
import { ReactComponent as ModalWarningIcon } from '../../../assets/icons/modalWarningIcon.svg';
import { useLinkCompaniesProjectMutation } from '../../../store/API/createProjectAPI';
import { useGetCompaniesQuery } from '../../../store/API/companiesAPI';
import { i18PAGES } from '../../../config/i18n/constants';
import ChangeStepInfoModalContent from '../../SetupCompanies/ChangeStepInfoModalContent';
import i18n from '../../../config/i18n/i18n';
import ModalWindow from '../../../style/ModalWindow/ModalWindow';
import { useToast } from '../../../hoc/toast/ToastProvider';
import Step2FormProject from './Step2FormProject';

const Step2AddCompany = ({ setSetupCurrentPage }) => {
    const { t } = useTranslation(i18PAGES.PROJECTS_SETUP);
    const { errorToast } = useToast();
    const { createProjectInfo, setCompanies } = useCreateProject();

    const [isOpenWarning, setIsOpenWarning] = useState(false);
    const [initialValueCompany, setInitialValueCompany] = useState([]);
    const [isBackButtonPressed, setIsBackButtonPressed] = useState(false);
    const [isStepBackAgree, setIsStepBackAgree] = useState(false);

    const { data: company, isLoading } = useGetCompaniesQuery({}, { refetchOnMountOrArgChange: true });
    const [linkCompaniesProject] = useLinkCompaniesProjectMutation();

    const onBackButtonPress = () => {
        setIsBackButtonPressed(true);
    };

    useEffect(() => {
        if (isStepBackAgree === true) {
            setSetupCurrentPage(SETUP_PAGES.STEP_1);
        }
    }, [isStepBackAgree, setSetupCurrentPage]);

    const activeCompaniesIdArray = useMemo(() => {
        if (createProjectInfo?.companyInfo?.length) {
            return createProjectInfo?.companyInfo?.map((c) => c.id);
        }
    }, [createProjectInfo?.companyInfo]);

    useEffect(() => {
        const convertCompanyArray = company
            ?.filter((c) => c.status === COMPANY_STATUS.COMPLETED)
            ?.map((company) => ({
                id: company.id,
                name: company.name,
                isChecked: activeCompaniesIdArray?.includes(company.id),
                countries: company.countries.map((country) => ({
                    id: country.id,
                    name: country.name,

                    offices: [
                        ...country.cities.map((city) =>
                            city.offices.map((office) => ({
                                id: office.id,
                                name: office.name,
                                city: office.cityDetails.name,
                                isChecked: false,
                            }))
                        ),
                    ].flat(),
                })),
            }));

        setInitialValueCompany(convertCompanyArray);
    }, [company]);

    return (
        <StepLayout currentStepNumber={2} height={270} type={STEP_TYPE.PROJECT}>
            <Formik
                onSubmit={(values, { setSubmitting }) => {
                    if (values.companies.filter((e) => e.isChecked === true).length > 0) {
                        const companyCheckedIds = values.companies
                            .filter((item) => item.isChecked === true)
                            .map((item) => item.id);
                        linkCompaniesProject({
                            companyIds: companyCheckedIds,
                            projectId: createProjectInfo.projectId,
                        })
                            .unwrap()
                            .then((data) => {
                                setCompanies(values);
                                setSetupCurrentPage(SETUP_PAGES.STEP_3);
                            })
                            .catch((err) => {
                                errorToast(t('Error add company!'));
                            })
                            .finally(() => {
                                setSubmitting(false);
                            });
                    } else {
                        setSubmitting(false);
                        setIsOpenWarning(true);
                    }
                }}
                initialValues={{
                    companies:
                        createProjectInfo.companyInfo?.companies && createProjectInfo.companyInfo?.companies.length > 0
                            ? createProjectInfo.companyInfo.companies
                            : initialValueCompany,
                }}
                enableReinitialize
            >
                {({ values, isSubmitting, handleSubmit }) => (
                    <Step2FormProject
                        handleSubmit={handleSubmit}
                        isSubmitting={isSubmitting}
                        values={values}
                        isLoading={isLoading}
                        onBackButtonPress={onBackButtonPress}
                    />
                )}
            </Formik>
            <ToastWindow
                isOpen={isOpenWarning}
                isShowCloseIcon
                handleCloseIconClick={() => {
                    setIsOpenWarning(false);
                }}
            >
                <div className="flex flex-col items-center justify-center text-int-white-main">
                    <div className="flex items-center text-[16px] font-Lexend500">
                        <ModalWarningIcon />
                        <div className="ml-[10px]">{t('No selected elements')}</div>
                    </div>
                    <div className="text-[14px]">{t('Select companies to go to the next step')}</div>
                </div>
            </ToastWindow>

            <ModalWindow isOpen={isBackButtonPressed} setIsOpen={setIsBackButtonPressed}>
                <ChangeStepInfoModalContent
                    setIsAgree={setIsStepBackAgree}
                    setIsModalOpened={setIsBackButtonPressed}
                    text={i18n.t('Are you sure you want to go back? Your current data can be lost')}
                />
            </ModalWindow>
        </StepLayout>
    );
};
export default Step2AddCompany;

Step2AddCompany.propTypes = {
    setSetupCurrentPage: func,
};
