import { bool, string } from 'prop-types';
import React from 'react';
import Preloader from '../../components/Preloader/Preloader';

const LoadingAndTextOverLayer = ({ text, isOpen = false }) => (
    <>
        {isOpen && (
            <div
                className="flex items-center justify-center w-screen h-screen fixed top-[0] left-[0] z-10"
                style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
            >
                <div className="flex-col items-center justify-center">
                    <div className="text-[24px] leading-[24px] font-Lexend500 text-int-white-main mb-[40px]">
                        {text}
                    </div>
                    <div className="flex items-center justify-center">
                        <Preloader />
                    </div>
                </div>
            </div>
        )}
    </>
);
LoadingAndTextOverLayer.propTypes = {
    isOpen: bool.isRequired,
    text: string,
};

export default LoadingAndTextOverLayer;
