import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { bool, func, number } from 'prop-types';
import { i18PAGES } from '../../config/i18n/constants';
import { addedZeroIfNeedForNumber } from '../util/dateFormatter';

const SendBlockWithCounter = (props) => {
    const { isResendActive, resendLink, seconds, isLoading } = props;
    const { t } = useTranslation(i18PAGES.AUTH);

    return (
        <div className="mt-[24px]">
            <div className="text-int-white-main text-[16px] font-Lexend400">{t('E-Mail nicht erhalten?')}</div>
            <button
                type="button"
                disabled={!isResendActive || isLoading}
                onClick={resendLink}
                className={`mt-[24px] mb-[16px] text-[18px] font-Lexend400 leading-[18px] px-[32px] py-[16px] ${
                    isResendActive ? 'bg-int-gray-secondary text-int-white-main' : 'bg-disable text-int-white-secondary'
                } rounded-[8px]`}
            >
                {t('Senden Sie eine E-Mail erneut')}
            </button>
            {!isResendActive ? (
                <div className="font-Lexend400 mb-[32px] text-[16px] leading-[16px] text-int-white-main">
                    {t('Resend email in')} {seconds >= 0 ? addedZeroIfNeedForNumber(seconds) : '60'} {t('sec')}
                </div>
            ) : (
                <div className="h-[16px] mb-[32px]" />
            )}
        </div>
    );
};

export default memo(SendBlockWithCounter);

SendBlockWithCounter.propTypes = {
    isResendActive: bool.isRequired,
    resendLink: func.isRequired,
    seconds: number,
    isLoading: bool,
};
