import React from 'react';
import { FieldArray, Form } from 'formik';
import { bool, func, object } from 'prop-types';
import { useTranslation } from 'react-i18next';
import Preloader from '../../Preloader/Preloader';
import Checkbox from '../../../style/Checkbox/Checkbox';
import { i18PAGES } from '../../../config/i18n/constants';

const Step2FormProject = ({
    handleSubmit,
    onBackButtonPress,
    isSubmitting,
    isLoading,
    values,
    isDisabledAllCheckbox = false,
}) => {
    const { t } = useTranslation(i18PAGES.PROJECTS_SETUP);

    return (
        <Form onSubmit={handleSubmit}>
            <div className="flex justify-between items-center mb-[24px]">
                <div className="font-Lexend500 text-[24px] leading-[24px] text-int-white-main">{t('Add Company')}</div>
                <div>
                    <button
                        className="text-int-white-main border border-int-gray-secondary rounded-[8px] w-[140px] h-[42px] font-Lexend400"
                        type="button"
                        onClick={onBackButtonPress}
                    >
                        {t('Go Back')}
                    </button>
                    <button
                        className={`text-int-white-main border border-int-grapefruit bg-int-grapefruit ml-[20px] rounded-[8px] w-[140px] h-[42px] font-Lexend400 ${
                            isSubmitting ? 'opacity-[0.5]' : ''
                        }`}
                        type="submit"
                        disabled={isSubmitting}
                    >
                        {isDisabledAllCheckbox ? <div>{t('Next')}</div> : <div>{t('Save & Next')}</div>}
                    </button>
                </div>
            </div>

            {isLoading ? (
                <div className="mt-[40px] flex justify-center">
                    <Preloader />
                </div>
            ) : (
                <div className="overflow-y-auto max-h-[150px] pr-[20px] mr-[-20px]" id="primary-scroll">
                    <FieldArray
                        name="companies"
                        render={() => (
                            <div className="grid grid-cols-3 ">
                                {values.companies?.map((item, index) => (
                                    <div key={index} className="mr-[40px]">
                                        <Checkbox
                                            name={`companies.${index}.isChecked`}
                                            id={`companies.${index}.isChecked`}
                                            label={values.companies[index].name}
                                            className="text-[16px]"
                                            value={values.companies[index].isChecked}
                                            disabled={isDisabledAllCheckbox}
                                        />
                                    </div>
                                ))}
                            </div>
                        )}
                    />
                </div>
            )}
        </Form>
    );
};

export default Step2FormProject;

Step2FormProject.propTypes = {
    handleSubmit: func,
    isSubmitting: bool,
    onBackButtonPress: func,
    values: object,
    isLoading: bool,
    isDisabledAllCheckbox: bool,
};
