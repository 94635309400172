import React from 'react';
import { useTranslation } from 'react-i18next';
import { bool, func, object } from 'prop-types';
import { i18PAGES } from '../../../../../config/i18n/constants';
import { Orientation } from '../../../../util/constants';
import RadioInput from '../../../../../style/RadioInput/RadioInput';

const MarketSituationEditItem = ({ values, isSubmitPressed, errors, setFieldValue }) => {
    const { t } = useTranslation(i18PAGES.COMPANIES_INFO);
    return (
        <div className="px-[30px] border-r border-int-gray-secondary">
            <div className="text-[14px] leading-[19px] font-Lexend600 uppercase">{t('Arbeitsmarktsituation')}</div>
            <div className="flex items-center mt-[30px]">
                <div>
                    <div className="leading-[16px] text-[14px] font-Lexend400 text-int-white-secondary uppercase">
                        {t('AS Stammhaus')}
                        {isSubmitPressed && errors?.headOfficeOrientation && (
                            <div className="mt-[10px] text-[14px] normal-case text-error">
                                {errors?.headOfficeOrientation}
                            </div>
                        )}
                    </div>
                    <div className="mt-[16px]">
                        <RadioInput
                            name="headOfficeOrientation"
                            value={Orientation.EMPLOYEE_FRIENDLY}
                            label="Arbeitnehmerfreundlich"
                            setFieldValue={setFieldValue}
                            isChecked={values?.headOfficeOrientation === Orientation.EMPLOYEE_FRIENDLY}
                        />
                    </div>
                    <div className="mt-[16px]">
                        <RadioInput
                            name="headOfficeOrientation"
                            value={Orientation.EMPLOYER_FRIENDLY}
                            label="Arbeitgeberfreundlich"
                            setFieldValue={setFieldValue}
                            isChecked={values?.headOfficeOrientation === Orientation.EMPLOYER_FRIENDLY}
                        />
                    </div>
                    <div className="mt-[16px]">
                        <RadioInput
                            name="headOfficeOrientation"
                            value={Orientation.BALANCED}
                            label="Ausgewogen"
                            setFieldValue={setFieldValue}
                            isChecked={values?.headOfficeOrientation === Orientation.BALANCED}
                        />
                    </div>
                </div>
                <div className="ml-[70px]">
                    <div className="leading-[16px] text-[14px] font-Lexend400 text-int-white-secondary uppercase">
                        {t('AS Regional')}
                        {isSubmitPressed && errors?.regionalOrientation && (
                            <div className="mt-[10px] text-[14px] normal-case text-error">
                                {errors?.regionalOrientation}
                            </div>
                        )}
                    </div>
                    <div className="mt-[16px]">
                        <RadioInput
                            name="regionalOrientation"
                            value={Orientation.EMPLOYEE_FRIENDLY}
                            label="Arbeitnehmerfreundlich"
                            setFieldValue={setFieldValue}
                            isChecked={values?.regionalOrientation === Orientation.EMPLOYEE_FRIENDLY}
                        />
                    </div>
                    <div className="mt-[16px]">
                        <RadioInput
                            name="regionalOrientation"
                            value={Orientation.EMPLOYER_FRIENDLY}
                            label="Arbeitgeberfreundlich"
                            setFieldValue={setFieldValue}
                            isChecked={values?.regionalOrientation === Orientation.EMPLOYER_FRIENDLY}
                        />
                    </div>
                    <div className="mt-[16px]">
                        <RadioInput
                            name="regionalOrientation"
                            value={Orientation.BALANCED}
                            label="Ausgewogen"
                            setFieldValue={setFieldValue}
                            isChecked={values?.regionalOrientation === Orientation.BALANCED}
                        />
                    </div>
                </div>
            </div>
            <div className="mt-[30px]">
                <div className="mt-[30px] flex">
                    <div>
                        <div className="leading-[16px] text-[14px] font-Lexend400 text-int-white-secondary uppercase">
                            {t('AS National')}
                            {isSubmitPressed && errors?.nationalOrientation && (
                                <div className="mt-[10px] text-[14px] normal-case text-error">
                                    {errors?.nationalOrientation}
                                </div>
                            )}
                        </div>
                        <div className="mt-[16px]">
                            <RadioInput
                                name="nationalOrientation"
                                value={Orientation.EMPLOYEE_FRIENDLY}
                                label="Arbeitnehmerfreundlich"
                                setFieldValue={setFieldValue}
                                isChecked={values?.nationalOrientation === Orientation.EMPLOYEE_FRIENDLY}
                            />
                        </div>
                        <div className="mt-[16px]">
                            <RadioInput
                                name="nationalOrientation"
                                value={Orientation.EMPLOYER_FRIENDLY}
                                label="Arbeitgeberfreundlich"
                                setFieldValue={setFieldValue}
                                isChecked={values?.nationalOrientation === Orientation.EMPLOYER_FRIENDLY}
                            />
                        </div>
                        <div className="mt-[16px]">
                            <RadioInput
                                name="nationalOrientation"
                                value={Orientation.BALANCED}
                                label="Ausgewogen"
                                setFieldValue={setFieldValue}
                                isChecked={values?.nationalOrientation === Orientation.BALANCED}
                            />
                        </div>
                    </div>
                    <div className="ml-[70px]">
                        <div className="leading-[16px] text-[14px] font-Lexend400 text-int-white-secondary uppercase">
                            {t('AS International')}
                            {isSubmitPressed && errors?.internationalOrientation && (
                                <div className="mt-[10px] text-[14px] normal-case text-error">
                                    {errors?.internationalOrientation}
                                </div>
                            )}
                        </div>
                        <div className="mt-[16px]">
                            <RadioInput
                                name="internationalOrientation"
                                value={Orientation.EMPLOYEE_FRIENDLY}
                                label="Arbeitnehmerfreundlich"
                                setFieldValue={setFieldValue}
                                isChecked={values?.internationalOrientation === Orientation.EMPLOYEE_FRIENDLY}
                            />
                        </div>
                        <div className="mt-[16px]">
                            <RadioInput
                                name="internationalOrientation"
                                value={Orientation.EMPLOYER_FRIENDLY}
                                label="Arbeitgeberfreundlich"
                                setFieldValue={setFieldValue}
                                isChecked={values?.internationalOrientation === Orientation.EMPLOYER_FRIENDLY}
                            />
                        </div>
                        <div className="mt-[16px]">
                            <RadioInput
                                name="internationalOrientation"
                                value={Orientation.BALANCED}
                                label="Ausgewogen"
                                setFieldValue={setFieldValue}
                                isChecked={values?.internationalOrientation === Orientation.BALANCED}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MarketSituationEditItem;

MarketSituationEditItem.propTypes = {
    values: object.isRequired,
    errors: object.isRequired,
    isSubmitPressed: bool,
    setFieldValue: func.isRequired,
};
