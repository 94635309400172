import http from '../../../util/http';

export const areOptionsEqual = (options1, options2) => {
    const options1String = JSON.stringify(options1);
    const options2String = JSON.stringify(options2);
    return options1String === options2String;
};

export const areOptionsUnique = (values, array) => {
    const result = array.filter((i) => areOptionsEqual(i.options, values.options));

    return result.length === 0;
};

export const downloadPresentation = (projectId, variationOptionId, error) => {
    http.get(
        `/projects/${projectId}/building-blocks/tools/variation-options/${variationOptionId}/presentation/download`,
        { responseType: 'blob' }
    )
        .then((response) => {
            const hiddenElement = document.createElement('a');
            const url = window.URL || window.webkitURL;
            hiddenElement.href = url.createObjectURL(response.data);
            hiddenElement.target = '_blank';
            hiddenElement.download = 'Presentation.pdf';
            hiddenElement.click();
        })
        .catch(() => {
            error();
        });
};
