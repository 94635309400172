import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { func } from 'prop-types';
import { useSearchParams } from 'react-router-dom';
import { i18PAGES } from '../../../config/i18n/constants';
import ProjectManagementUserSortBlock from '../projectManagementUserSortBlock';

const ExternalUsersLabels = ({ handleSetSortParam, handleDefaultSort }) => {
    const { t } = useTranslation(i18PAGES.PROJECTS_MANAGEMENT);

    const [searchParams] = useSearchParams();
    const sortByExternalFromUrl = searchParams.get('sortByExternal');
    const orderExternalFromUrl = searchParams.get('orderExternal');

    const sortLabel = useCallback(
        (sortBy) => {
            if (sortByExternalFromUrl === sortBy && orderExternalFromUrl === 'ASC') {
                return 'A-Z';
            }
            if (sortByExternalFromUrl === sortBy && orderExternalFromUrl === 'DESC') {
                return 'Z-A';
            }
            return '';
        },
        [sortByExternalFromUrl, orderExternalFromUrl]
    );

    return (
        <div className="flex w-[100%] py-[8px] bg-[#2A3143]">
            <div className="max-w-[200px] w-[100%] ml-[7px] pl-[5px]">
                <ProjectManagementUserSortBlock
                    handleAZSortClick={() => handleSetSortParam('sortByExternal', 'fullName', 'orderExternal', 'ASC')}
                    handleZASortClick={() => handleSetSortParam('sortByExternal', 'fullName', 'orderExternal', 'DESC')}
                    handleDefaultSortClick={() => handleDefaultSort('sortByExternal', 'orderExternal')}
                    label={sortLabel('fullName')}
                    title={t('Full name')}
                />
            </div>
            <div className="max-w-[230px] w-[100%] pl-[6px]">
                <ProjectManagementUserSortBlock
                    handleAZSortClick={() => handleSetSortParam('sortByExternal', 'email', 'orderExternal', 'ASC')}
                    handleZASortClick={() => handleSetSortParam('sortByExternal', 'email', 'orderExternal', 'DESC')}
                    handleDefaultSortClick={() => handleDefaultSort('sortByExternal', 'orderExternal')}
                    label={sortLabel('email')}
                    title={t('Email')}
                />
            </div>
            <div className="max-w-[160px] w-[100%] pl-[6px]">
                <ProjectManagementUserSortBlock
                    handleAZSortClick={() => handleSetSortParam('sortByExternal', 'activated', 'orderExternal', 'ASC')}
                    handleZASortClick={() => handleSetSortParam('sortByExternal', 'activated', 'orderExternal', 'DESC')}
                    handleDefaultSortClick={() => handleDefaultSort('sortByExternal', 'orderExternal')}
                    label={sortLabel('activated')}
                    title={t('Status')}
                />
            </div>
            <div className="max-w-[100px] w-[100%] text-center">{t('Pages')}</div>
            <div className="max-w-[160px] w-[100%] pl-[6px]">
                <ProjectManagementUserSortBlock
                    handleAZSortClick={() => handleSetSortParam('sortByExternal', 'role', 'orderExternal', 'ASC')}
                    handleZASortClick={() => handleSetSortParam('sortByExternal', 'role', 'orderExternal', 'DESC')}
                    handleDefaultSortClick={() => handleDefaultSort('sortByExternal', 'orderExternal')}
                    label={sortLabel('role')}
                    title={t('Permissions')}
                />
            </div>
            <div className="max-w-[110px] w-[100%] pr-[12px] text-right">{t('Actions')}</div>
        </div>
    );
};

export default ExternalUsersLabels;

ExternalUsersLabels.propTypes = {
    handleSetSortParam: func,
    handleDefaultSort: func,
};
