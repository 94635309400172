import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { arrayOf, object } from 'prop-types';
import { i18PAGES } from '../../../../config/i18n/constants';
import EmptyStateTableFTE from '../EmptyStateTableFTE';

const FteTableView = ({ fteTableData }) => {
    const { t } = useTranslation(i18PAGES.FTE_PROCESS);

    const fteTableDataFiltered = useMemo(() => fteTableData?.filter((item) => item.value !== 0), [fteTableData]);

    return (
        <div className="w-[600px]">
            <div className="max-w-[600px] flex bg-int-gray-main font-Lexend400 text-int-white-main uppercase ">
                <div className="max-w-[400px] w-[100%] h-[60px] px-[10px] text-[14px] leading-[14px] border-r-[1px] border-int-main-black flex items-center">
                    {t('building blocks')}
                </div>
                <div className="max-w-[200px] w-[100%] px-[10px] h-[60px] text-[14px] leading-[19px] flex items-center">
                    {t('Summe von FTE Anteil Arbeitszeit')}, %
                </div>
            </div>
            <div>
                {fteTableDataFiltered.length > 0 ? (
                    <div>
                        {fteTableDataFiltered.map((item, index) => (
                            <div
                                className="max-w-[600px] flex bg-int-gray-secondary font-Lexend400 text-int-white-main border-b-[1px] border-int-main-black "
                                key={index}
                            >
                                <div className="max-w-[400px] w-[100%] h-[60px] px-[10px] text-[14px] leading-[14px] border-r-[1px] border-int-main-black flex items-center">
                                    {item.label}
                                </div>
                                <div className="max-w-[200px] w-[100%] px-[10px] h-[60px] w-[100%] text-[14px] leading-[19px] flex items-center justify-end">
                                    {item.value}
                                </div>
                            </div>
                        ))}
                    </div>
                ) : (
                    <div className="pt-[20px] pb-[10px] bg-int-gray-secondary">
                        <EmptyStateTableFTE />
                    </div>
                )}
            </div>
        </div>
    );
};

export default FteTableView;

FteTableView.propTypes = {
    fteTableData: arrayOf(object),
};
