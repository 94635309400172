import React from 'react';
import { shape, string } from 'prop-types';
import { OnlyViewFieldTextArea } from '../../../../Profile/OnlyViewFieldInput';

const WeaknessExpectationsReadItem = ({ weaknessExpectation }) => (
    <div className="flex mb-[16px]">
        <div className="w-[450px] mr-[10px]">
            <OnlyViewFieldTextArea styles="h-[84px]" label="" value={weaknessExpectation.weakness || ''} />
        </div>
        <div className="w-[450px] mr-[10px]">
            <OnlyViewFieldTextArea styles="h-[84px]" label="" value={weaknessExpectation.description || ''} />
        </div>
        <div className="w-[450px]">
            <OnlyViewFieldTextArea styles="h-[84px]" label="" value={weaknessExpectation.expectation || ''} />
        </div>
    </div>
);

export default WeaknessExpectationsReadItem;

WeaknessExpectationsReadItem.propTypes = {
    weaknessExpectation: shape({
        weakness: string.isRequired,
        description: string.isRequired,
        expectation: string.isRequired,
    }),
};
