import React from 'react';
import { object, number, func, arrayOf } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { i18PAGES } from '../../../config/i18n/constants';
import Checkbox from '../../../style/Checkbox/Checkbox';
import { DATE_FORMAT_TYPE, formatDateFromIsoString } from '../../util/dateFormatter';

const RecentSubscriptionsItem = ({ index, length, handleRenew, openDeleteModal, values }) => {
    const { t } = useTranslation(i18PAGES.SUBSCRIPTION_MANAGEMENT);
    return (
        <div
            className={`flex items-center h-[50px] text-[14px] leading-[18px] font-Lexend500 text-int-white-main border-b-[1px] ${
                length - 1 === index ? 'border-none' : 'border-int-gray-secondary'
            } `}
        >
            <div className="w-[260px] px-[11px]">
                <Checkbox
                    id={`${index}.isChoosed`}
                    name={`${index}.isChoosed`}
                    label={values[index].moduleName}
                    value={values[index].isChoosed}
                />
            </div>
            <div className="w-[160px] px-[11px]">
                {formatDateFromIsoString(values[index].startDate, DATE_FORMAT_TYPE.DAY_MONTH_YEAR_SLASH)}
            </div>
            <div className="w-[350px] px-[11px]">
                {formatDateFromIsoString(values[index].endDate, DATE_FORMAT_TYPE.DAY_MONTH_YEAR_SLASH)}
            </div>

            <div className="w-[190px] px-[11px] text-center flex justify-center">
                <div
                    className="mr-[40px] underline cursor-pointer"
                    onClick={() => {
                        handleRenew([values[index].subscriptionId]);
                    }}
                >
                    {t('Renew')}
                </div>
                <div
                    className="text-int-grapefruit underline cursor-pointer"
                    onClick={() => {
                        openDeleteModal(values[index].subscriptionId);
                    }}
                >
                    {t('Delete')}
                </div>
            </div>
        </div>
    );
};

export default RecentSubscriptionsItem;

RecentSubscriptionsItem.propTypes = {
    index: number,
    length: number,
    handleRenew: func,
    openDeleteModal: func,
    values: arrayOf(object),
};
