import React, { useMemo } from 'react';
import { array, arrayOf, func, number, object, oneOfType, shape, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { i18PAGES } from '../../../../config/i18n/constants';
import TableEditItem, { TABLE_EDIT_ROW_ITEM_TYPE } from '../../../../style/Table/TableEditItem';

const AsIsApplicationEditTableRow = ({
    values,
    index,
    handleChange,
    handleBlur,
    applicationsInfoTouched,
    applicationsInfoErrors,
    setFieldValue,
    modulesOptions,
    buildingBlocks,
    languagesOptions,
}) => {
    const { t } = useTranslation(i18PAGES.AS_IS_APPLICATION);

    const statusOptions = useMemo(
        () => [
            { label: t('Behalten'), value: 'Behalten' },
            { label: t('Erstzen'), value: 'Erstzen' },
            { label: t('Nicht relevant'), value: 'Nicht relevant' },
        ],
        [t]
    );

    const chosenModuleId = useMemo(() => values?.applicationsInfo[index].module.id, [index, values?.applicationsInfo]);

    const buildingBlocksForSelectedModule = useMemo(
        () =>
            chosenModuleId === 0
                ? buildingBlocks?.map((o) => ({ label: o.buildingBlockName, value: o.buildingBlockId }))
                : buildingBlocks
                      .filter((o) => o.moduleId === chosenModuleId)
                      .map((o) => ({ label: o.buildingBlockName, value: o.buildingBlockId })),
        [buildingBlocks, chosenModuleId]
    );

    const tableData = useMemo(
        () => [
            {
                type: TABLE_EDIT_ROW_ITEM_TYPE.SEARCH_DROPDOWN,
                handleChange: ({ label, value }) => {
                    setFieldValue(`applicationsInfo[${index}].module.name`, label);
                    setFieldValue(`applicationsInfo.[${index}].module.id`, value);
                    setFieldValue(`applicationsInfo[${index}].buildingBlock.name`, '');
                    setFieldValue(`applicationsInfo.[${index}].buildingBlock.id`, '');
                },
                name: `applicationsInfo.${index}.module.name`,
                value: values?.applicationsInfo[index].module.name,
                isError: !!(applicationsInfoTouched?.module?.name && applicationsInfoErrors?.module?.name),
                errorText: applicationsInfoErrors?.module?.name,
                options: modulesOptions,
                isChangeOnValue: true,
            },
            {
                type: TABLE_EDIT_ROW_ITEM_TYPE.SEARCH_DROPDOWN,
                handleChange: ({ label, value }) => {
                    setFieldValue(`applicationsInfo[${index}].buildingBlock.name`, label);
                    setFieldValue(`applicationsInfo.[${index}].buildingBlock.id`, value);
                },
                name: `applicationsInfo.${index}.buildingBlock.name`,
                value: values?.applicationsInfo[index].buildingBlock.name,
                isError: !!(
                    applicationsInfoTouched?.buildingBlock?.name && applicationsInfoErrors?.buildingBlock?.name
                ),
                errorText: applicationsInfoErrors?.buildingBlock?.name,
                options: buildingBlocksForSelectedModule,
                isChangeOnValue: true,
            },
            {
                type: TABLE_EDIT_ROW_ITEM_TYPE.INPUT,
                name: `applicationsInfo.${index}.applicationName`,
                value: values?.applicationsInfo[index]?.applicationName,
            },
            {
                type: TABLE_EDIT_ROW_ITEM_TYPE.LIST_CHOOSE_DROPDOWN,
                name: `applicationsInfo.${index}.applicationLanguages`,
                value: values?.applicationsInfo[index]?.applicationLanguages || [],
                isError: !!(
                    applicationsInfoTouched?.applicationLanguages && applicationsInfoErrors?.applicationLanguages
                ),
                errorText: applicationsInfoErrors?.applicationLanguages,
                options: languagesOptions,
                setFieldValue,
            },
            {
                type: TABLE_EDIT_ROW_ITEM_TYPE.SEARCH_DROPDOWN,
                handleChange: ({ label, value }) => {
                    setFieldValue(`applicationsInfo[${index}].status`, value);
                },
                name: `applicationsInfo.${index}.status`,
                value: values?.applicationsInfo[index].status,
                isError: !!(applicationsInfoTouched?.status && applicationsInfoErrors?.status),
                errorText: applicationsInfoErrors?.status,
                options: statusOptions,
                isChangeOnValue: false,
            },
            {
                type: TABLE_EDIT_ROW_ITEM_TYPE.INPUT,
                name: `applicationsInfo.${index}.systemSatisfactions`,
                value: values?.applicationsInfo[index].systemSatisfactions,
                isError: !!(
                    applicationsInfoTouched?.systemSatisfactions && applicationsInfoErrors?.systemSatisfactions
                ),
                errorText: applicationsInfoErrors?.systemSatisfactions,
            },
            {
                type: TABLE_EDIT_ROW_ITEM_TYPE.INPUT,
                name: `applicationsInfo.${index}.reasonToChange`,
                value: values?.applicationsInfo[index].reasonToChange,
                isError: !!(applicationsInfoTouched?.reasonToChange && applicationsInfoErrors?.reasonToChange),
                errorText: applicationsInfoErrors?.reasonToChange,
                limit: 30,
            },

            {
                type: TABLE_EDIT_ROW_ITEM_TYPE.INPUT,
                name: `applicationsInfo.${index}.licenseCostsPerYear`,
                value: values?.applicationsInfo[index].licenseCostsPerYear,
                isError: !!(
                    applicationsInfoTouched?.licenseCostsPerYear && applicationsInfoErrors?.licenseCostsPerYear
                ),
                errorText: applicationsInfoErrors?.licenseCostsPerYear,
            },
            {
                type: TABLE_EDIT_ROW_ITEM_TYPE.INPUT,
                name: `applicationsInfo.${index}.operatingCostsInternalPerYear`,
                value: values?.applicationsInfo[index].operatingCostsInternalPerYear,
                isError: !!(
                    applicationsInfoTouched?.operatingCostsInternalPerYear &&
                    applicationsInfoErrors?.operatingCostsInternalPerYear
                ),
                errorText: applicationsInfoErrors?.operatingCostsInternalPerYear,
            },
            {
                type: TABLE_EDIT_ROW_ITEM_TYPE.INPUT,
                name: `applicationsInfo.${index}.supportCostsExternalPerYear`,
                value: values?.applicationsInfo[index].supportCostsExternalPerYear,
                isError: !!(
                    applicationsInfoTouched?.supportCostsExternalPerYear &&
                    applicationsInfoErrors?.supportCostsExternalPerYear
                ),
                errorText: applicationsInfoErrors?.supportCostsExternalPerYear,
            },
            {
                type: TABLE_EDIT_ROW_ITEM_TYPE.INPUT,
                name: `applicationsInfo.${index}.interfaceCostsPerYear`,
                value: values?.applicationsInfo[index].interfaceCostsPerYear,
                isError: !!(
                    applicationsInfoTouched?.interfaceCostsPerYear && applicationsInfoErrors?.interfaceCostsPerYear
                ),
                errorText: applicationsInfoErrors?.interfaceCostsPerYear,
            },
            {
                type: TABLE_EDIT_ROW_ITEM_TYPE.INPUT,
                name: `applicationsInfo.${index}.trainingCostsPerYear`,
                value: values?.applicationsInfo[index].trainingCostsPerYear,
                isError: !!(
                    applicationsInfoTouched?.trainingCostsPerYear && applicationsInfoErrors?.trainingCostsPerYear
                ),
                errorText: applicationsInfoErrors?.trainingCostsPerYear,
            },
        ],
        [
            applicationsInfoErrors?.applicationLanguages,
            applicationsInfoErrors?.buildingBlock?.name,
            applicationsInfoErrors?.interfaceCostsPerYear,
            applicationsInfoErrors?.licenseCostsPerYear,
            applicationsInfoErrors?.module?.name,
            applicationsInfoErrors?.operatingCostsInternalPerYear,
            applicationsInfoErrors?.reasonToChange,
            applicationsInfoErrors?.status,
            applicationsInfoErrors?.supportCostsExternalPerYear,
            applicationsInfoErrors?.systemSatisfactions,
            applicationsInfoErrors?.trainingCostsPerYear,
            applicationsInfoTouched?.applicationLanguages,
            applicationsInfoTouched?.buildingBlock?.name,
            applicationsInfoTouched?.interfaceCostsPerYear,
            applicationsInfoTouched?.licenseCostsPerYear,
            applicationsInfoTouched?.module?.name,
            applicationsInfoTouched?.operatingCostsInternalPerYear,
            applicationsInfoTouched?.reasonToChange,
            applicationsInfoTouched?.status,
            applicationsInfoTouched?.supportCostsExternalPerYear,
            applicationsInfoTouched?.systemSatisfactions,
            applicationsInfoTouched?.trainingCostsPerYear,
            buildingBlocksForSelectedModule,
            index,
            languagesOptions,
            modulesOptions,
            setFieldValue,
            statusOptions,
            values?.applicationsInfo,
        ]
    );

    return (
        <tr className="h-[60px] border-b-[1px] border-int-main-black">
            {tableData?.map((item) => (
                <TableEditItem
                    {...item}
                    handleBlur={handleBlur}
                    handleChange={item.handleChange || handleChange}
                    setFieldValue={setFieldValue}
                    key={item.name}
                />
            ))}
        </tr>
    );
};
export default AsIsApplicationEditTableRow;

AsIsApplicationEditTableRow.propTypes = {
    index: number.isRequired,
    values: object.isRequired,
    handleBlur: func.isRequired,
    handleChange: func.isRequired,
    applicationsInfoTouched: object.isRequired,
    applicationsInfoErrors: object.isRequired,
    modulesOptions: arrayOf(
        shape({
            label: string,
            value: oneOfType([string, number]),
        })
    ),
    buildingBlocks: array,
    languagesOptions: arrayOf(
        shape({
            label: string,
            value: oneOfType([string, number]),
        })
    ),
    setFieldValue: func.isRequired,
};
