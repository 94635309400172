import React from 'react';
import { bool, func, object } from 'prop-types';
import FInput from '../../../../style/Input/Input';
import ListChooseDropdown from '../../../../style/ListChooseDropdown/ListChooseDropdown';
import { languagesOptions } from '../../../util/languagesList';
import OrganizationStructureEditOnly from './EditOrganizationStructure/OrganizationStructureEditOnly';

const OfficeItemEditMode = ({ values, errors, touched, handleBlur, handleChange, setFieldValue, isSubmitted }) => (
    <>
        <div className="bg-int-gray-main p-[30px] pt-[50px] rounded-[8px] w-[1132px] pb-[20px] min-h-[377px]">
            <div className="flex">
                <div className="w-[250px]">
                    <FInput
                        isLabelUppercase
                        name="yearFounded"
                        placeholder="Type here"
                        value={values?.yearFounded || ''}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        type="text"
                        label="Year of founding"
                        isError={!!(touched?.yearFounded && errors?.yearFounded)}
                        errorText={errors?.yearFounded}
                        setFieldValue={setFieldValue}
                        limit={4}
                    />
                </div>
                <div className="w-[250px] ml-[24px]">
                    <FInput
                        isLabelUppercase
                        name="employeeQuantityWc"
                        placeholder="Type here"
                        value={values?.employeeQuantityWc || ''}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        type="text"
                        label="WHITE-COLLAR WORKERS"
                        isError={!!(touched?.employeeQuantityWc && errors?.employeeQuantityWc)}
                        errorText={errors?.employeeQuantityWc}
                        setFieldValue={setFieldValue}
                    />
                </div>

                <div className="w-[250px] ml-[24px]">
                    <FInput
                        isLabelUppercase
                        name="employeeQuantityWcFt"
                        placeholder="Type here"
                        value={values?.employeeQuantityWcFt || ''}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        type="text"
                        label="FULL-TIME WHITE-COLLAR"
                        isError={
                            !!(touched?.employeeQuantityWcFt && errors?.employeeQuantityWcFt) ||
                            !!(isSubmitted && errors?.employeeQuantityWcFt)
                        }
                        errorText={errors?.employeeQuantityWcFt}
                        setFieldValue={setFieldValue}
                    />
                </div>
                <div className="w-[250px] ml-[24px]">
                    <FInput
                        isLabelUppercase
                        name="employeeQuantityWcPt"
                        placeholder="Type here"
                        value={values?.employeeQuantityWcPt || ''}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        type="text"
                        label="PART-TIME WHITE-COLLAR"
                        isError={
                            !!(touched?.employeeQuantityWcPt && errors?.employeeQuantityWcPt) ||
                            !!(isSubmitted && errors?.employeeQuantityWcPt)
                        }
                        errorText={errors?.employeeQuantityWcPt}
                        setFieldValue={setFieldValue}
                    />
                </div>
            </div>

            <div className="flex mt-[40px]">
                <div className="w-[250px]">
                    <FInput
                        isLabelUppercase
                        name="employeeQuantityBc"
                        placeholder="Type here"
                        value={values?.employeeQuantityBc || ''}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        type="text"
                        label="BLUE-COLLAR WORKERS"
                        isError={!!(touched?.employeeQuantityBc && errors?.employeeQuantityBc)}
                        errorText={errors?.employeeQuantityBc}
                        setFieldValue={setFieldValue}
                    />
                </div>
                <div className="w-[250px] ml-[24px]">
                    <FInput
                        isLabelUppercase
                        name="employeeQuantityBcFt"
                        placeholder="Type here"
                        value={values?.employeeQuantityBcFt || ''}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        type="text"
                        label="FULL-TIME BLUE-COLLAR"
                        isError={
                            !!(touched?.employeeQuantityBcFt && errors?.employeeQuantityBcFt) ||
                            !!(isSubmitted && errors?.employeeQuantityBcFt)
                        }
                        errorText={errors?.employeeQuantityBcFt}
                        setFieldValue={setFieldValue}
                    />
                </div>
                <div className="w-[250px] ml-[24px]">
                    <FInput
                        isLabelUppercase
                        name="employeeQuantityBcPt"
                        placeholder="Type here"
                        value={values?.employeeQuantityBcPt || ''}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        type="text"
                        label="PART-TIME BLUE-COLLAR"
                        isError={
                            !!(touched?.employeeQuantityBcPt && errors?.employeeQuantityBcPt) ||
                            !!(isSubmitted && errors?.employeeQuantityBcPt)
                        }
                        errorText={errors?.employeeQuantityBcPt}
                        setFieldValue={setFieldValue}
                    />
                </div>
                <div className="w-[250px] ml-[24px]">
                    <FInput
                        isLabelUppercase
                        name="employeeQuantityTotal"
                        placeholder="Type here"
                        value={values?.employeeQuantityTotal || ''}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        type="text"
                        label="TOTAL NUMBER OF WORKERS"
                        isError={
                            !!(touched?.employeeQuantityTotal && errors?.employeeQuantityTotal) ||
                            !!(isSubmitted && errors?.employeeQuantityTotal)
                        }
                        errorText={errors?.employeeQuantityTotal}
                        setFieldValue={setFieldValue}
                    />
                </div>
            </div>

            <div className="flex mt-[40px]">
                <div className="w-[250px]">
                    <FInput
                        isLabelUppercase
                        name="employeeQuantityTotalFt"
                        placeholder="Type here"
                        value={values?.employeeQuantityTotalFt || ''}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        type="text"
                        label="FTE AMOUNT"
                        isError={
                            !!(touched?.employeeQuantityTotalFt && errors?.employeeQuantityTotalFt) ||
                            !!(isSubmitted && errors?.employeeQuantityTotalFt)
                        }
                        errorText={errors?.employeeQuantityTotalFt}
                        setFieldValue={setFieldValue}
                    />
                </div>
                <div className="w-[250px] ml-[24px]">
                    <FInput
                        isLabelUppercase
                        name="hrFteAmount"
                        placeholder="Type here"
                        value={values?.hrFteAmount || ''}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        type="text"
                        label="HR FTE AMOUNT"
                        isError={!!(touched?.hrFteAmount && errors?.hrFteAmount)}
                        errorText={errors?.hrFteAmount}
                        setFieldValue={setFieldValue}
                    />
                </div>
                <div className="w-[250px] ml-[24px]">
                    <FInput
                        isLabelUppercase
                        name="temporaryExternalWorkers"
                        placeholder="Type here"
                        value={values?.temporaryExternalWorkers || ''}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        type="text"
                        label="ZEITARBEITKRÄFTE"
                        isError={!!(touched?.temporaryExternalWorkers && errors?.temporaryExternalWorkers)}
                        errorText={errors?.temporaryExternalWorkers}
                        setFieldValue={setFieldValue}
                    />
                </div>
                <div className="w-[250px] ml-[24px]">
                    <FInput
                        isLabelUppercase
                        name="hrQuantity"
                        placeholder="Type here"
                        value={values?.hrQuantity || ''}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        type="text"
                        label="Anzahl HR Mitarbeit"
                        isError={!!(touched?.hrQuantity && errors?.hrQuantity)}
                        errorText={errors?.hrQuantity}
                        setFieldValue={setFieldValue}
                    />
                </div>
            </div>

            <div className="flex mt-[40px]">
                <ListChooseDropdown
                    name="languages"
                    placeholder="Select"
                    value={values?.languages || []}
                    isError={!!(touched?.languages && errors?.languages)}
                    errorText={errors?.languages}
                    className="w-[360px]"
                    options={languagesOptions}
                    label="Languages"
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                />
            </div>
        </div>
        <OrganizationStructureEditOnly
            values={values}
            errors={errors}
            setFieldValue={setFieldValue}
            isSubmitPressed={isSubmitted}
        />
    </>
);

export default OfficeItemEditMode;

OfficeItemEditMode.propTypes = {
    values: object.isRequired,
    errors: object.isRequired,
    touched: object.isRequired,
    handleBlur: func.isRequired,
    handleChange: func.isRequired,
    setFieldValue: func.isRequired,
    isSubmitted: bool.isRequired,
};
