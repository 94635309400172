import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import CurrentSubscriptionsTable from '../components/Subscription/CurrentSubscriptions/CurrentSubscriptionsTable';
import InvoicesSubscriptionsTable from '../components/Subscription/Invoices/InvoicesSubscriptionsTable';
import RecentSubscriptionsTable from '../components/Subscription/RecentSubscriptions/RecentSubscriptionsTable';
import { i18PAGES } from '../config/i18n/constants';
import { useGetSubscriptionsListQuery, useRenewSubscriptionsMutation } from '../store/API/subscriptionsAPI';
import { useToast } from '../hoc/toast/ToastProvider';
import { useGetInvoicesSubscriptionListQuery } from '../store/API/invoiceAPI';
import LoadingAndTextOverLayer from '../style/LoadingAndTextOverLayer/LoadingAndTextOverLayer';

const SubscriptionPage = () => {
    const { t } = useTranslation(i18PAGES.SUBSCRIPTION_MANAGEMENT);
    const [isLoadingRenew, setIsLoadingRenew] = useState(false);
    const { errorToast } = useToast();
    const navigate = useNavigate();

    const {
        data: subscriptionsList,
        isLoading: isLoadingSubscriptionsList,
        refetch: refetchSubscriptionsList,
    } = useGetSubscriptionsListQuery({ refetchOnMountOrArgChange: true });

    const { data: invoicesSubscriptionList, isLoading: isLoadingInvoicesSubscriptionList } =
        useGetInvoicesSubscriptionListQuery({ refetchOnMountOrArgChange: true });

    const [renewSubscriptions] = useRenewSubscriptionsMutation();

    const renewSubscriptionsApi = (data) => {
        setIsLoadingRenew(true);
        renewSubscriptions({
            body: {
                subscriptions: data,
            },
        })
            .unwrap()
            .then((res) => {
                navigate(`/user-invoice/${res}`);
            })
            .catch(() => {
                errorToast(t('Subscription renew error'));
            })
            .finally(() => {
                setIsLoadingRenew(false);
            });
    };

    return (
        <div className="w-[1020px] mx-auto mt-[45px] mb-[170px]">
            <div className="text-[24px] leading-[14px] font-Lexend400 text-int-white-main mb-[50px]">
                {t('Subscription')}
            </div>
            <CurrentSubscriptionsTable
                currentSubscriptions={subscriptionsList?.currentSubscriptions}
                isLoadingSubscriptionsList={isLoadingSubscriptionsList}
                refetchSubscriptionsList={refetchSubscriptionsList}
                renewSubscriptionsApi={renewSubscriptionsApi}
            />
            <RecentSubscriptionsTable
                recentSubscriptions={subscriptionsList?.recentSubscriptions}
                isLoadingSubscriptionsList={isLoadingSubscriptionsList}
                refetchSubscriptionsList={refetchSubscriptionsList}
                renewSubscriptionsApi={renewSubscriptionsApi}
            />
            <InvoicesSubscriptionsTable
                invoicesSubscriptionList={invoicesSubscriptionList}
                isLoadingInvoicesSubscriptionList={isLoadingInvoicesSubscriptionList}
            />
            <LoadingAndTextOverLayer isOpen={isLoadingRenew} text={t('Process renew invoice')} />
        </div>
    );
};

export default SubscriptionPage;
