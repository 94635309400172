import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { HEADERS } from '../../components/util/constants';

export const subscriptionsAPI = createApi({
    reducerPath: 'subscriptionsAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api',
        prepareHeaders: HEADERS,
    }),
    tagTypes: ['subscriptionsList'],
    endpoints: (build) => ({
        getSubscriptionsList: build.query({
            query: () => `/users/subscriptions`,
            keepUnusedDataFor: 0.0001,
        }),
        terminateSubscriptions: build.mutation({
            query: ({ body }) => ({
                url: `/users/subscriptions/terminate`,
                method: 'POST',
                body,
            }),
        }),
        renewSubscriptions: build.mutation({
            query: ({ body }) => ({
                url: `/users/subscriptions/renew`,
                method: 'POST',
                body,
            }),
        }),
        deleteSubscriptions: build.mutation({
            query: ({ body }) => ({
                url: `/users/subscriptions/delete`,
                method: 'DELETE',
                body,
            }),
        }),
    }),
});

export const {
    useGetSubscriptionsListQuery,
    useTerminateSubscriptionsMutation,
    useRenewSubscriptionsMutation,
    useDeleteSubscriptionsMutation,
} = subscriptionsAPI;
