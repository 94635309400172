import React from 'react';
import { array } from 'prop-types';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { i18PAGES } from '../../config/i18n/constants';
import { useToast } from '../../hoc/toast/ToastProvider';
import { useDeleteAddedModuleMutation } from '../../store/API/modulesAPI';

const AddedModulesList = (props) => {
    const { userModules } = props;
    const { t } = useTranslation(i18PAGES.MODULES);
    const { successToast, errorToast } = useToast();
    const [deleteModule] = useDeleteAddedModuleMutation();
    const handleDelete = (moduleId) => {
        deleteModule(moduleId)
            .unwrap()
            .then((res) => {
                successToast(t('Module was successfully deleted from list'));
            })
            .catch((err) => {
                errorToast(i18n.t('Something went wrong, try again later'));
            });
    };

    return (
        <div className="mt-[8px] mb-[12px]">
            {userModules?.map((m) => (
                <div className="mt-[32px] text-[14px] leading-[17px] font-Lexend500 flex" key={m.moduleId}>
                    <div className="max-w-[360px] w-[100%] pl-[12px]">{m.moduleName}</div>
                    <div className="max-w-[200px] w-[100%] pl-[12px]">
                        {`${m.duration} ${t(m.duration === 1 ? 'month' : 'months')}`}
                    </div>
                    <div className="max-w-[265px] w-[100%] pl-[12px] text-center">
                        <div className="w-[175px]">{Number(m.price).toFixed(2)}</div>
                    </div>
                    <div
                        className="max-w-[100px] w-[100%] pr-[12px] text-right underline cursor-pointer"
                        onClick={() => handleDelete(m.moduleId)}
                    >
                        {t('Delete')}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default AddedModulesList;

AddedModulesList.propTypes = {
    userModules: array.isRequired,
};
