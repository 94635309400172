import React from 'react';
import { useTranslation } from 'react-i18next';
import { string } from 'prop-types';
import { i18PAGES } from '../../config/i18n/constants';
import { DATE_FORMAT_TYPE, formatDateFromIsoString } from '../util/dateFormatter';

const InvoiceSupplierAndCustomerInfo = ({
    supplierCompanyName,
    supplierCompanyAddress,
    createdDate,
    customerCompanyName,
    customerCompanyAddress,
    subjectMatter,
}) => {
    const { t } = useTranslation(i18PAGES.INVOICE);

    return (
        <>
            <div className="text-[10px] leading-[12px] font-Lexend400 text-int-white-main mt-[15px]">
                {t('Supplier')}
            </div>
            <div className="text-[14px] leading-[18px] font-SourceSansPro700 text-int-white-main mt-[8px]">
                {supplierCompanyName}
            </div>
            <div className="flex justify-between mt-[8px]">
                <div className="text-[14px] leading-[19px] font-Lexend400 text-int-white-main mr-[10px]">
                    {supplierCompanyAddress}
                </div>
                <div className="text-right text-[14px] leading-[19px] font-Lexend400 w-[160px]">
                    <div className="text-int-white-secondary">{t('Date')}</div>
                    <div className="text-int-white-main">
                        {formatDateFromIsoString(createdDate, DATE_FORMAT_TYPE.DAY_MONTH_YEAR_SLASH)}
                    </div>
                </div>
            </div>

            <div className="text-[10px] leading-[12px] font-Lexend400 text-int-white-main mt-[15px]">
                {t('Customer')}
            </div>
            <div className="text-[14px] leading-[18px] font-SourceSansPro700 text-int-white-main mt-[8px]">
                {customerCompanyName}
            </div>
            <div className="flex justify-between mt-[8px] mb-[60px]">
                <div className="text-[14px] leading-[19px] font-Lexend400 text-int-white-main mr-[10px]">
                    {customerCompanyAddress}
                </div>
                <div className="text-right text-[14px] leading-[19px] font-Lexend400 w-[160px]">
                    <div className="text-int-white-secondary">{t('Subject matter')}</div>
                    <div className="text-int-white-main">{subjectMatter}</div>
                </div>
            </div>
        </>
    );
};

export default InvoiceSupplierAndCustomerInfo;

InvoiceSupplierAndCustomerInfo.propTypes = {
    supplierCompanyName: string,
    supplierCompanyAddress: string,
    createdDate: string,
    customerCompanyName: string,
    customerCompanyAddress: string,
    subjectMatter: string,
};
