import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PageLoader } from '../components/Preloader/Preloader';
import { useGetUserInvoiceByIdQuery } from '../store/API/invoiceAPI';
import FailedConfirmation from '../components/Confirmation/FailedConfirmation';
import { i18PAGES } from '../config/i18n/constants';
import Invoice from '../components/Invoice/Invoice';

const UserInvoicePage = () => {
    const { t } = useTranslation(i18PAGES.SUBSCRIPTION_MANAGEMENT);
    const { invoiceId } = useParams();

    const {
        data: userInvoiceByIdData,
        isLoading: isLoadingUserInvoiceById,
        error,
    } = useGetUserInvoiceByIdQuery({ invoiceId }, { refetchOnMountOrArgChange: true, skip: !invoiceId });

    if (isLoadingUserInvoiceById) return <PageLoader />;

    if (error?.status === 404)
        return (
            <div className="flex justify-center items-center pt-[150px]">
                <FailedConfirmation
                    buttonText={t('Go to Homepage')}
                    linkPath="/"
                    text={t('Invoice does not exist. Please try again or return to the Home page')}
                />
            </div>
        );

    return (
        <Invoice
            typeInvoiceByToken={false}
            fileLoaded={userInvoiceByIdData?.fileLoaded}
            invoiceNumber={userInvoiceByIdData?.invoiceNumber}
            supplierCompanyName={userInvoiceByIdData?.supplierCompanyName}
            supplierCompanyAddress={userInvoiceByIdData?.supplierCompanyAddress}
            createdDate={userInvoiceByIdData?.createdDate}
            customerCompanyName={userInvoiceByIdData?.customerCompanyName}
            customerCompanyAddress={userInvoiceByIdData?.customerCompanyAddress}
            subjectMatter={userInvoiceByIdData?.subjectMatter}
            modules={userInvoiceByIdData?.modules}
            subscriptionAmount={userInvoiceByIdData?.subscriptionAmount}
            tax={userInvoiceByIdData?.tax}
            totalAmount={userInvoiceByIdData?.totalAmount}
            wahlerCompanyEmail={userInvoiceByIdData?.wahlerCompanyEmail}
            supplierBankInformation={userInvoiceByIdData?.supplierBankInformation}
        />
    );
};

export default UserInvoicePage;
