import React from 'react';
import { useTranslation } from 'react-i18next';
import { func } from 'prop-types';
import ModulesCheckoutTableHeader from './ModulesCheckoutTableHeader';
import { PrimaryButton } from '../../style/Button/Button';
import { i18PAGES } from '../../config/i18n/constants';

const EmptyModulesCheckoutBlock = ({ handleClick }) => {
    const { t } = useTranslation(i18PAGES.MODULES);
    return (
        <div className="bg-int-gray-main rounded-[8px] mt-[30px] p-[30px]">
            <div className="mb-[24px] text-[24px] leading-[24px] font-Lexend500">{t('Modules')}</div>
            <ModulesCheckoutTableHeader />
            <div className="my-[84px] text-[18px] leading-[18px] font-Lexend600 text-center">
                {t('No modules added')}
            </div>
            <PrimaryButton handleClick={handleClick} className="w-[169px] text-center m-auto" isPink>
                {t('Add modules')}
            </PrimaryButton>
        </div>
    );
};

export default EmptyModulesCheckoutBlock;

EmptyModulesCheckoutBlock.propTypes = {
    handleClick: func.isRequired,
};
