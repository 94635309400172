import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageLoader } from '../components/Preloader/Preloader';
import { useGetNotCompletedUserInvoiceQuery } from '../store/API/invoiceAPI';
import FailedConfirmation from '../components/Confirmation/FailedConfirmation';
import { i18PAGES } from '../config/i18n/constants';
import Invoice from '../components/Invoice/Invoice';

const NotCompletedUserInvoicePage = () => {
    const { t } = useTranslation(i18PAGES.SUBSCRIPTION_MANAGEMENT);

    const {
        data: notCompletedUserInvoice,
        isLoading: isLoadingNotCompletedUserInvoice,
        error,
    } = useGetNotCompletedUserInvoiceQuery({}, { refetchOnMountOrArgChange: true });

    if (isLoadingNotCompletedUserInvoice) return <PageLoader />;

    if (error?.status === 404)
        return (
            <div className="flex justify-center items-center pt-[150px]">
                <FailedConfirmation
                    buttonText={t('Go to Homepage')}
                    linkPath="/"
                    text={t('Invoice does not exist. Please try again or return to the Home page')}
                />
            </div>
        );

    return (
        <Invoice
            typeInvoiceByToken={false}
            fileLoaded={notCompletedUserInvoice?.fileLoaded}
            invoiceNumber={notCompletedUserInvoice?.invoiceNumber}
            supplierCompanyName={notCompletedUserInvoice?.supplierCompanyName}
            supplierCompanyAddress={notCompletedUserInvoice?.supplierCompanyAddress}
            createdDate={notCompletedUserInvoice?.createdDate}
            customerCompanyName={notCompletedUserInvoice?.customerCompanyName}
            customerCompanyAddress={notCompletedUserInvoice?.customerCompanyAddress}
            subjectMatter={notCompletedUserInvoice?.subjectMatter}
            modules={notCompletedUserInvoice?.modules}
            subscriptionAmount={notCompletedUserInvoice?.subscriptionAmount}
            tax={notCompletedUserInvoice?.tax}
            totalAmount={notCompletedUserInvoice?.totalAmount}
            wahlerCompanyEmail={notCompletedUserInvoice?.wahlerCompanyEmail}
            supplierBankInformation={notCompletedUserInvoice?.supplierBankInformation}
        />
    );
};

export default NotCompletedUserInvoicePage;
