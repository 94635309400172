import React from 'react';
import './CommentsLoader.css';

const CommentsLoader = () => (
    <div className="bounce">
        <div />
        <div />
        <div />
    </div>
);

export default CommentsLoader;
