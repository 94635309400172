import React, { useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import { func, instanceOf, bool, string } from 'prop-types';
import 'react-datepicker/dist/react-datepicker.css';
import { formatDateFromIsoString, DATE_FORMAT_TYPE } from '../../components/util/dateFormatter';
import './MyDatePicker.css';
import { ReactComponent as DatePickerIcon } from '../../assets/icons/datePickerIcon.svg';
import { ReactComponent as ErrorIcon } from '../../assets/icons/errorIcon.svg';

const MyDatePicker = ({ startDate, setStartDate, disableDatesBeforeToday = false, isError, textError }) => {
    const startDisableDate = new Date(1970, 1, 1);
    const endDisableDate = new Date(new Date() - 86400000);

    const calendarRef = useRef();

    const [isOpenHelpErrorInfo, setIsOpenHelpErrorInfo] = useState(false);
    const [touched, setTouched] = useState(false);

    const handleBlur = () => {
        if (touched === false) {
            setTouched(true);
        }
    };

    return (
        <div
            className={`relative w-[100%] ${isError && touched && 'border-[1px] border-error'}  rounded-lg`}
            id="picker-container"
        >
            <DatePicker
                ref={calendarRef}
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                startDate={startDate}
                onKeyDown={(e) => {
                    e.preventDefault();
                }}
                onBlur={handleBlur}
                excludeDateIntervals={
                    disableDatesBeforeToday ? [{ start: startDisableDate, end: endDisableDate }] : false
                }
                dateFormat="dd/MM/yyyy"
                disabledKeyboardNavigation
                placeholderText="Select date"
                className=" bg-[#29323F] rounded-lg w-[100%] h-[42px] text-[16px] leading-[24px] text-int-white-main pl-[15px]"
                popperPlacement="bottom-end"
                popperModifiers={[
                    {
                        name: 'offset',
                        options: {
                            offset: [0, 0],
                        },
                    },
                    {
                        name: 'arrow',
                        options: {
                            padding: 194,
                        },
                    },
                ]}
            >
                <div className="inline-flex items-center justify-center text-[12px] w-[100%]">
                    <div className="flex items-center justify-center">
                        <div>
                            {startDate
                                ? formatDateFromIsoString(startDate, DATE_FORMAT_TYPE.DAY_MONTH_YEAR_SLASH)
                                : 'No Date'}
                        </div>
                    </div>
                </div>
            </DatePicker>
            <div
                className="flex items-center absolute top-[13px] right-[15px] h-[15px] cursor-pointer"
                onClick={() => {
                    calendarRef.current.onInputClick();
                }}
            >
                <DatePickerIcon fill="#EDF1FD" />
                {isError && touched && (
                    <>
                        <div
                            className="ml-[5px]"
                            onMouseEnter={() => setIsOpenHelpErrorInfo(true)}
                            onMouseLeave={() => setIsOpenHelpErrorInfo(false)}
                        >
                            <ErrorIcon />
                        </div>
                        {isOpenHelpErrorInfo && (
                            <div
                                className="text-[12px] text-error font-Lexend300 absolute
                     bg-[#222730] p-[10px] rounded-[2px] z-10 bottom-[23px] right-[-15px] whitespace-nowrap"
                            >
                                {textError}
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};
export default MyDatePicker;

MyDatePicker.propTypes = {
    startDate: instanceOf(Date),
    setStartDate: func,
    disableDatesBeforeToday: bool,
    isError: bool,
    textError: string,
};
