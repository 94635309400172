export const DATE_FORMAT_TYPE = {
    DAY_MONTH_YEAR_SLASH: 'DAY_MONTH_YEAR_SLASH',
    DAY_MONTH_YEAR: 'DAY_MONTH_YEAR',
};

const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];

export const formatDateFromIsoString = (isoString, type) => {
    const date = new Date(isoString);

    const day = addedZeroIfNeedForNumber(date.getDate());
    const month = addedZeroIfNeedForNumber(date.getMonth() + 1);
    const year = date.getFullYear();

    switch (type) {
        case DATE_FORMAT_TYPE.DAY_MONTH_YEAR_SLASH:
            return `${day}/${month}/${year}`;
        case DATE_FORMAT_TYPE.DAY_MONTH_YEAR:
            return `${day} ${monthNames[date.getMonth()]} ${year}`;
        default:
            return '';
    }
};

export const addedZeroIfNeedForNumber = (number) => {
    if (number > 9) {
        return number;
    }
    return `0${number}`;
};
