import React, { useState } from 'react';
import { number, shape, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ReactComponent as InvoicesDownloadIcon } from '../../../assets/icons/invoicesDownloadIcon.svg';
import { DATE_FORMAT_TYPE, formatDateFromIsoString } from '../../util/dateFormatter';
import { SUBSCRIPTION_INVOICE_STATUS } from '../../util/constants';
import { i18PAGES } from '../../../config/i18n/constants';
import { downloadPdfInvoice } from './downloadPdfInvoice';
import { useToast } from '../../../hoc/toast/ToastProvider';
import LoadingAndTextOverLayer from '../../../style/LoadingAndTextOverLayer/LoadingAndTextOverLayer';

const InvoicesSubscriptionsItem = ({ data, index, length }) => {
    const { t } = useTranslation(i18PAGES.SUBSCRIPTION_MANAGEMENT);
    const [isLoadingPdf, setIsLoadingPdf] = useState(false);
    const { errorToast } = useToast();

    return (
        <div
            className={`flex items-center h-[50px] text-[14px] leading-[18px] font-Lexend500 text-int-white-main border-b-[1px] ${
                length - 1 === index ? 'border-none' : 'border-int-gray-secondary'
            } `}
        >
            <div className="w-[220px] px-[11px]">{data.invoiceNumber}</div>
            <div className="w-[200px] px-[11px]">
                {formatDateFromIsoString(data.date, DATE_FORMAT_TYPE.DAY_MONTH_YEAR_SLASH)}
            </div>
            <div className="w-[200px] px-[11px]">{data.amount}</div>
            <div className="w-[200px] px-[11px]">
                {data.status === SUBSCRIPTION_INVOICE_STATUS.CONFIRMED ? t('Completed') : t('In Progress')}
            </div>
            <div className="w-[140px] px-[11px] text-center flex justify-center">
                <div
                    className=" cursor-pointer"
                    onClick={() => {
                        downloadPdfInvoice(
                            data.invoiceNumber,
                            `invoice_${new Date().toISOString()}`,
                            () => {
                                errorToast(t('Error download pdf invoice'));
                            },
                            setIsLoadingPdf
                        );
                    }}
                >
                    <InvoicesDownloadIcon width={20} height={20} fill="#EDF1FD" />
                </div>
            </div>
            <LoadingAndTextOverLayer isOpen={isLoadingPdf} text={t('Process download PDF invoice')} />
        </div>
    );
};

export default InvoicesSubscriptionsItem;

InvoicesSubscriptionsItem.propTypes = {
    data: shape({
        amount: number,
        date: string,
        invoiceNumber: number,
        status: string,
    }),
    index: number.isRequired,
    length: number.isRequired,
};
