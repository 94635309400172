import React from 'react';
import { array, number, oneOfType, string, bool } from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import { usePlatformEditMode } from '../../store/hooks/usePlatformEditMode';

const TabSwitcher = ({ itemsArray, projectId, rootUrl, useDisable = false }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { platformEditMode: disableTabSwitcher } = usePlatformEditMode();

    const handleClickTab = (item) => {
        navigate(`/${rootUrl}?current=${item.enumName.toLowerCase()}&projectId=${projectId}`);
    };

    return (
        <div
            className={`flex font-Lexend400 text-[16px] leading-[18px] ${
                disableTabSwitcher && useDisable && 'opacity-50'
            }`}
        >
            {itemsArray?.map((item, index) => (
                <div
                    key={index}
                    onClick={disableTabSwitcher && useDisable ? null : () => handleClickTab(item)}
                    className={`px-[16px] mr-[15px] border-b-[1px] ${
                        disableTabSwitcher && useDisable ? 'cursor-not-allowed' : 'cursor-pointer'
                    }  pb-[19px] ${
                        location.search.includes(encodeURIComponent(item.enumName.toLowerCase()))
                            ? 'text-int-white-main border-int-white-main'
                            : 'text-int-white-secondary border-int-gray-secondary'
                    }`}
                >
                    {item.name}
                </div>
            ))}
        </div>
    );
};

export default TabSwitcher;

TabSwitcher.propTypes = {
    itemsArray: array.isRequired,
    projectId: oneOfType([string, number]).isRequired,
    rootUrl: string.isRequired,
    useDisable: bool,
};
