import i18n from 'i18next';

export const resendRegistrationLetter = async (resendRegistrationEmail, defaultSendData, email) => {
    const resendRegistrationEmailFunction = async () => {
        await resendRegistrationEmail({
            email,
        }).unwrap();
    };

    await resendLetterHandler(resendRegistrationEmailFunction, defaultSendData, email);
};

export const resendResetPasswordLetter = async (resendResetPasswordEmail, defaultSendData, email) => {
    const resendResetPasswordFunction = async () => {
        await resendResetPasswordEmail({
            email,
        }).unwrap();
    };

    await resendLetterHandler(resendResetPasswordFunction, defaultSendData, email);
};

export const resendLetterHandler = async (
    sendFunction,
    { setIsResendPressed, setFinishDate, emailType, errorToast },
    email
) => {
    await sendFunction()
        .then(() => {
            setIsResendPressed(true);
            const currentDate = new Date();
            const endDate = currentDate.setSeconds(currentDate.getSeconds() + 61);
            setFinishDate(JSON.stringify(endDate));
            localStorage.setItem(`${emailType}FinishDate${email}`, JSON.stringify(endDate));
        })
        .catch(() => {
            errorToast(i18n.t('Something went wrong, try again later'));
        });
};
