import React, { useMemo } from 'react';
import { arrayOf, bool, number, shape, string } from 'prop-types';
import i18n from '../../config/i18n/i18n';

const OfficesModulesView = (props) => {
    const { office } = props;

    return (
        <div className="mt-[40px] mb-[20px] text-int-white-main">
            <div className="font-Lexend500 text-[18px] leading-[22px] mb-[13px] inline-flex items-center">
                <div>{i18n.t('Modules selected')}</div>
            </div>
            <div>
                {office?.moduleBuildingBlocks?.map((m) => (
                    <ModuleFeatureItem moduleFeatureItem={m} key={m.moduleId} />
                ))}
            </div>
        </div>
    );
};

export default OfficesModulesView;

OfficesModulesView.propTypes = {
    office: shape({
        id: number.isRequired,
        moduleFeatures: arrayOf(
            shape({
                moduleId: number.isRequired,
                moduleName: string,
                buildingBlocks: arrayOf(
                    shape({
                        name: string.isRequired,
                        selected: bool.isRequired,
                    })
                ),
            })
        ),
    }),
};

export const ModuleFeatureItem = ({ moduleFeatureItem }) => {
    const isShown = useMemo(() => {
        if (moduleFeatureItem?.buildingBlocks?.length) {
            let isReady = false;
            moduleFeatureItem?.buildingBlocks.forEach((f) => {
                if (f.selected === true) {
                    isReady = true;
                }
            });

            return isReady;
        }
    }, [moduleFeatureItem, moduleFeatureItem?.buildingBlocks]);

    if (isShown) {
        return (
            <div className="flex items-center border-b border-int-gray-secondary">
                <div className="min-w-[240px] font-Lexend400">
                    {moduleFeatureItem.moduleName || 'Unrecognized Module'}
                </div>
                <div className="ml-[10px] flex flex-wrap pt-[4px] pb-[14px]">
                    {moduleFeatureItem?.buildingBlocks?.map((bb, index) => {
                        if (bb.selected) {
                            return (
                                <div
                                    key={index}
                                    className="text-[14px] px-[8px] h-[24px] flex items-center justify-center bg-int-gray-secondary rounded-[4px] mt-[10px] mr-[10px] leading-[16px]"
                                >
                                    {bb.name}
                                </div>
                            );
                        }
                        return <div key={index} />;
                    })}
                </div>
            </div>
        );
    }
    return <div />;
};

ModuleFeatureItem.propTypes = {
    moduleFeatureItem: shape({
        moduleId: number.isRequired,
        moduleName: string,
        buildingBlocks: arrayOf(
            shape({
                name: string.isRequired,
                selected: bool.isRequired,
            })
        ),
    }),
};
