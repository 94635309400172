import React from 'react';
import { useTranslation } from 'react-i18next';
import { i18PAGES } from '../../../config/i18n/constants';

const InvoicesSubscriptionsHeader = () => {
    const { t } = useTranslation(i18PAGES.SUBSCRIPTION_MANAGEMENT);

    return (
        <div className="flex items-center h-[35px] bg-[#2A3143] text-[14px] leading-[18px] font-Lexend500 text-int-white-secondary mb-[10px]">
            <div className="w-[220px] px-[11px]">{t('Invoice number')}</div>
            <div className="w-[200px] px-[11px]">{t('Date')}</div>
            <div className="w-[200px] px-[11px]">{t('Amount')} (EUR,€)</div>
            <div className="w-[200px] px-[11px]">{t('Status')}</div>
            <div className="w-[140px] px-[11px] text-center">{t('Download')}</div>
        </div>
    );
};

export default InvoicesSubscriptionsHeader;
