import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as NoSavedDataIcon } from '../../../assets/icons/noSavedDataIcon.svg';
import { i18PAGES } from '../../../config/i18n/constants';

const EmptyStateTableFTE = () => {
    const { t } = useTranslation(i18PAGES.FTE_PROCESS);

    return (
        <div className="flex flex-col items-center justify-center">
            <NoSavedDataIcon fill="#9BA1B2" width={40} height={40} />
            <div className="text-[14px] leading-[30px] font-Lexend400 text-int-white-secondary mt-[16px]">
                {t('There is no saved data to display')}
            </div>
        </div>
    );
};

export default EmptyStateTableFTE;
