import React, { useCallback, useEffect, useRef, useState } from 'react';
import { bool, func, node, number, oneOfType, string } from 'prop-types';
import { ReactComponent as MinusIcon } from '../../assets/icons/lessIcon.svg';
import { ReactComponent as PlusIcon } from '../../assets/icons/moreIcon.svg';
import { ReactComponent as SuccessIcon } from '../../assets/icons/success.svg';
import './AccordionItem.css';

const AccordionItem = ({
    children,
    countryName,
    cityName,
    officesCount,
    isOpenedTab = false,
    isTabReady = false,
    flagCode,
    setOpenTabIndex,
    openedTabCityId,
    cityId,
}) => {
    const [isOpened, setIsOpened] = useState(isOpenedTab);
    const [isOverflow, setIsOverflow] = useState(false);
    const [isIconAnimated, setIsIconAnimated] = useState(false);
    const [height, setHeight] = useState(0);

    const childrenContent = useRef(null);

    useEffect(() => {
        setHeight(childrenContent?.current?.scrollHeight);
    }, [isOpened, openedTabCityId, children]);

    const overFlowAnimation = useCallback(() => {
        if (isOverflow) {
            setIsOverflow(false);
        }

        setIsIconAnimated(true);

        setTimeout(() => {
            setIsIconAnimated(false);
            if (!isOverflow) {
                setIsOverflow(true);
            }
        }, 1000);
    }, [isOverflow]);

    const openToggle = useCallback(() => {
        if (setOpenTabIndex) {
            if (cityId === openedTabCityId) {
                overFlowAnimation();
                setOpenTabIndex(undefined);
            } else {
                overFlowAnimation();
                setOpenTabIndex(cityId);
            }
        } else {
            overFlowAnimation();
            setIsOpened((prev) => !prev);
        }
    }, [cityId, openedTabCityId, overFlowAnimation, setOpenTabIndex]);

    useEffect(() => {
        if (setOpenTabIndex) {
            if (cityId === openedTabCityId) {
                setIsOpened(true);
            } else {
                setIsOpened(false);
            }
        }
    }, [cityId, openedTabCityId, setOpenTabIndex]);

    return (
        <div>
            <div className="bg-int-gray-main rounded-t-[8px]">
                <div
                    className={`flex justify-between items-center
                px-[20px] h-[56px]  ${isOpened ? 'bg-int-gray-secondary' : 'bg-[#2A3143]'} rounded-[8px]
                cursor-pointer w-[100%] mt-[20px]`}
                    onClick={openToggle}
                >
                    <div className="flex items-center">
                        {flagCode && (
                            <img
                                className="mr-[5px] w-[20px] h-[15px]"
                                src={`https://flagcdn.com/48x36/${flagCode}.png`}
                                alt="#"
                            />
                        )}
                        <div className="text-[16px] font-Lexend400 text-int-white-main capitalize">
                            {countryName}
                            {cityName && <>, {cityName.toLowerCase()}</>}
                        </div>
                        {officesCount > 0 && (
                            <div className="ml-[10px] text-[14px] leading-[24px] text-int-white-secondary">
                                {officesCount} {officesCount === 1 ? 'office' : 'offices'}
                            </div>
                        )}
                    </div>

                    <div className="flex items-center ">
                        {isTabReady && (
                            <div className="mr-[32px] flex items-center">
                                <div className="mr-[7px] text-[#10B981] text-[14px] leading-[24px]">Filled</div>
                                <SuccessIcon />
                            </div>
                        )}
                        <div className={isIconAnimated ? 'icon-animated' : ''}>
                            {isOpened ? <MinusIcon /> : <PlusIcon />}
                        </div>
                    </div>
                </div>
            </div>

            <div
                className="bg-int-gray-main rounded-b-[8px] overflow-hidden"
                style={{
                    height: isOpened ? height : '0',
                    transition: 'height 1s ease, overflow 0.1s',
                    transitionDelay: 'overflow 1s',
                }}
                ref={childrenContent}
            >
                {children}
            </div>
        </div>
    );
};

export default AccordionItem;

AccordionItem.propTypes = {
    children: node.isRequired,
    countryName: string.isRequired,
    cityName: string,
    officesCount: oneOfType([string, number]),
    isOpenedTab: bool,
    isTabReady: bool,
    flagCode: string,
    setOpenTabIndex: func,
    openedTabCityId: number,
    cityId: number,
};
