import React, { useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from '../style/Button/Button';
import { useGetInvoiceInfoQuery, useGetNotCompletedUserInvoiceQuery } from '../store/API/invoiceAPI';
import { LayoutLoader } from '../components/Preloader/Preloader';
import FailedConfirmation from '../components/Confirmation/FailedConfirmation';
import UserConfirmationSuccess from '../components/ConfirmTransaction/UserConfirmation/UserConfirmationSuccess';
import { SUBSCRIPTION_INVOICE_STATUS } from '../components/util/constants';

const TransactionInProgressPage = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const key = searchParams.get('key');
    const { t } = useTranslation();

    const handleOpenInvoice = () => {
        if (key) {
            navigate(`/invoice/${key}`);
        } else {
            navigate('/not-completed-user-invoice');
        }
    };

    const handleGoToConfirmTransaction = () => {
        if (key) {
            navigate(`/confirm-transaction?key=${key}`);
        } else {
            navigate(`/confirm-transaction`);
        }
    };

    const { data, isLoading, isError } = useGetInvoiceInfoQuery(key, { refetchOnMountOrArgChange: true, skip: !key });

    const {
        data: notCompletedUserInvoiceData,
        isLoading: isLoadingNotCompletedUserInvoice,
        isError: isErrorNotCompletedUserInvoice,
    } = useGetNotCompletedUserInvoiceQuery({}, { refetchOnMountOrArgChange: true, skip: key });

    const invoiceStatus = useMemo(
        () => notCompletedUserInvoiceData?.status || data?.status,
        [data?.status, notCompletedUserInvoiceData?.status]
    );

    if (isLoading || isLoadingNotCompletedUserInvoice) {
        return <LayoutLoader />;
    }

    if (isError) {
        return (
            <div className="min-h-[calc(100vh-300px)] h-[100%] flex items-center justify-center">
                <FailedConfirmation
                    buttonText={t('Go to Homepage')}
                    linkPath="/"
                    text={t('Token is expired or not valid')}
                />
            </div>
        );
    }

    if (isErrorNotCompletedUserInvoice) {
        return (
            <div className="min-h-[calc(100vh-300px)] h-[100%] flex items-center justify-center">
                <FailedConfirmation
                    buttonText={t('Go to Homepage')}
                    linkPath="/"
                    text={t("Can't get your invoice, try again later")}
                />
            </div>
        );
    }

    return (
        <div className="min-h-[calc(calc(calc(100vh-theme(spacing.header-height))-theme(spacing.platform-footer-height))-30px)] h-[100%] flex items-center justify-center">
            <div className="bg-int-gray-main m-auto px-[30px] pt-[30px] pb-[40px] relative rounded-[20px] max-w-[500px]">
                {invoiceStatus === SUBSCRIPTION_INVOICE_STATUS.IN_PROGRESS && <UserConfirmationSuccess />}
                {invoiceStatus === SUBSCRIPTION_INVOICE_STATUS.CREATED && (
                    <>
                        <div className="text-[24px] leading-[24px] font-Lexend500 text-int-white-main mb-[16px] text-center">
                            {i18n.t('Transaction in progress')}
                        </div>
                        <div className="text-[14px] leading-[22px] font-Lexend300 text-int-white-main mb-[50px] text-center">
                            {i18n.t('We are waiting for transaction from your bank.')}{' '}
                            {i18n.t(
                                'When your bank approves transaction you will receive a confirmation on your email and get access to the platform.'
                            )}
                        </div>
                        <div className="flex justify-between">
                            <PrimaryButton
                                isGrey
                                className="h-[50px] w-[210px]"
                                handleClick={handleGoToConfirmTransaction}
                            >
                                <div className="text-[18px] leading-[18px] font-Lexend400">
                                    {i18n.t('Confirm transaction')}
                                </div>
                            </PrimaryButton>
                            <PrimaryButton isPink className="h-[50px] w-[210px]" handleClick={handleOpenInvoice}>
                                <div className="text-[18px] leading-[18px] font-Lexend400">
                                    {i18n.t('Open Invoice')}
                                </div>
                            </PrimaryButton>
                        </div>
                    </>
                )}
                {invoiceStatus === SUBSCRIPTION_INVOICE_STATUS.DENY && (
                    <>
                        <div className="text-[24px] leading-[24px] font-Lexend500 text-int-white-main mb-[16px] text-center">
                            {i18n.t('Confirmation canceled')}
                        </div>
                        <div className="text-[14px] leading-[22px] font-Lexend300 text-int-white-main mb-[50px] text-center">
                            {i18n.t('Please confirm your subscription as soon as your bank approves the transaction')}
                        </div>
                        <div className="flex justify-between">
                            <PrimaryButton
                                isGrey
                                className="h-[50px] w-[210px]"
                                handleClick={handleGoToConfirmTransaction}
                            >
                                <div className="text-[18px] leading-[18px] font-Lexend400">
                                    {i18n.t('Confirm transaction')}
                                </div>
                            </PrimaryButton>
                            <PrimaryButton isPink className="h-[50px] w-[210px]" handleClick={handleOpenInvoice}>
                                <div className="text-[18px] leading-[18px] font-Lexend400">
                                    {i18n.t('Open Invoice')}
                                </div>
                            </PrimaryButton>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default TransactionInProgressPage;
