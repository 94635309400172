import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { HEADERS } from '../../components/util/constants';

export const providerSelectionAPI = createApi({
    reducerPath: 'providerSelectionAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api',
        prepareHeaders: HEADERS,
    }),
    tagTypes: ['providerSelectionList'],
    endpoints: (build) => ({
        getBuildingBlockList: build.query({
            query: (projectId) => `/projects/${projectId}/modules/building-blocks`,
            keepUnusedDataFor: 0.0001,
        }),
        getExpiredModules: build.query({
            query: (projectId) => `/projects/${projectId}/modules/expired`,
            keepUnusedDataFor: 0.0001,
        }),
        getToolsInfo: build.query({
            query: ({ projectId, buildingBlockId }) =>
                `/projects/${projectId}/building-blocks/${buildingBlockId}/link-tools`,
        }),
        renewModulesRequest: build.mutation({
            query: ({ projectId, body }) => ({
                url: `/projects/${projectId}/modules/renew/request`,
                method: 'POST',
                body,
            }),
        }),
        deleteModulesRequest: build.mutation({
            query: ({ projectId, body }) => ({
                url: `/projects/${projectId}/modules/delete/request`,
                method: 'POST',
                body,
            }),
        }),
        sendTools: build.mutation({
            query: ({ projectId, buildingBlockId, body }) => ({
                url: `/projects/${projectId}/building-blocks/${buildingBlockId}/link-tools`,
                method: 'POST',
                body,
            }),
        }),
        sendRequest: build.mutation({
            query: ({ projectId, buildingBlockId, body }) => ({
                url: `/projects/${projectId}/building-blocks/${buildingBlockId}/send-requests`,
                method: 'POST',
                body,
            }),
        }),
        extendDeadline: build.mutation({
            query: ({ projectId, buildingBlockId, body }) => ({
                url: `/projects/${projectId}/building-blocks/${buildingBlockId}/extend-deadline`,
                method: 'PUT',
                body,
            }),
        }),
        resendInvitation: build.mutation({
            query: ({ projectId, buildingBlockId, toolId }) => ({
                url: `/projects/${projectId}/building-blocks/${buildingBlockId}/tools/${toolId}/resend-invitation`,
                method: 'POST',
            }),
        }),
        rejectProvider: build.mutation({
            query: ({ projectId, buildingBlockId, toolId }) => ({
                url: `/projects/${projectId}/building-blocks/${buildingBlockId}/tools/${toolId}/reject-providers`,
                method: 'POST',
            }),
        }),
        restoreProvider: build.mutation({
            query: ({ projectId, buildingBlockId, toolId }) => ({
                url: `/projects/${projectId}/building-blocks/${buildingBlockId}/tools/${toolId}/restore-providers`,
                method: 'POST',
            }),
        }),
    }),
});

export const {
    useGetBuildingBlockListQuery,
    useGetExpiredModulesQuery,
    useGetToolsInfoQuery,
    useRenewModulesRequestMutation,
    useDeleteModulesRequestMutation,
    useSendToolsMutation,
    useSendRequestMutation,
    useExtendDeadlineMutation,
    useResendInvitationMutation,
    useRejectProviderMutation,
    useRestoreProviderMutation,
} = providerSelectionAPI;
