import React from 'react';
import { useTranslation } from 'react-i18next';
import { i18PAGES } from '../../../../config/i18n/constants';

const HrStrategyTableLabels = () => {
    const { t } = useTranslation(i18PAGES.MODULES_AND_STRATEGY);
    return (
        <div className="flex text-int-white-secondary font-Lexend400 text-[16px] leading-[16px] mb-[12px]">
            <div>{t('Strategy name')}</div>
            <div className="ml-[264px]">{t('Describe overall strategy points')}</div>
        </div>
    );
};

export default HrStrategyTableLabels;
