import React, { useCallback, useState } from 'react';
import { array, bool, node, number, string } from 'prop-types';
import { FloatingFocusManager, FloatingPortal } from '@floating-ui/react';
import { ReactComponent as ArrowUpIcon } from '../../assets/icons/up-arrow.svg';
import { ReactComponent as ArrowBottomIcon } from '../../assets/icons/bottom-arrow.svg';
import { useChangeDirectionDropdown } from '../../hooks/useChangeDirectionDropdown';

const DropdownItem = (props) => {
    const {
        className,
        menuOptions,
        children,
        offsetValue,
        isIcon,
        maxHeightDropDown = 360,
        widthDropDown,
        isFull,
        as: Wrapper = 'div',
        ...others
    } = props;

    const [isOpen, setIsOpen] = useState(false);

    const onToggle = useCallback(() => {
        setIsOpen((prev) => !prev);
    }, []);

    const onClose = useCallback(() => {
        setIsOpen(false);
    }, []);

    const { getReferenceProps, getFloatingProps, getItemProps, refs, context, floatingStyles } =
        useChangeDirectionDropdown({
            isOpen,
            setIsOpen,
            maxHeightDropDown,
            offsetValue,
            widthDropDown,
        });

    return (
        <Wrapper className={`relative ${className}`} {...others}>
            <div
                {...getReferenceProps({
                    ref: refs.setReference,
                })}
                className={`cursor-pointer ${isFull && 'w-[100%] h-[100%]'} flex items-center justify-center`}
                onClick={onToggle}
            >
                {children}
                {isIcon && <> {isOpen ? <ArrowUpIcon fill="#EDF1FD" /> : <ArrowBottomIcon fill="#EDF1FD" />}</>}
            </div>
            {isOpen && (
                <FloatingPortal>
                    <FloatingFocusManager context={context} modal={false}>
                        <div
                            ref={refs.setFloating}
                            {...getFloatingProps()}
                            style={{ ...floatingStyles, outline: 'none' }}
                            className="absolute w-[200px] bg-[#2A3143] rounded-[8px] right-0 z-50 shadow-dropdown text-[16px] leading-[16px] text-int-white-main"
                        >
                            {menuOptions.map((o, index) => {
                                const handleClick = () => {
                                    o.handleClick();
                                    onClose();
                                };

                                return (
                                    <div
                                        className="hover:bg-[#354053] rounded-[8px] cursor-pointer"
                                        key={index}
                                        {...getItemProps({
                                            onClick() {
                                                handleClick();
                                            },
                                        })}
                                    >
                                        <div
                                            className={`py-[15px] ml-[20px] mr-[5px] ${
                                                index !== menuOptions.length - 1 &&
                                                'border-b-[1px] border-int-gray-secondary'
                                            }`}
                                        >
                                            {o.text}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </FloatingFocusManager>
                </FloatingPortal>
            )}
        </Wrapper>
    );
};

export default DropdownItem;

DropdownItem.propTypes = {
    className: string,
    menuOptions: array,
    children: node.isRequired,
    offsetValue: number,
    isIcon: bool,
    maxHeightDropDown: number,
    widthDropDown: number,
    isFull: bool,
    as: string,
};
