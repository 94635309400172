import React from 'react';
import { useTranslation } from 'react-i18next';
import { bool, func, object } from 'prop-types';

import { LineOrganizationType, OrganisationStructure } from '../../../../util/constants';
import { i18PAGES } from '../../../../../config/i18n/constants';
import ReadOnlyRadioItem from '../../OfficeItemReadMode/ReadOnlyRadioItem';
import RadioInput from '../../../../../style/RadioInput/RadioInput';

const OrganizationStructureEditItem = ({ values, setFieldValue, errors, isSubmitPressed }) => {
    const { t } = useTranslation(i18PAGES.COMPANIES_INFO);
    return (
        <div className="pr-[30px] border-r border-int-gray-secondary">
            <div className="text-[14px] leading-[19px] font-Lexend600 uppercase">{t('Organisation structure')}</div>
            {isSubmitPressed && errors?.organizationStructure && (
                <div className="mt-[10px] mb-[-20px] text-[14px] text-error">{errors?.organizationStructure}</div>
            )}
            <div className="flex items-center mt-[30px]">
                <div>
                    <div>
                        <RadioInput
                            name="organizationStructure"
                            value={OrganisationStructure.LINE_ORGANIZATION}
                            label="Linienorganisation"
                            setFieldValue={setFieldValue}
                            isChecked={values?.organizationStructure === OrganisationStructure.LINE_ORGANIZATION}
                        />
                    </div>
                    <div className="mt-[16px]">
                        <RadioInput
                            name="organizationStructure"
                            value={OrganisationStructure.MATRIX_ORGANISATION}
                            label="Matrixorganisation"
                            setFieldValue={setFieldValue}
                            isChecked={values?.organizationStructure === OrganisationStructure.MATRIX_ORGANISATION}
                        />
                    </div>
                    <div className="mt-[16px]">
                        <RadioInput
                            name="organizationStructure"
                            value={OrganisationStructure.AGILE_ORGANISATION}
                            label="Agile Organisation"
                            setFieldValue={setFieldValue}
                            isChecked={values?.organizationStructure === OrganisationStructure.AGILE_ORGANISATION}
                        />
                    </div>
                </div>
                <div className="ml-[80px]">
                    {values?.organizationStructure &&
                    values?.organizationStructure === OrganisationStructure.LINE_ORGANIZATION ? (
                        <>
                            <div
                                className={`text-[14px] leading-[18px] font-Lexend600 ${
                                    values?.organizationStructure === OrganisationStructure.LINE_ORGANIZATION
                                        ? 'text-int-white-main'
                                        : 'text-int-gray-secondary'
                                } `}
                            >
                                {t('Art der Linienorganisation')}
                                {isSubmitPressed && errors?.lineOrganizationType && (
                                    <div className="mt-[10px] text-[14px] text-error">
                                        {errors?.lineOrganizationType}
                                    </div>
                                )}
                            </div>
                            <div className="mt-[16px]">
                                <RadioInput
                                    name="lineOrganizationType"
                                    value={LineOrganizationType.FUNCTIONAL_ORGANIZATION}
                                    label="Funktionale Organisation"
                                    setFieldValue={setFieldValue}
                                    isChecked={
                                        values?.lineOrganizationType === LineOrganizationType.FUNCTIONAL_ORGANIZATION
                                    }
                                />
                            </div>
                            <div className="mt-[16px]">
                                <RadioInput
                                    name="lineOrganizationType"
                                    value={LineOrganizationType.DIVISIONAL_ORGANIZATION}
                                    label="Divisionale Organisation"
                                    setFieldValue={setFieldValue}
                                    isChecked={
                                        values?.lineOrganizationType === LineOrganizationType.DIVISIONAL_ORGANIZATION
                                    }
                                />
                            </div>
                        </>
                    ) : (
                        <>
                            <div
                                className={`text-[14px] leading-[18px] font-Lexend600 ${
                                    values?.organizationStructure === OrganisationStructure.LINE_ORGANIZATION
                                        ? 'text-int-white-main'
                                        : 'text-int-gray-secondary'
                                } `}
                            >
                                {t('Art der Linienorganisation')}
                            </div>
                            <div className="mt-[16px]">
                                <ReadOnlyRadioItem
                                    label="Funktionale Organisation"
                                    isEnabled={
                                        values?.organizationStructure === OrganisationStructure.LINE_ORGANIZATION
                                    }
                                />
                            </div>
                            <div className="mt-[16px]">
                                <ReadOnlyRadioItem
                                    label="Divisionale Organisation"
                                    isEnabled={
                                        values?.organizationStructure === OrganisationStructure.LINE_ORGANIZATION
                                    }
                                />
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default OrganizationStructureEditItem;

OrganizationStructureEditItem.propTypes = {
    values: object.isRequired,
    errors: object.isRequired,
    isSubmitPressed: bool,
    setFieldValue: func.isRequired,
};
