import React from 'react';
import { func, object } from 'prop-types';
import { useLocation } from 'react-router-dom';

const ProjectInfoMenuDynamicChildrenItem = ({ itemChildren, handleClickDynamicSubMenu, menuItem }) => {
    const location = useLocation();

    return (
        <div className="text-[14px] leading-[16px] font-Lexend400 text-int-white-secondary px-[50px] py-[8px]">
            <div
                className={`inline-block cursor-pointer hover:text-int-white-main ${
                    location.search.includes(
                        `companyId=${itemChildren.companyId}&countryId=${itemChildren.countryId}&cityId=${itemChildren.cityId}&officeId=${itemChildren.officeId}`
                    ) &&
                    location.pathname.includes(menuItem.pageEnum.enumName.toLowerCase()) &&
                    'text-int-white-main'
                }`}
                onClick={() => handleClickDynamicSubMenu()}
            >
                <div>{itemChildren?.companyName?.toUpperCase()}</div>
                <div className="text-int-white-secondary">
                    {`${itemChildren.countryName}, ${itemChildren.cityName}, ${itemChildren.officeName}`}
                </div>
            </div>
        </div>
    );
};

export default ProjectInfoMenuDynamicChildrenItem;

ProjectInfoMenuDynamicChildrenItem.propTypes = {
    itemChildren: object.isRequired,
    menuItem: object.isRequired,
    handleClickDynamicSubMenu: func.isRequired,
};
