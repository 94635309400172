import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { func, number, string } from 'prop-types';
import { ReactComponent as ThreeDotsIcon } from '../../../assets/icons/threeDotIcon.svg';
import { i18PAGES } from '../../../config/i18n/constants';
import useOutsideAlerter from '../../util/useOutsideAlerter';
import { WORKERS_STATUS } from '../../util/constants';
import { useResendWorkerByIdMutation } from '../../../store/API/workersInformationAPI';
import { useToast } from '../../../hoc/toast/ToastProvider';
import { ReactComponent as MenuWorkersInformationIcon } from '../../../assets/icons/menuWorkersInformationIcon.svg';

const WorkersThreeDotsOptions = ({
    id,
    handleClickEdit,
    handleClickDelete,
    status,
    setIsResendingWorkerById,
    projectId,
}) => {
    const { t } = useTranslation(i18PAGES.WORKERS_EFFICIENCY);

    const { successToast, errorToast } = useToast();

    const [isOpen, setIsOpen] = useState(false);

    const onToggle = useCallback(() => {
        setIsOpen((prev) => !prev);
    }, []);

    const onClose = useCallback(() => {
        setIsOpen(false);
    }, []);

    const container = useRef(null);
    useOutsideAlerter({ ref: container, callback: onClose });

    const resendPointView = useMemo(
        () => status === WORKERS_STATUS.WAITING_FOR_RESPONSE || status === WORKERS_STATUS.RESULT_IS_SAVED,
        [status]
    );

    const [resendWorkerById] = useResendWorkerByIdMutation();

    const resendById = () => {
        setIsResendingWorkerById(true);
        resendWorkerById({
            projectId,
            workerDetailsId: id,
        })
            .unwrap()
            .then(() => {
                successToast(
                    t('Survey were successfully resent to user'),
                    '',
                    <MenuWorkersInformationIcon fill="#EDF1FD" />,
                    ''
                );
            })
            .catch(() => {
                errorToast(t('Error resent survey to user'));
            })
            .finally(() => {
                setIsResendingWorkerById(false);
            });
    };

    return (
        <div className="w-[100%] pr-[30px] flex justify-end" ref={container}>
            <div className="relative">
                <div onClick={onToggle} className="w-[18px] h-[18px] cursor-pointer flex items-center">
                    <ThreeDotsIcon fill="#9BA1B2" />
                </div>
                {isOpen && (
                    <div className="absolute z-10 left-[0px] top-[20px] bg-[#2A3143] rounded-lg text-[14px] leading-[14px] font-Lexend400 text-int-white-main py-[5px]">
                        {resendPointView && (
                            <>
                                <div
                                    className="px-[12px] h-[35px] flex items-center hover:bg-int-gray-secondary cursor-pointer whitespace-nowrap "
                                    onClick={() => {
                                        resendById();
                                        onClose();
                                    }}
                                >
                                    {t('Resend survey')}
                                </div>
                                <div className="mx-[12px] h-[1px] bg-int-gray-secondary" />
                            </>
                        )}
                        <div
                            className="px-[12px] h-[35px] flex items-center hover:bg-int-gray-secondary cursor-pointer"
                            onClick={() => {
                                handleClickEdit();
                                onClose();
                            }}
                        >
                            {t('Edit')}
                        </div>
                        <div
                            className="px-[12px] h-[35px] flex items-center  hover:bg-int-gray-secondary cursor-pointer"
                            onClick={() => {
                                handleClickDelete();
                                onClose();
                            }}
                        >
                            {t('Delete')}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default WorkersThreeDotsOptions;

WorkersThreeDotsOptions.propTypes = {
    id: number.isRequired,
    handleClickDelete: func.isRequired,
    handleClickEdit: func.isRequired,
    status: string.isRequired,
    setIsResendingWorkerById: func.isRequired,
    projectId: string.isRequired,
};
