import { array, object, string } from 'yup';
import { REGEX } from '../../util/constants';

export const asIsIntegrationsValidationSchema = object().shape({
    asIsIntegrationsData: array().of(
        object().shape({
            outgoingBuildingBlock: object().shape({
                id: string(),
                name: string().required('Outgoing Building Block should be selected'),
            }),
            ingoingBuildingBlock: object().shape({
                id: string(),
                name: string().required('Ingoing Building Block should be selected'),
            }),
            outgoingApp: string().max(15, 'Max 15 symbols'),
            ingoingApp: string().max(15, 'Max 15 symbols'),
            dataType: string().required('Data Type should be selected'),
            integrationType: string().required('Integration Type should be selected'),
            operationType: string().required('Operation Type should be selected'),
            supportEffort: string()
                .required('Support effort should be stated')
                .matches(REGEX.INTEGER_POSITIVE, 'Invalid support effort'),
            frequency: string().required('Frequency should be selected'),
            numberOfFrequency: string().required('Number Of Frequence should be selected'),
        })
    ),
});
