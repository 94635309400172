import React from 'react';
import { FieldArray } from 'formik';
import { func, object } from 'prop-types';
import HrStrategyEditItem from './HrStrategyEditItem';
import HrStrategyTableLabels from '../HrStrategyTableLabels';
import ModulesStrategyAddLineButton from '../../Common/Modules&StrategyAddLineButton';
import { hrStrategiesEmptyObject } from '../../Common/constants';

const HrStrategyEditMode = ({ values, errors, touched, handleBlur, handleChange, setFieldValue }) => (
    <div className="bg-int-gray-main p-[28px] pr-[56px] rounded-[8px]">
        <HrStrategyTableLabels />
        <FieldArray
            name="hrStrategies"
            render={(ArrayHelpers) => (
                <>
                    {values.hrStrategies?.map((we, index) => {
                        const hrStrategiesErrors = (errors.hrStrategies?.length && errors.hrStrategies[index]) || {};

                        const hrStrategiesTouched = (touched.hrStrategies?.length && touched.hrStrategies[index]) || {};

                        return (
                            <HrStrategyEditItem
                                key={index}
                                hrStrategiesTouched={hrStrategiesTouched}
                                hrStrategiesErrors={hrStrategiesErrors}
                                handleBlur={handleBlur}
                                handleChange={handleChange}
                                ArrayHelpers={ArrayHelpers}
                                values={values}
                                index={index}
                                setFieldValue={setFieldValue}
                            />
                        );
                    })}
                    <ModulesStrategyAddLineButton ArrayHelpers={ArrayHelpers} pushObject={hrStrategiesEmptyObject} />
                </>
            )}
        />
    </div>
);

export default HrStrategyEditMode;

HrStrategyEditMode.propTypes = {
    values: object.isRequired,
    errors: object.isRequired,
    touched: object.isRequired,
    handleBlur: func.isRequired,
    handleChange: func.isRequired,
    setFieldValue: func.isRequired,
};
