import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { string } from 'prop-types';
import { i18PAGES } from '../../../config/i18n/constants';

const FteNoDataBlock = ({ projectId }) => {
    const navigate = useNavigate();
    const { t } = useTranslation(i18PAGES.FTE_PROCESS);

    const goToWorkersInformation = () => {
        navigate(`/workers_information?projectId=${projectId}`);
    };
    return (
        <div className="flex flex-col items-center min-w-[804px]">
            <div className="text-[24px] leading-[24px] font-Lexend500 mt-[170px]">{t('No information to process')}</div>
            <div className="text-[18px] leading-[28px] font-Lexend400 mt-[24px]">
                {t('To fill in the FTE process information you need to fill in the Workers information first')}
            </div>
            <div
                className="border-[1px] border-int-grapefruit p-[12px]
                             text-[16px] leading-[16px] text-int-grapefruit
                              font-Lexend500 rounded-lg cursor-pointer mt-[32px]"
                onClick={goToWorkersInformation}
            >
                {t('Go to Workers information')}
            </div>
        </div>
    );
};

export default FteNoDataBlock;

FteNoDataBlock.propTypes = {
    projectId: string.isRequired,
};
