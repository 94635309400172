import { useDispatch, useSelector } from 'react-redux';
import { setShowFullMenu, setShowShortMenu } from '../reducers/menuShowReducer';

export const useShowMenu = () => {
    const dispatch = useDispatch();

    const showFullMenu = useSelector((state) => state.isShowFullMenu.isShowFullMenu);

    const _setShowFullMenu = () => {
        dispatch(setShowFullMenu());
    };

    const _setShowShortMenu = () => {
        dispatch(setShowShortMenu());
    };

    return {
        showFullMenu,
        setShowFullMenu: _setShowFullMenu,
        setShowShortMenu: _setShowShortMenu,
    };
};
