import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SuccessConfirmation from '../components/Confirmation/SuccessConfirmation';
import FailedConfirmation from '../components/Confirmation/FailedConfirmation';
import { i18PAGES } from '../config/i18n/constants';

const ConfirmationPage = () => {
    const { t } = useTranslation(i18PAGES.AUTH);

    const { confirmationType } = useParams();
    const node = useMemo(() => {
        // Email
        if (confirmationType === 'email-success') {
            return (
                <SuccessConfirmation
                    buttonText={t('Go to Login')}
                    linkPath="/login"
                    text={t('Your email was successfully confirmed.')}
                />
            );
        }

        if (confirmationType === 'email-failed') {
            return (
                <FailedConfirmation
                    buttonText={t('Retry again')}
                    linkPath="/register"
                    text={t('Your email confirmation was failed. Please try again or return to the Home page')}
                />
            );
        }

        // Payment
        if (confirmationType === 'payment') {
            return (
                <SuccessConfirmation
                    buttonText={t('Go to Homepage')}
                    linkPath="/"
                    text={t('Your payment was successfully completed. Thank you for your order.')}
                />
            );
        }

        if (confirmationType === 'payment') {
            return (
                <FailedConfirmation
                    buttonText={t('Retry Payment')}
                    linkPath="/"
                    text={t(
                        'The transaction was aborted due to technical reasons. Please try again or return to the Home page'
                    )}
                />
            );
        }

        // Password-reset
        if (confirmationType === 'expired-password-token') {
            return (
                <FailedConfirmation
                    buttonText={t('Retry Reset Password')}
                    linkPath="/forgot-password"
                    text={t('The token was expired')}
                />
            );
        }

        return <FailedConfirmation buttonText={t('Go to Homepage')} linkPath="/" text="" />;
    }, [confirmationType]);

    return <div className="min-h-[calc(100vh-300px)] h-[100%] flex items-center justify-center">{node}</div>;
};

export default ConfirmationPage;
