import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { bool, func } from 'prop-types';
import { i18PAGES } from '../../../../config/i18n/constants';
import { ReactComponent as EditIcon } from '../../../../assets/icons/editIcon.svg';

const BbCardEditButton = (props) => {
    const { isEditMode, setIsEditMode, isSendRequestAvailable, handleSubmit, isSubmitting } = props;
    const { t } = useTranslation(i18PAGES.OPTIONS);

    const toggleEditMode = useCallback(() => {
        setIsEditMode((prev) => !prev);
    }, [setIsEditMode]);

    if (isEditMode) {
        return (
            <button
                type="button"
                className={`h-[50px] pr-[32px] rounded-[8px] pl-[28px] ${
                    isSendRequestAvailable ? 'bg-int-gray-secondary' : 'bg-int-grapefruit'
                } font-Lexend400 text-[18px] leading-[18px] ${isSubmitting && 'opacity-[0.6]'}`}
                onClick={handleSubmit}
                disabled={isSubmitting}
            >
                {t('Save changes')}
            </button>
        );
    }

    return (
        <button
            type="button"
            className={`h-[50px] px-[32px] rounded-[8px] bg-int-gray-secondary font-Lexend400 text-[18px] leading-[18px] ${
                isSubmitting && 'opacity-[0.6]'
            }`}
            onClick={toggleEditMode}
            disabled={isSubmitting}
        >
            <div className="flex items-center justify-center">
                <EditIcon />
                <div className="ml-[6px]">{t('Select providers')}</div>
            </div>
        </button>
    );
};

export default BbCardEditButton;

BbCardEditButton.propTypes = {
    isEditMode: bool.isRequired,
    setIsEditMode: func.isRequired,
    isSendRequestAvailable: bool,
    handleSubmit: func.isRequired,
    isSubmitting: bool,
};
