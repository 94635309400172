import { createContext, useContext, useEffect, useMemo } from 'react';
import { node } from 'prop-types';
import { toast, Toaster } from 'react-hot-toast';
import { useLocation, useSearchParams } from 'react-router-dom';
import { ReactComponent as Icon } from '../../assets/icons/modal-success.svg';
import { ReactComponent as MenuCloseIcon } from '../../assets/icons/menuCloseIcon.svg';

const toastContext = createContext(null);

export const useToast = () => {
    const { successToast, errorToast } = useContext(toastContext);

    return {
        successToast,
        errorToast,
    };
};

export function ToastProvider({ children }) {
    const { pathname } = useLocation();
    const [searchParams] = useSearchParams();
    const officeId = searchParams.get('officeId');
    const current = searchParams.get('current');

    const successToast = (mainMessage, subMessage = '', icon = '', duration = 3000) => {
        toast.success(
            (t) => (
                <div className="w-[100%]">
                    <div
                        className="absolute right-[8px] top-[3px] cursor-pointer rounded-full hover:bg-green-800 w-[20px] h-[20px] flex items-center justify-center"
                        onClick={() => toast.dismiss(t.id)}
                    >
                        <MenuCloseIcon width={10} height={10} fill="white" />
                    </div>
                    <div className="flex items-start">
                        <div className="flex-start p-[4px]">{icon || <Icon />}</div>
                        <div className="ml-[8px] font-Lexend500 leading-[24px] text-[16px]">{mainMessage}</div>
                    </div>
                    <div className="text-[14px] font-Lexend300 leading-[22px]">{subMessage}</div>
                </div>
            ),
            {
                duration,
                className: 'text-center',
            }
        );
    };

    const errorToast = (message, duration = 3000) => {
        toast.error(
            (t) => (
                <div>
                    <div
                        className="absolute right-[8px] top-[3px] cursor-pointer rounded-full hover:bg-red-700 w-[20px] h-[20px] flex items-center justify-center"
                        onClick={() => toast.dismiss(t.id)}
                    >
                        <MenuCloseIcon width={10} height={10} fill="white" />
                    </div>
                    <div>{message}</div>
                </div>
            ),
            {
                duration,
                className: 'text-center',
            }
        );
    };

    const defaultProps = useMemo(
        () => ({
            successToast,
            errorToast,
        }),
        []
    );

    useEffect(() => {
        toast.remove();
    }, [pathname, current, officeId]);

    return (
        <toastContext.Provider value={defaultProps}>
            <Toaster
                position="top-center"
                reverseOrder={false}
                gutter={8}
                toastOptions={{
                    duration: 3000,
                    style: {
                        paddingRight: '30px',
                        maxWidth: '80vw',
                    },
                    success: {
                        icon: '',
                        style: {
                            paddingLeft: 0,
                            background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), #47E273',
                            color: '#EDF1FD',
                            borderRadius: '8px',
                        },
                    },
                    error: {
                        icon: '',
                        style: {
                            paddingLeft: '0px',
                            background: '#852e38',
                            color: '#EDF1FD',
                            borderRadius: '8px',
                        },
                    },
                }}
            />
            {children}
        </toastContext.Provider>
    );
}

ToastProvider.propTypes = {
    children: node.isRequired,
};
