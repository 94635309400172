import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { HEADERS } from '../../components/util/constants';

export const modulesStrategiesAPI = createApi({
    reducerPath: 'modulesStrategiesAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api',
        prepareHeaders: HEADERS,
    }),
    tagTypes: ['modulesStrategiesList'],
    endpoints: (build) => ({
        getProblemsOverview: build.query({
            query: (projectId) => `/projects/${projectId}/problems-overview`,
            providesTags: ['modulesStrategiesList'],
        }),
        getWeaknessExpectations: build.query({
            query: (projectId) => `/projects/${projectId}/weaknesses-and-expectations`,
            providesTags: ['modulesStrategiesList'],
        }),
        getActions: build.query({
            query: (projectId) => `/projects/${projectId}/actions`,
            providesTags: ['modulesStrategiesList'],
        }),
        getHrDigitalization: build.query({
            query: (projectId) => `/projects/${projectId}/hr-digitalizations`,
            providesTags: ['modulesStrategiesList'],
        }),
        getGoals: build.query({
            query: (projectId) => `/projects/${projectId}/goals`,
            providesTags: ['modulesStrategiesList'],
        }),
        getHrStrategy: build.query({
            query: (projectId) => `/projects/${projectId}/hr-strategies`,
            providesTags: ['modulesStrategiesList'],
        }),
        getChallenges: build.query({
            query: (projectId) => `/projects/${projectId}/challenges`,
            providesTags: ['modulesStrategiesList'],
        }),

        sendModuleStrategies: build.mutation({
            query: (body) => ({
                url: `/modules-strategies`,
                method: 'POST',
                body,
            }),
        }),
    }),
});

export const {
    useGetProblemsOverviewQuery,
    useGetWeaknessExpectationsQuery,
    useGetActionsQuery,
    useGetHrDigitalizationQuery,
    useGetGoalsQuery,
    useGetHrStrategyQuery,
    useGetChallengesQuery,
    useSendModuleStrategiesMutation,
} = modulesStrategiesAPI;
