import React from 'react';
import { bool, func, number } from 'prop-types';
import { useTranslation } from 'react-i18next';
import ModalWindow from '../../../style/ModalWindow/ModalWindow';
import { PrimaryButton } from '../../../style/Button/Button';
import { i18PAGES } from '../../../config/i18n/constants';

const DeleteRecentSubscriptionsModal = ({
    isOpenDeleteRecentSubscription,
    setIsOpenDeleteRecentSubscription,
    id,
    isLoading = false,
    handleDeleteClickOne,
    handleDeleteClickTwo,
    isOneType = true,
}) => {
    const { t } = useTranslation(i18PAGES.SUBSCRIPTION_MANAGEMENT);
    return (
        <ModalWindow
            isOpen={isOpenDeleteRecentSubscription}
            setIsOpen={setIsOpenDeleteRecentSubscription}
            className="-top-[210px] w-[580px]"
            isCloseClickOutside
        >
            <div className="flex flex-col items-center">
                <div className="text-[24px] leading-[24px] font-Lexend500 text-int-white-main">
                    {t('Delete module')}
                </div>
                <div className="text-[18px] leading-[24px] font-Lexend400 text-int-white-main my-[50px] text-center">
                    {isOneType ? (
                        <div>{t('Are you sure you want to delete module?')}</div>
                    ) : (
                        <div>{t('Are you sure you want to delete selected modules?')}</div>
                    )}
                </div>

                <div className="w-[100%] flex justify-around">
                    <PrimaryButton
                        className="w-[210px] h-[50px]"
                        handleClick={() => {
                            setIsOpenDeleteRecentSubscription(false);
                        }}
                    >
                        <div className="text-[18px] font-Lexend400">{t('Cancel')}</div>
                    </PrimaryButton>
                    <PrimaryButton
                        isPink
                        className="w-[210px] h-[50px]"
                        handleClick={isOneType ? () => handleDeleteClickOne([id]) : () => handleDeleteClickTwo()}
                        disabled={isLoading}
                    >
                        <div className="text-[18px] font-Lexend400">{t('Delete')}</div>
                    </PrimaryButton>
                </div>
            </div>
        </ModalWindow>
    );
};

export default DeleteRecentSubscriptionsModal;

DeleteRecentSubscriptionsModal.propTypes = {
    isOpenDeleteRecentSubscription: bool.isRequired,
    setIsOpenDeleteRecentSubscription: func.isRequired,
    id: number,
    isLoading: bool,
    handleDeleteClickOne: func,
    handleDeleteClickTwo: func,
    isOneType: bool.isRequired,
};
