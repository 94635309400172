export const checkedOfficesWithCountCheckedModulesArrValidation = (value) =>
    value
        ?.map((company) =>
            company.countries
                .map((countries) => countries.offices)
                .flat()
                .filter((i) => i.isChecked === true)
                .map((offices) =>
                    offices.officeModules
                        .map((module) => module.buildingBlocks)
                        .flat()
                        .filter((bb) => bb.selected === true)
                )
                .map((item) => item.length)
        )
        .map((item) => item.length > 0 && !item.includes(0));

export const dataSendOfficeModules = (value) =>
    value
        .map((company) =>
            company.countries
                .map((country) =>
                    country.offices.map((office) => ({
                        officeId: office.id,
                        moduleBuildingBlocks: office.officeModules.map((officeModule) => ({
                            moduleId: officeModule.moduleId,
                            moduleName: officeModule.title,
                            buildingBlocks: officeModule.buildingBlocks,
                        })),
                    }))
                )
                .flat()
        )
        .flat()
        .filter((o) => {
            const chosenOfficesId = [];
            o?.moduleBuildingBlocks.forEach((bb) => {
                bb.buildingBlocks.forEach((block) => {
                    if (block.selected === true) {
                        chosenOfficesId.push(o.officeId);
                    }
                });
            });

            return chosenOfficesId.includes(o.officeId);
        });
