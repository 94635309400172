export const i18PAGES = {
    PROJECTS_MANAGEMENT: 'PROJECTS_MANAGEMENT',
    USER_MANAGEMENT: 'USER_MANAGEMENT',
    SETUP_COMPANIES: 'SETUP_COMPANIES',
    AUTH: 'AUTH',
    PROJECT_LIST: 'PROJECT_LIST',
    PROFILE: 'PROFILE',
    MODULES: 'MODULES',
    HR_TOOLS: 'HR_TOOLS',
    PROJECTS_SETUP: 'PROJECTS_SETUP',
    COMPANIES_LIST: 'COMPANIES_LIST',
    COMPANIES_INFO: 'COMPANIES_INFO',
    PROVIDER_SELECTION: 'PROVIDER_SELECTION',
    FTE_PROCESS: 'FTE_PROCESS',
    WORKERS_EFFICIENCY: 'WORKERS_EFFICIENCY',
    MODULES_AND_STRATEGY: 'MODULES_AND_STRATEGY',
    PLATFORM_MENU: 'PLATFORM_MENU',
    AS_IS_APPLICATION: 'AS_IS_APPLICATION',
    AS_IS_INTEGRATIONS: 'AS_IS_INTEGRATIONS',
    TO_BE_APPLICATIONS: 'TO_BE_APPLICATIONS',
    TO_BE_INTEGRATIONS: 'TO_BE_INTEGRATIONS',
    INVOICE: 'INVOICE',
    SUBSCRIPTION_MANAGEMENT: 'SUBSCRIPTION_MANAGEMENT',
    OPTIONS: 'OPTIONS',
    QUESTIONNAIRE: 'QUESTIONNAIRE',
};
