import React from 'react';
import { string } from 'prop-types';
import { stringShorter } from '../../../util/stringShorter';

const ChoicePageItem = ({ name }) => (
    <div className="w-[120px] px-[4px] h-[22px] bg-[#9BA1B2] bg-opacity-30 flex items-center rounded-[2px] mr-[6px]">
        <div className="text-[14px] text-int-white-main">{stringShorter(name, 13)}</div>
    </div>
);

export default ChoicePageItem;

ChoicePageItem.propTypes = {
    name: string.isRequired,
};
