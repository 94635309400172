import React from 'react';
import { arrayOf, func, number, object, shape, string } from 'prop-types';
import { FieldArray } from 'formik';
import { useTranslation } from 'react-i18next';
import TableHeader from '../../../../style/Table/TableHeader';
import { ReactComponent as AddUserIcon } from '../../../../assets/icons/addIcon.svg';
import { i18PAGES } from '../../../../config/i18n/constants';
import { ReactComponent as DeleteIcon } from '../../../../assets/icons/userManagementBigDeleteIcon.svg';
import { generateKey } from '../../../util/generateKey';
import ToBeIntegrationsEditTableItem from './ToBeIntegrationsEditTableItem';
import { INITIAL_TO_BE_INTEGRATIONS_VALUE_DATA } from '../constants';

const ToBeIntegrationsEditTable = ({
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    tableHeaderData,
    setFieldValue,
    dataToBeIntegrationsOptions,
}) => {
    const { t } = useTranslation(i18PAGES.TO_BE_INTEGRATIONS);

    return (
        <FieldArray
            name="toBeIntegrationsData"
            render={(ArrayHelpers) => (
                <div>
                    <div className="flex">
                        <div id="bold-scroll" className="overflow-auto max-w-[1450px]">
                            <table className="table-fixed">
                                <TableHeader data={tableHeaderData} />
                                <tbody className="bg-int-gray-secondary">
                                    {values?.toBeIntegrationsData?.map((item, index) => {
                                        const toBeIntegrationsErrors =
                                            (errors.toBeIntegrationsData?.length &&
                                                errors.toBeIntegrationsData[index]) ||
                                            {};
                                        const toBeIntegrationsTouched =
                                            (touched.toBeIntegrationsData?.length &&
                                                touched.toBeIntegrationsData[index]) ||
                                            {};
                                        return (
                                            <ToBeIntegrationsEditTableItem
                                                values={values}
                                                index={index}
                                                handleChange={handleChange}
                                                handleBlur={handleBlur}
                                                toBeIntegrationsTouched={toBeIntegrationsTouched}
                                                toBeIntegrationsErrors={toBeIntegrationsErrors}
                                                ArrayHelpers={ArrayHelpers}
                                                setFieldValue={setFieldValue}
                                                key={item.key}
                                                dataToBeIntegrationsOptions={dataToBeIntegrationsOptions}
                                            />
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                        <div className="mt-[65px]">
                            {values?.toBeIntegrationsData?.map((item, index) => (
                                <div
                                    className="flex h-[60px] justify-center items-center px-[10px] cursor-pointer"
                                    onClick={() => {
                                        ArrayHelpers.remove(index);
                                    }}
                                    key={item.key}
                                >
                                    <DeleteIcon />
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="flex items-center mt-[20px]">
                        <div
                            className="flex items-center cursor-pointer "
                            onClick={() => {
                                ArrayHelpers.push({
                                    ...INITIAL_TO_BE_INTEGRATIONS_VALUE_DATA,
                                    key: generateKey(values.toBeIntegrationsData.length - 1),
                                });
                            }}
                        >
                            <div className="mr-[6px] ">
                                <AddUserIcon width={16} height={16} fill="#9BA1B2" />
                            </div>
                            <div className="text-int-white-secondary text-[14px]">{t('Add 1 more integration')}</div>
                        </div>
                    </div>
                </div>
            )}
        />
    );
};

export default ToBeIntegrationsEditTable;

ToBeIntegrationsEditTable.propTypes = {
    values: object.isRequired,
    errors: object.isRequired,
    touched: object.isRequired,
    handleChange: func.isRequired,
    handleBlur: func.isRequired,
    setFieldValue: func.isRequired,
    tableHeaderData: arrayOf(
        shape({
            minWidth: number,
            name: string,
        })
    ),
    dataToBeIntegrationsOptions: object,
};
