import React from 'react';
import { func, number, object } from 'prop-types';
import TextArea from '../../../../../style/TextArea/TextArea';
import { ReactComponent as DeleteIcon } from '../../../../../assets/icons/userManagementBigDeleteIcon.svg';

const HrStrategyEditItem = ({
    index,
    values,
    handleBlur,
    handleChange,
    hrStrategiesTouched,
    hrStrategiesErrors,
    ArrayHelpers,
    setFieldValue,
}) => (
    <div className="flex mb-[10px]">
        <div className="flex items-center">
            <TextArea
                label=""
                handleChange={handleChange}
                name={`hrStrategies.${index}.name`}
                value={values?.hrStrategies[index]?.name || ''}
                handleBlur={handleBlur}
                isError={!!(hrStrategiesTouched.name && hrStrategiesErrors.name)}
                errorText={hrStrategiesErrors.name}
                className="w-[370px] mr-[10px]"
                height={84}
                setFieldValue={setFieldValue}
                limit={100}
            />

            <TextArea
                label=""
                handleChange={handleChange}
                name={`hrStrategies.${index}.strategyPoints`}
                value={values?.hrStrategies[index]?.strategyPoints || ''}
                handleBlur={handleBlur}
                isError={!!(hrStrategiesTouched.strategyPoints && hrStrategiesErrors.strategyPoints)}
                errorText={hrStrategiesErrors.strategyPoints}
                className="w-[1000px]"
                height={84}
                setFieldValue={setFieldValue}
                limit={500}
            />

            <div
                className="flex justify-center items-center w-[20px] h-[42px] mr-[12px] cursor-pointer ml-[13px]"
                onClick={() => ArrayHelpers.remove(index)}
            >
                <DeleteIcon />
            </div>
        </div>
    </div>
);

export default HrStrategyEditItem;

HrStrategyEditItem.propTypes = {
    index: number.isRequired,
    values: object.isRequired,
    handleBlur: func.isRequired,
    handleChange: func.isRequired,
    hrStrategiesTouched: object.isRequired,
    hrStrategiesErrors: object.isRequired,
    ArrayHelpers: object.isRequired,
    setFieldValue: func.isRequired,
};
