import React, { useState } from 'react';
import { number, object } from 'prop-types';
import { COLOR_PALETTE } from '../fteConst';

const FteGraphViewItem = ({ item, index, maximumXGraph }) => {
    const [isShowHelper, setIsShowHelper] = useState(false);

    const widthLinePercent = (item.value / maximumXGraph) * 100;

    return (
        <div className="h-[68px] rounded-lg bg-[#2A3143] flex mb-[9px] pr-[10px]" key={index}>
            <div className="max-w-[237px] w-[100%] h-[68px] flex justify-start items-center pl-[19px] pr-[22px] text-[14px] leading-[24px] text-left">
                {item.label}
            </div>
            <div className="w-[100%] h-[75px] flex relative">
                {[...Array(10)].map((i, index) => (
                    <div className="w-[10%] border-l-[1px] border-[#586476] border-dashed z-[1]" key={index} />
                ))}
                <div className="border-r-[1px] border-[#586476] border-dashed z-[1]" key={index} />
                <div
                    className="h-[36px] rounded-lg bg-[#687890] top-[17px] z-[0] absolute"
                    style={{
                        width: `${widthLinePercent}%`,
                        backgroundColor: `${COLOR_PALETTE[index % 6]}`,
                    }}
                />

                <div
                    className="h-[36px] top-[17px] z-[2] absolute"
                    style={{
                        width: `${widthLinePercent}%`,
                    }}
                    onMouseEnter={() => setIsShowHelper(true)}
                    onMouseLeave={() => setIsShowHelper(false)}
                />
                {isShowHelper && (
                    <div
                        className="flex items-center justify-center absolute w-[60px] h-[30px] bg-int-main-black whitespace-nowrap rounded-[8px] z-[2]"
                        style={{
                            left: `calc(${widthLinePercent}% - 30px)`,
                            top: '-22px',
                        }}
                    >
                        <div className=" text-center text-[14px] z-[2]">{item.value}%</div>
                        <div className="w-[16px] h-[16px] bg-int-main-black absolute left-[calc(50%-8px)] bottom-[-5px] rotate-45" />
                    </div>
                )}
            </div>
        </div>
    );
};

export default FteGraphViewItem;

FteGraphViewItem.propTypes = {
    item: object,
    index: number,
    maximumXGraph: number,
};
