import React, { useEffect, useState } from 'react';
import { number, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CommentIcon } from '../../assets/icons/add-comment.svg';
import { useGetCommentsQuery } from '../../store/API/commentsAPI';
import { i18PAGES } from '../../config/i18n/constants';
import CommentsContent from './CommentsContent';

const Comments = ({ pageIdentifier, subPageIdentifier, projectId }) => {
    const [isOpen, setIsOpen] = useState(false);

    const { t } = useTranslation(i18PAGES.COMPANIES_INFO);

    const { data } = useGetCommentsQuery(
        { pageIdentifier, projectId, pageNumber: 0, subPageIdentifier },
        { skip: !projectId || !pageIdentifier }
    );

    const onToggleHandler = () => {
        setIsOpen((prev) => !prev);
    };

    useEffect(() => {
        setIsOpen(false);
    }, [subPageIdentifier]);

    return (
        <div className="relative">
            <div className="cursor-pointer hover:opacity-[0.8] flex items-center" onClick={onToggleHandler}>
                <CommentIcon />
                {data?.unreadCommentsCounter > 0 && (
                    <div className="cursor-pointer absolute top-[-6px] left-[16px] bg-int-grapefruit flex items-center justify-center w-[21px] h-[15px] rounded-[36px] text-[14px] font-Lexend500">
                        {data?.unreadCommentsCounter}
                    </div>
                )}
                <div className="text-[18px] leading-[18px] ml-[10px] font-Lexend400 cursor-pointer">
                    {t('Page comments')}
                </div>
            </div>
            {isOpen && (
                <CommentsContent
                    setIsOpen={setIsOpen}
                    pageIdentifier={pageIdentifier}
                    projectId={projectId}
                    isOpen={isOpen}
                    subPageIdentifier={subPageIdentifier}
                />
            )}
        </div>
    );
};

export default Comments;

Comments.propTypes = {
    pageIdentifier: string.isRequired,
    subPageIdentifier: string,
    projectId: number,
};
