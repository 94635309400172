import React from 'react';
import { string } from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { i18PAGES } from '../../../config/i18n/constants';

const PageViewerItem = ({ name, link }) => {
    const { t } = useTranslation(i18PAGES.PROJECT_LIST);

    return (
        <div className="bg-[#29323F] relative min-w-[128px] w-[128px] min-h-[111px] mr-[10px] px-[6px] rounded-[4px]">
            <div className="pt-[18px] text-[14px] leading-[16px] text-int-white-main">
                <div className="flex font-Lexend400 items-center justify-center h-[50px] text-center">{name}</div>
            </div>
            <Link to={link}>
                <div className="absolute bottom-[16px] text-[12px] font-Lexend400 text-int-main-black flex items-center justify-center rounded-[17px] h-[23px] w-[112px] bg-int-white-secondary">
                    {t('Go to page')}
                </div>
            </Link>
        </div>
    );
};

export default PageViewerItem;

PageViewerItem.propTypes = {
    name: string.isRequired,
    link: string.isRequired,
};
