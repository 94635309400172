import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { arrayOf, number, shape, string, bool } from 'prop-types';
import html2canvas from 'html2canvas';
import { jsPDF as JsPDF } from 'jspdf';
import { ReactComponent as LogoIcon } from '../../assets/icons/logo-big.svg';
import InvoiceSupplierAndCustomerInfo from './InvoiceSupplierAndCustomerInfo';
import InvoiceModuleTableInfo from './InvoiceModuleTableInfo';
import InvoiceFooterInfo from './InvoiceFooterInfo';
import InvoiceDetailsInfo from './InvoiceDetailsInfo';
import { i18PAGES } from '../../config/i18n/constants';
import { PrimaryButton } from '../../style/Button/Button';
import LoadingAndTextOverLayer from '../../style/LoadingAndTextOverLayer/LoadingAndTextOverLayer';
import { downloadPdfInvoice, downloadPdfInvoiceByToken } from '../Subscription/Invoices/downloadPdfInvoice';
import { useToast } from '../../hoc/toast/ToastProvider';
import { useUploadPdfInvoiceByIdMutation, useUploadPdfInvoiceByTokenMutation } from '../../store/API/invoiceAPI';

const Invoice = ({
    typeInvoiceByToken,
    token,
    fileLoaded,
    invoiceNumber,
    supplierCompanyName,
    supplierCompanyAddress,
    createdDate,
    customerCompanyName,
    customerCompanyAddress,
    subjectMatter,
    modules,
    subscriptionAmount,
    tax,
    totalAmount,
    wahlerCompanyEmail,
    supplierBankInformation,
}) => {
    const { t } = useTranslation(i18PAGES.INVOICE);

    const refPdf = useRef(null);
    const [isGeneratePdfFile, setIsGeneratePdfFile] = useState(false);
    const [isSuccessUploadPdf, setIsSuccessUploadPdf] = useState(true);
    const { successToast, errorToast } = useToast();

    const [uploadPdf] = useUploadPdfInvoiceByIdMutation();
    const [uploadPdfByToken] = useUploadPdfInvoiceByTokenMutation();

    useEffect(() => {
        if (fileLoaded === false) {
            setIsGeneratePdfFile(true);
            setIsSuccessUploadPdf(false);
            html2canvas(refPdf.current).then((canvas) => {
                const imageData = canvas.toDataURL('image/png');
                const pdf = new JsPDF({ orientation: 'p', unit: 'mm', compress: true });
                const imgProps = pdf.getImageProperties(imageData);
                const pdfWidth = pdf.internal.pageSize.getWidth();
                const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
                pdf.addImage(imageData, 'PNG', 10, 10, pdfWidth - 20, pdfHeight - 20);
                const blob = new Blob([pdf.output('blob')], { type: 'application/pdf' });
                const file = new File([blob], `${invoiceNumber}.pdf`, { type: 'application/pdf' });
                const formData = new FormData();
                formData.append('invoice', file);

                if (typeInvoiceByToken) {
                    uploadPdfByToken({ token, body: formData })
                        .unwrap()
                        .then(() => {
                            successToast(t('Pdf invoice generate and upload successfully'));
                            setIsSuccessUploadPdf(true);
                        })
                        .catch(() => {
                            errorToast(t('Pdf invoice generate and upload error, refresh page or try later'));
                        })
                        .finally(() => {
                            setIsGeneratePdfFile(false);
                        });
                } else {
                    uploadPdf({ invoiceId: invoiceNumber, body: formData })
                        .unwrap()
                        .then(() => {
                            successToast(t('Pdf invoice generate and upload successfully'));
                            setIsSuccessUploadPdf(true);
                        })
                        .catch(() => {
                            errorToast(t('Pdf invoice generate and upload error, refresh page or try later'));
                        })
                        .finally(() => {
                            setIsGeneratePdfFile(false);
                        });
                }
            });
        }
    }, [fileLoaded]);

    const downloadApiPdf = () => {
        if (typeInvoiceByToken) {
            downloadPdfInvoiceByToken(
                token,
                `invoice_${new Date().toISOString()}`,
                () => {
                    errorToast(t('Error download pdf invoice'));
                },
                setIsGeneratePdfFile
            );
        } else {
            downloadPdfInvoice(
                invoiceNumber,
                `invoice_${invoiceNumber}`,
                () => {
                    errorToast(t('Error download pdf invoice'));
                },
                setIsGeneratePdfFile
            );
        }
    };

    return (
        <div className="w-[888px] mt-[65px] mb-[145px] mx-auto">
            <div className="bg-int-gray-main rounded-t-[20px] h-[20px] border-int-gray-secondary" />
            <div ref={refPdf}>
                <div className="bg-int-gray-main px-[40px] pb-[30px] pt-[30px] border-b-[1px] border-int-gray-secondary">
                    <div className="flex justify-between">
                        <LogoIcon width={187} height={16} />
                        <div className="text-right">
                            <div className="text-[10px] leading-[12px] font-Lexend400 text-int-white-secondary">
                                {t('Invoice No.')}
                            </div>
                            <div className="text-[13px] leading-[16px] font-Lexend700 text-int-white-main">
                                #{invoiceNumber}
                            </div>
                        </div>
                    </div>

                    <InvoiceSupplierAndCustomerInfo
                        supplierCompanyName={supplierCompanyName}
                        supplierCompanyAddress={supplierCompanyAddress}
                        createdDate={createdDate}
                        customerCompanyName={customerCompanyName}
                        customerCompanyAddress={customerCompanyAddress}
                        subjectMatter={subjectMatter}
                    />
                    <InvoiceModuleTableInfo
                        modules={modules}
                        subscriptionAmount={subscriptionAmount}
                        tax={tax}
                        totalAmount={totalAmount}
                    />
                </div>
                <div className="bg-int-gray-main px-[40px] pt-[30px] pb-[40px]">
                    <InvoiceFooterInfo
                        wahlerCompanyEmail={wahlerCompanyEmail}
                        supplierCompanyName={supplierCompanyName}
                        supplierCompanyAddress={supplierCompanyAddress}
                        supplierBankInformation={supplierBankInformation}
                    />
                </div>
            </div>

            <div className="flex justify-end bg-int-gray-main rounded-b-[20px] px-[40px] pb-[40px]">
                <PrimaryButton
                    isPink
                    className="h-[40px] px-[32px]"
                    handleClick={downloadApiPdf}
                    disabled={!isSuccessUploadPdf}
                >
                    <div className="text-[16px] leading-[16px] font-Lexend600">{t('Download Invoice')}</div>
                </PrimaryButton>
            </div>
            <InvoiceDetailsInfo />
            <LoadingAndTextOverLayer isOpen={isGeneratePdfFile} text={t('Process download invoice')} />
        </div>
    );
};

export default Invoice;

Invoice.propTypes = {
    typeInvoiceByToken: bool.isRequired,
    token: string,
    fileLoaded: bool.isRequired,
    invoiceNumber: number,
    supplierCompanyName: string,
    supplierCompanyAddress: string,
    createdDate: string,
    customerCompanyName: string,
    customerCompanyAddress: string,
    subjectMatter: string,
    modules: arrayOf(
        shape({
            description: string,
            subscriptionPlan: number,
        })
    ),
    subscriptionAmount: number,
    tax: number,
    totalAmount: number,
    wahlerCompanyEmail: string,
    supplierBankInformation: shape({
        accountNumber: number,
        bankAddress: string,
        beneficiaryBank: string,
        currency: string,
        swiftBicCode: string,
    }),
};
