import { useCallback, useEffect, useState } from 'react';
import { FloatingFocusManager, FloatingPortal } from '@floating-ui/react';
import { arrayOf, bool, func, number, oneOfType, shape, string } from 'prop-types';
import { ReactComponent as ArrowBottomIcon } from '../../assets/icons/bottom-arrow.svg';
import { ReactComponent as ArrowUpIcon } from '../../assets/icons/up-arrow.svg';
import { ReactComponent as ErrorIcon } from '../../assets/icons/errorIcon.svg';
import { useChangeDirectionDropdown } from '../../hooks/useChangeDirectionDropdown';

export default function SearchDropDownNew(props) {
    const {
        options,
        placeholder,
        handleChange,
        handleBlur,
        name,
        isError,
        errorText,
        label,
        className,
        value,
        isSearch = true,
        handleFocus,
        maxHeightDropDown = 360,
        isDisabled = false,
    } = props;
    const [isOpen, setIsOpen] = useState(false);
    const [selected, setSelected] = useState(value || '');
    const [isOpenHelpErrorInfo, setIsOpenHelpErrorInfo] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [optionsArr, setOptionsArr] = useState(options || []);

    const { getReferenceProps, getFloatingProps, getItemProps, refs, context, floatingStyles, onInputClick } =
        useChangeDirectionDropdown({
            isOpen,
            setIsOpen,
            maxHeightDropDown,
        });

    const onFocus = () => {
        if (handleFocus) {
            handleFocus();
        }
    };

    const handleClick = ({ label, value }) => {
        if (handleChange) {
            handleChange({ label, value });
        }
        setSelected(label);
        setSearchText('');

        setIsOpen(false);
    };

    const onSearch = useCallback(
        (e) => {
            setSearchText(e.target.value);
            setSelected('');
            if (handleChange) {
                handleChange({ label: '', value: '' });
            }
        },
        [handleChange]
    );

    useEffect(() => {
        setOptionsArr(options);
    }, [options]);

    useEffect(() => {
        const arr = options?.filter((o) => o.label.toLowerCase().includes(searchText.toLowerCase()));

        setOptionsArr(arr);
    }, [searchText, errorText, isError, options]);

    const onBlur = (e) => {
        if (!isOpen) {
            handleBlur(e);
        }
    };

    return (
        <div className={`relative ${isDisabled && 'opacity-[0.6] pointer-events-none'} ${className || ''}`}>
            <div
                className={`absolute left-0 top-[-20px] ${
                    isError ? 'text-error' : 'text-int-white-main'
                } text-[14px] leading-[16px] font-Lexend400`}
            >
                {label}
            </div>
            <input
                {...getReferenceProps({
                    ref: refs.setReference,
                })}
                role="presentation"
                autoComplete="off"
                name={name}
                type="text"
                className={`${className} min-w-[160px] border ${!isError && isOpen && 'border-int-white-main'}
                flex items-center bg-[#2A3143] py-[11px] appearance-none rounded-[8px] 
                text-int-grey-40 pl-[24px] pr-[35px]`}
                style={{
                    border: `${isError ? '1px solid #FF5F5F' : ''}`,
                    caretColor: `${!isSearch && 'transparent'}`,
                    cursor: `${!isSearch && 'pointer'}`,
                }}
                value={searchText || selected}
                placeholder={placeholder}
                onChange={isSearch ? onSearch : () => {}}
                onBlur={onBlur}
                onFocus={onFocus}
                onClick={onInputClick}
            />
            <div className="pointer-events-none absolute right-[15px] top-[18px]">
                {isOpen ? <ArrowUpIcon fill="#EDF1FD" /> : <ArrowBottomIcon fill="#EDF1FD" />}
            </div>
            {isError && (
                <div className="absolute right-[35px] top-[12px]">
                    <div
                        className={errorText && 'cursor-pointer'}
                        onMouseEnter={() => setIsOpenHelpErrorInfo(true)}
                        onMouseLeave={() => setIsOpenHelpErrorInfo(false)}
                    >
                        <ErrorIcon />
                    </div>
                    {isOpenHelpErrorInfo && errorText && (
                        <div
                            className="text-[12px] text-error font-Lexend300 absolute
                     bg-[#222730] p-[10px] rounded-[2px] z-10 bottom-[23px] right-0 whitespace-nowrap"
                        >
                            {errorText}
                        </div>
                    )}
                </div>
            )}

            {isOpen && (
                <FloatingPortal>
                    <FloatingFocusManager context={context} modal={false}>
                        <ul
                            ref={refs.setFloating}
                            {...getFloatingProps()}
                            id="primary-scroll"
                            className="bg-[#2A3143] left-0 text-int-grey-40 rounded z-50 overflow-auto shadow-dropdown"
                            style={{ ...floatingStyles, outline: 'none' }}
                        >
                            {optionsArr &&
                                optionsArr.map(({ value, label, icon }, index) => (
                                    <li
                                        key={index}
                                        className="hover:bg-[#354053] py-[8px] px-[13px] cursor-pointer flex items-center text-int-white-main font-Lexend400"
                                        {...getItemProps({
                                            onClick() {
                                                handleClick({ value, label });
                                            },
                                        })}
                                    >
                                        {label}
                                    </li>
                                ))}
                        </ul>
                    </FloatingFocusManager>
                </FloatingPortal>
            )}
        </div>
    );
}

SearchDropDownNew.propTypes = {
    options: arrayOf(
        shape({
            value: oneOfType([string, number]).isRequired,
            label: string.isRequired,
            icon: string,
        })
    ).isRequired,
    label: string,
    placeholder: string,
    handleChange: func,
    name: string,
    isError: bool,
    isSearch: bool,
    errorText: string,
    className: string,
    handleBlur: func,
    handleFocus: func,
    value: oneOfType([string, number]),
    maxHeightDropDown: number,
    isDisabled: bool,
};
