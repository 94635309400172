import React, { useMemo } from 'react';
import { arrayOf, bool, func, number, object, shape, string } from 'prop-types';
import { FieldArray } from 'formik';
import TableHeader from '../../../../style/Table/TableHeader';
import AsIsApplicationEditTableRow from './AsIsApplicationEditTableRow';
import AddRowButton from './AddRowButton';
import { getEmptyFormDataItem } from '../constants';
import DeleteRowButton from './DeleteRowButton';

const AsIsApplicationEditTable = (props) => {
    const { tableHeaderData, values, errors, touched, handleChange, handleBlur, setFieldValue, options } = props;

    const modulesOptions = useMemo(
        () => options?.modules?.map((o) => ({ label: o.moduleName, value: o.moduleId })),
        [options]
    );

    const languagesOptions = useMemo(() => options?.languages?.map((o) => ({ label: o, value: o })), [options]);

    return (
        <FieldArray
            name="applicationsInfo"
            render={(ArrayHelpers) => (
                <div className="flex">
                    <div>
                        <div id="bold-scroll" className="max-w-[1450px] overflow-x-auto">
                            <table className="table-fixed">
                                <TableHeader data={tableHeaderData} />
                                <tbody>
                                    {values?.applicationsInfo?.map((application, index) => {
                                        const applicationsInfoErrors =
                                            (errors.applicationsInfo?.length && errors.applicationsInfo[index]) || {};
                                        const applicationsInfoTouched =
                                            (touched.applicationsInfo?.length && touched.applicationsInfo[index]) || {};

                                        return (
                                            <AsIsApplicationEditTableRow
                                                values={values}
                                                handleChange={handleChange}
                                                handleBlur={handleBlur}
                                                applicationsInfoTouched={applicationsInfoTouched}
                                                applicationsInfoErrors={applicationsInfoErrors}
                                                ArrayHelpers={ArrayHelpers}
                                                key={application?.key}
                                                index={index}
                                                setFieldValue={setFieldValue}
                                                modulesOptions={modulesOptions || []}
                                                buildingBlocks={options?.buildingBlocks || []}
                                                languagesOptions={languagesOptions || []}
                                            />
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                        <AddRowButton ArrayHelpers={ArrayHelpers} pushData={getEmptyFormDataItem()} values={values} />
                    </div>
                    <div className="mt-[65px]">
                        {values?.applicationsInfo?.map((item, index) => (
                            <DeleteRowButton ArrayHelpers={ArrayHelpers} index={index} key={index} />
                        ))}
                    </div>
                </div>
            )}
        />
    );
};

export default AsIsApplicationEditTable;

AsIsApplicationEditTable.propTypes = {
    tableHeaderData: arrayOf(
        shape({
            minWidth: number,
            name: string,
            isSortable: bool,
            queryName: string,
        })
    ),
    values: object.isRequired,
    errors: object.isRequired,
    touched: object.isRequired,
    handleChange: func.isRequired,
    handleBlur: func.isRequired,
    setFieldValue: func.isRequired,
    options: object,
};
