import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { HEADERS } from '../../components/util/constants';

export const toBeApplicationAPI = createApi({
    reducerPath: 'toBeApplicationAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api',
        prepareHeaders: HEADERS,
    }),
    tagTypes: ['toBeApplication'],
    endpoints: (build) => ({
        getToBeApplications: build.query({
            query: ({ projectId, sortBy, orderBy }) =>
                `/projects/${projectId}/to-be-applications?${sortBy ? `sort=${sortBy}` : ''}${
                    sortBy && orderBy ? `,${orderBy || 'DESC'}` : ''
                }`,
            keepUnusedDataFor: 0.0001,
            providesTags: (result) =>
                result?.content
                    ? [
                          ...result.content.map(({ id }) => ({ type: 'toBeApplication', id })),
                          { type: 'toBeApplication', id: 'LIST' },
                      ]
                    : [{ type: 'toBeApplication', id: 'LIST' }],
        }),
        createToBeApplications: build.mutation({
            query: ({ projectId, body }) => ({
                url: `/projects/${projectId}/to-be-applications`,
                method: 'POST',
                body,
            }),
            invalidatesTags: [{ type: 'toBeApplication', id: 'LIST' }],
        }),

        getBuildingBlocksCompanyOffices: build.query({
            query: (projectId) => `/projects/${projectId}/to-be-applications/options`,
        }),
    }),
});

export const {
    useGetToBeApplicationsQuery,
    useCreateToBeApplicationsMutation,
    useGetBuildingBlocksCompanyOfficesQuery,
} = toBeApplicationAPI;
