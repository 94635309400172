import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { HEADERS, PAGINATION_PAGE_SIZE } from '../../components/util/constants';

export const userManagementAPI = createApi({
    reducerPath: 'userManagementAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api',
        prepareHeaders: HEADERS,
    }),
    tagTypes: ['usersManagementList'],
    endpoints: (build) => ({
        userManagementList: build.query({
            query: ({ page = 0, sortBy, order }) =>
                `/admin/user-management?size=${PAGINATION_PAGE_SIZE}&sort=${sortBy || 'id'},${
                    sortBy === 'activated' ? `${order === 'DESC' ? 'ASC' : 'DESC'}` : `${order || 'DESC'}`
                }&page=${page}`,
            providesTags: (result) =>
                result
                    ? [
                          ...result.content.map(({ id }) => ({ type: 'usersManagementList', id })),
                          { type: 'usersManagementList', id: 'LIST' },
                      ]
                    : [{ type: 'usersManagementList', id: 'LIST' }],
        }),
        createListOfUser: build.mutation({
            query: (body) => ({
                url: '/admin/users/list',
                method: 'POST',
                body,
            }),
            invalidatesTags: [{ type: 'usersManagementList', id: 'LIST' }],
        }),
        updateUser: build.mutation({
            query: ({ id, ...patch }) => ({
                url: `/admin/users/${id}`,
                method: 'PUT',
                body: patch,
            }),
            invalidatesTags: (result, error, { id }) => [{ type: 'usersManagementList', id }],
        }),
        deleteUser: build.mutation({
            query: (id) => ({
                url: `/admin/users/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, { id }) => [{ type: 'usersManagementList', id }],
        }),
    }),
});

export const { useUserManagementListQuery, useCreateListOfUserMutation, useUpdateUserMutation, useDeleteUserMutation } =
    userManagementAPI;
