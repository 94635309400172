import { array, object, string } from 'yup';
import { OrganisationStructure, REGEX } from '../../util/constants';
import { currentYear } from '../../SetupCompanies/stepValidation';

export const companyInfoValidationSchema = object().shape({
    employeeQuantityTotal: string()
        .required('Quantity of employees should be stated')
        .matches(REGEX.NUMBERS_ONLY, 'Invalid quantity value')
        .max(7, 'Invalid quantity value')
        .test('Cant be less then both group of employee', 'Invalid value', (value, context) => {
            if (!isNaN(context.parent.employeeQuantityBc) && !isNaN(context.parent.employeeQuantityWc)) {
                return value >= Number(context.parent.employeeQuantityBc) + Number(context.parent.employeeQuantityWc);
            }
            return true;
        }),
    yearFounded: string()
        .required('Year should be stated')
        .test('Is positive?', 'Invalid year value', (value) => Number(value) > 0)
        .test('Is 4 symbols', 'Invalid year value', (value) => value?.length === 4)
        .test('Is allowed year', 'Invalid year value', (value) => Number(value) <= currentYear),
    employeeQuantityWc: string()
        .required('Quantity of white-collar employees should be stated')
        .max(7, 'Invalid quantity value')
        .test('Is positive?', 'Invalid quantity value', (value) => Number(value) > 0),
    employeeQuantityWcFt: string()
        .required('Quantity of workers should be stated')
        .max(7, 'Invalid quantity value')
        .test('Is positive?', 'Invalid value', (value) => Number(value) > 0),
    employeeQuantityBc: string()
        .max(7, 'Invalid quantity value')
        .required('Quantity of blue-collar employees should be stated')
        .test('Is positive?', 'Invalid quantity value', (value) => Number(value) > 0),
    employeeQuantityWcPt: string()
        .max(7, 'Invalid quantity value')
        .required('Quantity of workers should be stated')
        .test('Is positive?', 'Invalid value', (value) => Number(value) > 0),
    employeeQuantityBcFt: string()
        .max(7, 'Invalid quantity value')
        .required('Quantity of workers should be stated')
        .test('Is positive?', 'Invalid value', (value) => Number(value) > 0),
    employeeQuantityBcPt: string()
        .max(7, 'Invalid quantity value')
        .required('Quantity of workers should be stated')
        .test('Is positive?', 'Invalid value', (value) => Number(value) > 0),
    employeeQuantityTotalFt: string()
        .required('FTE amount should be stated')
        .matches(REGEX.DECIMAL, 'Invalid value')
        .test('Cant be zero only', 'Invalid value', (value) => value !== '0'),
    hrFteAmount: string()
        .required('FTE amount should be stated')
        .matches(REGEX.DECIMAL, 'Invalid value')
        .test('Cant be zero only', 'Invalid value', (value) => value !== '0'),
    temporaryExternalWorkers: string()
        .required('Number should be stated')
        .test('Is positive?', 'Invalid value', (value) => Number(value) > 0),
    hrQuantity: string()
        .required('Quantity should be stated')
        .test('Is positive?', 'Invalid value', (value) => Number(value) > 0),
    languages: array().min(1, 'At least one language should be selected'),
    organizationStructure: string().required('Please, select an option'),
    headOfficeOrientation: string().required('Please, select an option'),
    regionalOrientation: string().required('Please, select an option'),
    nationalOrientation: string().required('Please, select an option'),
    internationalOrientation: string().required('Please, select an option'),
    lineOrganizationType: string().test(
        'Required if have organizationStructure is Linienorganisation',
        'Please, select an option',
        (value, context) => {
            if (context?.parent?.organizationStructure === OrganisationStructure.LINE_ORGANIZATION && !value) {
                return false;
            }
            return true;
        }
    ),
    marketGrowthStrategy: string().test(
        'Required if have checkbox is selected',
        'Please, select an option',
        (value, context) => {
            if (context?.parent?.isGrowthStrategy && !value) {
                return false;
            }
            return true;
        }
    ),
    timingStrategy: string().test(
        'Required if have checkbox is selected',
        'Please, select an option',
        (value, context) => {
            if (context?.parent?.isTimingStrategy && !value) {
                return false;
            }
            return true;
        }
    ),
    competitiveStrategy: string().test(
        'Required if have checkbox is selected',
        'Please, select an option',
        (value, context) => {
            if (context?.parent?.isCompetitiveStrategy && !value) {
                return false;
            }
            return true;
        }
    ),
});
