import React from 'react';
import { node, string } from 'prop-types';
import DropdownItem from '../DropdownItem/DropdownItem';
import { useSort } from '../../hooks/useSort';
import { ReactComponent as SortingIcon } from '../../assets/icons/sortingIcon.svg';

const TableHeaderSortItem = (props) => {
    const { children, urlName, className, textStyles } = props;
    const { getMenuOptions, orderFromUrl, sortByFromUrl } = useSort();

    return (
        <DropdownItem
            offsetValue={15}
            className={`max-w-[220px] w-[100%] ml-[7px] pl-[5px] cursor-pointer ${className}`}
            menuOptions={getMenuOptions(urlName)}
            isIcon={false}
        >
            <div className="flex w-[100%] items-center justify-between">
                <div className={`mr-[5px] ${textStyles}`}>{children}</div>
                <div className="flex items-center gap-[6px] text-int-white-main text-[12px] leading-[12px] font-Lexend400">
                    {sortByFromUrl === urlName && <div>{orderFromUrl === 'DESC' ? 'Z-A' : 'A-Z'}</div>}
                    <SortingIcon width={16} height={17} fill="#EDF1FD" />
                </div>
            </div>
        </DropdownItem>
    );
};

export default TableHeaderSortItem;

TableHeaderSortItem.propTypes = {
    children: node.isRequired,
    urlName: string.isRequired,
    className: string,
    textStyles: string,
};
