import React, { useState } from 'react';
import { number, shape } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PrimaryButton } from '../../style/Button/Button';
import { i18PAGES } from '../../config/i18n/constants';
import { useCreateInvoiceAuthUserMutation } from '../../store/API/invoiceAPI';
import { useToast } from '../../hoc/toast/ToastProvider';

const CartCheck = (props) => {
    const { cartTotal } = props;
    const { t } = useTranslation(i18PAGES.MODULES);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const { errorToast } = useToast();

    const [createInvoice] = useCreateInvoiceAuthUserMutation();

    const onInvoiceClick = () => {
        setIsLoading(true);
        createInvoice()
            .unwrap()
            .then((res) => {
                navigate(`/user-invoice/${res}`);
            })
            .catch(() => {
                errorToast(t('Create invoice error'));
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <div className="bg-int-gray-main mt-[20px] rounded-[4px] px-[40px] pb-[26px] py-[22px]">
            <div className="flex justify-between items-center mb-[14px]">
                <div className="font-Inter400 text-[14px] leading-[22px]">{t('Sub total (UER)')}</div>
                <div className="text-[16px] leading-[24px] font-Inter500">
                    €{Number(cartTotal.subscriptionTotal).toFixed(2)}
                </div>
            </div>
            {/* TODO TEMPORARY HIDDEN */}
            {/* <div className="flex justify-between items-center mb-[14px]"> */}
            {/*     <div className="font-Inter400 text-[14px] leading-[22px]">{t('Tax')}</div> */}
            {/*     <div className="text-[16px] leading-[24px] font-Inter500">€{Number(cartTotal.tax).toFixed(2)}</div> */}
            {/* </div> */}
            <div className="flex justify-between items-center mb-[14px]">
                <div className="font-Inter400 text-[14px] leading-[22px]">{t('Total (EUR)')}</div>
                <div className="text-[16px] leading-[24px] font-Inter500">€{Number(cartTotal.total).toFixed(2)}</div>
            </div>

            <div className="mt-[16px] flex justify-between items-center">
                <div className="font-SourceSansPro400 text-[14px] leading-[17px] text-int-white-secondary">
                    {t('All charges of correspondent banks are at the Customers’s expenses.')}
                </div>
                <PrimaryButton
                    handleClick={onInvoiceClick}
                    className="w-[183px] text-center h-[40px]"
                    isPink
                    disabled={isLoading}
                >
                    {t('Create Invoice')}
                </PrimaryButton>
            </div>
        </div>
    );
};

export default CartCheck;

CartCheck.propTypes = {
    cartTotal: shape({
        subscriptionTotal: number,
        tax: number,
        total: number,
    }),
};
