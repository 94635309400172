import React from 'react';
import { func, number, object, oneOfType, string } from 'prop-types';
import OnlyViewFieldInput from '../../../Profile/OnlyViewFieldInput';
import BbStatusItem from './BbStatusItem';
import BbActionSelector from './BbActionSelector';
import Checkbox from '../../../../style/Checkbox/Checkbox';
import { BUILDING_BLOCKS_STATUSES } from '../constants';
import { useAuth } from '../../../../store/hooks/useAuth';

const BbCardContentReadMode = (props) => {
    const { data, projectId, buildingBlockId, refetchBuildingBlockInfo } = props;
    const { isEditablePagesProject } = useAuth();

    return (
        <>
            {data?.tools.map((t, index) => (
                <div
                    className={`py-[20px] flex items-center justify-start font-Lexend400 text-[16px] leading-[24px] text-int-white-main ${
                        index !== data.tools.length - 1 && 'border-b border-int-gray-secondary'
                    }`}
                    key={t.id}
                >
                    <div className="w-[260px] pr-[24px]">
                        <Checkbox
                            isShowOnly
                            label={t.name}
                            value={t.selected}
                            className="font-Lexend400 text-[16px] leading-[24px] text-int-white-main"
                            isEnabled
                        />
                    </div>
                    <div className="w-[420px]">
                        <OnlyViewFieldInput label="" value={t?.email || '-'} />
                    </div>
                    {t?.status !== BUILDING_BLOCKS_STATUSES.CREATED && (
                        <div className="flex flex-1 items-center justify-between">
                            <BbStatusItem status={t?.isRejected ? BUILDING_BLOCKS_STATUSES.REJECTED : t?.status} />
                            {isEditablePagesProject && !data.isExpired && (
                                <BbActionSelector
                                    status={t?.isRejected ? BUILDING_BLOCKS_STATUSES.REJECTED : t?.status}
                                    projectId={projectId}
                                    buildingBlockId={buildingBlockId}
                                    toolId={t.id}
                                    refetchBuildingBlockInfo={refetchBuildingBlockInfo}
                                />
                            )}
                        </div>
                    )}
                </div>
            ))}
        </>
    );
};

export default BbCardContentReadMode;

BbCardContentReadMode.propTypes = {
    data: object,
    projectId: oneOfType([string, number]),
    buildingBlockId: oneOfType([string, number]),
    refetchBuildingBlockInfo: func,
};
