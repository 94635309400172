import React, { useCallback, useMemo } from 'react';
import { bool, func, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { i18PAGES } from '../../../../config/i18n/constants';
import { DATE_FORMAT_TYPE, formatDateFromIsoString } from '../../../util/dateFormatter';
import { DEADLINE_STATUSES } from '../constants';
import { useAuth } from '../../../../store/hooks/useAuth';
import DropdownItem from '../../../../style/DropdownItem/DropdownItem';

const DeadlineButton = (props) => {
    const { t } = useTranslation(i18PAGES.OPTIONS);
    const { deadlineDate, className, setIsChangeDeadlineOpened, setChangeDeadLineStatus, isExpired } = props;
    const { isEditablePagesProject } = useAuth();

    const openDeadlineWindow = useCallback(() => {
        setChangeDeadLineStatus(DEADLINE_STATUSES.EXTEND);
        setIsChangeDeadlineOpened(true);
    }, [setChangeDeadLineStatus, setIsChangeDeadlineOpened]);

    const menuOptions = useMemo(
        () => [{ text: t('Extend deadline'), handleClick: openDeadlineWindow }],
        [openDeadlineWindow, t]
    );

    if (isEditablePagesProject && !isExpired) {
        return (
            <DropdownItem menuOptions={menuOptions} className={className} isIcon>
                <div className="mr-[10px] flex items-center text-[16px] leading-[24px] font-Lexend400">
                    <div className="text-int-white-secondary mr-[5px]">{t('Deadline')}:</div>
                    <div className="text-int-white-main">
                        {formatDateFromIsoString(deadlineDate, DATE_FORMAT_TYPE.DAY_MONTH_YEAR)}
                    </div>
                </div>
            </DropdownItem>
        );
    }
    return (
        <div className="mr-[25px] flex items-center text-[16px] leading-[24px] font-Lexend400">
            <div className="text-int-white-secondary mr-[5px]">{t('Deadline')}:</div>
            <div className="text-int-white-main">
                {formatDateFromIsoString(deadlineDate, DATE_FORMAT_TYPE.DAY_MONTH_YEAR)}
            </div>
        </div>
    );
};

export default DeadlineButton;

DeadlineButton.propTypes = {
    deadlineDate: string.isRequired,
    className: string,
    setIsChangeDeadlineOpened: func,
    setChangeDeadLineStatus: func,
    isExpired: bool,
};
