import { useTranslation } from 'react-i18next';
import { element, func, string } from 'prop-types';
import AddEntityBlock from '../CompaniesList/AddEntityBlock';

const EmptyProjectsDisplay = (props) => {
    const { buttonText, icon, description, handleClick } = props;

    const { t } = useTranslation();

    return (
        <div className="w-[1019px] m-auto">
            <div className="text-[24px] text-int-white-main font-Lexend500 mt-[30px] mb-[24px]">
                {t('List of projects')}
            </div>
            <AddEntityBlock buttonText={buttonText} description={description} icon={icon} handleClick={handleClick} />
        </div>
    );
};

export default EmptyProjectsDisplay;

EmptyProjectsDisplay.propTypes = {
    icon: element.isRequired,
    description: string.isRequired,
    buttonText: string,
    handleClick: func,
};
