import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { func, number, shape, string } from 'prop-types';
import { PrimaryButton } from '../../../style/Button/Button';
import { ReactComponent as AttachFileIcon } from '../../../assets/icons/atachFileIcon.svg';
import { ReactComponent as ErrorIcon } from '../../../assets/icons/errorIcon.svg';
import { ReactComponent as SuccessIcon } from '../../../assets/icons/success.svg';
import { i18PAGES } from '../../../config/i18n/constants';
import { allowedFormats, CONFIRMATION_STEPS } from '../constants';
import { ReactComponent as DeleteFileIcon } from '../../../assets/icons/closeModalIcon.svg';
import {
    useApproveTransactionAsUserMutation,
    useConfirmUserInvoiceWithInvoiceIdMutation,
} from '../../../store/API/invoiceAPI';
import { useToast } from '../../../hoc/toast/ToastProvider';
import i18n from '../../../config/i18n/i18n';
import LoadingAndTextOverLayer from '../../../style/LoadingAndTextOverLayer/LoadingAndTextOverLayer';

const UserConfirmationChoice = ({ setUserConfirmationStep, invoiceData, tokenKey }) => {
    const { t } = useTranslation(i18PAGES.AUTH);
    const [approveTransaction] = useApproveTransactionAsUserMutation();
    const [approveTransactionByInvoiceId] = useConfirmUserInvoiceWithInvoiceIdMutation();
    const { errorToast } = useToast();

    const [isBadFileFormat, setIsBadFileFormat] = useState(null);
    const [fileFormatError, setFileFormatError] = useState(null);
    const [isShowError, setIsShowError] = useState(false);
    const [isTransactionConfirmationLoading, setIsTransactionConfirmationLoading] = useState(false);
    const [file, setFile] = useState();

    const handleFileUpload = (event) => {
        const selectedFile = event.target.files[0];

        const fileNameArray = selectedFile?.name?.split('.');

        if (!allowedFormats.includes(fileNameArray[fileNameArray.length - 1].toLowerCase())) {
            setFileFormatError('File format is not supported');
            setIsBadFileFormat(true);
            setFile(selectedFile);
        } else if (selectedFile?.size > 10000000) {
            setFileFormatError('File size is too big');
            setIsBadFileFormat(true);
            setFile(selectedFile);
        } else {
            setFile(selectedFile);
            setIsBadFileFormat(false);
        }
    };

    const onRemoveFile = () => {
        setFile(null);
    };

    const onCancelClick = () => {
        setUserConfirmationStep(CONFIRMATION_STEPS.DECLINED_SCREEN);
    };

    const onConfirmClick = () => {
        setIsTransactionConfirmationLoading(true);
        const formData = new FormData();

        if (file) {
            formData.append('receipt', file, file?.name);
        } else {
            formData.append('receipt', null);
        }

        if (tokenKey) {
            formData.append('key', tokenKey);

            approveTransaction(formData)
                .unwrap()
                .then(() => {
                    setUserConfirmationStep(CONFIRMATION_STEPS.ACCEPTED_SCREEN);
                })
                .catch(() => {
                    errorToast(i18n.t('Something went wrong, try again later'));
                })
                .finally(() => {
                    setIsTransactionConfirmationLoading(false);
                });
        } else {
            approveTransactionByInvoiceId({ invoiceId: invoiceData.invoiceNumber, body: formData })
                .unwrap()
                .then(() => {
                    setUserConfirmationStep(CONFIRMATION_STEPS.ACCEPTED_SCREEN);
                })
                .catch(() => {
                    errorToast(i18n.t('Something went wrong, try again later'));
                })
                .finally(() => {
                    setIsTransactionConfirmationLoading(false);
                });
        }
    };

    return (
        <>
            <div className="text-int-white-main font-Lexend500 text-[24px] leading-[24px] text-center">
                {t('Confirm transaction')}
            </div>
            <div className="mt-[20px] w-[440px] text-center text-int-white-main font-Lexend300 text-[14px] leading-[22px]">
                {`${t(
                    'Please attach the receipt from your bank that confirms the fund transaction  for the platform subscription in the amount of'
                )} `}
                <div>{invoiceData.invoiceTotalAmount} EUR.</div>
            </div>
            <div className="w-[100%] text-center mt-[15px]">
                {file ? (
                    <div className="flex items-center justify-center m-auto w-[100%] text-center">
                        <div className="mr-[10px]">{file?.name}</div>
                        <div className="cursor-pointer" onClick={onRemoveFile}>
                            <DeleteFileIcon width={9} height={9} />
                        </div>
                        {isBadFileFormat === true && (
                            <div className="min-w-[20px] min-h-[20px] relative">
                                <ErrorIcon
                                    className="ml-[10px] min-w-[20px] min-h-[20px]"
                                    width={20}
                                    height={20}
                                    onMouseEnter={() => setIsShowError(true)}
                                    onMouseLeave={() => setIsShowError(false)}
                                />
                                {isShowError && (
                                    <div className="text-[12px] text-error font-Lexend300 absolute bg-[#222730] opacity-1 p-[10px] rounded-[2px] bottom-[23px] left-[-50px] whitespace-nowrap">
                                        {fileFormatError}
                                    </div>
                                )}
                            </div>
                        )}
                        {isBadFileFormat === false && (
                            <SuccessIcon className="ml-[10px] min-w-[20px] min-h-[20px]" width={20} height={20} />
                        )}
                    </div>
                ) : (
                    <label
                        onChange={handleFileUpload}
                        className="relative inline-flex items-center justify-center hover:bg-int-gray-secondary px-[5px] py-[5px] rounded-[8px] cursor-pointer text-[14px] leading-[22px] font-Lexend400"
                    >
                        <input type="file" className="hidden" />
                        <AttachFileIcon width={16} height={32} />
                        <div className="ml-[10px]">
                            {`${t('Attach a receipt in one of the following formats')}: pdf, jpeg, jpg or img`}
                        </div>
                    </label>
                )}
            </div>

            <div className="mt-[35px] flex items-center justify-between">
                <PrimaryButton className="w-[48%] h-[50px]" isGrey handleClick={onCancelClick}>
                    {t('Cancel')}
                </PrimaryButton>
                <PrimaryButton
                    disabled={file && isBadFileFormat}
                    className="w-[48%] h-[50px]"
                    isPink
                    handleClick={onConfirmClick}
                >
                    {t('Confirm')}
                </PrimaryButton>
            </div>
            <LoadingAndTextOverLayer
                isOpen={isTransactionConfirmationLoading}
                text={t('Loading transaction confirmation')}
            />
        </>
    );
};

export default UserConfirmationChoice;

UserConfirmationChoice.propTypes = {
    setUserConfirmationStep: func.isRequired,
    invoiceData: shape({
        invoiceNumber: number.isRequired,
        invoiceTotalAmount: number.isRequired,
    }).isRequired,
    tokenKey: string,
};
