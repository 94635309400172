import React from 'react';
import { bool, func, number, string } from 'prop-types';
import i18n from 'i18next';
import DeleteUserModal from '../../UserManagement/DeleteUserModal';
import { useDeleteInternalUserMutation } from '../../../store/API/projectInternalManagementAPI';
import { useToast } from '../../../hoc/toast/ToastProvider';

const InternalUserDelete = ({ isOpenDeleteUserModal, setIsOpenDeleteUserModal, id, fullName, chosenProjectId }) => {
    const [deleteInternalUser, { isLoading }] = useDeleteInternalUserMutation();
    const { successToast, errorToast } = useToast();

    const handleDeleteClick = (id) => {
        deleteInternalUser({ projectId: chosenProjectId, userId: id })
            .unwrap()
            .then((res) => {
                successToast(i18n.t('User was successfully deleted'));
                setIsOpenDeleteUserModal(false);
            })
            .catch((err) => {
                errorToast(i18n.t('The user is not available for deletion now. Try again later'));
            });
    };

    if (id && fullName) {
        return (
            <DeleteUserModal
                isLoading={isLoading}
                isOpenDeleteUserModal={isOpenDeleteUserModal}
                setIsOpenDeleteUserModal={setIsOpenDeleteUserModal}
                id={id}
                fullName={fullName}
                handleDeleteClick={handleDeleteClick}
            />
        );
    }
    return <></>;
};

export default InternalUserDelete;

InternalUserDelete.propTypes = {
    isOpenDeleteUserModal: bool.isRequired,
    setIsOpenDeleteUserModal: func.isRequired,
    id: number,
    fullName: string,
    chosenProjectId: number,
};
