import React from 'react';
import { number, shape, string } from 'prop-types';

const ToBeIntegrationsViewTableItem = ({ integrationItem, index }) => (
    <tr className="h-[60px] border-b-[1px] border-int-main-black text-left bg-int-gray-secondary">
        <td className="px-[10px] border-r-[1px] border-int-main-black text-center">{index ? index + 1 : 1}</td>
        <td className="px-[10px] border-r-[1px] border-int-main-black">
            {integrationItem?.outgoingBuildingBlock?.name || <EmptyValue />}
        </td>
        <td className="px-[10px] border-r-[1px] border-int-main-black">
            {integrationItem?.ingoingBuildingBlock?.name || <EmptyValue />}
        </td>
        <td className="px-[10px] border-r-[1px] border-int-main-black">
            {integrationItem?.outgoingApp || <EmptyValue />}
        </td>
        <td className="px-[10px] border-r-[1px] border-int-main-black">
            {integrationItem?.ingoingApp || <EmptyValue />}
        </td>
        <td className="px-[10px] border-r-[1px] border-int-main-black">
            {integrationItem?.dataType || <EmptyValue />}
        </td>
        <td className="px-[10px] border-r-[1px] border-int-main-black">
            {integrationItem?.integrationType || <EmptyValue />}
        </td>

        <td className="px-[10px] border-r-[1px] border-int-main-black">
            {integrationItem?.operationType || <EmptyValue />}
        </td>
        <td className="px-[10px] border-r-[1px] border-int-main-black">
            {integrationItem?.supportEffort || <EmptyValue />}
        </td>
        <td className="px-[10px] border-r-[1px] border-int-main-black">
            {integrationItem?.frequency || <EmptyValue />}
        </td>
        <td className="px-[10px] border-r-[1px] border-int-main-black">
            {integrationItem?.numberOfFrequency || <EmptyValue />}
        </td>
    </tr>
);

export default ToBeIntegrationsViewTableItem;
ToBeIntegrationsViewTableItem.propTypes = {
    index: number,
    integrationItem: shape({
        outgoingBuildingBlock: shape({
            id: number,
            name: string,
        }),
        ingoingBuildingBlock: shape({
            id: number,
            name: string,
        }),

        outgoingApp: string,
        ingoingApp: string,
        dataType: string,
        integrationType: string,
        operationType: string,
        supportEffort: number,
        frequency: string,
        numberOfFrequency: number,
    }),
};

const EmptyValue = () => <div className="text-int-white-secondary text-center">—</div>;
