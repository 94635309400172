import React from 'react';
import { string } from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ReactComponent as InvoicesDownloadIcon } from '../../assets/icons/invoicesDownloadIcon.svg';
import { i18PAGES } from '../../config/i18n/constants';

const ProjectPresentationsItem = ({ title, link }) => {
    const { t } = useTranslation(i18PAGES.PROJECT_LIST);

    return (
        <div className="flex justify-between items-center h-[40px] text-int-white-main">
            <div className="text-[16px] leading-[24px] font-Lexend500">{title}</div>
            <div className="flex items-center text-[14px] leading-[14px] font-Lexend400">
                <Link to={link} target="_blank" download className="flex">
                    <div className="mr-[10px]">{t('Download')}</div>
                    <div>
                        <InvoicesDownloadIcon width={15} height={15} fill="#EDF1FD" />
                    </div>
                </Link>
            </div>
        </div>
    );
};

export default ProjectPresentationsItem;

ProjectPresentationsItem.propTypes = {
    title: string.isRequired,
    link: string.isRequired,
};
