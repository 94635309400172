import { useDispatch, useSelector } from 'react-redux';
import { setPlatformEditMode } from '../reducers/platformEditModeReducer';

export const usePlatformEditMode = () => {
    const dispatch = useDispatch();

    const platformEditMode = useSelector((state) => state.isPlatformEditMode.isPlatformEditMode);

    const _setPlatformEditMode = (payload) => {
        dispatch(setPlatformEditMode(payload));
    };

    return {
        platformEditMode,
        setPlatformEditMode: _setPlatformEditMode,
    };
};
