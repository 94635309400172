import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import Checkbox from '../../../style/Checkbox/Checkbox';
import OfficeModuleItem from './OfficeModuleItem';
import OtherModulesHelper from './OtherModulesHelper';
import { ReactComponent as AddModulesIcon } from '../../../assets/icons/addProjectManagerIcon.svg';
import { PrimaryButton } from '../../../style/Button/Button';
import ModalWindow from '../../../style/ModalWindow/ModalWindow';
import { i18PAGES } from '../../../config/i18n/constants';
import { PROJECT_STATUS } from '../../util/constants';

const Step3OfficeItem = ({
    officesIndex,
    companiesIndex,
    countryIndex,
    office,
    step3values,
    setFieldValue,
    isDisabledAllInForm = false,
    allProvidersSubmitted = false,
    sentBuildingBlocks,
    projectStatus,
}) => {
    const { t } = useTranslation(i18PAGES.PROJECTS_SETUP);

    const [isModalOpen, setIsOpenModal] = useState(false);

    const officeIsChecked = useMemo(
        () => step3values.companies[companiesIndex].countries[countryIndex].offices[officesIndex].isChecked,
        [step3values]
    );

    const visibleModules = useMemo(
        () =>
            office?.officeModules
                ?.map((module) => ({
                    ...module,
                    buildingBlocks: module.buildingBlocks.filter((bb) => bb.selected === true),
                }))
                .filter((module) => module.buildingBlocks.length > 0)
                .slice(0, 2),
        [office]
    );

    const notVisibleModules = useMemo(
        () =>
            office?.officeModules
                ?.map((module) => ({
                    ...module,
                    buildingBlocks: module.buildingBlocks.filter((bb) => bb.selected === true),
                }))
                .filter((module) => module.buildingBlocks.length > 0)
                .slice(2),
        [office]
    );

    useEffect(() => {
        if (officeIsChecked === false) {
            step3values?.companies[companiesIndex]?.countries[countryIndex]?.offices[officesIndex]?.officeModules?.map(
                (module, indexModules) =>
                    module.buildingBlocks.forEach((bb, indexFeature) =>
                        setFieldValue(
                            `companies.${companiesIndex}.countries.${countryIndex}.offices.${officesIndex}.officeModules.${indexModules}.buildingBlocks.${indexFeature}.selected`,
                            false
                        )
                    )
            );
        }
    }, [officeIsChecked]);

    return (
        <div
            key={officesIndex}
            className="flex items-center w-[100%] text-[14px] leading-[18px] text-int-white-main py-[4px] border-b-[1px] border-int-gray-secondary"
        >
            <div className="max-w-[230px] w-[100%]">
                <Checkbox
                    name={`companies.${companiesIndex}.countries.${countryIndex}.offices.${officesIndex}.isChecked`}
                    id={`companies.${companiesIndex}.countries.${countryIndex}.offices.${officesIndex}.isChecked`}
                    label={office.name}
                    className="text-[14px]"
                    value={
                        step3values.companies[companiesIndex].countries[countryIndex].offices[officesIndex].isChecked
                    }
                    disabled={isDisabledAllInForm}
                />
            </div>
            <div className="max-w-[170px] w-[100%] pl-[12px]">{office.city}</div>
            <div className=" w-[100%] pl-[12px] pr-[14px]">
                <div className="flex justify-between items-center">
                    <div className="flex items-center">
                        {officeIsChecked && visibleModules?.length === 0 ? (
                            <div className="text-error">{t('Select at least 1 building block')}</div>
                        ) : (
                            visibleModules?.map((module, indexModule) => (
                                <div key={indexModule}>
                                    <OfficeModuleItem
                                        name={module.title}
                                        count={module.buildingBlocks.length}
                                        className="bg-int-gray-secondary mr-[16px]"
                                    />
                                </div>
                            ))
                        )}
                        <OtherModulesHelper notVisibleModules={notVisibleModules} />
                    </div>

                    {allProvidersSubmitted ? (
                        <AddModulesIcon fill="#354153" />
                    ) : (
                        <AddModulesIcon
                            fill={officeIsChecked ? '#9BA1B2' : '#354153'}
                            className={officeIsChecked && 'cursor-pointer'}
                            onClick={officeIsChecked ? () => setIsOpenModal(true) : null}
                        />
                    )}
                </div>
            </div>
            <ModalWindow
                isOpen={isModalOpen}
                setIsOpen={setIsOpenModal}
                className="-top-[0px] w-[620px]"
                isCloseClickOutside
            >
                <div className="flex flex-col">
                    <div className="text-[24px] leading-[24px] font-Lexend500 text-int-white-main">
                        {t('Select modules')}
                    </div>
                    <div className="text-[14px] leading-[22px] font-Lexend300 text-int-white-secondary mt-[16px] mb-[30px]">
                        {t('Please select modules that should be added in the')} “{office.name}”
                    </div>
                    <div className="text-[18px] leading-[22px] font-Lexend500 text-int-white-secondary mb-[24px]">
                        {t('Modules bought')}
                    </div>
                    <Formik
                        onSubmit={(values, { setSubmitting }) => {
                            values?.officeModules.map((module, indexModules) =>
                                module.buildingBlocks.forEach((bb, indexFeature) =>
                                    setFieldValue(
                                        `companies.${companiesIndex}.countries.${countryIndex}.offices.${officesIndex}.officeModules.${indexModules}.buildingBlocks.${indexFeature}.selected`,
                                        bb.selected
                                    )
                                )
                            );
                            setSubmitting(false);
                            setIsOpenModal(false);
                        }}
                        initialValues={{
                            officeModules:
                                step3values?.companies[companiesIndex]?.countries[countryIndex]?.offices[officesIndex]
                                    ?.officeModules,
                        }}
                        enableReinitialize
                    >
                        {({ values, handleSubmit }) => (
                            <Form onSubmit={handleSubmit}>
                                <div
                                    className="flex flex-col mb-[40px]
                                     text-[16px] leading-[16px] tracking-[1px] max-h-[495px] overflow-y-auto
                                     mr-[-16px] pr-[16px]"
                                    id="primary-scroll"
                                >
                                    {values?.officeModules?.map((officeModules, officeModulesIndex) => (
                                        <div key={officeModulesIndex} className="mb-[30px]">
                                            <div className="mb-[10px]">{officeModules.title}</div>
                                            <div className="grid grid-cols-2 gap-x-[60px]">
                                                {officeModules?.buildingBlocks?.map((bb, featuresIndex) => (
                                                    <div
                                                        className="pl-[16px] text-[14px] flex items-center"
                                                        key={featuresIndex}
                                                    >
                                                        <Checkbox
                                                            name={`officeModules.${officeModulesIndex}.buildingBlocks.${featuresIndex}.selected`}
                                                            id={`officeModules.${officeModulesIndex}.buildingBlocks.${featuresIndex}.selected`}
                                                            label={bb.name}
                                                            value={
                                                                values.officeModules[officeModulesIndex].buildingBlocks[
                                                                    featuresIndex
                                                                ].selected
                                                            }
                                                            disabled={
                                                                projectStatus === PROJECT_STATUS.SENT_TO_PROVIDERS &&
                                                                sentBuildingBlocks?.includes(bb.id)
                                                            }
                                                        />
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    ))}
                                </div>

                                <div className="w-[100%] flex justify-between">
                                    <PrimaryButton
                                        isGrey
                                        className="w-[160px] h-[42px]"
                                        handleClick={() => setIsOpenModal(false)}
                                    >
                                        <div className="text-[16px] font-Lexend400">{t('Cancel')}</div>
                                    </PrimaryButton>
                                    <PrimaryButton isPink className="w-[160px] h-[42px]" handleClick={handleSubmit}>
                                        <div className="text-[16px] font-Lexend400">{t('Add')}</div>
                                    </PrimaryButton>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </ModalWindow>
        </div>
    );
};

export default Step3OfficeItem;

Step3OfficeItem.propTypes = {
    officesIndex: PropTypes.number,
    companiesIndex: PropTypes.number,
    countryIndex: PropTypes.number,
    office: PropTypes.object,
    step3values: PropTypes.object,
    setFieldValue: PropTypes.func,
    isDisabledAllInForm: PropTypes.bool,
    allProvidersSubmitted: PropTypes.bool,
    sentBuildingBlocks: PropTypes.arrayOf(PropTypes.number),
    projectStatus: PropTypes.string,
};
