import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { array } from 'prop-types';
import { PrimaryButton } from '../../style/Button/Button';
import { ReactComponent as ProjectInfoMenuButtonArrowIcon } from '../../assets/icons/projectInfoMenuButtonArrowIcon.svg';
import { ReactComponent as MenuCloseIcon } from '../../assets/icons/menuCloseIcon.svg';
import { ReactComponent as MenuOpenIcon } from '../../assets/icons/menuOpenIcon.svg';
import ProjectInfoMenuItem from './ProjectInfoMenuItem';
import { useShowMenu } from '../../store/hooks/useShowMenu';
import { i18PAGES } from '../../config/i18n/constants';
import { useTabsMenu } from '../../store/hooks/useTabsMenu';
import { usePlatformEditMode } from '../../store/hooks/usePlatformEditMode';

const ProjectInfoMenu = ({ userPageList }) => {
    const { t } = useTranslation(i18PAGES.PLATFORM_MENU);
    const navigate = useNavigate();
    const location = useLocation();

    const [searchParams] = useSearchParams();
    const projectId = searchParams.get('projectId');
    const officeId = searchParams.get('officeId');
    const cityId = searchParams.get('cityId');
    const companyId = searchParams.get('companyId');
    const countryId = searchParams.get('countryId');
    const current = searchParams.get('current');

    const { showFullMenu, setShowFullMenu, setShowShortMenu } = useShowMenu();

    const { setTabsMenu } = useTabsMenu();

    const { platformEditMode: disableMenu } = usePlatformEditMode();

    const [height, setHeight] = useState(0);

    const backToProjectOverview = () => {
        navigate(`/projects-list?projectId=${projectId}`);
    };

    useEffect(() => {
        const resTabsMenuData = userPageList
            ?.filter((page) => page.availablePage === true)
            .map((page) => ({ ...page, data: page.data.filter((subPage) => subPage.availableSubpage === true) }))
            .map((page) => {
                if (page.dynamic === true)
                    return {
                        enumName: page.pageEnum.enumName,
                        data: page.data.map((tab) => ({
                            cityId: tab.cityId,
                            cityName: tab.cityName,
                            companyId: tab.companyId,
                            companyName: tab.companyName,
                            countryId: tab.countryId,
                            countryName: tab.countryName,
                            officeId: tab.officeId,
                            officeName: tab.officeName,
                        })),
                    };
                if (page.dynamic === false)
                    return {
                        enumName: page.pageEnum.enumName,
                        data: page.data.map((tab) => ({
                            name: tab.value,
                            enumName: tab.enumName,
                        })),
                    };
                return page;
            })
            .reduce((result, item) => ({ ...result, [item.enumName]: item.data }), {});

        setTabsMenu(resTabsMenuData);
    }, [userPageList]);

    const filteredAccessUserPageList = useMemo(
        () =>
            userPageList
                ?.filter((page) => page.availablePage === true)
                .map((page) => ({ ...page, data: page.data.filter((subPage) => subPage.availableSubpage === true) })),
        [userPageList]
    );

    useEffect(() => {
        if (filteredAccessUserPageList !== undefined) {
            const currentUrl = location.pathname.slice(1);
            const currentItemMenu = filteredAccessUserPageList?.find(
                (item) => item.pageEnum.enumName.toLowerCase() === currentUrl
            );
            if (currentItemMenu?.data?.length === 0) {
                navigate(`/${currentUrl}?projectId=${projectId}`);
            } else if (currentItemMenu?.dynamic === false) {
                navigate(
                    `/${currentUrl}?current=${
                        current || currentItemMenu?.data[0]?.enumName.toLowerCase()
                    }&projectId=${projectId}`
                );
            } else {
                const companyParam = companyId || currentItemMenu?.data[0]?.companyId;
                const countryParam = countryId || currentItemMenu?.data[0]?.countryId;
                const cityParam = cityId || currentItemMenu?.data[0]?.cityId;
                const officeParam = officeId || currentItemMenu?.data[0]?.officeId;

                navigate(
                    `/${currentUrl}?${companyParam !== undefined ? `companyId=${companyParam}&` : ''}${
                        countryParam !== undefined ? `countryId=${countryParam}&` : ''
                    }${cityParam !== undefined ? `cityId=${cityParam}&` : ''}${
                        officeParam !== undefined ? `officeId=${officeParam}&` : ''
                    }projectId=${projectId}`
                );
            }
        }
    }, [userPageList, projectId, current, companyId, countryId, cityId, officeId]);

    const handleResize = () => {
        const scrollHeight = Math.max(
            document.body.scrollHeight,
            document.documentElement.scrollHeight,
            document.body.offsetHeight,
            document.documentElement.offsetHeight,
            document.body.clientHeight,
            document.documentElement.clientHeight
        );
        setHeight(scrollHeight);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize, false);
        window.addEventListener('scroll', handleResize, false);
        window.addEventListener('click', handleResize, false);
        return () => {
            window.removeEventListener('resize', handleResize, false);
            window.removeEventListener('scroll', handleResize, false);
            window.removeEventListener('click', handleResize, false);
        };
    }, []);

    useEffect(() => {
        const render = setInterval(() => {
            handleResize();
        }, 100);
        setTimeout(() => {
            clearInterval(render);
        }, 2000);
    }, [location.pathname, location.search]);

    return (
        <div
            className={` ${
                showFullMenu ? 'w-[theme(menuWidth.open)]' : 'w-[theme(menuWidth.close)]'
            } pb-[20px] border-r border-int-gray-secondary
            h-full
            relative ${disableMenu ? 'overflow-y-hidden' : 'overflow-y-auto'}`}
            id="menu-scroll"
            style={{ maxHeight: `calc(${height}px - 186px)` }}
        >
            <div
                className={`h-[86px] px-[10px] flex items-center ${showFullMenu ? 'justify-end' : 'justify-center'}  `}
            >
                {showFullMenu ? (
                    <div
                        className="flex items-center justify-center w-[42px] h-[42px] cursor-pointer"
                        onClick={setShowShortMenu}
                    >
                        <MenuCloseIcon width={14} height={14} fill="white" />
                    </div>
                ) : (
                    <div
                        className="flex items-center justify-center w-[42px] h-[42px] cursor-pointer"
                        onClick={setShowFullMenu}
                    >
                        <MenuOpenIcon width={18} height={12} fill="white" />
                    </div>
                )}
            </div>
            <div className={`h-[1px] ${!showFullMenu && 'bg-int-gray-secondary'}  mx-[10px]`} />
            <div className="px-[10px] py-[20px]">
                <PrimaryButton isGrey handleClick={backToProjectOverview} className="h-[42px] w-[100%]">
                    <div className="flex items-center  text-[14px] font-Lexend400 text-int-white-main">
                        <ProjectInfoMenuButtonArrowIcon className={showFullMenu && 'mr-[10px]'} />
                        {showFullMenu && <div>{t('Back to project overview')}</div>}
                    </div>
                </PrimaryButton>
            </div>

            <div className={`h-[1px] ${!showFullMenu && 'bg-int-gray-secondary'}  mx-[10px] mb-[10px]`} />
            {!projectId && <div className="text-center text-[12px]">{t('Select a project')}</div>}
            {filteredAccessUserPageList?.map((item, index) => (
                <div key={index}>
                    <ProjectInfoMenuItem menuItem={item} index={index} projectId={projectId} />
                </div>
            ))}
            {disableMenu && (
                <div
                    className="bg-int-grapefruit w-[100%] h-[3000px] absolute top-[0] left-[0] z-10 cursor-not-allowed"
                    style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
                />
            )}
        </div>
    );
};

export default ProjectInfoMenu;

ProjectInfoMenu.propTypes = {
    userPageList: array,
};
