import React from 'react';
import { useTranslation } from 'react-i18next';
import HRCompareInfoAccordion from './HRCompareInfoAccordion';
import { i18PAGES } from '../../config/i18n/constants';

const ToolModuleDetailBlockAccordeon = () => {
    const { t } = useTranslation(i18PAGES.HR_TOOLS);

    return (
        <HRCompareInfoAccordion headerText="Module des Tools im Detail">
            <div className="grid grid-cols-3 gap-x-[60px] min-w-[1140px] text-[16px] font-Lexend300 leading-[24px] text-int-white-secondary">
                <div className="pl-[20px]">
                    {t(
                        'Was steckt hinter den einzelnen Modulen. Z.B. bei Recruiting: Bewerber Tracking, zu konfigurierende Karriereseite ….At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium.'
                    )}
                </div>
                <div className="pl-[20px]">{t('Was steckt hinter den einzelnen Modulen. Z.B. bei Recruiting:')}</div>
                <div className="pl-[20px]">
                    {t(
                        'Was steckt hinter den einzelnen Modulen. Z.B. bei Recruiting: Bewerber Tracking, zu konfigurierende Karriereseite …'
                    )}
                </div>
            </div>
        </HRCompareInfoAccordion>
    );
};

export default ToolModuleDetailBlockAccordeon;
