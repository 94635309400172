import React, { useCallback, useMemo, useState } from 'react';
import { array, bool, func, number, oneOfType, string } from 'prop-types';
import { stringShorter } from '../../util/stringShorter';
import StaticCompaniesViewer from './StaticCompaniesViewer';
import LeftArrow from './LeftArrow';
import RightArrow from './RightArrow';

const CompaniesListViewer = (props) => {
    const { companiesList, chooseCompany, chosenCompanyId, isTabIndex } = props;

    const pagesCount = useMemo(() => Math.ceil((companiesList?.length || 0) / 5) - 1, [companiesList?.length]);

    const [page, setPage] = useState(0);

    const pageElements = useMemo(() => {
        if (page === pagesCount && page !== 0) {
            return (companiesList?.length || 0) - 5 * page;
        }
        return 5;
    }, [companiesList?.length, page, pagesCount]);

    const mainBlockWidth = useMemo(() => {
        if (page < pagesCount && page > 0) {
            return 887;
        }
        return 887 + (page !== 0 ? 36 : 0) + (page !== pagesCount ? 36 : 0);
    }, [page, pagesCount]);

    const scrollDistance = useMemo(() => {
        if (page !== pagesCount) {
            return page * (pageElements * -186 - 5);
        }

        return (page - 1) * (5 * -186 - 5) + (pageElements * -186 - 5);
    }, [page, pageElements, pagesCount]);

    const onLeftArrowClick = () => {
        if (page !== 0) {
            setPage((prev) => prev - 1);
        }
    };
    const onRightArrowClick = () => {
        if (page !== pagesCount) {
            setPage((prev) => prev + 1);
        }
    };

    const isActiveTab = useCallback((id) => chosenCompanyId === id, [chosenCompanyId]);

    return (
        <div className="inline-flex rounded-[4px] bg-[#1A222E]">
            {companiesList?.length <= 5 ? (
                <StaticCompaniesViewer
                    companiesList={companiesList}
                    chooseCompany={chooseCompany}
                    isTabIndex={isTabIndex}
                    isActiveTab={isActiveTab}
                />
            ) : (
                <div className="m-auto items-center flex justify-between">
                    {page !== 0 && <LeftArrow onLeftArrowClick={onLeftArrowClick} />}
                    <div
                        className="overflow-hidden relative"
                        style={{
                            width: `${mainBlockWidth}px`,
                        }}
                    >
                        <div
                            className="flex items-center"
                            style={{
                                transition: 'all 1.5s ease',
                                marginLeft: `${scrollDistance}px`,
                            }}
                        >
                            {companiesList?.map((c, index) => (
                                <div
                                    className="px-[6px] h-[58px] bg-[#1A222E] flex items-center justify-center cursor-pointer"
                                    key={isTabIndex ? index : c.id}
                                    onClick={() => chooseCompany(isTabIndex ? index : c.id)}
                                >
                                    <div
                                        className={`${
                                            isActiveTab(isTabIndex ? index : c.id) && 'bg-int-gray-secondary'
                                        } rounded-[4px] flex items-center text-center capitalize justify-center font-Lexend500 leading-[26px] text-int-white-main w-[174px] min-w-[174px] h-[46px] min-h-[46px`}
                                        style={{ wordBreak: 'break-word' }}
                                    >
                                        {stringShorter(c.name, 20)}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    {page !== pagesCount && <RightArrow onRightArrowClick={onRightArrowClick} />}
                </div>
            )}
        </div>
    );
};

export default CompaniesListViewer;

CompaniesListViewer.propTypes = {
    companiesList: array,
    chooseCompany: func.isRequired,
    chosenCompanyId: oneOfType([string, number]),
    isTabIndex: bool,
};
