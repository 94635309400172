import React from 'react';
import { number, string, func, bool } from 'prop-types';
import WorkersListUserStatus from './WorkersListUserStatus';
import WorkersThreeDotsOptions from './WorkersThreeDotsOptions';
import StringShorterTooltip from '../../../style/StringShorterTooltip/StringShorterTooltip';

const WorkersListTableItem = ({
    id,
    fullName,
    email,
    roles,
    status,
    handleClickDelete,
    handleClickEdit,
    setIsResendingWorkerById,
    projectId,
    isRoleReviewer,
}) => (
    <div className="h-[50px] flex items-center text-[14px] font-Lexend400 leading-[19px] text-int-white-main border-b-[1px] border-int-gray-secondary">
        <div className="min-w-[307px] px-[20px]">
            <StringShorterTooltip string={fullName} maxLength={30} />
        </div>
        <div className="min-w-[307px] text-center">
            <StringShorterTooltip string={email} maxLength={30} />
        </div>
        <div className="min-w-[307px] text-center">
            <StringShorterTooltip string={roles} maxLength={30} />
        </div>
        <div className={isRoleReviewer ? 'w-[100%]' : 'min-w-[307px]'}>
            <WorkersListUserStatus status={status} />
        </div>
        {!isRoleReviewer && (
            <WorkersThreeDotsOptions
                id={id}
                handleClickDelete={handleClickDelete}
                handleClickEdit={handleClickEdit}
                status={status}
                setIsResendingWorkerById={setIsResendingWorkerById}
                projectId={projectId}
            />
        )}
    </div>
);

export default WorkersListTableItem;

WorkersListTableItem.propTypes = {
    id: number.isRequired,
    fullName: string.isRequired,
    email: string.isRequired,
    roles: string,
    status: string,
    handleClickDelete: func.isRequired,
    handleClickEdit: func.isRequired,
    setIsResendingWorkerById: func.isRequired,
    projectId: string.isRequired,
    isRoleReviewer: bool,
};
