import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CONFIRMATION_STEPS } from '../components/ConfirmTransaction/constants';
import OwnerConfirmationCanceled from '../components/ConfirmTransaction/OwnerConfirmation/OwnerConfirmationCanceled';
import OwnerConfirmationChoice from '../components/ConfirmTransaction/OwnerConfirmation/OwnerConfirmationChoice';
import OwnerConfirmationSuccess from '../components/ConfirmTransaction/OwnerConfirmation/OwnerConfirmationSuccess';
import { useGetWahlerInvoiceInfoQuery } from '../store/API/invoiceAPI';
import { LayoutLoader } from '../components/Preloader/Preloader';
import FailedConfirmation from '../components/Confirmation/FailedConfirmation';

const UserConfirmTransactionsPage = () => {
    const [searchParams] = useSearchParams();
    const key = searchParams.get('key');
    const { t } = useTranslation();

    const [ownerConfirmationStep, setOwnerConfirmationStep] = useState(CONFIRMATION_STEPS.CHOICE_SCREEN);
    const { data, isLoading, isError } = useGetWahlerInvoiceInfoQuery(key, {
        refetchOnMountOrArgChange: true,
        skip: !key,
    });

    if (isLoading) {
        return <LayoutLoader />;
    }

    if (isError || !key) {
        return (
            <div className="min-h-[calc(100vh-300px)] h-[100%] flex items-center justify-center">
                <FailedConfirmation
                    buttonText={t('Go to Homepage')}
                    linkPath="/"
                    text={t('Token is expired or not valid')}
                />
            </div>
        );
    }
    return (
        <div className="min-h-[calc(100vh-300px)] h-[100%] flex items-center justify-center">
            <div className="py-[100px]">
                <div className="bg-int-gray-main m-auto w-[500px] p-[30px] relative rounded-[20px]">
                    {ownerConfirmationStep === CONFIRMATION_STEPS.CHOICE_SCREEN && (
                        <OwnerConfirmationChoice
                            tokenKey={key}
                            invoiceData={data}
                            setOwnerConfirmationStep={setOwnerConfirmationStep}
                        />
                    )}
                    {ownerConfirmationStep === CONFIRMATION_STEPS.DECLINED_SCREEN && <OwnerConfirmationCanceled />}
                    {ownerConfirmationStep === CONFIRMATION_STEPS.ACCEPTED_SCREEN && <OwnerConfirmationSuccess />}
                </div>
            </div>
        </div>
    );
};

export default UserConfirmTransactionsPage;
