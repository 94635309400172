import React, { useState } from 'react';
import { FieldArray, Form, Formik } from 'formik';
import { array, object, string } from 'yup';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ReactComponent as AddUserIcon } from '../../assets/icons/addIcon.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/userManagementBigDeleteIcon.svg';
import CloseWarning from './CloseWarning';
import { PrimaryButton } from '../../style/Button/Button';
import ModalWindow from '../../style/ModalWindow/ModalWindow';
import FInput from '../../style/Input/Input';
import Checkbox from '../../style/Checkbox/Checkbox';
import { REGEX } from '../util/constants';
import { useCreateListOfUserMutation } from '../../store/API/userManagementAPI';
import { i18PAGES } from '../../config/i18n/constants';
import { errorEmailIsUsedStringToArrayEmails } from '../util/errorEmailIsUsedStringToArrayEmails';
import { useToast } from '../../hoc/toast/ToastProvider';
import i18n from '../../config/i18n/i18n';

const validationSchema = object().shape({
    userlist: array().of(
        object().shape({
            fullName: string()
                .required('Full name should be stated')
                .matches(REGEX.STRING_ONLY, 'Invalid full name')
                .max(50, 'Max length is 50 symbols'),
            email: string().required('Email should be stated').email('Invalid email'),
        })
    ),
});

const AddUsersModalForm = ({ isAddUserModalOpened, setIsAddUserModalOpened }) => {
    const { t } = useTranslation(i18PAGES.USER_MANAGEMENT);
    const { successToast, errorToast } = useToast();

    const [isShowCloseWarning, setIsShowCloseWarning] = useState(false);
    const [createListOfUser] = useCreateListOfUserMutation();

    const handleClickCancelModal = () => {
        if (isShowCloseWarning === false) {
            setIsShowCloseWarning(true);
        } else {
            setIsAddUserModalOpened(false);
            setIsShowCloseWarning(false);
        }
    };

    return (
        <ModalWindow
            isOpen={isAddUserModalOpened}
            setIsOpen={setIsAddUserModalOpened}
            className="-top-[0px] w-[800px]"
            handleClickClose={handleClickCancelModal}
        >
            <div className="flex flex-col items-center">
                <div className="text-[24px] leading-[24px] font-Lexend500 text-int-white-main">{t('Add user')}</div>
                <Formik
                    onSubmit={(values, { setSubmitting, setFieldError }) => {
                        createListOfUser(values)
                            .unwrap()
                            .then((res) => {
                                successToast(i18n.t('User was successfully added'));
                                setIsAddUserModalOpened(false);
                            })
                            .catch((err) => {
                                if (err.data.message.includes('Emails are already used:')) {
                                    errorToast(i18n.t('This user name is already registered on the platform.'));
                                    const errorArray = errorEmailIsUsedStringToArrayEmails(err.data.message);
                                    values.userlist
                                        .map((user, index) => ({ index, ...user }))
                                        .filter((user) => errorArray.includes(user.email))
                                        .forEach((user) => {
                                            setFieldError(
                                                `userlist.${user.index}.email`,
                                                'Email address already in use'
                                            );
                                        });
                                } else if (err.data.message.includes('Emails are not unique in the request:')) {
                                    errorToast(i18n.t('Emails are not unique in the request.'));
                                    const errorArray = errorEmailIsUsedStringToArrayEmails(err.data.message);
                                    values.userlist
                                        .map((user, index) => ({ index, ...user }))
                                        .filter((user) => errorArray.includes(user.email))
                                        .forEach((user) => {
                                            setFieldError(
                                                `userlist.${user.index}.email`,
                                                'Emails are not unique in the request'
                                            );
                                        });
                                } else {
                                    errorToast(i18n.t('Something went wrong, try again later'));
                                }
                            })
                            .finally(() => {
                                setSubmitting(false);
                            });
                    }}
                    validationSchema={validationSchema}
                    initialValues={{
                        userlist: [
                            {
                                fullName: '',
                                email: '',
                                isAdmin: false,
                            },
                        ],
                    }}
                >
                    {({
                        values,
                        touched,
                        errors,
                        handleSubmit,
                        handleBlur,
                        handleChange,
                        isSubmitting,
                        setFieldValue,
                    }) => (
                        <Form className="w-[100%] mt-[40px]">
                            <FieldArray
                                name="userlist"
                                render={(ArrayHelpers) => (
                                    <>
                                        <div
                                            className="overflow-y-auto max-h-[500px] mr-[-20px] pr-[20px]"
                                            id="primary-scroll"
                                        >
                                            {values.userlist.map((item, index) => {
                                                const usersErrors =
                                                    (errors.userlist?.length && errors.userlist[index]) || {};
                                                const usersTouched =
                                                    (touched.userlist?.length && touched.userlist[index]) || {};
                                                return (
                                                    <div className="mb-[40px]" key={index}>
                                                        <div className="flex justify-between items-center">
                                                            <FInput
                                                                name={`userlist.${index}.fullName`}
                                                                placeholder="Full name"
                                                                value={values.userlist[index].fullName}
                                                                handleBlur={handleBlur}
                                                                handleChange={handleChange}
                                                                className="w-[340px] mt-[22px]"
                                                                type="text"
                                                                label="Full name"
                                                                isError={
                                                                    !!(usersTouched.fullName && usersErrors.fullName)
                                                                }
                                                                errorText={usersErrors.fullName}
                                                                setFieldValue={setFieldValue}
                                                                limit={50}
                                                            />

                                                            <FInput
                                                                name={`userlist.${index}.email`}
                                                                placeholder="beispiel@email.com "
                                                                value={values.userlist[index].email}
                                                                handleBlur={handleBlur}
                                                                handleChange={handleChange}
                                                                className="w-[340px] mt-[22px]"
                                                                type="text"
                                                                label="Email Adresse"
                                                                isError={!!(usersTouched.email && usersErrors.email)}
                                                                errorText={usersErrors.email}
                                                                setFieldValue={setFieldValue}
                                                            />
                                                            {index > 0 ? (
                                                                <div
                                                                    className="flex justify-center items-center w-[20px] mt-[22px] h-[42px] mr-[12px] cursor-pointer"
                                                                    onClick={() => ArrayHelpers.remove(index)}
                                                                >
                                                                    <DeleteIcon />
                                                                </div>
                                                            ) : (
                                                                <div className="w-[20px] mt-[22px] h-[42px]" />
                                                            )}
                                                        </div>
                                                        <div className="mt-[22px]">
                                                            <Checkbox
                                                                name={`userlist.${index}.isAdmin`}
                                                                id={`userlist.${index}.isAdmin`}
                                                                label="Admin rights permission"
                                                                className="text-[14px]"
                                                                value={values.userlist[index].isAdmin}
                                                            />
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>

                                        <div className="w-[100%] h-[1px] bg-int-gray-secondary mt-[10px]" />
                                        <div className="w-[100%] flex justify-start pt-[31px] pb-[41px] relative">
                                            {values.userlist.length < 15 ? (
                                                <div
                                                    className="flex items-center cursor-pointer"
                                                    onClick={() =>
                                                        ArrayHelpers.push({
                                                            fullName: '',
                                                            email: '',
                                                            isAdmin: false,
                                                        })
                                                    }
                                                >
                                                    <div className="mr-[6px]">
                                                        <AddUserIcon width={16} height={16} fill="#9BA1B2" />
                                                    </div>
                                                    <div>{t('Add user')}</div>
                                                </div>
                                            ) : (
                                                <div className="h-[24px]" />
                                            )}

                                            {isShowCloseWarning && (
                                                <CloseWarning handleCloseClick={() => setIsShowCloseWarning(false)} />
                                            )}
                                        </div>
                                    </>
                                )}
                            />
                            <div className="w-[100%] flex justify-between">
                                <PrimaryButton
                                    isGrey
                                    className="w-[170px] h-[50px]"
                                    handleClick={handleClickCancelModal}
                                >
                                    <div className="text-[18px] font-Lexend400">{t('Cancel')}</div>
                                </PrimaryButton>
                                <PrimaryButton
                                    isPink
                                    className="w-[170px] h-[50px]"
                                    handleClick={handleSubmit}
                                    disabled={isSubmitting}
                                >
                                    <div className="text-[18px] font-Lexend400">{t('Add')}</div>
                                </PrimaryButton>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </ModalWindow>
    );
};

AddUsersModalForm.propTypes = {
    isAddUserModalOpened: PropTypes.bool.isRequired,
    setIsAddUserModalOpened: PropTypes.func.isRequired,
};

export default AddUsersModalForm;
