import React, { useState } from 'react';
import { func, number, object } from 'prop-types';
import { ReactComponent as ArrowBottomIcon } from '../../../../assets/icons/bottom-arrow.svg';
import { ReactComponent as ArrowUpIcon } from '../../../../assets/icons/up-arrow.svg';
import Checkbox from '../../../../style/Checkbox/Checkbox';

const TableCompaniesOfficesCheckboxDropdownItem = ({ companyIndex, company, values, index, setFieldValue }) => {
    const [isOpenChildren, setIsOpenChildren] = useState(true);

    const handleClickMainCheckbox = () => {
        if (values.toBeApplicationsList[index].companyOffices[companyIndex].isChosen === false) {
            for (let i = 0; i < company.officeList.length; i += 1) {
                setFieldValue(
                    `toBeApplicationsList.${index}.companyOffices.${companyIndex}.officeList.${i}.isChosen`,
                    true
                );
            }
        } else {
            for (let i = 0; i < company.officeList.length; i += 1) {
                setFieldValue(
                    `toBeApplicationsList.${index}.companyOffices.${companyIndex}.officeList.${i}.isChosen`,
                    false
                );
            }
        }
    };

    return (
        <div key={companyIndex}>
            <div className="flex items-center mb-[10px]">
                <div
                    className="w-[18px] h-[18px] mr-[12px] flex justify-center items-center cursor-pointer"
                    onClick={() => setIsOpenChildren((prev) => !prev)}
                >
                    {isOpenChildren ? <ArrowBottomIcon fill="#9BA1B2" /> : <ArrowUpIcon fill="#9BA1B2" />}
                </div>
                <Checkbox
                    label={company.companyName}
                    name={`toBeApplicationsList.${index}.companyOffices.${companyIndex}.isChosen`}
                    id={`toBeApplicationsList.${index}.companyOffices.${companyIndex}.isChosen`}
                    value={values.toBeApplicationsList[index].companyOffices[companyIndex].isChosen}
                    short
                    handleClick={handleClickMainCheckbox}
                />
            </div>
            {isOpenChildren && (
                <div className="pl-[60px]">
                    {company.officeList.map((office, officeIndex) => (
                        <div className="mb-[6px]" key={officeIndex}>
                            <Checkbox
                                name={`toBeApplicationsList.${index}.companyOffices.${companyIndex}.officeList.${officeIndex}.isChosen`}
                                id={`toBeApplicationsList.${index}.companyOffices.${companyIndex}.officeList.${officeIndex}.isChosen`}
                                value={
                                    values.toBeApplicationsList[index].companyOffices[companyIndex].officeList[
                                        officeIndex
                                    ].isChosen
                                }
                                label={`${office.countryName}, ${office.cityName}, ${office.officeName}`}
                                short
                            />
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default TableCompaniesOfficesCheckboxDropdownItem;

TableCompaniesOfficesCheckboxDropdownItem.propTypes = {
    companyIndex: number.isRequired,
    company: object,
    values: object.isRequired,
    index: number.isRequired,
    setFieldValue: func.isRequired,
};
