import React from 'react';
import { arrayOf, shape, string } from 'prop-types';
import ActionsTableLabels from '../ActionsTableLabels';
import ActionsReadItem from './ActionsReadItem';
import { weaknessExpectationsEmptyObject } from '../../Common/constants';

const ActionsReadMode = ({ actions }) => (
    <div className="bg-int-gray-main p-[28px] pr-[56px] rounded-[8px]">
        <ActionsTableLabels />
        {actions?.length > 0 && actions?.map((a, index) => <ActionsReadItem key={index} action={a} />)}
        {actions?.length === 0 && <ActionsReadItem action={weaknessExpectationsEmptyObject} />}
    </div>
);

export default ActionsReadMode;

ActionsReadMode.propTypes = {
    actions: arrayOf(
        shape({
            action: string.isRequired,
            description: string.isRequired,
        }).isRequired
    ),
};
