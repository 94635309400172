import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { func } from 'prop-types';
import { i18PAGES } from '../../config/i18n/constants';

const SendAgainBlock = memo((props) => {
    const { resendLink } = props;
    const { t } = useTranslation(i18PAGES.AUTH);
    return (
        <div className="my-[32px] font-Lexend400 flex justify-center">
            <div className="text-int-white-main">{t('E-Mail nicht erhalten?')}</div>
            <div className="text-[#5679F6] ml-[12px] cursor-pointer" onClick={resendLink}>
                {t('Senden Sie eine E-Mail erneut')}
            </div>
        </div>
    );
});

export default SendAgainBlock;

SendAgainBlock.propTypes = {
    resendLink: func.isRequired,
};
