import React from 'react';
import { useTranslation } from 'react-i18next';
import { func, bool, arrayOf, object, number } from 'prop-types';
import { ReactComponent as FilterListIcon } from '../../../../../assets/icons/filterListIcon.svg';
import FTEFilterCheckboxDropdown from './FTEFilterCheckboxDropdown';
import { ReactComponent as SortListIcon } from '../../../../../assets/icons/sortListIcon.svg';
import FTEFilterRadioDropdown from './FTEFilterRadioDropdown';
import { i18PAGES } from '../../../../../config/i18n/constants';
import { PrimaryButton } from '../../../../../style/Button/Button';

const FteGraphViewFilter = ({
    clearFilters,
    isResetForm,
    setIsResetForm,
    filterDataCompanies,
    setFilterDataCompanies,
    filterDataCountries,
    setFilterDataCountries,
    setFilterDataCities,
    filterDataCities,
    setFilterDataOffices,
    filterDataOffices,
    setSortData,
    sumLength,
    filtersOptionsData,
}) => {
    const { t } = useTranslation(i18PAGES.FTE_PROCESS);

    return (
        <div className="flex mb-[20px] h-[38px] items-center justify-between text-[16px] leading-[26px] font-Lexend400">
            <div className="flex items-center">
                <div className="flex items-center mr-[10px]">
                    <FilterListIcon width={14} height={10} fill="#EDF1FD" />
                    <div className="ml-[6px]">{t('Filter')}:</div>
                </div>
                <PrimaryButton
                    isGrey
                    className="h-[38px] w-[80px]"
                    handleClick={clearFilters}
                    disabled={sumLength === 0}
                >
                    <div className="text-[14px] leading-[24px] font-Lexend400">{t('All')}</div>
                </PrimaryButton>
                <FTEFilterCheckboxDropdown
                    className="w-[180px] ml-[10px]"
                    name="Company"
                    optionsList={filtersOptionsData?.companies}
                    isResetForm={isResetForm}
                    setIsResetForm={setIsResetForm}
                    data={filterDataCompanies}
                    setData={setFilterDataCompanies}
                    placeholder="Companies"
                />
                <FTEFilterCheckboxDropdown
                    className="w-[180px] ml-[10px]"
                    name="Countries"
                    optionsList={filtersOptionsData?.countries.map((country, index) => ({ id: index, name: country }))}
                    isResetForm={isResetForm}
                    setIsResetForm={setIsResetForm}
                    data={filterDataCountries}
                    setData={setFilterDataCountries}
                    placeholder="Countries"
                />
                <FTEFilterCheckboxDropdown
                    className="w-[180px] ml-[10px]"
                    name="Cities"
                    optionsList={filtersOptionsData?.cities.map((city, index) => ({ id: index, name: city }))}
                    isResetForm={isResetForm}
                    setIsResetForm={setIsResetForm}
                    data={filterDataCities}
                    setData={setFilterDataCities}
                    placeholder="Cities"
                />
                <FTEFilterCheckboxDropdown
                    className="w-[180px] ml-[10px]"
                    name="Offices"
                    optionsList={filtersOptionsData?.offices.map((office, index) => ({ id: index, name: office }))}
                    isResetForm={isResetForm}
                    setIsResetForm={setIsResetForm}
                    data={filterDataOffices}
                    setData={setFilterDataOffices}
                    placeholder="Offices"
                />
            </div>
            <div className="flex">
                <div className="flex items-center">
                    <SortListIcon width={18} height={18} fill="#EDF1FD" />
                    <div className="ml-[6px]">{t('Sort by')}:</div>
                </div>
                <FTEFilterRadioDropdown
                    className="w-[160px] ml-[10px]"
                    setData={setSortData}
                    optionsList={filtersOptionsData?.orderings}
                />
            </div>
        </div>
    );
};

export default FteGraphViewFilter;

FteGraphViewFilter.propTypes = {
    clearFilters: func,
    isResetForm: bool,
    setIsResetForm: func,
    filterDataCompanies: arrayOf(object),
    setFilterDataCompanies: func,
    filterDataCountries: arrayOf(object),
    setFilterDataCountries: func,
    filterDataCities: arrayOf(object),
    setFilterDataCities: func,
    filterDataOffices: arrayOf(object),
    setFilterDataOffices: func,
    setSortData: func,
    sumLength: number,
    filtersOptionsData: object,
};
