import React, { useState } from 'react';
import { FieldArray, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import { func, bool, object, arrayOf } from 'prop-types';
import FInput from '../../../style/Input/Input';
import TextArea from '../../../style/TextArea/TextArea';
import RadioInput from '../../../style/RadioInput/RadioInput';
import { ReactComponent as InfoProjectIcon } from '../../../assets/icons/infoProjectIcon.svg';
import Preloader from '../../Preloader/Preloader';
import SearchDropDown from '../../../style/SearchDropDown/SearchDropDown';
import { ReactComponent as AddProjectDeleteIcon } from '../../../assets/icons/addProjectDeleteIcon.svg';
import { ReactComponent as AddProjectManagerIcon } from '../../../assets/icons/addProjectManagerIcon.svg';
import { i18PAGES } from '../../../config/i18n/constants';

const Step1FormProject = ({
    handleSubmit,
    isSubmitting,
    values,
    handleBlur,
    handleChange,
    showModalOnFocusNewData,
    touched,
    errors,
    openModalChecking,
    setIsModalOpened,
    setFieldValue,
    filteredNotSelectedUsers,
    setSelectedUserById,
    isLoading,
    isDisabledYearsInScope = false,
}) => {
    const { t } = useTranslation(i18PAGES.PROJECTS_SETUP);

    const [isOpenHelpInfo, setIsOpenHelpInfo] = useState(false);

    return (
        <Form onSubmit={handleSubmit}>
            <div className="flex justify-between items-center">
                <div className="font-Lexend500 text-[24px] leading-[24px] text-int-white-main">{t('General Info')}</div>
                <div>
                    <button
                        className={`text-int-white-main border border-int-grapefruit bg-int-grapefruit ml-[20px] rounded-[8px] w-[140px] h-[42px] font-Lexend400 ${
                            isSubmitting && 'opacity-[0.5]'
                        }`}
                        type="submit"
                        disabled={isSubmitting}
                    >
                        {t('Save & Next')}
                    </button>
                </div>
            </div>

            {isLoading ? (
                <div className="flex justify-center items-center pb-[40px]">
                    <Preloader />
                </div>
            ) : (
                <>
                    <FInput
                        name="name"
                        placeholder="Project name"
                        value={values.name}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        handleFocus={showModalOnFocusNewData}
                        className="w-[400px] mt-[40px]"
                        type="textarea"
                        label="Project name"
                        isError={!!(touched.name && errors.name)}
                        errorText={errors.name}
                        setFieldValue={setFieldValue}
                        limit={30}
                    />
                    <TextArea
                        handleChange={handleChange}
                        name="description"
                        label="Project description"
                        value={values.description}
                        handleBlur={handleBlur}
                        handleFocus={showModalOnFocusNewData}
                        isError={!!(touched.description && errors.description)}
                        errorText={errors.description}
                        className="mt-[50px] min-h-[144px]"
                        placeholder="Project description"
                        errorIconPosition="top-[45px]"
                        setFieldValue={setFieldValue}
                        limit={300}
                    />
                    <div className="text-[20px] leading-[24px] text-int-white-main font-Lexend500">
                        {t('Years in scope')}
                    </div>
                    <div className="flex mt-[18px] text-int-white-main relative">
                        {openModalChecking && (
                            <div
                                className="absolute w-[320px] h-[40px] top-[-10px] z-10"
                                onClick={isDisabledYearsInScope ? null : () => setIsModalOpened(true)}
                            />
                        )}

                        <div className="mr-[30px]">
                            <RadioInput
                                setFieldValue={setFieldValue}
                                name="yearsInScope"
                                label="1"
                                value="1"
                                isChecked={values.yearsInScope === '1'}
                                disabled={isDisabledYearsInScope}
                            />
                        </div>
                        <div className="mr-[30px]">
                            <RadioInput
                                setFieldValue={setFieldValue}
                                name="yearsInScope"
                                label="2"
                                value="2"
                                isChecked={values.yearsInScope === '2'}
                                disabled={isDisabledYearsInScope}
                            />
                        </div>
                        <div className="mr-[30px]">
                            <RadioInput
                                setFieldValue={setFieldValue}
                                name="yearsInScope"
                                label="3"
                                value="3"
                                isChecked={values.yearsInScope === '3'}
                                disabled={isDisabledYearsInScope}
                            />
                        </div>
                        <div className="mr-[30px]">
                            <RadioInput
                                setFieldValue={setFieldValue}
                                name="yearsInScope"
                                label="4"
                                value="4"
                                isChecked={values.yearsInScope === '4'}
                                disabled={isDisabledYearsInScope}
                            />
                        </div>
                        <div className="mr-[30px] ">
                            <RadioInput
                                setFieldValue={setFieldValue}
                                name="yearsInScope"
                                label="5"
                                value="5"
                                isChecked={values.yearsInScope === '5'}
                                disabled={isDisabledYearsInScope}
                            />
                        </div>
                    </div>
                    <div className="mt-[30px] flex items-center mb-[20px]">
                        <div className="text-[20px] leading-[24px] text-int-white-main font-Lexend500 mr-[8px] ">
                            {t('Add Project Managers')}
                        </div>
                        <div className="relative">
                            {isOpenHelpInfo && (
                                <div
                                    className="absolute p-[12px] w-[370px] bg-int-gray-secondary rounded-[8px]
                                 text-[14px] leading-[150%] font-Lexend400 text-int-white-main z-20 text-center
                                 left-[-184px] top-[-85px]"
                                >
                                    {t(
                                        'Project Managers have the rights to edit the whole project. You can always add managers later'
                                    )}
                                    <div className="w-[16px] h-[16px] bg-int-gray-secondary absolute bottom-[-8px] left-1/2 rotate-45" />
                                </div>
                            )}

                            <InfoProjectIcon
                                className="cursor-pointer"
                                onMouseEnter={() => setIsOpenHelpInfo(true)}
                                onMouseLeave={() => setIsOpenHelpInfo(false)}
                            />
                        </div>
                    </div>

                    <FieldArray
                        name="users"
                        render={(ArrayHelpers) => (
                            <div>
                                {values?.users?.map((item, index) => {
                                    const usersErrors = (errors.users?.length && errors.users[index]) || {};
                                    const usersTouched = (touched.users?.length && touched.users[index]) || {};

                                    return (
                                        <div className="min-h-[68px]" key={item?.key || index}>
                                            <div className="w-[380px] flex">
                                                <SearchDropDown
                                                    name={`users.${index}`}
                                                    placeholder={values?.users[index]?.label}
                                                    isError={!!(usersTouched && usersErrors.label)}
                                                    errorText={usersErrors.label}
                                                    className="w-[340px]"
                                                    options={filteredNotSelectedUsers}
                                                    label=""
                                                    handleChange={(user) => {
                                                        if (user.label !== '') {
                                                            setFieldValue(`users.${index}`, {
                                                                value: user.value,
                                                                label: user.label,
                                                                isSelected: true,
                                                                key: user.key,
                                                            });
                                                            setSelectedUserById(values.users[index].value, false);
                                                            setSelectedUserById(user.value, true);
                                                        }
                                                    }}
                                                    handleBlur={handleBlur}
                                                    handleFocus={showModalOnFocusNewData}
                                                    maxHeightDropDown={200}
                                                />
                                                {values.users.length > 1 && (
                                                    <div
                                                        className="ml-[20px] mt-[13px] cursor-pointer"
                                                        onClick={() => {
                                                            ArrayHelpers.remove(index);
                                                            setSelectedUserById(values.users[index].value, false);
                                                        }}
                                                    >
                                                        <AddProjectDeleteIcon />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    );
                                })}
                                <div
                                    className={`inline-block items-center ${
                                        filteredNotSelectedUsers?.length > 0 && 'cursor-pointer'
                                    }`}
                                    onClick={
                                        filteredNotSelectedUsers?.length > 0
                                            ? () => {
                                                  if (openModalChecking) {
                                                      setIsModalOpened(true);
                                                  } else {
                                                      ArrayHelpers.push(filteredNotSelectedUsers[0]);
                                                      setSelectedUserById(filteredNotSelectedUsers[0].value, true);
                                                      setIsModalOpened(false);
                                                  }
                                              }
                                            : null
                                    }
                                >
                                    <div className="text-[14px] leading-[18px] font-Lexend500 flex">
                                        <AddProjectManagerIcon
                                            fill={filteredNotSelectedUsers?.length > 0 ? '#9BA1B2' : '#354153'}
                                        />
                                        <div
                                            className={`ml-[6px] ${
                                                filteredNotSelectedUsers?.length > 0
                                                    ? 'text-int-white-main'
                                                    : 'text-int-gray-secondary'
                                            }`}
                                        >
                                            {t('Add Project Manager')}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    />
                </>
            )}
        </Form>
    );
};

export default Step1FormProject;

Step1FormProject.propTypes = {
    handleSubmit: func,
    isSubmitting: bool,
    values: object,
    handleBlur: func,
    handleChange: func,
    showModalOnFocusNewData: func,
    touched: object,
    errors: object,
    openModalChecking: bool,
    setIsModalOpened: func,
    setFieldValue: func,
    filteredNotSelectedUsers: arrayOf(object),
    setSelectedUserById: func,
    isLoading: bool,
    isDisabledYearsInScope: bool,
};
