import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PrimaryButton } from '../../../style/Button/Button';
import { i18PAGES } from '../../../config/i18n/constants';

const OwnerConfirmationCanceled = () => {
    const { t } = useTranslation(i18PAGES.AUTH);
    const navigate = useNavigate();

    const onClosePage = () => {
        navigate('/');
    };

    return (
        <>
            <div className="text-int-white-main font-Lexend500 text-[24px] leading-[24px] text-center">
                {t('Access is denied')}
            </div>
            <div className="mt-[16px] w-[440px] text-center text-int-white-main font-Lexend300 text-[14px] leading-[22px]">
                {t(
                    'User will be notified that access to the platform is denied and they have to confirm transaction with their bank.'
                )}
            </div>

            <div className="mt-[50px] w-[100%] flex items-center justify-center">
                <PrimaryButton className="w-[210px] h-[50px]" isPink handleClick={onClosePage}>
                    {t('Close the page')}
                </PrimaryButton>
            </div>
        </>
    );
};

export default OwnerConfirmationCanceled;
