import React from 'react';
import { number, object } from 'prop-types';
import { ReactComponent as DeleteIcon } from '../../../../assets/icons/userManagementBigDeleteIcon.svg';

const DeleteRowButton = (props) => {
    const { ArrayHelpers, index } = props;
    return (
        <div
            className="flex h-[60px] justify-center items-center px-[10px] cursor-pointer"
            onClick={() => {
                ArrayHelpers.remove(index);
            }}
        >
            <DeleteIcon />
        </div>
    );
};

export default DeleteRowButton;

DeleteRowButton.propTypes = {
    ArrayHelpers: object.isRequired,
    index: number.isRequired,
};
