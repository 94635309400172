import React from 'react';
import { useTranslation } from 'react-i18next';
import { number, shape, string } from 'prop-types';
import { i18PAGES } from '../../config/i18n/constants';

const InvoiceFooterInfo = ({
    supplierCompanyName,
    supplierCompanyAddress,
    wahlerCompanyEmail,
    supplierBankInformation,
}) => {
    const { t } = useTranslation(i18PAGES.INVOICE);
    return (
        <div className="flex text-left">
            <div className="w-[100%] pr-[20px]">
                <div className="text-[14px] leading-[18px] font-Lexend500 text-int-white-main mb-[16px] mb-[16px]">
                    {supplierCompanyName}
                </div>
                <div className="text-[14px] leading-[19px] font-Inter400 text-int-white-main mb-[16px]">
                    {supplierCompanyAddress}
                </div>
                <div className="text-[14px] leading-[19px] font-Inter400 text-int-white-main mb-[16px]">
                    {wahlerCompanyEmail}
                </div>
            </div>
            <div className="min-w-[400px] max-w-[400px] w-[100%]">
                <div className="text-[14px] leading-[18px] font-Lexend500 text-int-white-main mb-[16px]">
                    {t('Supplier Bank information')}:
                </div>
                <div className="flex">
                    <div className="pr-[16px] min-w-[150px]">
                        <div className="text-[14px] leading-[18px] font-Lexend400 text-int-white-secondary mb-[4px]">
                            {t('Beneficiary’s bank')}
                        </div>
                        <div className="text-[14px] leading-[19px] font-Inter400 text-int-white-main mb-[16px]">
                            {supplierBankInformation?.beneficiaryBank}
                        </div>
                        <div className="text-[14px] leading-[18px] font-Lexend400 text-int-white-secondary mb-[4px]">
                            {t('SWIFT/BIC')}
                        </div>
                        <div className="text-[14px] leading-[19px] font-Inter400 text-int-white-main mb-[8px]">
                            {supplierBankInformation?.swiftBicCode}
                        </div>
                        <div className="text-[14px] leading-[18px] font-Lexend400 text-int-white-secondary mb-[4px]">
                            {t('Currency')}
                        </div>
                        <div className="text-[14px] leading-[19px] font-Inter400 text-int-white-main">
                            {supplierBankInformation?.currency}
                        </div>
                    </div>
                    <div className="pr-[40px]">
                        <div className="text-[14px] leading-[18px] font-Lexend400 text-int-white-secondary mb-[4px]">
                            {t('Account')} #
                        </div>
                        <div className="text-[14px] leading-[19px] font-Inter400 text-int-white-main mb-[16px]">
                            {supplierBankInformation?.accountNumber}
                        </div>
                        <div className="text-[14px] leading-[18px] font-Lexend400 text-int-white-secondary mb-[4px]">
                            {t('Address')}
                        </div>
                        <div className="text-[14px] leading-[19px] font-Inter400 text-int-white-main">
                            {supplierBankInformation?.bankAddress}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InvoiceFooterInfo;

InvoiceFooterInfo.propTypes = {
    supplierCompanyName: string,
    supplierCompanyAddress: string,
    wahlerCompanyEmail: string,
    supplierBankInformation: shape({
        accountNumber: number,
        bankAddress: string,
        beneficiaryBank: string,
        currency: string,
        swiftBicCode: string,
    }),
};
