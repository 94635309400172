import React from 'react';
import { arrayOf, number, oneOfType, shape, string } from 'prop-types';

const AsIsApplicationViewTableRow = ({ applicationItem }) => (
    <tr className="h-[60px] border-b-[1px] border-int-main-black text-left bg-int-gray-secondary">
        <td className="px-[10px] border-r-[1px] border-int-main-black">
            {applicationItem?.module?.name || <EmptyValue />}
        </td>
        <td className="px-[10px] border-r-[1px] border-int-main-black">
            {applicationItem?.buildingBlock?.name || <EmptyValue />}
        </td>
        <td className="px-[10px] border-r-[1px] border-int-main-black">
            {applicationItem?.applicationName || <EmptyValue />}
        </td>
        <td className="px-[10px] border-r-[1px] border-int-main-black">
            <div className="ml-[14px] flex">
                {applicationItem?.applicationLanguages?.length > 0 ? (
                    applicationItem?.applicationLanguages?.map((lang, key) => (
                        <div className="h-[35px]" key={key}>
                            <div className="mr-[10px] cursor-pointer flex bg-input rounded-[8px] py-[8px] px-[12px] text-[14px] leading-[16px] font-Lexend400 items-center">
                                {lang.split(' ').map((word, index) => (
                                    <div className="ml-[3px]" key={index}>
                                        {word}
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))
                ) : (
                    <EmptyValue />
                )}
            </div>
        </td>
        <td className="px-[10px] border-r-[1px] border-int-main-black">{applicationItem?.status || <EmptyValue />}</td>
        <td className="px-[10px] border-r-[1px] border-int-main-black">
            {applicationItem?.systemSatisfactions || <EmptyValue />}
        </td>
        <td className="px-[10px] border-r-[1px] border-int-main-black">
            {applicationItem?.reasonToChange || <EmptyValue />}
        </td>
        <td className="px-[10px] border-r-[1px] border-int-main-black">
            {applicationItem?.licenseCostsPerYear || <EmptyValue />}
        </td>
        <td className="px-[10px] border-r-[1px] border-int-main-black">
            {applicationItem?.operatingCostsInternalPerYear || <EmptyValue />}
        </td>
        <td className="px-[10px] border-r-[1px] border-int-main-black">
            {applicationItem?.supportCostsExternalPerYear || <EmptyValue />}
        </td>
        <td className="px-[10px] border-r-[1px] border-int-main-black">
            {applicationItem?.interfaceCostsPerYear || <EmptyValue />}
        </td>
        <td className="px-[10px] border-r-[1px] border-int-main-black">
            {applicationItem?.trainingCostsPerYear || <EmptyValue />}
        </td>
    </tr>
);

export default AsIsApplicationViewTableRow;
AsIsApplicationViewTableRow.propTypes = {
    applicationItem: shape({
        module: shape({
            id: number,
            name: string,
        }),
        buildingBlock: shape({
            id: number,
            name: string,
        }),
        applicationName: string,
        applicationLanguages: arrayOf(string),
        status: string,
        systemSatisfactions: oneOfType([string, number]),
        reasonToChange: string,
        licenseCostsPerYear: oneOfType([string, number]),
        operatingCostsInternalPerYear: oneOfType([string, number]),
        supportCostsExternalPerYear: oneOfType([string, number]),
        interfaceCostsPerYear: oneOfType([string, number]),
        trainingCostsPerYear: oneOfType([string, number]),
    }),
};

const EmptyValue = () => <div className="text-int-white-secondary w-[100%] text-center">—</div>;
