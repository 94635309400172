import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { HEADERS } from '../../components/util/constants';

export const profileAPI = createApi({
    reducerPath: 'profileAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api',
        prepareHeaders: HEADERS,
    }),
    tagTypes: ['profileInfo'],
    endpoints: (build) => ({
        profileInfo: build.query({
            query: () => `/users/profiles`,
        }),
        ownerUserList: build.query({
            query: () => `/admin/owner/users`,
        }),
        ownerUserListEditProject: build.query({
            query: ({ projectId }) => `/projects/${projectId}/users/available`,
        }),
        editProfileInfo: build.mutation({
            query: (body) => ({
                url: '/admin/profiles',
                method: 'PUT',
                body,
            }),
        }),
    }),
});

export const {
    useProfileInfoQuery,
    useOwnerUserListQuery,
    useOwnerUserListEditProjectQuery,
    useEditProfileInfoMutation,
} = profileAPI;
