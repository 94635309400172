import React from 'react';
import { number } from 'prop-types';
import { useTranslation } from 'react-i18next';

const ProgressBar = (props) => {
    const { progressValue } = props;
    const { t } = useTranslation();

    return (
        <div className="flex items-center">
            <div className="relative w-[399px] rounded-[14px] bg-[#435C81] h-[24px]">
                <div
                    className={`absolute left-[4px] top-[4px] h-[16px] rounded-[6px] ${
                        progressValue > 100 ? 'bg-int-grapefruit' : 'bg-[#99C1FC]'
                    }`}
                    style={{
                        width: progressValue < 101 ? `calc(3.9px * ${progressValue})` : 390,
                    }}
                />
            </div>
            <div className="font-Lexend500 text-[16px] leading-[26px] ml-[20px]">
                {progressValue > 100 ? (
                    <div className="text-int-grapefruit flex items-center">
                        <div>{progressValue}%</div>
                        <div className="ml-[20px]">{t('Total percentage amount should not exceed 100%')}</div>
                    </div>
                ) : (
                    <div className="text-int-white-main">{progressValue}%</div>
                )}
            </div>
        </div>
    );
};

export default ProgressBar;

ProgressBar.propTypes = {
    progressValue: number,
};
