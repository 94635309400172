import React from 'react';
import { useTranslation } from 'react-i18next';
import { i18PAGES } from '../../config/i18n/constants';

const SideList = () => {
    const { t } = useTranslation(i18PAGES.HR_TOOLS);
    return (
        <div className="bg-int-gray-main p-[20px] rounded-lg">
            <div className="font-Lexend600 text-[18px] leading-[18px] text-int-white-main">{t('Branches')}</div>
            <div className="flex items-center ml-[5px] mt-[10px]">
                <div className="rounded-full bg-int-grapefruit w-[6px] h-[6px] mr-[15px]" />
                <div>{t('Energy')}</div>
            </div>

            <div className="flex items-center ml-[5px] mt-[10px]">
                <div className="rounded-full bg-int-grapefruit w-[6px] h-[6px] mr-[15px]" />
                <div>{t('Manufactoring')}</div>
            </div>

            <div className="font-Lexend600 text-[18px] leading-[18px] text-int-white-main mt-[32px]">
                {t('References')}
            </div>
            <div className="flex items-center ml-[5px] mt-[10px]">
                <div className="rounded-full bg-int-grapefruit w-[6px] h-[6px] mr-[15px]" />
                <div>{t('Siemens')}</div>
            </div>

            <div className="flex items-center ml-[5px] mt-[10px]">
                <div className="rounded-full bg-int-grapefruit w-[6px] h-[6px] mr-[15px]" />
                <div>{t('Siemens Energy')}</div>
            </div>
        </div>
    );
};

export default SideList;
