import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import ProjectInfoPageHeader from '../components/Platform/Common/ProjectInfoPageHeader';
import { PAGE_IDENTIFIER } from '../components/util/constants';
import TabSwitcher from '../style/TabSwitcher/TabSwitcher';
import { i18PAGES } from '../config/i18n/constants';
import ProblemsOverview from '../components/Platform/Modules&Strategy/ProblemsOverview/ProblemsOverview';
import { useTabsMenu } from '../store/hooks/useTabsMenu';
import { MODULES_AND_PAGES_CHILDREN } from '../components/util/menuStaticChildrenConstants';
import PageNotAvailable from '../components/Platform/PageNotAvailable';
import WeaknessExpectations from '../components/Platform/Modules&Strategy/WeaknessExpectations/WeaknessExpectations';
import Actions from '../components/Platform/Modules&Strategy/Actions/Actions';
import HrDigitalization from '../components/Platform/Modules&Strategy/HrDigitalization/HrDigitalization';
import Goals from '../components/Platform/Modules&Strategy/Goals/Goals';
import HrStrategy from '../components/Platform/Modules&Strategy/HrStrategy/HrStrategy';
import Challenges from '../components/Platform/Modules&Strategy/Challenges/Challenges';

const ModulesStrategyPage = () => {
    const [searchParams] = useSearchParams();
    const projectId = searchParams.get('projectId');
    const currentTab = searchParams.get('current');

    const { t } = useTranslation(i18PAGES.MODULES_AND_STRATEGY);
    const { tabsMenuData } = useTabsMenu();

    const shownChildren = useMemo(() => {
        switch (currentTab?.toUpperCase()) {
            case MODULES_AND_PAGES_CHILDREN.PROBLEMS_OVERVIEW:
                return <ProblemsOverview projectId={Number(projectId)} />;
            case MODULES_AND_PAGES_CHILDREN.WEAKNESSES_AND_EXPECTATIONS:
                return <WeaknessExpectations projectId={Number(projectId)} />;
            case MODULES_AND_PAGES_CHILDREN.ACTIONS:
                return <Actions projectId={Number(projectId)} />;
            case MODULES_AND_PAGES_CHILDREN.HR_DIGITALIZATION:
                return <HrDigitalization projectId={Number(projectId)} />;
            case MODULES_AND_PAGES_CHILDREN.GOALS:
                return <Goals projectId={Number(projectId)} />;
            case MODULES_AND_PAGES_CHILDREN.HR_STRATEGY:
                return <HrStrategy projectId={Number(projectId)} />;
            case MODULES_AND_PAGES_CHILDREN.CHALLENGES:
                return <Challenges projectId={Number(projectId)} />;

            default:
                return <></>;
        }
    }, [currentTab, projectId]);

    if (!projectId) {
        return <PageNotAvailable />;
    }

    return (
        <div className="pl-[50px] pb-[80px] pr-[25px] pt-[70px] min-h-[100vh] text-int-white-main">
            <ProjectInfoPageHeader
                nextLink="/workers_information"
                pageIdentifier={PAGE_IDENTIFIER.MODULES_AND_STRATEGY}
                subPageIdentifier={currentTab?.toUpperCase() || 'PROBLEMS_OVERVIEW'}
                projectId={Number(projectId)}
                pageName={t('Modules & Strategy')}
            />

            <TabSwitcher
                itemsArray={tabsMenuData?.MODULES_AND_STRATEGY || []}
                rootUrl="modules_and_strategy"
                projectId={projectId}
                useDisable
            />

            <div className="mt-[30px]">{shownChildren}</div>
        </div>
    );
};

export default ModulesStrategyPage;
