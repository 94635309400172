import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { PAGE_IDENTIFIER } from '../components/util/constants';
import { i18PAGES } from '../config/i18n/constants';
import ProjectInfoPageHeader from '../components/Platform/Common/ProjectInfoPageHeader';
import PageNotAvailable from '../components/Platform/PageNotAvailable';
import WorkersListTable from '../components/Platform/WorkersInformation/WorkersListTable';

const WorkersEfficiencyPage = () => {
    const [searchParams] = useSearchParams();
    const projectId = searchParams.get('projectId');
    const { t } = useTranslation(i18PAGES.WORKERS_EFFICIENCY);

    if (!projectId) {
        return <PageNotAvailable />;
    }

    return (
        <div className="pl-[50px] pb-[80px] pr-[25px] pt-[70px] min-h-[100vh] text-int-white-main">
            <ProjectInfoPageHeader
                nextLink="/fte_process"
                pageIdentifier={PAGE_IDENTIFIER.WORKERS_INFORMATION}
                projectId={Number(projectId)}
                pageName={t('Workers information')}
            />
            <div className="flex justify-between mt-[30px] mb-[40px]">
                <div className="w-[600px] h-[52px] flex items-center px-[16px] bg-[#29323F80] rounded-[8px]">
                    {t('Explanation on how to fill in information on this tab')}
                </div>
            </div>
            <WorkersListTable />
        </div>
    );
};

export default WorkersEfficiencyPage;
