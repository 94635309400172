import React, { useCallback, useEffect, useRef, useState } from 'react';
import { node, string } from 'prop-types';
import { ReactComponent as MinusIcon } from '../../assets/icons/lessIcon.svg';
import { ReactComponent as PlusIcon } from '../../assets/icons/moreIcon.svg';
import './AccordionItem.css';

const CommonAccordionItem = ({ children, title }) => {
    const [isOpened, setIsOpened] = useState(false);
    const [height, setHeight] = useState(0);

    const childrenContent = useRef(null);

    useEffect(() => {
        setHeight(childrenContent?.current?.scrollHeight);
    }, [isOpened, children]);

    const openToggle = useCallback(() => {
        setIsOpened((prev) => !prev);
    }, []);

    return (
        <div>
            <div className="bg-int-gray-main rounded-t-[8px]">
                <div
                    className={`flex justify-between items-center
                px-[20px] h-[56px]  ${isOpened ? 'bg-int-gray-secondary' : 'bg-[#2A3143]'} rounded-[8px]
                cursor-pointer w-[100%]`}
                    onClick={openToggle}
                >
                    <div className="flex items-center justify-between w-[100%]">
                        <div className="text-[16px] leading-[24px] font-Lexend500 text-int-white-main">{title}</div>
                        <div className="icon-animated">{isOpened ? <MinusIcon /> : <PlusIcon />}</div>
                    </div>
                </div>
            </div>

            <div
                className="bg-int-gray-main rounded-b-[8px] overflow-hidden"
                style={{
                    height: isOpened ? height : '0',
                    transition: 'height 1s ease, overflow 0.1s',
                    transitionDelay: 'overflow 1s',
                }}
                ref={childrenContent}
            >
                {children}
            </div>
        </div>
    );
};

export default CommonAccordionItem;

CommonAccordionItem.propTypes = {
    children: node.isRequired,
    title: string.isRequired,
};
