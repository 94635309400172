import React, { useState } from 'react';
import { number } from 'prop-types';
import PageViewerItem from './PageViewerItem';
import { PAGE_VIEWERS } from './constants';
import { ReactComponent as ArrowRight } from '../../../assets/icons/arrow-right.svg';
import { ReactComponent as ArrowLeft } from '../../../assets/icons/medium-arrow-left.svg';

const ProjectPagesViewer = ({ projectId }) => {
    const [page, setPage] = useState(0);

    const onLeftArrowClick = () => {
        if (page !== 0) {
            setPage((prev) => prev - 1);
        }
    };
    const onRightArrowClick = () => {
        if (page !== 2) {
            setPage((prev) => prev + 1);
        }
    };
    return (
        <div className="mb-[30px] m-auto items-center flex justify-between">
            <div
                onClick={onLeftArrowClick}
                className={`cursor-pointer w-[25px] h-[25px] flex items-center justify-center ${
                    page === 0 && 'opacity-[0.3]'
                }`}
            >
                <ArrowLeft fill="#9BA1B2" />
            </div>
            <div className="overflow-hidden w-[93%]">
                <div
                    className="flex"
                    style={{
                        transition: 'all 1.5s ease',
                        marginLeft: `${page * -750}px`,
                    }}
                >
                    {PAGE_VIEWERS?.map((pv) => (
                        <PageViewerItem name={pv.name} link={`${pv.link}?projectId=${projectId}`} key={pv.id} />
                    ))}
                </div>
            </div>

            <div
                className={`cursor-pointer w-[25px] h-[25px] flex items-center justify-center ${
                    page === 2 && 'opacity-[0.3]'
                }`}
                onClick={onRightArrowClick}
            >
                <ArrowRight fill="#9BA1B2" />
            </div>
        </div>
    );
};

export default ProjectPagesViewer;

ProjectPagesViewer.propTypes = {
    projectId: number,
};
