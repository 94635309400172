import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import MailImage from '../assets/icons/mailImage.png';
import { i18PAGES } from '../config/i18n/constants';
import { useCountdown } from '../components/util/useCountDown';
import SendAgainBlock from '../components/SentEmail/SendAgainBlock';
import SendBlockWithCounter from '../components/SentEmail/SendBlockWithCounter';
import { useResendRegistrationEmailMutation, useResendResetPasswordMutation } from '../store/API/authAPI';
import { useToast } from '../hoc/toast/ToastProvider';
import { resendRegistrationLetter, resendResetPasswordLetter } from '../components/SentEmail/sendFunctions';

const SentEmailPage = () => {
    const { emailType } = useParams();

    const { t } = useTranslation(i18PAGES.AUTH);
    const location = useLocation();
    const email = location?.state?.email;

    const finishDateMSeconds = JSON.parse(localStorage.getItem(`${emailType}FinishDate${email}`)) || 0;
    const [finishDate, setFinishDate] = useState(finishDateMSeconds);
    const { seconds } = useCountdown(Number(finishDate));
    const [isResendPressed, setIsResendPressed] = useState(true);
    const [resendRegistrationEmail, { isLoading }] = useResendRegistrationEmailMutation();
    const [resendResetPassword, { isLoadingResetPass }] = useResendResetPasswordMutation();

    const { errorToast } = useToast();
    const isStartResendRegistrationEmailTime = localStorage.getItem('startResendRegistrationEmailTime');

    const isResendActive = useMemo(() => {
        const currentDateMSeconds = Date.parse(new Date().toISOString());
        return finishDateMSeconds < currentDateMSeconds;
    }, [finishDateMSeconds, seconds]);

    const resendLink = useCallback(async () => {
        const defaultSendData = { setIsResendPressed, setFinishDate, emailType, errorToast };
        if (emailType === 'register') {
            await resendRegistrationLetter(resendRegistrationEmail, defaultSendData, email);
        }
        if (emailType === 'password-reset') {
            await resendResetPasswordLetter(resendResetPassword, defaultSendData, email);
        }
    }, [emailType, errorToast, resendRegistrationEmail]);

    useEffect(() => {
        if (isStartResendRegistrationEmailTime) {
            localStorage.removeItem('startResendRegistrationEmailTime');
            const currentDate = new Date();
            const endDate = currentDate.setSeconds(currentDate.getSeconds() + 61);
            setFinishDate(JSON.stringify(endDate));
            localStorage.setItem(`${emailType}FinishDate${email}`, JSON.stringify(endDate));
        }
    }, [isStartResendRegistrationEmailTime]);

    return (
        <div className="min-h-[calc(100vh-300px)] h-[100%] flex items-center justify-center">
            <div className="bg-int-gray-main w-[500px] m-auto rounded-[20px] relative">
                <div className="flex justify-center mt-[-10px]">
                    <img src={MailImage} alt="#" />
                </div>
                <div className="top-[196px] text-center mb-[20px]">
                    <div className="font-Lexend-500 text-[24px] leading-[24px] text-int-white-main mb-[16px]">
                        {t('Überprüfen Sie Ihren Posteingang')}
                    </div>
                    <div className="text-[14px] px-[30px]">
                        {emailType === 'password-reset' && (
                            <>
                                {t(
                                    'Wir haben eine E-Mail mit Anweisungen zum Zurücksetzen Ihres Passworts gesendet. Bitte überprüfen Sie den Posteingang und den Spam-Ordner'
                                )}
                            </>
                        )}
                        {emailType === 'register' && (
                            <>
                                {t(
                                    'Wir haben Ihnen eine E-Mail zur Bestätigung Ihrer Anmeldung geschickt. Bitte überprüfen Sie Ihren Posteingang und Ihren Spam-Ordner'
                                )}
                            </>
                        )}
                    </div>
                    <div className="w-[500px] mt-[20px] h-[1px] bg-int-gray-secondary" />
                    {isResendPressed || !isResendActive ? (
                        <SendBlockWithCounter
                            resendLink={resendLink}
                            isResendActive={isResendActive}
                            seconds={seconds}
                            isLoading={isLoading || isLoadingResetPass}
                        />
                    ) : (
                        <SendAgainBlock resendLink={resendLink} isLoading={isLoading || isLoadingResetPass} />
                    )}
                </div>
            </div>
        </div>
    );
};
export default SentEmailPage;
