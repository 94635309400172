import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { func } from 'prop-types';
import { useSearchParams } from 'react-router-dom';
import { i18PAGES } from '../../../config/i18n/constants';
import ProjectManagementUserSortBlock from '../projectManagementUserSortBlock';

const InternalUsersLabels = ({ handleSetSortParam, handleDefaultSort }) => {
    const { t } = useTranslation(i18PAGES.PROJECTS_MANAGEMENT);

    const [searchParams] = useSearchParams();
    const sortByInternalFromUrl = searchParams.get('sortByInternal');
    const orderInternalFromUrl = searchParams.get('orderInternal');

    const sortLabel = useCallback(
        (sortBy) => {
            if (sortByInternalFromUrl === sortBy && orderInternalFromUrl === 'ASC') {
                return 'A-Z';
            }
            if (sortByInternalFromUrl === sortBy && orderInternalFromUrl === 'DESC') {
                return 'Z-A';
            }
            return '';
        },
        [sortByInternalFromUrl, orderInternalFromUrl]
    );

    return (
        <div className="flex w-[100%] py-[8px] bg-[#2A3143]">
            <div className="max-w-[300px] w-[100%] ml-[7px] pl-[5px]">
                <ProjectManagementUserSortBlock
                    handleAZSortClick={() => handleSetSortParam('sortByInternal', 'fullName', 'orderInternal', 'ASC')}
                    handleZASortClick={() => handleSetSortParam('sortByInternal', 'fullName', 'orderInternal', 'DESC')}
                    handleDefaultSortClick={() => handleDefaultSort('sortByInternal', 'orderInternal')}
                    label={sortLabel('fullName')}
                    title={t('Full name')}
                />
            </div>
            <div className="max-w-[390px] w-[100%] pl-[6px]">
                <ProjectManagementUserSortBlock
                    handleAZSortClick={() => handleSetSortParam('sortByInternal', 'email', 'orderInternal', 'ASC')}
                    handleZASortClick={() => handleSetSortParam('sortByInternal', 'email', 'orderInternal', 'DESC')}
                    handleDefaultSortClick={() => handleDefaultSort('sortByInternal', 'orderInternal')}
                    label={sortLabel('email')}
                    title={t('Email')}
                />
            </div>
            <div className="max-w-[150px] w-[100%] pl-[12px]">
                <ProjectManagementUserSortBlock
                    handleAZSortClick={() => handleSetSortParam('sortByInternal', 'role', 'orderInternal', 'ASC')}
                    handleZASortClick={() => handleSetSortParam('sortByInternal', 'role', 'orderInternal', 'DESC')}
                    handleDefaultSortClick={() => handleDefaultSort('sortByInternal', 'orderInternal')}
                    label={sortLabel('role')}
                    title={t('Role')}
                />
            </div>
            <div className="max-w-[150px] w-[100%] pr-[12px] text-right">{t('Actions')}</div>
        </div>
    );
};

export default InternalUsersLabels;

InternalUsersLabels.propTypes = {
    handleSetSortParam: func,
    handleDefaultSort: func,
};
