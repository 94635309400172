import React, { useMemo } from 'react';
import { arrayOf, bool, func, number, shape, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ReactComponent as UserManagementEditIcon } from '../../../assets/icons/userManagementEditIcon.svg';
import { ReactComponent as UserManagementDeleteIcon } from '../../../assets/icons/userManagementDeleteIcon.svg';
import StringShorterTooltip from '../../../style/StringShorterTooltip/StringShorterTooltip';
import { projectManagersUserRoles } from '../../util/constants';
import { i18PAGES } from '../../../config/i18n/constants';

const InternalTableItem = ({ user, handleClickEditUser, handleClickDeleteUser, isChosenProjectArchived }) => {
    const { t } = useTranslation(i18PAGES.PROJECTS_MANAGEMENT);

    const userName = useMemo(() => {
        if (user.userRole === projectManagersUserRoles.ROLE_ADMIN) return <div>{t('Global Admin')}</div>;
        if (user.userRole === projectManagersUserRoles.ROLE_MANAGER) return <div>{t('Project Manager')}</div>;
        if (user.userRole === projectManagersUserRoles.ROLE_USER) return <div>{t('User')}</div>;
        return '';
    }, [user]);

    return (
        <div className="flex w-[100%] py-[18px] text-[14px] leading-[18px] font-Lexend500 text-int-white-main border-b-[1px] border-int-gray-secondary">
            <div className="max-w-[300px] w-[100%] pl-[12px]">
                <StringShorterTooltip string={user.fullName} maxLength={22} />
            </div>
            <div className="max-w-[390px] w-[100%] pl-[12px]">
                <StringShorterTooltip string={user.email} maxLength={26} />
            </div>
            <div className="max-w-[150px] w-[100%] pl-[12px]">{userName}</div>

            <div className="flex justify-end items-center max-w-[150px] w-[100%] pr-[12px]">
                {user.userRole !== projectManagersUserRoles.ROLE_ADMIN && !isChosenProjectArchived && (
                    <>
                        <div
                            className="hover:bg-int-gray-secondary mr-[5px] cursor-pointer w-[20px] h-[20px] flex justify-center items-center"
                            onClick={() => handleClickEditUser(user)}
                        >
                            <UserManagementEditIcon />
                        </div>
                        <div
                            className="cursor-pointer w-[20px] h-[20px] flex justify-center items-center hover:bg-int-gray-secondary"
                            onClick={() => handleClickDeleteUser(user)}
                        >
                            <UserManagementDeleteIcon />
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default InternalTableItem;

InternalTableItem.propTypes = {
    user: shape({
        id: number.isRequired,
        fullName: string.isRequired,
        userRole: string.isRequired,
    }),
    handleClickEditUser: func.isRequired,
    handleClickDeleteUser: func.isRequired,
    isChosenProjectArchived: bool,
};
