import React, { useEffect, useState } from 'react';
import { arrayOf, bool, func, number, shape, string } from 'prop-types';
import { Form, Formik } from 'formik';
import AccordionItem from '../../style/AccordionItem/AccordionItem';
import { step4ValidationSchema } from '../SetupCompanies/stepValidation';
import OfficesFieldArray from '../SetupCompanies/Step4Offices/OfficesFieldArray';
import { findCountryCode } from '../util/countryHelper';
import OfficesInfoFieldsArray from './OfficesInfoFieldsArray';
import { useSendCompaniesOfficesMutation } from '../../store/API/companiesAPI';
import i18n from '../../config/i18n/i18n';
import { useToast } from '../../hoc/toast/ToastProvider';

const CompaniesCountryItem = ({
    cityItem,
    isEditMode = false,
    countryName,
    companyChanged,
    isShowModules = false,
    setOpenTabIndex,
    openedTabCityId,
}) => {
    const [currentOfficeTab, setCurrentOfficeTab] = useState(0);
    const [updateOffices] = useSendCompaniesOfficesMutation();
    const { successToast, errorToast } = useToast();

    const handleTabChoose = (tabIndex) => {
        setCurrentOfficeTab(tabIndex);
    };

    const onNextButtonPress = () => {
        setCurrentOfficeTab((prev) => prev + 1);
    };

    useEffect(() => {
        setCurrentOfficeTab(0);
    }, [companyChanged]);

    return (
        <Formik
            onSubmit={async (values, { setSubmitting }) => {
                const cityOffices = { ...values.offices, cityId: values.offices.id };

                await updateOffices({ cityOffices: [cityOffices] })
                    .unwrap()
                    .then(() => {
                        successToast(i18n.t('Office(s) changes were successfully updated'));
                    })
                    .catch(() => {
                        errorToast(i18n.t('Something went wrong with companies changes. Try again later'));
                    })
                    .finally(() => {
                        setSubmitting(false);
                    });
            }}
            validationSchema={step4ValidationSchema}
            initialValues={{
                offices: cityItem,
            }}
            enableReinitialize
        >
            {({ values, touched, errors, isSubmitting, handleSubmit, handleBlur, handleChange, setFieldValue }) => (
                <Form onSubmit={handleSubmit}>
                    <AccordionItem
                        officesCount={cityItem?.offices?.length}
                        cityName={cityItem?.name}
                        cityId={cityItem?.id}
                        countryName={countryName}
                        flagCode={findCountryCode(countryName)}
                        setOpenTabIndex={setOpenTabIndex}
                        openedTabCityId={openedTabCityId}
                    >
                        <div className="py-[30px]">
                            <div className="flex font-Lexend400 text-[16px] leading-[18px]">
                                {cityItem?.offices?.map((office, _index) => (
                                    <div
                                        key={_index}
                                        onClick={() => handleTabChoose(_index)}
                                        className={`px-[16px] cursor-pointer ${
                                            _index === currentOfficeTab
                                                ? 'text-int-white-main border-b-[3px] border-int-white-main pb-[19px] rounded-[3px]'
                                                : 'text-int-white-secondary'
                                        }`}
                                    >
                                        {office?.name}
                                    </div>
                                ))}
                            </div>
                            <div className="mt-[-2px] m-auto bg-int-gray-secondary h-[1px] w-[100%]" />
                            {isEditMode ? (
                                <OfficesFieldArray
                                    isLastTab={currentOfficeTab === (cityItem?.offices?.length || 0) - 1}
                                    handleChange={handleChange}
                                    values={values}
                                    errors={errors}
                                    handleBlur={handleBlur}
                                    touched={touched}
                                    currentTab={currentOfficeTab}
                                    isSubmitting={isSubmitting}
                                    onNextButtonPress={onNextButtonPress}
                                    isShowModules={isShowModules}
                                    isEditMode={isEditMode}
                                    setFieldValue={setFieldValue}
                                />
                            ) : (
                                <OfficesInfoFieldsArray
                                    officeItem={cityItem}
                                    currentTab={currentOfficeTab}
                                    isShowModules={isShowModules}
                                />
                            )}
                        </div>
                    </AccordionItem>
                </Form>
            )}
        </Formik>
    );
};

export default CompaniesCountryItem;

CompaniesCountryItem.propTypes = {
    cityItem: shape({
        name: string.isRequired,
        offices: arrayOf(
            shape({
                name: string.isRequired,
                address: string.isRequired,
                employeeQuantity: number,
                yearFounded: number.isRequired,
                employeeQuantityWc: number.isRequired,
                employeeQuantityBc: number.isRequired,
            })
        ),
    }).isRequired,
    isEditMode: bool,
    countryName: string.isRequired,
    companyChanged: number,
    isShowModules: bool,
    setOpenTabIndex: func,
    openedTabCityId: number,
};
