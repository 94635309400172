import React from 'react';
import { number } from 'prop-types';

const FteGraphViewPercentIndicator = ({ maximumXGraph }) => (
    <div className=" mb-[9px] flex text-[14px] leading-[24px] font-Lexend400">
        <div className="max-w-[237px] w-[100%] flex justify-between">
            <div className="ml-[20px]">FTE,%</div>
            <div className="relative right-[-5px]">0</div>
        </div>
        <div className="w-[100%] flex pr-[6px]">
            {[...Array(10)].map((i, index) => (
                <div className="w-[10%] text-center text-right relative right-[-5px]" key={index}>
                    {(maximumXGraph / 10) * (index + 1)}
                </div>
            ))}
        </div>
    </div>
);

export default FteGraphViewPercentIndicator;

FteGraphViewPercentIndicator.propTypes = {
    maximumXGraph: number,
};
