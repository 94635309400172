import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { PrimaryButton } from '../../style/Button/Button';
import { ReactComponent as LogoIcon } from '../../assets/icons/logo-big.svg';

const ExternalHeader = () => (
    <header className="h-header-height bg-int-gray-main">
        <div className="max-w-container-size h-[100%] m-auto flex items-center justify-between px-[100px]">
            <div className="flex items-center">
                <div className="cursor-pointer">
                    <Link to="/">
                        <LogoIcon />
                    </Link>
                </div>
            </div>

            <div className="flex items-center">
                <NavLink to="/register-modules" className="mr-[60px]">
                    {({ isActive }) => (
                        <div className={isActive ? 'font-Lexend400 text-int-white-main' : 'text-int-white-secondary'}>
                            Module und Funktionen
                        </div>
                    )}
                </NavLink>

                <NavLink to="/hr" className="mr-[50px]">
                    {({ isActive }) => (
                        <div className={isActive ? 'font-Lexend400 text-int-white-main' : 'text-int-white-secondary'}>
                            HR Tools
                        </div>
                    )}
                </NavLink>
                <Link to="/login">
                    <PrimaryButton className="w-[100px]">Login</PrimaryButton>
                </Link>

                <div className="ml-[30px]">
                    <Link to="/register-modules">
                        <PrimaryButton isPink className="w-[173px]">
                            Registrierung
                        </PrimaryButton>
                    </Link>
                </div>
            </div>
        </div>
    </header>
);

export default ExternalHeader;
