import { useDispatch, useSelector } from 'react-redux';
import {
    clearCompanies,
    clearProjectSetup,
    setCompanies,
    setFirstStep,
    setProjectTree,
} from '../reducers/createProjectReducer';

export const useCreateProject = () => {
    const dispatch = useDispatch();

    const createProjectInfo = useSelector((state) => state.createProject);

    const _setFirstStep = (payload) => {
        dispatch(setFirstStep(payload));
    };

    const _clearProjectSetup = () => {
        dispatch(clearProjectSetup());
    };

    const _setCompanies = (payload) => {
        dispatch(setCompanies(payload));
    };

    const _clearCompanies = () => {
        dispatch(clearCompanies());
    };

    const _setProjectTree = (projectTree) => {
        dispatch(setProjectTree(projectTree));
    };

    return {
        createProjectInfo,
        setFirstStep: _setFirstStep,
        clearProjectSetup: _clearProjectSetup,
        setCompanies: _setCompanies,
        clearCompanies: _clearCompanies,
        setProjectTree: _setProjectTree,
    };
};
