import React, { memo } from 'react';
import { bool, func, node, oneOf, string } from 'prop-types';

export const PrimaryButton = memo((props) => {
    const { children, className, handleClick, isPink, disabled, isGrey, type = 'button', ...others } = props;
    return (
        <button
            // eslint-disable-next-line react/button-has-type
            type={type}
            className={`${className} cursor-pointer hover:opacity-[0.8] flex items-center justify-center text-[16px] text-int-white-main border 
        ${isPink ? 'border-int-grapefruit bg-int-grapefruit' : !isGrey && 'border-int-white-main'}
        ${isGrey && 'border-int-gray-secondary bg-int-gray-secondary'}
         ${disabled ? 'opacity-[0.6] pointer-events-none' : ''} font-Lexend600 rounded-[8px] py-[6px]`}
            onClick={handleClick}
        >
            {children}
        </button>
    );
});

PrimaryButton.propTypes = {
    type: oneOf(['submit', 'button', 'reset']),
    children: node.isRequired,
    className: string,
    handleClick: func,
    isPink: bool,
    disabled: bool,
    isGrey: bool,
};

export const OutlineButton = memo((props) => {
    const { type = 'button', children, className, ...others } = props;

    return (
        <button
            // eslint-disable-next-line react/button-has-type
            type={type}
            className={`cursor-pointer 
            hover:opacity-[0.8] px-[40px] py-[10px] font-Lexend400 text-[16px] leading-[26px] 
            border border-int-white-secondary rounded-[8px] text-int-white-main ${className}`}
            {...others}
        >
            {children}
        </button>
    );
});

OutlineButton.propTypes = {
    children: node.isRequired,
    type: oneOf(['submit', 'button', 'reset']),
    className: string,
};
