import React from 'react';
import { bool, func, node } from 'prop-types';
import { ReactComponent as AddIcon } from '../../assets/icons/addIcon.svg';

const AddButton = ({ handleClick, children, disabled }) => (
    <button
        type="button"
        onClick={handleClick}
        className={`${
            disabled && 'opacity-[0.5]'
        } h-[42px] hover:opacity-[0.8] px-[16px] bg-int-gray-secondary rounded-[8px] text-int-white-main font-Lexend400 text-[14px] leading-[14px]`}
        disabled={disabled}
    >
        <div className="flex items-center">
            <AddIcon width={16} height={16} fill="#EDF1FD" />
            <div className="ml-[4px]">{children}</div>
        </div>
    </button>
);

export default AddButton;

AddButton.propTypes = {
    handleClick: func.isRequired,
    children: node.isRequired,
    disabled: bool,
};
