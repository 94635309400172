import { object, string } from 'prop-types';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useShowMenu } from '../../store/hooks/useShowMenu';
import ProjectInfoMenuStaticChildrenItem from './ProjectInfoMenuStaticChildrenItem';
import ProjectInfoMenuDynamicChildrenItem from './ProjectInfoMenuDynamicChildrenItem';
import ProjectInfoMenuItemCategory from './ProjectInfoMenuItemCategory';

const ProjectInfoMenuItem = ({ menuItem, projectId }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [isOpenMenu, setIsOpenMenu] = useState(false);
    const { showFullMenu } = useShowMenu();

    const toggleOpenMenu = (e) => {
        e.stopPropagation();
        setIsOpenMenu((prev) => !prev);
    };

    const handleClickMenu = (url, children, dynamic) => {
        if (children.length === 0) {
            navigate(`/${url}?projectId=${projectId}`);
        } else if (dynamic === false) {
            navigate(`/${url}?current=${children[0].enumName.toLowerCase()}&projectId=${projectId}`);
        } else {
            navigate(
                `/${url}?companyId=${children[0].companyId}&countryId=${children[0].countryId}&cityId=${children[0].cityId}&officeId=${children[0].officeId}&projectId=${projectId}`
            );
        }
    };

    useEffect(() => {
        if (location.pathname.includes(menuItem.pageEnum.enumName.toLowerCase())) {
            setIsOpenMenu(true);
        }
    }, [location, menuItem.pageEnum.enumName]);

    return (
        <div>
            <ProjectInfoMenuItemCategory
                isOpenMenu={isOpenMenu}
                toggleOpenMenu={toggleOpenMenu}
                menuItem={menuItem}
                handleClickMenu={handleClickMenu}
            />
            {isOpenMenu &&
                showFullMenu &&
                menuItem.dynamic === false &&
                menuItem?.data?.map((itemChildren, itemChildrenIndex) => {
                    const handleClickStaticSubMenu = () => {
                        navigate(
                            `/${menuItem.pageEnum.enumName.toLowerCase()}?current=${itemChildren.enumName.toLowerCase()}&projectId=${projectId}`
                        );
                    };

                    return (
                        <ProjectInfoMenuStaticChildrenItem
                            key={itemChildrenIndex}
                            handleClickStaticSubMenu={handleClickStaticSubMenu}
                            itemChildren={itemChildren}
                        />
                    );
                })}
            {isOpenMenu &&
                showFullMenu &&
                menuItem?.dynamic === true &&
                menuItem?.data?.map((itemChildren, itemChildrenIndex) => {
                    const handleClickDynamicSubMenu = () => {
                        navigate(
                            `/${menuItem.pageEnum.enumName.toLowerCase()}?companyId=${
                                itemChildren.companyId
                            }&countryId=${itemChildren.countryId}&cityId=${itemChildren.cityId}&officeId=${
                                itemChildren.officeId
                            }&projectId=${projectId}`
                        );
                    };

                    return (
                        <ProjectInfoMenuDynamicChildrenItem
                            key={itemChildrenIndex}
                            itemChildren={itemChildren}
                            handleClickDynamicSubMenu={handleClickDynamicSubMenu}
                            menuItem={menuItem}
                        />
                    );
                })}
        </div>
    );
};

export default ProjectInfoMenuItem;

ProjectInfoMenuItem.propTypes = {
    menuItem: object.isRequired,
    projectId: string,
};
