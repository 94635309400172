import React from 'react';
import { FieldArray } from 'formik';
import { func, object } from 'prop-types';
import ActionsEditItem from './ActionsEditItem';
import ActionsTableLabels from '../ActionsTableLabels';
import ModulesStrategyAddLineButton from '../../Common/Modules&StrategyAddLineButton';
import { actionsEmptyObject } from '../../Common/constants';

const ActionsEditMode = ({ values, errors, touched, handleBlur, handleChange, setFieldValue }) => (
    <div className="bg-int-gray-main p-[28px] pr-[56px] rounded-[8px]">
        <ActionsTableLabels />
        <FieldArray
            name="actions"
            render={(ArrayHelpers) => (
                <>
                    {values.actions?.map((we, index) => {
                        const actionsErrors = (errors.actions?.length && errors.actions[index]) || {};

                        const actionsTouched = (touched.actions?.length && touched.actions[index]) || {};

                        return (
                            <ActionsEditItem
                                key={index}
                                actionsTouched={actionsTouched}
                                actionsErrors={actionsErrors}
                                handleBlur={handleBlur}
                                handleChange={handleChange}
                                ArrayHelpers={ArrayHelpers}
                                values={values}
                                index={index}
                                setFieldValue={setFieldValue}
                            />
                        );
                    })}
                    <ModulesStrategyAddLineButton ArrayHelpers={ArrayHelpers} pushObject={actionsEmptyObject} />
                </>
            )}
        />
    </div>
);

export default ActionsEditMode;

ActionsEditMode.propTypes = {
    values: object.isRequired,
    errors: object.isRequired,
    touched: object.isRequired,
    handleBlur: func.isRequired,
    handleChange: func.isRequired,
    setFieldValue: func.isRequired,
};
