import React from 'react';
import { useTranslation } from 'react-i18next';
import { func } from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { PrimaryButton } from '../../../style/Button/Button';
import { i18PAGES } from '../../../config/i18n/constants';
import { CONFIRMATION_STEPS } from '../constants';

const UserConfirmationCanceled = ({ setUserConfirmationStep }) => {
    const { t } = useTranslation(i18PAGES.AUTH);
    const navigate = useNavigate();

    const onGoBackClick = () => {
        setUserConfirmationStep(CONFIRMATION_STEPS.CHOICE_SCREEN);
    };

    const onClosePage = () => {
        navigate('/');
    };

    return (
        <>
            <div className="text-int-white-main font-Lexend500 text-[24px] leading-[24px] text-center">
                {t('Confirm Canceled')}
            </div>
            <div className="mt-[16px] w-[440px] text-center text-int-white-main font-Lexend300 text-[14px] leading-[22px]">
                {t('Please confirm your subscription as soon as your bank approves the transaction')}
            </div>

            <div className="mt-[50px] flex items-center justify-between">
                <PrimaryButton className="w-[48%] h-[50px]" isGrey handleClick={onGoBackClick}>
                    {t('Go back')}
                </PrimaryButton>
                <PrimaryButton className="w-[48%] h-[50px]" isPink handleClick={onClosePage}>
                    {t('Close the page')}
                </PrimaryButton>
            </div>
        </>
    );
};

export default UserConfirmationCanceled;

UserConfirmationCanceled.propTypes = {
    setUserConfirmationStep: func.isRequired,
};
