import React from 'react';
import { array, bool, func } from 'prop-types';
import { stringShorter } from '../../util/stringShorter';

const StaticCompaniesViewer = (props) => {
    const { companiesList, chooseCompany, isTabIndex, isActiveTab } = props;

    return (
        <>
            {companiesList?.map((c, index) => (
                <div
                    className="px-[6px] h-[58px] bg-[#1A222E] flex items-center justify-center cursor-pointer"
                    key={isTabIndex ? index : c.id}
                    onClick={() => chooseCompany(isTabIndex ? index : c.id)}
                >
                    <div
                        className={`h-[46px] w-[174px] ${
                            isActiveTab(isTabIndex ? index : c.id) && 'bg-int-gray-secondary'
                        } rounded-[4px] flex items-center text-center capitalize justify-center font-Lexend500 leading-[26px] text-int-white-main`}
                        style={{ wordBreak: 'break-word' }}
                    >
                        {stringShorter(c.name, 25)}
                    </div>
                </div>
            ))}
        </>
    );
};

export default StaticCompaniesViewer;

StaticCompaniesViewer.propTypes = {
    companiesList: array.isRequired,
    chooseCompany: func.isRequired,
    isActiveTab: func,
    isTabIndex: bool,
};
