import { bool, func, node, string } from 'prop-types';
import { useEffect, useRef } from 'react';
import { ReactComponent as CloseModalIcon } from '../../assets/icons/closeModalIcon.svg';
import useOutsideAlerter from '../../components/util/useOutsideAlerter';

const ModalWindow = ({
    isOpen = false,
    setIsOpen,
    children,
    className,
    isCloseClickOutside = false,
    handleClickClose,
    isCloseButton = true,
}) => {
    const container = useRef(null);
    useOutsideAlerter({ ref: container, callback: () => setIsOpen(false) });

    useEffect(() => {
        if (isOpen) document.body.style.overflow = 'hidden';
        else document.body.style.overflow = 'visible';
        return () => {
            document.body.style.overflow = 'visible';
        };
    }, [isOpen]);

    return (
        <>
            {isOpen && (
                <div
                    className="flex items-center justify-center w-screen h-screen fixed top-[0] left-[0] z-10"
                    style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
                >
                    <div
                        className={`${className} px-[28px] pb-[28px] pt-[44px] rounded-lg bg-int-gray-main relative`}
                        ref={isCloseClickOutside ? container : null}
                    >
                        {isCloseButton && (
                            <div
                                className="absolute right-[28px] top-[28px] cursor-pointer"
                                onClick={handleClickClose || (() => setIsOpen(false))}
                            >
                                <CloseModalIcon />
                            </div>
                        )}
                        <div>{children}</div>
                    </div>
                </div>
            )}
        </>
    );
};

ModalWindow.propTypes = {
    children: node.isRequired,
    isOpen: bool.isRequired,
    setIsOpen: func.isRequired,
    className: string,
    isCloseClickOutside: bool,
    handleClickClose: func,
    isCloseButton: bool,
};

export default ModalWindow;
