import React, { useEffect, useRef, useState } from 'react';
import { bool, number, shape, string } from 'prop-types';
import CommentAvatar from './CommentAvatar';
import useOnScreen from '../util/useOnScreen';
import { chatDateHelper } from '../util/chatDateHelper';
import { useSetCommentReadMutation } from '../../store/API/commentsAPI';
import { stringShorter } from '../util/stringShorter';

const CommentItem = ({ message }) => {
    const [isRead, setIsRead] = useState(message?.read);
    const commentItemRef = useRef(null);
    const isVisible = useOnScreen(commentItemRef);
    const [sendSetAsRead] = useSetCommentReadMutation();

    useEffect(() => {
        if (isVisible && !isRead) {
            setTimeout(() => {
                sendSetAsRead(message.id)
                    .unwrap()
                    .then((data) => {
                        setIsRead(true);
                    });
            }, 1200);
        }
    }, [isRead, isVisible, message.id, sendSetAsRead]);

    return (
        <div ref={commentItemRef} className={`px-[30px] py-[14px] ${!isRead && 'bg-[#2C3849]'}`}>
            <div className="flex items-center pb-[12px]">
                <CommentAvatar name={message?.userFullName?.length ? message?.userFullName[0] : 'X'} />
                <div className="text-[16px] leading-[19px] font-Lexend500 text-int-white-main ml-[10px] mr-[10px]">
                    {stringShorter(message?.userFullName, 30) || 'Noname'}
                </div>
            </div>
            <div className="pl-[34px]">
                <div className="break-words text-int-white-main text-[14px] leading-[19px] mb-[8px]">
                    {message.content}
                </div>
                <div className="text-int-white-secondary font-Lexend400 text-[12px] leading-[19px]">
                    {chatDateHelper(message.createdDate)}
                </div>
            </div>
        </div>
    );
};

export default CommentItem;

CommentItem.propTypes = {
    message: shape({
        id: number.isRequired,
        content: string.isRequired,
        userId: number.isRequired,
        userFullName: string,
        createdDate: string.isRequired,
        pageIdentifier: string.isRequired,
        read: bool,
    }),
};
