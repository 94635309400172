import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { ReactComponent as ModalWarningIcon } from '../assets/icons/modalWarningIcon.svg';
import ToastWindow from '../style/ToastWindow/ToastWindow';
import { i18PAGES } from '../config/i18n/constants';
import Modules from '../components/Modules/Modules';

const ModulesPage = () => {
    const { t } = useTranslation(i18PAGES.MODULES);
    const location = useLocation();
    const { pathname } = location;

    const isAdminModules = useMemo(() => pathname === '/modules', [pathname]);
    const [isOpenModal, setIsOpenModal] = useState(!isAdminModules);

    return (
        <div className="flex flex-col py-[50px] w-[100%] px-[140px]">
            <div className="text-[24px] text-center text-int-white-main font-Lexend500 mb-[50px] capitalize">
                {t('Jetzt richtiges Paket auswählen')}
            </div>
            <div className="text-[18px] text-int-white-secondary font-Lexend300 mb-[60px] leading-[30px] text-center m-auto w-[703px]">
                {t(
                    'Select all the modules you need to optimize HR processes in your company and get the maximum result'
                )}
            </div>
            <div className="text-[24px] text-int-white-main font-Lexend500 mb-[31px]">{t('Module und Funktionen')}</div>

            <Modules isAdminModules={isAdminModules} />

            <ToastWindow isOpen={isOpenModal} isShowCloseIcon handleCloseIconClick={() => setIsOpenModal(false)}>
                <div className="flex flex-col items-center justify-center text-int-white-main">
                    <div className="flex items-center text-[16px] font-Lexend500">
                        <ModalWarningIcon />
                        <div className="ml-[10px]">{t('Modules needed')}</div>
                    </div>
                    <div className="text-[14px]">{t('Preselect modules to register')}</div>
                </div>
            </ToastWindow>
        </div>
    );
};

export default ModulesPage;
