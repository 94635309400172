import React from 'react';
import { bool, func } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from '../../../../style/Button/Button';
import i18n from '../../../../config/i18n/i18n';
import { i18PAGES } from '../../../../config/i18n/constants';

const VariationOptionsHeader = (props) => {
    const { t } = useTranslation(i18PAGES.OPTIONS);

    const { handleSubmit, isSubmitting } = props;

    return (
        <>
            <div className="mt-[30px] font-Lexend500 text-[24px] leading-[24px]">
                {t('Generate variation of options')}
            </div>
            <div className="mt-[30px] mb-[40px] flex justify-between">
                <div className="flex font-Lexend400 text-[14px] text-int-white-main">
                    <div className="w-[600px] min-h-[50px] flex items-center px-[16px] bg-[#29323F80] rounded-[8px]">
                        {t('Explanation on how to fill in information on this tab')}
                    </div>
                </div>

                <PrimaryButton
                    disabled={isSubmitting}
                    className="w-[170px] h-[50px] ml-[60px]"
                    isPink
                    handleClick={handleSubmit}
                >
                    <div className="text-[18px] font-Lexend400">{i18n.t('Save')}</div>
                </PrimaryButton>
            </div>
        </>
    );
};

export default VariationOptionsHeader;

VariationOptionsHeader.propTypes = {
    handleSubmit: func.isRequired,
    isSubmitting: bool,
};
