import React from 'react';
import { arrayOf, shape, string } from 'prop-types';
import WeaknessExpectationsTableLabels from '../WeaknessExpectationsTableLabels';
import WeaknessExpectationsReadItem from './WeaknessExpectationsReadItem';
import { weaknessExpectationsEmptyObject } from '../../Common/constants';

const WeaknessExpectationsReadMode = ({ weaknessExpectations }) => (
    <div className="bg-int-gray-main p-[28px] pr-[56px] rounded-[8px]">
        <WeaknessExpectationsTableLabels />
        {weaknessExpectations?.length > 0 &&
            weaknessExpectations?.map((p, index) => (
                <WeaknessExpectationsReadItem key={index} weaknessExpectation={p} />
            ))}
        {weaknessExpectations?.length === 0 && (
            <WeaknessExpectationsReadItem weaknessExpectation={weaknessExpectationsEmptyObject} />
        )}
    </div>
);

export default WeaknessExpectationsReadMode;

WeaknessExpectationsReadMode.propTypes = {
    weaknessExpectations: arrayOf(
        shape({
            weakness: string.isRequired,
            description: string.isRequired,
            expectation: string.isRequired,
        }).isRequired
    ),
};
