import React from 'react';
import { bool, func, node, number, string } from 'prop-types';
import { ReactComponent as CloseModalIcon } from '../../assets/icons/closeToastIcon.svg';

const ToastWindow = ({ children, isOpen, className, isShowCloseIcon = true, handleCloseIconClick, width = 504 }) => (
    <>
        {isOpen && (
            <div className="fixed top-[calc(theme(spacing.header-height)+10px)] left-[calc(50vw-252px)]">
                <div style={{ width }} className={`p-[10px] rounded-lg relative ${className || 'bg-[#5679F7]'}`}>
                    {isShowCloseIcon && (
                        <div
                            className="w-[10px] h-[10px] cursor-pointer absolute top-[10px] right-[10px]"
                            onClick={handleCloseIconClick}
                            aria-hidden="true"
                        >
                            <CloseModalIcon />
                        </div>
                    )}

                    {children}
                </div>
            </div>
        )}
    </>
);

ToastWindow.propTypes = {
    children: node.isRequired,
    isOpen: bool.isRequired,
    className: string,
    isShowCloseIcon: bool,
    handleCloseIconClick: func,
    width: number,
};

export default ToastWindow;
