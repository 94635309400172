import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { HEADERS } from '../../components/util/constants';
import { PM_PAGINATION_SIZE } from '../../components/ProjectsManagement/UsersPaginator';

export const projectExternalManagementAPI = createApi({
    reducerPath: 'projectExternalManagementAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api',
        prepareHeaders: HEADERS,
    }),
    tagTypes: ['projectExternalManagementList'],
    endpoints: (build) => ({
        externalUsers: build.query({
            query: ({ projectId, page, sortBy, order }) =>
                `/projects/${projectId}/external-users?size=${PM_PAGINATION_SIZE}&sort=${sortBy || 'id'},${
                    sortBy === 'activated' ? `${order === 'DESC' ? 'ASC' : 'DESC'}` : `${order || 'DESC'}`
                }&page=${page}`,
            providesTags: (result) =>
                result
                    ? [
                          ...result.content.map(({ id }) => ({ type: 'projectExternalManagementList', id })),
                          { type: 'projectExternalManagementList', id: 'LIST' },
                      ]
                    : [{ type: 'projectExternalManagementList', id: 'LIST' }],
        }),

        addExternalUser: build.mutation({
            query: ({ projectId, body }) => ({
                url: `/projects/${projectId}/external-users`,
                method: 'POST',
                body,
            }),
            invalidatesTags: (result, error, { id }) => [{ type: 'projectExternalManagementList', id }],
        }),
        editExternalUser: build.mutation({
            query: ({ projectId, userId, body }) => ({
                url: `/projects/${projectId}/external-users/${userId}`,
                method: 'PUT',
                body,
            }),
            invalidatesTags: (result, error, { id }) => [{ type: 'projectExternalManagementList', id }],
        }),
        deleteExternalUser: build.mutation({
            query: ({ projectId, userId }) => ({
                url: `/projects/${projectId}/external-users/${userId}`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, { id }) => [{ type: 'projectExternalManagementList', id }],
        }),
    }),
});

export const {
    useExternalUsersQuery,
    useAddExternalUserMutation,
    useEditExternalUserMutation,
    useDeleteExternalUserMutation,
} = projectExternalManagementAPI;
