import { generateKey } from '../../util/generateKey';

export const getInitialValueEditData = (dataToBeApplications, dataBuildingBlocksCompanyOffices) => ({
    toBeApplicationsList: dataToBeApplications?.map((toBeItem) => ({
        id: toBeItem.id,
        buildingBlock: toBeItem.buildingBlock,
        yearOfImplementation: toBeItem.yearOfImplementation,
        companyOffices: dataBuildingBlocksCompanyOffices?.companyOffices?.map((company) => ({
            companyId: company.companyId,
            companyName: company.companyName,
            officeList: company?.officeList?.map((office) => ({
                ...office,
                isChosen: dataToBeApplications
                    ?.map((toBe) => ({
                        ...toBe,
                        officeDetails: toBe.officeDetails?.map((comp) => ({
                            ...comp,
                            officeList: comp.officeList.map((off) => off.officeId),
                        })),
                    }))
                    .filter((item) => item.id === toBeItem.id)[0]
                    ?.officeDetails?.filter((item) => item.companyId === company.companyId)[0]
                    ?.officeList.includes(office.officeId),
            })),
            isChosen:
                dataToBeApplications
                    ?.filter((item) => item.id === toBeItem.id)[0]
                    ?.officeDetails?.filter((item) => item.companyId === company.companyId)[0]?.officeList?.length ===
                dataBuildingBlocksCompanyOffices?.companyOffices?.filter(
                    (item) => item.companyId === company.companyId
                )[0].officeList?.length,
        })),
        key: generateKey(toBeItem.id),
    })),
});
