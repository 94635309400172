import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import { func } from 'prop-types';
import { useTranslation } from 'react-i18next';
import StepLayout from './StepLayout';
import FInput from '../../style/Input/Input';
import SearchDropDown from '../../style/SearchDropDown/SearchDropDown';
import { SETUP_PAGES, STEP_TYPE } from '../util/constants';
import { useCompanySetup } from '../../store/hooks/useCompanySetup';
import { step1ValidationSchema } from './stepValidation';
import ModalWindow from '../../style/ModalWindow/ModalWindow';
import ChangeStepInfoModalContent from './ChangeStepInfoModalContent';
import { useCreateCompanyMutation } from '../../store/API/companiesAPI';
import http from '../util/http';
import { i18PAGES } from '../../config/i18n/constants';
import { useToast } from '../../hoc/toast/ToastProvider';

const quantityOptions = [
    { label: '1', value: '1' },
    { label: '2', value: '2' },
    { label: '3', value: '3' },
    { label: '4', value: '4' },
    { label: '5', value: '5' },
    { label: '6', value: '6' },
];

const Step1Company = ({ setSetupCurrentPage }) => {
    const { companySetupInfo, setFirstStep, clearCompanySetup, setCompanyTree } = useCompanySetup();
    const [createCompany] = useCreateCompanyMutation();

    const { t } = useTranslation(i18PAGES.SETUP_COMPANIES);
    const { errorToast } = useToast();

    const [isModalOpened, setIsModalOpened] = useState(false);
    const [isChangeAgree, setIsChangeAgree] = useState(false);

    const showModalOnFocusNewData = () => {
        if (
            companySetupInfo.countriesInfo?.countries &&
            companySetupInfo.countriesInfo?.countries?.length > 0 &&
            !isChangeAgree
        ) {
            setIsModalOpened(true);
        } else {
            setIsModalOpened(false);
        }
    };

    return (
        <StepLayout currentStepNumber={1} height={358} type={STEP_TYPE.COMPANIES}>
            <Formik
                onSubmit={async (values, { setSubmitting }) => {
                    if (
                        (companySetupInfo.companyTree?.countries?.length && isChangeAgree) ||
                        !companySetupInfo.companyTree?.countries?.length
                    ) {
                        await createCompany({
                            name: values.name,
                            countriesQuantity: Number(values.countriesQuantity),
                            companyId: companySetupInfo.companyTree?.id || null,
                        })
                            .unwrap()
                            .then((data) => {
                                setFirstStep(data);
                                http.get(`/companies/${data.companyId}`)
                                    .then((res) => {
                                        setCompanyTree(res.data);
                                        setSetupCurrentPage(SETUP_PAGES.STEP_2);
                                    })
                                    .catch((err) => {
                                        setSubmitting(false);
                                        errorToast(err.response.data.message);
                                    });
                            })
                            .catch((err) => {
                                setSubmitting(false);
                                errorToast(err.data.message);
                            });
                    } else {
                        setSubmitting(false);
                        setSetupCurrentPage(SETUP_PAGES.STEP_2);
                    }
                }}
                validationSchema={step1ValidationSchema}
                initialValues={{
                    name: companySetupInfo.companyTree?.name || '',
                    countriesQuantity:
                        companySetupInfo.companyTree?.countriesQuantity > 0
                            ? companySetupInfo.companyTree?.countriesQuantity
                            : '',
                }}
            >
                {({ values, touched, setFieldValue, errors, isSubmitting, handleSubmit, handleBlur, handleChange }) => (
                    <Form onSubmit={handleSubmit}>
                        <div className="flex justify-between items-center">
                            <div className="font-Lexend500 text-[24px] leading-[24px] text-int-white-main">
                                {t('Setup a Company')}
                            </div>
                            <div>
                                <button
                                    className={`text-int-white-main border border-int-grapefruit bg-int-grapefruit ml-[20px] rounded-[8px] w-[140px] h-[42px] font-Lexend400 ${
                                        isSubmitting && 'opacity-[0.5]'
                                    }`}
                                    type="submit"
                                    disabled={isSubmitting}
                                >
                                    {t('Save & Next')}
                                </button>
                            </div>
                        </div>
                        <div className="text-[14px] leading-[22px] mt-[8px]">{t('All fields are required')}</div>
                        <FInput
                            name="name"
                            placeholder="Company name"
                            value={values.name}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            handleFocus={showModalOnFocusNewData}
                            className="w-[400px] mt-[40px]"
                            type="text"
                            label="Company name"
                            isError={!!(touched.name && errors.name)}
                            errorText={errors.name}
                            setFieldValue={setFieldValue}
                            limit={50}
                        />

                        <div className="flex justify-between items-center mt-[40px]">
                            <SearchDropDown
                                name="countriesQuantity"
                                placeholder="Select"
                                value={values.countriesQuantity}
                                isError={!!(touched.countriesQuantity && errors.countriesQuantity)}
                                errorText={errors.countriesQuantity}
                                className="w-[400px]"
                                options={quantityOptions || []}
                                isSearch={false}
                                label="Country"
                                handleChange={(opt) => setFieldValue('countriesQuantity', opt.value)}
                                handleBlur={handleBlur}
                                handleFocus={showModalOnFocusNewData}
                                maxHeightDropDown={250}
                            />
                        </div>
                    </Form>
                )}
            </Formik>
            {isModalOpened && (
                <ModalWindow isOpen={isModalOpened} setIsOpen={setIsModalOpened}>
                    <ChangeStepInfoModalContent
                        setIsAgree={setIsChangeAgree}
                        setIsModalOpened={setIsModalOpened}
                        clearNextSteps={clearCompanySetup}
                    />
                </ModalWindow>
            )}
        </StepLayout>
    );
};
export default Step1Company;

Step1Company.propTypes = {
    setSetupCurrentPage: func,
};
