import React from 'react';
import { bool } from 'prop-types';
import { Link } from 'react-router-dom';
import { ReactComponent as LogoIcon } from '../../assets/icons/logo-big.svg';

const PlatformFooter = ({ isFullWidth = false }) => (
    <footer className="h-platform-footer-height bg-int-gray-main">
        <div
            className={`h-[100%] ${
                !isFullWidth && 'max-w-container-size'
            }  m-auto px-[150px] flex items-center justify-between`}
        >
            <div>
                <Link to="/">
                    <LogoIcon />
                </Link>
            </div>
            <div className="text-[18px] text-int-white-main">
                Copyright {new Date().getFullYear()} MNTN, Inc. Terms & Privacy
            </div>
            <div className="text-int-white-main">
                <div className="mb-[5px]">Blutenburgstr. 36 80636 München</div>
                <div>
                    <a href="tel: +498920040595">
                        <div className="cursor-pointer inline-block">+49 89 20040595</div>
                    </a>
                </div>
                <div>
                    <a href="mailto: info@wahler-hr.com">
                        <div className="cursor-pointer inline-block">info@wahler-hr.com</div>
                    </a>
                </div>
            </div>
        </div>
    </footer>
);

export default PlatformFooter;

PlatformFooter.propTypes = {
    isFullWidth: bool,
};
