import React from 'react';
import { func, number, object } from 'prop-types';
import TextArea from '../../../../../style/TextArea/TextArea';
import { ReactComponent as DeleteIcon } from '../../../../../assets/icons/userManagementBigDeleteIcon.svg';

const WeaknessExpectationsEditItem = ({
    index,
    values,
    handleBlur,
    handleChange,
    weaknessExpectationsTouched,
    weaknessExpectationsErrors,
    ArrayHelpers,
    setFieldValue,
}) => (
    <div className="flex mb-[10px]">
        <div className="flex items-center">
            <TextArea
                label=""
                handleChange={handleChange}
                name={`weaknessExpectations.${index}.weakness`}
                value={values?.weaknessExpectations[index]?.weakness || ''}
                handleBlur={handleBlur}
                isError={!!(weaknessExpectationsTouched.weakness && weaknessExpectationsErrors.weakness)}
                errorText={weaknessExpectationsErrors.weakness}
                className="w-[450px] mr-[10px]"
                height={84}
                setFieldValue={setFieldValue}
                limit={100}
            />
            <TextArea
                label=""
                handleChange={handleChange}
                name={`weaknessExpectations.${index}.description`}
                value={values?.weaknessExpectations[index]?.description || ''}
                handleBlur={handleBlur}
                isError={!!(weaknessExpectationsTouched.description && weaknessExpectationsErrors.description)}
                errorText={weaknessExpectationsErrors.description}
                className="w-[450px] mr-[10px]"
                height={84}
                setFieldValue={setFieldValue}
                limit={500}
            />
            <TextArea
                label=""
                handleChange={handleChange}
                name={`weaknessExpectations.${index}.expectation`}
                value={values?.weaknessExpectations[index]?.expectation || ''}
                handleBlur={handleBlur}
                isError={!!(weaknessExpectationsTouched.expectation && weaknessExpectationsErrors.expectation)}
                errorText={weaknessExpectationsErrors.expectation}
                className="w-[450px]"
                height={84}
                setFieldValue={setFieldValue}
                limit={500}
            />

            <div
                className="flex justify-center items-center w-[20px] h-[42px] mr-[12px] cursor-pointer ml-[13px]"
                onClick={() => ArrayHelpers.remove(index)}
            >
                <DeleteIcon />
            </div>
        </div>
    </div>
);

export default WeaknessExpectationsEditItem;

WeaknessExpectationsEditItem.propTypes = {
    index: number.isRequired,
    values: object.isRequired,
    handleBlur: func.isRequired,
    handleChange: func.isRequired,
    weaknessExpectationsTouched: object.isRequired,
    weaknessExpectationsErrors: object.isRequired,
    ArrayHelpers: object.isRequired,
    setFieldValue: func.isRequired,
};
