import React from 'react';
import { bool, number, oneOfType, string } from 'prop-types';
import { stringShorter } from '../util/stringShorter';

const OnlyViewFieldInput = ({ label, value, isUpperCase = false, shortLength, className }) => (
    <div className={`w-[100%] relative ${className}`}>
        <div
            className={` ${
                isUpperCase && 'uppercase'
            } absolute left-0 top-[-20px] text-[14px] leading-[16px] font-Lexend400 text-int-white-main`}
        >
            {label}
        </div>
        <div className="flex items-center w-[100%] h-[44px] rounded-[8px] bg-[#354153] px-[25px] text-[16px] font-Lexend400 text-int-white-main">
            {stringShorter(value, shortLength || 45)}
        </div>
    </div>
);

OnlyViewFieldInput.propTypes = {
    label: string.isRequired,
    value: oneOfType([string, number]),
    isUpperCase: bool,
    shortLength: number,
    className: string,
};

export default OnlyViewFieldInput;

export const OnlyViewFieldTextArea = ({ label, value, styles }) => (
    <div className="w-[100%] relative">
        <div className="absolute left-0 top-[-20px] text-[14px] leading-[16px] font-Lexend400 text-int-white-main">
            {label}
        </div>
        <div
            id="primary-scroll"
            className={`flex overflow-y-auto w-[100%] h-[100%] rounded-[6px] bg-int-gray-secondary pl-[8px] pr-[28px] py-[6px] leading-[19px] text-[14px] font-Lexend400 text-int-white-main ${styles}`}
        >
            <div className="mp-[15px] px-[6px] pt-[8px]">{value}</div>
        </div>
    </div>
);

OnlyViewFieldTextArea.propTypes = {
    label: string,
    value: oneOfType([string, number]),
    styles: string,
};
