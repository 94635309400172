import React from 'react';
import { func, number, object } from 'prop-types';
import TextArea from '../../../../../style/TextArea/TextArea';
import { ReactComponent as DeleteIcon } from '../../../../../assets/icons/userManagementBigDeleteIcon.svg';

const HrDigitalizationEditItem = ({
    index,
    values,
    handleBlur,
    handleChange,
    hrDigitalizationsTouched,
    hrDigitalizationsErrors,
    ArrayHelpers,
    setFieldValue,
}) => (
    <div className="flex mb-[10px]">
        <div className="flex items-center">
            <TextArea
                label=""
                handleChange={handleChange}
                name={`hrDigitalizations.${index}.stageName`}
                value={values?.hrDigitalizations[index]?.stageName || ''}
                handleBlur={handleBlur}
                isError={!!(hrDigitalizationsTouched.stageName && hrDigitalizationsErrors.stageName)}
                errorText={hrDigitalizationsErrors.stageName}
                className="w-[370px] mr-[10px]"
                height={84}
                setFieldValue={setFieldValue}
                limit={100}
            />
            <TextArea
                label=""
                handleChange={handleChange}
                name={`hrDigitalizations.${index}.description`}
                value={values?.hrDigitalizations[index]?.description || ''}
                handleBlur={handleBlur}
                isError={!!(hrDigitalizationsTouched.description && hrDigitalizationsErrors.description)}
                errorText={hrDigitalizationsErrors.description}
                className="w-[900px]"
                height={84}
                setFieldValue={setFieldValue}
                limit={500}
            />

            <div
                className="flex justify-center items-center w-[20px] h-[42px] mr-[12px] cursor-pointer ml-[13px]"
                onClick={() => ArrayHelpers.remove(index)}
            >
                <DeleteIcon />
            </div>
        </div>
    </div>
);

export default HrDigitalizationEditItem;

HrDigitalizationEditItem.propTypes = {
    index: number.isRequired,
    values: object.isRequired,
    handleBlur: func.isRequired,
    handleChange: func.isRequired,
    hrDigitalizationsTouched: object.isRequired,
    hrDigitalizationsErrors: object.isRequired,
    ArrayHelpers: object.isRequired,
    setFieldValue: func.isRequired,
};
