import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isPlatformEditMode: false,
};

const isPlatformEditModeSlice = createSlice({
    name: 'platform-edit-mode',
    initialState,
    reducers: {
        setPlatformEditMode(state, action) {
            state.isPlatformEditMode = action.payload;
        },
    },
});

export const { setPlatformEditMode } = isPlatformEditModeSlice.actions;

export default isPlatformEditModeSlice.reducer;
