import React from 'react';
import { bool, node } from 'prop-types';
import PlatformHeader from './PlatformHeader';
import PlatformFooter from './PlatformFooter';

const PlatformLayout = ({ children, isFullWidth = false }) => (
    <>
        <PlatformHeader isFullWidth={isFullWidth} />
        <div
            className={`min-h-[calc(calc(100vh-theme(spacing.header-height))-theme(spacing.platform-footer-height))]
         ${!isFullWidth && 'max-w-container-size'}  m-auto py-[15px]`}
        >
            {children}
        </div>
        <PlatformFooter isFullWidth={isFullWidth} />
    </>
);

export default PlatformLayout;

PlatformLayout.propTypes = {
    children: node.isRequired,
    isFullWidth: bool,
};
