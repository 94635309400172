import React from 'react';
import { useTranslation } from 'react-i18next';
import { number, object } from 'prop-types';
import { i18PAGES } from '../../config/i18n/constants';
import { ReactComponent as InfoIcon } from '../../assets/icons/filledInfo.svg';
import ProgressBar from '../../style/ProgressBar/ProgressBar';
import OnlyViewFieldInput from '../Profile/OnlyViewFieldInput';

const WorkingTimeDistributionReadModeOnly = (props) => {
    const { t } = useTranslation(i18PAGES.QUESTIONNAIRE);
    const { data, progressValue = 100 } = props;

    return (
        <>
            <div className="mt-[60px] font-Lexend500 text-[18px] leading-[26px] text-int-white-main">
                {t('Working time distribution')}
            </div>
            <div className="mt-[16px] text-int-white-main mb-[10px]">
                <div className="flex items-center mb-[5px]">
                    <div className="text-[14px] leading-[26px] font-Lexend400 mr-[6px]">{t('Overall FTE')}</div>
                    <InfoIcon width={18} height={18} fill="#9BA1B2" />
                </div>
                <ProgressBar progressValue={progressValue} />
            </div>

            <div>
                {data.workingTimeDistributionDTOS.map((distribution, index) => (
                    <div
                        className="bg-[#1F2937] mt-[20px] min-h-[104px] w-[100%] p-[20px] pb-0 pt-[40px] rounded-[8px]"
                        key={index}
                    >
                        <div className="flex justify-between items-center">
                            <div>
                                <OnlyViewFieldInput
                                    label="Module"
                                    className="w-[320px] mr-[24px]"
                                    value={distribution.moduleName}
                                />
                            </div>
                        </div>

                        <div className="flex mt-[30px] justify-between items-center">
                            <div className="flex items-center flex-wrap">
                                {distribution?.buildingBlocks?.map((bb, ind) => (
                                    <div className="flex items-center mr-[40px] mb-[20px]" key={ind}>
                                        <div className="mr-[20px] text-[16px] leading-[26px] font-Lexend400 text-int-white-main">
                                            {bb.buildingBlockName}
                                        </div>

                                        <OnlyViewFieldInput label="" className="w-[120px]" value={bb.workPercent} />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};

export default WorkingTimeDistributionReadModeOnly;

WorkingTimeDistributionReadModeOnly.propTypes = {
    data: object,
    progressValue: number,
};
