import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { bool } from 'prop-types';
import ModulesBuyBlock from './ModulesBuyBlock';
import Pagination from '../../style/Pagination/Pagination';
import { defineModuleType, MODULE_STATUS } from './helpers';
import ModulesCard from './ModulesCard';
import { LayoutLoader } from '../Preloader/Preloader';
import {
    useAddedModulesQuery,
    useBoughtModulesMutation,
    useModulesListQuery,
    useRegistrationModulesListQuery,
} from '../../store/API/modulesAPI';
import i18n from '../../config/i18n/i18n';
import { useToast } from '../../hoc/toast/ToastProvider';

const Modules = ({ isAdminModules }) => {
    const [activePage, setActivePage] = useState(1);
    const [addedModulesArray, setAddedModulesArray] = useState([]);

    const { data, isLoading } = useModulesListQuery(activePage, { skip: !isAdminModules });
    const { data: registerModulesData, isLoading: isRegisterModulesLoading } = useRegistrationModulesListQuery(
        activePage,
        {
            skip: isAdminModules,
        }
    );

    const { data: addedModules, isLoading: isAddedModulesLoading } = useAddedModulesQuery(
        {},
        { skip: !isAdminModules }
    );

    const [buyModules] = useBoughtModulesMutation();

    const navigate = useNavigate();
    const { errorToast } = useToast();

    const modulesList = useMemo(() => data?.modules || registerModulesData, [data, registerModulesData]);

    const toggleAddButton = useCallback(
        (moduleInfo, type) => {
            if (type !== MODULE_STATUS.BOUGHT) {
                if (addedModulesArray.some((m) => m.moduleId === moduleInfo.moduleId)) {
                    const arrayWithDeletedId = addedModulesArray.filter((mod) => moduleInfo.moduleId !== mod.moduleId);
                    setAddedModulesArray(arrayWithDeletedId);
                } else {
                    setAddedModulesArray((prev) => [...prev, moduleInfo]);
                }
            }
        },
        [addedModulesArray]
    );

    useEffect(() => {
        addedModules?.forEach((m) => {
            setAddedModulesArray((prev) => [...prev, { moduleId: m.moduleId, price: m.price }]);
        });
    }, [addedModules]);

    const handlePurchase = () => {
        if (isAdminModules) {
            const sendData = {
                modules: addedModulesArray.map((m) => m.moduleId),
            };

            buyModules(sendData)
                .unwrap()
                .then((data) => {
                    navigate('/modules-checkout');
                })
                .catch((e) => {
                    errorToast(i18n.t('Something went wrong, try again later'));
                });
        } else {
            navigate('/register', { state: { modules: addedModulesArray.map((m) => m.moduleId) } });
        }
    };

    const totalPrice = useMemo(() => {
        if (addedModulesArray?.length > 0) {
            if (!isAdminModules && addedModulesArray?.length === 1) {
                return 250;
            }

            return addedModulesArray.reduce((res, current) => res + current.price, 0);
        }
    }, [addedModulesArray]);

    if (isLoading || isAddedModulesLoading || isRegisterModulesLoading) {
        return <LayoutLoader />;
    }

    return (
        <div className="flex">
            <div className="mr-[40px]">
                <ModulesBuyBlock
                    addedModules={addedModulesArray?.length}
                    price={500}
                    totalPrice={totalPrice}
                    handlePurchase={handlePurchase}
                    isAdminModules={isAdminModules}
                />
            </div>
            <div>
                <div className="flex flex-wrap justify-between">
                    {modulesList?.content?.map((mod, index) => (
                        <ModulesCard
                            key={index}
                            id={mod.moduleId}
                            title={mod.title}
                            description={mod.description}
                            buildingBlocks={mod.buildingBlocks}
                            type={defineModuleType(
                                mod.status,
                                { moduleId: mod.moduleId, price: mod.price },
                                addedModulesArray
                            )}
                            handleClick={toggleAddButton}
                            price={mod.price}
                        />
                    ))}
                </div>
                <div className="flex items-center justify-center mt-[10px]">
                    <Pagination
                        count={modulesList?.totalElements}
                        pageSize={modulesList?.size}
                        activePage={activePage}
                        setActivePage={setActivePage}
                    />
                </div>
            </div>
        </div>
    );
};

export default Modules;

Modules.propTypes = {
    isAdminModules: bool.isRequired,
};
