import React from 'react';
import { useTranslation } from 'react-i18next';
import { object } from 'prop-types';
import { i18PAGES } from '../../../../../config/i18n/constants';
import ReadOnlyRadioItem from '../ReadOnlyRadioItem';
import { Orientation } from '../../../../util/constants';

const MarketSituationReadItem = ({ office }) => {
    const { t } = useTranslation(i18PAGES.COMPANIES_INFO);
    return (
        <div className="px-[30px] border-r border-int-gray-secondary">
            <div className="text-[14px] leading-[19px] font-Lexend600 uppercase">{t('Arbeitsmarktsituation')}</div>
            <div className="flex items-center mt-[30px]">
                <div>
                    <div className="leading-[16px] text-[14px] font-Lexend400 text-int-white-secondary uppercase">
                        {t('AS Stammhaus')}
                    </div>
                    <div className="mt-[16px]">
                        <ReadOnlyRadioItem
                            label="Arbeitnehmerfreundlich"
                            isActive={office?.headOfficeOrientation === Orientation.EMPLOYEE_FRIENDLY}
                        />
                    </div>
                    <div className="mt-[16px]">
                        <ReadOnlyRadioItem
                            label="Arbeitgeberfreundlich"
                            isActive={office?.headOfficeOrientation === Orientation.EMPLOYER_FRIENDLY}
                        />
                    </div>
                    <div className="mt-[16px]">
                        <ReadOnlyRadioItem
                            label="Ausgewogen"
                            isActive={office?.headOfficeOrientation === Orientation.BALANCED}
                        />
                    </div>
                </div>
                <div className="ml-[70px]">
                    <div className="leading-[16px] text-[14px] font-Lexend400 text-int-white-secondary uppercase">
                        {t('AS Regional')}
                    </div>
                    <div className="mt-[16px]">
                        <ReadOnlyRadioItem
                            label="Arbeitnehmerfreundlich"
                            isActive={office?.regionalOrientation === Orientation.EMPLOYEE_FRIENDLY}
                        />
                    </div>
                    <div className="mt-[16px]">
                        <ReadOnlyRadioItem
                            label="Arbeitgeberfreundlich"
                            isActive={office?.regionalOrientation === Orientation.EMPLOYER_FRIENDLY}
                        />
                    </div>
                    <div className="mt-[16px]">
                        <ReadOnlyRadioItem
                            label="Ausgewogen"
                            isActive={office?.regionalOrientation === Orientation.BALANCED}
                        />
                    </div>
                </div>
            </div>
            <div className="mt-[30px]">
                <div className="mt-[30px] flex">
                    <div>
                        <div className="leading-[16px] text-[14px] font-Lexend400 text-int-white-secondary uppercase">
                            {t('AS National')}
                        </div>
                        <div className="mt-[16px]">
                            <ReadOnlyRadioItem
                                label="Arbeitnehmerfreundlich"
                                isActive={office?.nationalOrientation === Orientation.EMPLOYEE_FRIENDLY}
                            />
                        </div>
                        <div className="mt-[16px]">
                            <ReadOnlyRadioItem
                                label="Arbeitgeberfreundlich"
                                isActive={office?.nationalOrientation === Orientation.EMPLOYER_FRIENDLY}
                            />
                        </div>
                        <div className="mt-[16px]">
                            <ReadOnlyRadioItem
                                label="Ausgewogen"
                                isActive={office?.nationalOrientation === Orientation.BALANCED}
                            />
                        </div>
                    </div>
                    <div className="ml-[70px]">
                        <div className="leading-[16px] text-[14px] font-Lexend400 text-int-white-secondary uppercase">
                            {t('AS International')}
                        </div>
                        <div className="mt-[16px]">
                            <ReadOnlyRadioItem
                                label="Arbeitnehmerfreundlich"
                                isActive={office?.internationalOrientation === Orientation.EMPLOYEE_FRIENDLY}
                            />
                        </div>
                        <div className="mt-[16px]">
                            <ReadOnlyRadioItem
                                label="Arbeitgeberfreundlich"
                                isActive={office?.internationalOrientation === Orientation.EMPLOYER_FRIENDLY}
                            />
                        </div>
                        <div className="mt-[16px]">
                            <ReadOnlyRadioItem
                                label="Ausgewogen"
                                isActive={office?.internationalOrientation === Orientation.BALANCED}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MarketSituationReadItem;

MarketSituationReadItem.propTypes = {
    office: object.isRequired,
};
