import React from 'react';
import { useTranslation } from 'react-i18next';
import { func, string } from 'prop-types';
import { ReactComponent as AddUserIcon } from '../../../../assets/icons/addIcon.svg';

const AddRowButton = (props) => {
    const { t } = useTranslation();

    const { pushFunction, text } = props;

    return (
        <div className="flex items-center mt-[20px]">
            <div className="flex items-center cursor-pointer " onClick={pushFunction}>
                <div className="mr-[6px] ">
                    <AddUserIcon width={16} height={16} fill="#9BA1B2" />
                </div>
                <div className="text-int-white-secondary">{text || t('Add')}</div>
            </div>
        </div>
    );
};

export default AddRowButton;

AddRowButton.propTypes = {
    pushFunction: func.isRequired,
    text: string,
};
