import React, { useCallback, useRef, useState } from 'react';
import { arrayOf, func, shape, string } from 'prop-types';
import { ReactComponent as ThreeDotsIcon } from '../../assets/icons/threeDotIcon.svg';
import useOutsideAlerter from '../../components/util/useOutsideAlerter';

const ThreeDotsMenu = ({ menuOptions }) => {
    const [isOpen, setIsOpen] = useState(false);

    const onToggle = useCallback(() => {
        setIsOpen((prev) => !prev);
    }, []);

    const onClose = useCallback(() => {
        setIsOpen(false);
    }, []);

    const container = useRef(null);
    useOutsideAlerter({ ref: container, callback: onClose });

    return (
        <div className="relative" ref={container}>
            <div
                className="h-[30px] w-[30px] cursor-pointer flex items-center justify-center mb-[35px]"
                onClick={onToggle}
            >
                <ThreeDotsIcon fill="#EDF1FD" />
            </div>
            {isOpen && (
                <div className="absolute w-[220px] bg-[#2A3143] rounded-[8px] top-[40%] right-[-12px] z-50 shadow-dropdown">
                    {menuOptions.map((o, index) => {
                        const handleClick = () => {
                            o.handleClick();
                            onClose();
                        };

                        return (
                            <div
                                className="hover:bg-[#354053] rounded-[8px] cursor-pointer"
                                onClick={handleClick}
                                key={index}
                            >
                                <div
                                    className={`py-[12px] ml-[20px] mr-[5px] ${
                                        index !== menuOptions.length - 1 && 'border-b-[1px] border-int-gray-secondary'
                                    }`}
                                >
                                    {o.text}
                                </div>
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

export default ThreeDotsMenu;

ThreeDotsMenu.propTypes = {
    menuOptions: arrayOf(
        shape({
            text: string.isRequired,
            handleClick: func.isRequired,
        })
    ).isRequired,
};
