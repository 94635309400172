export const PAGE_VIEWERS = [
    { id: 1, name: 'Companies', link: '/companies' },
    { id: 2, name: 'As is applications', link: '/as_is_applications' },
    { id: 3, name: 'To be applications', link: '/to_be_applications' },
    { id: 4, name: 'Application requirements', link: '/application_requirements' },
    { id: 5, name: 'Aggregation of years of implementation', link: '/aggregation_of_years_of_implementation' },
    { id: 6, name: 'As is integrations', link: '/as_is_integrations' },
    { id: 7, name: 'To be integrations', link: '/to_be_integrations' },
    { id: 8, name: 'Modules & strategy', link: '/modules_and_strategy' },
    { id: 9, name: 'Workers information', link: '/workers_information' },
    { id: 10, name: 'FTE Process', link: '/fte_process' },
    { id: 11, name: 'Select Provider', link: '/select_provider' },
    { id: 12, name: 'Options', link: '/options' },
    { id: 13, name: 'Financial comparison', link: '/financial_comparison' },
    { id: 14, name: 'Modules efficiencies', link: '/modules_efficiencies' },
    { id: 15, name: 'Efficiencies comparison', link: '/efficiencies_comparison' },
    { id: 16, name: 'Presentation', link: '/presentation' },
];
