import React from 'react';
import { useTranslation } from 'react-i18next';
import { arrayOf, bool, func, object } from 'prop-types';
import { PrimaryButton } from '../../../style/Button/Button';
import { i18PAGES } from '../../../config/i18n/constants';
import { ReactComponent as CheckboxIcon } from '../../../assets/icons/checkboxIcon.svg';
import { ReactComponent as EditIcon } from '../../../assets/icons/editIcon.svg';
import { useAuth } from '../../../store/hooks/useAuth';

const CompaniesInfoButtons = ({
    isEditMode,
    applyHandleChange,
    isApplyForAll,
    offEditMode,
    onEditMode,
    setIsSubmitted,
    isFormikSubmitting,
    setIsSubmittedPressed,
    companies,
}) => {
    const { t } = useTranslation(i18PAGES.COMPANIES_INFO);
    const { isEditablePagesProject } = useAuth();

    const setSubmittedTrue = () => {
        setIsSubmitted(true);
        setIsSubmittedPressed(true);
    };

    return (
        <div className="flex justify-between mt-[15px] mb-[30px]">
            <div className="w-[600px] min-h-[50px] text-[14px] flex items-center px-[16px] bg-[#29323F80] rounded-[8px]">
                {t('Explanation on how to fill in information on this tab')}
            </div>

            {isEditablePagesProject && (
                <>
                    {isEditMode ? (
                        <div className="flex items-center">
                            {companies.length > 1 && (
                                <div
                                    className="flex float-right ml-[40px] relative text-[14px] text-int-white-secondary items-center cursor-pointer"
                                    onClick={applyHandleChange}
                                >
                                    <div
                                        className={`w-[20px] h-[20px] rounded-[4px] border border-int-white-secondary ${
                                            isApplyForAll && 'bg-int-grapefruit'
                                        }`}
                                    />
                                    <div className="ml-[10px]">{t('Apply for all offices')}</div>
                                    {isApplyForAll && (
                                        <div className="absolute top-[6px] left-[4px]">
                                            <CheckboxIcon />
                                        </div>
                                    )}
                                </div>
                            )}

                            <button
                                className={`w-[170px] rounded-[8px] flex items-center justify-center bg-int-grapefruit h-[50px] ml-[40px] font-Lexend600 ${
                                    isFormikSubmitting && 'opacity-[0.5]'
                                }`}
                                type="submit"
                                onClick={setSubmittedTrue}
                                disabled={isFormikSubmitting}
                            >
                                {t('Save')}
                            </button>

                            <PrimaryButton
                                className="w-[170px] h-[50px] ml-[40px] flex items-center"
                                isGrey
                                handleClick={offEditMode}
                            >
                                <div>{t('Close editing')}</div>
                            </PrimaryButton>
                        </div>
                    ) : (
                        <PrimaryButton
                            className="w-[170px] h-[50px] ml-[60px] flex items-center"
                            isGrey
                            handleClick={onEditMode}
                        >
                            <EditIcon />
                            <div className="ml-[5px]">{t('Edit')}</div>
                        </PrimaryButton>
                    )}
                </>
            )}
        </div>
    );
};

export default CompaniesInfoButtons;

CompaniesInfoButtons.propTypes = {
    isEditMode: bool.isRequired,
    isApplyForAll: bool.isRequired,
    applyHandleChange: func.isRequired,
    offEditMode: func.isRequired,
    onEditMode: func.isRequired,
    setIsSubmitted: func.isRequired,
    setIsSubmittedPressed: func.isRequired,
    isFormikSubmitting: bool,
    companies: arrayOf(object).isRequired,
};
