import React from 'react';
import { useTranslation } from 'react-i18next';
import { object } from 'prop-types';
import { i18PAGES } from '../../../../../config/i18n/constants';
import ReadOnlyRadioItem from '../ReadOnlyRadioItem';
import { CompetitiveStrategy, MarketGrowthStrategy, TimingStrategy } from '../../../../util/constants';
import Checkbox from '../../../../../style/Checkbox/Checkbox';

const StrategyReadItem = ({ office }) => {
    const { t } = useTranslation(i18PAGES.COMPANIES_INFO);
    return (
        <div className="pl-[30px] pb-[60px]">
            <div className="text-[14px] leading-[19px] font-Lexend600 uppercase">{t('Strategy')}</div>
            <div className="flex items-center mt-[30px]">
                <div>
                    <div className="flex">
                        <div>
                            <Checkbox
                                isShowOnly
                                label="Marktwachstumsstrategie"
                                value={office?.isGrowthStrategy}
                                className="text-[14px] font-Lexend400 my-[-8px]"
                                isEnabled={!!office?.isGrowthStrategy}
                            />

                            <div className="pl-[30px]">
                                <div className="mt-[16px]">
                                    <ReadOnlyRadioItem
                                        label="Diversifikation"
                                        isEnabled={!!office?.isGrowthStrategy}
                                        isActive={
                                            office?.marketGrowthStrategy === MarketGrowthStrategy.DIVERSIFICATION &&
                                            !!office?.isGrowthStrategy
                                        }
                                    />
                                </div>
                                <div className="mt-[16px]">
                                    <ReadOnlyRadioItem
                                        label="Marktdurchdringung"
                                        isEnabled={!!office?.isGrowthStrategy}
                                        isActive={
                                            office?.marketGrowthStrategy === MarketGrowthStrategy.MARKET_PENETRATION &&
                                            !!office?.isGrowthStrategy
                                        }
                                    />
                                </div>
                                <div className="mt-[16px]">
                                    <ReadOnlyRadioItem
                                        label="Productentwicklung"
                                        isEnabled={!!office?.isGrowthStrategy}
                                        isActive={
                                            office?.marketGrowthStrategy === MarketGrowthStrategy.PRODUCT_DEVELOPMENT &&
                                            !!office?.isGrowthStrategy
                                        }
                                    />
                                </div>
                                <div className="mt-[16px]">
                                    <ReadOnlyRadioItem
                                        label="Marktentwicklung"
                                        isEnabled={!!office?.isGrowthStrategy}
                                        isActive={
                                            office?.marketGrowthStrategy === MarketGrowthStrategy.MARKET_DEVELOPMENT &&
                                            !!office?.isGrowthStrategy
                                        }
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="ml-[45px]">
                            <Checkbox
                                isShowOnly
                                label="Timingstrategie"
                                value={office?.isTimingStrategy}
                                className="text-[14px] font-Lexend400 my-[-8px]"
                                isEnabled={!!office?.isTimingStrategy}
                            />

                            <div className="pl-[30px]">
                                <div className="mt-[16px]">
                                    <ReadOnlyRadioItem
                                        label="Folgestrategie"
                                        isEnabled={!!office?.isTimingStrategy}
                                        isActive={
                                            office?.timingStrategy === TimingStrategy.FOLLOW_UP_STRATEGY &&
                                            !!office?.isTimingStrategy
                                        }
                                    />
                                </div>
                                <div className="mt-[16px]">
                                    <ReadOnlyRadioItem
                                        label="Pionierstrategie"
                                        isEnabled={!!office?.isTimingStrategy}
                                        isActive={
                                            office?.timingStrategy === TimingStrategy.PIONEER_STRATEGY &&
                                            !!office?.isTimingStrategy
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-[30px]">
                        <Checkbox
                            isShowOnly
                            label="Wettbewerbsstrategie"
                            value={office?.isCompetitiveStrategy}
                            className="text-[14px] font-Lexend400 my-[-8px]"
                            isEnabled={!!office?.isCompetitiveStrategy}
                        />

                        <div className="pl-[30px]">
                            <div className="mt-[16px]">
                                <ReadOnlyRadioItem
                                    label="Qualitätsführerschaft"
                                    isEnabled={!!office?.isCompetitiveStrategy}
                                    isActive={
                                        office?.competitiveStrategy === CompetitiveStrategy.QUALITY_LEADERSHIP &&
                                        !!office?.isCompetitiveStrategy
                                    }
                                />
                            </div>
                            <div className="mt-[16px]">
                                <ReadOnlyRadioItem
                                    label="Nischenstrategie"
                                    isEnabled={!!office?.isCompetitiveStrategy}
                                    isActive={
                                        office?.competitiveStrategy === CompetitiveStrategy.NICHE_STRATEGY &&
                                        !!office?.isCompetitiveStrategy
                                    }
                                />
                            </div>
                            <div className="mt-[16px]">
                                <ReadOnlyRadioItem
                                    label="Kostenführerschaft"
                                    isEnabled={!!office?.isCompetitiveStrategy}
                                    isActive={
                                        office?.competitiveStrategy === CompetitiveStrategy.COST_LEADERSHIP &&
                                        !!office?.isCompetitiveStrategy
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StrategyReadItem;

StrategyReadItem.propTypes = {
    office: object.isRequired,
};
