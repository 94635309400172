import React from 'react';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import { array, object } from 'yup';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from '../../../style/Button/Button';
import ModalWindow from '../../../style/ModalWindow/ModalWindow';
import { i18PAGES } from '../../../config/i18n/constants';
import RadioInput from '../../../style/RadioInput/RadioInput';
import OnlyViewFieldInput from '../../Profile/OnlyViewFieldInput';
import { projectManagersUserRoles } from '../../util/constants';
import { useEditExternalUserMutation } from '../../../store/API/projectExternalManagementAPI';
import { validationReady } from './ExternalUserAdd/AddExternalUserModalForm';
import { formatAccessDataForApi } from './formatAccessDataForApi';
import { useToast } from '../../../hoc/toast/ToastProvider';
import EditChoicePagesDropDown from './ChoicePagesDropDown/EditChoicePagesDropDown';

const validationSchema = object({
    pages: array().of(object()),
    isReady: validationReady,
});

const ExternalUserEdit = ({
    setIsOpenEditUserModal,
    isOpenEditUserModal,
    id,
    fullName,
    email,
    userRole,
    chosenProjectId,
    access,
}) => {
    const [updateExternalUser] = useEditExternalUserMutation();

    const { t } = useTranslation(i18PAGES.PROJECTS_MANAGEMENT);
    const { successToast, errorToast } = useToast();

    return (
        <ModalWindow isOpen={isOpenEditUserModal} setIsOpen={setIsOpenEditUserModal} isCloseClickOutside>
            <div className="text-[24px] leading-[24px] font-Lexend500 text-int-white-main text-center">
                {t('Edit External User')}
            </div>
            <Formik
                onSubmit={(values, { setSubmitting }) => {
                    const dataExternalUser = {
                        role: values.userRole,
                        access: formatAccessDataForApi(values?.pages),
                    };

                    updateExternalUser({
                        projectId: chosenProjectId,
                        userId: id,
                        body: dataExternalUser,
                    })
                        .unwrap()
                        .then(() => {
                            successToast(t('User information were edited successfully'));
                            setIsOpenEditUserModal(false);
                        })
                        .catch(() => {
                            errorToast(t('Unexpected error'));
                        })
                        .finally(() => {
                            setSubmitting(false);
                        });
                }}
                validationSchema={validationSchema}
                initialValues={{
                    userRole: userRole !== undefined ? userRole : projectManagersUserRoles.ROLE_REVIEWER,
                    pages: access,
                }}
                enableReinitialize
            >
                {({ values, handleSubmit, isSubmitting, setFieldValue, handleBlur, errors, touched }) => (
                    <Form className="w-[1124px] mt-[40px]">
                        <div>
                            <div className="flex items-center">
                                <div className="w-[320px] mt-[40px] min-h-[70px] mr-[12px]">
                                    <OnlyViewFieldInput label={t('Name')} value={fullName} shortLength={28} />
                                </div>
                                <div className="w-[320px] mt-[40px] min-h-[70px] mr-[12px]">
                                    <OnlyViewFieldInput label={t('Email')} value={email} shortLength={28} />
                                </div>
                                <EditChoicePagesDropDown
                                    name="pages"
                                    label="Pages"
                                    placeholder="Select"
                                    className="w-[320px] mt-[40px] min-h-[70px] mr-[12px]"
                                    values={values}
                                    valuesPages={values.pages}
                                    handleBlur={handleBlur}
                                    isTouched={!!touched.pages}
                                    typeEdit
                                />
                            </div>
                            <div className="flex items-center text-int-white-main">
                                <div className="text-[16px] leading-[24px] font-Lexend500 text-int-white-main mr-[42px]">
                                    {t('Select Role')}
                                </div>
                                <div className="mr-[30px]">
                                    <RadioInput
                                        setFieldValue={setFieldValue}
                                        name="userRole"
                                        label="Reviewer"
                                        value={projectManagersUserRoles.ROLE_REVIEWER}
                                        isChecked={values?.userRole === projectManagersUserRoles.ROLE_REVIEWER}
                                    />
                                </div>
                                <div className="mr-[30px]">
                                    <RadioInput
                                        setFieldValue={setFieldValue}
                                        name="userRole"
                                        label="Contributor"
                                        value={projectManagersUserRoles.ROLE_CONTRIBUTOR}
                                        isChecked={values?.userRole === projectManagersUserRoles.ROLE_CONTRIBUTOR}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="w-[100%] mt-[37px] flex justify-between">
                            <PrimaryButton
                                isGrey
                                className="w-[170px] h-[50px]"
                                handleClick={() => setIsOpenEditUserModal(false)}
                            >
                                <div className="text-[18px] font-Lexend400">{t('Cancel')}</div>
                            </PrimaryButton>
                            <PrimaryButton
                                isPink
                                className="w-[170px] h-[50px]"
                                handleClick={handleSubmit}
                                disabled={isSubmitting}
                            >
                                <div className="text-[18px] font-Lexend400">{t('Save changes')}</div>
                            </PrimaryButton>
                        </div>
                    </Form>
                )}
            </Formik>
        </ModalWindow>
    );
};

export default ExternalUserEdit;

ExternalUserEdit.propTypes = {
    setIsOpenEditUserModal: PropTypes.func.isRequired,
    isOpenEditUserModal: PropTypes.bool.isRequired,
    id: PropTypes.number,
    fullName: PropTypes.string,
    email: PropTypes.string,
    userRole: PropTypes.string,
    chosenProjectId: PropTypes.number,
    access: PropTypes.arrayOf(PropTypes.object),
};
