import React, { useEffect } from 'react';
import { Form, Formik } from 'formik';
import { object, string } from 'yup';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import FInput from '../style/Input/Input';
import { PrimaryButton } from '../style/Button/Button';
import FormLayout from '../layouts/FormLayout';
import { useAuth } from '../store/hooks/useAuth';
import http from '../components/util/http';
import { i18PAGES } from '../config/i18n/constants';
import { useToast } from '../hoc/toast/ToastProvider';

const validationSchema = object({
    email: string().required('Enter your Email').email('Invalid email'),
    password: string().required('Enter your password'),
});
const LoginPage = () => {
    const navigate = useNavigate();
    const { successToast } = useToast();
    const { isAuth } = useAuth();
    const location = useLocation();

    const { t } = useTranslation(i18PAGES.AUTH);

    const { login, setProfileData } = useAuth();
    const isPasswordChanged = localStorage.getItem('isPasswordChanged');

    useEffect(() => {
        if (isPasswordChanged) {
            const timeout = setTimeout(() => {
                localStorage.removeItem('isPasswordChanged');
                successToast(
                    t('New password was successfully changed'),
                    t('You can continue to Log in with your new password')
                );
            }, 150);

            return () => clearTimeout(timeout);
        }
    }, [isPasswordChanged, successToast, t]);

    if (isAuth) {
        return <Navigate to="/" state={{ from: location }} replace />;
    }

    return (
        <div className="min-h-[calc(100vh-300px)] h-[100%] flex items-center justify-center">
            <div className="py-[100px]">
                <FormLayout
                    subText={t('Loggen Sie sich mit den Daten ein, die Sie während der Registrierung angegeben haben.')}
                    mainText={t('Hallo! Willkommen zurück')}
                    width={500}
                >
                    <Formik
                        onSubmit={(values, { setErrors, setSubmitting }) => {
                            http.post('/authenticate', {
                                email: values.email,
                                password: values.password,
                            })
                                .then((res) => {
                                    login(res.headers.authorization);
                                    setProfileData(res.data);
                                    navigate('/');
                                })
                                .catch((err) => {
                                    if (err?.data?.detail === `User ${values.email} was not activated`) {
                                        setErrors({ email: t('This email is already registered. Activate account.') });
                                    } else setErrors({ email: t('Email or password is incorrect') });
                                })
                                .finally(() => {
                                    setSubmitting(false);
                                });
                        }}
                        validationSchema={validationSchema}
                        initialValues={{
                            email: '',
                            password: '',
                        }}
                    >
                        {({
                            values,
                            touched,
                            setFieldValue,
                            errors,
                            isSubmitting,
                            handleSubmit,
                            handleBlur,
                            handleChange,
                        }) => (
                            <Form onSubmit={handleSubmit}>
                                <FInput
                                    name="email"
                                    placeholder="beispiel@email.com "
                                    value={values.email}
                                    handleBlur={handleBlur}
                                    handleChange={handleChange}
                                    className="w-[440px] mt-[40px]"
                                    type="text"
                                    label={t('Email Adresse')}
                                    isError={!!(touched.email && errors.email)}
                                    errorText={errors.email}
                                    setFieldValue={setFieldValue}
                                />
                                <div className="relative mt-[40px]">
                                    <Link
                                        to="/forgot-password"
                                        className="absolute right-[5px] top-[-25px] text-int-blue-link font-Lexend400 cursor-pointer"
                                    >
                                        {t('Passwort vergessen?')}
                                    </Link>
                                    <FInput
                                        name="password"
                                        placeholder="Passwort eingeben"
                                        value={values.password}
                                        handleBlur={handleBlur}
                                        handleChange={handleChange}
                                        className="w-[440px]"
                                        type="password"
                                        label={t('Passwort')}
                                        isError={!!(touched.password && errors.password)}
                                        errorText={errors.password}
                                        isPassword
                                        setFieldValue={setFieldValue}
                                    />
                                </div>

                                <PrimaryButton
                                    className="mt-[50px] w-[440px] h-[42px]"
                                    type="submit"
                                    isPink
                                    disabled={isSubmitting}
                                >
                                    {t('Einloggen')}
                                </PrimaryButton>
                            </Form>
                        )}
                    </Formik>
                    <div className="absolute left-0 bg-int-gray-secondary mt-[20px] mb-[30px] w-[500px] h-[1px]" />
                    <div className="mt-[52px] font-Lexend400 flex justify-center">
                        <div className="text-int-white-main">{t('Sie haben keinen Account?')}</div>
                        <Link to="/register" className="text-int-blue-link ml-[12px]">
                            {t('Registrieren')}
                        </Link>
                    </div>
                </FormLayout>
            </div>
        </div>
    );
};

export default LoginPage;
