import React, { useState } from 'react';
import { FieldArray } from 'formik';
import { arrayOf, bool, func, number, object, shape, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ReactComponent as AddUserIcon } from '../../../../assets/icons/addIcon.svg';
import { ReactComponent as DeleteIcon } from '../../../../assets/icons/userManagementBigDeleteIcon.svg';
import { i18PAGES } from '../../../../config/i18n/constants';
import FTableInput from '../../../../style/Table/TableInputs/TableInput/TableInput';
import { generateKey } from '../../../util/generateKey';
import TableHeader from '../../../../style/Table/TableHeader';
import TableCompaniesOfficesCheckboxDropdown from '../TableCompaniesOfficesCheckboxDropdown/TableCompaniesOfficesCheckboxDropdown';
import TableSearchDropDown from '../../../../style/Table/TableInputs/TableSearchDropDown/TableSearchDropDown';

const AggregationOfYearEditTable = ({
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    setFieldValue,
    tableHeaderData,
    buildingBlock,
    initialValueCompanies,
    dataToBeApplications,
}) => {
    const { t } = useTranslation(i18PAGES.TO_BE_APPLICATIONS);

    const [chosenIdBuildingBlock, setChosenIdBuildingBlock] = useState(
        dataToBeApplications.map((toBeItem) => toBeItem.buildingBlock.id)
    );

    const addChosenIdBuildingBlock = (id) => {
        if (!chosenIdBuildingBlock.includes(id)) setChosenIdBuildingBlock((prev) => [...prev, id]);
    };

    const deleteChosenIdBuildingBlock = (id) => {
        setChosenIdBuildingBlock((prev) => {
            const index = prev.indexOf(id);
            if (index > -1) {
                prev.splice(index, 1);
            }
            return prev;
        });
    };

    const buildingBlockOptions = buildingBlock
        ?.map((block) => ({ value: block.id, label: block.name }))
        ?.filter((block) => !chosenIdBuildingBlock.includes(block.value));

    return (
        <FieldArray
            name="toBeApplicationsList"
            render={(ArrayHelpers) => (
                <div>
                    <table className="table-auto">
                        <TableHeader data={tableHeaderData} />
                        <tbody className="text-[16px] font-Lexend400 text-int-white-secondary">
                            {values?.toBeApplicationsList?.map((item, index) => {
                                const toBeApplicationsListErrors =
                                    (errors.toBeApplicationsList?.length && errors.toBeApplicationsList[index]) || {};
                                const toBeApplicationsListTouched =
                                    (touched.toBeApplicationsList?.length && touched.toBeApplicationsList[index]) || {};

                                return (
                                    <tr className="h-[60px] border-b-[1px] border-int-main-black" key={item.key}>
                                        <td className="px-[6px] border-r-[1px] border-int-main-black bg-int-gray-secondary">
                                            <TableSearchDropDown
                                                options={buildingBlockOptions}
                                                placeholder="Select"
                                                handleChange={({ label, value }) => {
                                                    setFieldValue(
                                                        `toBeApplicationsList[${index}].buildingBlock.name`,
                                                        label
                                                    );
                                                    setFieldValue(
                                                        `toBeApplicationsList[${index}].buildingBlock.id`,
                                                        value
                                                    );
                                                    addChosenIdBuildingBlock(value);
                                                    deleteChosenIdBuildingBlock(
                                                        values.toBeApplicationsList[index].buildingBlock?.id
                                                    );
                                                }}
                                                handleBlur={handleBlur}
                                                name={`toBeApplicationsList.${index}.buildingBlock.name`}
                                                value={values.toBeApplicationsList[index].buildingBlock?.name}
                                                isError={
                                                    !!(
                                                        toBeApplicationsListTouched.buildingBlock?.name &&
                                                        toBeApplicationsListErrors.buildingBlock?.name
                                                    )
                                                }
                                                errorText={toBeApplicationsListErrors.buildingBlock?.name}
                                                isAutoCompleteOff
                                                maxHeightDropDown={200}
                                            />
                                        </td>
                                        <td className="px-[6px] border-r-[1px] border-int-main-black bg-int-gray-secondary">
                                            <TableCompaniesOfficesCheckboxDropdown
                                                setFieldValue={setFieldValue}
                                                placeholder="Select"
                                                handleBlur={handleBlur}
                                                name={`toBeApplicationsList.${index}.companyOffices`}
                                                values={values}
                                                index={index}
                                                isError={
                                                    !!(
                                                        toBeApplicationsListTouched.companyOffices &&
                                                        toBeApplicationsListErrors.companyOffices
                                                    )
                                                }
                                                errorText={toBeApplicationsListErrors.companyOffices}
                                            />
                                        </td>
                                        <td className="px-[6px] border-r-[1px] border-int-main-black bg-int-gray-secondary">
                                            <FTableInput
                                                setFieldValue={setFieldValue}
                                                limit={4}
                                                type="text"
                                                placeholder="Type"
                                                handleChange={handleChange}
                                                handleBlur={handleBlur}
                                                name={`toBeApplicationsList.${index}.yearOfImplementation`}
                                                value={values.toBeApplicationsList[index].yearOfImplementation}
                                                isError={
                                                    !!(
                                                        toBeApplicationsListTouched.yearOfImplementation &&
                                                        toBeApplicationsListErrors.yearOfImplementation
                                                    )
                                                }
                                                errorText={toBeApplicationsListErrors.yearOfImplementation}
                                            />
                                        </td>

                                        <td className="px-[10px] text-center bg-red">
                                            <div
                                                className="flex justify-center cursor-pointer"
                                                onClick={() => {
                                                    ArrayHelpers.remove(index);
                                                    deleteChosenIdBuildingBlock(
                                                        values.toBeApplicationsList[index].buildingBlock?.id
                                                    );
                                                }}
                                            >
                                                <DeleteIcon />
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>

                    {buildingBlockOptions?.length > 0 &&
                        buildingBlock?.length > values?.toBeApplicationsList?.length && (
                            <div className="flex items-center mt-[20px]">
                                <div
                                    className="flex items-center cursor-pointer "
                                    onClick={() => {
                                        ArrayHelpers.push({
                                            buildingBlock: { id: 0, name: '' },
                                            companyOffices: initialValueCompanies,
                                            yearOfImplementation: '',
                                            key: generateKey(values?.toBeApplicationsList.length - 1),
                                        });
                                    }}
                                >
                                    <div className="mr-[6px] ">
                                        <AddUserIcon width={16} height={16} fill="#9BA1B2" />
                                    </div>
                                    <div className="text-int-white-secondary text-[14px]">{t('Add 1 more line')}</div>
                                </div>
                            </div>
                        )}
                </div>
            )}
        />
    );
};

export default AggregationOfYearEditTable;

AggregationOfYearEditTable.propTypes = {
    values: object.isRequired,
    errors: object.isRequired,
    touched: object.isRequired,
    handleChange: func.isRequired,
    handleBlur: func.isRequired,
    setFieldValue: func,
    tableHeaderData: arrayOf(
        shape({
            minWidth: number,
            name: string,
            isSortable: bool,
            queryName: string,
        })
    ),
    buildingBlock: arrayOf(
        shape({
            id: number,
            name: string,
        })
    ),
    initialValueCompanies: arrayOf(object).isRequired,
    dataToBeApplications: arrayOf(object).isRequired,
};
