import React, { useCallback } from 'react';
import { bool, func, shape, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from '../../style/Button/Button';
import { i18PAGES } from '../../config/i18n/constants';

const QuestionnaireHeader = (props) => {
    const { t } = useTranslation(i18PAGES.QUESTIONNAIRE);
    const { isSubmitting, handleSubmit, isReadMode, userData, setIsSubmitAnswer } = props;

    const onSubmit = useCallback(() => {
        setIsSubmitAnswer(true);
        handleSubmit();
    }, [handleSubmit, setIsSubmitAnswer]);

    return (
        <>
            <div className="flex justify-between items-center">
                <div className="flex items-end text-int-white-main">
                    <div className="text-[24px] leading-[24px] font-Lexend500">{t('Questionnaire')}</div>
                    <div className="flex items-center">
                        <div className="ml-[20px] text-[14px] leading-[19px] font-Lexend400">{userData.fullName}</div>
                        <div className="ml-[10px] text-[12px] leading-[12px] font-Lexend400 text-int-white-secondary">
                            {userData.email}
                        </div>
                    </div>
                </div>
                {!isReadMode && (
                    <div>
                        <PrimaryButton
                            handleClick={onSubmit}
                            disabled={isSubmitting}
                            className="p-[30px] h-[50px] font-Lexend300"
                            isPink
                        >
                            {t('Send results')}
                        </PrimaryButton>
                    </div>
                )}
            </div>
            <div className="mt-[30px] flex font-Lexend400 text-[14px] text-int-white-main">
                <div className="w-[600px] min-h-[50px] flex items-center px-[16px] bg-[#29323F80] rounded-[8px]">
                    Explanation on how to fill in this questionnaire
                </div>
            </div>
        </>
    );
};

export default QuestionnaireHeader;

QuestionnaireHeader.propTypes = {
    isSubmitting: bool,
    handleSubmit: func.isRequired,
    isReadMode: bool,
    userData: shape({
        fullName: string,
        email: string,
    }),
    setIsSubmitAnswer: func,
};
