import { array, number, object, string } from 'yup';
import { currentYear } from '../../SetupCompanies/stepValidation';

export const toBeApplicationsValidation = object().shape({
    toBeApplicationsList: array().of(
        object().shape({
            buildingBlock: object().shape({
                id: number(),
                name: string().required('Building block should be select'),
            }),
            companyOffices: array()
                .of(object())
                .test(
                    'not checked offices',
                    'Offices should be select',
                    (value) =>
                        value
                            .map((company) => ({
                                ...company,
                                officeList: company.officeList.filter((office) => office.isChosen === true),
                            }))
                            .filter((company) => company.officeList.length > 0).length !== 0
                ),
            yearOfImplementation: string()
                .test('Is positive?', 'Invalid year value', (value) => Number(value) > 0)
                .test('Is 4 symbols', 'Invalid year value', (value) => value?.length === 4)
                .test('Is allowed year', 'Invalid year value', (value) => Number(value) >= currentYear),
        })
    ),
});
