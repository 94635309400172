export const MODULE_STATUS = {
    INIT: 'INIT',
    ADDED: 'ADDED',
    BOUGHT: 'BOUGHT',
};

export const defineModuleType = (status, moduleInfo, array) => {
    if (status === MODULE_STATUS.BOUGHT) {
        return status;
    }

    if (array?.some((e) => e.moduleId === moduleInfo.moduleId)) {
        return MODULE_STATUS.ADDED;
    }

    return MODULE_STATUS.INIT;
};
