import React from 'react';
import { Form, Formik } from 'formik';
import { object, ref, string } from 'yup';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import FInput from '../style/Input/Input';
import { PrimaryButton } from '../style/Button/Button';
import FormLayout from '../layouts/FormLayout';
import { i18PAGES } from '../config/i18n/constants';

const validationSchema = object({
    password: string().required('Password should be stated'),
    password_confirmation: string()
        .required('Passwords do not match')
        .oneOf([ref('password'), null], 'Passwords do not match'),
});

const SetPasswordPage = () => {
    const { token } = useParams();

    const { t } = useTranslation(i18PAGES.AUTH);

    return (
        <div className="min-h-[calc(100vh-300px)] h-[100%] flex items-center justify-center">
            <FormLayout
                subText="Bitte geben Sie die Ihrem Konto zugeordnete E-Mail-Adresse ein, um Anweisungen zum Zurücksetzen des Passworts zu erhalten"
                mainText="Erstelle Ein Neues Passwort"
                width={500}
            >
                <Formik
                    onSubmit={(values, { setErrors, setSubmitting }) => {
                        setSubmitting(false);
                    }}
                    validationSchema={validationSchema}
                    initialValues={{
                        password: '',
                        password_confirmation: '',
                    }}
                >
                    {({
                        values,
                        touched,
                        errors,
                        isSubmitting,
                        handleSubmit,
                        handleBlur,
                        handleChange,
                        setFieldValue,
                    }) => (
                        <Form onSubmit={handleSubmit}>
                            <FInput
                                name="password"
                                placeholder="Passwort eingeben"
                                value={values.password}
                                handleBlur={handleBlur}
                                handleChange={handleChange}
                                className="w-[440px] mt-[40px]"
                                type="password"
                                label="Passwort"
                                isError={!!(touched.password && errors.password)}
                                errorText={errors.password}
                                isPassword
                                setFieldValue={setFieldValue}
                            />

                            <FInput
                                name="password_confirmation"
                                placeholder="Passwort wiederholen"
                                value={values.password_confirmation}
                                handleBlur={handleBlur}
                                handleChange={handleChange}
                                className="w-[440px] mt-[40px]"
                                type="password"
                                label="Passwort wiederholen"
                                isError={!!(touched.password_confirmation && errors.password_confirmation)}
                                errorText={errors.password_confirmation}
                                isPassword
                                setFieldValue={setFieldValue}
                            />

                            <PrimaryButton
                                className="mt-[50px] h-[42px] w-[100%]"
                                type="submit"
                                isPink
                                disabled={isSubmitting}
                            >
                                {t('Änderungen speichern')}
                            </PrimaryButton>
                        </Form>
                    )}
                </Formik>
            </FormLayout>
        </div>
    );
};

export default SetPasswordPage;
