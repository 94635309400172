import React from 'react';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { arrayOf, func, shape, number, string } from 'prop-types';
import Checkbox from '../../style/Checkbox/Checkbox';
import { i18PAGES } from '../../config/i18n/constants';

const HRToolsFilterBlock = ({ setFilterData, hrAllBuildingBlocks }) => {
    const { t } = useTranslation(i18PAGES.HR_TOOLS);

    return (
        <div className="bg-int-gray-main rounded-lg mb-[24px] px-[20px] pt-[20px] pb-[10px] shadow-filters ">
            <div className="flex flex-row justify-between items-center">
                <div className="text-int-white-main text-[18px] font-Lexend600">{t('Filter')}</div>
            </div>
            <div className="flex flex-col mt-[10px] max-h-[515px] overflow-auto mr-[-15px]" id="primary-scroll">
                <Formik
                    onSubmit={(values, { setSubmitting }) => {
                        setSubmitting(false);
                        setFilterData(values);
                    }}
                    initialValues={hrAllBuildingBlocks?.map((item) => ({ ...item, isChecked: false }))}
                    enableReinitialize
                >
                    {({ values, handleSubmit }) => (
                        <Form>
                            {values.map((item, index) => (
                                <Checkbox
                                    key={item.id}
                                    id={`${index}.isChecked`}
                                    name={`${index}.isChecked`}
                                    label={item.name}
                                    value={values[index].isChecked}
                                    handleClick={handleSubmit}
                                />
                            ))}
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default HRToolsFilterBlock;

HRToolsFilterBlock.propTypes = {
    setFilterData: func,
    hrAllBuildingBlocks: arrayOf(
        shape({
            id: number,
            name: string,
        })
    ),
};
