import { func, oneOf } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ReactComponent as AddedModuleButtonIcon } from '../../assets/icons/addedModuleButtonIcon.svg';
import { ReactComponent as AddModuleButtonIcon } from '../../assets/icons/addModuleButtonIcon.svg';
import { i18PAGES } from '../../config/i18n/constants';
import { MODULE_STATUS } from './helpers';

const ModulesCardButton = ({ type, handleClick }) => {
    const { t } = useTranslation(i18PAGES.MODULES);

    if (type === MODULE_STATUS.INIT) {
        return (
            <div
                aria-hidden="true"
                onClick={handleClick}
                className="cursor-pointer flex justify-center items-center min-w-[101px] h-[42px] rounded-lg text-[16px]
                 text-int-grapefruit border border-int-grapefruit font-Lexend500 hover:opacity-[0.8]"
            >
                <AddModuleButtonIcon />
                <div className="ml-[10px]">{t('Add')}</div>
            </div>
        );
    }

    if (type === MODULE_STATUS.ADDED) {
        return (
            <div
                aria-hidden="true"
                onClick={handleClick}
                className="cursor-pointer flex justify-center items-center min-w-[101px] h-[42px] bg-int-gray-secondary
                rounded-lg text-[16px] text-int-white-secondary font-Lexend500 hover:opacity-[0.8]"
            >
                <AddedModuleButtonIcon />
                <div className="ml-[10px]">{t('Added')}</div>
            </div>
        );
    }

    if (type === MODULE_STATUS.BOUGHT) {
        return (
            <div
                aria-hidden="true"
                onClick={handleClick}
                className="flex justify-center items-center min-w-[101px] h-[42px]
                 bg-[#2A395D] rounded-lg text-[16px] text-[#5679F7] font-Lexend500"
            >
                {t('Bought')}
            </div>
        );
    }

    return false;
};

ModulesCardButton.propTypes = {
    type: oneOf(['INIT', 'ADDED', 'BOUGHT']).isRequired,
    handleClick: func,
};

export default ModulesCardButton;
