import React, { useState } from 'react';
import { string, number } from 'prop-types';
import { stringShorter } from '../../components/util/stringShorter';

const StringShorterTooltip = ({ string, maxLength, bgColor }) => {
    const [isOpenHelpInfo, setIsOpenHelpInfo] = useState(false);

    if (string.length <= maxLength) return string;

    return (
        <div
            className="inline-flex flex-col items-center relative cursor-pointer"
            onMouseEnter={() => setIsOpenHelpInfo(true)}
            onMouseLeave={() => setIsOpenHelpInfo(false)}
        >
            {stringShorter(string, maxLength)}
            {isOpenHelpInfo && (
                <div
                    className={`absolute ${
                        bgColor || 'bg-int-gray-secondary'
                    }   whitespace-nowrap rounded-[8px] px-[16px] py-[10px] bottom-[calc(100%+12px)] z-10`}
                >
                    {string}
                    <div
                        className={`w-[16px] h-[16px] ${
                            bgColor || 'bg-int-gray-secondary'
                        } absolute left-[calc(50%-8px)] rotate-45`}
                    />
                </div>
            )}
        </div>
    );
};

export default StringShorterTooltip;

StringShorterTooltip.propTypes = {
    string: string.isRequired,
    maxLength: number.isRequired,
    bgColor: string,
};
