import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { arrayOf, bool, number, shape, string, func } from 'prop-types';
import { RECENT_SUBSCRIPTIONS_ACTIONS_TYPE } from '../constants';
import { i18PAGES } from '../../../config/i18n/constants';
import DeleteRecentSubscriptionsModal from './DeleteRecentSubscriptionsModal';
import RecentSubscriptionsItem from './RecentSubscriptionsItem';
import RecentSubscriptionsHeader from './RecentSubscriptionsHeader';
import SubscriptionSelectDropdown from '../SubscriptionSelectDropdown';
import Preloader from '../../Preloader/Preloader';
import { useDeleteSubscriptionsMutation } from '../../../store/API/subscriptionsAPI';
import { useToast } from '../../../hoc/toast/ToastProvider';
import CurrentSubscriptionsItem from '../CurrentSubscriptions/CurrentSubscriptionsItem';

const RecentSubscriptionsTable = ({
    recentSubscriptions,
    isLoadingSubscriptionsList,
    refetchSubscriptionsList,
    renewSubscriptionsApi,
}) => {
    const { t } = useTranslation(i18PAGES.SUBSCRIPTION_MANAGEMENT);
    const { successToast, errorToast } = useToast();
    const [isOpenDeleteRecentSubscription, setIsOpenDeleteRecentSubscription] = useState(false);
    const [isOpenDeleteSelectedRecentSubscription, setIsOpenDeleteSelectedRecentSubscription] = useState(false);
    const [deleteRecentSubscriptionId, setDeleterecentSubscriptionId] = useState(0);
    const [actionType, setActionType] = useState(RECENT_SUBSCRIPTIONS_ACTIONS_TYPE.RENEW);
    const [isLoading, setIsLoading] = useState(false);

    const [deleteSubscriptions] = useDeleteSubscriptionsMutation();

    const openDeleteModal = (id) => {
        setDeleterecentSubscriptionId(id);
        setIsOpenDeleteRecentSubscription(true);
    };

    const deleteSubscriptionsApi = (data) => {
        setIsLoading(true);
        deleteSubscriptions({
            body: {
                subscriptions: data,
            },
        })
            .unwrap()
            .then(() => {
                successToast(t('Subscription delete success'));
                setIsOpenDeleteRecentSubscription(false);
                setIsOpenDeleteSelectedRecentSubscription(false);
                refetchSubscriptionsList();
            })
            .catch(() => {
                errorToast(t('Subscription delete error'));
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <div className="w-[1020px] p-[30px] bg-int-gray-main rounded-lg mb-[30px]">
            <Formik
                onSubmit={(values, { setSubmitting }) => {
                    const checkedModulesIdArray = values
                        .filter((module) => module.isChoosed === true)
                        .map((module) => module.subscriptionId);

                    if (actionType === RECENT_SUBSCRIPTIONS_ACTIONS_TYPE.RENEW) {
                        renewSubscriptionsApi(checkedModulesIdArray);
                    }
                    if (actionType === RECENT_SUBSCRIPTIONS_ACTIONS_TYPE.DELETE) {
                        deleteSubscriptionsApi(checkedModulesIdArray);
                    }
                    setSubmitting(false);
                }}
                initialValues={recentSubscriptions?.map((module) => ({ ...module, isChoosed: false }))}
                enableReinitialize
            >
                {({ values, handleSubmit, setFieldValue }) => {
                    const selectAllModules = () => {
                        values.map((module, index) => setFieldValue(`${index}.isChoosed`, true));
                    };

                    const noneSelectAllModules = () => {
                        values.map((module, index) => setFieldValue(`${index}.isChoosed`, false));
                    };

                    const tableDataRender = () => {
                        if (isLoadingSubscriptionsList)
                            return (
                                <div className="h-[70px] flex items-center justify-center">
                                    <Preloader />
                                </div>
                            );
                        if (values?.length === 0)
                            return (
                                <div className="flex items-center justify-center pt-[20px] text-[16px]">
                                    {t(`You have no expired subscriptions. All the subscriptions are active`)}
                                </div>
                            );
                        return values?.map((item, index) => (
                            <RecentSubscriptionsItem
                                index={index}
                                length={values.length}
                                handleRenew={renewSubscriptionsApi}
                                openDeleteModal={openDeleteModal}
                                key={index}
                                values={values}
                            />
                        ));
                    };

                    return (
                        <Form>
                            <div className=" text-int-white-main mb-[40px] flex justify-between items-center">
                                <div className="text-[24px] leading-[24px] font-Lexend500">
                                    {t('Recent subscriptions')}
                                </div>
                                <div className="flex items-center text-[14px] leading-[18px] font-Lexend500 text-int-white-main">
                                    {values?.filter((i) => i.isChoosed === true).length > 0 && (
                                        <>
                                            <div
                                                className="cursor-pointer"
                                                onClick={() => {
                                                    setActionType(RECENT_SUBSCRIPTIONS_ACTIONS_TYPE.RENEW);
                                                    handleSubmit();
                                                }}
                                            >
                                                {t('Renew')}
                                            </div>
                                            <div
                                                className="cursor-pointer text-int-grapefruit mx-[20px]"
                                                onClick={() => {
                                                    setIsOpenDeleteSelectedRecentSubscription(true);
                                                }}
                                            >
                                                {t('Delete')}
                                            </div>
                                        </>
                                    )}

                                    {values?.length > 0 && (
                                        <SubscriptionSelectDropdown
                                            handleSelectAll={selectAllModules}
                                            handleNonSelectAll={noneSelectAllModules}
                                            disableAllItem={values?.filter((i) => i.isChoosed === false).length === 0}
                                            disableNotAllItem={values?.filter((i) => i.isChoosed === true).length === 0}
                                        />
                                    )}
                                </div>
                            </div>
                            <RecentSubscriptionsHeader />
                            {tableDataRender()}
                            <DeleteRecentSubscriptionsModal
                                isOneType
                                isOpenDeleteRecentSubscription={isOpenDeleteRecentSubscription}
                                setIsOpenDeleteRecentSubscription={setIsOpenDeleteRecentSubscription}
                                id={deleteRecentSubscriptionId}
                                handleDeleteClickOne={deleteSubscriptionsApi}
                                isLoading={isLoading}
                            />
                            <DeleteRecentSubscriptionsModal
                                isOneType={false}
                                isOpenDeleteRecentSubscription={isOpenDeleteSelectedRecentSubscription}
                                setIsOpenDeleteRecentSubscription={setIsOpenDeleteSelectedRecentSubscription}
                                handleDeleteClickTwo={() => {
                                    setActionType(RECENT_SUBSCRIPTIONS_ACTIONS_TYPE.DELETE);
                                    handleSubmit();
                                }}
                                isLoading={isLoading}
                            />
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
};

export default RecentSubscriptionsTable;

RecentSubscriptionsTable.propTypes = {
    recentSubscriptions: arrayOf(
        shape({
            status: string,
            endDate: string,
            moduleId: number,
            moduleName: string,
            startDate: string,
            subscriptionId: number,
        })
    ),
    isLoadingSubscriptionsList: bool.isRequired,
    refetchSubscriptionsList: func.isRequired,
    renewSubscriptionsApi: func.isRequired,
};
