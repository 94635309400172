import React, { useEffect } from 'react';
import { node } from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../store/hooks/useAuth';
import http, { AUTH_TOKEN } from '../../components/util/http';
import { PageLoader } from '../../components/Preloader/Preloader';
import { usePlatformEditMode } from '../../store/hooks/usePlatformEditMode';

const AuthWrapper = ({ children }) => {
    const token = localStorage.getItem(AUTH_TOKEN);

    const navigate = useNavigate();
    const location = useLocation();
    const { setPlatformEditMode } = usePlatformEditMode();

    const {
        setPending,
        setAuth,
        setProfileData,
        isPending,
        isPermission,
        setIsPermission,
        isServerError,
        setServerError,
    } = useAuth();

    useEffect(() => {
        if (!token) {
            setAuth(false);
            setPending(false);
        } else {
            http.get(`/principal`)
                .then((res) => {
                    setProfileData(res.data);
                    setAuth(true);
                })
                .catch((err) => {
                    if (err?.status >= 500) {
                        navigate('/server-error');
                    }
                })
                .finally(() => {
                    setPending(false);
                });
        }
    }, [token]);

    useEffect(() => {
        setPlatformEditMode(false);
    }, [location.pathname]);

    useEffect(() => {
        if (isPermission === false) {
            setIsPermission(true);
            navigate('/not-available');
        }
    }, [isPermission, navigate, setIsPermission]);

    useEffect(() => {
        if (isServerError) {
            setServerError(false);
            navigate('/server-error');
        }
    }, [isServerError, navigate, setServerError]);

    if (isPending) {
        return <PageLoader />;
    }

    return <div>{children}</div>;
};

export default AuthWrapper;

AuthWrapper.propTypes = {
    children: node,
};
