import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { number } from 'prop-types';
import AddButton from '../../AddButton';
import { i18PAGES } from '../../../../config/i18n/constants';
import AddExternalUserModalForm from './AddExternalUserModalForm';

const ExternalAddButtonContent = ({ chosenProjectId }) => {
    const { t } = useTranslation(i18PAGES.PROJECTS_MANAGEMENT);

    const [isAddFormOpened, setIsAddFormOpened] = useState(false);

    return (
        <div>
            <AddButton handleClick={() => setIsAddFormOpened(true)}>{t('Add External User')}</AddButton>
            <AddExternalUserModalForm
                isOpened={isAddFormOpened}
                setIsOpened={setIsAddFormOpened}
                projectId={chosenProjectId}
            />
        </div>
    );
};

export default ExternalAddButtonContent;

ExternalAddButtonContent.propTypes = {
    chosenProjectId: number,
};
