import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    name: '',
    description: '',
    yearsInScope: 1,
    projectId: null,
    managerIds: [],
    companyInfo: [],
};

const createProjectSlice = createSlice({
    name: 'create-project',
    initialState,
    reducers: {
        setFirstStep(state, action) {
            return { ...state, ...action.payload };
        },
        clearProjectSetup() {
            return initialState;
        },
        setCompanies(state, action) {
            state.companyInfo = action.payload;
        },
        clearCompanies(state) {
            state.companyInfo = [];
        },
        setProjectTree(state, action) {
            return action.payload;
        },
    },
});

export const { setFirstStep, clearProjectSetup, setCompanies, clearCompanies, setProjectTree } =
    createProjectSlice.actions;

export default createProjectSlice.reducer;
