import { array, object, string } from 'yup';
import { REGEX } from '../util/constants';

export const currentYear = new Date().getFullYear();

export const step1ValidationSchema = object({
    name: string().required('Company name should be stated').max(50, 'Max length is 50 symbols'),
    countriesQuantity: string().required('Number should be selected'),
});

export const step2ValidationSchema = object().shape({
    countries: array().of(
        object().shape({
            name: string().required('Country should be selected'),
            citiesQuantity: string()
                .required('Quantity should be stated')
                .matches(REGEX.NUMBERS_ONLY, 'Quantity should be number')
                .max(3, 'Quantity should be correct'),
        })
    ),
});

export const step2InitialValues = (companySetupInfo) => {
    const countryArray = [];
    for (let i = 0; i < companySetupInfo.companyTree?.countriesQuantity; i++) {
        countryArray.push({
            name: companySetupInfo.companyTree?.countries[i]?.name || '',
            citiesQuantity: companySetupInfo?.companyTree?.countries[i]?.citiesQuantity || '',
            countryId: companySetupInfo?.companyTree?.countries[i]?.id || '',
        });
    }
    return { companyId: companySetupInfo.companyTree?.id, countries: countryArray };
};

export const step3ValidationSchema = object().shape({
    cities: array().of(
        object().shape({
            cities: array().of(
                object().shape({
                    officesQuantity: string()
                        .required('Number of offices should be stated')
                        .matches(REGEX.NUMBERS_ONLY, 'Quantity should be number')
                        .test('Is Max 7', 'Max quantity is 7', (value) => Number(value) <= 7),
                    name: string()
                        .required('City name should be stated')
                        .matches(REGEX.STRING_ONLY, 'City name should be correct')
                        .max(50, 'Max length is 50 symbols'),
                })
            ),
        })
    ),
});

export const step3InitialValues = (companySetupInfo) => {
    const citiesArray = [];
    for (let i = 0; i < companySetupInfo.companyTree?.countriesQuantity; i++) {
        const cities = [];
        for (let j = 0; j < companySetupInfo.companyTree?.countries[i]?.citiesQuantity; j++) {
            cities.push({
                name: companySetupInfo.companyTree?.countries[i]?.cities[j]?.name || '',
                officesQuantity: companySetupInfo.companyTree?.countries[i]?.cities[j]?.officesQuantity || '',
                id: companySetupInfo.companyTree?.countries[i]?.cities[j]?.id || null,
            });
        }
        citiesArray.push({
            countryName: companySetupInfo.companyTree?.countries[i]?.name || '',
            countryId: companySetupInfo.companyTree?.countries[i]?.id || null,
            cities,
        });
    }
    return citiesArray;
};

export const step4ValidationSchema = object().shape({
    offices: object({
        offices: array().of(
            object().shape({
                name: string().required('Office name should be stated').max(30, 'Max length is 30 symbols'),
                address: string().required('Address should be stated').max(50, 'Max length is 50 symbols'),
                employeeQuantityTotal: string()
                    .required('Quantity of employees should be stated')
                    .max(7, 'Invalid quantity value')
                    .matches(REGEX.NUMBERS_ONLY, 'Invalid quantity value'),
                yearFounded: string()
                    .required('Year should be stated')
                    .test('Is positive?', 'Invalid year value', (value) => Number(value) > 0)
                    .test('Is 4 symbols', 'Invalid year value', (value) => value?.length === 4)
                    .test('Is allowed year', 'Invalid year value', (value) => Number(value) <= currentYear),
                employeeQuantityWc: string()
                    .required('Quantity of white-collar employees should be stated')
                    .max(7, 'Invalid quantity value')
                    .test('Is positive?', 'Invalid quantity value', (value) => Number(value) > 0),
                employeeQuantityBc: string()
                    .required('Quantity of blue-collar employees should be stated')
                    .max(7, 'Invalid quantity value')
                    .test('Is positive?', 'Invalid quantity value', (value) => Number(value) > 0),
            })
        ),
    }),
});

export const step4InitialValues = (companySetupInfo) => {
    const resultArray = [];
    for (let i = 0; i < companySetupInfo?.companyTree?.countriesQuantity; i++) {
        for (let j = 0; j < companySetupInfo?.companyTree?.countries[i].cities?.length; j++) {
            const officesArray = [];
            for (let k = 0; k < companySetupInfo?.companyTree?.countries[i].cities[j]?.officesQuantity; k++) {
                officesArray.push({
                    id: companySetupInfo?.companyTree?.countries[i]?.cities[j]?.offices[k]?.id || null,
                    name: companySetupInfo?.companyTree?.countries[i]?.cities[j]?.offices[k]?.name || '',
                    address: companySetupInfo?.companyTree?.countries[i]?.cities[j]?.offices[k]?.address || '',
                    employeeQuantityTotal:
                        companySetupInfo?.companyTree?.countries[i]?.cities[j]?.offices[k]?.employeeQuantityTotal || '',
                    yearFounded: companySetupInfo?.companyTree?.countries[i].cities[j]?.offices[k]?.yearFounded || '',
                    employeeQuantityWc:
                        companySetupInfo?.companyTree?.countries[i]?.cities[j]?.offices[k]?.employeeQuantityWc || '',
                    employeeQuantityBc:
                        companySetupInfo?.companyTree?.countries[i]?.cities[j]?.offices[k]?.employeeQuantityBc || '',
                });
            }

            resultArray.push({
                cityId: companySetupInfo.companyTree?.countries[i]?.cities[j]?.id || null,
                cityName: companySetupInfo.companyTree?.countries[i]?.cities[j]?.name || '',
                countryName: companySetupInfo.companyTree?.countries[i]?.name || '',
                offices: officesArray,
            });
        }
    }

    return resultArray;
};
