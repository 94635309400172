import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import './index.css';
import './fonts/fonts.css';
import 'react-loading-skeleton/dist/skeleton.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './store/store';
import AuthWrapper from './hoc/auth/AuthWrapper';
import { ToastProvider } from './hoc/toast/ToastProvider';
import ProjectRolesProvider from './hoc/projectRoles/ProjectRolesProvider';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <Provider store={store}>
            <AuthWrapper>
                <ProjectRolesProvider>
                    <ToastProvider>
                        <App />
                    </ToastProvider>
                </ProjectRolesProvider>
            </AuthWrapper>
        </Provider>
    </BrowserRouter>
);

reportWebVitals();
