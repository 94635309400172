import React from 'react';
import { func, number, object } from 'prop-types';
import TextArea from '../../../../../style/TextArea/TextArea';
import { ReactComponent as DeleteIcon } from '../../../../../assets/icons/userManagementBigDeleteIcon.svg';

const ActionsEditItem = ({
    index,
    values,
    handleBlur,
    handleChange,
    actionsTouched,
    actionsErrors,
    setFieldValue,
    ArrayHelpers,
}) => (
    <div className="flex mb-[10px]">
        <div className="flex items-center">
            <TextArea
                label=""
                handleChange={handleChange}
                name={`actions.${index}.action`}
                value={values?.actions[index]?.action || ''}
                handleBlur={handleBlur}
                isError={!!(actionsTouched.action && actionsErrors.action)}
                errorText={actionsErrors.action}
                className="w-[370px] mr-[10px]"
                height={84}
                setFieldValue={setFieldValue}
                limit={100}
            />
            <TextArea
                label=""
                handleChange={handleChange}
                name={`actions.${index}.description`}
                value={values?.actions[index]?.description || ''}
                handleBlur={handleBlur}
                isError={!!(actionsTouched.description && actionsErrors.description)}
                errorText={actionsErrors.description}
                className="w-[990px] mr-[10px]"
                height={84}
                setFieldValue={setFieldValue}
                limit={500}
            />

            <div
                className="flex justify-center items-center w-[20px] h-[42px] mr-[12px] cursor-pointer ml-[13px]"
                onClick={() => ArrayHelpers.remove(index)}
            >
                <DeleteIcon />
            </div>
        </div>
    </div>
);

export default ActionsEditItem;

ActionsEditItem.propTypes = {
    index: number.isRequired,
    values: object.isRequired,
    handleBlur: func.isRequired,
    handleChange: func.isRequired,
    actionsTouched: object.isRequired,
    actionsErrors: object.isRequired,
    ArrayHelpers: object.isRequired,
    setFieldValue: func.isRequired,
};
