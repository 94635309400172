import { useEffect } from 'react';
import { usePlatformEditMode } from '../../store/hooks/usePlatformEditMode';

export const useEditMode = (editMode) => {
    const { setPlatformEditMode } = usePlatformEditMode();

    useEffect(() => {
        setPlatformEditMode(editMode);
    }, [editMode]);
};
