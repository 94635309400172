import React, { useState } from 'react';
import { arrayOf, shape, string } from 'prop-types';

const OtherModulesHelper = ({ notVisibleModules }) => {
    const [isHoverHelpArea, setIsHoverHelpArea] = useState(false);
    const [isHoverMainArea, setIsHoverMainArea] = useState(false);

    return (
        <div className="relative">
            {notVisibleModules?.length > 0 && (
                <div
                    className="w-[22px] h-[22px] rounded-full border border-int-white-main flex justify-center items-center cursor-pointer relative"
                    onMouseEnter={() => {
                        setIsHoverHelpArea(true);
                    }}
                >
                    <div className="text-[10px]">+</div>
                    <div className="text-[11px]">{notVisibleModules.length}</div>
                </div>
            )}

            {(isHoverHelpArea || isHoverMainArea) && (
                <div className="w-[221px] h-[315px] absolute left-[-107px] top-[-292px] cursor-pointer">
                    <div
                        className="p-[16px] w-[219px] h-[278px] bg-int-gray-secondary rounded-[8px]
                                     text-[14px] leading-[150%] font-Lexend400 text-int-white-main z-40 text-center border border-int-white-secondary"
                        onMouseEnter={() => {
                            setIsHoverMainArea(true);
                        }}
                        onMouseLeave={() => {
                            setIsHoverMainArea(false);
                        }}
                    >
                        <div
                            className="max-h-[246px] h-[246px] overflow-y-auto overflow-x-hidden mr-[-9px] pr-[9px] flex flex-col items-start text-left"
                            id="help-scroll"
                        >
                            {notVisibleModules?.map((module) => (
                                <div key={module.title}>
                                    <div className="text-[12px] leading-[16px] font-Lexend400 tracking-[1px] mb-[6px]">
                                        {module.title}
                                    </div>
                                    <div className="text-[12px] leading-[16px] font-Lexend300 tracking-[1px]">
                                        {module?.buildingBlocks?.map((bb) => (
                                            <div className="flex items-center my-[2px]" key={bb.name}>
                                                <div className="min-w-[4px] min-h-[4px] rounded-full bg-int-grapefruit mr-[12px]" />
                                                <div>{bb.name}</div>
                                            </div>
                                        ))}
                                        <div className="min-h-[1px] w-[100%] bg-[#5E6876] opacity-50 my-[10px]" />
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="w-[16px] h-[16px] bg-int-gray-secondary absolute bottom-[29px] left-1/2 rotate-45 border-r border-b border-int-white-secondary" />
                        <div
                            className="w-[60px] h-[60px] absolute bottom-[8px] left-[88px] rotate-45"
                            onMouseEnter={() => {
                                setIsHoverHelpArea(true);
                            }}
                            onMouseLeave={() => {
                                setIsHoverHelpArea(false);
                            }}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default OtherModulesHelper;

OtherModulesHelper.propTypes = {
    notVisibleModules: arrayOf(
        shape({
            title: string,
            buildingBlocks: arrayOf(
                shape({
                    name: string,
                })
            ),
        })
    ),
};
