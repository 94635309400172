import React, { useEffect, useMemo, useState } from 'react';
import { Formik } from 'formik';
import { arrayOf, bool, func, number, object, string } from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { COMPANY_STATUS, SETUP_PAGES, STEP_TYPE } from '../util/constants';
import StepLayout from '../SetupCompanies/StepLayout';
import { ReactComponent as ModalWarningIcon } from '../../assets/icons/modalWarningIcon.svg';
import ToastWindow from '../../style/ToastWindow/ToastWindow';
import { useEditLinkOfficesProjectMutation } from '../../store/API/createProjectAPI';
import { useGetProjectBoughtUserModulesListQuery } from '../../store/API/userModulesAPI';
import { i18PAGES } from '../../config/i18n/constants';
import ChangeStepInfoModalContent from '../SetupCompanies/ChangeStepInfoModalContent';
import i18n from '../../config/i18n/i18n';
import ModalWindow from '../../style/ModalWindow/ModalWindow';
import { useToast } from '../../hoc/toast/ToastProvider';
import { useGetCompaniesEditProjectQuery } from '../../store/API/companiesAPI';
import Step3FormProject from '../CreateProject/Step3CreateProject/Step3FormProject';
import {
    checkedOfficesWithCountCheckedModulesArrValidation,
    dataSendOfficeModules,
} from '../CreateProject/Step3CreateProject/step3Constants';

const errorText = 'Building blocks that are Sent to Providers can not be edited for project';

const Step3EditProject = ({
    setSetupCurrentPage,
    projectData,
    projectId,
    isDisabledFields,
    allProvidersSubmitted,
    sentBuildingBlocks,
    projectStatus,
}) => {
    const { t } = useTranslation(i18PAGES.PROJECTS_SETUP);
    const { errorToast } = useToast();
    const navigate = useNavigate();

    const [initialValueCompany, setInitialValueCompany] = useState([]);
    const [isOpenWarning, setIsOpenWarning] = useState(false);
    const [currentTab, setCurrentTab] = useState(0);
    const [isBackButtonPressed, setIsBackButtonPressed] = useState(false);
    const [isStepBackAgree, setIsStepBackAgree] = useState(false);

    const { data: boughtUserModulesList, isLoading: isLoadingBoughtUserModulesList } =
        useGetProjectBoughtUserModulesListQuery(projectId, { skip: !projectId });

    const { data: company, isLoading: isLoadingCompany } = useGetCompaniesEditProjectQuery(
        { projectId },
        { refetchOnMountOrArgChange: true, skip: !projectId }
    );

    const [editLinkOfficesProject] = useEditLinkOfficesProjectMutation();

    const onBackButtonPress = () => {
        setIsBackButtonPressed(true);
    };

    useEffect(() => {
        if (isStepBackAgree === true) {
            setSetupCurrentPage(SETUP_PAGES.STEP_2);
        }
    }, [isStepBackAgree, setSetupCurrentPage]);

    const activeCompaniesIdArray = useMemo(() => {
        if (projectData?.companies?.length) {
            return projectData?.companies?.map((c) => c.id);
        }
    }, [projectData]);

    const activeOfficesIdArray = useMemo(() => {
        if (projectData?.offices?.length) {
            return projectData?.offices?.map((office) => office.id);
        }
    }, [projectData]);

    const activeModulesIdByOfficeIdArray = useMemo(() => {
        if (projectData?.offices?.length) {
            return projectData?.offices?.map((office) => ({
                id: office.id,
                moduleIdArray: office.moduleBuildingBlocks
                    .map((module) =>
                        module.buildingBlocks.filter((block) => block.selected === true).map((block) => block.id)
                    )
                    .flat(),
            }));
        }
    }, [projectData]);

    useEffect(() => {
        const convertCompanyArray = company
            ?.filter((c) => c.status === COMPANY_STATUS.COMPLETED && activeCompaniesIdArray.includes(c.id))
            ?.map((company) => ({
                ...company,
                countries: company.countries.map((country) => ({
                    ...country,
                    offices: [
                        ...country.cities.map((city) =>
                            city.offices.map((office) => ({
                                ...office,
                                city: office?.cityDetails.name,
                                isChecked: activeOfficesIdArray.includes(office.id),
                                officeModules: boughtUserModulesList?.map((modules) => ({
                                    moduleId: modules.moduleId,
                                    title: modules.title,
                                    buildingBlocks: modules.buildingBlocks.map((bb) => ({
                                        id: bb.id,
                                        name: bb.name,
                                        selected: activeModulesIdByOfficeIdArray
                                            .find((block) => block.id === office.id)
                                            ?.moduleIdArray?.includes(bb.id),
                                    })),
                                })),
                            }))
                        ),
                    ].flat(),
                })),
            }));

        setInitialValueCompany(convertCompanyArray);
    }, [company, boughtUserModulesList, projectData]);

    const handleTabChoose = (tabIndex) => {
        setCurrentTab(tabIndex);
    };

    const goToProjectList = () => {
        navigate(`/projects-list?projectId=${projectId}`);
        localStorage.setItem('isProjectEditSuccess', 'true');
    };

    return (
        <StepLayout currentStepNumber={3} height={0} type={STEP_TYPE.PROJECT}>
            <Formik
                onSubmit={(values, { setSubmitting }) => {
                    const checkedOfficesWithCountCheckedModulesArr = checkedOfficesWithCountCheckedModulesArrValidation(
                        values?.companies
                    );

                    const officeModules = dataSendOfficeModules(values?.companies);

                    const dataSendModule = {
                        projectId: Number(projectId),
                        officeModules,
                    };

                    if (allProvidersSubmitted) {
                        goToProjectList();
                    } else if (!checkedOfficesWithCountCheckedModulesArr.includes(false)) {
                        editLinkOfficesProject(dataSendModule)
                            .unwrap()
                            .then(() => {
                                goToProjectList();
                            })
                            .catch((err) => {
                                if (err?.data?.message?.includes(errorText)) {
                                    errorToast(errorText, 4000);
                                } else {
                                    errorToast(err?.data?.message || 'Something went wrong, try again later');
                                }
                            })
                            .finally(() => {
                                setSubmitting(false);
                            });
                    } else {
                        setSubmitting(false);
                        setIsOpenWarning(true);
                        setTimeout(() => {
                            setIsOpenWarning(false);
                        }, 3000);
                    }
                }}
                initialValues={{
                    companies: initialValueCompany,
                }}
                enableReinitialize
            >
                {({ values, isSubmitting, handleSubmit, setFieldValue }) => (
                    <Step3FormProject
                        onBackButtonPress={onBackButtonPress}
                        setFieldValue={setFieldValue}
                        values={values}
                        isSubmitting={isSubmitting}
                        isLoading={isLoadingBoughtUserModulesList || isLoadingCompany}
                        handleSubmit={handleSubmit}
                        handleTabChoose={handleTabChoose}
                        currentTab={currentTab}
                        isDisabledAllInForm={isDisabledFields}
                        allProvidersSubmitted={allProvidersSubmitted}
                        sentBuildingBlocks={sentBuildingBlocks}
                        projectStatus={projectStatus}
                    />
                )}
            </Formik>
            <ToastWindow
                isOpen={isOpenWarning}
                isShowCloseIcon
                handleCloseIconClick={() => {
                    setIsOpenWarning(false);
                }}
            >
                <div className="flex flex-col items-center justify-center text-int-white-main">
                    <div className="flex items-center text-[16px] font-Lexend500">
                        <ModalWarningIcon />
                        <div className="ml-[10px]">{t('No selected elements')}</div>
                    </div>
                    <div className="text-[14px]">{t('Select offices and modules to go to the next step')}</div>
                </div>
            </ToastWindow>

            <ModalWindow isOpen={isBackButtonPressed} setIsOpen={setIsBackButtonPressed}>
                <ChangeStepInfoModalContent
                    setIsAgree={setIsStepBackAgree}
                    setIsModalOpened={setIsBackButtonPressed}
                    text={i18n.t('Are you sure you want to go back? Your current data can be lost')}
                />
            </ModalWindow>
        </StepLayout>
    );
};
export default Step3EditProject;

Step3EditProject.propTypes = {
    setSetupCurrentPage: func,
    projectData: object,
    projectId: string,
    isDisabledFields: bool,
    allProvidersSubmitted: bool,
    sentBuildingBlocks: arrayOf(number),
    projectStatus: string,
};
