import React from 'react';
import { useTranslation } from 'react-i18next';
import { object } from 'prop-types';
import { i18PAGES } from '../../../../config/i18n/constants';
import { ReactComponent as AddIcon } from '../../../../assets/icons/moreIcon.svg';

const ModulesStrategyAddLineButton = ({ ArrayHelpers, pushObject }) => {
    const { t } = useTranslation(i18PAGES.MODULES_AND_STRATEGY);

    return (
        <div className="inline-flex items-center cursor-pointer" onClick={() => ArrayHelpers.push(pushObject)}>
            <div className="mr-[6px]">
                <AddIcon width={14} height={14} fill="#9BA1B2" />
            </div>
            <div className="text-int-white-secondary">{t('Add line')}</div>
        </div>
    );
};

export default ModulesStrategyAddLineButton;

ModulesStrategyAddLineButton.propTypes = {
    ArrayHelpers: object.isRequired,
    pushObject: object.isRequired,
};
