import React from 'react';
import { bool, func, string } from 'prop-types';

const PhotoItem = ({ url, isActive, chooseImage }) => (
    <img
        onClick={chooseImage}
        className={`cursor-pointer ${!isActive && 'opacity-[0.5]'}`}
        width={70}
        height={50}
        src={url}
        alt="#"
    />
);

export default PhotoItem;

PhotoItem.propTypes = {
    url: string,
    isActive: bool,
    chooseImage: func,
};
