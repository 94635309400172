import { useDispatch, useSelector } from 'react-redux';
import {
    clearCities,
    clearCompanySetup,
    clearCompanySetupState,
    clearOffices,
    setCities,
    setCompanyTree,
    setCountries,
    setFirstStep,
    setOffices,
} from '../reducers/companySetupReducer';

export const useCompanySetup = () => {
    const dispatch = useDispatch();

    const companySetupInfo = useSelector((state) => state.companySetup);

    const _setCompanyTree = (payload) => {
        dispatch(setCompanyTree(payload));
    };

    const _setFirstStep = (payload) => {
        dispatch(setFirstStep(payload));
    };

    const _setCountries = (countriesArray) => {
        dispatch(setCountries(countriesArray));
    };
    const _setCities = (citiesArray) => {
        dispatch(setCities(citiesArray));
    };

    const _setOffices = (officesArray) => {
        dispatch(setOffices(officesArray));
    };

    const _clearCompanySetup = () => {
        dispatch(clearCompanySetup());
    };
    const _clearCities = () => {
        dispatch(clearCities());
    };
    const _clearOffices = () => {
        dispatch(clearOffices());
    };
    const _clearCompanySetupState = () => {
        dispatch(clearCompanySetupState());
    };

    return {
        companySetupInfo,
        setCompanyTree: _setCompanyTree,
        setFirstStep: _setFirstStep,
        setCountries: _setCountries,
        clearCompanySetup: _clearCompanySetup,
        setCities: _setCities,
        setOffices: _setOffices,
        clearCities: _clearCities,
        clearOffices: _clearOffices,
        clearCompanySetupState: _clearCompanySetupState,
    };
};
