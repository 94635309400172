import React, { useMemo } from 'react';
import { number, string } from 'prop-types';
import { useNavigate } from 'react-router-dom';
import Comments from '../../Comments/Comments';
import { PrimaryButton } from '../../../style/Button/Button';
import i18n from '../../../config/i18n/i18n';
import { getNextProjectPageLink } from '../../util/getNextProjectPageLink';
import { useTabsMenu } from '../../../store/hooks/useTabsMenu';

const ProjectInfoPageHeader = ({ pageName, pageIdentifier, subPageIdentifier, projectId, nextLink }) => {
    const navigate = useNavigate();
    const { tabsMenuData } = useTabsMenu();

    const nextPageLink = useMemo(
        () => getNextProjectPageLink(tabsMenuData, pageIdentifier, projectId),
        [pageIdentifier, projectId, tabsMenuData]
    );

    const navigateToNext = () => {
        navigate(nextPageLink || nextLink);
    };

    return (
        <div className="flex items-center justify-between mb-[30px]">
            <div className="font-Lexend500 text-[24px] leading-[24px]">{pageName}</div>

            <div className="flex items-center">
                <Comments pageIdentifier={pageIdentifier} projectId={projectId} subPageIdentifier={subPageIdentifier} />

                <PrimaryButton
                    className="w-[170px] h-[50px] ml-[60px]"
                    disabled={!nextPageLink}
                    isGrey
                    handleClick={navigateToNext}
                >
                    <div className="text-[18px] font-Lexend400">{i18n.t('Next')}</div>
                </PrimaryButton>
            </div>
        </div>
    );
};

export default ProjectInfoPageHeader;
ProjectInfoPageHeader.propTypes = {
    pageName: string.isRequired,
    pageIdentifier: string.isRequired,
    subPageIdentifier: string,
    projectId: number,
    nextLink: string,
};
