export const MODULES_AND_PAGES_CHILDREN = {
    PROBLEMS_OVERVIEW: 'PROBLEMS_OVERVIEW',
    WEAKNESSES_AND_EXPECTATIONS: 'WEAKNESSES_AND_EXPECTATIONS',
    ACTIONS: 'ACTIONS',
    HR_DIGITALIZATION: 'HR_DIGITALIZATION',
    GOALS: 'GOALS',
    HR_STRATEGY: 'HR_STRATEGY',
    CHALLENGES: 'CHALLENGES',
};

export const SELECT_PROVIDER_CHILDREN = {
    SELECT_MODULES_BUILDING_BLOCKS: 'SELECT_MODULES_BUILDING_BLOCKS',
    MODULES_COVERAGE_BY_PROVIDER: 'MODULES_COVERAGE_BY_PROVIDER',
};
