export const ROLES = {
    ROLE_ADMIN: 'ROLE_ADMIN',
    ROLE_USER: 'ROLE_USER',
    ROLE_OWNER: 'ROLE_OWNER',
    NOT_COMPLETED_USER: 'NOT_COMPLETED_USER',
};

export const getRole = (roleArray) => {
    if (roleArray.includes(ROLES.ROLE_OWNER)) {
        return ROLES.ROLE_ADMIN;
    }
    if (roleArray.includes(ROLES.ROLE_ADMIN)) {
        return ROLES.ROLE_ADMIN;
    }

    return ROLES.ROLE_USER;
};
