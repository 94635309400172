import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { object, string } from 'yup';
import { bool } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from '../../style/Button/Button';
import FInput from '../../style/Input/Input';
import { REGEX } from '../util/constants';
import OnlyViewFieldInput from './OnlyViewFieldInput';
import { useAuth } from '../../store/hooks/useAuth';
import { useEditProfileInfoMutation, useProfileInfoQuery } from '../../store/API/profileAPI';
import Preloader from '../Preloader/Preloader';
import { i18PAGES } from '../../config/i18n/constants';
import { useToast } from '../../hoc/toast/ToastProvider';

const validationSchema = object({
    fullName: string().required('Full name should be stated').max(50, 'Max length is 50 symbols'),
    phone: string()
        .required('Phone number should be stated')
        .matches(REGEX.PHONE, 'Invalid phone number')
        .min(6, 'Invalid phone number'),
});

const EditProfileInformation = ({ adminView = false }) => {
    const { t } = useTranslation(i18PAGES.PROFILE);
    const { successToast, errorToast } = useToast();

    const [isEdit, setIsEdit] = useState(false);
    const [startValues, setStartValues] = useState({});
    const { profileData } = useAuth();
    const [editProfileInfo] = useEditProfileInfoMutation();

    const {
        data: profileInfo,
        isLoading,
        isFetching,
        refetch,
    } = useProfileInfoQuery({}, { refetchOnMountOrArgChange: true });

    useEffect(() => {
        setStartValues(profileInfo);
    }, [profileInfo]);

    return (
        <div className="bg-int-gray-main rounded-lg px-[40px] pt-[20px] pb-[20px] mb-[30px]">
            <Formik
                onSubmit={(values, { setSubmitting }) => {
                    if (values.fullName === startValues?.user?.fullName && values.phone === startValues?.user?.phone) {
                        setIsEdit(false);
                        setSubmitting(false);
                    } else {
                        editProfileInfo({
                            user: {
                                fullName: values.fullName,
                                phone: values.phone,
                            },
                            companyInfo: {
                                name: profileInfo?.companyInfo?.name,
                                address: profileInfo?.companyInfo?.address,
                                country: profileInfo?.companyInfo?.country,
                                city: profileInfo?.companyInfo?.city,
                                zipCode: profileInfo?.companyInfo?.zipCode,
                            },
                        })
                            .unwrap()
                            .then(() => {
                                refetch().then(() => {
                                    successToast(t('Profile information were updated successfully'));
                                    setIsEdit(false);
                                });
                            })
                            .catch(() => {
                                errorToast(t('Error edit user information!'));
                            })
                            .finally(() => {
                                setSubmitting(false);
                            });
                    }
                }}
                validationSchema={validationSchema}
                enableReinitialize
                initialValues={{
                    fullName: profileInfo?.user?.fullName,
                    phone: profileInfo?.user?.phone,
                }}
            >
                {({ values, isSubmitting, touched, errors, handleSubmit, handleBlur, handleChange, setFieldValue }) => (
                    <Form>
                        <div className="flex items-center justify-between pb-[40px]">
                            <div className="text-[24px] font-Lexend500 text-int-white-main h-[50px]">
                                {t('Profile Information')}
                            </div>

                            {adminView && (
                                <div className="flex">
                                    {isEdit ? (
                                        <PrimaryButton
                                            isPink
                                            className="h-[50px] min-w-[170px] px-[32px]"
                                            handleClick={() => {
                                                handleSubmit();
                                            }}
                                            disabled={isSubmitting}
                                        >
                                            <div className="text-[18px] font-Lexend400">{t('Save changes')}</div>
                                        </PrimaryButton>
                                    ) : (
                                        <PrimaryButton
                                            isGrey
                                            className="border-0 h-[50px] min-w-[170px] px-[32px]"
                                            handleClick={() => setIsEdit(true)}
                                        >
                                            <div className="text-[16px] font-Lexend400">{t('Edit')}</div>
                                        </PrimaryButton>
                                    )}
                                </div>
                            )}
                        </div>
                        {isLoading || isFetching ? (
                            <div className="flex justify-center items-center pb-[40px]">
                                <Preloader />
                            </div>
                        ) : (
                            <div className="grid grid-cols-2 gap-x-[40px] ">
                                {isEdit ? (
                                    <FInput
                                        name="fullName"
                                        placeholder="Full name"
                                        value={values.fullName}
                                        handleBlur={handleBlur}
                                        handleChange={handleChange}
                                        className="w-[100%] mt-[22px]"
                                        type="text"
                                        label="Full name"
                                        isError={!!(touched.fullName && errors.fullName)}
                                        errorText={errors.fullName}
                                        limit={50}
                                        setFieldValue={setFieldValue}
                                    />
                                ) : (
                                    <div className="mt-[22px]">
                                        <OnlyViewFieldInput label="Full name" value={values.fullName} />
                                    </div>
                                )}
                                <div className="mt-[22px]">
                                    <OnlyViewFieldInput label="Email adresse" value={profileData?.email} />
                                </div>

                                {isEdit ? (
                                    <FInput
                                        name="phone"
                                        placeholder="+ 389"
                                        value={values.phone}
                                        handleBlur={handleBlur}
                                        handleChange={handleChange}
                                        className="w-[100%] mt-[48px] mb-[25px]"
                                        type="text"
                                        label="Telefonnummer"
                                        isError={!!(touched.phone && errors.phone)}
                                        errorText={errors.phone}
                                        setFieldValue={setFieldValue}
                                    />
                                ) : (
                                    <div className="mt-[48px] mb-[25px]">
                                        <OnlyViewFieldInput label="Telefonnummer" value={values.phone} />
                                    </div>
                                )}
                            </div>
                        )}
                    </Form>
                )}
            </Formik>
        </div>
    );
};

EditProfileInformation.propTypes = {
    adminView: bool,
};

export default EditProfileInformation;
