import React from 'react';
import { func, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { i18PAGES } from '../../config/i18n/constants';

const ChangeStepInfoModalContent = ({ setIsModalOpened, setIsAgree, clearNextSteps, text }) => {
    const { t } = useTranslation(i18PAGES.SETUP_COMPANIES);
    const acceptModal = () => {
        setIsModalOpened(false);
        setIsAgree(true);
        if (clearNextSteps) {
            clearNextSteps();
        }
    };

    const declineModal = () => {
        setIsModalOpened(false);
        setIsAgree(false);
    };

    return (
        <div className="w-[470px] m-auto font-Lexend400 text-[18px] leading-[28px] text-int-white-main">
            <div className="text-center w-[460px]">
                {text ||
                    t(
                        'Are you sure you want to change the information? All the previous data will be changed according to your current selection'
                    )}
            </div>
            <div className="flex justify-between leading-[18px] mt-[40px] mb-[15px]">
                <button
                    className={`text-int-white-main border border-int-white-main rounded-[8px] w-[210px] h-[50px] font-Lexend400 `}
                    type="button"
                    onClick={acceptModal}
                >
                    {t('Proceed')}
                </button>

                <button
                    className={`text-int-white-main border border-int-grapefruit bg-int-grapefruit rounded-[8px] w-[210px] h-[50px] font-Lexend400 `}
                    type="button"
                    onClick={declineModal}
                >
                    {t('Cancel')}
                </button>
            </div>
        </div>
    );
};

export default ChangeStepInfoModalContent;

ChangeStepInfoModalContent.propTypes = {
    text: string,
    setIsModalOpened: func.isRequired,
    setIsAgree: func.isRequired,
    clearNextSteps: func,
};
