import React from 'react';
import { FieldArray } from 'formik';
import { func, object } from 'prop-types';
import HrDigitalizationEditItem from './HrDigitalizationEditItem';
import HrDigitalizationTableLabels from '../HrDigitalizationTableLabels';
import ModulesStrategyAddLineButton from '../../Common/Modules&StrategyAddLineButton';
import { hrDigitalizationsEmptyObject, weaknessExpectationsEmptyObject } from '../../Common/constants';

const HrDigitalizationEditMode = ({ values, errors, touched, handleBlur, handleChange, setFieldValue }) => (
    <div className="bg-int-gray-main p-[28px] pr-[56px] rounded-[8px]">
        <HrDigitalizationTableLabels />
        <FieldArray
            name="hrDigitalizations"
            render={(ArrayHelpers) => (
                <>
                    {values.hrDigitalizations?.map((we, index) => {
                        const hrDigitalizationsErrors =
                            (errors.hrDigitalizations?.length && errors.hrDigitalizations[index]) || {};

                        const hrDigitalizationsTouched =
                            (touched.hrDigitalizations?.length && touched.hrDigitalizations[index]) || {};

                        return (
                            <HrDigitalizationEditItem
                                key={index}
                                hrDigitalizationsTouched={hrDigitalizationsTouched}
                                hrDigitalizationsErrors={hrDigitalizationsErrors}
                                handleBlur={handleBlur}
                                handleChange={handleChange}
                                ArrayHelpers={ArrayHelpers}
                                values={values}
                                index={index}
                                setFieldValue={setFieldValue}
                            />
                        );
                    })}
                    <ModulesStrategyAddLineButton
                        ArrayHelpers={ArrayHelpers}
                        pushObject={hrDigitalizationsEmptyObject}
                    />
                </>
            )}
        />
    </div>
);

export default HrDigitalizationEditMode;

HrDigitalizationEditMode.propTypes = {
    values: object.isRequired,
    errors: object.isRequired,
    touched: object.isRequired,
    handleBlur: func.isRequired,
    handleChange: func.isRequired,
    setFieldValue: func.isRequired,
};
