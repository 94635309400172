import { findCountryCode } from '../../../util/countryHelper';

export const getColumns = (data) => {
    const outgoingArray = data?.map((d) => ({
        id: `${d.outgoingBuildingBlock.id}${d.outgoingApp}`,
        name: `${d.outgoingBuildingBlock.name} / \n${d.outgoingApp}`,
        buildingBlockId: d.outgoingBuildingBlock.id,
    }));
    const ingoingArray = data?.map((d) => ({
        id: `${d.ingoingBuildingBlock.id}${d.ingoingApp}`,
        name: `${d.ingoingBuildingBlock.name} / \n${d.ingoingApp}`,
        buildingBlockId: d.ingoingBuildingBlock.id,
    }));

    const middleArray = outgoingArray.filter((o) =>
        ingoingArray.some(({ id, name }) => o.id === id && o.name === name)
    );

    const leftArray = outgoingArray.filter((o) => !middleArray.some(({ id, name }) => o.id === id && o.name === name));
    const rightArray = ingoingArray.filter((o) => !middleArray.some(({ id, name }) => o.id === id && o.name === name));

    return { leftArray, middleArray, rightArray };
};

export const getNodes = (columns, buildingBlocksCountries) => {
    const leftArrayNodes = columns?.leftArray?.map((item, index) => {
        const countriesString = getNodesCountries(buildingBlocksCountries, item);

        return {
            id: item.id,
            x: 20,
            y: 40 * index + (50 * index + 20),
            width: 250,
            height: 40,
            attrs: {
                body: {
                    rx: 16,
                    ry: 16,
                },
                title: {
                    text: item.name,
                    refX: '50%',
                    refY: '50%',
                    fill: '#333',
                    fontSize: 14,
                    'text-anchor': 'middle',
                    'text-vertical-anchor': 'middle',
                    fontWeight: 'bold',
                    fontVariant: 'small-caps',
                },
                text: {
                    text: countriesString ? `Geography covered: ${countriesString}` : '',
                    refX: '50%',
                    refY: '125%',
                    fontSize: 12,
                    fill: '#333',
                    'text-anchor': 'middle',
                    'text-vertical-anchor': 'middle',
                },
            },
            markup: [
                {
                    tagName: 'rect',
                    selector: 'body',
                },
                {
                    tagName: 'text',
                    selector: 'title',
                },
                {
                    tagName: 'text',
                    selector: 'text',
                },
            ],
            buildingBlockId: item.buildingBlockId,
        };
    });

    const rightNodes = columns?.rightArray?.map((item, index) => {
        const countriesString = getNodesCountries(buildingBlocksCountries, item);
        return {
            id: item.id,
            x: 1380,
            y: 40 * index + (50 * index + 20),
            width: 250,
            height: 40,
            attrs: {
                body: {
                    rx: 16,
                    ry: 16,
                },
                title: {
                    text: item.name,
                    refX: '50%',
                    refY: '50%',
                    fill: '#333',
                    fontSize: 14,
                    'text-anchor': 'middle',
                    'text-vertical-anchor': 'middle',
                    fontWeight: 'bold',
                    fontVariant: 'small-caps',
                },
                text: {
                    text: countriesString ? `Geography covered: ${countriesString}` : '',
                    refX: '50%',
                    refY: '125%',
                    fontSize: 12,
                    fill: '#333',
                    'text-anchor': 'middle',
                    'text-vertical-anchor': 'middle',
                },
            },
            markup: [
                {
                    tagName: 'rect',
                    selector: 'body',
                },
                {
                    tagName: 'text',
                    selector: 'title',
                },
                {
                    tagName: 'text',
                    selector: 'text',
                },
            ],
            buildingBlockId: item.buildingBlockId,
        };
    });

    const centerNodes = columns?.middleArray?.map((item, index) => {
        const countriesString = getNodesCountries(buildingBlocksCountries, item);
        return {
            id: item.id,
            x: 600,
            y: 150 * index + (60 * index + 20),
            width: 300,
            height: 150,
            zIndex: 1,
            attrs: {
                body: {
                    refWidth: 1,
                    refHeight: 1,
                    fill: '#a8dcfc',
                    stroke: '#074f85',
                    rx: 16,
                    ry: 16,
                },
                title: {
                    text: item.name,
                    refX: '50%',
                    refY: '50%',
                    fill: '#074f85',
                    fontSize: 20,
                    'text-anchor': 'middle',
                    'text-vertical-anchor': 'middle',
                },
                text: {
                    text: countriesString ? `Geography covered: ${countriesString}` : '',
                    refX: '50%',
                    refY: '106%',
                    fontSize: 12,
                    fill: '#333',
                    'text-anchor': 'middle',
                    'text-vertical-anchor': 'middle',
                },
            },
            buildingBlockId: item.buildingBlockId,
            markup: [
                {
                    tagName: 'rect',
                    selector: 'body',
                },
                {
                    tagName: 'text',
                    selector: 'title',
                },
                {
                    tagName: 'text',
                    selector: 'text',
                },
            ],
        };
    });

    return [...leftArrayNodes, ...centerNodes, ...rightNodes];
};

const labelTemplate = (labelName) => [
    {
        markup: [
            {
                tagName: 'rect',
                selector: 'body',
            },
            {
                tagName: 'text',
                selector: 'label',
            },
        ],
        attrs: {
            label: {
                cursor: 'pointer',
                text: labelName,
                textAnchor: 'middle',
                textVerticalAnchor: 'middle',
                fontSize: 12,
                fill: 'black',
            },
            body: {
                maxWidth: '30px',
                cursor: 'pointer',
                labelName: 'labelName',
                ref: 'label',
                refX: '-10%',
                refY: '-10%',
                refWidth: '120%',
                refHeight: '120%',
                fill: 'white',
                stroke: 'black',
                strokeWidth: 1,
            },
        },
    },
];

export const getEdges = (data) =>
    data.map((d) => ({
        source: `${d.outgoingBuildingBlock.id}${d.outgoingApp}`,
        target: `${d.ingoingBuildingBlock.id}${d.ingoingApp}`,
        labels: labelTemplate(`${d?.dataType}, ${d?.integrationType}`),
        attrs: {
            line: {
                stroke: '#00bf4e',
                strokeWidth: 3,
                rx: 16,
                ry: 16,
            },
        },
        zIndex: 0,
    }));

const getNodesCountries = (buildingBlocksCountries, item) => {
    const currentBuildingBlockCountries =
        buildingBlocksCountries?.filter((bb) => bb.id === item.buildingBlockId)[0]?.countries || [];

    const cutNameCountriesArray = currentBuildingBlockCountries.map(
        (country) => findCountryCode(country).toUpperCase() || country.slice(0, 3).toUpperCase()
    );

    return cutNameCountriesArray?.join(', ');
};
