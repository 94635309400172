import React, { useState } from 'react';
import { bool, number, object } from 'prop-types';
import { ReactComponent as ChoicePagesDropDownItemIcon } from '../../../../assets/icons/choicePagesDropDownItemIcon.svg';
import Checkbox from '../../../../style/Checkbox/Checkbox';
import { stringCapitalise } from '../../../util/stringCapitalise';
import { stringShorter } from '../../../util/stringShorter';

const ChoicePagesDropDownItem = ({ module, values, moduleIndex, index, typeEdit }) => {
    const [isOpenChildren, setIsOpenChildren] = useState(false);

    const handleClickOpenChildren = () => {
        setIsOpenChildren((prev) => !prev);
    };

    return (
        <>
            {module?.data?.length === 0 ? (
                <div className="text-[16px] text-int-white-main py-[0px] hover:bg-[#354153] flex items-center pl-[10px]">
                    {typeEdit ? (
                        <Checkbox
                            name={`pages.${moduleIndex}.availablePage`}
                            id={`pages.${moduleIndex}.availablePage`}
                            label={stringCapitalise(values?.pages[moduleIndex]?.pageEnum.value)}
                            value={values?.pages[moduleIndex]?.availablePage}
                        />
                    ) : (
                        <Checkbox
                            name={`userList.${index}.pages.${moduleIndex}.availablePage`}
                            id={`userList.${index}.pages.${moduleIndex}.availablePage`}
                            label={stringCapitalise(values?.userList[index]?.pages[moduleIndex]?.pageEnum.value)}
                            value={values?.userList[index]?.pages[moduleIndex]?.availablePage}
                        />
                    )}
                </div>
            ) : (
                <div
                    className="text-[16px] text-int-white-main py-[8px] hover:bg-[#354153] cursor-pointer flex items-center"
                    onClick={handleClickOpenChildren}
                >
                    <div className="w-[18px] h-[18px] flex justify-center items-center mx-[10px]">
                        <div className={isOpenChildren ? 'rotate-90' : ''}>
                            <ChoicePagesDropDownItemIcon fill="#BDBDBD" width={6} height={8} />
                        </div>
                    </div>
                    <div>{stringCapitalise(module.pageEnum.value)}</div>
                </div>
            )}

            {isOpenChildren &&
                module?.dynamic === true &&
                module?.data?.map((child, childIndex) => (
                    <div className="text-[14px] text-int-white-main pl-[40px] hover:bg-[#354153]" key={childIndex}>
                        {typeEdit ? (
                            <Checkbox
                                name={`pages.${moduleIndex}.data.${childIndex}.availableSubpage`}
                                id={`pages.${moduleIndex}.data.${childIndex}.availableSubpage`}
                                label={stringCapitalise(
                                    stringShorter(values?.pages[moduleIndex]?.data[childIndex]?.companyName, 20)
                                )}
                                subLabel={`${stringCapitalise(
                                    values?.pages[moduleIndex]?.data[childIndex]?.countryName
                                )}, ${stringCapitalise(
                                    values?.pages[moduleIndex]?.data[childIndex]?.cityName
                                )}, ${stringCapitalise(values?.pages[moduleIndex]?.data[childIndex]?.officeName)}`}
                                value={values?.pages[moduleIndex]?.data[childIndex]?.availableSubpage}
                            />
                        ) : (
                            <Checkbox
                                name={`userList.${index}.pages.${moduleIndex}.data.${childIndex}.availableSubpage`}
                                id={`userList.${index}.pages.${moduleIndex}.data.${childIndex}.availableSubpage`}
                                label={stringCapitalise(
                                    stringShorter(
                                        values?.userList[index]?.pages[moduleIndex]?.data[childIndex]?.companyName,
                                        20
                                    )
                                )}
                                subLabel={`${stringCapitalise(
                                    values?.userList[index]?.pages[moduleIndex]?.data[childIndex]?.countryName
                                )}, ${stringCapitalise(
                                    values?.userList[index]?.pages[moduleIndex]?.data[childIndex]?.cityName
                                )}, ${stringCapitalise(
                                    values?.userList[index]?.pages[moduleIndex]?.data[childIndex]?.officeName
                                )}`}
                                value={values?.userList[index]?.pages[moduleIndex]?.data[childIndex]?.availableSubpage}
                            />
                        )}
                    </div>
                ))}

            {isOpenChildren &&
                module?.dynamic === false &&
                module?.data?.map((child, childIndex) => (
                    <div className="text-[14px] text-int-white-main pl-[40px] hover:bg-[#354153]" key={childIndex}>
                        {typeEdit ? (
                            <Checkbox
                                name={`pages.${moduleIndex}.data.${childIndex}.availableSubpage`}
                                id={`pages.${moduleIndex}.data.${childIndex}.availableSubpage`}
                                label={stringCapitalise(values?.pages[moduleIndex].data[childIndex]?.value)}
                                value={values?.pages[moduleIndex].data[childIndex]?.availableSubpage}
                            />
                        ) : (
                            <Checkbox
                                name={`userList.${index}.pages.${moduleIndex}.data.${childIndex}.availableSubpage`}
                                id={`userList.${index}.pages.${moduleIndex}.data.${childIndex}.availableSubpage`}
                                label={stringCapitalise(
                                    values?.userList[index]?.pages[moduleIndex]?.data[childIndex]?.value
                                )}
                                value={values?.userList[index]?.pages[moduleIndex]?.data[childIndex]?.availableSubpage}
                            />
                        )}
                    </div>
                ))}
        </>
    );
};

export default ChoicePagesDropDownItem;

ChoicePagesDropDownItem.propTypes = {
    module: object.isRequired,
    values: object.isRequired,
    moduleIndex: number.isRequired,
    index: number,
    typeEdit: bool.isRequired,
};
