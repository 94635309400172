import React from 'react';
import { node } from 'prop-types';
import ExternalHeader from './ExternalHeader';
import ExternalFooter from './ExternalFooter';

const ExternalLayout = ({ children }) => (
    <>
        <ExternalHeader />
        <div
            className="min-h-[calc(calc(100vh-theme(spacing.header-height))-theme(spacing.external-footer-height))] flex items-center
            justify-center max-w-container-size m-auto py-[15px]"
        >
            {children}
        </div>
        <ExternalFooter />
    </>
);

export default ExternalLayout;

ExternalLayout.propTypes = {
    children: node.isRequired,
};
