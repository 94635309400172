import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Formik } from 'formik';
import { func } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { SETUP_PAGES, STEP_TYPE } from '../../util/constants';
import StepLayout from '../../SetupCompanies/StepLayout';
import { useCreateProject } from '../../../store/hooks/useCreateProject';
import { useCreateProjectMutation } from '../../../store/API/createProjectAPI';
import ModalWindow from '../../../style/ModalWindow/ModalWindow';
import ChangeStepInfoModalContent from '../../SetupCompanies/ChangeStepInfoModalContent';
import { useOwnerUserListQuery } from '../../../store/API/profileAPI';
import { i18PAGES } from '../../../config/i18n/constants';
import { generateKey } from '../../util/generateKey';
import { useToast } from '../../../hoc/toast/ToastProvider';
import Step1FormProject from './Step1FormProject';
import { validationStep1ProjectSchema } from './validationStep1Project';

const Step1GeneralInfo = ({ setSetupCurrentPage }) => {
    const { t } = useTranslation(i18PAGES.PROJECTS_SETUP);
    const { errorToast } = useToast();

    const [isModalOpened, setIsModalOpened] = useState(false);
    const [isChangeAgree, setIsChangeAgree] = useState(false);
    const [tempUsers, setTempUsers] = useState([]);

    const { createProjectInfo, setFirstStep, clearCompanies } = useCreateProject();

    const { data: userList, isLoading } = useOwnerUserListQuery({}, { refetchOnMountOrArgChange: true });
    const [createProject] = useCreateProjectMutation();

    const openModalChecking = useMemo(
        () =>
            createProjectInfo.companyInfo?.companies &&
            createProjectInfo.companyInfo?.companies.length &&
            !isChangeAgree,
        [isChangeAgree, createProjectInfo.companyInfo?.companies]
    );

    const showModalOnFocusNewData = useCallback(() => {
        if (openModalChecking) {
            setIsModalOpened(true);
        } else {
            setIsModalOpened(false);
        }
    }, [openModalChecking]);

    const filteredNotSelectedUsers = useMemo(() => tempUsers?.filter((item) => item.isSelected === false), [tempUsers]);

    const setSelectedUserById = (id, isSelected) => {
        const changeTempUsers = tempUsers.map((user) => {
            if (user.value === id) {
                user.isSelected = isSelected;
            }
            return user;
        });
        setTempUsers(changeTempUsers);
    };

    useEffect(() => {
        const changeUserList = userList?.map((item, index) => {
            if (
                (createProjectInfo.managerIds.length === 0 && index === 0) ||
                (createProjectInfo.managerIds.length > 0 && createProjectInfo.managerIds.includes(item.id))
            ) {
                return { value: item.id, label: item.fullName, isSelected: true, key: generateKey(index) };
            }
            return { value: item.id, label: item.fullName, isSelected: false, key: generateKey(index) };
        });
        if (changeUserList !== undefined) {
            setTempUsers(changeUserList);
        }
    }, [userList, createProjectInfo.managerIds.length]);

    return (
        <StepLayout currentStepNumber={1} height={0} type={STEP_TYPE.PROJECT}>
            <Formik
                onSubmit={(values, { setSubmitting }) => {
                    createProject({
                        id: createProjectInfo.projectId === null ? null : createProjectInfo.projectId,
                        name: values.name,
                        description: values.description,
                        yearsInScope: values.yearsInScope,
                        managerIds: values.users.map((item) => item.value),
                    })
                        .unwrap()
                        .then((data) => {
                            setFirstStep({
                                projectId: data.id,
                                name: data.name,
                                description: data.description,
                                yearsInScope: data.yearsInScope,
                                managerIds: data.managerIds,
                            });

                            setSetupCurrentPage(SETUP_PAGES.STEP_2);
                        })
                        .catch((err) => {
                            errorToast(t('Error create project!'));
                        })
                        .finally(() => {
                            setSubmitting(false);
                        });
                }}
                validationSchema={validationStep1ProjectSchema}
                initialValues={{
                    name: createProjectInfo.name || '',
                    description: createProjectInfo.description || '',
                    yearsInScope: createProjectInfo.yearsInScope.toString() || '1',
                    users:
                        tempUsers?.filter((item) => createProjectInfo.managerIds.includes(item.value)).length > 0
                            ? tempUsers
                                  ?.filter((item) => createProjectInfo.managerIds.includes(item.value))
                                  .map((item) => ({ value: item.value, label: item.label, isSelected: true }))
                            : [tempUsers[0]],
                }}
                enableReinitialize
            >
                {({ values, touched, errors, isSubmitting, handleSubmit, handleBlur, handleChange, setFieldValue }) => (
                    <Step1FormProject
                        isLoading={isLoading}
                        values={values}
                        setFieldValue={setFieldValue}
                        isSubmitting={isSubmitting}
                        handleSubmit={handleSubmit}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        touched={touched}
                        errors={errors}
                        filteredNotSelectedUsers={filteredNotSelectedUsers}
                        openModalChecking={openModalChecking}
                        setIsModalOpened={setIsModalOpened}
                        setSelectedUserById={setSelectedUserById}
                        showModalOnFocusNewData={showModalOnFocusNewData}
                    />
                )}
            </Formik>

            {isModalOpened && (
                <ModalWindow isOpen={isModalOpened} setIsOpen={setIsModalOpened}>
                    <ChangeStepInfoModalContent
                        setIsAgree={setIsChangeAgree}
                        setIsModalOpened={setIsModalOpened}
                        clearNextSteps={clearCompanies}
                    />
                </ModalWindow>
            )}
        </StepLayout>
    );
};

export default Step1GeneralInfo;

Step1GeneralInfo.propTypes = {
    setSetupCurrentPage: func,
};
