import React from 'react';
import { func, number, object } from 'prop-types';
import Pagination from '../../style/Pagination/Pagination';

export const PM_PAGINATION_SIZE = 8;

const UsersPaginator = (props) => {
    const {
        internalUsers,
        internalActivePage,
        setInternalActivePage,
        externalUsers,
        externalActivePage,
        setExternalActivePage,
        openedTab,
    } = props;

    return (
        <div className="flex justify-center mt-[50px] bg-int-main-black">
            {openedTab === 0 && internalUsers?.content?.length > 0 && (
                <Pagination
                    count={internalUsers?.totalElements}
                    pageSize={PM_PAGINATION_SIZE}
                    activePage={internalActivePage}
                    setActivePage={setInternalActivePage}
                />
            )}
            {openedTab === 1 && externalUsers?.content?.length > 0 && (
                <Pagination
                    count={externalUsers?.totalElements}
                    pageSize={PM_PAGINATION_SIZE}
                    activePage={externalActivePage}
                    setActivePage={setExternalActivePage}
                />
            )}
        </div>
    );
};

export default UsersPaginator;

UsersPaginator.propTypes = {
    internalUsers: object,
    externalUsers: object,
    internalActivePage: number.isRequired,
    setInternalActivePage: func.isRequired,
    externalActivePage: number.isRequired,
    setExternalActivePage: func.isRequired,
    openedTab: number.isRequired,
};
