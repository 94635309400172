import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from '../style/Button/Button';
import UserManagementMainBlock from '../components/UserManagement/UserManagementMainBlock';
import AddUsersModalForm from '../components/UserManagement/AddUsersModalForm';
import { i18PAGES } from '../config/i18n/constants';

const UserManagementPage = () => {
    const [isAddUserModalOpened, setIsAddUserModalOpened] = useState(false);

    const { t } = useTranslation(i18PAGES.USER_MANAGEMENT);

    return (
        <div className="w-[1020px] m-auto">
            <div className="flex justify-between items-center mt-[25px] mb-[35px]">
                <div className="text-[24px] leading-[24px] text-int-white-main font-Lexend500">
                    {t('User Management')}
                </div>
                <PrimaryButton isPink className="h-[50px] px-[32px]" handleClick={() => setIsAddUserModalOpened(true)}>
                    <div className="text-[18px] font-Lexend400">{t('Add user')}</div>
                </PrimaryButton>
            </div>
            <UserManagementMainBlock />

            <AddUsersModalForm
                isAddUserModalOpened={isAddUserModalOpened}
                setIsAddUserModalOpened={setIsAddUserModalOpened}
            />
        </div>
    );
};

export default UserManagementPage;
