import React from 'react';
import { useTranslation } from 'react-i18next';
import { arrayOf, number, shape, string } from 'prop-types';
import { i18PAGES } from '../../config/i18n/constants';

const InvoiceModuleTableInfo = ({ modules, subscriptionAmount, tax, totalAmount }) => {
    const { t } = useTranslation(i18PAGES.INVOICE);

    return (
        <>
            <div className="border-[1px] border-int-gray-secondary rounded">
                <div
                    className="px-[20px] py-[12px] border-b-[1px] border-int-gray-secondary
                     flex text-[12px] leading-[18px] font-Inter500 text-int-white-secondary"
                >
                    <div className="min-w-[300px] w-[100%] text-left">{t('Description')}</div>
                    <div className="w-[100%] text-center">{t('Subscription plan')}</div>
                    <div className="min-w-[239px] w-[100%] text-right">{t('Price')}, €</div>
                </div>
                <div className="p-[20px] flex text-[14px] leading-[22px] font-Inter500 text-int-white-main">
                    <div className="min-w-[300px] w-[100%] border-r-[1px] border-int-gray-secondary pr-[20px]">
                        {modules?.map((module, index) => (
                            <div className="mb-[20px]" key={index}>
                                {module.description}
                            </div>
                        ))}
                    </div>
                    <div className="w-[100%] px-[20px] text-center">{modules?.[0]?.subscriptionPlan} months</div>
                    <div className="min-w-[239px] w-[100%] border-l-[1px] border-int-gray-secondary pl-[20px] text-right">
                        {subscriptionAmount}
                    </div>
                </div>
            </div>
            <div className="flex mt-[20px]">
                <div className="w-[100%] text-[14px] leading-[18px] font-SourceSansPro400 text-int-white-secondary pr-[10px]">
                    {t('All charges of correspondent banks are at the Supplier’s expenses.')}
                </div>
                <div
                    className="min-w-[400px] max-w-[400px]  w-[100%] px-[20px] py-[18px]
                     border-[1px] border-int-gray-secondary rounded font-Inter400 text-int-white-main bg-[#26303F]"
                >
                    <div className="flex justify-between items-center">
                        <div className="text-[14px] leading-[22px]">{t('Sub total')} (EUR)</div>
                        <div className="text-[16px] leading-[24px]">€{subscriptionAmount}</div>
                    </div>
                    <div className="flex justify-between items-center my-[18px]">
                        <div className="text-[14px] leading-[22px]">{t('Tax')}</div>
                        <div className="text-[16px] leading-[24px]">€{tax}</div>
                    </div>
                    <div className="flex justify-between items-center">
                        <div className="text-[14px] leading-[22px]">{t('Total')} (EUR)</div>
                        <div className="text-[16px] leading-[24px]">€{totalAmount}</div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default InvoiceModuleTableInfo;

InvoiceModuleTableInfo.propTypes = {
    modules: arrayOf(
        shape({
            description: string,
            subscriptionPlan: number,
        })
    ),
    subscriptionAmount: number,
    tax: number,
    totalAmount: number,
};
