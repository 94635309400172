import React from 'react';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import { object, string } from 'yup';
import { useTranslation } from 'react-i18next';
import ModalWindow from '../../style/ModalWindow/ModalWindow';
import FInput from '../../style/Input/Input';
import Checkbox from '../../style/Checkbox/Checkbox';
import { PrimaryButton } from '../../style/Button/Button';
import { useUpdateUserMutation } from '../../store/API/userManagementAPI';
import { i18PAGES } from '../../config/i18n/constants';
import { useToast } from '../../hoc/toast/ToastProvider';

const validationSchema = object({
    fullName: string().required('Full name should be stated').max(50, 'Invalid full name'),
});

const EditUserModalForm = ({ isOpenEditUserModal, setIsOpenEditUserModal, id, fullName, email, isAdmin }) => {
    const [updateUser] = useUpdateUserMutation();
    const { t } = useTranslation(i18PAGES.USER_MANAGEMENT);
    const { successToast, errorToast } = useToast();

    return (
        <ModalWindow
            isOpen={isOpenEditUserModal}
            setIsOpen={setIsOpenEditUserModal}
            isCloseClickOutside
            className="-top-[130px] w-[800px]"
        >
            <div className="flex flex-col items-center">
                <div className="text-[24px] leading-[24px] font-Lexend500 text-int-white-main">{t('Edit user')}</div>
                <Formik
                    onSubmit={(values, { setSubmitting }) => {
                        updateUser({ id, fullName: values.fullName, adminRightsPermission: values.isAdmin })
                            .unwrap()
                            .then(() => {
                                successToast(t('User information were successfully edited'));
                                setIsOpenEditUserModal(false);
                            })
                            .catch(() => {
                                errorToast(t('Error edit users'));
                            })
                            .finally(() => {
                                setSubmitting(false);
                            });
                    }}
                    validationSchema={validationSchema}
                    initialValues={{ fullName, email, isAdmin }}
                >
                    {({
                        values,
                        touched,
                        errors,
                        handleSubmit,
                        handleBlur,
                        handleChange,
                        isSubmitting,
                        setFieldValue,
                    }) => (
                        <Form className="w-[100%] mt-[40px]">
                            <div className="overflow-y-auto max-h-[500px]" id="primary-scroll">
                                <div>
                                    <div className="flex justify-between items-center">
                                        <FInput
                                            name="fullName"
                                            placeholder="Full name"
                                            value={values.fullName}
                                            handleBlur={handleBlur}
                                            handleChange={handleChange}
                                            className="w-[340px]"
                                            type="text"
                                            label="Full name"
                                            isError={!!(touched.fullName && errors.fullName)}
                                            errorText={errors.fullName}
                                            setFieldValue={setFieldValue}
                                            limit={50}
                                        />
                                        <div className="w-[340px] mt-[22px] min-h-[66px] relative">
                                            <div className="absolute left-0 top-[-20px] text-[14px] leading-[16px] font-Lexend400 text-int-white-main">
                                                {t('Email Adresse')}
                                            </div>
                                            <div className="flex items-center w-[100%] h-[44px] rounded-[8px] bg-[#354153] px-[25px] text-[16px] font-Lexend400 text-int-white-main ">
                                                {values.email}
                                            </div>
                                        </div>
                                        <div className="w-[20px] h-[42px]" />
                                    </div>
                                    <Checkbox
                                        name="isAdmin"
                                        id="isAdmin"
                                        label="Admin rights permission"
                                        className="text-[14px]"
                                        value={values.isAdmin}
                                    />
                                </div>
                            </div>
                            <div className="w-[100%] h-[1px] bg-int-gray-secondary mt-[30px] mb-[40px]" />

                            <div className="w-[100%] flex justify-between">
                                <PrimaryButton
                                    isGrey
                                    className="w-[170px] h-[50px]"
                                    handleClick={() => setIsOpenEditUserModal(false)}
                                >
                                    <div className="text-[18px] font-Lexend400">{t('Cancel')}</div>
                                </PrimaryButton>
                                <PrimaryButton
                                    isPink
                                    className="w-[170px] h-[50px]"
                                    handleClick={handleSubmit}
                                    disabled={isSubmitting}
                                >
                                    <div className="text-[18px] font-Lexend400">{t('Save changes')}</div>
                                </PrimaryButton>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </ModalWindow>
    );
};

EditUserModalForm.propTypes = {
    isOpenEditUserModal: PropTypes.bool.isRequired,
    setIsOpenEditUserModal: PropTypes.func.isRequired,
    id: PropTypes.number,
    fullName: PropTypes.string,
    email: PropTypes.string,
    isAdmin: PropTypes.bool,
};

export default EditUserModalForm;
