import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { HEADERS } from '../../components/util/constants';
import { PM_PAGINATION_SIZE } from '../../components/ProjectsManagement/UsersPaginator';

export const projectInternalManagementAPI = createApi({
    reducerPath: 'projectInternalManagementAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api',
        prepareHeaders: HEADERS,
    }),
    tagTypes: ['projectInternalManagementList'],
    endpoints: (build) => ({
        usersProjects: build.query({
            query: ({ sortParams = '' }) => `/projects${sortParams && `?sort=${sortParams}`}`,
        }),
        archiveProject: build.mutation({
            query: ({ projectId }) => ({
                url: `/projects/${projectId}/archive`,
                method: 'PUT',
            }),
        }),
        projectUsers: build.query({
            query: (projectId) => `/projects/${projectId}/users`,
        }),

        internalUsers: build.query({
            query: ({ projectId, page, sortBy, order }) =>
                `/projects/${projectId}/internal-users?size=${PM_PAGINATION_SIZE}&sort=${sortBy || 'id'},${
                    order || 'DESC'
                }&page=${page}`,
            providesTags: (result) =>
                result
                    ? [
                          ...result.content.map(({ id }) => ({ type: 'projectInternalManagementList', id })),
                          { type: 'projectInternalManagementList', id: 'LIST' },
                      ]
                    : [{ type: 'projectInternalManagementListF', id: 'LIST' }],
        }),

        addInternalUser: build.mutation({
            query: ({ projectId, body }) => ({
                url: `/projects/${projectId}/internal-users`,
                method: 'POST',
                body,
            }),
            invalidatesTags: (result, error, { id }) => [{ type: 'projectInternalManagementList', id }],
        }),
        editInternalUser: build.mutation({
            query: ({ projectId, userId, body }) => ({
                url: `/projects/${projectId}/internal-users/${userId}`,
                method: 'PUT',
                body,
            }),
            invalidatesTags: (result, error, { id }) => [{ type: 'projectInternalManagementList', id }],
        }),
        deleteInternalUser: build.mutation({
            query: ({ projectId, userId }) => ({
                url: `/projects/${projectId}/internal-users/${userId}`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, { id }) => [{ type: 'projectInternalManagementList', id }],
        }),
        optionsUsers: build.query({
            query: (projectId) => `/projects/${projectId}/available/internal-users`,
            providesTags: (result) =>
                result
                    ? [
                          ...result.map(({ id }) => ({ type: 'projectInternalManagementList', id })),
                          { type: 'projectInternalManagementList', id: 'LIST' },
                      ]
                    : [{ type: 'projectInternalManagementList', id: 'LIST' }],
        }),
    }),
});

export const {
    useUsersProjectsQuery,
    useArchiveProjectMutation,
    useProjectUsersQuery,
    useInternalUsersQuery,
    useAddInternalUserMutation,
    useEditInternalUserMutation,
    useDeleteInternalUserMutation,
    useOptionsUsersQuery,
} = projectInternalManagementAPI;
