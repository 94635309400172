import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { HEADERS } from '../../components/util/constants';

export const modulesAPI = createApi({
    reducerPath: 'modulesAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api',
        prepareHeaders: HEADERS,
    }),
    tagTypes: ['modulesList'],
    endpoints: (build) => ({
        modulesList: build.query({
            query: (page) => `/modules?page=${page - 1 || 0}&size=6`,
            providesTags: ['modulesList'],
            keepUnusedDataFor: 0.0001,
        }),
        registrationModulesList: build.query({
            query: (page) => `/modules/all?page=${page - 1 || 0}&size=6`,
            providesTags: ['modulesList'],
            keepUnusedDataFor: 0.0001,
        }),
        addedModules: build.query({
            query: () => `/users/modules?statuses=ADDED`,
            providesTags: ['modulesList'],
            keepUnusedDataFor: 0.0001,
        }),
        boughtModules: build.mutation({
            query: (body) => ({
                url: '/users/modules/purchase',
                method: 'POST',
                body,
            }),
            invalidatesTags: ['modulesList'],
        }),
        deleteAddedModule: build.mutation({
            query: (moduleId) => ({
                url: `/users/modules/${moduleId}/delete`,
                method: 'POST',
            }),
            invalidatesTags: ['modulesList'],
        }),
        userModulesCarts: build.query({
            query: () => `/users/modules/carts`,
            providesTags: ['modulesList'],
        }),
    }),
});

export const {
    useModulesListQuery,
    useRegistrationModulesListQuery,
    useBoughtModulesMutation,
    useAddedModulesQuery,
    useUserModulesCartsQuery,
    useDeleteAddedModuleMutation,
} = modulesAPI;
