import React from 'react';
import { arrayOf, shape, string } from 'prop-types';
import GoalsTableLabels from '../GoalsTableLabels';
import GoalsReadItem from './GoalsReadItem';
import { hrDigitalizationGoalsEmptyObject } from '../../Common/constants';

const GoalsReadMode = ({ hrDigitalizationGoals }) => (
    <div className="bg-int-gray-main p-[28px] pr-[56px] rounded-[8px]">
        <GoalsTableLabels />
        {hrDigitalizationGoals?.length > 0 &&
            hrDigitalizationGoals?.map((p, index) => <GoalsReadItem key={index} hrDigitalizationGoals={p} />)}
        {hrDigitalizationGoals?.length === 0 && (
            <GoalsReadItem hrDigitalizationGoals={hrDigitalizationGoalsEmptyObject} />
        )}
    </div>
);

export default GoalsReadMode;

GoalsReadMode.propTypes = {
    hrDigitalizationGoals: arrayOf(
        shape({
            goal: string.isRequired,
            contribution: string.isRequired,
        }).isRequired
    ),
};
