import React, { useCallback, useMemo, useState } from 'react';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { bool, func, number, oneOfType, string } from 'prop-types';
import BbCardEditButton from './BbCardEditButton';
import BbCardSendRequestButton from './BBCardSendRequestButton';
import DeadlineButton from './DeadlineButton';
import BbCardContentEditMode from './BbCardContentEditMode';
import { validationSchema } from '../validationSchema';
import BbCardContentReadMode from './BbCardContentReadMode';
import DeadLineWindow from '../DeadLineWindow';
import { BUILDING_BLOCKS_STATUSES, DEADLINE_STATUSES } from '../constants';
import { useToast } from '../../../../hoc/toast/ToastProvider';
import { i18PAGES } from '../../../../config/i18n/constants';
import { useGetToolsInfoQuery, useSendToolsMutation } from '../../../../store/API/providerSelectionAPI';
import Preloader from '../../../Preloader/Preloader';
import i18n from '../../../../config/i18n/i18n';
import { useAuth } from '../../../../store/hooks/useAuth';

const BuildingBlockCard = (props) => {
    const { t } = useTranslation(i18PAGES.PROVIDER_SELECTION);
    const { projectId, buildingBlockId, setIsEditMode, isEditMode } = props;
    const { successToast, errorToast } = useToast();

    const [isChangeDeadlineOpened, setIsChangeDeadlineOpened] = useState(false);
    const [changeDeadLineStatus, setChangeDeadLineStatus] = useState(undefined);
    const [sendTools] = useSendToolsMutation();
    const { isEditablePagesProject } = useAuth();

    const { data, isLoading, refetch } = useGetToolsInfoQuery(
        { projectId, buildingBlockId },
        { skip: !projectId || !buildingBlockId }
    );

    const isSendRequestAvailable = useMemo(
        () => data?.tools?.some((tool) => tool.status === BUILDING_BLOCKS_STATUSES.CREATED && tool.selected === true),
        [data]
    );

    const onSendProviderRequest = useCallback(() => {
        setChangeDeadLineStatus(DEADLINE_STATUSES.INITIAL);
        setIsChangeDeadlineOpened(true);
    }, []);

    if (isLoading) {
        return (
            <div className="bg-int-gray-main rounded-[8px] h-[500px] flex items-center justify-center p-[30px] w-[100%]">
                <Preloader />
            </div>
        );
    }

    return (
        <>
            <Formik
                initialValues={{
                    buildingBlock: data,
                }}
                onSubmit={(values, { setSubmitting }) => {
                    const isCheckboxSelected = values.buildingBlock.tools.filter((tool) => tool.selected).length > 0;

                    if (isCheckboxSelected) {
                        sendTools({
                            projectId,
                            buildingBlockId: values.buildingBlock.buildingBlockId,
                            body: { tools: values.buildingBlock.tools },
                        })
                            .unwrap()
                            .then(() => {
                                refetch()
                                    .then(() => {
                                        successToast(t('Changes were successfully saved'), '', '', 3000);
                                        setIsEditMode(false);
                                    })
                                    .catch(() => {
                                        errorToast(i18n.t('Something went wrong, try again later'));
                                    })
                                    .finally(() => {
                                        setSubmitting(false);
                                    });
                            })
                            .catch(() => {
                                errorToast(i18n.t('Something went wrong, try again later'));
                                setSubmitting(false);
                            });
                    }

                    if (!isCheckboxSelected) {
                        errorToast(t('At least one provider should be selected'));
                        setSubmitting(false);
                    }
                }}
                validationSchema={validationSchema}
                enableReinitialize
            >
                {({ isSubmitting, handleSubmit, values, errors, handleBlur, setFieldValue, handleChange, touched }) => (
                    <div className="bg-int-gray-main rounded-[8px] p-[30px] w-[100%]">
                        <Form>
                            <div className="flex justify-between items-center">
                                <div className="text-[24px] leading-[26px] font-Lexend500">
                                    {data?.buildingBlockName}
                                </div>
                                <div className="flex items-center">
                                    {data?.deadlineDate && (
                                        <DeadlineButton
                                            deadlineDate={data.deadlineDate}
                                            isExpired={data.isExpired}
                                            className="mr-[20px]"
                                            setIsChangeDeadlineOpened={setIsChangeDeadlineOpened}
                                            setChangeDeadLineStatus={setChangeDeadLineStatus}
                                        />
                                    )}
                                    {isEditablePagesProject ? (
                                        <>
                                            <BbCardEditButton
                                                isEditMode={isEditMode}
                                                setIsEditMode={setIsEditMode}
                                                isSendRequestAvailable={
                                                    isSendRequestAvailable && !isEditMode && !data?.isExpired
                                                }
                                                handleSubmit={handleSubmit}
                                                isSubmitting={isSubmitting || data?.isExpired}
                                            />
                                            <BbCardSendRequestButton
                                                setIsEditMode={setIsEditMode}
                                                className="ml-[20px]"
                                                isEditMode={isEditMode}
                                                isActive={!isEditMode && isSendRequestAvailable && !data?.isExpired}
                                                onSendProviderRequest={onSendProviderRequest}
                                            />
                                        </>
                                    ) : (
                                        <div className="w-[500px] h-[50px]" />
                                    )}
                                </div>
                            </div>
                            <div className="mt-[40px] mb-[10px]">
                                {isEditMode ? (
                                    <BbCardContentEditMode
                                        handleBlur={handleBlur}
                                        touched={touched}
                                        errors={errors}
                                        setFieldValue={setFieldValue}
                                        handleChange={handleChange}
                                        values={values}
                                    />
                                ) : (
                                    <BbCardContentReadMode
                                        data={data}
                                        projectId={projectId}
                                        buildingBlockId={buildingBlockId}
                                        refetchBuildingBlockInfo={refetch}
                                    />
                                )}
                            </div>
                        </Form>
                    </div>
                )}
            </Formik>
            {isChangeDeadlineOpened && (
                <DeadLineWindow
                    status={changeDeadLineStatus}
                    isOpen={isChangeDeadlineOpened}
                    setIsOpen={setIsChangeDeadlineOpened}
                    projectId={projectId}
                    buildingBlockId={buildingBlockId}
                    refetchBuildingBlockInfo={refetch}
                />
            )}
        </>
    );
};

export default BuildingBlockCard;

BuildingBlockCard.propTypes = {
    projectId: oneOfType([string, number]),
    buildingBlockId: oneOfType([string, number]),
    setIsEditMode: func.isRequired,
    isEditMode: bool.isRequired,
};
