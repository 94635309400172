import React from 'react';
import { useTranslation } from 'react-i18next';
import { i18PAGES } from '../../config/i18n/constants';

const InvoiceDetailsInfo = () => {
    const { t } = useTranslation(i18PAGES.INVOICE);

    return (
        <div className="text-int-white-main mt-[40px]">
            {/* TODO TEMPORARY HIDDEN */}
            {/* <div className="text-[18px] leading-[22px] font-Lexend600 mb-[20px]">{t('Invoice details')}:</div> */}
            <div className="text-[18px] leading-[22px] font-Lexend600">{t('Payment details')}:</div>
            <div className="mt-[20px]">
                <div className="flex mb-[20px]">
                    <div className="w-[5px] h-[5px] rounded-full bg-int-white-main mr-[10px] mt-[10px]" />
                    <div className="text-[16px] leading-[24px] font-Lexend400">
                        {t('Access to the platform available only after we receive transaction from your bank.')}
                    </div>
                </div>
                <div className="flex mb-[20px]">
                    <div className="w-[5px] h-[5px] rounded-full bg-int-white-main mr-[10px] mt-[10px]" />
                    <div className="text-[16px] leading-[24px] font-Lexend400">
                        {t('Note that there might be additional fees applied by you bank.')}
                    </div>
                </div>
                <div className="flex mb-[20px]">
                    <div className="w-[5px] h-[5px] rounded-full bg-int-white-main mr-[10px] mt-[10px]" />
                    <div className="text-[16px] leading-[24px] font-Lexend400">
                        {t(
                            '1 month before your subscription ends you will get notification to prolong it. There is no auto renewal, so when subscription end you will need to update payment.'
                        )}
                    </div>
                </div>
                <div className="flex">
                    <div className="w-[5px] h-[5px] rounded-full bg-int-white-main mr-[10px] mt-[10px]" />
                    <div className="text-[16px] leading-[24px] font-Lexend400">
                        {t(
                            'By subscribing, you agree to our user agreement, our privacy policy and to receive marketing and account-related emails from our company.'
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InvoiceDetailsInfo;
