import React, { useEffect, useState } from 'react';
import { func } from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SETUP_PAGES, STEP_TYPE } from '../../util/constants';
import { useCompanySetup } from '../../../store/hooks/useCompanySetup';
import StepView from '../StepView';
import { step4InitialValues } from '../stepValidation';
import SetupOfficeItem from './SetupOfficeItem';
import { i18PAGES } from '../../../config/i18n/constants';
import ChangeStepInfoModalContent from '../ChangeStepInfoModalContent';
import i18n from '../../../config/i18n/i18n';
import ModalWindow from '../../../style/ModalWindow/ModalWindow';

const Step4Offices = ({ setSetupCurrentPage }) => {
    const { companySetupInfo, clearCompanySetupState, setOffices } = useCompanySetup();
    const [filledArray, setFilledArray] = useState([]);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const { t } = useTranslation(i18PAGES.SETUP_COMPANIES);

    const [isBackButtonPressed, setIsBackButtonPressed] = useState(false);
    const [isStepBackAgree, setIsStepBackAgree] = useState(false);

    const onBackButtonPress = () => {
        setIsBackButtonPressed(true);
        setOffices(['1']);
    };

    useEffect(() => {
        if (isStepBackAgree === true) {
            setSetupCurrentPage(SETUP_PAGES.STEP_3);
        }
    }, [isStepBackAgree, setSetupCurrentPage]);

    const checkAsReady = (index) => {
        setFilledArray((prev) => {
            if (!prev?.includes(index)) {
                return [...prev, index];
            }

            return prev;
        });
    };

    const onFinishClick = () => {
        if (filledArray?.length === initialArray?.length) {
            clearCompanySetupState();

            const navigateUrl = companySetupInfo?.companyTree?.id
                ? `/companies-list?companyId=${companySetupInfo?.companyTree?.id}`
                : '/companies-list';
            localStorage.setItem('isNewCompanyCreated', 'true');
            navigate(navigateUrl);
        } else {
            setError('You should fill and save all fields');
        }
    };

    const initialArray = step4InitialValues(companySetupInfo);

    return (
        <div className="w-[1019px] m-auto mt-[50px] mb-[30px]">
            <StepView currentStepNumber={4} type={STEP_TYPE.COMPANIES} />

            <div>
                <div className=" mt-[70px] bg-int-gray-main rounded-[8px] h-[122px] p-[30px] flex justify-between items-center">
                    <div>
                        <div className="font-Lexend500 text-[24px] leading-[24px] text-int-white-main">
                            {t('Setup Offices')}
                        </div>
                        <div className="text-[14px] leading-[22px] mt-[16px]">{t('All fields are required')}</div>
                    </div>
                    <div className="flex flex-col items-center">
                        <div>
                            <button
                                className="text-int-white-main border border-int-gray-secondary rounded-[8px] w-[140px] h-[42px] font-Lexend400"
                                type="button"
                                onClick={onBackButtonPress}
                            >
                                {t('Go Back')}
                            </button>

                            <button
                                className="text-int-white-main border border-int-grapefruit bg-int-grapefruit ml-[20px] rounded-[8px] w-[140px] h-[42px] font-Lexend400"
                                type="button"
                                onClick={onFinishClick}
                            >
                                {t('Finish')}
                            </button>
                        </div>
                        {error && <div className="mt-[10px] text-[14px] text-int-grapefruit">{error}</div>}
                    </div>
                </div>

                <div className="mt-[15px] w-[100%]">
                    {initialArray.length &&
                        initialArray.map((officeItem, i) => (
                            <div key={i}>
                                <SetupOfficeItem index={i} officeItem={officeItem} checkAsReady={checkAsReady} />
                            </div>
                        ))}
                </div>
            </div>
            <ModalWindow isOpen={isBackButtonPressed} setIsOpen={setIsBackButtonPressed}>
                <ChangeStepInfoModalContent
                    setIsAgree={setIsStepBackAgree}
                    setIsModalOpened={setIsBackButtonPressed}
                    text={i18n.t('Are you sure you want to go back? Your current data can be lost')}
                />
            </ModalWindow>
        </div>
    );
};
export default Step4Offices;

Step4Offices.propTypes = {
    setSetupCurrentPage: func,
};
