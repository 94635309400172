import React from 'react';
import { bool, func, number, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useToast } from '../../../hoc/toast/ToastProvider';
import { PrimaryButton } from '../../../style/Button/Button';
import ModalWindow from '../../../style/ModalWindow/ModalWindow';
import { i18PAGES } from '../../../config/i18n/constants';
import { useDeleteWorkersInformationMutation } from '../../../store/API/workersInformationAPI';

const DeleteWorkerModal = ({ isOpenDeleteWorkerModal, setIsOpenDeleteWorkerModal, id, fullName, projectId }) => {
    const { t } = useTranslation(i18PAGES.WORKERS_EFFICIENCY);

    const { successToast, errorToast } = useToast();

    const [deleteWorkers, { isLoading: isUpdating }] = useDeleteWorkersInformationMutation();

    const handleDeleteClick = () => {
        deleteWorkers({
            projectId,
            workerDetailsId: id,
        })
            .unwrap()
            .then(() => {
                successToast(t('Workers was successfully deleted'));
            })
            .catch(() => {
                errorToast(t('Error workers delete'));
            })
            .finally(() => {
                setIsOpenDeleteWorkerModal(false);
            });
    };

    return (
        <ModalWindow
            isOpen={isOpenDeleteWorkerModal}
            setIsOpen={setIsOpenDeleteWorkerModal}
            className="-top-[210px] w-[580px]"
            isCloseClickOutside
        >
            <div className="flex flex-col items-center">
                <div className="text-[24px] leading-[24px] font-Lexend500 text-int-white-main">
                    {t('Delete Worker')}
                </div>
                <div className="text-[18px] leading-[24px] font-Lexend400 text-int-white-main my-[50px] text-center">
                    {t('Are you sure you want to delete worker')} {`${fullName} ?`}
                </div>

                <div className="w-[100%] flex justify-around">
                    <PrimaryButton
                        className="w-[210px] h-[50px]"
                        handleClick={() => {
                            setIsOpenDeleteWorkerModal(false);
                        }}
                    >
                        <div className="text-[18px] font-Lexend400">{t('Cancel')}</div>
                    </PrimaryButton>
                    <PrimaryButton
                        isPink
                        className="w-[210px] h-[50px]"
                        handleClick={() => {
                            handleDeleteClick(id);
                        }}
                        disabled={isUpdating}
                    >
                        <div className="text-[18px] font-Lexend400">{t('Delete')}</div>
                    </PrimaryButton>
                </div>
            </div>
        </ModalWindow>
    );
};

export default DeleteWorkerModal;

DeleteWorkerModal.propTypes = {
    isOpenDeleteWorkerModal: bool.isRequired,
    setIsOpenDeleteWorkerModal: func.isRequired,
    id: number,
    fullName: string,
    projectId: string.isRequired,
};
