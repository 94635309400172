import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { func, string } from 'prop-types';
import ToastWindow from '../../../../style/ToastWindow/ToastWindow';
import { i18PAGES } from '../../../../config/i18n/constants';
import { ReactComponent as ModalWarningIcon } from '../../../../assets/icons/modalWarningIcon.svg';

export const VARIATION_WARNING_TYPES = {
    SIMILAR_OPTIONS: 'SIMILAR_OPTIONS',
    SIMILAR_VARIANTS: 'SIMILAR_VARIANTS',
    NOT_ALL_PROVIDERS_SELECTED: 'NOT_ALL_PROVIDERS_SELECTED',
};

const VariationsWarningModal = (props) => {
    const { t } = useTranslation(i18PAGES.OPTIONS);

    const { warningMessageType, setWarningMessageType } = props;

    const onClose = useCallback(() => {
        setWarningMessageType(undefined);
    }, [setWarningMessageType]);

    return (
        <ToastWindow isOpen={!!warningMessageType} isShowCloseIcon handleCloseIconClick={onClose} width={740}>
            <div className="flex flex-col items-center justify-center text-int-white-main">
                <div className="flex items-center text-[16px] font-Lexend500">
                    <ModalWarningIcon />
                    <div className="ml-[10px]">
                        {warningMessageType === VARIATION_WARNING_TYPES.SIMILAR_OPTIONS && t('This similar options')}
                        {warningMessageType === VARIATION_WARNING_TYPES.SIMILAR_VARIANTS &&
                            t('This variation already exist')}
                        {warningMessageType === VARIATION_WARNING_TYPES.NOT_ALL_PROVIDERS_SELECTED &&
                            t('Providers are not selected')}
                    </div>
                </div>
                <div className="text-[14px]">
                    {warningMessageType === VARIATION_WARNING_TYPES.SIMILAR_OPTIONS &&
                        t('You have created 2 similar options. Reselect providers for one of these options')}
                    {warningMessageType === VARIATION_WARNING_TYPES.SIMILAR_VARIANTS &&
                        t('This combination is already in your list. To create a new one select other providers')}
                    {warningMessageType === VARIATION_WARNING_TYPES.NOT_ALL_PROVIDERS_SELECTED &&
                        t('Select providers for every building blocks')}
                </div>
            </div>
        </ToastWindow>
    );
};

export default VariationsWarningModal;

VariationsWarningModal.propTypes = {
    warningMessageType: string,
    setWarningMessageType: func.isRequired,
};
