import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { HEADERS } from '../../components/util/constants';

export const authAPI = createApi({
    reducerPath: 'authAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api',
        prepareHeaders: HEADERS,
    }),
    endpoints: (build) => ({
        changePassword: build.mutation({
            query: (body) => ({
                url: '/account/change-password',
                method: 'POST',
                body,
            }),
        }),
        logoutUser: build.mutation({
            query: (body) => ({
                url: '/logout',
                method: 'POST',
                body,
            }),
        }),
        resendRegistrationEmail: build.mutation({
            query: (body) => ({
                url: '/users/resend-registration-email',
                method: 'POST',
                body,
            }),
        }),
        resendResetPassword: build.mutation({
            query: (body) => ({
                url: '/account/reset-password/init',
                method: 'POST',
                body,
            }),
        }),
    }),
});

export const {
    useChangePasswordMutation,
    useLogoutUserMutation,
    useResendRegistrationEmailMutation,
    useResendResetPasswordMutation,
} = authAPI;
