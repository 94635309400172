import React from 'react';
import { shape, string } from 'prop-types';
import { OnlyViewFieldTextArea } from '../../../../Profile/OnlyViewFieldInput';

const HrStrategyReadItem = ({ hrStrategy }) => (
    <div className="flex mb-[16px]">
        <div className="w-[370px] mr-[10px]">
            <OnlyViewFieldTextArea styles="h-[84px]" label="" value={hrStrategy.name || ''} />
        </div>
        <div className="w-[1000px]">
            <OnlyViewFieldTextArea styles="h-[84px]" label="" value={hrStrategy.strategyPoints || ''} />
        </div>
    </div>
);

export default HrStrategyReadItem;

HrStrategyReadItem.propTypes = {
    hrStrategy: shape({
        name: string.isRequired,
        strategyPoints: string.isRequired,
    }),
};
