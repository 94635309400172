import React from 'react';
import { arrayOf, shape, string } from 'prop-types';
import ProblemsOverviewTableLabels from '../ProblemsOverviewTableLabels';
import ProblemsOverviewReadItem from './ProblemsOverviewReadItem';
import { problemsOverviewEmptyObject } from '../../Common/constants';

const ProblemsOverviewReadMode = ({ problems }) => (
    <div className="bg-int-gray-main p-[28px] pr-[56px] rounded-[8px]">
        <ProblemsOverviewTableLabels />
        {problems?.length > 0 && problems?.map((p, index) => <ProblemsOverviewReadItem key={index} problem={p} />)}
        {problems?.length === 0 && <ProblemsOverviewReadItem problem={problemsOverviewEmptyObject} />}
    </div>
);

export default ProblemsOverviewReadMode;

ProblemsOverviewReadMode.propTypes = {
    problems: arrayOf(
        shape({
            name: string.isRequired,
            description: string.isRequired,
        }).isRequired
    ),
};
