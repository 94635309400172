import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    data: {},
};

const tabsMenuSlice = createSlice({
    name: 'tabs-menu',
    initialState,
    reducers: {
        setTabsMenu(state, action) {
            state.data = action.payload;
        },
    },
});

export const { setTabsMenu } = tabsMenuSlice.actions;

export default tabsMenuSlice.reducer;
