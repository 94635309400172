import React from 'react';
import { useNavigate } from 'react-router-dom';
import i18n from '../config/i18n/i18n';
import { PrimaryButton } from '../style/Button/Button';
import { ReactComponent as PageIsNotExistIcon } from '../assets/icons/not-exist icon.svg';

const ServerErrorPage = () => {
    const navigate = useNavigate();
    const onHomeButtonClick = () => {
        navigate('/');
    };

    return (
        <div className="text-center h-[calc(100vh-300px)] flex flex-col items-center justify-center text-int-white-main px-[30px] ">
            <div>
                <PageIsNotExistIcon width={90} height={91} fill="#FF4F66" />
            </div>
            <div className="text-[24px] leading-[32px] font-Lexend500 mt-[30px]">
                {i18n.t('This Page isn’t available right now')}
            </div>
            <div className="text-[16px] leading-[20px] font-Lexend400 mt-[20px] mb-[40px]">
                {i18n.t(
                    'This may be because of a technical error that we’re working to get fixed. Try to visit this page later'
                )}
            </div>
            <PrimaryButton isPink className="px-[20px] h-[50px] text-[18px]" handleClick={onHomeButtonClick}>
                <div className="font-Lexend400 text-[18px] leading-[18px]">{i18n.t('Go to Homepage')}</div>
            </PrimaryButton>
        </div>
    );
};

export default ServerErrorPage;
