import React from 'react';
import { bool, func, string } from 'prop-types';
import { Field } from 'formik';
import { ReactComponent as CheckboxIcon } from '../../assets/icons/checkboxIcon.svg';

const Checkbox = ({
    id,
    name,
    label,
    subLabel,
    className,
    value,
    isShowOnly = false,
    isEnabled = true,
    short = false,
    handleClick,
    disabled = false,
    centerTextCheckbox = true,
}) => {
    const onClick = () => {
        if (handleClick) {
            handleClick();
        }
    };

    return (
        <div className={`${short && 'flex items-center'} ${disabled && 'opacity-30'}`}>
            <label
                htmlFor={name}
                className={`flex ${!short && 'mb-[8px] mt-[8px]'}  ${
                    !isShowOnly && !disabled && 'cursor-pointer'
                } inline-flex flex-col`}
                onClick={disabled ? null : () => onClick()}
            >
                <div className={`flex ${centerTextCheckbox && 'items-center'}`}>
                    {value ? (
                        <div
                            className={`flex justify-center items-center w-[18px] h-[18px] min-w-[18px] ${
                                isShowOnly ? 'bg-int-gray-secondary' : 'bg-int-grapefruit'
                            } rounded-sm ${!centerTextCheckbox && 'mt-[4px]'}`}
                        >
                            <CheckboxIcon />
                        </div>
                    ) : (
                        <div
                            className={`w-[18px] h-[18px] min-w-[18px] border ${
                                isEnabled ? 'border-int-white-secondary' : 'border-int-gray-secondary'
                            }  rounded-sm ${!centerTextCheckbox && 'mt-[4px]'}`}
                        />
                    )}
                    <Field id={id} name={name} type="checkbox" className="hidden" disabled={disabled} />
                    {label && (
                        <span className={`${className} ${value && 'text-int-white-main'} break-words pl-[12px]`}>
                            {label}
                        </span>
                    )}
                </div>
                {subLabel && (
                    <div className="pl-[30px]">
                        <span className="text-[12px] text-int-white-secondary">{subLabel}</span>
                    </div>
                )}
            </label>
        </div>
    );
};

Checkbox.propTypes = {
    id: string,
    name: string,
    label: string,
    subLabel: string,
    className: string,
    value: bool,
    isShowOnly: bool,
    isEnabled: bool,
    short: bool,
    handleClick: func,
    disabled: bool,
    centerTextCheckbox: bool,
};

export default Checkbox;
