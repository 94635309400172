import React, { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as PageIsNotAvailableIcon } from '../../assets/icons/pageIsNotAvailableIcon.svg';
import i18n from '../../config/i18n/i18n';
import { PrimaryButton } from '../../style/Button/Button';

const PageNotAvailable = () => {
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const isNotProjectPage = useMemo(() => pathname === '/not-available', [pathname]);

    const onBackButtonClick = () => {
        if (isNotProjectPage) {
            navigate('/');
        } else {
            navigate(`/projects-list`);
        }
    };

    return (
        <div className="text-center h-[calc(100vh-300px)] flex flex-col items-center justify-center text-int-white-main px-[30px] ">
            <div>
                <PageIsNotAvailableIcon width={90} height={91} fill="#FF4F66" />
            </div>
            <div className="text-[24px] leading-[32px] font-Lexend500 mt-[40px]">{i18n.t('Page is not available')}</div>
            <div className="text-[16px] leading-[20px] font-Lexend400 mt-[20px] mb-[40px]">
                {i18n.t('You have no access to this page or content is not available. Please return to the ')}
                {isNotProjectPage ? i18n.t('home page.') : i18n.t('projects page.')}
            </div>
            <PrimaryButton isPink className="px-[20px] h-[50px] text-[18px]" handleClick={onBackButtonClick}>
                <div className="font-Lexend400 text-[18px] leading-[18px]">
                    {isNotProjectPage ? i18n.t('Return to the home page') : i18n.t('Return to the projects page')}
                </div>
            </PrimaryButton>
        </div>
    );
};

export default PageNotAvailable;
