import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import { object, ref, string } from 'yup';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { PrimaryButton } from '../../style/Button/Button';
import ModalWindow from '../../style/ModalWindow/ModalWindow';
import FInput from '../../style/Input/Input';
import { REGEX } from '../util/constants';
import { ReactComponent as SuccessPassChangeIcon } from '../../assets/icons/successPassChangeIcon.svg';
import { useChangePasswordMutation } from '../../store/API/authAPI';
import { i18PAGES } from '../../config/i18n/constants';
import { useToast } from '../../hoc/toast/ToastProvider';

const validationSchema = object({
    password_old: string().required('Password should be stated'),
    password: string()
        .required('Password should be stated')
        .matches(
            REGEX.PASSWORD,
            'Password should contain minimum 8 characters, at least 1 lower case letter and at least 1 higher case letter'
        ),
    password_confirmation: string()
        .required('Passwords do not match')
        .oneOf([ref('password'), null], 'Passwords do not match'),
});

const ChangePassword = () => {
    const { t } = useTranslation(i18PAGES.PROFILE);
    const { successToast, errorToast } = useToast();

    const [isOpenModalChangePass, setIsOpenModalChangePass] = useState(false);

    const [changePassword] = useChangePasswordMutation();

    return (
        <div className="flex justify-between items-center bg-int-gray-main rounded-lg px-[40px] pt-[20px] pb-[20px] mb-[30px]">
            <div className="text-[24px] text-int-white-main font-Lexend500">{t('Password')}</div>
            <PrimaryButton
                isGrey
                className="border-0 h-[50px] min-w-[170px]"
                handleClick={() => setIsOpenModalChangePass(true)}
            >
                <div className="text-[16px] font-Lexend400">{t('Change password')}</div>
            </PrimaryButton>

            <ModalWindow
                isOpen={isOpenModalChangePass}
                setIsOpen={setIsOpenModalChangePass}
                className="-top-[40px] w-[580px]"
                isCloseClickOutside
            >
                <div className="flex flex-col items-center px-[30px]">
                    <div className="text-[24px] font-Lexend500 text-int-white-main mb-[50px]">
                        {t('Change Password')}
                    </div>

                    <Formik
                        onSubmit={(values, { setSubmitting, setFieldError }) => {
                            changePassword({
                                currentPassword: values.password_old,
                                newPassword: values.password,
                            })
                                .unwrap()
                                .then((res) => {
                                    setIsOpenModalChangePass(false);
                                    successToast(
                                        'Your password was successfully updated',
                                        'You can always change the password in the password settings',
                                        <SuccessPassChangeIcon />
                                    );
                                })
                                .catch((err) => {
                                    if (err.data.message === 'Incorrect password') {
                                        setFieldError('password_old', 'Password is incorrect');
                                    } else if (
                                        err.data.message ===
                                        'Your new password must be different from your current password'
                                    ) {
                                        setFieldError('password_old', 'Your new password must be different');
                                    } else {
                                        errorToast(i18n.t('Something went wrong, try again later'));
                                    }
                                })
                                .finally(() => {
                                    setSubmitting(false);
                                });
                        }}
                        validationSchema={validationSchema}
                        initialValues={{
                            password_old: '',
                            password: '',
                            password_confirmation: '',
                        }}
                    >
                        {({
                            values,
                            touched,
                            errors,
                            isSubmitting,
                            handleSubmit,
                            handleBlur,
                            handleChange,
                            setFieldValue,
                        }) => (
                            <Form className="w-[100%] relative">
                                <FInput
                                    name="password_old"
                                    placeholder="Current password"
                                    value={values.password_old}
                                    handleBlur={handleBlur}
                                    handleChange={handleChange}
                                    className="w-[100%] mt-[22px] min-h-[70px]"
                                    type="password"
                                    label="Current password"
                                    isError={!!(touched.password_old && errors.password_old)}
                                    errorText={errors.password_old}
                                    isPassword
                                    setFieldValue={setFieldValue}
                                />
                                <div className="text-[14px] text-int-white-main font-Lexend400 absolute top-[70px] right-0 cursor-pointer">
                                    <Link to="/forgot-password" target="_blank">
                                        {t('Forgot password?')}
                                    </Link>
                                </div>

                                <FInput
                                    name="password"
                                    placeholder="Passwort eingeben"
                                    value={values.password}
                                    handleBlur={handleBlur}
                                    handleChange={handleChange}
                                    className="w-[100%] mt-[50px] min-h-[70px]"
                                    type="password"
                                    label="Passwort"
                                    isError={!!(touched.password && errors.password)}
                                    errorText={errors.password}
                                    isPassword
                                    setFieldValue={setFieldValue}
                                />

                                <FInput
                                    name="password_confirmation"
                                    placeholder="Passwort wiederholen"
                                    value={values.password_confirmation}
                                    handleBlur={handleBlur}
                                    handleChange={handleChange}
                                    className="w-[100%] mt-[22px] min-h-[70px] mb-[28px]"
                                    type="password"
                                    label="Passwort wiederholen"
                                    isError={!!(touched.password_confirmation && errors.password_confirmation)}
                                    errorText={errors.password_confirmation}
                                    isPassword
                                    setFieldValue={setFieldValue}
                                />

                                <div className="w-[100%] flex justify-between mb-[22px]">
                                    <PrimaryButton
                                        className="w-[210px]"
                                        handleClick={() => {
                                            setIsOpenModalChangePass(false);
                                        }}
                                    >
                                        <div className="text-[18px] font-Lexend400">{t('Cancel')}</div>
                                    </PrimaryButton>
                                    <PrimaryButton
                                        isPink
                                        className="w-[210px]"
                                        handleClick={handleSubmit}
                                        disabled={isSubmitting}
                                    >
                                        <div className="text-[18px] font-Lexend400">{t('Change password')}</div>
                                    </PrimaryButton>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </ModalWindow>
        </div>
    );
};

export default ChangePassword;
