import React from 'react';
import { useTranslation } from 'react-i18next';
import { bool, func, object } from 'prop-types';
import { i18PAGES } from '../../../../../config/i18n/constants';
import { CompetitiveStrategy, MarketGrowthStrategy, TimingStrategy } from '../../../../util/constants';
import ReadOnlyRadioItem from '../../OfficeItemReadMode/ReadOnlyRadioItem';
import Checkbox from '../../../../../style/Checkbox/Checkbox';
import RadioInput from '../../../../../style/RadioInput/RadioInput';

const StrategyEditItem = ({ values, errors, isSubmitPressed, setFieldValue }) => {
    const { t } = useTranslation(i18PAGES.COMPANIES_INFO);
    return (
        <div className="pl-[30px] pb-[60px]">
            <div className="text-[14px] leading-[19px] font-Lexend600 uppercase">{t('Strategy')}</div>
            <div className="flex items-center mt-[30px]">
                <div>
                    <div className="flex">
                        <div>
                            <Checkbox
                                name="isGrowthStrategy"
                                id="isGrowthStrategy"
                                label="Marktwachstumsstrategie"
                                className="text-[14px] font-Lexend400 my-[-8px]"
                                value={values?.isGrowthStrategy}
                            />

                            {isSubmitPressed && errors?.marketGrowthStrategy && (
                                <div className="mt-[10px] text-[14px] text-error">{errors?.marketGrowthStrategy}</div>
                            )}
                            <div className="pl-[30px]">
                                {values?.isGrowthStrategy ? (
                                    <>
                                        <div className="mt-[16px]">
                                            <RadioInput
                                                name="marketGrowthStrategy"
                                                value={MarketGrowthStrategy.DIVERSIFICATION}
                                                label="Diversifikation"
                                                setFieldValue={setFieldValue}
                                                isChecked={
                                                    values?.marketGrowthStrategy ===
                                                    MarketGrowthStrategy.DIVERSIFICATION
                                                }
                                            />
                                        </div>
                                        <div className="mt-[16px]">
                                            <RadioInput
                                                name="marketGrowthStrategy"
                                                value={MarketGrowthStrategy.MARKET_PENETRATION}
                                                label="Marktdurchdringung"
                                                setFieldValue={setFieldValue}
                                                isChecked={
                                                    values?.marketGrowthStrategy ===
                                                    MarketGrowthStrategy.MARKET_PENETRATION
                                                }
                                            />
                                        </div>
                                        <div className="mt-[16px]">
                                            <RadioInput
                                                name="marketGrowthStrategy"
                                                value={MarketGrowthStrategy.PRODUCT_DEVELOPMENT}
                                                label="Productentwicklung"
                                                setFieldValue={setFieldValue}
                                                isChecked={
                                                    values?.marketGrowthStrategy ===
                                                    MarketGrowthStrategy.PRODUCT_DEVELOPMENT
                                                }
                                            />
                                        </div>
                                        <div className="mt-[16px]">
                                            <RadioInput
                                                name="marketGrowthStrategy"
                                                value={MarketGrowthStrategy.MARKET_DEVELOPMENT}
                                                label="Marktentwicklung"
                                                setFieldValue={setFieldValue}
                                                isChecked={
                                                    values?.marketGrowthStrategy ===
                                                    MarketGrowthStrategy.MARKET_DEVELOPMENT
                                                }
                                            />
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className="mt-[16px]">
                                            <ReadOnlyRadioItem
                                                label="Diversifikation"
                                                isEnabled={values?.isGrowthStrategy}
                                            />
                                        </div>
                                        <div className="mt-[16px]">
                                            <ReadOnlyRadioItem
                                                label="Marktdurchdringung"
                                                isEnabled={values?.isGrowthStrategy}
                                            />
                                        </div>
                                        <div className="mt-[16px]">
                                            <ReadOnlyRadioItem
                                                label="Productentwicklung"
                                                isEnabled={values?.isGrowthStrategy}
                                            />
                                        </div>
                                        <div className="mt-[16px]">
                                            <ReadOnlyRadioItem
                                                label="Marktentwicklung"
                                                isEnabled={values?.isGrowthStrategy}
                                            />
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>

                        <div className="ml-[45px]">
                            <Checkbox
                                name="isTimingStrategy"
                                id="isTimingStrategy"
                                label="Timingstrategie"
                                className="text-[14px] font-Lexend400 my-[-8px]"
                                value={values?.isTimingStrategy}
                            />

                            {isSubmitPressed && errors?.timingStrategy && (
                                <div className="mt-[10px] text-[14px] text-error">{errors?.timingStrategy}</div>
                            )}
                            <div className="pl-[30px]">
                                {values?.isTimingStrategy ? (
                                    <>
                                        <div className="mt-[16px]">
                                            <RadioInput
                                                name="timingStrategy"
                                                value={TimingStrategy.FOLLOW_UP_STRATEGY}
                                                label="Folgestrategie"
                                                setFieldValue={setFieldValue}
                                                isChecked={values?.timingStrategy === TimingStrategy.FOLLOW_UP_STRATEGY}
                                            />
                                        </div>

                                        <div className="mt-[16px]">
                                            <RadioInput
                                                name="timingStrategy"
                                                value={TimingStrategy.PIONEER_STRATEGY}
                                                label="Pionierstrategie"
                                                setFieldValue={setFieldValue}
                                                isChecked={values?.timingStrategy === TimingStrategy.PIONEER_STRATEGY}
                                            />
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className="mt-[16px]">
                                            <ReadOnlyRadioItem
                                                label="Folgestrategie"
                                                isEnabled={values?.isTimingStrategy}
                                            />
                                        </div>
                                        <div className="mt-[16px]">
                                            <ReadOnlyRadioItem
                                                label="Pionierstrategie"
                                                isEnabled={values?.isTimingStrategy}
                                            />
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="mt-[30px]">
                        <Checkbox
                            name="isCompetitiveStrategy"
                            id="isCompetitiveStrategy"
                            label="Wettbewerbsstrategie"
                            className="text-[14px] font-Lexend400 my-[-8px]"
                            value={values?.isCompetitiveStrategy}
                        />

                        {isSubmitPressed && errors?.competitiveStrategy && (
                            <div className="mt-[10px] text-[14px] text-error">{errors?.competitiveStrategy}</div>
                        )}
                        <div className="pl-[30px]">
                            {values.isCompetitiveStrategy ? (
                                <>
                                    <div className="mt-[16px]">
                                        <RadioInput
                                            name="competitiveStrategy"
                                            value={CompetitiveStrategy.QUALITY_LEADERSHIP}
                                            label="Qualitätsführerschaft"
                                            setFieldValue={setFieldValue}
                                            isChecked={
                                                values?.competitiveStrategy === CompetitiveStrategy.QUALITY_LEADERSHIP
                                            }
                                        />
                                    </div>
                                    <div className="mt-[16px]">
                                        <RadioInput
                                            name="competitiveStrategy"
                                            value={CompetitiveStrategy.NICHE_STRATEGY}
                                            label="Nischenstrategie"
                                            setFieldValue={setFieldValue}
                                            isChecked={
                                                values?.competitiveStrategy === CompetitiveStrategy.NICHE_STRATEGY
                                            }
                                        />
                                    </div>
                                    <div className="mt-[16px]">
                                        <RadioInput
                                            name="competitiveStrategy"
                                            value={CompetitiveStrategy.COST_LEADERSHIP}
                                            label="Kostenführerschaft"
                                            setFieldValue={setFieldValue}
                                            isChecked={
                                                values?.competitiveStrategy === CompetitiveStrategy.COST_LEADERSHIP
                                            }
                                        />
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="mt-[16px]">
                                        <ReadOnlyRadioItem
                                            label="Qualitätsführerschaft"
                                            isEnabled={values?.isCompetitiveStrategy}
                                        />
                                    </div>
                                    <div className="mt-[16px]">
                                        <ReadOnlyRadioItem
                                            label="Nischenstrategie"
                                            isEnabled={values?.isCompetitiveStrategy}
                                        />
                                    </div>
                                    <div className="mt-[16px]">
                                        <ReadOnlyRadioItem
                                            label="Kostenführerschaft"
                                            isEnabled={values?.isCompetitiveStrategy}
                                        />
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StrategyEditItem;

StrategyEditItem.propTypes = {
    values: object.isRequired,
    errors: object.isRequired,
    isSubmitPressed: bool,
    setFieldValue: func.isRequired,
};
