import { Link } from 'react-router-dom';
import React from 'react';
import { arrayOf, shape, string } from 'prop-types';
import { ReactComponent as HomeBreadcrumbIcon } from '../../assets/icons/homeBreadcrumbIcon.svg';
import { ReactComponent as PaginationRightIcon } from '../../assets/icons/paginationRightIcon.svg';

const Breadcrumb = ({ breadCrumbDetail }) => (
    <div className="flex items-center">
        <Link to="/">
            <HomeBreadcrumbIcon />
        </Link>

        {breadCrumbDetail.map((item) => (
            <span className="flex items-center" key={item.name}>
                <div className="mx-[14px]">
                    <PaginationRightIcon fill="#EDF1FD" />
                </div>
                <Link to={item.path}>
                    <div className="text-[18px] font-Lexend400 text-int-white-main">{item.name}</div>
                </Link>
            </span>
        ))}
    </div>
);

export default Breadcrumb;

Breadcrumb.propTypes = {
    breadCrumbDetail: arrayOf(
        shape({
            name: string.isRequired,
            path: string.isRequired,
        })
    ).isRequired,
};
