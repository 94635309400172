import React from 'react';
import { useTranslation } from 'react-i18next';
import TabItem from './TabItem';
import { i18PAGES } from '../../config/i18n/constants';

const BonusFeaturesBlock = () => {
    const { t } = useTranslation(i18PAGES.HR_TOOLS);

    return (
        <TabItem headerText="Bonusly funktionen" openHeight={250}>
            <div className="mt-[30px] pl-[10px] pr-[20px]">
                <div className="text-int-white-main text-[16px] pl-[10px] leading-[16px] font-Lexend400">
                    {t('Von Benutzern am meisten geschätzte Funktionen')}
                </div>
                <div className="flex justify-between text-[14px] leading-[14px] mt-[32px] pl-[10px] pr-[20px]">
                    <div>
                        <div className="flex items-center">
                            <div className="bg-int-white-main rounded-full h-[6px] w-[6px] mr-[15px]" />
                            <div>{t('Reporting/Analytics')}</div>
                        </div>
                        <div className="flex items-center mt-[16px]">
                            <div className="bg-int-white-main rounded-full h-[6px] w-[6px] mr-[15px]" />
                            <div>{t('Reporting & Statistics')}</div>
                        </div>
                        <div className="flex items-center mt-[16px]">
                            <div className="bg-int-white-main rounded-full h-[6px] w-[6px] mr-[15px]" />
                            <div>{t('Activity Dashboard')}</div>
                        </div>
                        <div className="flex items-center mt-[16px]">
                            <div className="bg-int-white-main rounded-full h-[6px] w-[6px] mr-[15px]" />
                            <div>{t('API')}</div>
                        </div>
                    </div>
                    <div>
                        <div className="flex items-center">
                            <div className="bg-int-white-main rounded-full h-[6px] w-[6px] mr-[15px]" />
                            <div>{t('Notifications')}</div>
                        </div>
                        <div className="flex items-center mt-[16px]">
                            <div className="bg-int-white-main rounded-full h-[6px] w-[6px] mr-[15px]" />
                            <div>{t('Third Party Integrations')}</div>
                        </div>
                        <div className="flex items-center mt-[16px]">
                            <div className="bg-int-white-main rounded-full h-[6px] w-[6px] mr-[15px]" />
                            <div>{t('Customizable Reports')}</div>
                        </div>
                        <div className="flex items-center mt-[16px]">
                            <div className="bg-int-white-main rounded-full h-[6px] w-[6px] mr-[15px]" />
                            <div>{t('Data Import/Export')}</div>
                        </div>
                    </div>
                </div>
            </div>
        </TabItem>
    );
};

export default BonusFeaturesBlock;
