import React from 'react';
import { useTranslation } from 'react-i18next';
import { arrayOf, func, object, number } from 'prop-types';
import FteSelectedFilterItem from './FTESelectedFilterItem';
import { i18PAGES } from '../../../../../config/i18n/constants';
import { PrimaryButton } from '../../../../../style/Button/Button';
import { filteredArray } from '../../fteConst';

const FteSelectedFilters = ({
    clearFilters,
    filterDataCompanies,
    setFilterDataCompanies,
    filterDataCountries,
    setFilterDataCountries,
    filterDataCities,
    setFilterDataCities,
    filterDataOffices,
    setFilterDataOffices,
    sumLength,
}) => {
    const { t } = useTranslation(i18PAGES.FTE_PROCESS);

    const deleteItemById = (id, filterData, setFilterData) => {
        setFilterData(
            filterData.map((i) => {
                if (id === i.id) return { ...i, isChecked: false };
                return i;
            })
        );
    };

    return (
        <div>
            {sumLength > 0 ? (
                <div className="ml-[74px] mb-[20px] flex flex-wrap items-center">
                    {filteredArray(filterDataCompanies).map((company, index) => (
                        <FteSelectedFilterItem
                            key={index}
                            title={company.name}
                            handleClick={() => deleteItemById(company.id, filterDataCompanies, setFilterDataCompanies)}
                        />
                    ))}
                    {filteredArray(filterDataCountries).map((country, index) => (
                        <FteSelectedFilterItem
                            key={index}
                            title={country.name}
                            handleClick={() => deleteItemById(country.id, filterDataCountries, setFilterDataCountries)}
                        />
                    ))}
                    {filteredArray(filterDataCities).map((city, index) => (
                        <FteSelectedFilterItem
                            key={index}
                            title={city.name}
                            handleClick={() => deleteItemById(city.id, filterDataCities, setFilterDataCities)}
                        />
                    ))}
                    {filteredArray(filterDataOffices).map((office, index) => (
                        <FteSelectedFilterItem
                            key={index}
                            title={office.name}
                            handleClick={() => deleteItemById(office.id, filterDataOffices, setFilterDataOffices)}
                        />
                    ))}
                    <PrimaryButton isGrey className="h-[32px] px-[12px]" handleClick={clearFilters}>
                        <div className="text-[14px] leading-[24px] font-Lexend400">{t('Remove filters')}</div>
                    </PrimaryButton>
                </div>
            ) : (
                <div className="h-[10px]" />
            )}
        </div>
    );
};

export default FteSelectedFilters;

FteSelectedFilters.propTypes = {
    clearFilters: func,
    filterDataCompanies: arrayOf(object),
    setFilterDataCompanies: func,
    filterDataCountries: arrayOf(object),
    setFilterDataCountries: func,
    filterDataCities: arrayOf(object),
    setFilterDataCities: func,
    filterDataOffices: arrayOf(object),
    setFilterDataOffices: func,
    sumLength: number,
};
