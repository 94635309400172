import React, { useMemo } from 'react';
import { func, number, object } from 'prop-types';
import FTableInput from '../../../../style/Table/TableInputs/TableInput/TableInput';
import TableSearchDropDown from '../../../../style/Table/TableInputs/TableSearchDropDown/TableSearchDropDown';

const AsIsIntegrationsEditTableItem = ({
    values,
    index,
    handleChange,
    handleBlur,
    asIsIntegrationsTouched,
    asIsIntegrationsErrors,
    setFieldValue,
    dataAsIsIntegrationsOptions,
}) => {
    const outgoingBuildingBlocksOptions = useMemo(
        () =>
            dataAsIsIntegrationsOptions?.outgoingBuildingBlocks?.map((block) => ({
                label: block.name,
                value: block.id,
            })),
        [dataAsIsIntegrationsOptions]
    );

    const ingoingBuildingBlocksOptions = useMemo(
        () =>
            dataAsIsIntegrationsOptions?.ingoingBuildingBlocks?.map((block) => ({
                label: block.name,
                value: block.id,
            })),
        [dataAsIsIntegrationsOptions]
    );

    const dataTypeOptions = useMemo(
        () =>
            dataAsIsIntegrationsOptions?.dataType?.map((dataType) => ({
                label: dataType,
                value: dataType,
            })),
        [dataAsIsIntegrationsOptions]
    );

    const integrationTypesOptions = useMemo(
        () =>
            dataAsIsIntegrationsOptions?.integrationTypes?.map((integrationType) => ({
                label: integrationType,
                value: integrationType,
            })),
        [dataAsIsIntegrationsOptions]
    );

    const operationTypesOptions = useMemo(
        () =>
            dataAsIsIntegrationsOptions?.operationTypes?.map((operationType) => ({
                label: operationType,
                value: operationType,
            })),
        [dataAsIsIntegrationsOptions]
    );

    const frequenciesOptions = useMemo(
        () =>
            dataAsIsIntegrationsOptions?.frequencies?.map((frequencie) => ({
                label: frequencie,
                value: frequencie,
            })),
        [dataAsIsIntegrationsOptions]
    );

    const numberOfFrequenciesOptions = useMemo(
        () =>
            dataAsIsIntegrationsOptions?.numberOfFrequencies?.map((numberOfFrequencie) => ({
                label: numberOfFrequencie.toString(),
                value: numberOfFrequencie,
            })),
        [dataAsIsIntegrationsOptions]
    );

    return (
        <tr className="h-[60px] border-b-[1px] border-int-main-black" key={index}>
            <td className="px-[10px] border-r-[1px] border-int-main-black text-center">{index + 1}</td>
            <td className="px-[6px] border-r-[1px] border-int-main-black">
                <TableSearchDropDown
                    options={outgoingBuildingBlocksOptions}
                    placeholder="Select"
                    handleChange={({ label, value }) => {
                        setFieldValue(`asIsIntegrationsData[${index}].outgoingBuildingBlock.name`, label);
                        setFieldValue(`asIsIntegrationsData[${index}].outgoingBuildingBlock.id`, value);
                    }}
                    handleBlur={handleBlur}
                    name={`asIsIntegrationsData.${index}.outgoingBuildingBlock.name`}
                    value={values.asIsIntegrationsData[index].outgoingBuildingBlock.name}
                    isError={
                        !!(
                            asIsIntegrationsTouched?.outgoingBuildingBlock?.name &&
                            asIsIntegrationsErrors?.outgoingBuildingBlock?.name
                        )
                    }
                    errorText={asIsIntegrationsErrors?.outgoingBuildingBlock?.name}
                />
            </td>
            <td className="px-[6px] border-r-[1px] border-int-main-black">
                <TableSearchDropDown
                    options={ingoingBuildingBlocksOptions}
                    placeholder="Select"
                    handleChange={({ label, value }) => {
                        setFieldValue(`asIsIntegrationsData[${index}].ingoingBuildingBlock.name`, label);
                        setFieldValue(`asIsIntegrationsData[${index}].ingoingBuildingBlock.id`, value);
                    }}
                    handleBlur={handleBlur}
                    name={`asIsIntegrationsData.${index}.ingoingBuildingBlock.name`}
                    value={values.asIsIntegrationsData[index].ingoingBuildingBlock.name}
                    isError={
                        !!(
                            asIsIntegrationsTouched?.ingoingBuildingBlock?.name &&
                            asIsIntegrationsErrors?.ingoingBuildingBlock?.name
                        )
                    }
                    errorText={asIsIntegrationsErrors?.ingoingBuildingBlock?.name}
                />
            </td>
            <td className="px-[6px] border-r-[1px] border-int-main-black">
                <FTableInput
                    type="text"
                    placeholder="Type"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    name={`asIsIntegrationsData.${index}.outgoingApp`}
                    value={values.asIsIntegrationsData[index].outgoingApp}
                    isError={!!(asIsIntegrationsTouched.outgoingApp && asIsIntegrationsErrors.outgoingApp)}
                    errorText={asIsIntegrationsErrors.outgoingApp}
                    isNoAutoComplete
                    setFieldValue={setFieldValue}
                    limit={15}
                />
            </td>
            <td className="px-[6px] border-r-[1px] border-int-main-black">
                <FTableInput
                    type="text"
                    placeholder="Type"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    name={`asIsIntegrationsData.${index}.ingoingApp`}
                    value={values.asIsIntegrationsData[index].ingoingApp}
                    isError={!!(asIsIntegrationsTouched.ingoingApp && asIsIntegrationsErrors.ingoingApp)}
                    errorText={asIsIntegrationsErrors.ingoingApp}
                    setFieldValue={setFieldValue}
                    limit={15}
                />
            </td>
            <td className="px-[6px] border-r-[1px] border-int-main-black">
                <TableSearchDropDown
                    options={dataTypeOptions}
                    placeholder="Select"
                    handleChange={({ label, value }) => {
                        setFieldValue(`asIsIntegrationsData[${index}].dataType`, label);
                        setFieldValue(`asIsIntegrationsData[${index}].dataType`, value);
                    }}
                    handleBlur={handleBlur}
                    name={`asIsIntegrationsData.${index}.dataType`}
                    value={values.asIsIntegrationsData[index].dataType}
                    isError={!!(asIsIntegrationsTouched?.dataType && asIsIntegrationsErrors?.dataType)}
                    errorText={asIsIntegrationsErrors?.dataType}
                />
            </td>
            <td className="px-[6px] border-r-[1px] border-int-main-black">
                <TableSearchDropDown
                    options={integrationTypesOptions}
                    placeholder="Select"
                    handleChange={({ label, value }) => {
                        setFieldValue(`asIsIntegrationsData[${index}].integrationType`, label);
                        setFieldValue(`asIsIntegrationsData[${index}].integrationType`, value);
                    }}
                    handleBlur={handleBlur}
                    name={`asIsIntegrationsData.${index}.integrationType`}
                    value={values.asIsIntegrationsData[index].integrationType}
                    isError={!!(asIsIntegrationsTouched?.integrationType && asIsIntegrationsErrors?.integrationType)}
                    errorText={asIsIntegrationsErrors?.integrationType}
                    isSearch={false}
                />
            </td>
            <td className="px-[6px] border-r-[1px] border-int-main-black">
                <TableSearchDropDown
                    options={operationTypesOptions}
                    placeholder="Select"
                    handleChange={({ label, value }) => {
                        setFieldValue(`asIsIntegrationsData[${index}].operationType`, label);
                        setFieldValue(`asIsIntegrationsData[${index}].operationType`, value);
                    }}
                    handleBlur={handleBlur}
                    name={`asIsIntegrationsData.${index}.operationType`}
                    value={values.asIsIntegrationsData[index].operationType}
                    isError={!!(asIsIntegrationsTouched?.operationType && asIsIntegrationsErrors?.operationType)}
                    errorText={asIsIntegrationsErrors?.operationType}
                    isSearch={false}
                />
            </td>
            <td className="px-[6px] border-r-[1px] border-int-main-black">
                <FTableInput
                    type="text"
                    placeholder="Type"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    name={`asIsIntegrationsData.${index}.supportEffort`}
                    value={values.asIsIntegrationsData[index].supportEffort}
                    isError={!!(asIsIntegrationsTouched.supportEffort && asIsIntegrationsErrors.supportEffort)}
                    errorText={asIsIntegrationsErrors.supportEffort}
                    setFieldValue={setFieldValue}
                />
            </td>
            <td className="px-[6px] border-r-[1px] border-int-main-black">
                <TableSearchDropDown
                    options={frequenciesOptions}
                    placeholder="Select"
                    handleChange={({ label, value }) => {
                        setFieldValue(`asIsIntegrationsData[${index}].frequency`, label);
                        setFieldValue(`asIsIntegrationsData[${index}].frequency`, value);
                    }}
                    handleBlur={handleBlur}
                    name={`asIsIntegrationsData.${index}.frequency`}
                    value={values.asIsIntegrationsData[index].frequency}
                    isError={!!(asIsIntegrationsTouched?.frequency && asIsIntegrationsErrors?.frequency)}
                    errorText={asIsIntegrationsErrors?.frequency}
                    isSearch={false}
                />
            </td>
            <td className="px-[6px] border-r-[1px] border-int-main-black">
                <TableSearchDropDown
                    options={numberOfFrequenciesOptions}
                    placeholder="Select"
                    handleChange={({ value }) => {
                        setFieldValue(`asIsIntegrationsData[${index}].numberOfFrequency`, value);
                    }}
                    handleBlur={handleBlur}
                    name={`asIsIntegrationsData.${index}.numberOfFrequency`}
                    value={values.asIsIntegrationsData[index].numberOfFrequency}
                    isError={
                        !!(asIsIntegrationsTouched?.numberOfFrequency && asIsIntegrationsErrors?.numberOfFrequency)
                    }
                    errorText={asIsIntegrationsErrors?.numberOfFrequency}
                    isSearch={false}
                />
            </td>
        </tr>
    );
};

export default AsIsIntegrationsEditTableItem;

AsIsIntegrationsEditTableItem.propTypes = {
    index: number.isRequired,
    values: object.isRequired,
    handleBlur: func.isRequired,
    handleChange: func.isRequired,
    asIsIntegrationsTouched: object.isRequired,
    asIsIntegrationsErrors: object.isRequired,
    setFieldValue: func.isRequired,
    dataAsIsIntegrationsOptions: object,
};
