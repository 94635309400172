import { Outlet } from 'react-router-dom';
import NotFoundPage from '../pages/NotFoundPage';
import HomePage from '../pages/HomePage';
import ModulesPage from '../pages/ModulesPage';
import RegistrationPage from '../pages/RegistrationPage';
import LoginPage from '../pages/LoginPage';
import SentEmailPage from '../pages/SentEmailPage';
import ConfirmationPage from '../pages/ConfirmationPage';
import HRToolsPage from '../pages/HRToolsPage';
import ForgotPasswordPage from '../pages/ForgotPasswordPage';
import PasswordResetPage from '../pages/PasswordResetPage';
import HrInfoPage from '../pages/HRInfoPage';
import SetupCompaniesPage from '../pages/SetupCompaniesPage';
import ProfilePage from '../pages/ProfilePage';
import UserManagementPage from '../pages/UserManagmentPage';
import SetPasswordPage from '../pages/SetPasswordPage';
import CreateProjectPage from '../pages/CreateProjectPage';
import CompanyList from '../pages/CompanyListPage';
import ActivateAccountPage from '../pages/ActivateAccountPage';
import MainLayout from '../layouts/MainLayout';
import PlatformLayout from '../layouts/Platform/PlatformLayout';
import { RequireAuth } from '../hoc/auth/RequireAuth';
import CompaniesInfoPage from '../pages/CompaniesInfoPage';
import ProjectInfoLayout from '../layouts/ProjectInfo/ProjectInfoLayout';
import ProjectListPage from '../pages/ProjectListPage';
import ProjectsManagementPage from '../pages/ProjectsManagementPage';
import FTEProcessPage from '../pages/FTEProcessPage';
import WorkersEfficiencyPage from '../pages/WorkersEfficiencyPage';
import ModulesStrategyPage from '../pages/Modules&StrategyPage';
import ToBeApplicationsPage from '../pages/ToBeApplicationsPage';
import AsIsApplicationsPage from '../pages/AsIsApplicationsPage';
import AsIsIntegrationsPage from '../pages/AsIsIntegrationsPage';
import ToBeIntegrationsPage from '../pages/ToBeIntegrationsPage';
import InvoicePage from '../pages/InvoicePage';
import SubscriptionPage from '../pages/SubscriptionPage';
import PaymentNotificationPage from '../pages/PaymentNotificationPage';
import TransactionInProgressPage from '../pages/TransactionInProgressPage';
import EditProjectPage from '../pages/EditProjectPage';
import ModulesCheckoutPage from '../pages/ModulesCheckoutPage';
import PageNotAvailable from '../components/Platform/PageNotAvailable';
import ServerErrorPage from '../pages/ServerErrorPage';
import UserConfirmTransactionsPage from '../pages/UserConfirmTransactionsPage';
import OwnerConfirmTransactionsPage from '../pages/OwnerConfirmTransactionsPage';
import UserInvoicePage from '../pages/UserInvoicePage';
import NotCompletedUserInvoicePage from '../pages/NotCompletedUserInvoicePage';
import OptionsPage from '../pages/OptionsPage';
import ProviderSelectionPage from '../pages/ProviderSelectionPage';
import DatePickerPage from '../pages/DatePickerPage';
import QuestionnairePage from '../pages/QuestionnairePage';

const routes = [
    {
        path: '/',
        children: [
            {
                path: '',
                element: (
                    <MainLayout>
                        <Outlet />
                    </MainLayout>
                ),
                children: [
                    { path: '', element: <HomePage /> },
                    {
                        path: 'modules',
                        element: (
                            <RequireAuth>
                                <ModulesPage />
                            </RequireAuth>
                        ),
                    },
                    {
                        path: 'register-modules',
                        element: <ModulesPage />,
                    },
                    { path: 'hr', element: <HRToolsPage /> },
                    { path: 'hr/:hrId', element: <HrInfoPage /> },
                    { path: 'register', element: <RegistrationPage /> },
                    { path: 'login', element: <LoginPage /> },
                    { path: 'email-sent/:emailType', element: <SentEmailPage /> },
                    {
                        path: 'confirmation/:confirmationType',
                        element: <ConfirmationPage />,
                    },
                    { path: 'forgot-password', element: <ForgotPasswordPage /> },
                    { path: 'password-reset/:token', element: <PasswordResetPage /> },
                    { path: 'activate-account/:token', element: <ActivateAccountPage /> },
                    { path: 'invoice/:token', element: <InvoicePage /> },
                    { path: 'set-password/:token', element: <SetPasswordPage /> },
                    { path: 'invoices/cancel/:token', element: <PaymentNotificationPage /> },
                    { path: 'modules-checkout', element: <ModulesCheckoutPage /> },
                    { path: 'not-available', element: <PageNotAvailable /> },
                    { path: 'server-error', element: <ServerErrorPage /> },
                    { path: 'confirm-transaction', element: <UserConfirmTransactionsPage /> },
                    { path: 'invoices/invoice-info', element: <TransactionInProgressPage /> },
                    { path: 'invoices/invoice-info/wahler', element: <OwnerConfirmTransactionsPage /> },
                    { path: 'questionnaire', element: <QuestionnairePage /> },
                    { path: 'datepicker', element: <DatePickerPage /> },
                ],
            },

            {
                path: '/',
                element: (
                    <RequireAuth>
                        <PlatformLayout>
                            <Outlet />
                        </PlatformLayout>
                    </RequireAuth>
                ),
                children: [
                    { path: 'profile', element: <ProfilePage /> },
                    {
                        path: 'user-management',
                        element: <UserManagementPage />,
                    },
                    { path: 'subscription', element: <SubscriptionPage /> },
                    { path: 'user-invoice/:invoiceId', element: <UserInvoicePage /> },
                    { path: 'not-completed-user-invoice', element: <NotCompletedUserInvoicePage /> },
                    { path: 'companies-list', element: <CompanyList /> },
                    { path: 'setup-companies', element: <SetupCompaniesPage /> },
                    { path: 'create-project', element: <CreateProjectPage /> },
                    { path: 'edit-project', element: <EditProjectPage /> },
                    { path: 'projects-list', element: <ProjectListPage /> },
                    {
                        path: 'projects-management',
                        element: <ProjectsManagementPage />,
                    },
                ],
            },

            {
                path: '/',
                element: (
                    <RequireAuth>
                        <ProjectInfoLayout>
                            <Outlet />
                        </ProjectInfoLayout>
                    </RequireAuth>
                ),
                children: [
                    { path: 'companies', element: <CompaniesInfoPage /> },
                    { path: 'as_is_applications', element: <AsIsApplicationsPage /> },
                    { path: 'to_be_applications', element: <ToBeApplicationsPage /> },
                    { path: 'as_is_integrations', element: <AsIsIntegrationsPage /> },
                    { path: 'to_be_integrations', element: <ToBeIntegrationsPage /> },
                    { path: 'application_requirements', element: <div>Application requirements</div> },
                    { path: 'modules_and_strategy', element: <ModulesStrategyPage /> },
                    { path: 'workers_information', element: <WorkersEfficiencyPage /> },
                    { path: 'fte_process', element: <FTEProcessPage /> },
                    { path: 'select_provider', element: <ProviderSelectionPage /> },
                    { path: 'options', element: <OptionsPage /> },
                    { path: 'financial_comparison', element: <div>Financial comparison</div> },
                    { path: 'modules_efficiencies', element: <div>Modules efficiencies</div> },
                    { path: 'efficiencies_comparison', element: <div>Efficiencies comparison</div> },
                    { path: 'presentation', element: <div>Presentation</div> },
                ],
            },

            {
                path: '*',
                element: (
                    <MainLayout>
                        <NotFoundPage />
                    </MainLayout>
                ),
            },
        ],
    },
];

export default routes;
