import React, { useCallback, useState } from 'react';
import { array, func, number, object } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import SearchDropDown from '../../style/SearchDropDown/SearchDropDown';
import { OutlineButton, PrimaryButton } from '../../style/Button/Button';
import FInput from '../../style/Input/Input';
import { ReactComponent as SuccessIcon } from '../../assets/icons/success.svg';
import { i18PAGES } from '../../config/i18n/constants';
import i18n from '../../config/i18n/i18n';
import { useToast } from '../../hoc/toast/ToastProvider';
import { twoDecimalMinus, twoDecimalSum } from '../util/twoDecimalSum';
import { useSendSurveyWorkingTimeDistributionsMutation } from '../../store/API/surveysAPI';

const WorkingTimeDistributionItem = (props) => {
    const { t } = useTranslation(i18PAGES.QUESTIONNAIRE);
    const { successToast, errorToast } = useToast();
    const [searchParams] = useSearchParams();
    const [isNotFilledAnyInput, setIsNotFilledAnyInput] = useState(false);
    const [sendWorkingTimeDistributions, { isLoading: isSending }] = useSendSurveyWorkingTimeDistributionsMutation();
    const key = searchParams.get('key');
    const {
        values,
        distribution,
        modulesOptions,
        setProgressValue,
        setFieldValue,
        setReadyModulesCount,
        modulesList,
        index,
        errors,
        touched,
        ArrayHelpers,
        handleBlur,
        handleChange,
        setIsSubmitAnswer,
    } = props;

    const onProgressBarDeclined = useCallback(() => {
        const percentsPerModule = distribution?.buildingBlocks?.reduce(
            (accumulator, currentValue) => twoDecimalSum(accumulator, currentValue?.workPercent),
            0
        );

        setProgressValue((prev) => twoDecimalMinus(prev, percentsPerModule));
    }, [distribution?.buildingBlocks, setProgressValue]);

    const onDropdownSelect = useCallback(
        (module) => {
            if (distribution.isSaved) {
                setFieldValue(`workingTimeDistributionDTOS.${index}.isSaved`, false);
                onProgressBarDeclined();
                setReadyModulesCount((prev) => prev - 1);
            }

            setFieldValue(`workingTimeDistributionDTOS.${index}.moduleId`, module.value);
            setFieldValue(`workingTimeDistributionDTOS.${index}.moduleName`, module.label);
            setFieldValue(
                `workingTimeDistributionDTOS.${index}.buildingBlocks`,
                modulesList
                    .find((mod) => mod.moduleId === module.value)
                    ?.buildingBlocks?.map((bb) => ({
                        buildingBlockId: bb.id,
                        buildingBlockName: bb.name,
                        workPercent: 0,
                    }))
            );
        },
        [distribution.isSaved, index, modulesList, onProgressBarDeclined, setFieldValue, setReadyModulesCount]
    );

    const getSendData = useCallback(
        (deletionModuleId = null) => ({
            key,
            body: {
                workingTimeDistributionDTOS: values.workingTimeDistributionDTOS
                    .filter((dist) => dist.moduleId !== deletionModuleId && dist.moduleId !== null)
                    .map((d) => ({
                        ...d,
                        buildingBlocks: d.buildingBlocks.map((bb) => ({
                            ...bb,
                            workPercent: bb?.workPercent || 0,
                        })),
                    })),
            },
        }),
        [key, values.workingTimeDistributionDTOS]
    );

    const onSaveButton = useCallback(() => {
        setIsSubmitAnswer(false);
        const isAnyInputFilled = distribution.buildingBlocks.find((bb) => Number(bb.workPercent > 0));

        if (!isAnyInputFilled) {
            setIsNotFilledAnyInput(true);
            return;
        }

        if (isAnyInputFilled && Object.keys(errors).length === 0 && !distribution.isSaved) {
            sendWorkingTimeDistributions(getSendData())
                .unwrap()
                .then(() => {
                    if (!distribution.isSaved) {
                        const percentsPerModule = distribution?.buildingBlocks?.reduce(
                            (accumulator, currentValue) => twoDecimalSum(accumulator, currentValue?.workPercent),
                            0
                        );
                        setProgressValue((prev) => twoDecimalSum(prev, percentsPerModule));
                        setFieldValue(`workingTimeDistributionDTOS.${index}.isSaved`, true);
                        setReadyModulesCount((prev) => prev + 1);
                    }
                })
                .catch((err) => {
                    errorToast(err?.data?.message || i18n.t('Something went wrong, try again later'));
                });
        }
    }, [
        setIsSubmitAnswer,
        distribution.buildingBlocks,
        distribution.isSaved,
        errors,
        sendWorkingTimeDistributions,
        getSendData,
        setProgressValue,
        setFieldValue,
        index,
        setReadyModulesCount,
        errorToast,
    ]);

    const onDeleteClick = useCallback(() => {
        if (distribution.moduleId) {
            sendWorkingTimeDistributions(getSendData(distribution.moduleId))
                .unwrap()
                .then(() => {
                    successToast(t('Section deleted successfully'));
                    if (distribution.isSaved) {
                        onProgressBarDeclined();
                        setReadyModulesCount((prev) => prev - 1);
                    }
                })
                .catch((err) => {
                    errorToast(err?.data?.message || i18n.t('Something went wrong, try again later'));
                });
        }
        ArrayHelpers.remove(index);
    }, [
        ArrayHelpers,
        distribution.isSaved,
        distribution.moduleId,
        errorToast,
        getSendData,
        index,
        onProgressBarDeclined,
        sendWorkingTimeDistributions,
        setReadyModulesCount,
        successToast,
        t,
    ]);

    return (
        <div className="bg-[#1F2937] mt-[20px] min-h-[104px] w-[100%] p-[20px] pb-0 rounded-[8px]">
            <div className="flex justify-between items-center">
                <div>
                    <SearchDropDown
                        name={`workingTimeDistributionDTOS.${index}.module`}
                        placeholder="Select"
                        className="w-[320px] mt-[20px]"
                        options={
                            modulesOptions?.filter(
                                (option) =>
                                    !values?.workingTimeDistributionDTOS
                                        ?.map((wd) => wd.moduleId)
                                        .includes(option.value)
                            ) || []
                        }
                        label="Module"
                        value={distribution?.moduleName || ''}
                        handleChange={(module) => onDropdownSelect(module)}
                        handleBlur={handleBlur}
                    />
                </div>

                {!distribution.moduleId && values.workingTimeDistributionDTOS.length > 1 && (
                    <div>
                        <OutlineButton onClick={onDeleteClick}>{t('Delete')}</OutlineButton>
                    </div>
                )}
            </div>
            {distribution.moduleId && (
                <div className="flex mt-[30px] justify-between items-center">
                    <div className="flex items-center flex-wrap">
                        {distribution?.buildingBlocks?.map((bb, ind) => {
                            const onWorkPercentChange = () => (event) => {
                                setIsNotFilledAnyInput(false);

                                if (distribution.isSaved) {
                                    setFieldValue(`workingTimeDistributionDTOS.${index}.isSaved`, false);
                                    onProgressBarDeclined();
                                    setReadyModulesCount((prev) => prev - 1);
                                }
                                handleChange(event);
                            };

                            const buildingBlocksTouched =
                                (touched.workingTimeDistributionDTOS?.length &&
                                    touched?.workingTimeDistributionDTOS[index] &&
                                    touched?.workingTimeDistributionDTOS[index]?.buildingBlocks &&
                                    touched?.workingTimeDistributionDTOS[index]?.buildingBlocks[ind]) ||
                                {};

                            const buildingBlocksErrors =
                                (errors.workingTimeDistributionDTOS?.length &&
                                    errors?.workingTimeDistributionDTOS[index] &&
                                    errors?.workingTimeDistributionDTOS[index]?.buildingBlocks &&
                                    errors?.workingTimeDistributionDTOS[index]?.buildingBlocks[ind]) ||
                                {};

                            return (
                                <div className="flex items-center mr-[40px] mb-[20px]" key={ind}>
                                    <div className="mr-[20px] text-[16px] leading-[26px] font-Lexend400 text-int-white-main">
                                        {bb.buildingBlockName}
                                    </div>
                                    <FInput
                                        name={`workingTimeDistributionDTOS.${index}.buildingBlocks.${ind}.workPercent`}
                                        placeholder="0"
                                        value={bb.workPercent || ''}
                                        handleBlur={handleBlur}
                                        handleChange={onWorkPercentChange()}
                                        type="text"
                                        className="w-[120px]"
                                        setFieldValue={setFieldValue}
                                        isError={
                                            !!(
                                                buildingBlocksTouched?.workPercent && buildingBlocksErrors?.workPercent
                                            ) || isNotFilledAnyInput
                                        }
                                        errorText={
                                            buildingBlocksErrors?.workPercent ||
                                            t('At least one input should be filled')
                                        }
                                    />
                                </div>
                            );
                        })}
                    </div>
                    <div className="flex mb-[20px]">
                        <PrimaryButton
                            isGrey
                            className="mr-[20px] px-[40px] py-[10px] min-h-[48px]"
                            handleClick={onSaveButton}
                            type="submit"
                            disabled={isSending}
                        >
                            <div>{t('Save')}</div>
                            {distribution.isSaved && (
                                <div className="ml-[6px]">
                                    <SuccessIcon width={18} height={18} />
                                </div>
                            )}
                        </PrimaryButton>
                        {values.workingTimeDistributionDTOS.filter((timeDistr) => timeDistr.isSaved === true).length >
                            1 && <OutlineButton onClick={onDeleteClick}>{t('Delete')}</OutlineButton>}
                    </div>
                </div>
            )}
        </div>
    );
};

export default WorkingTimeDistributionItem;

WorkingTimeDistributionItem.propTypes = {
    distribution: object,
    setProgressValue: func,
    setFieldValue: func,
    handleBlur: func,
    handleChange: func,
    setReadyModulesCount: func,
    modulesList: array,
    index: number,
    ArrayHelpers: object,
    values: object,
    modulesOptions: array,
    touched: object,
    errors: object,
    setIsSubmitAnswer: func,
};
