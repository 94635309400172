import React from 'react';
import { object } from 'prop-types';
import OnlyViewFieldInput from '../../../Profile/OnlyViewFieldInput';
import { ReactComponent as ArrowBottomIcon } from '../../../../assets/icons/bottom-arrow.svg';
import OrganizationStructureReadOnly from './ReadOrganizationStructure/OrganizationStructureReadOnly';

const OfficeInfoReadMode = ({ officeItem }) => (
    <>
        <div className="bg-int-gray-main p-[30px] pt-[50px] rounded-[8px] w-[1132px] min-h-[377px]">
            <div className="flex">
                <div className="w-[250px]">
                    <OnlyViewFieldInput isUpperCase label="Year of founding" value={officeItem?.yearFounded || '-'} />
                </div>
                <div className="w-[250px] ml-[24px]">
                    <OnlyViewFieldInput
                        label="white-collar workers"
                        isUpperCase
                        value={officeItem?.employeeQuantityWc || '-'}
                    />
                </div>

                <div className="w-[250px] ml-[24px]">
                    <OnlyViewFieldInput
                        label="Full-time white-collar"
                        isUpperCase
                        value={officeItem?.employeeQuantityWcFt || '-'}
                    />
                </div>
                <div className="w-[250px] ml-[24px]">
                    <OnlyViewFieldInput
                        label="Part-time white-collar"
                        isUpperCase
                        value={officeItem?.employeeQuantityWcPt || '-'}
                    />
                </div>
            </div>

            <div className="flex mt-[40px]">
                <div className="w-[250px]">
                    <OnlyViewFieldInput
                        label="Blue-collar workers"
                        isUpperCase
                        value={officeItem?.employeeQuantityBc || '-'}
                    />
                </div>
                <div className="w-[250px] ml-[24px]">
                    <OnlyViewFieldInput
                        label="Full-time blue-collar"
                        isUpperCase
                        value={officeItem?.employeeQuantityBcFt || '-'}
                    />
                </div>
                <div className="w-[250px] ml-[24px]">
                    <OnlyViewFieldInput
                        label="Part-time blue-collar"
                        isUpperCase
                        value={officeItem?.employeeQuantityBcPt || '-'}
                    />
                </div>
                <div className="w-[250px] ml-[24px]">
                    <OnlyViewFieldInput
                        label="Total number of workers"
                        isUpperCase
                        value={officeItem?.employeeQuantityTotal || '-'}
                    />
                </div>
            </div>

            <div className="flex mt-[40px]">
                <div className="w-[250px]">
                    <OnlyViewFieldInput
                        label="FTE amount"
                        isUpperCase
                        value={officeItem?.employeeQuantityTotalFt || '-'}
                    />
                </div>
                <div className="w-[250px] ml-[24px]">
                    <OnlyViewFieldInput label="HR FTE amount" isUpperCase value={officeItem?.hrFteAmount || '-'} />
                </div>
                <div className="w-[250px] ml-[24px]">
                    <OnlyViewFieldInput
                        label="Zeitarbeitkräfte"
                        isUpperCase
                        value={officeItem?.temporaryExternalWorkers || '-'}
                    />
                </div>
                <div className="w-[250px] ml-[24px]">
                    <OnlyViewFieldInput label="Anzahl HR Mitarbeit" value={officeItem?.hrQuantity || '-'} isUpperCase />
                </div>
            </div>

            <div className="flex mt-[40px]">
                <div className="min-w-[360px]">
                    <OnlyViewFieldInput label="Languages" value="-" />
                </div>

                <div className="ml-[24px] flex items-center flex-wrap w-[700px]">
                    {officeItem?.languages &&
                        officeItem?.languages.map((lang, key) => (
                            <div className="h-[42px]" key={key}>
                                <div className="mt-[4px] mr-[10px] flex bg-int-gray-secondary rounded-[8px] py-[8px] px-[12px] text-[14px] leading-[16px] font-Lexend400 items-center">
                                    {lang.split(' ').map((word, index) => (
                                        <div className="ml-[3px]" key={index}>{` ${word}`}</div>
                                    ))}
                                </div>
                            </div>
                        ))}
                </div>
            </div>
        </div>
        <OrganizationStructureReadOnly office={officeItem} />
    </>
);

export default OfficeInfoReadMode;

OfficeInfoReadMode.propTypes = {
    officeItem: object,
};
