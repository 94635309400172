import React, { useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import i18n from 'i18next';
import ProjectInfoPageHeader from '../components/Platform/Common/ProjectInfoPageHeader';
import { PAGE_IDENTIFIER } from '../components/util/constants';
import { i18PAGES } from '../config/i18n/constants';
import DescriptionAndButtonsBlock from '../components/Platform/Common/DescriptionAndButtonsBlock';
import {
    getTableAsIsIntegrationsHeaderData,
    INITIAL_AS_IS_INTEGRATIONS_VALUE_DATA,
} from '../components/Platform/AsIsIntegrations/constants';
import AsIsIntegrationsEditTable from '../components/Platform/AsIsIntegrations/EditMode/AsIsIntegrationsEditTable';
import AsIsIntegrationsViewTable from '../components/Platform/AsIsIntegrations/ViewMode/AsIsIntegrationsViewTable';
import { generateKey } from '../components/util/generateKey';
import {
    useCreateAsIsIntegrationsMutation,
    useGetAsIsIntegrationsOptionsQuery,
    useGetAsIsIntegrationsQuery,
} from '../store/API/asIsIntegrationsAPI';
import Preloader from '../components/Preloader/Preloader';
import { useToast } from '../hoc/toast/ToastProvider';
import { asIsIntegrationsValidationSchema } from '../components/Platform/AsIsIntegrations/isIsIntegrationsValidation';
import PageNotAvailable from '../components/Platform/PageNotAvailable';
import { useEditMode } from '../components/util/useEditMode';
import VisualizeGraph from '../components/Platform/ToBeIntegrations/VisualizeGraph/VisualizeGraph';
import WarningModal from '../style/WarningModal/WarningModal';
import { useSort } from '../hooks/useSort';
import { useWarningModalControl } from '../store/hooks/useWarningModalControl';

const AsIsIntegrationsPage = () => {
    const { t } = useTranslation(i18PAGES.AS_IS_INTEGRATIONS);
    const { successToast, errorToast } = useToast();
    const tableHeaderData = useMemo(() => getTableAsIsIntegrationsHeaderData(t), [t]);
    const [isGraphModal, setIsGraphModal] = useState(false);
    const { orderFromUrl, sortByFromUrl } = useSort();

    const formikRef = useRef();

    const {
        isEditMode,
        isShowCloseEditAlertModal,
        openEditAlertModal,
        acceptOffEditMode,
        declineOffEditMode,
        setIsEditMode,
        setIsShowCloseEditAlertModal,
    } = useWarningModalControl(formikRef);

    useEditMode(isEditMode);

    const [searchParams] = useSearchParams();
    const projectId = searchParams.get('projectId');

    const onEditMode = () => {
        setIsEditMode(true);
    };

    const openGraphModal = () => {
        setIsGraphModal(true);
    };

    const { data: dataAsIsIntegrationsOptions, isLoading: isLoadingAsIsIntegrationsOptions } =
        useGetAsIsIntegrationsOptionsQuery();

    const { data: dataAsIsIntegrations, isLoading: isLoadingAsIsIntegrations } = useGetAsIsIntegrationsQuery(
        { projectId, sortBy: sortByFromUrl, orderBy: orderFromUrl },
        {
            refetchOnMountOrArgChange: true,
            skip: !projectId,
        }
    );

    const [createAsIsIntegrations] = useCreateAsIsIntegrationsMutation();

    const initialValueEditData = useMemo(
        () => dataAsIsIntegrations?.map((asIs) => ({ ...asIs, key: generateKey(asIs.id) })),
        [dataAsIsIntegrations]
    );

    if (!projectId) {
        return <PageNotAvailable />;
    }

    return (
        <div className="pl-[50px] pb-[80px] pr-[25px] pt-[70px] min-h-[100vh] text-int-white-main">
            <ProjectInfoPageHeader
                nextLink="/to_be_integrations"
                pageIdentifier={PAGE_IDENTIFIER.AS_IS_INTEGRATIONS}
                projectId={Number(projectId)}
                pageName={t('As-is Integrations')}
            />
            <div className="mt-[50px]">
                <Formik
                    initialValues={{
                        asIsIntegrationsData:
                            dataAsIsIntegrations?.length > 0
                                ? initialValueEditData
                                : [{ ...INITIAL_AS_IS_INTEGRATIONS_VALUE_DATA, key: generateKey(0) }],
                    }}
                    validationSchema={asIsIntegrationsValidationSchema}
                    enableReinitialize
                    innerRef={formikRef}
                    onSubmit={(values, { setSubmitting }) => {
                        const asIsIntegrationsDataForApi = values?.asIsIntegrationsData?.map((asIs) => ({
                            ...asIs,
                            supportEffort: parseInt(asIs.supportEffort),
                        }));

                        createAsIsIntegrations({
                            projectId,
                            body: {
                                asIsIntegrations: asIsIntegrationsDataForApi,
                            },
                        })
                            .unwrap()
                            .then(() => {
                                successToast(i18n.t('As-is integrations was updated'), '', '', 6000);
                                setIsEditMode(false);
                            })
                            .catch((err) => {
                                if (
                                    err.data.message ===
                                    'After sending a request to providers, you will not be able to edit As-Is Integrations.'
                                ) {
                                    errorToast(
                                        t(
                                            'After sending a request to providers, you will not be able to edit As-Is Integrations.'
                                        )
                                    );
                                } else {
                                    errorToast(i18n.t('Something went wrong, try again later'));
                                }
                            })
                            .finally(() => {
                                setSubmitting(false);
                            });
                    }}
                >
                    {({
                        values,
                        isSubmitting,
                        handleSubmit,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        setFieldValue,
                    }) => (
                        <Form>
                            <DescriptionAndButtonsBlock
                                isEditMode={isEditMode}
                                descriptionText={t('Explanation on how to fill in information on this tab')}
                                onEditMode={onEditMode}
                                offEditMode={openEditAlertModal}
                                isSubmitting={isSubmitting}
                                handleSave={handleSubmit}
                                openGraphModal={openGraphModal}
                                isGraphModalActive={dataAsIsIntegrations?.length > 0}
                            />
                            {isLoadingAsIsIntegrationsOptions || isLoadingAsIsIntegrations ? (
                                <div className="flex items-center justify-center pt-[40px]">
                                    <Preloader />
                                </div>
                            ) : (
                                <>
                                    {isEditMode ? (
                                        <AsIsIntegrationsEditTable
                                            tableHeaderData={tableHeaderData}
                                            values={values}
                                            errors={errors}
                                            touched={touched}
                                            handleChange={handleChange}
                                            handleBlur={handleBlur}
                                            setFieldValue={setFieldValue}
                                            dataAsIsIntegrationsOptions={dataAsIsIntegrationsOptions}
                                        />
                                    ) : (
                                        <AsIsIntegrationsViewTable
                                            tableHeaderData={tableHeaderData}
                                            data={dataAsIsIntegrations}
                                        />
                                    )}
                                </>
                            )}
                        </Form>
                    )}
                </Formik>
            </div>
            {isGraphModal && (
                <VisualizeGraph
                    isOpen={isGraphModal}
                    setIsOpen={setIsGraphModal}
                    data={dataAsIsIntegrations}
                    page={PAGE_IDENTIFIER.AS_IS_INTEGRATIONS}
                />
            )}
            <WarningModal
                isOpen={isShowCloseEditAlertModal}
                setIsOpen={setIsShowCloseEditAlertModal}
                warningText={t('Are you sure you want to close editing mode? Your result will not be saved')}
                acceptButtonText={t('Close')}
                declineButtonText={t('Cancel')}
                accept={acceptOffEditMode}
                decline={declineOffEditMode}
            />
        </div>
    );
};

export default AsIsIntegrationsPage;
