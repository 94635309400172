import React, { useEffect, useMemo, useState } from 'react';
import { Formik } from 'formik';
import { func, object, string, bool } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { COMPANY_STATUS, SETUP_PAGES, STEP_TYPE } from '../util/constants';
import StepLayout from '../SetupCompanies/StepLayout';
import ToastWindow from '../../style/ToastWindow/ToastWindow';
import { ReactComponent as ModalWarningIcon } from '../../assets/icons/modalWarningIcon.svg';
import { useEditLinkCompaniesProjectMutation } from '../../store/API/createProjectAPI';
import { useGetCompaniesEditProjectQuery } from '../../store/API/companiesAPI';
import { i18PAGES } from '../../config/i18n/constants';
import ChangeStepInfoModalContent from '../SetupCompanies/ChangeStepInfoModalContent';
import i18n from '../../config/i18n/i18n';
import ModalWindow from '../../style/ModalWindow/ModalWindow';
import { useToast } from '../../hoc/toast/ToastProvider';
import Step2FormProject from '../CreateProject/Step2CreateProject/Step2FormProject';

const Step2AddCompany = ({
    setSetupCurrentPage,
    projectData,
    projectId,
    refetchProjectData,
    isLoadingProjectData,
    isDisabledFields,
}) => {
    const { t } = useTranslation(i18PAGES.PROJECTS_SETUP);
    const { errorToast } = useToast();

    const [isOpenWarning, setIsOpenWarning] = useState(false);
    const [initialValueCompany, setInitialValueCompany] = useState([]);
    const [isBackButtonPressed, setIsBackButtonPressed] = useState(false);
    const [isStepBackAgree, setIsStepBackAgree] = useState(false);

    const { data: company, isLoading: isLoadingCompany } = useGetCompaniesEditProjectQuery(
        { projectId },
        { refetchOnMountOrArgChange: true, skip: !projectId }
    );

    const [editLinkCompaniesProject] = useEditLinkCompaniesProjectMutation();

    const onBackButtonPress = () => {
        setIsBackButtonPressed(true);
    };

    useEffect(() => {
        if (isStepBackAgree === true) {
            setSetupCurrentPage(SETUP_PAGES.STEP_1);
        }
    }, [isStepBackAgree, setSetupCurrentPage]);

    const activeCompaniesIdArray = useMemo(() => {
        if (projectData?.companies?.length) {
            return projectData?.companies?.map((c) => c.id);
        }
    }, [projectData?.companies]);

    useEffect(() => {
        const convertCompanyArray = company
            ?.filter((c) => c.status === COMPANY_STATUS.COMPLETED)
            ?.map((company) => ({
                id: company.id,
                name: company.name,
                isChecked: activeCompaniesIdArray?.includes(company.id),
            }));

        setInitialValueCompany(convertCompanyArray);
    }, [company]);

    return (
        <StepLayout currentStepNumber={2} height={270} type={STEP_TYPE.PROJECT}>
            <Formik
                onSubmit={(values, { setSubmitting }) => {
                    if (isDisabledFields) {
                        setSetupCurrentPage(SETUP_PAGES.STEP_3);
                    } else if (values.companies.filter((e) => e.isChecked === true).length > 0) {
                        const companyCheckedIds = values.companies
                            .filter((item) => item.isChecked === true)
                            .map((item) => item.id);

                        editLinkCompaniesProject({
                            companyIds: companyCheckedIds,
                            projectId,
                        })
                            .unwrap()
                            .then(() => {
                                refetchProjectData().then(() => {
                                    setSetupCurrentPage(SETUP_PAGES.STEP_3);
                                });
                            })
                            .catch(() => {
                                errorToast(t('Error add company!'));
                            })
                            .finally(() => {
                                setSubmitting(false);
                            });
                    } else {
                        setSubmitting(false);
                        setIsOpenWarning(true);
                    }
                }}
                initialValues={{
                    companies: initialValueCompany,
                }}
                enableReinitialize
            >
                {({ values, isSubmitting, handleSubmit }) => (
                    <Step2FormProject
                        handleSubmit={handleSubmit}
                        isSubmitting={isSubmitting}
                        values={values}
                        isLoading={isLoadingCompany || isLoadingProjectData}
                        onBackButtonPress={onBackButtonPress}
                        isDisabledAllCheckbox={isDisabledFields}
                    />
                )}
            </Formik>
            <ToastWindow
                isOpen={isOpenWarning}
                isShowCloseIcon
                handleCloseIconClick={() => {
                    setIsOpenWarning(false);
                }}
            >
                <div className="flex flex-col items-center justify-center text-int-white-main">
                    <div className="flex items-center text-[16px] font-Lexend500">
                        <ModalWarningIcon />
                        <div className="ml-[10px]">{t('No selected elements')}</div>
                    </div>
                    <div className="text-[14px]">{t('Select companies to go to the next step')}</div>
                </div>
            </ToastWindow>

            <ModalWindow isOpen={isBackButtonPressed} setIsOpen={setIsBackButtonPressed}>
                <ChangeStepInfoModalContent
                    setIsAgree={setIsStepBackAgree}
                    setIsModalOpened={setIsBackButtonPressed}
                    text={i18n.t('Are you sure you want to go back? Your current data can be lost')}
                />
            </ModalWindow>
        </StepLayout>
    );
};
export default Step2AddCompany;

Step2AddCompany.propTypes = {
    setSetupCurrentPage: func,
    projectData: object,
    projectId: string,
    refetchProjectData: func,
    isLoadingProjectData: bool,
    isDisabledFields: bool,
};
