import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { func, object } from 'prop-types';
import FInput from '../../style/Input/Input';
import SearchDropDown from '../../style/SearchDropDown/SearchDropDown';
import { i18PAGES } from '../../config/i18n/constants';
import { PrimaryButton } from '../../style/Button/Button';
import { checkIfObjectEmpty } from '../util/checkIfObjectEmpty';
import { checkIfPersonalInformationFilled } from './helper';

const PersonalInformation = (props) => {
    const { t } = useTranslation(i18PAGES.QUESTIONNAIRE);

    const [countryRerenderTrigger, setCountryRerenderTrigger] = useState(0);
    const [cityRerenderTrigger, setCityRerenderTrigger] = useState(1);
    const [officeRerenderTrigger, setOfficeRerenderTrigger] = useState(2);

    const {
        values,
        handleBlur,
        handleChange,
        touched,
        errors,
        setFieldValue,
        options,
        getProjectDropdownsDefaultNames,
        setIsPersonalInfoSent,
    } = props;

    const isAllPersonalInformationFilled = useMemo(() => checkIfPersonalInformationFilled(values), [values]);

    const onSave = useCallback(() => {
        if (checkIfObjectEmpty(errors) && isAllPersonalInformationFilled) {
            setIsPersonalInfoSent(true);
        }
    }, [errors, isAllPersonalInformationFilled, setIsPersonalInfoSent]);

    const projectDropdownsDefaultNames = useMemo(
        () => getProjectDropdownsDefaultNames(values),
        [getProjectDropdownsDefaultNames, values]
    );

    const companiesOptions = useMemo(
        () => options?.companies?.map((c) => ({ label: c.companyName, value: c.companyId })),
        [options?.companies]
    );

    const countriesOptions = useMemo(
        () =>
            options?.countries
                .filter((c) => c.companyId === values.companyId)
                .map((c) => ({ label: c.countryName, value: c.countryId })),
        [options?.countries, values.companyId]
    );

    const citiesOptions = useMemo(
        () =>
            options?.cities
                .filter((c) => c.countryId === values.countryId)
                .map((c) => ({ label: c.cityName, value: c.cityId })),
        [options?.cities, values.countryId]
    );

    const officesOptions = useMemo(
        () =>
            options?.offices
                .filter((o) => o.cityId === values.cityId)
                .map((o) => ({ label: o.officeName, value: o.officeId })),
        [options?.offices, values.cityId]
    );

    return (
        <>
            <div className="mt-[40px] font-Lexend500 text-[18px] leading-[26px] text-int-white-main">
                {t('Personal information')}
            </div>
            <div className="mt-[50px] flex items-center">
                <FInput
                    name="role"
                    placeholder="Type"
                    value={values.role || ''}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    className="w-[320px] mr-[24px]"
                    label="Role"
                    type="text"
                    isError={!!(touched.role && errors.role)}
                    errorText={errors.role}
                    setFieldValue={setFieldValue}
                    limit={50}
                />
                <FInput
                    name="fte"
                    placeholder="Type"
                    value={values.fte || ''}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    className="w-[320px] mr-[24px]"
                    label="FTE contracted"
                    type="text"
                    isError={!!(touched.fte && errors.fte)}
                    errorText={errors.fte}
                    setFieldValue={setFieldValue}
                />
            </div>

            <div className="mt-[50px] flex items-center">
                <SearchDropDown
                    name="companyId"
                    placeholder="Select"
                    isError={!!(touched.companyId && errors.companyId)}
                    errorText={errors.companyId}
                    className="w-[320px] mr-[24px]"
                    options={companiesOptions}
                    value={projectDropdownsDefaultNames?.companyName || ''}
                    label="Company"
                    handleChange={(company) => {
                        setFieldValue('countryId', null);
                        setFieldValue('cityId', null);
                        setFieldValue('officeId', null);
                        setCountryRerenderTrigger((prev) => prev + 1);
                        setCityRerenderTrigger((prev) => prev + 1);
                        setOfficeRerenderTrigger((prev) => prev + 1);
                        setFieldValue('companyId', company.value);
                    }}
                    handleBlur={handleBlur}
                />

                <SearchDropDown
                    key={`company-${countryRerenderTrigger}`}
                    name="countryId"
                    placeholder="Select"
                    isError={!!(touched.countryId && errors.countryId)}
                    errorText={errors.countryId}
                    className="w-[320px] mr-[24px]"
                    isDisabled={!values.companyId}
                    options={countriesOptions}
                    value={projectDropdownsDefaultNames?.countryName || ''}
                    label="Country"
                    handleChange={(country) => {
                        setFieldValue('cityId', null);
                        setFieldValue('officeId', null);
                        setCityRerenderTrigger((prev) => prev + 1);
                        setOfficeRerenderTrigger((prev) => prev + 1);
                        setFieldValue('countryId', country.value);
                    }}
                    handleBlur={handleBlur}
                />

                <SearchDropDown
                    key={`city-${cityRerenderTrigger}`}
                    name="cityId"
                    placeholder="Select"
                    isError={!!(touched.cityId && errors.cityId)}
                    errorText={errors.cityId}
                    className="w-[320px] mr-[24px]"
                    isDisabled={!values.countryId}
                    options={citiesOptions}
                    label="City"
                    value={projectDropdownsDefaultNames?.cityName || ''}
                    handleChange={(city) => {
                        setOfficeRerenderTrigger((prev) => prev + 1);
                        setFieldValue('officeId', null);
                        setFieldValue('cityId', city.value);
                    }}
                    handleBlur={handleBlur}
                />

                <SearchDropDown
                    key={`office-${officeRerenderTrigger}`}
                    name="officeId"
                    placeholder="Select"
                    isError={!!(touched.officeId && errors.officeId)}
                    errorText={errors.officeId}
                    className="w-[320px] mr-[24px]"
                    isDisabled={!values.cityId}
                    options={officesOptions}
                    label="Office"
                    value={projectDropdownsDefaultNames?.officeName || ''}
                    handleChange={(office) => setFieldValue('officeId', office.value)}
                    handleBlur={handleBlur}
                />
            </div>
            <PrimaryButton handleClick={onSave} type="submit" className="mt-[40px] h-[50px] px-[32px]" isGrey>
                {t('Save & next')}
            </PrimaryButton>
        </>
    );
};

export default PersonalInformation;

PersonalInformation.propTypes = {
    values: object,
    handleBlur: func,
    handleChange: func,
    touched: object,
    errors: object,
    setFieldValue: func,
    options: object,
    getProjectDropdownsDefaultNames: func,
    setIsPersonalInfoSent: func,
};
