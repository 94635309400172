export const DEADLINE_STATUSES = {
    INITIAL: 'INITIAL',
    EXTEND: 'EXTEND',
};
export const BUILDING_BLOCKS_STATUSES = {
    CREATED: 'CREATED',
    WAITING_FOR_RESPONSE: 'WAITING_FOR_RESPONSE',
    REJECTED_BY_PROVIDER: 'REJECTED_BY_PROVIDER',
    DEADLINE_EXPIRED: 'DEADLINE_EXPIRED',
    SAVED_RESULT: 'SAVED_RESULT',
    REJECTED: 'REJECTED',
};

export const SEND_ASK_MESSAGES_STATUSES = {
    RENEW: 'RENEW',
    DELETE: 'DELETE',
};
