import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { ReactComponent as AddUserIcon } from '../../../assets/icons/addIcon.svg';
import { PrimaryButton } from '../../../style/Button/Button';
import WorkersListTableHeader from './WorkersListTableHeader';
import WorkersListTableItem from './WorkersListTableItem';
import { ReactComponent as UserManagementIcon } from '../../../assets/icons/userManagementIcon.svg';
import Pagination from '../../../style/Pagination/Pagination';
import AddWorkersModalForm from './AddWorkersModalForm';
import { i18PAGES } from '../../../config/i18n/constants';
import DeleteWorkerModal from './DeleteWorkerModal';
import EditWorkersModal from './EditWorkersModal';
import {
    useGetWorkersInformationQuery,
    useSendSurveysAllWorkerMutation,
} from '../../../store/API/workersInformationAPI';
import Preloader from '../../Preloader/Preloader';
import { projectManagersUserRoles, WORKERS_PAGINATION_PAGE_SIZE } from '../../util/constants';
import LoadingAndTextOverLayer from '../../../style/LoadingAndTextOverLayer/LoadingAndTextOverLayer';
import { useToast } from '../../../hoc/toast/ToastProvider';
import { ReactComponent as MenuWorkersInformationIcon } from '../../../assets/icons/menuWorkersInformationIcon.svg';
import { useAuth } from '../../../store/hooks/useAuth';
import { useSort } from '../../../hooks/useSort';

const WorkersListTable = () => {
    const { t } = useTranslation(i18PAGES.WORKERS_EFFICIENCY);

    const { successToast, errorToast } = useToast();

    const { orderFromUrl, pageFromUrl, sortByFromUrl } = useSort();

    const [searchParams, setSearchParams] = useSearchParams();
    const projectId = searchParams.get('projectId');
    const [activePage, setActivePage] = useState(pageFromUrl || 1);

    const [isResendingWorkerById, setIsResendingWorkerById] = useState(false);

    const [isAddWorkersModalOpened, setIsAddWorkersModalOpened] = useState(false);
    const [isOpenDeleteWorkerModal, setIsOpenDeleteWorkerModal] = useState(false);
    const [deleteWorkerModalData, setDeleteWorkerModalData] = useState(null);
    const [isOpenEditWorkersModal, setIsOpenEditWorkersModal] = useState(false);
    const [editWorkersModalData, setEditWorkersModalData] = useState(null);

    useEffect(() => {
        searchParams.set('page', String(activePage));
        setSearchParams(searchParams);
    }, [activePage, searchParams, setSearchParams]);

    const handleClickDeleteWorker = (data) => {
        setIsOpenDeleteWorkerModal(true);
        setDeleteWorkerModalData(data);
    };

    const handleClickEditWorker = (data) => {
        setIsOpenEditWorkersModal(true);
        setEditWorkersModalData(data);
    };

    const { data: dataWorkersInformation, isLoading: isLoadingDataWorkersInformation } = useGetWorkersInformationQuery(
        { projectId, page: activePage - 1, sortBy: sortByFromUrl, order: orderFromUrl },
        { skip: !projectId }
    );

    const [sendSurveysAllWorker] = useSendSurveysAllWorkerMutation();

    const [isSendingSurveysWorkers, setIsSendingSurveysWorkers] = useState(false);

    const sendSurveysWorker = () => {
        setIsSendingSurveysWorkers(true);
        sendSurveysAllWorker({
            projectId,
        })
            .unwrap()
            .then(() => {
                successToast(
                    t('Surveys were successfully sent to users'),
                    '',
                    <MenuWorkersInformationIcon fill="#EDF1FD" />,
                    ''
                );
            })
            .catch(() => {
                errorToast(t('Error sent surveys to users'));
            })
            .finally(() => {
                setIsSendingSurveysWorkers(false);
            });
    };

    const { projectRole } = useAuth();
    const isRoleReviewer = useMemo(() => projectRole === projectManagersUserRoles.ROLE_REVIEWER, [projectRole]);

    return (
        <>
            <div className="min-h-[685px]">
                <div className="bg-int-gray-main p-[30px] rounded-lg mr-[40px]">
                    <div className="flex justify-between items-center">
                        <div className="text-[24px] font-Lexend500 leading-[26px]">{t('Workers list')}</div>

                        {isRoleReviewer ? (
                            <div className="h-[50px]" />
                        ) : (
                            <div className="flex items-center">
                                <div
                                    className="flex items-center h-[50px] px-[24px] cursor-pointer"
                                    onClick={() => setIsAddWorkersModalOpened(true)}
                                >
                                    <AddUserIcon width={16} height={16} fill="#FFFFFF" />
                                    <div className="ml-[6px]">{t('Add worker')}</div>
                                </div>

                                <PrimaryButton
                                    isGrey
                                    className="h-[50px] px-[24px] ml-[20px]"
                                    handleClick={sendSurveysWorker}
                                    disabled={!dataWorkersInformation?.newWorkersExists}
                                >
                                    <div className="text-[18px] font-Lexend400">{t('Send surveys')}</div>
                                </PrimaryButton>
                            </div>
                        )}
                    </div>
                    <WorkersListTableHeader isRoleReviewer={isRoleReviewer} />
                    {isLoadingDataWorkersInformation ? (
                        <div className="h-[40px] mt-[30px] mb-[30px] flex items-center justify-center">
                            <Preloader />
                        </div>
                    ) : (
                        <div>
                            {dataWorkersInformation?.workerDetails?.content?.length > 0 ? (
                                dataWorkersInformation?.workerDetails?.content?.map((user) => (
                                    <WorkersListTableItem
                                        key={user.id}
                                        id={user.id}
                                        fullName={user.fullName}
                                        email={user.email}
                                        roles={user.role}
                                        status={user.status}
                                        handleClickDelete={() => {
                                            handleClickDeleteWorker(user);
                                        }}
                                        handleClickEdit={() => {
                                            handleClickEditWorker(user);
                                        }}
                                        setIsResendingWorkerById={setIsResendingWorkerById}
                                        projectId={projectId}
                                        isRoleReviewer={isRoleReviewer}
                                    />
                                ))
                            ) : (
                                <div className="flex flex-col items-center">
                                    <div className="mt-[40px] mb-[14px]">
                                        <UserManagementIcon />
                                    </div>
                                    <div className="text-[16px] leading-[30px] font-Lexend400 text-int-white-secondary">
                                        {t('There are no users in the list. Please add new users.')}
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>

            <div className="flex justify-center mt-[30px]">
                <Pagination
                    count={dataWorkersInformation?.workerDetails?.totalElements}
                    pageSize={WORKERS_PAGINATION_PAGE_SIZE}
                    activePage={activePage}
                    setActivePage={setActivePage}
                />
            </div>
            <AddWorkersModalForm
                isAddWorkersModalOpened={isAddWorkersModalOpened}
                setIsAddWorkersModalOpened={setIsAddWorkersModalOpened}
                projectId={projectId}
                setActivePage={setActivePage}
            />
            <EditWorkersModal
                setIsOpenEditWorkersModal={setIsOpenEditWorkersModal}
                isOpenEditWorkersModal={isOpenEditWorkersModal}
                id={editWorkersModalData?.id}
                fullName={editWorkersModalData?.fullName}
                role={editWorkersModalData?.role}
                email={editWorkersModalData?.email}
                projectId={projectId}
            />
            <DeleteWorkerModal
                isOpenDeleteWorkerModal={isOpenDeleteWorkerModal}
                setIsOpenDeleteWorkerModal={setIsOpenDeleteWorkerModal}
                id={deleteWorkerModalData?.id}
                fullName={deleteWorkerModalData?.fullName}
                projectId={projectId}
            />
            <LoadingAndTextOverLayer isOpen={isResendingWorkerById} text={t('Process resend surveys worker')} />
            <LoadingAndTextOverLayer isOpen={isSendingSurveysWorkers} text={t('Process send surveys all workers')} />
        </>
    );
};

export default WorkersListTable;
