import React from 'react';
import { arrayOf, bool, number, oneOfType, shape, string } from 'prop-types';
import TableHeader from '../../../../style/Table/TableHeader';
import AsIsApplicationViewTableRow from './AsIsApplicationViewTableRow';

const AsIsApplicationsViewTable = (props) => {
    const { tableHeaderData, data } = props;

    return (
        <div id="bold-scroll" className="overflow-auto max-w-[1450px]">
            <table className="table-fixed">
                <TableHeader data={tableHeaderData} isReadMode />
                <tbody>
                    {data?.length > 0 ? (
                        data?.map((application, index) => (
                            <AsIsApplicationViewTableRow applicationItem={application} key={index} />
                        ))
                    ) : (
                        <AsIsApplicationViewTableRow />
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default AsIsApplicationsViewTable;

AsIsApplicationsViewTable.propTypes = {
    tableHeaderData: arrayOf(
        shape({
            minWidth: number,
            name: string,
            isSortable: bool,
            queryName: string,
        })
    ),
    data: arrayOf(
        shape({
            module: shape({
                id: number,
                name: string,
            }),
            buildingBlock: shape({
                id: number,
                name: string,
            }),
            applicationName: string,
            applicationLanguages: arrayOf(string),
            status: string,
            systemSatisfactions: oneOfType([string, number]),
            reasonToChange: string,
            licenseCostsPerYear: oneOfType([string, number]),
            operatingCostsInternalPerYear: oneOfType([string, number]),
            supportCostsExternalPerYear: oneOfType([string, number]),
            interfaceCostsPerYear: oneOfType([string, number]),
            trainingCostsPerYear: oneOfType([string, number]),
        })
    ),
};
