import React from 'react';
import { shape, string } from 'prop-types';
import { OnlyViewFieldTextArea } from '../../../../Profile/OnlyViewFieldInput';

const ChallengesReadItem = ({ challenge }) => (
    <div className="flex mb-[16px]">
        <div className="w-[1370px]">
            <OnlyViewFieldTextArea styles="h-[84px]" label="" value={challenge.content || ''} />
        </div>
    </div>
);

export default ChallengesReadItem;

ChallengesReadItem.propTypes = {
    challenge: shape({
        content: string.isRequired,
    }),
};
