import React, { useEffect } from 'react';
import { node } from 'prop-types';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { PageLoader } from '../../components/Preloader/Preloader';
import { useAuth } from '../../store/hooks/useAuth';
import { ROLES } from '../../components/util/roleStatus';
import { projectManagersUserRoles } from '../../components/util/constants';
import http from '../../components/util/http';

const ProjectRolesProvider = ({ children }) => {
    const [searchParams] = useSearchParams();
    const projectId = searchParams.get('projectId');
    const navigate = useNavigate();
    const pathname = useLocation()?.pathname;

    const {
        profileData,
        setIsEditableListProject,
        setIsEditablePagesProject,
        setProjectRole,
        setIsProjectRoleLoading,
        isProjectRoleLoading,
    } = useAuth();

    useEffect(() => {
        setIsEditableListProject(false);
        setIsEditablePagesProject(false);
        setProjectRole(null);
        if (projectId && profileData) {
            setIsProjectRoleLoading(true);
            http.get(`projects/${projectId}/roles`)
                .then((res) => {
                    setProjectRole(res.data);

                    if (profileData.userRoles.includes(ROLES.ROLE_ADMIN)) {
                        setIsEditableListProject(true);
                        setIsEditablePagesProject(true);
                    } else if (res.data === projectManagersUserRoles.ROLE_MANAGER) {
                        setIsEditableListProject(true);
                        setIsEditablePagesProject(true);
                    } else if (res.data === projectManagersUserRoles.ROLE_CONTRIBUTOR) {
                        setIsEditablePagesProject(true);
                    } else if (res.data === '') {
                        setIsEditablePagesProject(true);
                    }
                })
                .finally(() => {
                    setIsProjectRoleLoading(false);
                });
        }
    }, [projectId, profileData]);

    useEffect(() => {
        if (
            profileData?.userRoles.includes(ROLES.NOT_COMPLETED_USER) &&
            !pathname.includes('/invoices/cancel') &&
            !pathname.includes('/confirm-transaction')
        ) {
            navigate('/invoices/invoice-info');
        }
    }, [profileData?.userRoles]);

    if (isProjectRoleLoading && pathname !== '/projects-list') {
        return <PageLoader />;
    }

    return <div>{children}</div>;
};

export default ProjectRolesProvider;

ProjectRolesProvider.propTypes = {
    children: node,
};
