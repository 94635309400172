import React from 'react';
import { shape, string } from 'prop-types';
import { OnlyViewFieldTextArea } from '../../../../Profile/OnlyViewFieldInput';

const HrDigitalizationReadItem = ({ hrDigitalizations }) => (
    <div className="flex mb-[16px]">
        <div className="w-[370px] mr-[10px]">
            <OnlyViewFieldTextArea styles="h-[84px]" label="" value={hrDigitalizations.stageName || ''} />
        </div>
        <div className="w-[900px]">
            <OnlyViewFieldTextArea styles="h-[84px]" label="" value={hrDigitalizations.description || ''} />
        </div>
    </div>
);

export default HrDigitalizationReadItem;

HrDigitalizationReadItem.propTypes = {
    hrDigitalizations: shape({
        stageName: string.isRequired,
        description: string.isRequired,
    }),
};
