import React, { useState } from 'react';
import MyDatePickerRange from '../style/MyDatePicker/MyDatePickerRange';
import MyDatePicker from '../style/MyDatePicker/MyDatePicker';

const DatePickerPage = () => {
    const [startDate, setStartDate] = useState(null);

    const [startDateRange, setStartDateRange] = useState(null);
    const [endDateRange, setEndDateRange] = useState(null);

    return (
        <div>
            <div className="w-[500px]">
                <div>No error</div>
                <MyDatePicker startDate={startDate} setStartDate={setStartDate} disableDatesBeforeToday />
            </div>
            <div className="w-[500px] mt-[50px]">
                <div>With error</div>
                <MyDatePicker
                    startDate={startDate}
                    setStartDate={setStartDate}
                    disableDatesBeforeToday
                    isError
                    textError="Simple text error"
                />
            </div>
            <div className="w-[500px] mt-[50px]">
                <div>No error</div>
                <MyDatePickerRange
                    startDate={startDateRange}
                    setStartDate={setStartDateRange}
                    endDate={endDateRange}
                    setEndDate={setEndDateRange}
                    disableDatesBeforeToday
                />
            </div>
            <div className="w-[500px] mt-[50px]">
                <div>With error</div>
                <MyDatePickerRange
                    startDate={startDateRange}
                    setStartDate={setStartDateRange}
                    endDate={endDateRange}
                    setEndDate={setEndDateRange}
                    disableDatesBeforeToday
                    isError
                    textError="Simple text error long long long"
                />
            </div>
        </div>
    );
};
export default DatePickerPage;
