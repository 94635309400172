import React, { useRef, useState } from 'react';
import { Form, Formik } from 'formik';
import { number } from 'prop-types';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import DescriptionAndButtonsBlock from '../../Common/DescriptionAndButtonsBlock';
import WeaknessExpectationsReadMode from './WeaknessExpectationsReadMode/WeaknessExpectationsReadMode';
import { weaknessExpectationsEmptyObject, weaknessExpectationsValidationSchema } from '../Common/constants';
import WeaknessExpectationsEditMode from './WeaknessExpectationsEditMode/WeaknessExpectationsEditMode';
import {
    useGetWeaknessExpectationsQuery,
    useSendModuleStrategiesMutation,
} from '../../../../store/API/modulesStrategiesAPI';
import { useToast } from '../../../../hoc/toast/ToastProvider';
import { i18PAGES } from '../../../../config/i18n/constants';
import Preloader from '../../../Preloader/Preloader';
import { useEditMode } from '../../../util/useEditMode';
import WarningModal from '../../../../style/WarningModal/WarningModal';
import { MODULES_AND_STRATEGY_TABS_IDENTIFIERS } from '../../../util/constants';
import { useWarningModalControl } from '../../../../store/hooks/useWarningModalControl';

const WeaknessExpectations = (props) => {
    const { projectId } = props;
    const { t } = useTranslation(i18PAGES.MODULES_AND_STRATEGY);
    const { isLoading, data, refetch } = useGetWeaknessExpectationsQuery(projectId, { skip: !projectId });

    // const [isEditMode, setIsEditMode] = useState(false);
    //
    // const [isShowCloseEditAlertModal, setIsShowCloseEditAlertModal] = useState(false);

    const formikRef = useRef();

    const {
        isEditMode,
        isShowCloseEditAlertModal,
        openEditAlertModal,
        acceptOffEditMode,
        declineOffEditMode,
        setIsEditMode,
        setIsShowCloseEditAlertModal,
    } = useWarningModalControl(formikRef);

    useEditMode(isEditMode);
    // const openEditAlertModal = () => {
    //     setIsShowCloseEditAlertModal(true);
    // };
    //
    // const acceptOffEditMode = () => {
    //     setIsShowCloseEditAlertModal(false);
    //     setIsEditMode(false);
    // };
    //
    // const declineOffEditMode = () => {
    //     setIsShowCloseEditAlertModal(false);
    // };

    const { successToast, errorToast } = useToast();

    const [sendProblems] = useSendModuleStrategiesMutation();

    const onEditMode = () => {
        setIsEditMode(true);
    };

    if (isLoading) {
        return (
            <div className="h-[350px] flex items-center justify-center">
                <Preloader />
            </div>
        );
    }

    return (
        <>
            <Formik
                initialValues={{
                    weaknessExpectations:
                        data?.weaknessExpectations?.length > 0
                            ? data?.weaknessExpectations
                            : [weaknessExpectationsEmptyObject],
                }}
                enableReinitialize
                innerRef={formikRef}
                onSubmit={(values, { setSubmitting }) => {
                    sendProblems({
                        projectId,
                        weaknessExpectations: values.weaknessExpectations,
                        problems: [],
                        actions: [],
                        hrDigitalizations: [],
                        hrDigitalizationGoals: [],
                        hrStrategies: [],
                        challenges: [],
                        pageIdentifier: MODULES_AND_STRATEGY_TABS_IDENTIFIERS.WEAKNESSES_AND_EXPECTATIONS,
                    })
                        .unwrap()
                        .then(() => {
                            refetch().then(() => {
                                successToast(t('Weaknesses & expectations were successfully updated'));
                                setIsEditMode(false);
                            });
                        })
                        .catch((err) => {
                            errorToast(i18n.t('Something went wrong, try again later'));
                        })
                        .finally(() => {
                            setSubmitting(false);
                        });
                }}
                validationSchema={weaknessExpectationsValidationSchema}
            >
                {({ values, isSubmitting, handleSubmit, errors, touched, handleChange, handleBlur, setFieldValue }) => (
                    <Form>
                        <DescriptionAndButtonsBlock
                            offEditMode={openEditAlertModal}
                            onEditMode={onEditMode}
                            descriptionText="Explanation on how to fill in information on this tab"
                            isEditMode={isEditMode}
                            handleSave={handleSubmit}
                            isSubmitting={isSubmitting}
                        />

                        {isEditMode ? (
                            <WeaknessExpectationsEditMode
                                values={values}
                                handleBlur={handleBlur}
                                handleChange={handleChange}
                                errors={errors}
                                touched={touched}
                                setFieldValue={setFieldValue}
                            />
                        ) : (
                            <WeaknessExpectationsReadMode weaknessExpectations={data?.weaknessExpectations || []} />
                        )}
                    </Form>
                )}
            </Formik>
            <WarningModal
                isOpen={isShowCloseEditAlertModal}
                setIsOpen={setIsShowCloseEditAlertModal}
                warningText={t('Are you sure you want to close editing mode? Your result will not be saved')}
                acceptButtonText={t('Close')}
                declineButtonText={t('Cancel')}
                accept={acceptOffEditMode}
                decline={declineOffEditMode}
            />
        </>
    );
};

export default WeaknessExpectations;

WeaknessExpectations.propTypes = {
    projectId: number,
};
