import React from 'react';
import { useTranslation } from 'react-i18next';
import { arrayOf, bool, number, shape, string } from 'prop-types';
import InvoicesSubscriptionsHeader from './InvoicesSubscriptionsHeader';
import InvoicesSubscriptionsItem from './InvoicesSubscriptionsItem';
import { i18PAGES } from '../../../config/i18n/constants';
import Preloader from '../../Preloader/Preloader';

const InvoicesSubscriptionsTable = ({ invoicesSubscriptionList, isLoadingInvoicesSubscriptionList }) => {
    const { t } = useTranslation(i18PAGES.SUBSCRIPTION_MANAGEMENT);

    const tableDataRender = () => {
        if (isLoadingInvoicesSubscriptionList)
            return (
                <div className="h-[70px] flex items-center justify-center">
                    <Preloader />
                </div>
            );
        if (invoicesSubscriptionList?.length === 0)
            return (
                <div className="flex items-center justify-center pt-[20px] text-[16px]">
                    {t(`You have no Invoices`)}
                </div>
            );
        return invoicesSubscriptionList?.map((item, index) => (
            <InvoicesSubscriptionsItem
                data={item}
                index={index}
                length={invoicesSubscriptionList?.length}
                key={index}
            />
        ));
    };

    return (
        <div className="w-[1020px] p-[30px] bg-int-gray-main rounded-lg mb-[30px]">
            <div className=" text-int-white-main mb-[40px] flex justify-between items-center">
                <div className="text-[24px] leading-[24px] font-Lexend500">{t('Invoices')}</div>
            </div>
            <InvoicesSubscriptionsHeader />
            {tableDataRender()}
        </div>
    );
};

export default InvoicesSubscriptionsTable;

InvoicesSubscriptionsTable.propTypes = {
    invoicesSubscriptionList: arrayOf(
        shape({
            amount: number,
            date: string,
            invoiceNumber: number,
            status: string,
        })
    ),
    isLoadingInvoicesSubscriptionList: bool.isRequired,
};
