import React, { useCallback } from 'react';
import { func, number, object } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ReactComponent as DeleteIcon } from '../../../../assets/icons/userManagementBigDeleteIcon.svg';
import FInput from '../../../../style/Input/Input';
import RadioInput from '../../../../style/RadioInput/RadioInput';
import { i18PAGES } from '../../../../config/i18n/constants';
import ChoicePagesDropDown from '../ChoicePagesDropDown/ChoicePagesDropDown';
import { projectManagersUserRoles } from '../../../util/constants';

const ExternalAddItem = ({
    values,
    handleBlur,
    setFieldValue,
    index,
    usersErrors,
    usersTouched,
    ArrayHelpers,
    handleChange,
}) => {
    const { t } = useTranslation(i18PAGES.PROJECTS_MANAGEMENT);

    const removeHandler = useCallback(() => {
        ArrayHelpers.remove(index);
    }, [ArrayHelpers, index]);

    return (
        <div>
            <div className="flex">
                <FInput
                    name={`userList.${index}.fullName`}
                    placeholder={t('Type name')}
                    value={values.userList[index].fullName}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    className="w-[320px] mt-[40px] mr-[12px]"
                    type="textarea"
                    label={t('Name')}
                    isError={!!(usersTouched.fullName && usersErrors.fullName)}
                    errorText={usersErrors.fullName}
                    isNoAutoComplete
                    setFieldValue={setFieldValue}
                    limit={50}
                    bottomPosition="bottom-[28px]"
                />
                <FInput
                    name={`userList.${index}.email`}
                    placeholder={t('Type email')}
                    value={values.userList[index].email}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    className="w-[320px] mt-[40px] mr-[12px]"
                    type="textarea"
                    label={t('Email')}
                    isError={!!(usersTouched.email && usersErrors.email)}
                    errorText={usersErrors.email}
                    isNoAutoComplete
                    setFieldValue={setFieldValue}
                />
                <ChoicePagesDropDown
                    name={`userList.${index}.pages`}
                    label="Pages"
                    placeholder="Select"
                    className="w-[320px] mt-[40px] mr-[12px] min-h-[70px]"
                    values={values}
                    valuesPages={values.userList[index].pages}
                    index={index}
                    handleBlur={handleBlur}
                    isTouched={!!usersTouched.pages}
                />
                {values?.userList?.length > 1 ? (
                    <div
                        className="flex justify-center items-center w-[20px] h-[42px] mt-[40px] cursor-pointer"
                        onClick={removeHandler}
                    >
                        <DeleteIcon />
                    </div>
                ) : (
                    <div className="w-[20px] h-[42px]" />
                )}
            </div>
            <div className="flex items-center text-int-white-main">
                <div className="text-[16px] leading-[24px] font-Lexend500 text-int-white-main mr-[42px]">
                    {t('Select Role')}
                </div>
                <div className="mr-[30px]">
                    <RadioInput
                        setFieldValue={setFieldValue}
                        name={`userList.${index}.permissions`}
                        label="Reviewer"
                        value={projectManagersUserRoles.ROLE_REVIEWER}
                        isChecked={values.userList[index].permissions === projectManagersUserRoles.ROLE_REVIEWER}
                    />
                </div>
                <div className="mr-[30px]">
                    <RadioInput
                        setFieldValue={setFieldValue}
                        name={`userList.${index}.permissions`}
                        label="Contributor"
                        value={projectManagersUserRoles.ROLE_CONTRIBUTOR}
                        isChecked={values.userList[index].permissions === projectManagersUserRoles.ROLE_CONTRIBUTOR}
                    />
                </div>
            </div>
            <div className="w-[100%] h-[1px] bg-int-gray-secondary mt-[20px]" />
        </div>
    );
};

export default React.memo(ExternalAddItem);

ExternalAddItem.propTypes = {
    values: object.isRequired,
    handleBlur: func.isRequired,
    setFieldValue: func.isRequired,
    index: number.isRequired,
    usersErrors: object.isRequired,
    usersTouched: object.isRequired,
    ArrayHelpers: object.isRequired,
    handleChange: func.isRequired,
};
