import { isRejectedWithValue } from '@reduxjs/toolkit';
import { setAuth, setIsPermission, setServerError } from '../../store/reducers/authReducer';
import { AUTH_TOKEN } from './http';

export const rtkQueryErrorLogger =
    ({ dispatch }) =>
    (next) =>
    (action) => {
        if (isRejectedWithValue(action)) {
            if (action.payload.status >= 500) {
                dispatch(setServerError(true));
            }

            if (action.payload.status === 401) {
                localStorage.removeItem(AUTH_TOKEN);
                dispatch(setAuth(false));
            }

            if (action.payload.status === 403 || action.payload.status === 406 || action.payload.status === 404) {
                dispatch(setIsPermission(false));
            }
        }

        return next(action);
    };
