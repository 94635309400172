import React, { memo, useMemo, useState } from 'react';
import { bool, func, number, oneOfType, string } from 'prop-types';
import classnames from 'classnames';
import { ReactComponent as ErrorIcon } from '../../assets/icons/errorIcon.svg';
import { ReactComponent as OpenEyeIcon } from '../../assets/icons/openEyeIcon.svg';
import { ReactComponent as CloseEyeIcon } from '../../assets/icons/closeEyeIcon.svg';
import { ReactComponent as SearchIcon } from '../../assets/icons/searchIcon.svg';
import { ReactComponent as DeleteTextIcon } from '../../assets/icons/deleteText.svg';

const FInput = ({
    isError,
    errorText,
    type,
    placeholder,
    name,
    label,
    className,
    isPassword,
    value,
    handleChange,
    handleBlur,
    handleFocus,
    isNoAutoComplete = false,
    isLabelUppercase = false,
    limit,
    setFieldValue,
    bottomPosition,
}) => {
    const [showPassword, setShowPassword] = useState(false);
    const [isOpenHelpErrorInfo, setIsOpenHelpErrorInfo] = useState(false);
    const [focused, setFocused] = useState(false);

    const showPasswordToggle = () => {
        setShowPassword((prev) => !prev);
    };

    const onBlurHandler = (e) => {
        handleBlur(e);
        setFocused(false);
    };

    const onFocusHandler = () => {
        if (handleFocus) {
            handleFocus();
        }
        setFocused(true);
    };

    const onDeleteText = () => {
        handleChange('');
        setFieldValue(name, '');
    };

    const labelClass = useMemo(
        () =>
            classnames(
                `${
                    isLabelUppercase && 'uppercase'
                } absolute left-0 top-[-20px] text-[14px] leading-[16px] font-Lexend400`,
                {
                    'text-error': isError,
                    'text-int-white-main': !isError,
                }
            ),
        [isError, isLabelUppercase]
    );

    const inputClass = useMemo(
        () =>
            classnames('border inline-block w-[100%] rounded-[8px] bg-[#2A3143] pr-[35px] px-[24px] py-[11px]', {
                'border-[#EDF1FD]': !isError && focused,
                'border-error': isError,
            }),
        [isError, focused]
    );

    return (
        <div className={`relative ${className || ''}`}>
            {label && <div className={labelClass}>{label}</div>}

            <input
                autoComplete={isNoAutoComplete ? 'new-password' : ''}
                type={!isPassword ? type : `${showPassword ? 'text' : 'password'}`}
                placeholder={placeholder}
                name={name}
                value={value}
                onChange={handleChange}
                onBlur={onBlurHandler}
                onFocus={onFocusHandler}
                className={inputClass}
            />

            {isError && (
                <div className={`absolute ${isPassword ? 'right-[40px]' : 'right-[15px]'}  top-[12px]`}>
                    <ErrorIcon />
                </div>
            )}

            {isError ? (
                <div className={`absolute ${isPassword ? 'right-[40px]' : 'right-[15px]'}  top-[12px]`}>
                    <div
                        className={errorText && 'cursor-pointer'}
                        onMouseEnter={() => setIsOpenHelpErrorInfo(true)}
                        onMouseLeave={() => setIsOpenHelpErrorInfo(false)}
                    >
                        <ErrorIcon />
                    </div>
                    {isOpenHelpErrorInfo && errorText && (
                        <div
                            className="text-[12px] text-error font-Lexend300 absolute
                     bg-[#222730] p-[10px] rounded-[2px] z-10 bottom-[23px] right-0 whitespace-nowrap"
                        >
                            {errorText}
                        </div>
                    )}
                </div>
            ) : (
                <>
                    {!isPassword && String(value).length > 0 && (
                        <div
                            className="absolute right-[15px] top-[15px] h-[14px] w-[14px] hover:bg-int-gray-secondary flex items-center justify-center cursor-pointer"
                            onClick={onDeleteText}
                        >
                            <DeleteTextIcon width={10} height={10} fill="#EDF1FD" />
                        </div>
                    )}
                </>
            )}
            {isPassword && (
                <div className="absolute right-[15px] top-[15px] cursor-pointer" onClick={showPasswordToggle}>
                    {showPassword ? <CloseEyeIcon /> : <OpenEyeIcon />}
                </div>
            )}
            {!isPassword && limit && String(value).length > 0 && (
                <div
                    className={`absolute right-[8px]  ${
                        bottomPosition || 'bottom-[3px]'
                    } text-int-white-secondary text-[10px] leading-[10px] font-Lexend400`}
                >
                    {String(value).length}/{limit}
                </div>
            )}
        </div>
    );
};

export default memo(FInput);

FInput.propTypes = {
    isError: bool,
    isPassword: bool,
    errorText: string,
    type: string.isRequired,
    placeholder: string,
    name: string.isRequired,
    label: string,
    className: string,
    value: oneOfType([string, number]).isRequired,
    handleChange: func.isRequired,
    handleBlur: func,
    handleFocus: func,
    isNoAutoComplete: bool,
    isLabelUppercase: bool,
    limit: number,
    setFieldValue: func.isRequired,
    bottomPosition: string,
};

export const SearchInput = ({ inputText, setInputText, className, color = '#1F2937' }) => {
    const onChangeHandler = (e) => {
        setInputText(e.target.value);
    };

    return (
        <div className={`relative ${className || ''}`}>
            <input
                type="text"
                placeholder="Tool suchen"
                value={inputText}
                onChange={onChangeHandler}
                className="inline-block w-[100%] rounded-[8px] pl-[46px] pr-[10px] py-[11px]"
                style={{
                    backgroundColor: color && color,
                }}
            />
            <div className="absolute left-0 top-[14px] left-[17px]">
                <SearchIcon />
            </div>
        </div>
    );
};

SearchInput.propTypes = {
    inputText: string.isRequired,
    setInputText: func.isRequired,
    className: string,
    color: string,
};
