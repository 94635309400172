import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { HEADERS } from '../../components/util/constants';

export const asIsApplicationsAPI = createApi({
    reducerPath: 'asIsApplicationsAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api',
        prepareHeaders: HEADERS,
    }),
    tagTypes: ['asIsApplicationsList'],
    endpoints: (build) => ({
        asIsApplicationInfo: build.query({
            query: ({ projectId, officeId, sortBy, orderBy }) =>
                `/projects/${projectId}/offices/${officeId}/as-is-applications?${sortBy ? `sort=${sortBy}` : ''}${
                    sortBy && orderBy ? `,${orderBy || 'DESC'}` : ''
                }`,
            keepUnusedDataFor: 0.0001,
        }),
        sendAsIsApplications: build.mutation({
            query: ({ projectId, officeId, body }) => ({
                url: `/projects/${projectId}/offices/${officeId}/as-is-applications`,
                method: 'POST',
                body,
            }),
        }),

        asIsApplicationsOptions: build.query({
            query: ({ projectId, officeId }) => `/projects/${projectId}/offices/${officeId}/as-is-applications/options`,
            keepUnusedDataFor: 0.0001,
        }),
    }),
});

export const { useAsIsApplicationInfoQuery, useSendAsIsApplicationsMutation, useAsIsApplicationsOptionsQuery } =
    asIsApplicationsAPI;
