import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { HEADERS } from '../../components/util/constants';

export const optionsAPI = createApi({
    reducerPath: 'optionsAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api',
        prepareHeaders: HEADERS,
    }),
    tagTypes: ['options'],
    endpoints: (build) => ({
        getVariationsOptions: build.query({
            query: (projectId) => `/projects/${projectId}/building-blocks/tools/variation-options`,
        }),

        getVariations: build.query({
            query: (projectId) => `/projects/${projectId}/building-blocks/tools/variation-options/all`,
            providesTags: ['options'],
        }),

        sendVariation: build.mutation({
            query: ({ projectId, body }) => ({
                url: `/projects/${projectId}/building-blocks/tools/variation-options`,
                method: 'POST',
                body,
            }),
            invalidatesTags: ['options'],
        }),
        deleteVariation: build.mutation({
            query: ({ projectId, variationId }) => ({
                url: `/projects/${projectId}/building-blocks/tools/variation-options/${variationId}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['options'],
        }),
    }),
});

export const {
    useGetVariationsOptionsQuery,
    useGetVariationsQuery,
    useSendVariationMutation,
    useDeleteVariationMutation,
} = optionsAPI;
