import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    name: '',
    companyId: null,
    countriesQuantity: 0,
    countriesInfo: {},
    citiesInfo: [],
    officesInfo: [],
    companyTree: {},
};

const companySetupSlice = createSlice({
    name: 'company-setup',
    initialState,
    reducers: {
        setFirstStep(state, action) {
            return { ...state, ...action.payload };
        },
        setCountries(state, action) {
            state.countriesInfo = action.payload;
        },
        setCities(state, action) {
            state.citiesInfo = action.payload;
        },
        setOffices(state, action) {
            state.officesInfo = action.payload;
        },
        clearCompanySetup(state) {
            return { ...initialState, companyId: state.companyId, companyTree: { id: state.companyTree.id } };
        },
        setCompanyTree(state, action) {
            state.companyTree = action.payload;
        },
        clearCities(state) {
            state.citiesInfo = [];
            state.officesInfo = [];
        },
        clearOffices(state) {
            state.officesInfo = [];
        },
        clearCompanySetupState() {
            return initialState;
        },
    },
});

export const {
    setFirstStep,
    setCompanyTree,
    setCountries,
    setCities,
    setOffices,
    clearCompanySetup,
    clearCities,
    clearOffices,
    clearCompanySetupState,
} = companySetupSlice.actions;

export default companySetupSlice.reducer;
