import { array, object, string } from 'yup';

export const problemsOverviewValidationSchema = object().shape({
    problems: array().of(
        object().shape({
            name: string().required('Name of the problem should be stated').max(100, 'Max length is 100 symbols'),
            description: string().required('Problem should be stated').max(500, 'Max length is 500 symbols'),
        })
    ),
});

export const problemsOverviewEmptyObject = {
    name: '',
    description: '',
};

export const weaknessExpectationsValidationSchema = object().shape({
    weaknessExpectations: array().of(
        object().shape({
            weakness: string().required('Name of the weakness should be stated').max(100, 'Max length is 100 symbols'),
            description: string().required('Description should be stated').max(500, 'Max length is 500 symbols'),
            expectation: string().required('Expectation should be stated').max(500, 'Max length is 500 symbols'),
        })
    ),
});

export const weaknessExpectationsEmptyObject = {
    weakness: '',
    description: '',
    expectation: '',
};

export const actionsValidationSchema = object().shape({
    actions: array().of(
        object().shape({
            action: string().required('Name of the action should be stated').max(100, 'Max length is 100 symbols'),
            description: string().required('Description should be stated').max(500, 'Max length is 500 symbols'),
        })
    ),
});

export const actionsEmptyObject = {
    action: '',
    description: '',
};

export const hrDigitalizationsValidationSchema = object().shape({
    hrDigitalizations: array().of(
        object().shape({
            stageName: string().required('Stage name should be stated').max(100, 'Max length is 100 symbols'),
            description: string().required('Description should be stated').max(500, 'Max length is 500 symbols'),
        })
    ),
});

export const hrDigitalizationsEmptyObject = {
    stageName: '',
    description: '',
};

export const hrDigitalizationGoalsValidationSchema = object().shape({
    hrDigitalizationGoals: array().of(
        object().shape({
            goal: string().required('Goal should be stated').max(100, 'Max length is 100 symbols'),
            contribution: string().required('Contribution should be stated').max(500, 'Max length is 500 symbols'),
        })
    ),
});

export const hrDigitalizationGoalsEmptyObject = {
    goal: '',
    contribution: '',
};

export const hrStrategiesValidationSchema = object().shape({
    hrStrategies: array().of(
        object().shape({
            name: string().required('Name should be stated').max(100, 'Max length is 100 symbols'),
            strategyPoints: string().required('Strategy points should be stated').max(500, 'Max length is 500 symbols'),
        })
    ),
});

export const hrStrategiesEmptyObject = {
    name: '',
    strategyPoints: '',
};

export const challengesValidationSchema = object().shape({
    challenges: array().of(
        object().shape({
            content: string().required('Challenge should be stated').max(500, 'Max length is 500 symbols'),
        })
    ),
});

export const challengesEmptyObject = {
    content: '',
};
