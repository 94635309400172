import { array, object, string } from 'yup';
import { REGEX } from '../../util/constants';

export const validationSchema = object().shape({
    buildingBlock: object().shape({
        tools: array().of(
            object().shape({
                email: string()
                    .test(
                        'Required if checkbox is chosen',
                        'Email should be stated',
                        (value, context) => !(context.parent.selected === true && !value)
                    )
                    .test('If string is valid email', 'Invalid email', (value, context) => {
                        if (context.parent.selected === true) {
                            return REGEX.EMAIL.test(value);
                        }
                        return true;
                    })
                    .nullable(),
            })
        ),
    }),
});
