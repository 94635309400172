import React, { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { useSearchParams } from 'react-router-dom';
import { OrganisationStructure, PAGE_IDENTIFIER } from '../components/util/constants';
import { i18PAGES } from '../config/i18n/constants';
import ProjectInfoPageHeader from '../components/Platform/Common/ProjectInfoPageHeader';
import ProjectInfoTabSwitcher from '../components/Platform/Common/ProjectInfoTabSwitcher';
import { companyInfoValidationSchema } from '../components/Platform/CompaniesInfo/validationSchema';
import WarningModal from '../style/WarningModal/WarningModal';
import { useOfficeDetailInfoQuery, useUpdateOfficeDetailInfoMutation } from '../store/API/projectsInfoAPI';
import { LayoutLoader } from '../components/Preloader/Preloader';
import i18n from '../config/i18n/i18n';
import { useTabsMenu } from '../store/hooks/useTabsMenu';
import CompaniesInfo from '../components/Platform/CompaniesInfo/CompaniesInfo';
import { useToast } from '../hoc/toast/ToastProvider';
import PageNotAvailable from '../components/Platform/PageNotAvailable';
import { useEditMode } from '../components/util/useEditMode';
import { useWarningModalControl } from '../store/hooks/useWarningModalControl';

const CompaniesInfoPage = () => {
    const [searchParams] = useSearchParams();
    const projectId = searchParams.get('projectId');
    const officeId = searchParams.get('officeId');

    const { t } = useTranslation(i18PAGES.COMPANIES_INFO);
    const { successToast, errorToast } = useToast();
    const { tabsMenuData } = useTabsMenu();

    const [isApplyForAll, setIsApplyForAll] = useState(false);

    const formikRef = useRef();

    const {
        isEditMode,
        isShowCloseEditAlertModal,
        openEditAlertModal,
        acceptOffEditMode,
        declineOffEditMode,
        setIsEditMode,
        setIsShowCloseEditAlertModal,
    } = useWarningModalControl(formikRef, () => {
        setIsApplyForAll(false);
    });

    useEditMode(isEditMode);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isNeedApplyForAllRefetch, setIsNeedApplyForAllRefetch] = useState(false);

    const {
        data: currentOffice,
        isCurrentOfficeLoading,
        refetch,
    } = useOfficeDetailInfoQuery(
        { officeId, projectId, isNeedRefetch: isNeedApplyForAllRefetch },
        {
            skip: !officeId || !projectId,
        }
    );
    const [updateOfficeInfo] = useUpdateOfficeDetailInfoMutation();

    const applyHandleChange = () => {
        setIsApplyForAll((prev) => !prev);
    };

    const onEditMode = () => {
        setIsEditMode(true);
    };

    const resultedOffice = useMemo(() => {
        if (currentOffice) {
            return {
                ...currentOffice,
                isGrowthStrategy: !!currentOffice?.marketGrowthStrategy,
                isTimingStrategy: !!currentOffice?.timingStrategy,
                isCompetitiveStrategy: !!currentOffice?.competitiveStrategy,
            };
        }
    }, [currentOffice]);

    if (!projectId) {
        return <PageNotAvailable />;
    }

    if (!projectId || isCurrentOfficeLoading) {
        return <LayoutLoader />;
    }

    return (
        <div className="pl-[50px] pb-[80px] pr-[25px] pt-[70px] min-h-[100vh] text-int-white-main">
            <ProjectInfoPageHeader
                nextLink="/as_is_applications"
                pageIdentifier={PAGE_IDENTIFIER.COMPANIES}
                projectId={Number(projectId)}
                pageName={t('Company information')}
            />

            <ProjectInfoTabSwitcher itemsArray={tabsMenuData?.COMPANIES} projectId={projectId} rootUrl="companies" />

            <Formik
                initialValues={resultedOffice}
                onSubmit={(values, { setSubmitting }) => {
                    const sendData = {
                        ...values,
                        marketGrowthStrategy: values.isGrowthStrategy ? values.marketGrowthStrategy : null,
                        timingStrategy: values.isTimingStrategy ? values.timingStrategy : null,
                        competitiveStrategy: values.isCompetitiveStrategy ? values.competitiveStrategy : null,
                        lineOrganizationType:
                            values.organizationStructure === OrganisationStructure.LINE_ORGANIZATION
                                ? values.lineOrganizationType
                                : null,
                        applyAll: isApplyForAll,
                    };

                    updateOfficeInfo({ projectId, officeId, body: sendData })
                        .unwrap()
                        .then(() => {
                            refetch()
                                .then(() => {
                                    if (isApplyForAll === true) {
                                        setIsNeedApplyForAllRefetch((prev) => !prev);
                                    }
                                    successToast(i18n.t('Office changes were successfully updated'));
                                    setIsEditMode(false);
                                })
                                .finally(() => setSubmitting(false));
                        })
                        .catch((err) => {
                            if (
                                err.data.message ===
                                'After sending a request to providers, you will not be able to edit company information.'
                            ) {
                                errorToast(
                                    t(
                                        'After sending a request to providers, you will not be able to edit company information.'
                                    )
                                );
                            } else {
                                errorToast(i18n.t('Something went wrong with office changes. Try again later'));
                            }
                            setSubmitting(false);
                        })
                        .finally(() => {
                            setSubmitting(false);
                        });
                }}
                validationSchema={companyInfoValidationSchema}
                enableReinitialize
                innerRef={formikRef}
            >
                {(formik) => (
                    <Form onSubmit={formik.handleSubmit}>
                        {resultedOffice && (
                            <CompaniesInfo
                                formik={formik}
                                resultedOffice={resultedOffice}
                                isSubmitted={isSubmitted}
                                isEditMode={isEditMode}
                                openEditAlertModal={openEditAlertModal}
                                isApplyForAll={isApplyForAll}
                                applyHandleChange={applyHandleChange}
                                onEditMode={onEditMode}
                                setIsSubmitted={setIsSubmitted}
                                companies={tabsMenuData?.COMPANIES}
                            />
                        )}
                    </Form>
                )}
            </Formik>
            <WarningModal
                isOpen={isShowCloseEditAlertModal}
                setIsOpen={setIsShowCloseEditAlertModal}
                warningText={t('Are you sure you want to close editing mode? Your result will not be saved')}
                acceptButtonText={t('Close')}
                declineButtonText={t('Cancel')}
                accept={acceptOffEditMode}
                decline={declineOffEditMode}
            />
        </div>
    );
};

export default CompaniesInfoPage;
