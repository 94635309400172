import React from 'react';
import { string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { i18PAGES } from '../../../../config/i18n/constants';
import { ReactComponent as WaitingResponseIcon } from '../../../../assets/icons/waiting-for-response.svg';
import { ReactComponent as DeadLineExpiredIcon } from '../../../../assets/icons/deadline_expired.svg';
import { ReactComponent as SavedResultIcon } from '../../../../assets/icons/saved_result.svg';
import { ReactComponent as RejectedIcon } from '../../../../assets/icons/rejected.svg';
import { ReactComponent as RejectedByProviderIcon } from '../../../../assets/icons/rejected-by-provider.svg';
import { BUILDING_BLOCKS_STATUSES } from '../constants';

const BbStatusItem = (props) => {
    const { t } = useTranslation(i18PAGES.PROVIDER_SELECTION);
    const { status } = props;
    switch (status) {
        case BUILDING_BLOCKS_STATUSES.WAITING_FOR_RESPONSE:
            return (
                <div className="flex items-center ml-[22px]">
                    <WaitingResponseIcon />
                    <div className="ml-[12px] font-Lexend400 text-[16px] leading-[24px]">
                        {t('Waiting for response')}
                    </div>
                </div>
            );
        case BUILDING_BLOCKS_STATUSES.DEADLINE_EXPIRED:
            return (
                <div className="flex items-center ml-[22px]">
                    <DeadLineExpiredIcon />
                    <div className="ml-[12px] font-Lexend400 text-[16px] leading-[24px]">{t('Deadline expired')}</div>
                </div>
            );
        case BUILDING_BLOCKS_STATUSES.SAVED_RESULT:
            return (
                <div className="flex items-center ml-[22px]">
                    <SavedResultIcon />
                    <div className="ml-[12px] font-Lexend400 text-[16px] leading-[24px]">{t('Result is saved')}</div>
                </div>
            );
        case BUILDING_BLOCKS_STATUSES.REJECTED:
            return (
                <div className="flex items-center ml-[22px]">
                    <RejectedIcon />
                    <div className="ml-[12px] font-Lexend400 text-[16px] leading-[24px] text-int-white-secondary">
                        {t('Rejected')}
                    </div>
                </div>
            );
        case BUILDING_BLOCKS_STATUSES.REJECTED_BY_PROVIDER:
            return (
                <div className="flex items-center ml-[22px]">
                    <RejectedByProviderIcon />
                    <div className="ml-[12px] font-Lexend400 text-[16px] leading-[24px]">
                        {t('Rejected by provider')}
                    </div>
                </div>
            );
        default:
            return <></>;
    }
};

export default BbStatusItem;

BbStatusItem.propTypes = {
    status: string.isRequired,
};
