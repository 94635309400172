import React from 'react';
import { useLocation } from 'react-router-dom';
import { func, object, bool } from 'prop-types';
import { MENU_ICON } from './menuIconConst';
import { ReactComponent as ArrowUpIcon } from '../../assets/icons/up-arrow.svg';
import { ReactComponent as ArrowBottomIcon } from '../../assets/icons/bottom-arrow.svg';
import { useShowMenu } from '../../store/hooks/useShowMenu';

const ProjectInfoMenuItemCategory = ({ menuItem, handleClickMenu, toggleOpenMenu, isOpenMenu }) => {
    const location = useLocation();
    const { showFullMenu } = useShowMenu();

    return (
        <div
            className={`flex items-center ${
                showFullMenu ? 'justify-between' : 'justify-center'
            } cursor-pointer mx-[10px] rounded-lg px-[12px] my-[2px]
                 h-[48px] text-[16px] leading-[16px] font-Lexend400 text-int-white-main hover:bg-[#2A323E]  ${
                     location.pathname.includes(menuItem.pageEnum.enumName.toLowerCase()) && 'bg-[#2A323E]'
                 }`}
            onClick={() => handleClickMenu(menuItem.pageEnum.enumName.toLowerCase(), menuItem.data, menuItem.dynamic)}
        >
            <div className="flex items-center justify-center">
                <div className={`flex items-center justify-center h-[18px] w-[18px] ${showFullMenu && 'mr-[10px]'} `}>
                    {MENU_ICON?.find((item) => item?.enumName === menuItem?.pageEnum?.enumName)?.icon || ''}
                </div>
                {showFullMenu && <div className="cursor-pointer">{menuItem.pageEnum.value}</div>}
            </div>

            {menuItem?.data?.length > 0 && showFullMenu && (
                <div
                    className={`flex items-center justify-center 
                        min-w-[24px] w-[24px] h-[24px] cursor-pointer rounded-[4px] 
                        ${
                            location.pathname.includes(menuItem.pageEnum.enumName.toLowerCase())
                                ? 'border border-int-white-secondary'
                                : 'bg-int-gray-secondary'
                        }`}
                    onClick={toggleOpenMenu}
                >
                    {isOpenMenu ? <ArrowUpIcon fill="#BDBDBD" /> : <ArrowBottomIcon fill="#BDBDBD" />}
                </div>
            )}
        </div>
    );
};

export default ProjectInfoMenuItemCategory;

ProjectInfoMenuItemCategory.propTypes = {
    menuItem: object.isRequired,
    handleClickMenu: func.isRequired,
    toggleOpenMenu: func.isRequired,
    isOpenMenu: bool.isRequired,
};
