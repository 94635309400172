import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from '../../style/Button/Button';
import { i18PAGES } from '../../config/i18n/constants';
import { USER_ROLES } from '../util/constants';
import { useAuth } from '../../store/hooks/useAuth';

const ProjectListHeader = () => {
    const { t } = useTranslation(i18PAGES.PROJECT_LIST);
    const { profileData } = useAuth();
    const isAdmin = profileData?.userRoles?.includes(USER_ROLES.ROLE_ADMIN);

    return (
        <div className="w-[1019px]">
            <div className="flex items-center justify-between">
                <div className="text-int-white-main font-Lexend500 text-[24px] leading-[24px]">
                    {t('List of projects')}
                </div>
                {isAdmin && (
                    <Link to="/create-project">
                        <PrimaryButton className="w-[230px] h-[42px] font-Lexend400" isPink>
                            {t('Create new project')}
                        </PrimaryButton>
                    </Link>
                )}
            </div>
        </div>
    );
};

export default ProjectListHeader;
