import React from 'react';
import { bool, element, func, number, oneOfType, string } from 'prop-types';

const RadioInput = ({ name, value, setFieldValue, isChecked, label, disabled = false, handleSubmit, setOpen }) => {
    const handleClick = () => {
        setFieldValue(name, value);
        if (handleSubmit) {
            handleSubmit();
        }
        if (setOpen) {
            setOpen(false);
        }
    };

    return (
        <div
            className={`flex items-center ${disabled ? 'opacity-30 cursor-default' : 'cursor-pointer'} `}
            onClick={disabled ? null : () => handleClick()}
        >
            <input
                className={`w-[18px] h-[18px] min-w-[18px] min-h-[18px] rounded-[30px] ${
                    isChecked
                        ? 'border-[2px] bg-int-grapefruit border-int-white-main'
                        : 'border border-int-white-secondary'
                }`}
                type="radio"
                name="test"
                value="LINE_ORGANIZATION"
                disabled={disabled}
            />
            <div className="ml-[12px] text-[14px] leading-[14px] font-Lexend400">{label}</div>
        </div>
    );
};

export default RadioInput;

RadioInput.propTypes = {
    name: string.isRequired,
    value: oneOfType([string, bool, number]).isRequired,
    label: oneOfType([string, element]).isRequired,
    setFieldValue: func.isRequired,
    isChecked: bool,
    disabled: bool,
    handleSubmit: func,
    setOpen: func,
};
