import { bool, func, node, number } from 'prop-types';
import { ReactComponent as PaginationLeftIcon } from '../../assets/icons/paginationLeftIcon.svg';
import { ReactComponent as PaginationRightIcon } from '../../assets/icons/paginationRightIcon.svg';
import { ReactComponent as DotsPaginationIcon } from '../../assets/icons/dotsPaginationIcon.svg';

const Pagination = ({ count = 0, pageSize, activePage, setActivePage }) => {
    const TOTAL_PAGE = Math.ceil(count / pageSize);

    if (TOTAL_PAGE > 1)
        return (
            <div className="flex items-center">
                <div
                    className={`w-[36px] h-[28px] mx-[2px] flex justify-center items-center text-[18px] font-Lexend400 text-int-white-main border rounded  ${
                        activePage === 1
                            ? 'border-int-white-secondary'
                            : 'border-int-white-main cursor-pointer hover:border-int-grapefruit'
                    }`}
                    onClick={() => {
                        if (activePage === 1) {
                            return false;
                        }
                        return setActivePage((prev) => prev - 1);
                    }}
                    aria-hidden="true"
                >
                    <PaginationLeftIcon fill={activePage === 1 ? '#9BA1B2' : '#EDF1FD'} />
                </div>
                {/* eslint-disable-next-line no-nested-ternary */}
                {TOTAL_PAGE < 7 ? (
                    <>
                        {Array.apply(0, Array(TOTAL_PAGE)).map((arr, i) => (
                            <PaginationItem
                                key={i}
                                handleClick={() => setActivePage(i + 1)}
                                isActive={activePage === i + 1}
                            >
                                {i + 1}
                            </PaginationItem>
                        ))}
                    </>
                ) : // eslint-disable-next-line no-nested-ternary
                activePage % 5 >= 0 && activePage > 4 && activePage + 2 < TOTAL_PAGE ? (
                    <>
                        <PaginationItem handleClick={() => setActivePage(1)} isActive={activePage === 1}>
                            {1}
                        </PaginationItem>
                        <div className=" w-[36px] h-[28px] flex items-end justify-center items-center">
                            <DotsPaginationIcon fill="#D1D5DB" />
                        </div>
                        <PaginationItem handleClick={() => setActivePage(activePage - 1)} isActive={false}>
                            {activePage - 1}
                        </PaginationItem>
                        <PaginationItem handleClick={() => setActivePage(activePage)} isActive>
                            {activePage}
                        </PaginationItem>
                        <PaginationItem handleClick={() => setActivePage(activePage + 1)} isActive={false}>
                            {activePage + 1}
                        </PaginationItem>
                        <div className=" w-[36px] h-[28px] flex items-end justify-center items-center">
                            <DotsPaginationIcon fill="#D1D5DB" />
                        </div>
                        <PaginationItem
                            handleClick={() => setActivePage(TOTAL_PAGE)}
                            isActive={activePage === TOTAL_PAGE}
                        >
                            {TOTAL_PAGE}
                        </PaginationItem>
                    </>
                ) : activePage % 5 >= 0 && activePage > 4 && activePage + 2 >= TOTAL_PAGE ? (
                    <>
                        <PaginationItem handleClick={() => setActivePage(1)} isActive={activePage === 1}>
                            {1}
                        </PaginationItem>
                        <div className=" w-[36px] h-[28px] flex items-end justify-center items-center">
                            <DotsPaginationIcon fill="#D1D5DB" />
                        </div>
                        <PaginationItem
                            handleClick={() => setActivePage(TOTAL_PAGE - 3)}
                            isActive={activePage === TOTAL_PAGE - 3}
                        >
                            {TOTAL_PAGE - 3}
                        </PaginationItem>
                        <PaginationItem
                            handleClick={() => setActivePage(TOTAL_PAGE - 2)}
                            isActive={activePage === TOTAL_PAGE - 2}
                        >
                            {TOTAL_PAGE - 2}
                        </PaginationItem>
                        <PaginationItem
                            handleClick={() => setActivePage(TOTAL_PAGE - 1)}
                            isActive={activePage === TOTAL_PAGE - 1}
                        >
                            {TOTAL_PAGE - 1}
                        </PaginationItem>
                        <PaginationItem
                            handleClick={() => setActivePage(TOTAL_PAGE)}
                            isActive={activePage === TOTAL_PAGE}
                        >
                            {TOTAL_PAGE}
                        </PaginationItem>
                    </>
                ) : (
                    <>
                        {Array.apply(0, Array(5)).map((arr, i) => (
                            <PaginationItem
                                key={i}
                                handleClick={() => setActivePage(i + 1)}
                                isActive={activePage === i + 1}
                            >
                                {i + 1}
                            </PaginationItem>
                        ))}
                        <div className=" w-[36px] h-[28px] flex items-end justify-center items-center">
                            <DotsPaginationIcon fill="#D1D5DB" />
                        </div>
                        <PaginationItem
                            handleClick={() => setActivePage(TOTAL_PAGE)}
                            isActive={activePage === TOTAL_PAGE}
                        >
                            {TOTAL_PAGE}
                        </PaginationItem>
                    </>
                )}
                <div
                    className={`w-[36px] h-[28px] mx-[2px] flex justify-center items-center text-[18px] font-Lexend400 text-int-white-main border rounded  ${
                        activePage === TOTAL_PAGE
                            ? 'border-int-white-secondary'
                            : 'border-int-white-main cursor-pointer hover:border-int-grapefruit'
                    }`}
                    onClick={() => {
                        if (activePage === TOTAL_PAGE) {
                            return false;
                        }
                        return setActivePage((prev) => prev + 1);
                    }}
                    aria-hidden="true"
                >
                    <PaginationRightIcon fill={activePage === TOTAL_PAGE ? '#9BA1B2' : '#EDF1FD'} />
                </div>
            </div>
        );
};

Pagination.propTypes = {
    count: number,
    pageSize: number.isRequired,
    activePage: number.isRequired,
    setActivePage: func.isRequired,
};

export default Pagination;

const PaginationItem = ({ handleClick, isActive, children }) => (
    <div
        onClick={handleClick}
        className={`w-[36px] h-[28px] mx-[2px] flex justify-center items-center text-[18px] font-Lexend400 text-int-white-main border cursor-pointer rounded hover:border-int-grapefruit ${
            isActive ? 'border-int-grapefruit' : 'border-int-white-main'
        }`}
        aria-hidden="true"
    >
        {children}
    </div>
);

PaginationItem.propTypes = {
    handleClick: func.isRequired,
    isActive: bool.isRequired,
    children: node.isRequired,
};
