import { element, func, string } from 'prop-types';
import { PrimaryButton } from '../../style/Button/Button';

const AddEntityBlock = ({ icon, description, buttonText, handleClick }) => (
    <div className="p-[30px] rounded-lg bg-int-gray-main flex flex-col items-center">
        {icon}
        <div className="text-[16px] mt-[26px] mb-[24px]">{description}</div>
        {buttonText && (
            <PrimaryButton isPink className="px-[22px] h-[42px]" handleClick={handleClick}>
                <div className="font-Lexend400 text-[14px]">{buttonText}</div>
            </PrimaryButton>
        )}
    </div>
);

AddEntityBlock.propTypes = {
    icon: element.isRequired,
    description: string.isRequired,
    buttonText: string,
    handleClick: func,
};

export default AddEntityBlock;
