import React from 'react';
import { arrayOf, bool, number, shape, string } from 'prop-types';
import DropdownItem from '../DropdownItem/DropdownItem';
import { useSort } from '../../hooks/useSort';
import { ReactComponent as SortingIcon } from '../../assets/icons/sortingIcon.svg';
import { ReactComponent as ArrowDownSortIcon } from '../../assets/icons/arrow-down-sort.svg';
import { ReactComponent as ArrowUpSortIcon } from '../../assets/icons/arrow-up-sort.svg';

const TableHeader = (props) => {
    const { sortByFromUrl, orderFromUrl, getMenuOptions } = useSort();

    const { data, isReadMode } = props;
    return (
        <thead className="bg-int-gray-main">
            <tr className="text-left h-[60px] text-[14px] font-Lexend400 uppercase text-[#CDD1DC]">
                {data.map((row, index) => {
                    if (isReadMode && row.isSortable) {
                        return (
                            <DropdownItem
                                as="th"
                                isFull
                                offsetValue={1}
                                className="w-[100%] px-[10px] border-r-[1px] border-int-main-black cursor-pointer h-[64px]"
                                menuOptions={getMenuOptions(row.queryName, row.isDateSorting)}
                                isIcon={false}
                                style={{
                                    minWidth: row.minWidth,
                                }}
                                key={index}
                            >
                                <div className="w-[100%]">
                                    <div className="inline-flex w-[100%] items-center justify-between">
                                        <div className="mr-[5px]">{row.name}</div>
                                        <div className="flex items-center gap-[6px] text-int-white-main text-[12px] leading-[12px] font-Lexend400">
                                            {row.isDateSorting ? (
                                                <>
                                                    {sortByFromUrl === row.queryName && (
                                                        <div>
                                                            {orderFromUrl === 'DESC' ? (
                                                                <ArrowDownSortIcon
                                                                    stroke="#EDF1FD"
                                                                    width={24}
                                                                    height={24}
                                                                />
                                                            ) : (
                                                                <ArrowUpSortIcon
                                                                    stroke="#EDF1FD"
                                                                    width={20}
                                                                    height={22}
                                                                />
                                                            )}
                                                        </div>
                                                    )}
                                                </>
                                            ) : (
                                                <>
                                                    {sortByFromUrl === row.queryName && (
                                                        <div>{orderFromUrl === 'DESC' ? 'Z-A' : 'A-Z'}</div>
                                                    )}
                                                </>
                                            )}
                                            <SortingIcon width={16} height={17} fill="#EDF1FD" />
                                        </div>
                                    </div>
                                </div>
                            </DropdownItem>
                        );
                    }

                    return (
                        <th
                            className="px-[10px] border-r-[1px] border-int-main-black"
                            key={index}
                            style={{
                                minWidth: row.minWidth,
                            }}
                        >
                            {row.name}
                        </th>
                    );
                })}
            </tr>
        </thead>
    );
};

export default TableHeader;

TableHeader.propTypes = {
    data: arrayOf(
        shape({
            minWidth: number,
            name: string,
            isSortable: bool,
            queryName: string,
            isDateSorting: bool,
        })
    ),
    isReadMode: bool,
};
