import React from 'react';
import { useTranslation } from 'react-i18next';
import { i18PAGES } from '../../config/i18n/constants';

const ProjectsManagementHeader = () => {
    const { t } = useTranslation(i18PAGES.PROJECTS_MANAGEMENT);

    return (
        <div className="w-[1019px] h-[42px] flex items-center">
            <div className="text-int-white-main font-Lexend500 text-[24px] leading-[24px]">
                {t('List of Project Managers')}
            </div>
        </div>
    );
};

export default ProjectsManagementHeader;
