import React from 'react';
import { func } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from '../../style/Button/Button';
import { i18PAGES } from '../../config/i18n/constants';

const HRGetConsultationBlock = ({ handleClick }) => {
    const { t } = useTranslation(i18PAGES.HR_TOOLS);

    return (
        <div className="flex justify-center items-center bg-int-gray-main rounded-lg py-[40px] mt-[40px] min-w-[1140px]">
            <div className="text-[20px] font-Lexend400 text-int-white-main">{t('Text of the CTA?')}</div>
            <PrimaryButton isPink className="px-[32px] ml-[40px]" handleClick={handleClick}>
                {t('Get full consultation')}
            </PrimaryButton>
        </div>
    );
};

export default HRGetConsultationBlock;

HRGetConsultationBlock.propTypes = {
    handleClick: func,
};
