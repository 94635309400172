import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { HEADERS } from '../../components/util/constants';

const COMMENTS_PAGE_SIZE = 10;
export const commentsAPI = createApi({
    reducerPath: 'commentsAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api',
        prepareHeaders: HEADERS,
    }),
    tagTypes: ['comments'],
    endpoints: (build) => ({
        getComments: build.query({
            query: ({ pageIdentifier, projectId, pageNumber, subPageIdentifier }) =>
                `/comments/pages/${pageIdentifier}?projectId=${projectId}${
                    subPageIdentifier ? `&subPageIdentifier=${subPageIdentifier}` : ''
                }&page=${pageNumber}&size=${COMMENTS_PAGE_SIZE}&sort=createdDate,DESC`,
            providesTags: (result) =>
                result?.comments?.content
                    ? [
                          ...result.comments.content.map(({ id }) => ({ type: 'comments', id })),
                          { type: 'comments', id: 'LIST' },
                      ]
                    : [{ type: 'comments', id: 'LIST' }],
        }),
        sendComment: build.mutation({
            query: (body) => ({
                url: '/comments',
                method: 'POST',
                body,
            }),
            invalidatesTags: [{ type: 'comments', id: 'LIST' }],
        }),
        setCommentRead: build.mutation({
            query: (commentId) => ({
                url: `/comments/${commentId}/read`,
                method: 'PUT',
            }),
            invalidatesTags: [{ type: 'comments', id: 'LIST' }],
        }),
    }),
});

export const { useGetCommentsQuery, useSendCommentMutation, useSetCommentReadMutation } = commentsAPI;
