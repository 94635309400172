import React from 'react';
import { object, func } from 'prop-types';
import { useLocation } from 'react-router-dom';

const ProjectInfoMenuStaticChildrenItem = ({ itemChildren, handleClickStaticSubMenu }) => {
    const location = useLocation();

    return (
        <div className="text-[14px] leading-[16px] font-Lexend400 text-int-white-secondary px-[50px] py-[8px]">
            <div
                className={`inline-block cursor-pointer hover:text-int-white-main ${
                    location.search.includes(encodeURIComponent(itemChildren.enumName.toLowerCase())) &&
                    'text-int-white-main'
                }`}
                onClick={() => handleClickStaticSubMenu()}
            >
                {itemChildren.value}
            </div>
        </div>
    );
};

export default ProjectInfoMenuStaticChildrenItem;

ProjectInfoMenuStaticChildrenItem.propTypes = {
    itemChildren: object.isRequired,
    handleClickStaticSubMenu: func.isRequired,
};
