import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { object, ref, string } from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import FInput from '../style/Input/Input';
import { PrimaryButton } from '../style/Button/Button';
import FormLayout from '../layouts/FormLayout';
import { LayoutLoader } from '../components/Preloader/Preloader';
import { REGEX } from '../components/util/constants';
import http from '../components/util/http';
import { i18PAGES } from '../config/i18n/constants';
import { useAuth } from '../store/hooks/useAuth';

const validationSchema = object({
    password: string()
        .required('Password should be stated')
        .matches(
            REGEX.PASSWORD,
            'Password should contain minimum 8 characters, at least 1 lower case letter and at least 1 higher case letter'
        ),
    password_confirmation: string()
        .required('Passwords do not match')
        .oneOf([ref('password'), null], 'Passwords do not match'),
});

const PasswordResetPage = () => {
    const { token } = useParams();
    const [isTokenValid, setIsTokenValid] = useState(false);
    const navigate = useNavigate();
    const { profileData } = useAuth();

    const { t } = useTranslation(i18PAGES.AUTH);

    useEffect(() => {
        http.post('/verify', {
            token,
        })
            .then((res) => {
                setIsTokenValid(true);
            })
            .catch((err) => {
                navigate('/confirmation/expired-password-token');
            });
    }, []);

    if (isTokenValid) {
        return (
            <div className="min-h-[calc(100vh-300px)] h-[100%] flex items-center justify-center">
                <FormLayout
                    subText="Bitte geben Sie die Ihrem Konto zugeordnete E-Mail-Adresse ein, um Anweisungen zum Zurücksetzen des Passworts zu erhalten"
                    mainText="Erstelle Ein Neues Passwort"
                    width={500}
                >
                    <Formik
                        onSubmit={(values, { setSubmitting }) => {
                            http.post('/account/reset-password/finish', {
                                newPassword: values.password,
                                key: token,
                            })
                                .then((res) => {
                                    if (profileData === null) {
                                        localStorage.setItem('isPasswordChanged', 'true');
                                    }
                                    navigate('/login');
                                })
                                .catch((err) => {
                                    navigate('/confirmation/expired-password-token');
                                })
                                .finally(() => {
                                    setSubmitting(false);
                                });
                        }}
                        validationSchema={validationSchema}
                        initialValues={{
                            password: '',
                            password_confirmation: '',
                        }}
                    >
                        {({
                            values,
                            touched,
                            errors,
                            isSubmitting,
                            handleSubmit,
                            handleBlur,
                            handleChange,
                            setFieldValue,
                        }) => (
                            <Form onSubmit={handleSubmit}>
                                <FInput
                                    name="password"
                                    placeholder="Passwort eingeben"
                                    value={values.password}
                                    handleBlur={handleBlur}
                                    handleChange={handleChange}
                                    className="w-[440px] mt-[40px]"
                                    type="password"
                                    label="Passwort"
                                    isError={!!(touched.password && errors.password)}
                                    errorText={errors.password}
                                    isPassword
                                    setFieldValue={setFieldValue}
                                />

                                <FInput
                                    name="password_confirmation"
                                    placeholder="Passwort wiederholen"
                                    value={values.password_confirmation}
                                    handleBlur={handleBlur}
                                    handleChange={handleChange}
                                    className="w-[440px] mt-[40px]"
                                    type="password"
                                    label="Passwort wiederholen"
                                    isError={!!(touched.password_confirmation && errors.password_confirmation)}
                                    errorText={errors.password_confirmation}
                                    isPassword
                                    setFieldValue={setFieldValue}
                                />

                                <PrimaryButton
                                    className="h-[42px] w-[100%] mt-[50px]"
                                    type="submit"
                                    isPink
                                    disabled={isSubmitting}
                                >
                                    {t('Ein neues Passwort anfordern')}
                                </PrimaryButton>
                            </Form>
                        )}
                    </Formik>
                </FormLayout>
            </div>
        );
    }

    return <LayoutLoader />;
};
export default PasswordResetPage;
