import React from 'react';
import { useTranslation } from 'react-i18next';
import { bool } from 'prop-types';
import { i18PAGES } from '../../../config/i18n/constants';
import TableHeaderSortItem from '../../../style/Table/TableHeaderSortItem';

const WorkersListTableHeader = ({ isRoleReviewer }) => {
    const { t } = useTranslation(i18PAGES.WORKERS_EFFICIENCY);

    return (
        <div className="bg-[#2A3143] h-[35px] flex items-center mt-[40px] text-[14px] font-Lexend500 leading-[21px] text-int-white-secondary">
            <TableHeaderSortItem textStyles="ml-[12px]" className="min-w-[300px]" urlName="fullName">
                {t('Full name')}
            </TableHeaderSortItem>
            <TableHeaderSortItem textStyles="ml-[120px]" className="min-w-[300px]" urlName="email">
                {t('Email')}
            </TableHeaderSortItem>
            <TableHeaderSortItem textStyles="ml-[125px]" className="min-w-[300px]" urlName="role">
                {t('Role')}
            </TableHeaderSortItem>
            <TableHeaderSortItem
                className={isRoleReviewer ? 'min-w-[460px]' : 'min-w-[300px]'}
                textStyles={isRoleReviewer ? 'ml-[205px]' : 'ml-[110px]'}
                urlName="status"
            >
                {t('Status')}
            </TableHeaderSortItem>
            {!isRoleReviewer && <div className="w-[100%] text-right px-[20px]">{t('Actions')}</div>}
        </div>
    );
};

export default WorkersListTableHeader;

WorkersListTableHeader.propTypes = {
    isRoleReviewer: bool,
};
