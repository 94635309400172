import React from 'react';
import { useTranslation } from 'react-i18next';
import { i18PAGES } from '../../../config/i18n/constants';

const RecentSubscriptionsHeader = () => {
    const { t } = useTranslation(i18PAGES.SUBSCRIPTION_MANAGEMENT);
    return (
        <div className="flex items-center h-[35px] bg-[#2A3143] text-[14px] leading-[18px] font-Lexend500 text-int-white-secondary mb-[10px]">
            <div className="w-[260px] px-[11px]">{t('Module name')}</div>
            <div className="w-[160px] px-[11px]">{t('Start date')}</div>
            <div className="w-[350px] px-[11px]">{t('End date')}</div>
            <div className="w-[190px] px-[11px] text-center">{t('Action')}</div>
        </div>
    );
};

export default RecentSubscriptionsHeader;
