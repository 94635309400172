import React from 'react';
import './Preloader.css';

const Preloader = () => <div className="spin" />;

export default Preloader;

export const LayoutLoader = () => (
    <div className="min-h-[calc(100vh-300px)] h-[100%] flex items-center justify-center">
        <Preloader />
    </div>
);
export const PageLoader = () => (
    <div className="h-[100vh] w-[100vw] bg-int-main-black flex items-center justify-center">
        <Preloader />
    </div>
);
