import React, { useEffect, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { object, ref, string } from 'yup';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ArrowLeft } from '../assets/icons/arrow-left.svg';
import FInput from '../style/Input/Input';
import { countryOptions, REGEX } from '../components/util/constants';
import SearchDropDown from '../style/SearchDropDown/SearchDropDown';
import { PrimaryButton } from '../style/Button/Button';
import http from '../components/util/http';
import { i18PAGES } from '../config/i18n/constants';
import { useAuth } from '../store/hooks/useAuth';
import WarningModal from '../style/WarningModal/WarningModal';

const validationSchema = object({
    fullName: string().required('Full name should be stated').max(50, 'Max length is 50 symbols'),
    company_name: string().required('Company name should be stated').max(70, 'Max length is 70 symbols'),
    company_address: string().required('Company address should be stated').max(50, 'Max length is 50 symbols'),
    country: string().required('Country should be selected'),
    city: string()
        .required('City should be stated')
        .matches(REGEX.STRING_ONLY, 'Invalid city name')
        .max(50, 'Max length is 50 symbols'),
    zip_code: string()
        .required('ZIP should be stated')
        .matches(REGEX.NUMBERS_ONLY, 'Invalid ZIP code')
        .min(5, 'Invalid ZIP code')
        .max(7, 'Invalid ZIP code'),
    phone: string()
        .required('Phone number should be stated')
        .matches(REGEX.PHONE, 'Invalid phone number')
        .min(6, 'Invalid phone number'),
    email: string().required('Email should be stated').email('Invalid email'),
    password: string()
        .required('Password should be stated')
        .matches(
            REGEX.PASSWORD,
            'Password should contain minimum 8 characters, at least 1 lower case letter and at least 1 higher case letter'
        ),
    password_confirmation: string()
        .required('Passwords do not match')
        .oneOf([ref('password'), null], 'Passwords do not match'),
});
const RegistrationPage = () => {
    const { t } = useTranslation(i18PAGES.AUTH);
    const { isAuth } = useAuth();
    const location = useLocation();
    const navigate = useNavigate();
    const [isOpenModalConfirmPressBack, setIsOpenModalConfirmPressBack] = useState(false);

    const modules = location?.state?.modules;

    useEffect(() => {
        if (!modules) {
            navigate('/register-modules');
        }
    }, [modules]);

    const navigateBack = () => {
        navigate(-1);
    };

    if (isAuth) {
        return <Navigate to="/" state={{ from: location }} replace />;
    }

    return (
        <div className="min-h-[calc(100vh-300px)] h-[100%] flex items-center justify-center">
            <div className="pt-[100px] pb-[200px]">
                <Formik
                    onSubmit={(values, { setErrors, setSubmitting }) => {
                        http.post('/registration', {
                            companyName: values.company_name,
                            fullName: values.fullName,
                            companyAddress: values.company_address,
                            city: values.city,
                            country: values.country,
                            phone: values.phone,
                            email: values.email,
                            zipCode: values.zip_code,
                            password: values.password,
                            passwordRepeat: values.password_confirmation,
                            langKey: 'EN',
                            modules,
                        })
                            .then(() => {
                                navigate('/email-sent/register', { state: { email: values.email } });
                                localStorage.setItem('startResendRegistrationEmailTime', 'true');
                            })
                            .catch((err) => {
                                if (err.data.message === 'Email is already in use!') {
                                    setErrors({ email: 'Email is already in use!' });
                                } else {
                                    setErrors({ email: 'Check data and try again' });
                                }
                            })
                            .finally(() => {
                                setSubmitting(false);
                            });
                    }}
                    validationSchema={validationSchema}
                    initialValues={{
                        fullName: '',
                        company_name: '',
                        company_address: '',
                        country: '',
                        city: '',
                        zip_code: '',
                        phone: '',
                        email: '',
                        password: '',
                        password_confirmation: '',
                    }}
                >
                    {({
                        values,
                        touched,
                        errors,
                        setFieldValue,
                        isSubmitting,
                        handleSubmit,
                        handleBlur,
                        handleChange,
                    }) => (
                        <Form onSubmit={handleSubmit}>
                            <div className="bg-int-gray-main w-[520px] m-auto p-[30px] relative rounded-[20px]">
                                <div
                                    className="absolute left-[-111px] top-[-30px] text-[18px] font-Lexend400 flex items-center text-int-white-main cursor-pointer"
                                    onClick={() => {
                                        if (
                                            values.fullName === '' &&
                                            values.company_name === '' &&
                                            values.company_address === '' &&
                                            values.country === '' &&
                                            values.city === '' &&
                                            values.zip_code === '' &&
                                            values.phone === '' &&
                                            values.email === '' &&
                                            values.password === '' &&
                                            values.password_confirmation === ''
                                        ) {
                                            navigateBack();
                                        } else {
                                            setIsOpenModalConfirmPressBack(true);
                                        }
                                    }}
                                >
                                    <ArrowLeft fill="#EDF1FD" width={9} height={16} />
                                    <div className="ml-[17px]"> {t('Back')}</div>
                                </div>

                                <div>
                                    <div className="text-int-white-main font-Lexend500 text-[24px] leading-[24px] mb-[15px]">
                                        {t('Account Erstellen')}
                                    </div>
                                    <div className="text-int-white-secondary leading-[22px] text-[14px] mb-[30px]">
                                        {t('Persönliche Daten eingeben, um einen Account bei uns zu erstellen.')}
                                    </div>
                                    <FInput
                                        name="fullName"
                                        placeholder="Full name"
                                        value={values.fullName}
                                        handleBlur={handleBlur}
                                        handleChange={handleChange}
                                        className="w-[460px]"
                                        type="text"
                                        label="Full name"
                                        isError={!!(touched.fullName && errors.fullName)}
                                        errorText={errors.fullName}
                                        setFieldValue={setFieldValue}
                                        limit={50}
                                    />

                                    <FInput
                                        name="company_name"
                                        placeholder="Company name"
                                        value={values.company_name}
                                        handleBlur={handleBlur}
                                        handleChange={handleChange}
                                        className="w-[460px] mt-[40px]"
                                        type="text"
                                        label="Company name"
                                        isError={!!(touched.company_name && errors.company_name)}
                                        errorText={errors.company_name}
                                        setFieldValue={setFieldValue}
                                        limit={70}
                                    />
                                    <FInput
                                        name="company_address"
                                        placeholder="Address"
                                        value={values.company_address}
                                        handleBlur={handleBlur}
                                        handleChange={handleChange}
                                        className="w-[460px] mt-[40px]"
                                        type="text"
                                        label="Company Address"
                                        isError={!!(touched.company_address && errors.company_address)}
                                        errorText={errors.company_address}
                                        setFieldValue={setFieldValue}
                                        limit={50}
                                    />
                                    <SearchDropDown
                                        name="country"
                                        placeholder="Select"
                                        isError={!!(touched.country && errors.country)}
                                        errorText={errors.country}
                                        className="w-[460px] mt-[40px]"
                                        options={countryOptions}
                                        label="Country"
                                        handleChange={(country) => setFieldValue('country', country.value)}
                                        handleBlur={handleBlur}
                                    />
                                    <div className="flex mt-[40px] justify-between">
                                        <FInput
                                            name="city"
                                            placeholder="City"
                                            value={values.city}
                                            handleBlur={handleBlur}
                                            handleChange={handleChange}
                                            className="w-[220px]"
                                            type="text"
                                            label="City"
                                            isError={!!(touched.city && errors.city)}
                                            errorText={errors.city}
                                            setFieldValue={setFieldValue}
                                            limit={50}
                                        />

                                        <FInput
                                            name="zip_code"
                                            placeholder="Zip Code"
                                            value={values.zip_code}
                                            handleBlur={handleBlur}
                                            handleChange={handleChange}
                                            className="w-[217px]"
                                            type="text"
                                            label="Postal/Zip code"
                                            isError={!!(touched.zip_code && errors.zip_code)}
                                            errorText={errors.zip_code}
                                            setFieldValue={setFieldValue}
                                            limit={7}
                                        />
                                    </div>
                                    <FInput
                                        name="phone"
                                        placeholder="+389"
                                        value={values.phone}
                                        handleBlur={handleBlur}
                                        handleChange={handleChange}
                                        className="w-[460px] mt-[40px]"
                                        type="text"
                                        label="Telefonnummer"
                                        isError={!!(touched.phone && errors.phone)}
                                        errorText={errors.phone}
                                        setFieldValue={setFieldValue}
                                    />
                                    <FInput
                                        name="email"
                                        placeholder="beispiel@email.com "
                                        value={values.email}
                                        handleBlur={handleBlur}
                                        handleChange={handleChange}
                                        className="w-[460px] mt-[40px]"
                                        type="text"
                                        label="Email Adresse"
                                        isError={!!(touched.email && errors.email)}
                                        errorText={errors.email}
                                        autoComplete="new-password"
                                        setFieldValue={setFieldValue}
                                    />

                                    <FInput
                                        name="password"
                                        placeholder="Passwort eingeben"
                                        value={values.password}
                                        handleBlur={handleBlur}
                                        handleChange={handleChange}
                                        className="w-[460px] mt-[40px]"
                                        type="password"
                                        label="Passwort"
                                        isError={!!(touched.password && errors.password)}
                                        errorText={errors.password}
                                        isPassword
                                        isNoAutoComplete
                                        setFieldValue={setFieldValue}
                                    />
                                    <FInput
                                        name="password_confirmation"
                                        placeholder="Passwort wiederholen"
                                        value={values.password_confirmation}
                                        handleBlur={handleBlur}
                                        handleChange={handleChange}
                                        className="w-[460px] mt-[40px]"
                                        type="password"
                                        label="Passwort wiederholen"
                                        isError={!!(touched.password_confirmation && errors.password_confirmation)}
                                        errorText={errors.password_confirmation}
                                        isPassword
                                        isNoAutoComplete
                                        setFieldValue={setFieldValue}
                                    />

                                    <PrimaryButton
                                        type="submit"
                                        className="mt-[30px] w-[100%] h-[42px]"
                                        isPink
                                        disabled={isSubmitting}
                                    >
                                        {t('Registrieren')}
                                    </PrimaryButton>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
            <WarningModal
                isOpen={isOpenModalConfirmPressBack}
                declineButtonText={t('Cancel')}
                setIsOpen={setIsOpenModalConfirmPressBack}
                acceptButtonText={t('Proceed')}
                decline={() => setIsOpenModalConfirmPressBack(false)}
                warningText={t('Are you sure you want to go back? Your current data can be lost')}
                accept={() => navigateBack()}
            />
        </div>
    );
};

export default RegistrationPage;
