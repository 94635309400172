import React from 'react';
import { useTranslation } from 'react-i18next';
import { object } from 'prop-types';
import { ReactComponent as AddUserIcon } from '../../../../assets/icons/addIcon.svg';
import { generateKey } from '../../../util/generateKey';

const AddRowButton = (props) => {
    const { t } = useTranslation();
    const { ArrayHelpers, pushData, values } = props;
    return (
        <div className="flex items-center mt-[20px]">
            <div
                className="flex items-center cursor-pointer "
                onClick={() => {
                    ArrayHelpers.push({ ...pushData, key: generateKey(values.applicationsInfo.length - 1) });
                }}
            >
                <div className="mr-[6px] ">
                    <AddUserIcon width={16} height={16} fill="#9BA1B2" />
                </div>
                <div className="text-int-white-secondary">{t('Add')}</div>
            </div>
        </div>
    );
};

export default AddRowButton;

AddRowButton.propTypes = {
    ArrayHelpers: object.isRequired,
    pushData: object.isRequired,
    values: object.isRequired,
};
