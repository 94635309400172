import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { HEADERS } from '../../components/util/constants';

export const invoiceAPI = createApi({
    reducerPath: 'invoiceAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api',
        prepareHeaders: HEADERS,
    }),
    tagTypes: ['invoiceList'],
    endpoints: (build) => ({
        getInvoiceInfo: build.query({
            query: (key) => `invoices/invoice-info?key=${key}`,
        }),
        getInvoicesSubscriptionList: build.query({
            query: () => `/users/invoices`,
            keepUnusedDataFor: 0.0001,
        }),
        getNotCompletedUserInvoice: build.query({
            query: () => `/not-completed-users/invoices`,
        }),
        getUserInvoiceById: build.query({
            query: ({ invoiceId }) => `/invoices/${invoiceId}`,
        }),
        approveTransactionAsUser: build.mutation({
            query: (body) => ({
                url: '/invoices/confirm',
                method: 'POST',
                body,
            }),
        }),
        getWahlerInvoiceInfo: build.query({
            query: (key) => `invoices/invoice-info/wahler?key=${key}`,
        }),
        sendTransactionStatusAsWahler: build.mutation({
            query: ({ key, status }) => ({
                url: `/invoices/invoice-info/wahler?key=${key}&status=${status}`,
                method: 'POST',
            }),
        }),
        createInvoiceAuthUser: build.mutation({
            query: () => ({
                url: `/users/invoices`,
                method: 'POST',
            }),
        }),
        uploadPdfInvoiceById: build.mutation({
            query: ({ invoiceId, body }) => ({
                url: `/upload/invoices/${invoiceId}`,
                method: 'POST',
                body,
            }),
        }),
        uploadPdfInvoiceByToken: build.mutation({
            query: ({ token, body }) => ({
                url: `/upload/invoices?key=${token}`,
                method: 'POST',
                body,
            }),
        }),
        cancelInvoiceByToken: build.mutation({
            query: ({ token }) => ({
                url: `/invoices/cancel?key=${token}`,
                method: 'POST',
            }),
        }),
        confirmUserInvoiceWithInvoiceId: build.mutation({
            query: ({ invoiceId, body }) => ({
                url: `/invoices/${invoiceId}/confirm`,
                method: 'POST',
                body,
            }),
        }),
    }),
});

export const {
    useGetInvoiceInfoQuery,
    useApproveTransactionAsUserMutation,
    useGetNotCompletedUserInvoiceQuery,
    useGetUserInvoiceByIdQuery,
    useGetInvoicesSubscriptionListQuery,
    useGetWahlerInvoiceInfoQuery,
    useSendTransactionStatusAsWahlerMutation,
    useCreateInvoiceAuthUserMutation,
    useUploadPdfInvoiceByIdMutation,
    useUploadPdfInvoiceByTokenMutation,
    useCancelInvoiceByTokenMutation,
    useConfirmUserInvoiceWithInvoiceIdMutation,
} = invoiceAPI;
