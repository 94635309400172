import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { SETUP_PAGES } from '../components/util/constants';
import Step1GeneralInfo from '../components/CreateProject/Step1CreateProject/Step1GeneralInfo';
import Step2AddCompany from '../components/CreateProject/Step2CreateProject/Step2AddCompany';
import Step3AddOffices from '../components/CreateProject/Step3CreateProject/Step3AddOffices';
import { useGetChosenProjectQuery } from '../store/API/createProjectAPI';
import { useCreateProject } from '../store/hooks/useCreateProject';
import { LayoutLoader } from '../components/Preloader/Preloader';

const CreateProjectPage = () => {
    const [setupCurrentPage, setSetupCurrentPage] = useState(SETUP_PAGES.STEP_1);
    const [searchParams] = useSearchParams();
    const projectId = searchParams.get('projectId');

    const { setProjectTree, createProjectInfo, clearProjectSetup } = useCreateProject();

    const { data: project, isLoading } = useGetChosenProjectQuery(projectId, {
        skip: !projectId,
        refetchOnMountOrArgChange: true,
    });

    useEffect(() => {
        if (!projectId) {
            clearProjectSetup();
        }
    }, []);

    useEffect(() => {
        if (projectId && project) {
            const currentProjectData = {
                name: project.name,
                description: project.description,
                yearsInScope: project.yearsInScope,
                projectId: project.id,
                managerIds: project.managerIds,
                companyInfo: project.companies,
            };

            setProjectTree(currentProjectData);

            if (!project.name && createProjectInfo.projectId) {
                setSetupCurrentPage(SETUP_PAGES.STEP_1);
            }
            if (project?.companies?.length === 0 && createProjectInfo.projectId) {
                setSetupCurrentPage(SETUP_PAGES.STEP_2);
            }
            if (project?.offices?.length === 0 && createProjectInfo.projectId) {
                setSetupCurrentPage(SETUP_PAGES.STEP_2);
            }
        }
    }, [projectId, project, createProjectInfo?.projectId]);

    if (isLoading) {
        return <LayoutLoader />;
    }

    switch (setupCurrentPage) {
        case SETUP_PAGES.STEP_1:
            return <Step1GeneralInfo setSetupCurrentPage={setSetupCurrentPage} />;
        case SETUP_PAGES.STEP_2:
            return <Step2AddCompany setSetupCurrentPage={setSetupCurrentPage} />;
        case SETUP_PAGES.STEP_3:
            return <Step3AddOffices setSetupCurrentPage={setSetupCurrentPage} />;
        default:
            return <></>;
    }
};
export default CreateProjectPage;
