import React from 'react';
import { arrayOf, bool, func, number, string } from 'prop-types';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import Checkbox from '../../../style/Checkbox/Checkbox';
import { PrimaryButton } from '../../../style/Button/Button';
import ModalWindow from '../../../style/ModalWindow/ModalWindow';
import OnlyViewFieldInput from '../../Profile/OnlyViewFieldInput';
import { stringShorter } from '../../util/stringShorter';
import { i18PAGES } from '../../../config/i18n/constants';
import { useEditInternalUserMutation } from '../../../store/API/projectInternalManagementAPI';
import { useToast } from '../../../hoc/toast/ToastProvider';
import { projectManagersUserRoles } from '../../util/constants';

const InternalUserEdit = ({ setIsOpenEditUserModal, isOpenEditUserModal, id, fullName, roles, chosenProjectId }) => {
    const [updateInternalUser] = useEditInternalUserMutation();

    const { t } = useTranslation(i18PAGES.PROJECTS_MANAGEMENT);
    const { successToast, errorToast } = useToast();

    return (
        <ModalWindow isOpen={isOpenEditUserModal} setIsOpen={setIsOpenEditUserModal} isCloseClickOutside>
            <div className="text-[24px] leading-[24px] font-Lexend500 text-int-white-main text-center">
                {t('Edit Internal User')}
            </div>
            <Formik
                onSubmit={(values, { setSubmitting }) => {
                    updateInternalUser({
                        projectId: chosenProjectId,
                        userId: id,
                        body: { projectManager: values.projectManager },
                    })
                        .unwrap()
                        .then(() => {
                            successToast(i18n.t('User information was successfully edited'));
                            setIsOpenEditUserModal(false);
                        })
                        .catch(() => {
                            errorToast(i18n.t('Something went wrong, try again later'));
                        })
                        .finally(() => {
                            setSubmitting(false);
                        });
                }}
                initialValues={{ projectManager: roles ? roles === projectManagersUserRoles.ROLE_MANAGER : false }}
            >
                {({ values, handleSubmit, isSubmitting }) => (
                    <Form className="w-[510px] mt-[40px]">
                        <div className="mb-[12px] w-[400px]">
                            <OnlyViewFieldInput label="User" value={stringShorter(fullName, 40)} />
                        </div>

                        <Checkbox
                            name="projectManager"
                            id="projectManager"
                            label="Assign as Project Manager"
                            value={values.projectManager}
                        />

                        <div className="w-[100%] mt-[37px] flex justify-between">
                            <PrimaryButton
                                isGrey
                                className="w-[170px] h-[50px]"
                                handleClick={() => setIsOpenEditUserModal(false)}
                            >
                                <div className="text-[18px] font-Lexend400">{t('Cancel')}</div>
                            </PrimaryButton>
                            <PrimaryButton
                                isPink
                                className="w-[170px] h-[50px]"
                                handleClick={handleSubmit}
                                disabled={isSubmitting}
                            >
                                <div className="text-[18px] font-Lexend400">{t('Save changes')}</div>
                            </PrimaryButton>
                        </div>
                    </Form>
                )}
            </Formik>
        </ModalWindow>
    );
};

export default InternalUserEdit;

InternalUserEdit.propTypes = {
    setIsOpenEditUserModal: func.isRequired,
    isOpenEditUserModal: bool.isRequired,
    id: number,
    fullName: string,
    roles: arrayOf(string),
    chosenProjectId: number,
};
