import React, { useMemo } from 'react';
import { arrayOf, bool, func, number, object, shape, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ReactComponent as UserManagementEditIcon } from '../../../assets/icons/userManagementEditIcon.svg';
import { ReactComponent as UserManagementDeleteIcon } from '../../../assets/icons/userManagementDeleteIcon.svg';
import { ReactComponent as ExternalUserApprovedIcon } from '../../../assets/icons/externalUserApprovedIcon.svg';
import { ReactComponent as ExternalUserInProgressIcon } from '../../../assets/icons/externalUserInProgressIcon.svg';
import { i18PAGES } from '../../../config/i18n/constants';
import { projectManagersUserRoles } from '../../util/constants';
import StringShorterTooltip from '../../../style/StringShorterTooltip/StringShorterTooltip';

const ExternalTableItem = ({ user, handleClickEditUser, handleClickDeleteUser, isChosenProjectArchived }) => {
    const { t } = useTranslation(i18PAGES.PROJECTS_MANAGEMENT);

    const pagesCount = useMemo(() => {
        const pageAccessCountWithoutChildren = user.access
            .filter((page) => page.data.length === 0)
            .filter((page) => page.availablePage === true).length;

        const pageAccessCountWithChildren = user.access
            .filter((page) => page.data.length > 0)
            .map((page) => page.data)
            .flat()
            .filter((subpage) => subpage.availableSubpage === true).length;

        const pagesCount = pageAccessCountWithoutChildren + pageAccessCountWithChildren;

        return pagesCount;
    }, [user]);

    const userName = useMemo(() => {
        if (user.userRole === projectManagersUserRoles.ROLE_REVIEWER) return <div>{t('Reviewer')}</div>;
        if (user.userRole === projectManagersUserRoles.ROLE_CONTRIBUTOR) return <div>{t('Contributor')}</div>;
        return '';
    }, [user]);

    return (
        <div className="flex w-[100%] py-[18px] text-[14px] leading-[18px] font-Lexend500 text-int-white-main border-b-[1px] border-int-gray-secondary">
            <div className="max-w-[200px] w-[100%] pl-[12px]">
                <StringShorterTooltip string={user.fullName} maxLength={22} />
            </div>
            <div className="max-w-[230px] w-[100%] pl-[12px]">
                <StringShorterTooltip string={user.email} maxLength={26} />
            </div>
            <div className="max-w-[160px] w-[100%] text-center text-[12px] font-Lexend400">
                {user.activated ? (
                    <div className="flex items-center justify-center">
                        <div className="mr-[7px]">{t('Approved')}</div>
                        <ExternalUserApprovedIcon fill="#47E273" width={13} height={12} />
                    </div>
                ) : (
                    <div className="flex items-center justify-center">
                        <div className="mr-[7px]">{t('In progress')}</div>
                        <ExternalUserInProgressIcon fill="#5679F7" width={13} height={12} />
                    </div>
                )}
            </div>
            <div className="max-w-[100px] w-[100%] text-center">{pagesCount}</div>
            <div className="max-w-[160px] w-[100%] text-center">{userName}</div>
            <div className="flex justify-end items-center max-w-[110px] w-[100%] pr-[12px]">
                {!isChosenProjectArchived && (
                    <>
                        <UserManagementEditIcon
                            className="mr-[16px] cursor-pointer"
                            onClick={() => handleClickEditUser(user)}
                        />
                        <UserManagementDeleteIcon
                            className="cursor-pointer"
                            onClick={() => handleClickDeleteUser(user)}
                        />
                    </>
                )}
            </div>
        </div>
    );
};

export default ExternalTableItem;

ExternalTableItem.propTypes = {
    user: shape({
        id: number.isRequired,
        email: string.isRequired,
        fullName: string.isRequired,
        activated: bool.isRequired,
        access: arrayOf(object),
        userRole: string,
    }),
    handleClickEditUser: func.isRequired,
    handleClickDeleteUser: func.isRequired,
    isChosenProjectArchived: bool,
};
