import React from 'react';
import { arrayOf, bool, number, shape, string } from 'prop-types';
import OnlyViewFieldInput from '../Profile/OnlyViewFieldInput';
import OfficesModulesView from '../ProjectsList/OfficesModulesView';

const OfficesInfoFieldsArray = ({ officeItem, currentTab, isShowModules = false }) => (
    <div>
        {officeItem?.offices.map((office, ind) => {
            if (ind === currentTab) {
                return (
                    <div key={ind}>
                        <div className="flex justify-between pt-[40px]">
                            <div className="w-[467px]">
                                <OnlyViewFieldInput label={`Company’s office name ${ind + 1}`} value={office.name} />
                            </div>
                            <div className="w-[467px]">
                                <OnlyViewFieldInput label={`Office ${ind + 1} address`} value={office.address} />
                            </div>
                        </div>
                        <div className="pt-[40px] flex justify-between">
                            <div className="w-[467px]">
                                <OnlyViewFieldInput
                                    label="Quantity of employees"
                                    value={office.employeeQuantityTotal}
                                />
                            </div>
                            <div className="w-[467px]">
                                <OnlyViewFieldInput label="Founded" value={office.yearFounded} />
                            </div>
                        </div>
                        <div className="pt-[40px] flex justify-between">
                            <div className="w-[467px]">
                                <OnlyViewFieldInput
                                    label="Quantity of white-collar employees"
                                    value={office.employeeQuantityWc}
                                />
                            </div>
                            <div className="w-[467px]">
                                <OnlyViewFieldInput
                                    label="Quantity of blue-collar employees"
                                    value={office.employeeQuantityBc}
                                />
                            </div>
                        </div>

                        {isShowModules && <OfficesModulesView office={office} />}
                    </div>
                );
            }
            return <div key={ind} />;
        })}
    </div>
);

export default OfficesInfoFieldsArray;

OfficesInfoFieldsArray.propTypes = {
    officeItem: shape({
        name: string.isRequired,
        offices: arrayOf(
            shape({
                name: string.isRequired,
                address: string.isRequired,
                employeeQuantityTotal: number.isRequired,
                yearFounded: number.isRequired,
                employeeQuantityWc: number.isRequired,
                employeeQuantityBc: number.isRequired,
            })
        ),
    }).isRequired,
    currentTab: number.isRequired,
    isShowModules: bool,
};
