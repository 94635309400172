import React from 'react';
import { number } from 'prop-types';

const ChoicePageCounter = ({ count }) => {
    if (count > 0)
        return (
            <div className="w-[22px] h-[22px] rounded-full border border-int-white-main flex justify-center items-center cursor-pointer relative">
                <div className="text-[10px]">+</div>
                <div className="text-[11px]">{count}</div>
            </div>
        );
};

export default ChoicePageCounter;

ChoicePageCounter.propTypes = {
    count: number.isRequired,
};
