import React, { useState } from 'react';
import { bool, number, object, func } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ReactComponent as UserManagementIcon } from '../../../assets/icons/userManagementIcon.svg';
import InternalTableItem from './InternalTableItem';
import InternalUsersLabels from './InternalUsersLabels';
import { i18PAGES } from '../../../config/i18n/constants';
import InternalUserDelete from './InternalUserDelete';
import InternalUserEdit from './InternalUserEdit';

const InternalUsersTable = ({
    users,
    chosenProjectId,
    isChosenProjectArchived,
    handleSetSortParam,
    handleDefaultSort,
}) => {
    const { t } = useTranslation(i18PAGES.PROJECTS_MANAGEMENT);

    const [isOpenEditUserModal, setIsOpenEditUserModal] = useState(false);
    const [editUserModalData, setEditUserModalData] = useState(null);

    const [isOpenDeleteUserModal, setIsOpenDeleteUserModal] = useState(false);
    const [deleteUserModalData, setDeleteUserModalData] = useState(null);

    const handleClickEditUser = (user) => {
        setIsOpenEditUserModal(true);
        setEditUserModalData(user);
    };

    const handleClickDeleteUser = (user) => {
        setIsOpenDeleteUserModal(true);
        setDeleteUserModalData(user);
    };

    return (
        <div>
            <div className="w-[958px] flex flex-col items-center rounded-lg mb-[20px]">
                <InternalUsersLabels handleSetSortParam={handleSetSortParam} handleDefaultSort={handleDefaultSort} />

                {users?.content?.length > 0 &&
                    users?.content?.map((u) => (
                        <InternalTableItem
                            key={u.id}
                            handleClickEditUser={handleClickEditUser}
                            handleClickDeleteUser={handleClickDeleteUser}
                            user={u}
                            isChosenProjectArchived={isChosenProjectArchived}
                        />
                    ))}

                {users?.content?.length === 0 && (
                    <>
                        <div className="mt-[30px] mb-[12px]">
                            <UserManagementIcon />
                        </div>
                        <div className="text-[14px] leading-[16px] font-Lexend300 text-int-white-secondary">
                            {t('There are no users in the list. Please add new users.')}
                        </div>
                    </>
                )}
            </div>

            {!isChosenProjectArchived && (
                <>
                    <InternalUserDelete
                        isOpenDeleteUserModal={isOpenDeleteUserModal}
                        setIsOpenDeleteUserModal={setIsOpenDeleteUserModal}
                        id={deleteUserModalData?.id}
                        fullName={deleteUserModalData?.fullName}
                        chosenProjectId={chosenProjectId}
                    />

                    <InternalUserEdit
                        setIsOpenEditUserModal={setIsOpenEditUserModal}
                        isOpenEditUserModal={isOpenEditUserModal}
                        id={editUserModalData?.id}
                        fullName={editUserModalData?.fullName}
                        roles={editUserModalData?.userRole}
                        chosenProjectId={chosenProjectId}
                    />
                </>
            )}
        </div>
    );
};

export default InternalUsersTable;

InternalUsersTable.propTypes = {
    users: object,
    chosenProjectId: number,
    isChosenProjectArchived: bool,
    handleSetSortParam: func,
    handleDefaultSort: func,
};
