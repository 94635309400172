import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { HEADERS } from '../../components/util/constants';

export const companiesAPI = createApi({
    reducerPath: 'companiesAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api',
        prepareHeaders: HEADERS,
    }),
    tagTypes: ['companies'],
    endpoints: (build) => ({
        getCompanies: build.query({
            query: ({ sortParams = '' }) => `/users/companies${sortParams && `?sort=${sortParams}`}`,
            providesTags: (result) =>
                result
                    ? [...result.map(({ id }) => ({ type: 'companies', id })), { type: 'companies', id: 'LIST' }]
                    : [{ type: 'companies', id: 'LIST' }],
        }),
        getCompaniesEditProject: build.query({
            query: ({ projectId }) => `/projects/${projectId}/companies`,
            providesTags: (result) =>
                result
                    ? [...result.map(({ id }) => ({ type: 'companies', id })), { type: 'companies', id: 'LIST' }]
                    : [{ type: 'companies', id: 'LIST' }],
        }),
        getCompanyInProject: build.query({
            query: ({ projectId, companyId }) => `/projects/${projectId}/companies/${companyId}`,
            providesTags: (result) =>
                result
                    ? [
                          ...result.countries.map(({ id }) => ({ type: 'companies', id })),
                          { type: 'companies', id: 'LIST' },
                      ]
                    : [{ type: 'companies', id: 'LIST' }],
        }),

        setCompanyNameById: build.mutation({
            query: ({ companyId, body }) => ({
                url: `/companies/${companyId}`,
                method: 'PUT',
                body,
            }),
            invalidatesTags: [{ type: 'companies', id: 'LIST' }],
        }),

        sendCompaniesOffices: build.mutation({
            query: (body) => ({
                url: '/offices',
                method: 'POST',
                body,
            }),
            invalidatesTags: [{ type: 'companies', id: 'LIST' }],
        }),

        createOffices: build.mutation({
            query: (body) => ({
                url: '/offices',
                method: 'POST',
                body,
            }),
        }),

        createCompany: build.mutation({
            query: (body) => ({
                url: '/companies',
                method: 'POST',
                body,
            }),
        }),

        createCountries: build.mutation({
            query: (body) => ({
                url: '/countries',
                method: 'POST',
                body,
            }),
        }),

        createCities: build.mutation({
            query: (body) => ({
                url: '/cities',
                method: 'POST',
                body,
            }),
        }),
    }),
});

export const {
    useGetCompaniesQuery,
    useGetCompaniesEditProjectQuery,
    useGetCompanyInProjectQuery,
    useSetCompanyNameByIdMutation,
    useSendCompaniesOfficesMutation,
    useCreateCompanyMutation,
    useCreateCountriesMutation,
    useCreateCitiesMutation,
    useCreateOfficesMutation,
} = companiesAPI;
