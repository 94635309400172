import React from 'react';
import { FieldArray } from 'formik';
import { bool, func, number, object } from 'prop-types';
import { useTranslation } from 'react-i18next';
import FInput from '../../../style/Input/Input';
import { PrimaryButton } from '../../../style/Button/Button';
import OfficesModulesView from '../../ProjectsList/OfficesModulesView';

const OfficesFieldArray = ({
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    isLastTab = false,
    currentTab,
    isSubmitting,
    onNextButtonPress,
    isShowModules,
    setFieldValue,
}) => {
    const { t } = useTranslation();
    return (
        <FieldArray name="offices">
            {() =>
                values.offices.offices.map((office, ind) => {
                    const officesErrors = errors.offices?.offices?.length ? errors.offices.offices[ind] : {};

                    const officesTouched = touched.offices?.offices?.length ? touched.offices.offices[ind] : {};

                    const officesValues = values.offices.offices[ind];

                    if (ind === currentTab) {
                        return (
                            <div key={ind}>
                                <div className="pt-[40px] flex justify-between">
                                    <FInput
                                        name={`offices.offices.${ind}.name`}
                                        placeholder="Enter office name"
                                        value={officesValues.name}
                                        handleBlur={handleBlur}
                                        handleChange={handleChange}
                                        className="w-[467px]"
                                        type="text"
                                        label={`Company’s office name ${ind + 1}`}
                                        isError={!!(officesTouched?.name && officesErrors?.name)}
                                        errorText={officesErrors?.name}
                                        setFieldValue={setFieldValue}
                                        limit={30}
                                    />

                                    <FInput
                                        name={`offices.offices.${ind}.address`}
                                        placeholder="Enter office address"
                                        value={officesValues.address}
                                        handleBlur={handleBlur}
                                        handleChange={handleChange}
                                        className="w-[467px]"
                                        type="text"
                                        label={`Office ${ind + 1} address`}
                                        isError={!!(officesTouched?.address && officesErrors?.address)}
                                        errorText={officesErrors?.address}
                                        setFieldValue={setFieldValue}
                                        limit={50}
                                    />
                                </div>
                                <div className="pt-[40px] flex justify-between">
                                    <FInput
                                        name={`offices.offices.${ind}.employeeQuantityTotal`}
                                        placeholder="Enter number of employees"
                                        value={officesValues.employeeQuantityTotal}
                                        handleBlur={handleBlur}
                                        handleChange={handleChange}
                                        className="w-[467px]"
                                        type="text"
                                        label="Quantity of employees"
                                        isError={
                                            !!(
                                                officesTouched?.employeeQuantityTotal &&
                                                officesErrors?.employeeQuantityTotal
                                            )
                                        }
                                        errorText={officesErrors?.employeeQuantityTotal}
                                        setFieldValue={setFieldValue}
                                    />
                                    <FInput
                                        name={`offices.offices.${ind}.yearFounded`}
                                        placeholder="Year"
                                        value={officesValues.yearFounded}
                                        handleBlur={handleBlur}
                                        handleChange={handleChange}
                                        className="w-[467px]"
                                        type="text"
                                        label="Founded"
                                        isError={!!(officesTouched?.yearFounded && officesErrors?.yearFounded)}
                                        errorText={officesErrors?.yearFounded}
                                        setFieldValue={setFieldValue}
                                    />
                                </div>
                                <div className="pt-[40px] flex justify-between">
                                    <FInput
                                        name={`offices.offices.${ind}.employeeQuantityWc`}
                                        placeholder="Enter number of employees"
                                        value={officesValues.employeeQuantityWc}
                                        handleBlur={handleBlur}
                                        handleChange={handleChange}
                                        className="w-[467px]"
                                        type="text"
                                        label="Quantity of white-collar employees"
                                        isError={
                                            !!(officesTouched?.employeeQuantityWc && officesErrors?.employeeQuantityWc)
                                        }
                                        errorText={officesErrors?.employeeQuantityWc}
                                        setFieldValue={setFieldValue}
                                    />
                                    <FInput
                                        name={`offices.offices.${ind}.employeeQuantityBc`}
                                        placeholder="Enter number of employees"
                                        value={officesValues.employeeQuantityBc}
                                        handleBlur={handleBlur}
                                        handleChange={handleChange}
                                        className="w-[467px]"
                                        type="text"
                                        label="Quantity of blue-collar employees"
                                        isError={
                                            !!(officesTouched?.employeeQuantityBc && officesErrors?.employeeQuantityBc)
                                        }
                                        errorText={officesErrors?.employeeQuantityBc}
                                        setFieldValue={setFieldValue}
                                    />
                                </div>
                                <div className="float-right">
                                    {isLastTab ? (
                                        <PrimaryButton
                                            type="submit"
                                            className="mt-[30px] w-[103px] h-[42px]"
                                            isGrey
                                            disabled={isSubmitting}
                                        >
                                            {t('Save')}
                                        </PrimaryButton>
                                    ) : (
                                        <PrimaryButton
                                            handleClick={onNextButtonPress}
                                            className="mt-[30px] w-[103px] h-[42px]"
                                            isGrey
                                        >
                                            {t('Next')}
                                        </PrimaryButton>
                                    )}
                                </div>
                                {isShowModules && <OfficesModulesView office={officesValues} />}
                            </div>
                        );
                    }
                    return <div key={ind} />;
                })
            }
        </FieldArray>
    );
};

export default OfficesFieldArray;

OfficesFieldArray.propTypes = {
    values: object.isRequired,
    errors: object.isRequired,
    touched: object.isRequired,
    handleBlur: func.isRequired,
    handleChange: func.isRequired,
    onNextButtonPress: func.isRequired,
    isLastTab: bool,
    currentTab: number.isRequired,
    isSubmitting: bool.isRequired,
    isShowModules: bool,
    setFieldValue: func.isRequired,
};
