import React from 'react';
import { array, string } from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import { usePlatformEditMode } from '../../../store/hooks/usePlatformEditMode';

const ProjectInfoTabSwitcher = ({ itemsArray, projectId, rootUrl }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { platformEditMode: disableTabSwitcher } = usePlatformEditMode();

    const handleClickTab = (item) => {
        navigate(
            `/${rootUrl}?companyId=${item.companyId}&countryId=${item.countryId}&cityId=${item.cityId}&officeId=${item.officeId}&projectId=${projectId}`
        );
    };

    return (
        <div
            id="primary-scroll"
            className={`flex font-Lexend400 text-[16px] leading-[18px] pb-[15px] overflow-auto w-[1420px] ${
                disableTabSwitcher && 'opacity-50'
            }`}
        >
            {itemsArray?.map((item, index) => (
                <div
                    key={index}
                    onClick={disableTabSwitcher ? null : () => handleClickTab(item)}
                    className={`px-[16px] mr-[15px] border-b-[1px] ${
                        disableTabSwitcher ? 'cursor-not-allowed' : 'cursor-pointer'
                    }  ${
                        location.search.includes(
                            `companyId=${item.companyId}&countryId=${item.countryId}&cityId=${item.cityId}&officeId=${item.officeId}`
                        )
                            ? 'text-int-white-main border-int-white-main pb-[19px]'
                            : 'text-int-white-secondary border-int-gray-secondary'
                    }`}
                >
                    <div className="uppercase mb-[8px] font-Lexend400">{item.companyName}</div>
                    <div className="flex text-[14px] font-Lexend300">
                        <div>{`${item.countryName}, ${item.cityName}, ${item.officeName}`}</div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default ProjectInfoTabSwitcher;

ProjectInfoTabSwitcher.propTypes = {
    itemsArray: array,
    projectId: string,
    rootUrl: string,
};
