import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { number } from 'prop-types';
import AddButton from '../../AddButton';
import AddInternalUserModalForm from './AddInternalUserModalForm';
import NoUsersModal from './NoUsersModal';
import { i18PAGES } from '../../../../config/i18n/constants';
import { useOptionsUsersQuery } from '../../../../store/API/projectInternalManagementAPI';

const InternalAddButtonContent = ({ chosenProjectId }) => {
    const { t } = useTranslation(i18PAGES.PROJECTS_MANAGEMENT);

    const { data: userList, isLoading, isFetching } = useOptionsUsersQuery(chosenProjectId, { skip: !chosenProjectId });

    const usersOptions = useMemo(() => {
        if (userList?.length > 0) {
            return userList.map((u) => ({ label: u.fullName, value: u.id }));
        }
        return [];
    }, [userList]);

    const [isAddFormOpened, setIsAddFormOpened] = useState(false);
    const [isNoUserOpened, setIsNoUserOpened] = useState(false);

    const onAddInternalUserClick = useCallback(() => {
        if (userList?.length > 0) {
            setIsAddFormOpened(true);
        } else {
            setIsNoUserOpened(true);
        }
    }, [userList?.length]);

    return (
        <div>
            <AddButton handleClick={onAddInternalUserClick} disabled={isLoading || isFetching}>
                {t('Add Internal User')}
            </AddButton>
            <AddInternalUserModalForm
                usersOptions={usersOptions}
                isOpened={isAddFormOpened}
                setIsOpened={setIsAddFormOpened}
                chosenProjectId={chosenProjectId}
            />
            <NoUsersModal setIsOpen={setIsNoUserOpened} isOpen={isNoUserOpened} />
        </div>
    );
};

export default InternalAddButtonContent;

InternalAddButtonContent.propTypes = {
    chosenProjectId: number,
};
