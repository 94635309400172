import React from 'react';
import { useTranslation } from 'react-i18next';
import { func } from 'prop-types';
import { ReactComponent as ArrowLeft } from '../../assets/icons/arrow-left.svg';

const BackButton = (props) => {
    const { navigateBack } = props;

    const { t } = useTranslation();
    return (
        <div
            className="text-[18px] font-Lexend400 flex items-center text-int-white-main cursor-pointer"
            onClick={navigateBack}
        >
            <ArrowLeft fill="#EDF1FD" width={9} height={16} />
            <div className="ml-[17px]"> {t('Back')}</div>
        </div>
    );
};

export default BackButton;

BackButton.propTypes = {
    navigateBack: func.isRequired,
};
