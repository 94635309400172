import React from 'react';
import { bool, func } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { i18PAGES } from '../../config/i18n/constants';

const ModulesBuyButton = ({ isActive, handlePurchase }) => {
    const { t } = useTranslation(i18PAGES.MODULES);
    return (
        <button
            type="button"
            disabled={!isActive}
            onClick={handlePurchase}
            className={`h-[52px] 
    ${isActive ? 'bg-int-grapefruit' : 'bg-int-gray-secondary'}
    ${isActive ? 'text-int-white-main' : 'text-int-white-secondary'} ${isActive && 'cursor-pointer'}
      flex justify-center w-[100%] items-center text-[18px] font-Lexend500`}
        >
            {t('Buy')}
        </button>
    );
};

ModulesBuyButton.propTypes = {
    isActive: bool,
    handlePurchase: func.isRequired,
};

export default ModulesBuyButton;
