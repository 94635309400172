import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { bool, func, number, oneOfType, string } from 'prop-types';
import { PrimaryButton } from '../../../style/Button/Button';
import ModalWindow from '../../../style/ModalWindow/ModalWindow';
import { i18PAGES } from '../../../config/i18n/constants';
import { DEADLINE_STATUSES } from './constants';
import { useToast } from '../../../hoc/toast/ToastProvider';
import MyDatePickerRange from '../../../style/MyDatePicker/MyDatePickerRange';
import { useExtendDeadlineMutation, useSendRequestMutation } from '../../../store/API/providerSelectionAPI';
import i18n from '../../../config/i18n/i18n';

const DeadLineWindow = (props) => {
    const { t } = useTranslation(i18PAGES.PROVIDER_SELECTION);
    const { successToast, errorToast } = useToast();
    const { isOpen, setIsOpen, status, projectId, buildingBlockId, refetchBuildingBlockInfo } = props;

    const [startDate, setStartDate] = useState(new Date());
    const [finishDate, setFinishDate] = useState(new Date(new Date().setDate(new Date().getDate() + 14)));
    const [isSendRequestAvailable, setIsSendRequestAvailable] = useState(false);
    const [sendRequest, { isLoading: isSendRequestLoading }] = useSendRequestMutation();
    const [extendDeadline, { isLoading: isExtendDeadlineLoading }] = useExtendDeadlineMutation();

    const onClose = useCallback(() => {
        setIsOpen(false);
    }, [setIsOpen]);

    const onSendRequest = useCallback(() => {
        if (status === DEADLINE_STATUSES.INITIAL) {
            sendRequest({ projectId, buildingBlockId, body: { createdDate: startDate, deadlineDate: finishDate } })
                .unwrap()
                .then(() => {
                    successToast(
                        t('Providers’ requests were sent'),
                        t('Now providers will get notifications to fill up the form regarding prices and conditions'),
                        '',
                        3000
                    );
                    refetchBuildingBlockInfo().then(() => {
                        setIsOpen(false);
                    });
                })
                .catch(() => {
                    errorToast(i18n.t('Something went wrong, try again later'));
                });
        }

        if (status === DEADLINE_STATUSES.EXTEND) {
            extendDeadline({ projectId, buildingBlockId, body: { createdDate: startDate, deadlineDate: finishDate } })
                .unwrap()
                .then(() => {
                    successToast(
                        t('Deadline is extended'),
                        t('Providers will receive notification that they have more time for filling the information '),
                        '',
                        3000
                    );
                    refetchBuildingBlockInfo().then(() => {
                        setIsOpen(false);
                    });
                })
                .catch(() => {
                    errorToast(i18n.t('Something went wrong, try again later'));
                });
        }
    }, [
        buildingBlockId,
        errorToast,
        extendDeadline,
        finishDate,
        projectId,
        refetchBuildingBlockInfo,
        sendRequest,
        setIsOpen,
        startDate,
        status,
        successToast,
        t,
    ]);

    useEffect(() => {
        if (startDate && finishDate && startDate.getTime() !== finishDate.getTime()) {
            setIsSendRequestAvailable(true);
        } else {
            setIsSendRequestAvailable(false);
        }
    }, [finishDate, startDate]);

    return (
        <ModalWindow isOpen={isOpen} setIsOpen={setIsOpen} className="w-[580px]">
            <div className="px-[30px]">
                <div className="text-int-white-main font-Lexend500 text-[24px] leading-[24px] text-center">
                    {status === DEADLINE_STATUSES.EXTEND && t('Extend deadline')}
                    {status === DEADLINE_STATUSES.INITIAL && t('Set a deadline')}
                </div>
                <div className="mt-[50px] text-[18px] leading-[28px] font-Lexend400 text-center">
                    {status === DEADLINE_STATUSES.EXTEND &&
                        t(
                            'Extend the deadline for provider, during which it will be possible for provider to fill in the required information.'
                        )}
                    {status === DEADLINE_STATUSES.INITIAL &&
                        t(
                            'Set a deadline for provider, during which it will be possible for provider to fill in the required information.'
                        )}
                </div>
                <div className="mt-[30px] flex items-center justify-center rounded-[8px]">
                    <div className="w-[460px] ">
                        <MyDatePickerRange
                            startDate={startDate}
                            setStartDate={setStartDate}
                            endDate={finishDate}
                            setEndDate={setFinishDate}
                            disableDatesBeforeToday
                        />
                    </div>
                </div>
                <div className="mb-[20px] mt-[50px] flex items-center justify-between">
                    <PrimaryButton className="w-[210px] h-[50px]" isGrey handleClick={onClose}>
                        {t('Cancel')}
                    </PrimaryButton>
                    <PrimaryButton
                        disabled={!isSendRequestAvailable || isSendRequestLoading || isExtendDeadlineLoading}
                        className="w-[210px] h-[50px]"
                        isPink
                        handleClick={onSendRequest}
                    >
                        {t('Send request')}
                    </PrimaryButton>
                </div>
            </div>
        </ModalWindow>
    );
};

export default DeadLineWindow;

DeadLineWindow.propTypes = {
    isOpen: bool.isRequired,
    setIsOpen: func.isRequired,
    status: string,
    projectId: oneOfType([string, number]),
    buildingBlockId: oneOfType([string, number]),
    refetchBuildingBlockInfo: func.isRequired,
};
