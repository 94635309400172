import React, { useState } from 'react';
import { bool, func, number, object } from 'prop-types';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { useNavigate } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import { ReactComponent as EditIcon } from '../../assets/icons/editIcon.svg';
import { ReactComponent as ArrowIcon } from '../../assets/icons/arrowRight.svg';
import ManagementBlock from './ManagementBlock';
import ProjectPagesViewer from './ProjectPagesViewer/ProjectPagesViewer';
import { i18PAGES } from '../../config/i18n/constants';
import { useAuth } from '../../store/hooks/useAuth';
import { getRole, ROLES } from '../util/roleStatus';
import 'react-loading-skeleton/dist/skeleton.css';
import { stringShorter } from '../util/stringShorter';
import { PrimaryButton } from '../../style/Button/Button';
import { ReactComponent as AddToArchiveIcon } from '../../assets/icons/addToArchiveIcon.svg';
import ArchiveProjectModal from './ArchiveProjectModal';
import { useToast } from '../../hoc/toast/ToastProvider';
import { useArchiveProjectMutation } from '../../store/API/projectInternalManagementAPI';
import CommonAccordionItem from '../../style/AccordionItem/CommonAccordionItem';
import ProjectPresentationsItem from './ProjectPresentationsItem';
import { projectManagersUserRoles } from '../util/constants';

const ProjectListInfo = ({ projectInfo, projectId, refetchProjectsList, isArchiveAvailable }) => {
    const { t } = useTranslation(i18PAGES.PROJECT_LIST);
    const { successToast, errorToast } = useToast();
    const { profileData, isEditableListProject, projectRole, isProjectRoleLoading } = useAuth();
    const [isOpenArchiveProjectModalModal, setIsOpenArchiveProjectModalModal] = useState(false);
    const navigate = useNavigate();

    const [archiveProject] = useArchiveProjectMutation();

    const goToEditProjectPage = () => {
        navigate(`/edit-project?projectId=${projectId}`);
    };

    const handleArchiveProject = () => {
        setIsOpenArchiveProjectModalModal(false);
        archiveProject({ projectId })
            .unwrap()
            .then(() => {
                successToast(
                    `${i18n.t('Project')} ${projectInfo.name} ${i18n.t('was successfully archived')}`,
                    '',
                    '',
                    6000
                );
                refetchProjectsList();
            })
            .catch(() => {
                errorToast(i18n.t('Something went wrong, try again later'));
            })
            .finally(() => {});
    };

    // todo API presentations
    const PRESENTATIONS_DATA = [
        { title: 'Presentations 1', link: '/test.pdf' },
        { title: 'Presentations 2', link: '/test.pdf' },
        { title: 'Presentations 3', link: '/test.pdf' },
    ];
    return (
        <div>
            <div className="flex justify-between items-center text-int-white-main">
                <div className="flex items-center">
                    <div className="font-Lexend500 text-[24px] leading-[24px] mr-[32px] capitalize max-w-[300px]">
                        {stringShorter(projectInfo.name, 40)}
                    </div>
                    {(getRole(profileData?.userRoles || []) === ROLES.ROLE_ADMIN ||
                        getRole(profileData?.userRoles || []) === ROLES.ROLE_OWNER ||
                        projectRole === projectManagersUserRoles.ROLE_MANAGER) &&
                        !projectInfo?.archived &&
                        !isProjectRoleLoading && <ManagementBlock projectId={projectId} />}

                    {isProjectRoleLoading && (
                        <Skeleton duration={1} borderRadius="40px" width={298} height={30} baseColor="#354153" />
                    )}
                </div>

                {projectInfo?.archived ? (
                    <div className="flex items-center text-[14px] leading-[14px] font-Lexend400">
                        <div className="mr-[8px]">{t('Archived')}</div>
                        <div>
                            <AddToArchiveIcon width={14} height={13} fill="#EDF1FD" />
                        </div>
                    </div>
                ) : (
                    <div className="flex items-center">
                        {isEditableListProject && !isProjectRoleLoading && (
                            <div
                                className="flex items-center cursor-pointer h-[42px] w-[120px]"
                                onClick={goToEditProjectPage}
                            >
                                <EditIcon />
                                <div className="font-Lexend400 text-[14px] leading-[14px] ml-[4px]">
                                    {t('Edit Project')}
                                </div>
                            </div>
                        )}
                        {isProjectRoleLoading && (
                            <Skeleton duration={1} className="mr-[20px]" width={110} height={20} baseColor="#354153" />
                        )}

                        <div className="text-[14px] leading-[14px] hover:opacity-[0.8] font-Lexend400 ml-[5px] w-[143px] h-[42px] rounded-[8px] bg-[#354153] flex items-center justify-center cursor-pointer">
                            <div className="flex items-center">
                                <div className="mr-[7px]">{t('Go to project')}</div>
                                <ArrowIcon />
                            </div>
                        </div>
                    </div>
                )}
            </div>
            {!projectInfo?.archived && isArchiveAvailable && (
                <div className="flex justify-start mt-[15px]">
                    <PrimaryButton
                        className="h-[34px] px-[18px] flex items-center"
                        isGrey
                        handleClick={() => setIsOpenArchiveProjectModalModal(true)}
                    >
                        <AddToArchiveIcon width={16} height={14} fill="#EDF1FD" />
                        <div className="ml-[6px] text-[14px] font-Lexend400">{t('Add to archive')}</div>
                    </PrimaryButton>
                </div>
            )}

            <div className="mb-[24px] mt-[24px] flex items-center font-Lexend400 text-[16px] leading-[14px]">
                <div className="text-int-white-secondary mr-[14px]">{t('Years in scope')}</div>
                <div className="text-int-white-main">
                    {projectInfo.yearsInScope} {t('years')}
                </div>
            </div>
            <div className="text-[14px] text-int-white-secondary leading-[22px] mb-[20px]">
                {projectInfo.description}
            </div>

            {projectInfo?.archived ? (
                <div className="mb-[30px] mt-[30px]">
                    <CommonAccordionItem title={t('Presentations')}>
                        <div className="px-[20px] pt-[10px]">
                            {/* todo API presentations */}
                            {PRESENTATIONS_DATA.length > 0 ? (
                                PRESENTATIONS_DATA.map((pres, index) => (
                                    <ProjectPresentationsItem title={pres.title} link={pres.link} key={index} />
                                ))
                            ) : (
                                <div className="text-[16px] leading-[24px] font-Lexend500 text-int-white-secondary text-center">
                                    No presentations generated
                                </div>
                            )}
                        </div>
                    </CommonAccordionItem>
                </div>
            ) : (
                <>
                    <div className="text-int-white-main font-Lexend400 leading-[22px] text-[16px] mb-[20px]">
                        {t('Go to the particular project page')}
                    </div>

                    <ProjectPagesViewer projectId={projectId} />
                </>
            )}

            <ArchiveProjectModal
                isOpenArchiveProjectModalModal={isOpenArchiveProjectModalModal}
                setIsOpenArchiveProjectModalModal={setIsOpenArchiveProjectModalModal}
                handleArchiveProject={handleArchiveProject}
            />
        </div>
    );
};

export default ProjectListInfo;

ProjectListInfo.propTypes = {
    projectInfo: object,
    projectId: number,
    refetchProjectsList: func,
    isArchiveAvailable: bool,
};
