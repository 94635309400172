import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as LogoIcon } from '../../assets/icons/logo-big.svg';
import { ReactComponent as MapIcon } from '../../assets/icons/mapIcon.svg';
import { ReactComponent as MailIcon } from '../../assets/icons/mailIcon.svg';
import { ReactComponent as PhoneIcon } from '../../assets/icons/phonIcon.svg';

const ExternalFooter = () => (
    <footer className="h-external-footer-height bg-int-gray-main">
        <div className="max-w-container-size m-auto h-[100%] px-[150px] py-[66px] flex justify-between">
            <div>
                <div>
                    <Link to="/">
                        <LogoIcon />
                    </Link>
                </div>
                <div className="flex items-center mt-[40px]">
                    <MapIcon />
                    <div className="ml-[20px] text-int-white-main font-Lexend400">Blutenburgstr. 36 80636 München</div>
                </div>
                <div>
                    <a href="mailto: info@wahler-hr.com" className="inline-block mt-[30px]">
                        <div className="flex items-center  cursor-pointer">
                            <MailIcon />
                            <div className="ml-[20px] text-int-white-main font-Lexend400">info@wahler-hr.com</div>
                        </div>
                    </a>
                </div>
                <div>
                    <a href="tel: +498920040595" className="inline-block mt-[30px]">
                        <div className="flex items-center  cursor-pointer">
                            <PhoneIcon />
                            <div className="ml-[20px] text-int-white-main font-Lexend400">+49 89 20040595</div>
                        </div>
                    </a>
                </div>
                <div className="text-int-white-secondary text-[14px] mt-[30px]">
                    Copyright {new Date().getFullYear()} MNTN, Inc. Terms & Privacy
                </div>
            </div>

            <div className="flex leading-[42px] justify-between w-[450px]">
                <div>
                    <div className="font-Lexend400 text-int-white-main">
                        <a href="https://www.google.com/" target="_blank" rel="noreferrer">
                            About Wahler
                        </a>
                    </div>
                    <div className="font-Lexend400 text-int-white-main">
                        <a href="https://www.google.com/" target="_blank" rel="noreferrer">
                            Contributors & Writers
                        </a>
                    </div>
                    <div className="font-Lexend400 text-int-white-main">
                        <a href="https://www.google.com/" target="_blank" rel="noreferrer">
                            Write For Us
                        </a>
                    </div>
                    <div className="font-Lexend400 text-int-white-main">
                        <a href="https://www.google.com/" target="_blank" rel="noreferrer">
                            Contact Us
                        </a>
                    </div>
                    <div className="font-Lexend400 text-int-white-main">
                        <a href="https://www.google.com/" target="_blank" rel="noreferrer">
                            Privacy Policy
                        </a>
                    </div>
                </div>

                <div>
                    <div className="font-Lexend400 text-int-white-main">
                        <a href="https://www.google.com/" target="_blank" rel="noreferrer">
                            The Team
                        </a>
                    </div>
                    <div className="font-Lexend400 text-int-white-main">
                        <a href="https://www.google.com/" target="_blank" rel="noreferrer">
                            Jobs
                        </a>
                    </div>
                    <div className="font-Lexend400 text-int-white-main">
                        <a href="https://www.google.com/" target="_blank" rel="noreferrer">
                            Press
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </footer>
);

export default ExternalFooter;
