import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import HRToolsCard from '../HRTools/HRToolsCard';
import Img1 from '../../assets/temp/1.png';
import Img2 from '../../assets/temp/2.png';
import Img3 from '../../assets/temp/3.png';
import Img4 from '../../assets/temp/4.png';
import { i18PAGES } from '../../config/i18n/constants';

const SimilarToolsBlock = () => {
    const { t } = useTranslation(i18PAGES.HR_TOOLS);
    const navigate = useNavigate();

    const moveToHrToolInfo = () => {
        navigate('/hr/1');
    };
    return (
        <>
            <div className="mt-[100px] mb-[40px] text-[40px] leading-[50px] text-int-white-main font-Lexend600">
                {t('Ähnliche Tools')}
            </div>
            <div className="w-[100%]">
                <div className="grid grid-cols-4 gap-x-[33px] gap-y-[40px] mb-[40px] min-w-[840px] pr-[41px]">
                    <HRToolsCard
                        image={Img1}
                        handleClickInfo={moveToHrToolInfo}
                        title="Bamboo HR"
                        description="The best Human Resource Information Software (HRIS) | Bamboo HR"
                    />
                    <HRToolsCard
                        image={Img2}
                        handleClickInfo={moveToHrToolInfo}
                        title="Culture AMP"
                        description="The best Human Resource Information Software (HRIS) | Bamboo HR"
                    />
                    <HRToolsCard
                        image={Img3}
                        handleClickInfo={moveToHrToolInfo}
                        title="Culture AMP"
                        description="The best Human Resource Information Software (HRIS) | Bamboo HR"
                    />
                    <HRToolsCard
                        image={Img4}
                        handleClickInfo={moveToHrToolInfo}
                        title="Culture AMP"
                        description="The best Human Resource Information Software (HRIS) | Bamboo HR"
                    />
                </div>
            </div>
        </>
    );
};

export default SimilarToolsBlock;
