import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { object, string } from 'yup';
import { bool } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from '../../style/Button/Button';
import FInput from '../../style/Input/Input';
import { countryOptions, REGEX } from '../util/constants';
import OnlyViewFieldInput from './OnlyViewFieldInput';
import SearchDropDown from '../../style/SearchDropDown/SearchDropDown';
import { useEditProfileInfoMutation, useProfileInfoQuery } from '../../store/API/profileAPI';
import Preloader from '../Preloader/Preloader';
import { i18PAGES } from '../../config/i18n/constants';
import { useToast } from '../../hoc/toast/ToastProvider';

const validationSchema = object({
    name: string().required('Company name should be stated').max(70, 'Max length is 70 symbols'),
    address: string().required('Company address should be stated').max(50, 'Max length is 50 symbols'),
    country: string().required('Country should be selected'),
    city: string()
        .required('City should be stated')
        .matches(REGEX.STRING_ONLY, 'Invalid city name')
        .max(50, 'Max length is 50 symbols'),
    zipCode: string()
        .required('ZIP should be stated')
        .matches(REGEX.NUMBERS_ONLY, 'Invalid ZIP code')
        .max(7, 'Invalid ZIP code'),
});

const EditCompanyInformation = ({ adminView = false }) => {
    const { t } = useTranslation(i18PAGES.PROFILE);
    const { successToast, errorToast } = useToast();

    const [isEdit, setIsEdit] = useState(false);
    const {
        data: profileInfo,
        isLoading,
        isFetching,
        refetch,
    } = useProfileInfoQuery({}, { refetchOnMountOrArgChange: true });

    const [startValues, setStartValues] = useState({});
    const [editProfileInfo] = useEditProfileInfoMutation();

    useEffect(() => {
        setStartValues(profileInfo);
    }, [profileInfo]);

    return (
        <div className="bg-int-gray-main rounded-lg px-[40px] pt-[20px] pb-[20px] mb-[95px]">
            <Formik
                onSubmit={(values, { setSubmitting }) => {
                    if (
                        values.name === startValues?.companyInfo?.name &&
                        values.address === startValues?.companyInfo?.address &&
                        values.country === startValues?.companyInfo?.country &&
                        values.city === startValues?.companyInfo?.city &&
                        values.zipCode === startValues?.companyInfo?.zipCode
                    ) {
                        setIsEdit(false);
                        setSubmitting(false);
                    } else {
                        editProfileInfo({
                            user: {
                                fullName: profileInfo?.user?.fullName,
                                phone: profileInfo?.user?.phone,
                            },
                            companyInfo: {
                                name: values.name,
                                address: values.address,
                                country: values.country,
                                city: values.city,
                                zipCode: values.zipCode,
                            },
                        })
                            .unwrap()
                            .then(() => {
                                refetch().then(() => {
                                    successToast(t('Company information were updated successfully'));
                                    setIsEdit(false);
                                });
                            })
                            .catch(() => {
                                errorToast(t('Error edit company information!'));
                            })
                            .finally(() => {
                                setSubmitting(false);
                            });
                    }
                }}
                validationSchema={validationSchema}
                enableReinitialize
                initialValues={{
                    name: profileInfo?.companyInfo?.name,
                    address: profileInfo?.companyInfo?.address,
                    country: profileInfo?.companyInfo?.country,
                    city: profileInfo?.companyInfo?.city,
                    zipCode: profileInfo?.companyInfo?.zipCode,
                }}
            >
                {({
                    values,
                    isSubmitting,
                    touched,
                    errors,
                    handleSubmit,
                    handleBlur,
                    handleChange,
                    setErrors,
                    setFieldValue,
                }) => (
                    <Form>
                        <div className="flex items-center justify-between pb-[40px]">
                            <div className="text-[24px] font-Lexend500 text-int-white-main h-[50px]">
                                {t('Company Information')}
                            </div>

                            {adminView && (
                                <div className="flex">
                                    {isEdit ? (
                                        <PrimaryButton
                                            isPink
                                            className="h-[50px] min-w-[170px] px-[32px]"
                                            handleClick={() => {
                                                handleSubmit();
                                            }}
                                            disabled={isSubmitting}
                                        >
                                            <div className="text-[18px] font-Lexend400">{t('Save changes')}</div>
                                        </PrimaryButton>
                                    ) : (
                                        <PrimaryButton
                                            isGrey
                                            className="border-0 h-[50px] min-w-[170px] px-[32px]"
                                            handleClick={() => setIsEdit(true)}
                                        >
                                            <div className="text-[16px] font-Lexend400">{t('Edit')}</div>
                                        </PrimaryButton>
                                    )}
                                </div>
                            )}
                        </div>
                        {isLoading || isFetching ? (
                            <div className="flex justify-center items-center pb-[40px]">
                                <Preloader />
                            </div>
                        ) : (
                            <div className="grid grid-cols-2 gap-x-[40px] mb-[30px]">
                                {isEdit ? (
                                    <FInput
                                        name="name"
                                        placeholder="Company name"
                                        value={values.name}
                                        handleBlur={handleBlur}
                                        handleChange={handleChange}
                                        className="w-[100%] mt-[22px]"
                                        type="text"
                                        label="Company name"
                                        isError={!!(touched.name && errors.name)}
                                        errorText={errors.name}
                                        setFieldValue={setFieldValue}
                                        limit={70}
                                    />
                                ) : (
                                    <div className="mt-[22px]">
                                        <OnlyViewFieldInput label="Company name" value={values.name} />
                                    </div>
                                )}

                                {isEdit ? (
                                    <FInput
                                        name="address"
                                        placeholder="Company address"
                                        value={values.address}
                                        handleBlur={handleBlur}
                                        handleChange={handleChange}
                                        className="w-[100%] mt-[22px]"
                                        type="text"
                                        label="Company address "
                                        isError={!!(touched.address && errors.address)}
                                        errorText={errors.address}
                                        setFieldValue={setFieldValue}
                                        limit={50}
                                    />
                                ) : (
                                    <div className="mt-[22px]">
                                        <OnlyViewFieldInput label="Company address" value={values.address} />
                                    </div>
                                )}

                                {isEdit ? (
                                    <div className="mt-[48px]">
                                        <SearchDropDown
                                            name="country"
                                            placeholder={values.country}
                                            isError={!!(touched.country && errors.country)}
                                            errorText={errors.country}
                                            className="w-[100%]"
                                            options={countryOptions}
                                            label="Country"
                                            handleChange={(country) => setFieldValue('country', country.value)}
                                            handleBlur={handleBlur}
                                            setError={() => setErrors({ country: 'Country should be selected' })}
                                        />
                                    </div>
                                ) : (
                                    <div className="mt-[48px]">
                                        <OnlyViewFieldInput label="Country" value={values.country} />
                                    </div>
                                )}

                                <div className="grid grid-cols-2 gap-x-[23px]">
                                    {isEdit ? (
                                        <FInput
                                            name="city"
                                            placeholder="city"
                                            value={values.city}
                                            handleBlur={handleBlur}
                                            handleChange={handleChange}
                                            className="w-[100%] mt-[48px]"
                                            type="text"
                                            label="City"
                                            isError={!!(touched.city && errors.city)}
                                            errorText={errors.city}
                                            setFieldValue={setFieldValue}
                                            limit={50}
                                        />
                                    ) : (
                                        <div className="mt-[48px]">
                                            <OnlyViewFieldInput label="City" value={values.city} />
                                        </div>
                                    )}
                                    {isEdit ? (
                                        <FInput
                                            name="zipCode"
                                            placeholder="Postal/Zip Code"
                                            value={values.zipCode}
                                            handleBlur={handleBlur}
                                            handleChange={handleChange}
                                            className="w-[100%] mt-[48px]"
                                            type="text"
                                            label="Postal/Zip Code"
                                            isError={!!(touched.zipCode && errors.zipCode)}
                                            errorText={errors.zipCode}
                                            setFieldValue={setFieldValue}
                                            limit={7}
                                        />
                                    ) : (
                                        <div className="mt-[48px]">
                                            <OnlyViewFieldInput label="Postal/Zip Code" value={values.zipCode} />
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </Form>
                )}
            </Formik>
        </div>
    );
};

EditCompanyInformation.propTypes = {
    adminView: bool,
};

export default EditCompanyInformation;
