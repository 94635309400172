import React, { useEffect, useMemo, useRef, useState } from 'react';
import { arrayOf, bool, func, object } from 'prop-types';
import CompaniesInfoButtons from './CompaniesInfoButtons';
import OfficeInfoItem from './OfficeInfoItem';

const CompaniesInfo = (props) => {
    const {
        formik,
        resultedOffice,
        isSubmitted,
        isEditMode,
        openEditAlertModal,
        isApplyForAll,
        applyHandleChange,
        onEditMode,
        setIsSubmitted,
        companies,
    } = props;

    const scrollDivRef = useRef(null);
    const [isSubmittedPressed, setIsSubmittedPressed] = useState(false);

    const organizationStructureErrors = useMemo(
        () =>
            formik.errors.headOfficeOrientation ||
            formik.errors.internationalOrientation ||
            formik.errors.nationalOrientation ||
            formik.errors.organizationStructure ||
            formik.errors.regionalOrientation ||
            formik.errors.lineOrganizationType ||
            formik.errors.marketGrowthStrategy ||
            formik.errors.timingStrategy ||
            formik.errors.competitiveStrategy,
        [
            formik.errors.competitiveStrategy,
            formik.errors.headOfficeOrientation,
            formik.errors.internationalOrientation,
            formik.errors.lineOrganizationType,
            formik.errors.marketGrowthStrategy,
            formik.errors.nationalOrientation,
            formik.errors.organizationStructure,
            formik.errors.regionalOrientation,
            formik.errors.timingStrategy,
        ]
    );

    const isScrollToBottom = useMemo(
        () => organizationStructureErrors && isSubmittedPressed,
        [isSubmittedPressed, organizationStructureErrors]
    );

    const scrollToOrganizationStructure = () => {
        scrollDivRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(() => {
        if (isScrollToBottom) {
            scrollToOrganizationStructure();
        }
    }, [isScrollToBottom, scrollToOrganizationStructure]);

    useEffect(() => {
        if (isSubmittedPressed) {
            setIsSubmittedPressed(false);
        }
    }, [isSubmittedPressed, setIsSubmittedPressed]);

    return (
        <>
            <CompaniesInfoButtons
                isEditMode={isEditMode}
                offEditMode={openEditAlertModal}
                isApplyForAll={isApplyForAll}
                applyHandleChange={applyHandleChange}
                onEditMode={onEditMode}
                setIsSubmitted={setIsSubmitted}
                isFormikSubmitting={formik.isSubmitting}
                setIsSubmittedPressed={setIsSubmittedPressed}
                companies={companies}
            />

            <OfficeInfoItem
                formik={formik}
                isSubmitted={isSubmitted}
                officeItem={resultedOffice}
                isEditMode={isEditMode}
            />
            <div ref={scrollDivRef} />
        </>
    );
};

export default CompaniesInfo;

CompaniesInfo.propTypes = {
    formik: object.isRequired,
    resultedOffice: object.isRequired,
    isSubmitted: bool.isRequired,
    isEditMode: bool.isRequired,
    openEditAlertModal: func.isRequired,
    isApplyForAll: bool.isRequired,
    applyHandleChange: func.isRequired,
    onEditMode: func.isRequired,
    setIsSubmitted: func.isRequired,
    companies: arrayOf(object).isRequired,
};
