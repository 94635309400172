import React, { useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PROJECT_STATUS, SETUP_PAGES } from '../components/util/constants';

import {
    useGetAllProvidersSubmittedQuery,
    useGetChosenProjectQuery,
    useGetSentBuildingBlocksQuery,
} from '../store/API/createProjectAPI';
import { LayoutLoader } from '../components/Preloader/Preloader';
import Step1EditProject from '../components/EditProject/Step1EditProject';
import Step2EditProject from '../components/EditProject/Step2EditProject';
import Step3EditProject from '../components/EditProject/Step3EditProject';
import { i18PAGES } from '../config/i18n/constants';

const EditProjectPage = () => {
    const { t } = useTranslation(i18PAGES.PROJECT_LIST);

    const [searchParams] = useSearchParams();
    const isModulesEditing = searchParams.get('isModulesEditing');
    const projectId = searchParams.get('projectId');

    const [setupCurrentPage, setSetupCurrentPage] = useState(
        isModulesEditing ? SETUP_PAGES.STEP_3 : SETUP_PAGES.STEP_1
    );

    const {
        data: projectData,
        isLoading: isLoadingProjectData,
        refetch: refetchProjectData,
    } = useGetChosenProjectQuery(projectId, {
        skip: !projectId,
        refetchOnMountOrArgChange: true,
    });

    const { data: allProvidersSubmitted, isLoading: isLoadingAllProvidersSubmitted } = useGetAllProvidersSubmittedQuery(
        projectId,
        {
            skip: !projectId,
            refetchOnMountOrArgChange: true,
        }
    );

    const { data: sentBuildingBlocks, isLoading: isLoadingSentBuildingBlocks } = useGetSentBuildingBlocksQuery(
        projectId,
        {
            skip: !projectId,
            refetchOnMountOrArgChange: true,
        }
    );

    const isDisabledFields = useMemo(
        () => projectData?.status === PROJECT_STATUS.SENT_TO_PROVIDERS || allProvidersSubmitted === true,
        [allProvidersSubmitted, projectData]
    );

    if (isLoadingProjectData || isLoadingAllProvidersSubmitted || isLoadingSentBuildingBlocks) {
        return <LayoutLoader />;
    }

    if (projectData === undefined) {
        return <div className="text-center">{t('Project not exist')}</div>;
    }

    switch (setupCurrentPage) {
        case SETUP_PAGES.STEP_1:
            return (
                <Step1EditProject
                    setSetupCurrentPage={setSetupCurrentPage}
                    projectData={projectData}
                    projectId={projectId}
                    refetchProjectData={refetchProjectData}
                    isLoadingProjectData={isLoadingProjectData}
                    isDisabledFields={isDisabledFields}
                />
            );
        case SETUP_PAGES.STEP_2:
            return (
                <Step2EditProject
                    setSetupCurrentPage={setSetupCurrentPage}
                    projectData={projectData}
                    projectId={projectId}
                    refetchProjectData={refetchProjectData}
                    isLoadingProjectData={isLoadingProjectData}
                    isDisabledFields={isDisabledFields}
                />
            );
        case SETUP_PAGES.STEP_3:
            return (
                <Step3EditProject
                    setSetupCurrentPage={setSetupCurrentPage}
                    projectData={projectData}
                    projectId={projectId}
                    isDisabledFields={isDisabledFields}
                    allProvidersSubmitted={allProvidersSubmitted}
                    sentBuildingBlocks={sentBuildingBlocks}
                    projectStatus={projectData?.status}
                />
            );
        default:
            return <></>;
    }
};
export default EditProjectPage;
