import React from 'react';
import { string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ReactComponent as WorkersListWaitingStatusIcon } from '../../../assets/icons/workersListWaitingStatusIcon.svg';
import { ReactComponent as WorkersListRespondStatusIcon } from '../../../assets/icons/workersListRespondStatusIcon.svg';
import { i18PAGES } from '../../../config/i18n/constants';
import { WORKERS_STATUS } from '../../util/constants';

const WorkersListUserStatus = ({ status }) => {
    const { t } = useTranslation(i18PAGES.WORKERS_EFFICIENCY);

    if (status === WORKERS_STATUS.CREATED) return <div />;

    if (status === WORKERS_STATUS.WAITING_FOR_RESPONSE)
        return (
            <div className="flex items-center justify-center">
                <div className="mr-[6px]">
                    <WorkersListWaitingStatusIcon width={14} height={14} />
                </div>
                <div>{t('Waiting for response')}</div>
            </div>
        );

    if (status === WORKERS_STATUS.RESULT_IS_SAVED)
        return (
            <div className="flex items-center justify-center">
                <div className="mr-[6px]">
                    <WorkersListRespondStatusIcon width={14} height={14} />
                </div>
                <div>{t('Respond is saved')}</div>
            </div>
        );
};

export default WorkersListUserStatus;

WorkersListUserStatus.propTypes = {
    status: string.isRequired,
};
