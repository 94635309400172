import React from 'react';
import TabItem from './TabItem';

const BonusPricingBlock = () => (
    <TabItem openHeight={436} headerText="Bonusly Preisgestaltung">
        <div
            className="overflow-y-auto text-[14px] leading-[24px] font-Lexend300 h-[336px] mt-[30px] pl-[10px] pr-[20px] text-int-white-main"
            id="primary-scroll"
        >
            <div>
                Core plans start at $2.70 per user per month, billed annually, plus the cost of zero-markup rewards.
                That's $32.40 per user per year, plus rewards. Pro plans start at $4.50 per user per month, billed
                annually, plus the cost of zero-markup rewards. (That's $54 per user per year, plus rewards.) Bonusly
                Core has the essentials to get your employees started with peer-to-peer recognition, including chat tool
                and intranet integrations, effortless global rewards with instant fulfillment, and automated birthday
                and work anniversary recognition.
            </div>
            <div className="mt-[20px]">
                Bonusly Pro takes the admin and manager experience to the next level with turnkey HRIS and SSO
                integrations, advanced reporting, configurable admin permissions, centralized company incentive and
                award management, and incentivized employee feedback gathering. Core plans start at $2.70 per user per
                month, billed annually, plus the cost of zero-markup rewards. That's $32.40 per user per year, plus
                rewards. Pro plans start at $4.50 per user per month, billed annually, plus the cost of zero-markup
                rewards. (That's $54 per user per year, plus rewards.) Bonusly Core has the essentials to get your
                employees started with peer-to-peer recognition, including chat tool and intranet integrations,
                effortless global rewards with instant fulfillment, and automated birthday and work anniversary
                recognition.
            </div>
            <div className="mt-[20px]">
                Bonusly Pro takes the admin and manager experience to the next level with turnkey HRIS and SSO
                integrations, advanced reporting, configurable admin permissions, centralized company incentive and
                award management, and incentivized employee feedback gathering. Core plans start at $2.70 per user per
                month, billed annually, plus the cost of zero-markup rewards. That's $32.40 per user per year, plus
                rewards. Pro plans start at $4.50 per user per month, billed annually, plus the cost of zero-markup
                rewards. (That's $54 per user per year, plus rewards.) Bonusly Core has the essentials to get your
                employees started with peer-to-peer recognition, including chat tool and intranet integrations,
                effortless global rewards with instant fulfillment, and automated birthday and work anniversary
                recognition.
            </div>
            <div className="mt-[20px]">
                Bonusly Pro takes the admin and manager experience to the next level with turnkey HRIS and SSO
                integrations, advanced reporting, configurable admin permissions, centralized company incentive and
                award management, and incentivized employee feedback gathering. Core plans start at $2.70 per user per
                month, billed annually, plus the cost of zero-markup rewards. That's $32.40 per user per year, plus
                rewards. Pro plans start at $4.50 per user per month, billed annually, plus the cost of zero-markup
                rewards. (That's $54 per user per year, plus rewards.) Bonusly Core has the essentials to get your
                employees started with peer-to-peer recognition, including chat tool and intranet integrations,
                effortless global rewards with instant fulfillment, and automated birthday and work anniversary
                recognition.
            </div>
            <div className="mt-[20px]">
                Bonusly Pro takes the admin and manager experience to the next level with turnkey HRIS and SSO
                integrations, advanced reporting, configurable admin permissions, centralized company incentive and
                award management, and incentivized employee feedback gathering. Core plans start at $2.70 per user per
                month, billed annually, plus the cost of zero-markup rewards. That's $32.40 per user per year, plus
                rewards. Pro plans start at $4.50 per user per month, billed annually, plus the cost of zero-markup
                rewards. (That's $54 per user per year, plus rewards.) Bonusly Core has the essentials to get your
                employees started with peer-to-peer recognition, including chat tool and intranet integrations,
                effortless global rewards with instant fulfillment, and automated birthday and work anniversary
                recognition.
            </div>
            <div className="mt-[20px]">
                Bonusly Pro takes the admin and manager experience to the next level with turnkey HRIS and SSO
                integrations, advanced reporting, configurable admin permissions, centralized company incentive and
                award management, and incentivized employee feedback gathering. Core plans start at $2.70 per user per
                month, billed annually, plus the cost of zero-markup rewards. That's $32.40 per user per year, plus
                rewards. Pro plans start at $4.50 per user per month, billed annually, plus the cost of zero-markup
                rewards. (That's $54 per user per year, plus rewards.) Bonusly Core has the essentials to get your
                employees started with peer-to-peer recognition, including chat tool and intranet integrations,
                effortless global rewards with instant fulfillment, and automated birthday and work anniversary
                recognition.
            </div>
        </div>
    </TabItem>
);

export default BonusPricingBlock;
