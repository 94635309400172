import React from 'react';
import { func } from 'prop-types';
import { ReactComponent as ArrowRight } from '../../../assets/icons/arrow-right.svg';

const RightArrow = (props) => {
    const { onRightArrowClick } = props;

    return (
        <div
            className="cursor-pointer w-[36px] h-[58px] bg-[#1A222E] flex items-center justify-center"
            onClick={onRightArrowClick}
        >
            <ArrowRight fill="#9BA1B2" />
        </div>
    );
};

export default RightArrow;

RightArrow.propTypes = {
    onRightArrowClick: func.isRequired,
};
