import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { bool } from 'prop-types';
import { i18PAGES } from '../config/i18n/constants';
import i18n from '../config/i18n/i18n';
import { PrimaryButton } from '../style/Button/Button';
import { ReactComponent as PageIsNotExistIcon } from '../assets/icons/not-exist icon.svg';

const NotFoundPage = ({ isNavigate }) => {
    const { t } = useTranslation(i18PAGES.AUTH);

    const navigate = useNavigate();

    const onHomeButtonClick = () => {
        navigate('/');
    };

    useEffect(() => {
        if (isNavigate) {
            navigate('/not-found');
        }
    }, [isNavigate, navigate]);

    return (
        <div className="text-center h-[calc(100vh-300px)] flex flex-col items-center justify-center text-int-white-main px-[30px] ">
            <div>
                <PageIsNotExistIcon width={90} height={91} fill="#FF4F66" />
            </div>
            <div className="text-[24px] leading-[32px] font-Lexend500 mt-[40px]">{i18n.t('Page does not exist')}</div>
            <div className="text-[16px] leading-[20px] font-Lexend400 mt-[20px] mb-[40px]">
                {t('This link is wrong or the page doesn’t exist. Please check the link')}
            </div>
            <PrimaryButton isPink className="px-[20px] h-[50px] text-[18px]" handleClick={onHomeButtonClick}>
                <div className="font-Lexend400 text-[18px] leading-[18px]">{i18n.t('Return to the home page')}</div>
            </PrimaryButton>
        </div>
    );
};

export default NotFoundPage;

NotFoundPage.propTypes = {
    isNavigate: bool,
};
