import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import i18n from 'i18next';
import ProjectInfoPageHeader from '../components/Platform/Common/ProjectInfoPageHeader';
import { PAGE_IDENTIFIER } from '../components/util/constants';
import { i18PAGES } from '../config/i18n/constants';
import DescriptionAndButtonsBlock from '../components/Platform/Common/DescriptionAndButtonsBlock';
import { generateKey } from '../components/util/generateKey';
import Preloader from '../components/Preloader/Preloader';
import { useToast } from '../hoc/toast/ToastProvider';
import PageNotAvailable from '../components/Platform/PageNotAvailable';
import {
    getTableToBeIntegrationsHeaderData,
    INITIAL_TO_BE_INTEGRATIONS_VALUE_DATA,
} from '../components/Platform/ToBeIntegrations/constants';
import { toBeIntegrationsValidationSchema } from '../components/Platform/ToBeIntegrations/toBeIntegrationsValidation';
import ToBeIntegrationsEditTable from '../components/Platform/ToBeIntegrations/EditMode/ToBeIntegrationsEditTable';
import ToBeIntegrationsViewTable from '../components/Platform/ToBeIntegrations/ViewMode/ToBeIntegrationsViewTable';
import { ReactComponent as FileCopyIcon } from '../assets/icons/fileCopyIcon.svg';
import {
    useCreateToBeIntegrationsMutation,
    useGetToBeIntegrationsOptionsQuery,
    useGetToBeIntegrationsQuery,
} from '../store/API/toBeIntegrationsAPI';
import http from '../components/util/http';
import LoadingAndTextOverLayer from '../style/LoadingAndTextOverLayer/LoadingAndTextOverLayer';
import DuplicateintegrationsModal from '../components/Platform/ToBeIntegrations/DuplicateintegrationsModal';
import VisualizeGraph from '../components/Platform/ToBeIntegrations/VisualizeGraph/VisualizeGraph';
import { useEditMode } from '../components/util/useEditMode';
import WarningModal from '../style/WarningModal/WarningModal';
import { useSort } from '../hooks/useSort';
import { useWarningModalControl } from '../store/hooks/useWarningModalControl';

const ToBeIntegrationsPage = () => {
    const { t } = useTranslation(i18PAGES.TO_BE_INTEGRATIONS);
    const { successToast, errorToast } = useToast();
    const tableHeaderData = useMemo(() => getTableToBeIntegrationsHeaderData(t), [t]);
    const [isGraphModal, setIsGraphModal] = useState(false);
    const { orderFromUrl, sortByFromUrl } = useSort();

    const formikRef = useRef();

    const {
        isEditMode,
        isShowCloseEditAlertModal,
        openEditAlertModal,
        acceptOffEditMode,
        declineOffEditMode,
        setIsEditMode,
        setIsShowCloseEditAlertModal,
    } = useWarningModalControl(formikRef);

    useEditMode(isEditMode);

    const [copyDataFromPrevStep, setDataFromPrevStep] = useState([]);
    const [isLoadingCopyData, setIsLoadingCopyData] = useState(false);
    const [isOpenDuplicateModal, setIsOpenDuplicateModal] = useState(false);
    const [searchParams] = useSearchParams();
    const projectId = searchParams.get('projectId');

    const onEditMode = () => {
        setDataFromPrevStep([]);
        setIsEditMode(true);
    };

    const openGraphModal = () => {
        setIsGraphModal(true);
    };

    const { data: dataToBeIntegrationsOptions, isLoading: isLoadingToBeIntegrationsOptions } =
        useGetToBeIntegrationsOptionsQuery(projectId, {
            refetchOnMountOrArgChange: true,
            skip: !projectId,
        });

    const { data: dataToBeIntegrations, isLoading: isLoadingToBeIntegrations } = useGetToBeIntegrationsQuery(
        { projectId, sortBy: sortByFromUrl, orderBy: orderFromUrl },
        {
            refetchOnMountOrArgChange: true,
            skip: !projectId,
        }
    );

    const [createToBeIntegrations] = useCreateToBeIntegrationsMutation();

    const initialValueEditData = useMemo(() => {
        if (copyDataFromPrevStep?.length > 0)
            return copyDataFromPrevStep?.map((toBe, index) => ({
                ...toBe,
                key: generateKey(index),
            }));

        if (dataToBeIntegrations?.length > 0 && copyDataFromPrevStep?.length === 0)
            return dataToBeIntegrations?.map((toBe) => ({
                ...toBe,
                key: generateKey(toBe.id),
            }));
        if (dataToBeIntegrations?.length === 0 && copyDataFromPrevStep?.length === 0)
            return [
                {
                    ...INITIAL_TO_BE_INTEGRATIONS_VALUE_DATA,
                    key: generateKey(0),
                },
            ];
    }, [dataToBeIntegrations, copyDataFromPrevStep]);

    useEffect(() => {
        setDataFromPrevStep([]);
    }, [projectId]);

    const copyData = () => {
        setIsLoadingCopyData(true);
        setIsOpenDuplicateModal(false);
        http.get(`/projects/${projectId}/to-be-integrations/copy-information`)
            .then((res) => {
                setDataFromPrevStep(res.data);
                successToast(
                    i18n.t('Information is copied'),
                    '',
                    <FileCopyIcon width={20} height={20} fill="#EDF1FD" />,
                    6000
                );
            })
            .catch((err) => {
                errorToast(err.data.message);
            })
            .finally(() => {
                setIsLoadingCopyData(false);
            });
    };

    if (!projectId) {
        return <PageNotAvailable />;
    }

    return (
        <div className="pl-[50px] pb-[80px] pr-[25px] pt-[70px] min-h-[100vh] text-int-white-main">
            <ProjectInfoPageHeader
                nextLink="/application_requirements"
                pageIdentifier={PAGE_IDENTIFIER.TO_BE_INTEGRATIONS}
                projectId={Number(projectId)}
                pageName={t('To-be Integrations')}
            />

            <div className="mt-[50px]">
                <Formik
                    initialValues={{
                        toBeIntegrationsData: initialValueEditData,
                    }}
                    validationSchema={toBeIntegrationsValidationSchema}
                    enableReinitialize
                    innerRef={formikRef}
                    onSubmit={(values, { setSubmitting }) => {
                        const toBeIntegrationsDataForApi = values?.toBeIntegrationsData?.map((toBe) => ({
                            ...toBe,
                            outgoingBuildingBlockId: toBe.outgoingBuildingBlock.id,
                            ingoingBuildingBlockId: toBe.ingoingBuildingBlock.id,
                            supportEffort: parseInt(toBe.supportEffort),
                        }));

                        createToBeIntegrations({
                            projectId,
                            body: {
                                toBeIntegrations: toBeIntegrationsDataForApi,
                            },
                        })
                            .unwrap()
                            .then(() => {
                                successToast(i18n.t('To-be integrations was updated'), '', '', 6000);
                                setIsEditMode(false);
                            })
                            .catch((err) => {
                                if (
                                    err?.data?.message ===
                                    'After sending a request to providers, you will not be able to edit To-Be Integrations.'
                                ) {
                                    errorToast(
                                        t(
                                            'After sending a request to providers, you will not be able to edit To-Be Integrations.'
                                        )
                                    );
                                } else errorToast(i18n.t('Something went wrong, try again later'));
                            })
                            .finally(() => {
                                setSubmitting(false);
                            });
                    }}
                >
                    {({
                        values,
                        isSubmitting,
                        handleSubmit,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        setFieldValue,
                    }) => (
                        <Form>
                            <DescriptionAndButtonsBlock
                                isEditMode={isEditMode}
                                descriptionText={t('Explanation on how to fill in information on this tab')}
                                onEditMode={onEditMode}
                                offEditMode={openEditAlertModal}
                                isSubmitting={isSubmitting}
                                handleSave={handleSubmit}
                                showButtonCopy
                                handleCopy={() => {
                                    setIsOpenDuplicateModal(true);
                                }}
                                openGraphModal={openGraphModal}
                                isGraphModalActive={dataToBeIntegrations?.length > 0}
                            />
                            {isLoadingToBeIntegrationsOptions || isLoadingToBeIntegrations ? (
                                <div className="flex items-center justify-center pt-[40px]">
                                    <Preloader />
                                </div>
                            ) : (
                                <>
                                    {isEditMode ? (
                                        <ToBeIntegrationsEditTable
                                            tableHeaderData={tableHeaderData}
                                            values={values}
                                            errors={errors}
                                            touched={touched}
                                            handleChange={handleChange}
                                            handleBlur={handleBlur}
                                            setFieldValue={setFieldValue}
                                            dataToBeIntegrationsOptions={dataToBeIntegrationsOptions}
                                        />
                                    ) : (
                                        <ToBeIntegrationsViewTable
                                            tableHeaderData={tableHeaderData}
                                            data={dataToBeIntegrations}
                                        />
                                    )}
                                </>
                            )}
                        </Form>
                    )}
                </Formik>
            </div>
            <LoadingAndTextOverLayer isOpen={isLoadingCopyData} text={t('Dublicating information')} />
            <DuplicateintegrationsModal
                setIsOpenDuplicateModal={setIsOpenDuplicateModal}
                isOpenDuplicateModal={isOpenDuplicateModal}
                handleDuplicateClick={() => copyData()}
            />

            {isGraphModal && (
                <VisualizeGraph
                    isOpen={isGraphModal}
                    setIsOpen={setIsGraphModal}
                    data={dataToBeIntegrations}
                    page={PAGE_IDENTIFIER.TO_BE_INTEGRATIONS}
                />
            )}
            <WarningModal
                isOpen={isShowCloseEditAlertModal}
                setIsOpen={setIsShowCloseEditAlertModal}
                warningText={t('Are you sure you want to close editing mode? Your result will not be saved')}
                acceptButtonText={t('Close')}
                declineButtonText={t('Cancel')}
                accept={acceptOffEditMode}
                decline={declineOffEditMode}
            />
        </div>
    );
};

export default ToBeIntegrationsPage;
