import React from 'react';
import { useTranslation } from 'react-i18next';
import { i18PAGES } from '../../config/i18n/constants';

const ModulesCheckoutTableHeader = () => {
    const { t } = useTranslation(i18PAGES.MODULES);
    return (
        <div className="flex w-[100%] py-[8px] bg-input text-[14px] text-int-white-secondary font-Lexend500 leading-[17px]">
            <div className="max-w-[360px] w-[100%] pl-[12px]">{t('Module')}</div>
            <div className="max-w-[200px] w-[100%] pl-[12px]">{t('Duration')}</div>
            <div className="max-w-[265px] w-[100%] pl-[12px]">{t('Price per module (EUR,€)')}</div>
            <div className="max-w-[100px] w-[100%] pr-[12px] text-right">{t('Action')}</div>
        </div>
    );
};

export default ModulesCheckoutTableHeader;
