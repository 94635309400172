import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { array, bool, func, string } from 'prop-types';
import { Graph } from '@antv/x6';
import html2canvas from 'html2canvas';
import { useSearchParams } from 'react-router-dom';
import ModalWindow from '../../../../style/ModalWindow/ModalWindow';
import { getColumns, getEdges, getNodes } from './helpers';
import { PrimaryButton } from '../../../../style/Button/Button';
import { useSendToBeIntegrationsSchemeMutation } from '../../../../store/API/toBeIntegrationsAPI';
import { useToast } from '../../../../hoc/toast/ToastProvider';
import { PAGE_IDENTIFIER } from '../../../util/constants';
import {
    useGetBuildingBlocksCountriesQuery,
    useSendAsIsIntegrationsSchemeMutation,
} from '../../../../store/API/asIsIntegrationsAPI';
import Preloader from '../../../Preloader/Preloader';

const VisualizeGraph = (props) => {
    const { isOpen, setIsOpen, data, page } = props;
    const { t } = useTranslation();
    const { successToast, errorToast } = useToast();

    const [searchParams] = useSearchParams();
    const projectId = searchParams.get('projectId');

    const { data: buildingBlocksCountries, isLoading: isBbCountriesLoading } = useGetBuildingBlocksCountriesQuery(
        projectId,
        {
            skip: !projectId,
            refetchOnMountOrArgChange: true,
        }
    );

    const graphRef = useRef(null);
    const [graph, setGraph] = useState(null);

    const [sendToBeScheme] = useSendToBeIntegrationsSchemeMutation();
    const [sendAsIsScheme] = useSendAsIsIntegrationsSchemeMutation();

    const [isSendButtonDisabled, setIsSendButtonDisabled] = useState(false);

    const handleZoomIn = () => {
        graph.zoom(0.1);
    };

    const handleZoomOut = () => {
        graph.zoom(-0.1);
    };

    const columns = useMemo(() => getColumns(data), [data]);
    const nodes = useMemo(() => getNodes(columns, buildingBlocksCountries), [buildingBlocksCountries, columns]);
    const edges = useMemo(() => getEdges(data), [data]);

    const graphData = useMemo(() => ({ nodes, edges }), [edges, nodes]);

    useEffect(() => {
        const renderGraph = () => {
            const graph = new Graph({
                container: graphRef.current,
                background: {
                    color: '#f5f5f5',
                },
                width: 1660,
                height: 830,
                resizing: {
                    enabled: true,
                },
            });

            setGraph(graph);
            graph.fromJSON(graphData);
        };

        if (graphRef.current && graphData) {
            renderGraph();
        }
    }, [graphData]);

    const sendFile = () => {
        setIsSendButtonDisabled(true);
        html2canvas(graphRef.current).then((canvas) => {
            const imageData = canvas.toDataURL('image/png');
            const base64Data = imageData.split(',')[1];

            const binaryData = atob(base64Data);
            const typedArray = new Uint8Array(binaryData.length);
            for (let i = 0; i < binaryData.length; i++) {
                typedArray[i] = binaryData.charCodeAt(i);
            }
            const blob = new Blob([typedArray.buffer], { type: 'image/png' });

            const file = new File([blob], `${new Date().toISOString()}.png`, { type: 'image/png' });
            const formData = new FormData();
            formData.append('scheme', file);

            if (page === PAGE_IDENTIFIER.TO_BE_INTEGRATIONS) {
                sendToBeScheme({ projectId, body: formData })
                    .unwrap()
                    .then(() => {
                        successToast('Scheme sent successfully');
                        setIsOpen(false);
                    })
                    .catch(() => {
                        errorToast(t('Something went wrong, try again later'));
                    })
                    .finally(() => {
                        setIsSendButtonDisabled(false);
                    });
            }

            if (page === PAGE_IDENTIFIER.AS_IS_INTEGRATIONS) {
                sendAsIsScheme({ projectId, body: formData })
                    .unwrap()
                    .then(() => {
                        successToast('Scheme sent successfully');
                        setIsOpen(false);
                    })
                    .catch(() => {
                        errorToast(t('Something went wrong, try again later'));
                    })
                    .finally(() => {
                        setIsSendButtonDisabled(false);
                    });
            }
        });
    };

    return (
        <ModalWindow isOpen={isOpen} setIsOpen={setIsOpen} isCloseButton={false}>
            <div className="relative mx-[-28px] mb-[-28px] mt-[-44px] p-[30px] rounded-[8px] bg-int-main-black text-int-white-main">
                {isBbCountriesLoading ? (
                    <Preloader />
                ) : (
                    <>
                        <div
                            id="bold-scroll"
                            className="overflow-auto h-[850px] w-[1680px] items-center justify-center"
                        >
                            <div className="flex items-center justify-center relative " ref={graphRef} />
                        </div>
                        <div className="flex items-center justify-center">
                            <PrimaryButton
                                className="w-[160px] h-[42px] mr-[20px]"
                                isGrey
                                handleClick={() => setIsOpen(false)}
                            >
                                {t('Close')}
                            </PrimaryButton>
                            <PrimaryButton
                                disabled={isSendButtonDisabled}
                                className="w-[160px] h-[42px]"
                                isPink
                                handleClick={sendFile}
                            >
                                {t('Send')}
                            </PrimaryButton>
                        </div>
                        <div className="absolute right-[15px] z-10 top-[30px]">
                            <button
                                type="button"
                                className="rounded w-[20px] h-[20px] bg-int-grapefruit flex items-center justify-center"
                                onClick={handleZoomIn}
                            >
                                +
                            </button>
                            <button
                                type="button"
                                className=" mt-[15px] rounded w-[20px] h-[20px] bg-int-grapefruit flex justify-center items-center"
                                onClick={handleZoomOut}
                            >
                                -
                            </button>
                        </div>
                    </>
                )}
            </div>
        </ModalWindow>
    );
};

export default VisualizeGraph;

VisualizeGraph.propTypes = {
    isOpen: bool.isRequired,
    setIsOpen: func.isRequired,
    data: array.isRequired,
    page: string.isRequired,
};
