import React from 'react';
import { object } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { i18PAGES } from '../../../../../config/i18n/constants';
import OrganizationStructureReadItem from './OrganizationStructureReadItem';
import StrategyReadItem from './StrategyReadItem';
import MarketSituationReadItem from './MarketSituationReadItem';

const OrganizationStructureReadOnly = ({ office }) => {
    const { t } = useTranslation(i18PAGES.COMPANIES_INFO);

    return (
        <div className="mt-[30px]">
            <div className="text-[24px] leading-[24px] font-Lexend500">{t('Organization structure')}</div>

            <div className="text-[14px] leading-[14px] mt-[20px] text-int-white-secondary font-Lexend400">
                {t('Fill this block to get a comment on current situation in the office')}
            </div>

            <div className="px-[10px] py-[30px] rounded-[8px] bg-int-gray-main mt-[30px] flex">
                <OrganizationStructureReadItem office={office} />
                <MarketSituationReadItem office={office} />
                <StrategyReadItem office={office} />
            </div>
        </div>
    );
};

export default OrganizationStructureReadOnly;

OrganizationStructureReadOnly.propTypes = {
    office: object.isRequired,
};
