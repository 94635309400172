import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { arrayOf, bool, func, number, shape, string } from 'prop-types';
import { stringShorter } from '../../util/stringShorter';
import { i18PAGES } from '../../../config/i18n/constants';
import { ReactComponent as TimeIcon } from '../../../assets/icons/timeIcon.svg';

const BuildingBlocksList = (props) => {
    const { t } = useTranslation(i18PAGES.OPTIONS);
    const { currentBuildingBlockId, setCurrentBuildingBlockId, buildingBlocksList, isEditMode } = props;

    const onSelectBuildingBlock = useCallback(
        (id) => {
            setCurrentBuildingBlockId(id);
        },
        [setCurrentBuildingBlockId]
    );

    return (
        <div
            className={`mr-[20px] min-w-[260px] bg-int-gray-main rounded-[8px] p-[16px] ${
                isEditMode && 'cursor-not-allowed'
            }`}
        >
            <div
                id="primary-scroll"
                className={`max-h-[300px] pr-[16px] mr-[-16px] ${
                    isEditMode ? 'overflow-y-hidden opacity-[0.4] pointer-events-none' : 'overflow-y-auto'
                }`}
            >
                <div className="font-Lexend500 text-[16px] leading-[24px]">{t('Building Blocks')}</div>
                <div className="mt-[14px] text-[14px] leading-[24px] font-Lexend400">
                    {buildingBlocksList.map((bb, index) => (
                        <div
                            className={`mt-[16px] flex ${
                                bb.id === currentBuildingBlockId ? 'text-int-white-main' : 'text-int-white-secondary'
                            }`}
                            key={index}
                        >
                            <div
                                onClick={() => onSelectBuildingBlock(bb.id)}
                                className={`cursor-pointer flex items-center ${
                                    bb.isExpired && 'w-[100%]'
                                } justify-between`}
                            >
                                <div>{stringShorter(bb.name, 30)}</div>
                                {bb.isExpired && (
                                    <TimeIcon
                                        width={18}
                                        height={18}
                                        fill={bb.id === currentBuildingBlockId ? '#EDF1FD' : '#9BA1B2'}
                                    />
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default BuildingBlocksList;

BuildingBlocksList.propTypes = {
    currentBuildingBlockId: number,
    setCurrentBuildingBlockId: func.isRequired,
    buildingBlocksList: arrayOf(
        shape({
            name: string.isRequired,
            id: number.isRequired,
            isExpired: bool.isRequired,
        })
    ),
    isEditMode: bool.isRequired,
};
