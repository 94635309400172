import { useTranslation } from 'react-i18next';
import EditProfileInformation from '../components/Profile/EditProfileInformation';
import EditCompanyInformation from '../components/Profile/EditCompanyInformation';
import ChangePassword from '../components/Profile/ChangePassword';
import { useAuth } from '../store/hooks/useAuth';
import { USER_ROLES } from '../components/util/constants';
import { i18PAGES } from '../config/i18n/constants';

const ProfilePage = () => {
    const { profileData } = useAuth();

    const { t } = useTranslation(i18PAGES.PROFILE);

    const isAdmin = profileData?.userRoles?.includes(USER_ROLES.ROLE_ADMIN);

    return (
        <div className="w-[1020px] m-auto">
            <div className="text-[24px] leading-[18px] text-int-white-main font-Lexend400 mt-[40px] mb-[50px] cursor-pointer">
                {t('My profile')}
            </div>
            <ChangePassword />
            <EditProfileInformation adminView={isAdmin} />
            <EditCompanyInformation adminView={isAdmin} />
        </div>
    );
};

export default ProfilePage;
