import React from 'react';
import { bool, func } from 'prop-types';
import { useTranslation } from 'react-i18next';
import ModalWindow from '../../../style/ModalWindow/ModalWindow';
import { PrimaryButton } from '../../../style/Button/Button';
import { i18PAGES } from '../../../config/i18n/constants';

const DuplicateintegrationsModal = ({ isOpenDuplicateModal, setIsOpenDuplicateModal, handleDuplicateClick }) => {
    const { t } = useTranslation(i18PAGES.SUBSCRIPTION_MANAGEMENT);

    return (
        <ModalWindow
            isOpen={isOpenDuplicateModal}
            setIsOpen={setIsOpenDuplicateModal}
            className="-top-[100px] w-[580px]"
            isCloseClickOutside
        >
            <div className="flex flex-col items-center">
                <div className="text-[24px] leading-[24px] font-Lexend500 text-int-white-main">
                    {t('Duplicate integrations')}
                </div>
                <div className="text-[18px] leading-[24px] font-Lexend400 text-int-white-main my-[50px] text-center">
                    <div>
                        {t(
                            'The table with integrations from the previous step As-Is Integrations will be fully duplicated. You will be able to make edit after it.'
                        )}
                    </div>
                </div>

                <div className="w-[100%] flex justify-around">
                    <PrimaryButton
                        className="w-[210px] h-[50px]"
                        handleClick={() => {
                            setIsOpenDuplicateModal(false);
                        }}
                    >
                        <div className="text-[18px] font-Lexend400">{t('Cancel')}</div>
                    </PrimaryButton>
                    <PrimaryButton isPink className="w-[210px] h-[50px]" handleClick={handleDuplicateClick}>
                        <div className="text-[18px] font-Lexend400">{t('Duplicate')}</div>
                    </PrimaryButton>
                </div>
            </div>
        </ModalWindow>
    );
};

export default DuplicateintegrationsModal;

DuplicateintegrationsModal.propTypes = {
    isOpenDuplicateModal: bool.isRequired,
    setIsOpenDuplicateModal: func.isRequired,
    handleDuplicateClick: func,
};
