export const formatAccessDataForApi = (data) =>
    data?.map((page) => {
        if (page?.data?.length > 0) {
            if (page?.data?.filter((dataItem) => dataItem.availableSubpage === true).length === 0)
                return { ...page, availablePage: false };
            if (page?.data?.filter((dataItem) => dataItem.availableSubpage === true).length > 0)
                return { ...page, availablePage: true };
        }
        return page;
    });
