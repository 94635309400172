import React, { useMemo, useRef, useState } from 'react';
import { func, string, bool } from 'prop-types';
import { ReactComponent as SortingIcon } from '../../assets/icons/sortingIcon.svg';
import useOutsideAlerter from '../util/useOutsideAlerter';

const ProjectManagementUserSortBlock = ({
    handleNewToOldSortClick,
    handleOldToNewSortClick,
    handleAZSortClick,
    handleZASortClick,
    handleDefaultSortClick,
    title,
    label,
    isProjectDropdown = false,
}) => {
    const [isMenuOpened, setIsMenuOpened] = useState(false);

    const menuToggle = () => {
        setIsMenuOpened((prev) => !prev);
    };

    const container = useRef(null);
    useOutsideAlerter({ ref: container, callback: () => setIsMenuOpened(false) });

    const MENU_ITEMS_SORT_USERS = [
        {
            title: 'A-Z',
            handleClick: () => handleAZSortClick(),
        },
        {
            title: 'Z-A',
            handleClick: () => handleZASortClick(),
        },
        {
            title: 'By default',
            handleClick: () => handleDefaultSortClick(),
        },
    ];

    const MENU_ITEMS_SORT_PROJECTS = [
        {
            title: 'New to old',
            handleClick: () => handleNewToOldSortClick(),
        },
        {
            title: 'Old to new',
            handleClick: () => handleOldToNewSortClick(),
        },
        {
            title: 'A-Z',
            handleClick: () => handleAZSortClick(),
        },
        {
            title: 'Z-A',
            handleClick: () => handleZASortClick(),
        },
        {
            title: 'By default',
            handleClick: () => handleDefaultSortClick(),
        },
    ];

    const menuItems = useMemo(() => {
        if (isProjectDropdown) return MENU_ITEMS_SORT_PROJECTS;
        return MENU_ITEMS_SORT_USERS;
    }, [isProjectDropdown]);

    return (
        <div ref={container} className="flex w-[100%] items-center cursor-pointer relative" onClick={menuToggle}>
            <div className="flex w-[100%] justify-between items-center">
                <div>{title}</div>
                <div className="flex items-center">
                    <div className="text-[12px] leading-[12px] text-int-white-main">{label}</div>
                    <div className={`pl-[12px] ${!isProjectDropdown && 'pr-[12px]'}`}>
                        <SortingIcon width={16} height={17} fill={isProjectDropdown ? '#9BA1B2' : '#EDF1FD'} />
                    </div>
                </div>
            </div>

            {isMenuOpened && (
                <div
                    className={`absolute overflow-hidden bg-[#2A3143] rounded-lg text-[14px] leading-[19px] text-int-white-main whitespace-nowrap top-[34px] ${
                        !isProjectDropdown ? 'right-[10px]' : 'right-[0px]'
                    }  z-20`}
                >
                    {menuItems.map((item, index) => (
                        <div
                            key={index}
                            className="h-[42px] flex items-center pl-[20px] pr-[40px] hover:bg-[#474E62] font-Lexend400"
                            onClick={item.handleClick}
                        >
                            {item.title}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default ProjectManagementUserSortBlock;

ProjectManagementUserSortBlock.propTypes = {
    isProjectDropdown: bool,
    handleNewToOldSortClick: func,
    handleOldToNewSortClick: func,
    handleDefaultSortClick: func,
    handleAZSortClick: func,
    handleZASortClick: func,
    label: string,
    title: string,
};
