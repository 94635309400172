import React, { useMemo, useRef, useState } from 'react';
import { arrayOf, bool, func, number, object, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import useOutsideAlerter from '../../../util/useOutsideAlerter';
import ChoicePageItem from './ChoicePageItem';
import ChoicePageCounter from './ChoicePageCounter';
import ChoicePagesDropDownItem from './ChoicePagesDropDownItem';
import { i18PAGES } from '../../../../config/i18n/constants';
import { stringCapitalise } from '../../../util/stringCapitalise';
import { ReactComponent as ErrorIcon } from '../../../../assets/icons/errorIcon.svg';

export default function EditChoicePagesDropDown({
    placeholder,
    isTouched,
    label,
    values,
    valuesPages,
    index,
    name,
    handleBlur,
    className,
    typeEdit = false,
}) {
    const { t } = useTranslation(i18PAGES.PROJECTS_MANAGEMENT);
    const container = useRef(null);
    const [open, setOpen] = useState(false);
    const [isOpenHelpErrorInfo, setIsOpenHelpErrorInfo] = useState(false);
    useOutsideAlerter({ ref: container, callback: () => setOpen(false) });

    const filteredChoiceModules = useMemo(
        () =>
            [
                ...valuesPages
                    .filter((module) => module.data.length === 0)
                    .map((module) => ({
                        title: stringCapitalise(module.pageEnum.value),
                        availablePage: module.availablePage,
                    })),
                ...valuesPages
                    .filter((module) => module.data.length > 0)
                    .map((module) =>
                        module.dynamic === true
                            ? module.data.map((page) => ({
                                  title: stringCapitalise(page.companyName),
                                  availablePage: page.availableSubpage,
                              }))
                            : module.data.map((page) => ({
                                  title: stringCapitalise(page.value),
                                  availablePage: page.availableSubpage,
                              }))
                    )
                    .flat(),
            ].filter((module) => module.availablePage === true),
        [valuesPages]
    );

    const visibleChoiceModules = useMemo(() => filteredChoiceModules.slice(0, 2), [filteredChoiceModules]);

    const notVisibleChoiceModules = useMemo(() => filteredChoiceModules.slice(2), [filteredChoiceModules]);

    const isError = isTouched && filteredChoiceModules.length === 0;

    const onBlur = (e) => {
        if (!open) {
            handleBlur(e);
        }
    };

    return (
        <div className={`relative ${className || ''}`} ref={container}>
            <div
                className={`absolute text-[14px] leading-[16px] font-Lexend400 left-0 top-[-20px] ${
                    isError ? 'text-error' : 'text-int-white-main'
                } `}
            >
                {label}
            </div>
            <input
                name={name}
                type="text"
                onClick={() => setOpen(true)}
                className={`h-[44px] w-[100%] min-w-[160px] border ${!isError && open ? 'border-int-white-main' : ''}
                flex items-center bg-[#2A3143] appearance-none rounded-[8px] 
                text-int-grey-40 pl-[24px] pr-[35px] cursor-pointer`}
                onBlur={onBlur}
                placeholder={filteredChoiceModules.length === 0 ? placeholder : ''}
                style={{ caretColor: 'transparent', border: `${isError ? '1px solid #FF5F5F' : ''}` }}
                readOnly
            />
            {filteredChoiceModules.length > 0 && (
                <div
                    className="relative max-w-[90%] top-[-33px] left-[25px] cursor-pointer"
                    onClick={() => setOpen(true)}
                >
                    <div className="flex">
                        {visibleChoiceModules?.map((i, index) => (
                            <ChoicePageItem name={i.title} key={index} />
                        ))}
                        <ChoicePageCounter count={notVisibleChoiceModules.length} />
                    </div>
                </div>
            )}
            {open && (
                <div className="absolute top-[calc(100%-18px)] bg-[#2A3143] left-0 w-full text-int-grey-40 rounded z-50 pr-[20px] py-[16px] shadow-dropdown">
                    <div className="max-h-[200px] overflow-auto pr-[0px] -mr-[10px]" id="primary-scroll">
                        {valuesPages?.map((module, moduleIndex) => (
                            <ChoicePagesDropDownItem
                                module={module}
                                key={moduleIndex}
                                moduleIndex={moduleIndex}
                                index={index}
                                values={values}
                                typeEdit={typeEdit}
                            />
                        ))}
                    </div>
                </div>
            )}

            {isError && (
                <div className="absolute right-[25px] top-[14px]">
                    <div
                        className="cursor-pointer"
                        onMouseEnter={() => setIsOpenHelpErrorInfo(true)}
                        onMouseLeave={() => setIsOpenHelpErrorInfo(false)}
                    >
                        <ErrorIcon />
                    </div>
                    {isOpenHelpErrorInfo && (
                        <div
                            className="text-[12px] text-error font-Lexend300 absolute bg-[#222730]
                         p-[10px] rounded-[2px] z-10 bottom-[23px] right-0 whitespace-nowrap"
                        >
                            {t('Pages should be select')}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

EditChoicePagesDropDown.propTypes = {
    index: number,
    values: object.isRequired,
    valuesPages: arrayOf(object).isRequired,
    label: string,
    placeholder: string,
    isTouched: bool,
    name: string.isRequired,
    handleBlur: func,
    className: string,
    typeEdit: bool,
};
