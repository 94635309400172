import React from 'react';
import { bool, func, number, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import DeleteUserModal from '../../UserManagement/DeleteUserModal';
import { useDeleteExternalUserMutation } from '../../../store/API/projectExternalManagementAPI';
import { useToast } from '../../../hoc/toast/ToastProvider';
import { i18PAGES } from '../../../config/i18n/constants';

const ExtrenalUserDelete = ({ isOpenDeleteUserModal, setIsOpenDeleteUserModal, id, fullName, chosenProjectId }) => {
    const { t } = useTranslation(i18PAGES.USER_MANAGEMENT);
    const { successToast, errorToast } = useToast();

    const [deleteExternalUser, { isLoading }] = useDeleteExternalUserMutation();

    const handleDeleteClick = (id) => {
        deleteExternalUser({ projectId: chosenProjectId, userId: id })
            .unwrap()
            .then((data) => {
                successToast(t('User was successfully deleted'));
                setIsOpenDeleteUserModal(false);
            })
            .catch((err) => {
                errorToast(t('Error deleting user!'));
            });
    };

    if (id && fullName) {
        return (
            <DeleteUserModal
                isOpenDeleteUserModal={isOpenDeleteUserModal}
                setIsOpenDeleteUserModal={setIsOpenDeleteUserModal}
                id={id}
                fullName={fullName}
                isLoading={isLoading}
                handleDeleteClick={handleDeleteClick}
            />
        );
    }
    return <></>;
};

export default ExtrenalUserDelete;

ExtrenalUserDelete.propTypes = {
    isOpenDeleteUserModal: bool.isRequired,
    setIsOpenDeleteUserModal: func.isRequired,
    id: number,
    fullName: string,
    chosenProjectId: number,
};
