import React from 'react';
import { FieldArray } from 'formik';
import { func, object } from 'prop-types';
import ChallengesEditItem from './ChallengesEditItem';
import ChallengesTableLabels from '../ChallengesTableLabels';
import ModulesStrategyAddLineButton from '../../Common/Modules&StrategyAddLineButton';
import { challengesEmptyObject, hrStrategiesEmptyObject } from '../../Common/constants';

const ChallengesEditMode = ({ values, errors, touched, handleBlur, handleChange, setFieldValue }) => (
    <div className="bg-int-gray-main p-[28px] pr-[56px] rounded-[8px]">
        <ChallengesTableLabels />
        <FieldArray
            name="challenges"
            render={(ArrayHelpers) => (
                <>
                    {values.challenges?.map((we, index) => {
                        const challengesErrors = (errors.challenges?.length && errors.challenges[index]) || {};

                        const challengesTouched = (touched.challenges?.length && touched.challenges[index]) || {};

                        return (
                            <ChallengesEditItem
                                key={index}
                                challengesTouched={challengesTouched}
                                challengesErrors={challengesErrors}
                                handleBlur={handleBlur}
                                handleChange={handleChange}
                                ArrayHelpers={ArrayHelpers}
                                values={values}
                                index={index}
                                setFieldValue={setFieldValue}
                            />
                        );
                    })}
                    <ModulesStrategyAddLineButton ArrayHelpers={ArrayHelpers} pushObject={challengesEmptyObject} />
                </>
            )}
        />
    </div>
);

export default ChallengesEditMode;

ChallengesEditMode.propTypes = {
    values: object.isRequired,
    errors: object.isRequired,
    touched: object.isRequired,
    handleBlur: func.isRequired,
    handleChange: func.isRequired,
    setFieldValue: func.isRequired,
};
