import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { LayoutLoader } from '../components/Preloader/Preloader';
import http from '../components/util/http';

const ActivateAccountPage = () => {
    const { token } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        http.get(`/activate?key=${token}`)
            .then((res) => {
                navigate(`/invoice/${token}`);
            })
            .catch(() => {
                navigate('/confirmation/email-failed');
            });
    }, []);

    return <LayoutLoader />;
};
export default ActivateAccountPage;
