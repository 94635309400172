import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { useSearchParams } from 'react-router-dom';
import i18n from 'i18next';
import { PrimaryButton } from '../style/Button/Button';
import { PAGE_IDENTIFIER } from '../components/util/constants';
import { i18PAGES } from '../config/i18n/constants';
import { ReactComponent as EditIcon } from '../assets/icons/editIcon.svg';
import ProjectInfoPageHeader from '../components/Platform/Common/ProjectInfoPageHeader';
import { generateKey } from '../components/util/generateKey';
import { useAuth } from '../store/hooks/useAuth';
import {
    useCreateToBeApplicationsMutation,
    useGetBuildingBlocksCompanyOfficesQuery,
    useGetToBeApplicationsQuery,
} from '../store/API/toBeApplicationAPI';
import AggregationOfYearEditTable from '../components/Platform/ToBeApplications/ToBeApplicationsEdit/ToBeApplicationsEditTable';
import AggregationOfYearViewTable from '../components/Platform/ToBeApplications/ToBeApplicationsView/ToBeApplicationsViewTable';
import Preloader from '../components/Preloader/Preloader';
import { useToast } from '../hoc/toast/ToastProvider';
import { toBeApplicationsValidation } from '../components/Platform/ToBeApplications/toBeApplicationsValidation';
import { getInitialValueEditData } from '../components/Platform/ToBeApplications/getInitialValueEditData';
import PageNotAvailable from '../components/Platform/PageNotAvailable';
import { useEditMode } from '../components/util/useEditMode';
import WarningModal from '../style/WarningModal/WarningModal';
import { useSort } from '../hooks/useSort';
import { useWarningModalControl } from '../store/hooks/useWarningModalControl';

const ToBeApplicationsPage = () => {
    const { t } = useTranslation(i18PAGES.TO_BE_APPLICATIONS);
    const { orderFromUrl, sortByFromUrl } = useSort();
    const [isPageMounted, setIsPageMounted] = useState(false);
    const { successToast, errorToast } = useToast();

    const formikRef = useRef();

    const {
        isEditMode,
        isShowCloseEditAlertModal,
        openEditAlertModal,
        acceptOffEditMode,
        declineOffEditMode,
        setIsEditMode,
        setIsShowCloseEditAlertModal,
    } = useWarningModalControl(formikRef);

    useEditMode(isEditMode);

    const { isEditablePagesProject } = useAuth();

    const [searchParams] = useSearchParams();
    const projectId = searchParams.get('projectId');
    const officeId = searchParams.get('officeId');

    const tableHeaderData = useMemo(
        () => [
            {
                minWidth: 300,
                name: t('Building block'),
            },
            {
                minWidth: 390,
                name: t('Companies'),
            },
            {
                minWidth: 280,
                name: t('Year of implementation'),
                isSortable: true,
                queryName: 'yearOfImplementation',
                isDateSorting: true,
            },
        ],
        [t]
    );

    const { data: dataBuildingBlocksCompanyOffices, isLoading: isLoadingBuildingBlocksCompanyOffices } =
        useGetBuildingBlocksCompanyOfficesQuery(projectId, {
            refetchOnMountOrArgChange: true,
            skip: !projectId,
        });

    const { data: dataToBeApplications, isLoading: isLoadingToBeApplications } = useGetToBeApplicationsQuery(
        { projectId, sortBy: sortByFromUrl, orderBy: orderFromUrl },
        {
            refetchOnMountOrArgChange: true,
            skip: !projectId,
        }
    );

    const [createToBeApplications] = useCreateToBeApplicationsMutation();

    const initialValueEditData = useMemo(
        () => getInitialValueEditData(dataToBeApplications, dataBuildingBlocksCompanyOffices),
        [dataToBeApplications, dataBuildingBlocksCompanyOffices, getInitialValueEditData]
    );

    const initialValueCompanies = useMemo(
        () =>
            dataBuildingBlocksCompanyOffices?.companyOffices?.map((company) => ({
                companyId: company.companyId,
                companyName: company.companyName,
                officeList: company?.officeList?.map((office) => ({ ...office, isChosen: false })),
                isChosen: false,
            })),
        [dataBuildingBlocksCompanyOffices]
    );

    useEffect(() => {
        setIsPageMounted(true);
    }, []);

    useEffect(() => {
        if (
            dataBuildingBlocksCompanyOffices &&
            dataBuildingBlocksCompanyOffices?.buildingBlocks?.length === 0 &&
            !isLoadingBuildingBlocksCompanyOffices
        ) {
            errorToast(t('Add building blocks on the project detail page'), 5000);
        }
    }, [dataBuildingBlocksCompanyOffices, errorToast, isLoadingBuildingBlocksCompanyOffices, isPageMounted, t]);

    if (!projectId) {
        return <PageNotAvailable />;
    }

    return (
        <div className="pl-[50px] pb-[80px] pr-[25px] pt-[70px] min-h-[100vh] text-int-white-main">
            <ProjectInfoPageHeader
                nextLink="/as_is_integrations"
                pageIdentifier={PAGE_IDENTIFIER.TO_BE_APPLICATIONS}
                projectId={Number(projectId)}
                pageName="To-be applications"
            />
            <Formik
                onSubmit={(values, { setSubmitting }) => {
                    const toBeApplicationListDataForApi = values.toBeApplicationsList.map((app) => ({
                        id: app.id,
                        buildingBlock: app.buildingBlock,
                        yearOfImplementation: app.yearOfImplementation,
                        companyOffices: app.companyOffices
                            .map((company) => ({
                                companyId: company.companyId,
                                companyName: company.companyName,
                                officeList: company.officeList.filter((office) => office.isChosen === true),
                            }))
                            .filter((company) => company.officeList.length > 0)
                            .map((company) => ({
                                companyId: company.companyId,
                                companyName: company.companyName,
                                officeList: company.officeList.map((office) => ({
                                    cityId: office.cityId,
                                    cityName: office.cityName,
                                    countryId: office.countryId,
                                    countryName: office.countryName,
                                    officeId: office.officeId,
                                    officeName: office.officeName,
                                })),
                            })),
                    }));

                    createToBeApplications({
                        projectId,
                        officeId,
                        body: {
                            toBeApplicationList: toBeApplicationListDataForApi,
                        },
                    })
                        .unwrap()
                        .then(() => {
                            successToast(i18n.t('To-be applications was updated'), '', '', 6000);
                            setIsEditMode(false);
                        })
                        .catch(() => {
                            errorToast(i18n.t('Something went wrong, try again later'));
                        })
                        .finally(() => {
                            setSubmitting(false);
                        });
                }}
                validationSchema={toBeApplicationsValidation}
                initialValues={
                    dataToBeApplications?.length > 0
                        ? initialValueEditData
                        : {
                              toBeApplicationsList: [
                                  {
                                      buildingBlock: { id: 0, name: '' },
                                      companyOffices: initialValueCompanies,
                                      yearOfImplementation: '',
                                      key: generateKey(0),
                                  },
                              ],
                          }
                }
                enableReinitialize
                innerRef={formikRef}
            >
                {({ values, touched, errors, handleSubmit, handleBlur, handleChange, isSubmitting, setFieldValue }) => (
                    <Form>
                        <div className="flex justify-between mt-[40px] mb-[40px]">
                            <div className="w-[600px] h-[52px] flex items-center px-[16px] bg-[#29323F80] rounded-[8px]">
                                {t('Select building block from the existing blocks bought by the user')}
                            </div>
                        </div>
                        {isLoadingBuildingBlocksCompanyOffices || isLoadingToBeApplications ? (
                            <div className="flex items-center justify-center pt-[40px]">
                                <Preloader />
                            </div>
                        ) : (
                            <div className="pb-[20px] flex justify-between">
                                {isEditMode ? (
                                    <>
                                        <AggregationOfYearEditTable
                                            values={values}
                                            errors={errors}
                                            touched={touched}
                                            handleChange={handleChange}
                                            handleBlur={handleBlur}
                                            setFieldValue={setFieldValue}
                                            tableHeaderData={tableHeaderData}
                                            buildingBlock={dataBuildingBlocksCompanyOffices?.buildingBlocks}
                                            initialValueCompanies={initialValueCompanies}
                                            dataToBeApplications={dataToBeApplications}
                                        />
                                        <div className="flex">
                                            <PrimaryButton
                                                className="w-[170px] h-[50px] ml-[20px] flex items-center"
                                                isGrey
                                                handleClick={openEditAlertModal}
                                            >
                                                <div className="text-[18px] font-Lexend400">
                                                    {i18n.t('Close editing')}
                                                </div>
                                            </PrimaryButton>

                                            <PrimaryButton
                                                className="h-[50px] px-[32px] ml-[20px] flex items-center min-w-[170px]"
                                                isPink
                                                handleClick={handleSubmit}
                                                disabled={isSubmitting}
                                            >
                                                <div className="text-[18px] font-Lexend400">{i18n.t('Save')}</div>
                                            </PrimaryButton>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <AggregationOfYearViewTable
                                            tableHeaderData={tableHeaderData}
                                            dataToBeApplications={dataToBeApplications}
                                        />
                                        {isEditablePagesProject && (
                                            <PrimaryButton
                                                className="w-[170px] h-[50px] ml-[60px] flex items-center"
                                                isGrey
                                                handleClick={() => setIsEditMode(true)}
                                            >
                                                <EditIcon />
                                                <div className="ml-[5px]">{t('Edit')}</div>
                                            </PrimaryButton>
                                        )}
                                    </>
                                )}
                            </div>
                        )}
                    </Form>
                )}
            </Formik>
            <WarningModal
                isOpen={isShowCloseEditAlertModal}
                setIsOpen={setIsShowCloseEditAlertModal}
                warningText={t('Are you sure you want to close editing mode? Your result will not be saved')}
                acceptButtonText={t('Close')}
                declineButtonText={t('Cancel')}
                accept={acceptOffEditMode}
                decline={declineOffEditMode}
            />
        </div>
    );
};

export default ToBeApplicationsPage;
