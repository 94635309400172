import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import BackButton from '../components/ModulesCheckout/BackButton';
import { i18PAGES } from '../config/i18n/constants';
import ModulesCheckoutTableHeader from '../components/ModulesCheckout/ModulesCheckoutTableHeader';
import EmptyModulesCheckoutBlock from '../components/ModulesCheckout/EmptyModulesCheckoutBlock';
import AddedModulesList from '../components/ModulesCheckout/AddedModulesList';
import CartCheck from '../components/ModulesCheckout/cartCheck';
import { useUserModulesCartsQuery } from '../store/API/modulesAPI';
import { LayoutLoader } from '../components/Preloader/Preloader';

const ModulesCheckoutPage = () => {
    const { t } = useTranslation(i18PAGES.MODULES);
    const { data, isLoading } = useUserModulesCartsQuery();

    const navigate = useNavigate();

    const navigateBack = useCallback(() => {
        navigate('/modules');
    }, [navigate]);

    if (isLoading) {
        return <LayoutLoader />;
    }

    return (
        <div className="w-[1020px] m-auto mt-[52px] text-int-white-main">
            <BackButton navigateBack={navigateBack} />
            {data?.userModules?.length > 0 ? (
                <>
                    <div className="bg-int-gray-main rounded-[8px] mt-[30px] p-[30px]">
                        <div className="mb-[24px] text-[24px] leading-[24px] font-Lexend500">{t('Modules')}</div>
                        <ModulesCheckoutTableHeader />
                        <AddedModulesList userModules={data.userModules} />
                    </div>
                    <CartCheck cartTotal={data.cartTotal} />
                </>
            ) : (
                <EmptyModulesCheckoutBlock handleClick={navigateBack} />
            )}
        </div>
    );
};

export default ModulesCheckoutPage;
