import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { func, bool } from 'prop-types';
import { ReactComponent as ArrowUpIcon } from '../../assets/icons/up-arrow.svg';
import { ReactComponent as ArrowBottomIcon } from '../../assets/icons/bottom-arrow.svg';
import { i18PAGES } from '../../config/i18n/constants';
import useOutsideAlerter from '../util/useOutsideAlerter';

const SubscriptionSelectDropdown = ({
    handleSelectAll,
    handleNonSelectAll,
    disableAllItem = false,
    disableNotAllItem = false,
}) => {
    const { t } = useTranslation(i18PAGES.SUBSCRIPTION_MANAGEMENT);
    const [isOpen, setIsOpen] = useState(false);

    const dropdownToggle = () => {
        setIsOpen((prev) => !prev);
    };

    const container = useRef(null);
    useOutsideAlerter({ ref: container, callback: () => setIsOpen(false) });

    return (
        <div ref={container} className="relative">
            <div
                className="text-[14px] h-[24px] leading-[14px] font-Lexend400 cursor-pointer flex items-center pl-[35px]"
                onClick={() => dropdownToggle()}
            >
                <div className="mr-[13px]">{t('Select')}</div>
                {isOpen ? (
                    <ArrowUpIcon fill="#EDF1FD" width={8} height={5} />
                ) : (
                    <ArrowBottomIcon fill="#EDF1FD" width={8} height={5} />
                )}
            </div>
            {isOpen && (
                <div className="text-[16px] leading-[16px] font-Lexend400 bg-[#2A3143] rounded-lg absolute overflow-hidden top-[25px] right-[-14px] shadow-dropdown">
                    <div
                        className={`h-[42px] pl-[30px] pr-[40px] flex items-center ${
                            disableAllItem
                                ? 'text-int-white-secondary cursor-default'
                                : 'cursor-pointer hover:bg-[#474E62] text-int-white-main'
                        } `}
                        onClick={
                            disableAllItem
                                ? null
                                : () => {
                                      handleSelectAll();
                                      setIsOpen(false);
                                  }
                        }
                    >
                        {t('All')}
                    </div>
                    <div
                        className={`h-[42px] pl-[30px] pr-[40px] flex items-center ${
                            disableNotAllItem
                                ? 'text-int-white-secondary cursor-default'
                                : 'cursor-pointer hover:bg-[#474E62] text-int-white-main'
                        } `}
                        onClick={
                            disableNotAllItem
                                ? null
                                : () => {
                                      handleNonSelectAll();
                                      setIsOpen(false);
                                  }
                        }
                    >
                        {t('None')}
                    </div>
                </div>
            )}
        </div>
    );
};

export default SubscriptionSelectDropdown;

SubscriptionSelectDropdown.propTypes = {
    handleSelectAll: func.isRequired,
    handleNonSelectAll: func.isRequired,
    disableAllItem: bool,
    disableNotAllItem: bool,
};
