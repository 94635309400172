import React, { useState } from 'react';
import { bool, number, object, func } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ReactComponent as UserManagementIcon } from '../../../assets/icons/userManagementIcon.svg';
import { i18PAGES } from '../../../config/i18n/constants';
import ExternalUsersLabels from './ExternalUsersLabels';
import ExternalTableItem from './ExternalTableItem';
import ExtrenalUserDelete from './ExtrenalUserDelete';
import ExternalUserEdit from './ExternalUserEdit';

const ExternalUsersTable = ({
    externalUsers,
    chosenProjectId,
    isChosenProjectArchived,
    handleSetSortParam,
    handleDefaultSort,
}) => {
    const { t } = useTranslation(i18PAGES.PROJECTS_MANAGEMENT);

    const [isOpenEditUserModal, setIsOpenEditUserModal] = useState(false);
    const [editUserModalData, setEditUserModalData] = useState(null);

    const [isOpenDeleteUserModal, setIsOpenDeleteUserModal] = useState(false);
    const [deleteUserModalData, setDeleteUserModalData] = useState(null);

    const handleClickEditUser = (user) => {
        setIsOpenEditUserModal(true);
        setEditUserModalData(user);
    };

    const handleClickDeleteUser = (user) => {
        setIsOpenDeleteUserModal(true);
        setDeleteUserModalData(user);
    };

    return (
        <div>
            <div className="w-[958px] flex flex-col items-center rounded-lg mb-[20px]">
                <ExternalUsersLabels handleSetSortParam={handleSetSortParam} handleDefaultSort={handleDefaultSort} />

                {externalUsers?.content?.length > 0 &&
                    externalUsers?.content?.map((u) => (
                        <ExternalTableItem
                            key={u.id}
                            handleClickEditUser={handleClickEditUser}
                            handleClickDeleteUser={handleClickDeleteUser}
                            user={u}
                            isChosenProjectArchived={isChosenProjectArchived}
                        />
                    ))}

                {externalUsers?.content?.length === 0 && (
                    <>
                        <div className="mt-[30px] mb-[12px]">
                            <UserManagementIcon />
                        </div>
                        <div className="text-[14px] leading-[16px] font-Lexend300 text-int-white-secondary">
                            {t('There are no users in the list. Please add new users.')}
                        </div>
                    </>
                )}
            </div>
            {!isChosenProjectArchived && (
                <>
                    <ExtrenalUserDelete
                        isOpenDeleteUserModal={isOpenDeleteUserModal}
                        setIsOpenDeleteUserModal={setIsOpenDeleteUserModal}
                        id={deleteUserModalData?.id}
                        fullName={deleteUserModalData?.fullName}
                        chosenProjectId={chosenProjectId}
                    />

                    <ExternalUserEdit
                        setIsOpenEditUserModal={setIsOpenEditUserModal}
                        isOpenEditUserModal={isOpenEditUserModal}
                        id={editUserModalData?.id}
                        fullName={editUserModalData?.fullName}
                        email={editUserModalData?.email}
                        userRole={editUserModalData?.userRole}
                        access={editUserModalData?.access}
                        chosenProjectId={chosenProjectId}
                    />
                </>
            )}
        </div>
    );
};

export default ExternalUsersTable;

ExternalUsersTable.propTypes = {
    externalUsers: object.isRequired,
    chosenProjectId: number,
    isChosenProjectArchived: bool,
    handleSetSortParam: func,
    handleDefaultSort: func,
};
