import React from 'react';
import { arrayOf, func, number, oneOf, shape, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import ModulesCardButton from './ModulesCardButton';
import { i18PAGES } from '../../config/i18n/constants';

const ModulesCard = ({ id, title, description, buildingBlocks, type, handleClick, price }) => {
    const { t } = useTranslation(i18PAGES.MODULES);

    return (
        <div className="w-[calc(50%-8px)] h-[338px] bg-int-gray-main rounded-lg mb-[16px] px-[16px] py-[24px]">
            <div
                className={`flex flex-row break-words justify-between ${
                    title?.length < 30 ? 'items-center' : 'items-start'
                } mb-[16px]`}
            >
                <div className="text-[18px] text-int-white-main font-Lexend600 max-w-[280px]">{title}</div>
                <ModulesCardButton type={type} handleClick={() => handleClick({ moduleId: id, price }, type)} />
            </div>
            <div id="primary-scroll" className="overflow-auto h-[231px]">
                <div className="mb-[16px] text-[14px] text-int-white-main font-Lexend300 leading-[24px]">
                    {description}
                </div>
                <div className="text-[14px] text-int-white-main font-Lexend300 mt-[16px] mb-[8px]">
                    {t('You will get:')}
                </div>
                <div className="text-[14px] text-int-white-main font-Lexend500">
                    {buildingBlocks.map((item, index) => (
                        <li key={index} className="pl-[8px]">
                            {item.name}
                        </li>
                    ))}
                </div>
            </div>
        </div>
    );
};

ModulesCard.propTypes = {
    id: number.isRequired,
    title: string.isRequired,
    description: string.isRequired,
    buildingBlocks: arrayOf(
        shape({
            name: string,
            id: number,
        })
    ).isRequired,
    type: oneOf(['INIT', 'ADDED', 'BOUGHT']).isRequired,
    handleClick: func,
    price: number,
};

export default ModulesCard;
