import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { number, bool } from 'prop-types';
import { PrimaryButton } from '../../style/Button/Button';

const NotFilledProject = ({ projectId, isEditLink = false }) => {
    const { t } = useTranslation();

    return (
        <div className="flex items-center justify-center h-[100%]">
            <div>
                <div className="text-[20px] leading-[20px]">{t('This project is not completed yet')}</div>
                <Link
                    to={`/${isEditLink ? 'edit-project' : 'create-project'}?projectId=${projectId}`}
                    className=" mt-[30px] flex justify-center"
                >
                    <PrimaryButton className="w-[230px] h-[42px] font-Lexend400" isPink>
                        {t('Complete')}
                    </PrimaryButton>
                </Link>
            </div>
        </div>
    );
};

export default NotFilledProject;

NotFilledProject.propTypes = {
    projectId: number,
    isEditLink: bool,
};
