import React from 'react';
import { Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { object, string } from 'yup';
import { useTranslation } from 'react-i18next';
import FInput from '../style/Input/Input';
import { PrimaryButton } from '../style/Button/Button';
import FormLayout from '../layouts/FormLayout';
import { i18PAGES } from '../config/i18n/constants';
import { useResendResetPasswordMutation } from '../store/API/authAPI';

const validationSchema = object({
    email: string().required('Enter your Email').email('Invalid email'),
});

const ForgotPasswordPage = () => {
    const navigate = useNavigate();

    const { t } = useTranslation(i18PAGES.AUTH);

    const [resendResetPassword] = useResendResetPasswordMutation();

    return (
        <div className="min-h-[calc(100vh-300px)] h-[100%] flex items-center justify-center">
            <FormLayout
                subText="Bitte geben Sie die Ihrem Konto zugeordnete E-Mail-Adresse ein, um Anweisungen zum Zurücksetzen des Passworts zu erhalten"
                mainText="Setze Dein Passwort Zurück"
                width={500}
            >
                <Formik
                    onSubmit={(values, { setErrors, setSubmitting }) => {
                        resendResetPassword({
                            email: values.email,
                        })
                            .unwrap()
                            .then(() => {
                                navigate('/email-sent/password-reset', { state: { email: values.email } });
                                localStorage.setItem('startResendRegistrationEmailTime', 'true');
                            })
                            .catch(() => {
                                setErrors({ email: 'Check your email address is correct' });
                            })
                            .finally(() => {
                                setSubmitting(false);
                            });
                    }}
                    validationSchema={validationSchema}
                    initialValues={{
                        email: '',
                    }}
                >
                    {({
                        values,
                        touched,
                        errors,
                        isSubmitting,
                        handleSubmit,
                        handleBlur,
                        handleChange,
                        setFieldValue,
                    }) => (
                        <Form onSubmit={handleSubmit}>
                            <FInput
                                name="email"
                                placeholder="beispiel@email.com "
                                value={values.email}
                                handleBlur={handleBlur}
                                handleChange={handleChange}
                                className="w-[440px] mt-[40px]"
                                type="text"
                                label="Email Adresse"
                                isError={!!(touched.email && errors.email)}
                                errorText={errors.email}
                                setFieldValue={setFieldValue}
                            />

                            <PrimaryButton
                                type="submit"
                                className="mt-[50px] h-[42px] w-[100%]"
                                isPink
                                disabled={isSubmitting}
                            >
                                {t('Ein neues Passwort anfordern')}
                            </PrimaryButton>
                        </Form>
                    )}
                </Formik>
            </FormLayout>
        </div>
    );
};
export default ForgotPasswordPage;
