import React from 'react';
import { func } from 'prop-types';
import { ReactComponent as ArrowLeft } from '../../../assets/icons/medium-arrow-left.svg';

const LeftArrow = (props) => {
    const { onLeftArrowClick } = props;
    return (
        <div
            onClick={onLeftArrowClick}
            className="cursor-pointer w-[36px] h-[58px] bg-[#1A222E] flex items-center justify-center"
        >
            <ArrowLeft fill="#9BA1B2" />
        </div>
    );
};

export default LeftArrow;

LeftArrow.propTypes = {
    onLeftArrowClick: func.isRequired,
};
