import { ReactComponent as MenuCompaniesIcon } from '../../assets/icons/menuCompaniesIcon.svg';
import { ReactComponent as MenuAsIsApplicationsIcon } from '../../assets/icons/menuAsIsApplicationsIcon.svg';
import { ReactComponent as MenuToBeApplicationsIcon } from '../../assets/icons/menuToBeApplicationsIcon.svg';
import { ReactComponent as MenuApplicationRequirementsIcon } from '../../assets/icons/menuApplicationRequirementsIcon.svg';
import { ReactComponent as MenuAggregationOfYearsOfImplementationIcon } from '../../assets/icons/menuAggregationOfYearsOfImplementationIcon.svg';
import { ReactComponent as MenuAsIsIntegrationsIcon } from '../../assets/icons/menuAsIsIntegrationsIcon.svg';
import { ReactComponent as MenuToBeIntegrationsIcon } from '../../assets/icons/menuToBeIntegrationsIcon.svg';
import { ReactComponent as MenuModulesStrategyIcon } from '../../assets/icons/menuModulesStrategyIcon.svg';
import { ReactComponent as MenuWorkersInformationIcon } from '../../assets/icons/menuWorkersInformationIcon.svg';
import { ReactComponent as MenuFTEProcessIcon } from '../../assets/icons/menuFTEProcessIcon.svg';
import { ReactComponent as MenuSelectProviderIcon } from '../../assets/icons/menuSelectProviderIcon.svg';
import { ReactComponent as MenuOptionsIcon } from '../../assets/icons/menuOptionsIcon.svg';
import { ReactComponent as MenuFinancialComparisonIcon } from '../../assets/icons/menuFinancialComparisonIcon.svg';
import { ReactComponent as MenuModulesEfficienciesIcon } from '../../assets/icons/menuModulesEfficienciesIcon.svg';
import { ReactComponent as MenuEfficienciesComparisonIcon } from '../../assets/icons/menuEfficienciesComparisonIcon.svg';
import { ReactComponent as MenuPresentationIcon } from '../../assets/icons/menuPresentationIcon.svg';

export const MENU_ICON = [
    { enumName: 'COMPANIES', icon: <MenuCompaniesIcon width={16} height={15} fill="#EDF1FD" /> },
    { enumName: 'AS_IS_APPLICATIONS', icon: <MenuAsIsApplicationsIcon width={12} height={12} fill="#EDF1FD" /> },
    { enumName: 'TO_BE_APPLICATIONS', icon: <MenuToBeApplicationsIcon width={18} height={18} fill="#EDF1FD" /> },
    {
        enumName: 'APPLICATION_REQUIREMENTS',
        icon: <MenuApplicationRequirementsIcon width={18} height={18} fill="#EDF1FD" />,
    },
    {
        enumName: 'AGGREGATION_OF_YEARS_OF_IMPLEMENTATION',
        icon: <MenuAggregationOfYearsOfImplementationIcon width={15} height={12} fill="#EDF1FD" />,
    },
    { enumName: 'AS_IS_INTEGRATIONS', icon: <MenuAsIsIntegrationsIcon width={18} height={18} fill="#EDF1FD" /> },
    { enumName: 'TO_BE_INTEGRATIONS', icon: <MenuToBeIntegrationsIcon width={16} height={16} fill="#EDF1FD" /> },
    { enumName: 'MODULES_AND_STRATEGY', icon: <MenuModulesStrategyIcon width={12} height={12} fill="#EDF1FD" /> },
    { enumName: 'WORKERS_INFORMATION', icon: <MenuWorkersInformationIcon width={16} height={12} fill="#EDF1FD" /> },
    { enumName: 'FTE_PROCESS', icon: <MenuFTEProcessIcon width={16} height={4} fill="#EDF1FD" /> },
    { enumName: 'SELECT_PROVIDER', icon: <MenuSelectProviderIcon width={14} height={14} fill="#EDF1FD" /> },
    { enumName: 'OPTIONS', icon: <MenuOptionsIcon width={13} height={12} fill="#EDF1FD" /> },
    {
        enumName: 'FINANCIAL_COMPARISON',
        icon: <MenuFinancialComparisonIcon width={16} height={16} fill="#EDF1FD" />,
    },
    {
        enumName: 'MODULES_EFFICIENCIES',
        icon: <MenuModulesEfficienciesIcon width={16} height={16} fill="#EDF1FD" />,
    },
    {
        enumName: 'EFFICIENCIES_COMPARISON',
        icon: <MenuEfficienciesComparisonIcon width={16} height={10} fill="#EDF1FD" />,
    },
    { enumName: 'PRESENTATION', icon: <MenuPresentationIcon width={18} height={12} fill="#EDF1FD" /> },
];
