import { string } from 'prop-types';

const HRcompareListItem = ({ title }) => (
    <div className="flex items-center mb-[16px]">
        <div className="w-[6px] h-[6px] rounded-full bg-int-grapefruit mr-[12px]" />
        <div className="text-[16px] font-Lexend300 text-int-white-main">{title}</div>
    </div>
);

export default HRcompareListItem;

HRcompareListItem.propTypes = {
    title: string.isRequired,
};
