import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { SETUP_PAGES, STEP_TYPE } from '../util/constants';
import StepLayout from '../SetupCompanies/StepLayout';
import ModalWindow from '../../style/ModalWindow/ModalWindow';
import ChangeStepInfoModalContent from '../SetupCompanies/ChangeStepInfoModalContent';
import { useOwnerUserListEditProjectQuery } from '../../store/API/profileAPI';
import { i18PAGES } from '../../config/i18n/constants';
import { generateKey } from '../util/generateKey';
import { useToast } from '../../hoc/toast/ToastProvider';
import { useEditProjectMutation } from '../../store/API/createProjectAPI';
import Step1FormProject from '../CreateProject/Step1CreateProject/Step1FormProject';
import { validationStep1ProjectSchema } from '../CreateProject/Step1CreateProject/validationStep1Project';

const Step1EditProject = ({
    setSetupCurrentPage,
    projectData,
    projectId,
    refetchProjectData,
    isLoadingProjectData,
    isDisabledFields,
}) => {
    const { t } = useTranslation(i18PAGES.PROJECTS_SETUP);
    const { errorToast } = useToast();

    const [isModalOpened, setIsModalOpened] = useState(false);
    const [isChangeAgree, setIsChangeAgree] = useState(false);
    const [tempUsers, setTempUsers] = useState([]);

    const { data: userList, isLoading: isLoadingUserList } = useOwnerUserListEditProjectQuery(
        { projectId },
        { refetchOnMountOrArgChange: true, skip: !projectId }
    );

    const [editProject] = useEditProjectMutation();

    const openModalChecking = useMemo(() => !isChangeAgree, [isChangeAgree]);

    const showModalOnFocusNewData = useCallback(() => {
        if (openModalChecking) {
            setIsModalOpened(true);
        } else {
            setIsModalOpened(false);
        }
    }, [openModalChecking]);

    const filteredNotSelectedUsers = useMemo(() => tempUsers?.filter((item) => item.isSelected === false), [tempUsers]);

    const setSelectedUserById = (id, isSelected) => {
        const changeTempUsers = tempUsers.map((user) => {
            if (user.value === id) {
                user.isSelected = isSelected;
            }
            return user;
        });
        setTempUsers(changeTempUsers);
    };

    useEffect(() => {
        const changeUserList = userList?.map((item, index) => {
            if (projectData?.managerIds?.includes(item.id)) {
                return { value: item.id, label: item.fullName, isSelected: true, key: generateKey(index) };
            }
            return { value: item.id, label: item.fullName, isSelected: false, key: generateKey(index) };
        });
        if (changeUserList !== undefined) {
            setTempUsers(changeUserList);
        }
    }, [userList, projectData]);

    return (
        <StepLayout currentStepNumber={1} height={0} type={STEP_TYPE.PROJECT}>
            <Formik
                onSubmit={(values, { setSubmitting }) => {
                    editProject({
                        id: projectId,
                        name: values.name,
                        description: values.description,
                        yearsInScope: values.yearsInScope,
                        managerIds: values.users.map((item) => item.value),
                    })
                        .unwrap()
                        .then(() => {
                            refetchProjectData().then(() => {
                                setSetupCurrentPage(SETUP_PAGES.STEP_2);
                            });
                        })
                        .catch(() => {
                            errorToast(t('Error create project!'));
                        })
                        .finally(() => {
                            setSubmitting(false);
                        });
                }}
                validationSchema={validationStep1ProjectSchema}
                initialValues={{
                    name: projectData?.name || '',
                    description: projectData.description || '',
                    yearsInScope: projectData.yearsInScope.toString() || '1',
                    users: tempUsers
                        ?.filter((item) => projectData?.managerIds?.includes(item.value))
                        .map((item) => ({ ...item, isSelected: true })),
                }}
                enableReinitialize
            >
                {({ values, touched, errors, isSubmitting, handleSubmit, handleBlur, handleChange, setFieldValue }) => (
                    <Step1FormProject
                        isLoading={isLoadingProjectData || isLoadingUserList}
                        values={values}
                        setFieldValue={setFieldValue}
                        isSubmitting={isSubmitting}
                        handleSubmit={handleSubmit}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        touched={touched}
                        errors={errors}
                        filteredNotSelectedUsers={filteredNotSelectedUsers}
                        openModalChecking={openModalChecking}
                        setIsModalOpened={setIsModalOpened}
                        setSelectedUserById={setSelectedUserById}
                        showModalOnFocusNewData={showModalOnFocusNewData}
                        isDisabledYearsInScope={isDisabledFields}
                    />
                )}
            </Formik>

            {isModalOpened && (
                <ModalWindow isOpen={isModalOpened} setIsOpen={setIsModalOpened}>
                    <ChangeStepInfoModalContent setIsAgree={setIsChangeAgree} setIsModalOpened={setIsModalOpened} />
                </ModalWindow>
            )}
        </StepLayout>
    );
};

export default Step1EditProject;

Step1EditProject.propTypes = {
    setSetupCurrentPage: PropTypes.func,
    projectData: PropTypes.object,
    projectId: PropTypes.string,
    refetchProjectData: PropTypes.func,
    isLoadingProjectData: PropTypes.bool,
    isDisabledFields: PropTypes.bool,
};
