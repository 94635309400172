import React from 'react';
import { node, number, string } from 'prop-types';

const FormLayout = ({ children, mainText, subText, width = 520 }) => (
    <div>
        <div
            style={{
                width: `${width}px`,
            }}
            className="bg-int-gray-main m-auto p-[30px] relative rounded-[20px]"
        >
            <div className="text-int-white-main font-Lexend500 text-[24px] leading-[24px] mb-[15px]">{mainText}</div>
            <div className="text-int-white-secondary leading-[22px] text-[14px] mb-[50px] w-[450px]">{subText}</div>
            <div>{children}</div>
        </div>
    </div>
);

export default FormLayout;

FormLayout.propTypes = {
    children: node.isRequired,
    mainText: string,
    subText: string,
    width: number,
};
