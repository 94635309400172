import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ModalIcon } from '../assets/icons/companyCreatedIcon.svg';
import ChooseList from '../components/ProjectsList/ChooseList';
import ProjectCompaniesList from '../components/ProjectsList/ProjectCompaniesList';
import ProjectListHeader from '../components/ProjectsList/ProjectListHeader';
import ProjectListInfo from '../components/ProjectsList/ProjectListInfo';
import { i18PAGES } from '../config/i18n/constants';
import { useUsersProjectsQuery } from '../store/API/projectInternalManagementAPI';
import { LayoutLoader } from '../components/Preloader/Preloader';
import EmptyProjectsDisplay from '../components/ProjectsList/EmptyProjectsDisplay';
import NotFilledProject from '../components/ProjectsList/NotFilledProject';
import { useToast } from '../hoc/toast/ToastProvider';
import { useAuth } from '../store/hooks/useAuth';
import { ROLES } from '../components/util/roleStatus';
import { useGetCompaniesQuery } from '../store/API/companiesAPI';
import { ReactComponent as AddHRCompareIcon } from '../assets/icons/addHRCompareIcon.svg';
import NotFoundPage from './NotFoundPage';
import { PROJECT_STATUS, projectManagersUserRoles } from '../components/util/constants';

const ProjectListPage = () => {
    const { t } = useTranslation(i18PAGES.PROJECT_LIST);
    const navigate = useNavigate();
    const { successToast } = useToast();
    const isNewProjectCreated = localStorage.getItem('isNewProjectCreated');
    const isProjectEditSuccess = localStorage.getItem('isProjectEditSuccess');
    const [searchParams, setSearchParams] = useSearchParams();
    const selectedProjectId = searchParams.get('projectId');

    const { profileData, projectRole } = useAuth();
    const isProjectManager = useMemo(() => projectRole === projectManagersUserRoles.ROLE_MANAGER, [projectRole]);
    const isAdminRole = useMemo(() => profileData?.userRoles?.includes(ROLES.ROLE_ADMIN), [profileData?.userRoles]);
    const {
        data: companiesList,
        isLoading,
        isFetching,
    } = useGetCompaniesQuery({}, { refetchOnMountOrArgChange: true, skip: !isAdminRole });

    const [chosenProjectId, setChosenProjectId] = useState(Number(selectedProjectId) || undefined);
    const [chosenCompanyId, setChosenCompanyId] = useState();
    const [isProjectCompleted, setIsProjectCompleted] = useState();

    useEffect(() => {
        if (isNewProjectCreated) {
            const timeout = setTimeout(() => {
                localStorage.removeItem('isNewProjectCreated');
                successToast(
                    t('Project was successfully created'),
                    t('It will be displayed on the homepage, you can add other people in it, edit data and modules'),
                    <ModalIcon />,
                    8000
                );
            }, 150);

            return () => clearTimeout(timeout);
        }
    }, [isNewProjectCreated, successToast, t]);

    useEffect(() => {
        if (isProjectEditSuccess) {
            const timeoutShowEditSuccess = setTimeout(() => {
                localStorage.removeItem('isProjectEditSuccess');
                successToast(t('Project changes were successfully saved'), '', '', 8000);
            }, 150);

            return () => clearTimeout(timeoutShowEditSuccess);
        }
    }, [isProjectEditSuccess, successToast, t]);

    const [sortParams, setSortParams] = useState('');

    const {
        data: projectsList,
        isLoading: isProjectsLoading,
        isFetching: isProjectsFetching,
        refetch: refetchProjectsList,
    } = useUsersProjectsQuery(
        { sortParams },
        {
            refetchOnMountOrArgChange: true,
        }
    );

    const currentProject = useMemo(() => {
        if (projectsList && chosenProjectId) {
            setChosenCompanyId(null);
            return projectsList.filter((p) => p.id === chosenProjectId)[0];
        }
    }, [chosenProjectId, projectsList]);

    const setProjectId = (projectId) => {
        setChosenProjectId(projectId);
        if (projectId !== chosenProjectId) {
            setIsProjectCompleted(undefined);
        }
    };

    const changeUrl = useCallback(
        (id) => {
            setSearchParams({ projectId: String(id) });
        },
        [setSearchParams]
    );

    useEffect(() => {
        if (currentProject) {
            setIsProjectCompleted(
                currentProject?.status === PROJECT_STATUS.COMPLETED ||
                    currentProject?.status === PROJECT_STATUS.SENT_TO_PROVIDERS
            );
        }
    }, [currentProject]);

    useEffect(() => {
        if (projectsList?.length && !chosenProjectId) {
            setChosenProjectId(projectsList[0].id);
            changeUrl(projectsList[0].id);
        }
    }, [projectsList, chosenProjectId, changeUrl]);

    useEffect(() => {
        if (currentProject?.companies?.length && !chosenCompanyId) {
            setChosenCompanyId(currentProject?.companies[0].id);
        }
    }, [currentProject?.companies, chosenCompanyId]);

    if (isProjectsLoading || isLoading || isFetching || isProjectsFetching) {
        return <LayoutLoader />;
    }

    if (projectsList && projectsList?.length === 0 && !isNewProjectCreated) {
        if (isAdminRole && companiesList?.length > 0) {
            return (
                <EmptyProjectsDisplay
                    buttonText={t('Setup a Project')}
                    description={t('Create your first project with one of created companies')}
                    icon={<AddHRCompareIcon />}
                    handleClick={() => navigate('/create-project')}
                />
            );
        }

        if (isAdminRole && companiesList?.length === 0) {
            return (
                <EmptyProjectsDisplay
                    buttonText={t('Setup a Company')}
                    description={t('Set up the company first to create a project')}
                    icon={<AddHRCompareIcon />}
                    handleClick={() => navigate('/setup-companies')}
                />
            );
        }
        return <EmptyProjectsDisplay description={t('You have no projects assigned')} icon={<AddHRCompareIcon />} />;
    }

    if (!currentProject && !isNewProjectCreated && projectsList?.length && chosenProjectId) {
        return <NotFoundPage isNavigate />;
    }

    return (
        <div className="px-[111px] m-auto mt-[30px] mb-[80px]">
            <ProjectListHeader />
            <div className="flex justify-between items-start mt-[40px]">
                <div className="bg-int-gray-main overflow-y-auto rounded-[8px] min-h-[300px] p-[30px] w-[1019px]">
                    {isProjectCompleted === true && (
                        <>
                            <ProjectListInfo
                                projectInfo={currentProject || {}}
                                projectId={chosenProjectId}
                                refetchProjectsList={refetchProjectsList}
                                isArchiveAvailable={isProjectManager || isAdminRole}
                            />

                            <ProjectCompaniesList
                                companiesList={currentProject?.companies || []}
                                chosenCompanyId={chosenCompanyId}
                                setChosenCompanyId={setChosenCompanyId}
                                projectId={chosenProjectId}
                            />
                        </>
                    )}

                    {isProjectCompleted === false && (
                        <div className="h-[490px] flex items-center justify-center">
                            <NotFilledProject
                                projectId={chosenProjectId}
                                isEditLink={currentProject?.status === PROJECT_STATUS.EDITED}
                            />
                        </div>
                    )}
                </div>

                <ChooseList
                    chosenId={chosenProjectId}
                    setChosenId={setProjectId}
                    data={projectsList}
                    name="Projects"
                    changeUrl={changeUrl}
                    maxHeight="max-h-[470px]"
                    type="project"
                    handleDefaultSortClick={() => setSortParams('')}
                    handleAZSortClick={() => setSortParams('name,ASC')}
                    handleZASortClick={() => setSortParams('name,DESC')}
                    handleNewToOldSortClick={() => setSortParams('createdDate,DESC')}
                    handleOldToNewSortClick={() => setSortParams('createdDate,ASC')}
                />
            </div>
        </div>
    );
};

export default ProjectListPage;
