import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { arrayOf, bool, func, number, shape, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import ProjectInfoPageHeader from '../../Common/ProjectInfoPageHeader';
import { PAGE_IDENTIFIER } from '../../../util/constants';
import { i18PAGES } from '../../../../config/i18n/constants';
import OptionsDescriptionAnButtonsBlock from './OptionsDescriptionAnButtonsBlock';
import OptionsEmptyState from './OptionsEmptyState';
import VariationCard from './VariationCard';

const OptionsVariations = (props) => {
    const { t } = useTranslation(i18PAGES.OPTIONS);
    const { onOpenGeneratingVariations, variationsData, oneEditVariationClick, isGenerateButtonDisabled } = props;

    const [searchParams] = useSearchParams();
    const projectId = searchParams.get('projectId');

    return (
        <>
            <ProjectInfoPageHeader
                pageIdentifier={PAGE_IDENTIFIER.OPTIONS}
                projectId={Number(projectId)}
                pageName={t('Options variations')}
            />
            <OptionsDescriptionAnButtonsBlock
                onOpenGeneratingVariations={onOpenGeneratingVariations}
                isGenerateButtonDisabled={isGenerateButtonDisabled}
            />

            {variationsData?.length > 0 ? (
                variationsData?.map((variationData, index) => (
                    <VariationCard
                        key={index}
                        data={variationData}
                        variationIndex={index}
                        oneEditVariationClick={oneEditVariationClick}
                    />
                ))
            ) : (
                <OptionsEmptyState
                    text={
                        isGenerateButtonDisabled
                            ? t('To start generate variations you need to select providers first on the previous step')
                            : t(
                                  'You can now start generating variations of options by selecting the button “Generate variations of options”'
                              )
                    }
                />
            )}
        </>
    );
};

export default OptionsVariations;

OptionsVariations.propTypes = {
    onOpenGeneratingVariations: func.isRequired,
    variationsData: arrayOf(
        shape({
            variationId: number,
            options: arrayOf(
                arrayOf(
                    shape({
                        buildingBlockName: string,
                        buildingBlockId: number,
                        providers: arrayOf(
                            shape({
                                providerId: number,
                                providerName: string,
                                active: bool,
                            })
                        ),
                    })
                )
            ).isRequired,
        }).isRequired
    ),
    oneEditVariationClick: func,
    isGenerateButtonDisabled: bool,
};
