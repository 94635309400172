import React from 'react';
import { useTranslation } from 'react-i18next';
import { object } from 'prop-types';
import { i18PAGES } from '../../config/i18n/constants';
import OnlyViewFieldInput from '../Profile/OnlyViewFieldInput';

const PersonalInformationReadOnly = (props) => {
    const { t } = useTranslation(i18PAGES.QUESTIONNAIRE);
    const { data, projectDropdownsDefaultNames } = props;

    return (
        <>
            <div className="mt-[40px] font-Lexend500 text-[18px] leading-[26px] text-int-white-main">
                {t('Personal information')}
            </div>
            <div className="mt-[50px] flex items-center">
                <OnlyViewFieldInput label="Role" className="w-[320px] mr-[24px]" value={data?.role} />

                <OnlyViewFieldInput label="FTE contracted" className="w-[320px] mr-[24px]" value={data?.fte} />
            </div>

            <div className="mt-[50px] flex items-center">
                <OnlyViewFieldInput
                    label="Company"
                    className="w-[320px] mr-[24px]"
                    value={projectDropdownsDefaultNames?.companyName || ''}
                />

                <OnlyViewFieldInput
                    label="Country"
                    className="w-[320px] mr-[24px]"
                    value={projectDropdownsDefaultNames?.countryName || ''}
                />

                <OnlyViewFieldInput
                    label="City"
                    className="w-[320px] mr-[24px]"
                    value={projectDropdownsDefaultNames?.cityName || ''}
                />

                <OnlyViewFieldInput
                    label="Office"
                    className="w-[320px] mr-[24px]"
                    value={projectDropdownsDefaultNames?.officeName || ''}
                />
            </div>
        </>
    );
};

export default PersonalInformationReadOnly;

PersonalInformationReadOnly.propTypes = {
    data: object,
    projectDropdownsDefaultNames: object,
};
