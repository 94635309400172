import React from 'react';
import { arrayOf, bool, number, object, shape, string } from 'prop-types';
import AggregationOfYearViewTableItem from './ToBeApplicationsViewTableItem';
import AggregationOfYearClearTable from './ToBeApplicationsClearTable';
import TableHeader from '../../../../style/Table/TableHeader';

const AggregationOfYearViewTable = ({ tableHeaderData, dataToBeApplications }) => (
    <table className="table-fixed">
        <TableHeader data={tableHeaderData} isReadMode />
        <tbody className="bg-int-gray-secondary text-[16px] font-Lexend400 text-int-white-main">
            {dataToBeApplications?.length > 0 ? (
                dataToBeApplications?.map((item, index) => (
                    <AggregationOfYearViewTableItem
                        buildingBlockName={item.buildingBlock.name}
                        foundedYear={item.yearOfImplementation}
                        companies={item.officeDetails}
                        key={index}
                    />
                ))
            ) : (
                <AggregationOfYearClearTable />
            )}
        </tbody>
    </table>
);

export default AggregationOfYearViewTable;

AggregationOfYearViewTable.propTypes = {
    tableHeaderData: arrayOf(
        shape({
            minWidth: number,
            name: string,
            isSortable: bool,
            queryName: string,
        })
    ),
    dataToBeApplications: arrayOf(object),
};
