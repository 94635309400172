import React, { useState } from 'react';
import { bool, func, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ToolsFavoriteLabelIcon } from '../../assets/icons/toolsFavoriteLabelIcon.svg';
import { ReactComponent as AddToolsFavoriteIcon } from '../../assets/icons/addToolsFavoriteIcon.svg';
import { ReactComponent as InfoToolsFavoriteIcon } from '../../assets/icons/infoToolsFavoriteIcon.svg';
import { i18PAGES } from '../../config/i18n/constants';

const HRToolsCard = ({ image, title, description, handleClickFavorite, handleClickInfo, isFavorite = false }) => {
    const { t } = useTranslation(i18PAGES.HR_TOOLS);

    const [isShowButtonLayer, setIsShowButtonLayer] = useState(false);

    return (
        <div className="bg-int-gray-main rounded-lg min-h-[338px] max-h-[306px] min-w-[260px]  overflow-hidden relative">
            {isFavorite && (
                <div className="absolute right-[16px]">
                    <ToolsFavoriteLabelIcon />
                </div>
            )}
            {isShowButtonLayer && (
                <div
                    className="flex flex-col justify-center items-center h-[160px] w-[260px] absolute"
                    style={{
                        background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7))',
                    }}
                    onMouseLeave={() => setIsShowButtonLayer(false)}
                >
                    <div
                        className="flex justify-center items-center bg-int-grapefruit
                         rounded-lg w-[158px] h-[44px] text-[16px] font-Lexend600
                          text-int-white-main cursor-pointer mb-[16px]"
                        onClick={handleClickFavorite}
                        aria-hidden="true"
                    >
                        <div className="mr-[10px]">
                            <AddToolsFavoriteIcon />
                        </div>
                        {t('Vergleichen')}
                    </div>

                    <div
                        className="flex justify-center items-center border border-[#EDF1FD]
                         rounded-lg w-[158px] h-[44px] text-[16px] font-Lexend600
                          text-int-white-main cursor-pointer"
                        onClick={handleClickInfo}
                        aria-hidden="true"
                    >
                        <div className="mr-[8px]">
                            <InfoToolsFavoriteIcon />
                        </div>
                        {t('Einzelheiten')}
                    </div>
                </div>
            )}
            <div
                className="h-[160px] w-[260px]"
                style={{ backgroundImage: `url(${image})` }}
                onMouseEnter={() => setIsShowButtonLayer(true)}
            />
            <div className="flex flex-col p-[16px] min-h-0 h-[174px]">
                <div className="text-[16px] leading-[26px] font-Lexend600 text-int-white-main mb-[16px]">{title}</div>
                <div
                    className="flex-grow-1 text-[16px] pr-[4px] leading-[24px] font-Lexend300 text-int-white-secondary overflow-auto mr-[-12px] min-h-0"
                    id="primary-scroll"
                >
                    {description}
                </div>
            </div>
        </div>
    );
};

HRToolsCard.propTypes = {
    image: string.isRequired,
    title: string.isRequired,
    description: string.isRequired,
    handleClickFavorite: func,
    handleClickInfo: func,
    isFavorite: bool,
};

export default HRToolsCard;
