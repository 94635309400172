import React, { useEffect, useRef, useState } from 'react';
import { arrayOf, func, object, string, bool } from 'prop-types';
import { Form, Formik } from 'formik';
import useOutsideAlerter from '../../../../util/useOutsideAlerter';
import { ReactComponent as ArrowBottomIcon } from '../../../../../assets/icons/bottom-arrow.svg';
import { ReactComponent as ArrowUpIcon } from '../../../../../assets/icons/up-arrow.svg';
import Checkbox from '../../../../../style/Checkbox/Checkbox';

export default function FTEFilterCheckboxDropdown({
    name,
    placeholder,
    className,
    optionsList,
    isResetForm,
    setIsResetForm,
    data,
    setData,
}) {
    const container = useRef(null);
    const [open, setOpen] = useState(false);
    useOutsideAlerter({ ref: container, callback: () => setOpen(false) });

    const formikRef = useRef();

    useEffect(() => {
        if (setIsResetForm) {
            formikRef.current.resetForm();
            setIsResetForm(false);
        }
    }, [isResetForm, setIsResetForm]);

    useEffect(() => {
        data?.forEach((i, index) => {
            formikRef.current.setFieldValue(`${index}.isChecked`, i.isChecked);
        });
    }, [data]);

    return (
        <Formik
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(false);
                setData(values);
            }}
            initialValues={optionsList?.map((item) => ({ ...item, isChecked: false }))}
            enableReinitialize
            innerRef={formikRef}
        >
            {({ values, handleSubmit }) => (
                <Form>
                    <div className={`relative ${className || ''}`} ref={container}>
                        <input
                            type="text"
                            name={name}
                            onClick={() => setOpen((prev) => !prev)}
                            placeholder=""
                            className={`border ${
                                open ? 'border-int-white-main' : 'border-[#2A3143]'
                            } cursor-pointer bg-[#2A3143] rounded-[4px] px-[8px] h-[38px] w-[100%]`}
                            aria-hidden="true"
                            autoComplete="off"
                            style={{
                                caretColor: 'transparent',
                                cursor: 'pointer',
                            }}
                            readOnly
                        />
                        {open && (
                            <div
                                className="absolute top-[calc(100%+8px)] bg-[#2A3143] w-full text-int-white-main text-[14px]
                 font-Lexend400 rounded z-10 max-h-[272px] pt-[8px] pb-[8px] pl-[16px] shadow-dropdown"
                            >
                                <div className="overflow-y-auto max-h-[240px] pr-[20px]" id="primary-scroll">
                                    {values?.map((item, index) => (
                                        <Checkbox
                                            key={item.id}
                                            id={`${index}.isChecked`}
                                            name={`${index}.isChecked`}
                                            label={item.name}
                                            value={values[index].isChecked}
                                            handleClick={handleSubmit}
                                            short
                                            centerTextCheckbox={false}
                                        />
                                    ))}
                                </div>
                            </div>
                        )}
                        <div className="pointer-events-none absolute right-[10px] top-[16px] flex items-center px-2">
                            {open ? <ArrowUpIcon fill="#EDF1FD" /> : <ArrowBottomIcon fill="#EDF1FD" />}
                        </div>
                        <div
                            className="absolute top-[6px] ml-[16px] cursor-pointer text-[14px] font-Lexend400"
                            onClick={() => setOpen((prev) => !prev)}
                        >
                            <div>
                                {placeholder}{' '}
                                {values.filter((item) => item.isChecked === true).length > 0 && (
                                    <span>({values?.filter((item) => item.isChecked === true).length})</span>
                                )}
                            </div>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    );
}

FTEFilterCheckboxDropdown.propTypes = {
    name: string,
    placeholder: string,
    className: string,
    optionsList: arrayOf(object),
    isResetForm: bool,
    setIsResetForm: func,
    data: arrayOf(object),
    setData: func,
};
