import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { arrayOf, bool, number, shape, string } from 'prop-types';
import { PrimaryButton } from '../../style/Button/Button';
import { i18PAGES } from '../../config/i18n/constants';

const BoughtModulesList = ({ modulesList }) => {
    const { t } = useTranslation(i18PAGES.COMPANIES_LIST);

    return (
        <div className="mt-[30px] text-int-white-secondary bg-int-gray-main p-[30px] rounded-[8px]">
            <div className="mt-[10px] text-[24px] leading-[30px] font-Lexend500">{t('Modules bought')}</div>

            <div className="mt-[10px]  text-int-white-main p-[20px]">
                {modulesList?.map((mod, index) => (
                    <div className="flex flex-col" key={index}>
                        <div className="text-[18px] leading-[18px] text-int-white-main font-Lexend600 mb-[20px]">
                            {mod.title}
                        </div>
                        <div className="pl-[20px] grid grid-cols-3 gap-y-[20px]">
                            {mod.buildingBlocks?.map((block, blockIndex) => (
                                <div className="flex items-start" key={blockIndex}>
                                    <div className="min-w-[6px] min-h-[6px] bg-int-grapefruit rounded-full mr-[8px] mt-[10px]" />
                                    <div className="text-[16px] leading-[24px] text-int-white-main font-Lexend300">
                                        {block.name}
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="h-[1px] bg-int-gray-secondary mt-[20px] mb-[20px]" />
                    </div>
                ))}
            </div>
            {(modulesList?.length === 0 || !modulesList) && (
                <div className="text-int-white-secondary font-Lexend400 leading-[30px] text-center mt-[30px]">
                    {t('You have no any bought modules')}
                </div>
            )}
            <div className="flex justify-end">
                <Link to="/modules">
                    <PrimaryButton className="w-[170px] h-[50px] font-Lexend400 border-none" isGrey>
                        {t('Buy more')}
                    </PrimaryButton>
                </Link>
            </div>
        </div>
    );
};

export default BoughtModulesList;

BoughtModulesList.propTypes = {
    modulesList: arrayOf(
        shape({
            moduleId: number.isRequired,
            title: string.isRequired,
            description: string.isRequired,
            buildingBlocks: arrayOf(
                shape({
                    id: number.isRequired,
                    name: string.isRequired,
                })
            ),
            bought: bool,
        })
    ),
};
