import React from 'react';
import { object, number, func, arrayOf } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { i18PAGES } from '../../../config/i18n/constants';
import Checkbox from '../../../style/Checkbox/Checkbox';
import { DATE_FORMAT_TYPE, formatDateFromIsoString } from '../../util/dateFormatter';
import { SUBSCRIPTION_STATUS } from '../../util/constants';
import SubscriptionDateHelper from './SubscriptionDateHelper';

const CurrentSubscriptionsItem = ({ index, length, handleRenew, openTerminateModal, values }) => {
    const { t } = useTranslation(i18PAGES.SUBSCRIPTION_MANAGEMENT);

    const isTerminated = values[index].status === SUBSCRIPTION_STATUS.TERMINATED;

    return (
        <div
            className={`flex items-center h-[50px] text-[14px] leading-[18px] font-Lexend500 text-int-white-main border-b-[1px] ${
                length - 1 === index ? 'border-none' : 'border-int-gray-secondary'
            } `}
        >
            <div className="w-[260px] px-[11px]">
                <Checkbox
                    id={`${index}.isChecked`}
                    name={`${index}.isChecked`}
                    label={values[index].moduleName}
                    value={values[index].isChecked}
                />
            </div>
            <div className="w-[160px] px-[11px]">
                {formatDateFromIsoString(values[index].startDate, DATE_FORMAT_TYPE.DAY_MONTH_YEAR_SLASH)}
            </div>
            <div className="w-[160px] px-[11px]">
                {formatDateFromIsoString(values[index].endDate, DATE_FORMAT_TYPE.DAY_MONTH_YEAR_SLASH)}
            </div>
            <div className="w-[190px] px-[11px]">
                {isTerminated ? (
                    <div>{t('Terminated')}</div>
                ) : (
                    <SubscriptionDateHelper isoDate={values[index].endDate} />
                )}
            </div>
            <div className="w-[190px] px-[11px] text-center flex justify-center">
                <div
                    className="mr-[20px] underline cursor-pointer"
                    onClick={() => {
                        handleRenew([values[index].subscriptionId]);
                    }}
                >
                    {t('Renew')}
                </div>
                <div
                    className={` ${
                        isTerminated
                            ? 'text-int-gray-secondary cursor-default'
                            : 'text-int-grapefruit underline cursor-pointer'
                    }`}
                    onClick={
                        isTerminated
                            ? null
                            : () => {
                                  openTerminateModal(values[index].subscriptionId);
                              }
                    }
                >
                    {t('Terminate')}
                </div>
            </div>
        </div>
    );
};

export default CurrentSubscriptionsItem;

CurrentSubscriptionsItem.propTypes = {
    index: number,
    length: number,
    handleRenew: func,
    openTerminateModal: func,
    values: arrayOf(object),
};
