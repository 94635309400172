import React from 'react';
import { array, func, number, string } from 'prop-types';
import { stringShorter } from '../util/stringShorter';
import { ReactComponent as AddToArchiveIcon } from '../../assets/icons/addToArchiveIcon.svg';
import ProjectManagementUserSortBlock from '../ProjectsManagement/projectManagementUserSortBlock';

const ChooseList = ({
    chosenId,
    setChosenId,
    data,
    name,
    maxHeight = 'max-h-[980px]',
    changeUrl,
    type,
    handleNewToOldSortClick,
    handleOldToNewSortClick,
    handleAZSortClick,
    handleZASortClick,
    handleDefaultSortClick,
}) => {
    const handleChoose = (id) => {
        setChosenId(id);
        if (changeUrl) {
            changeUrl(id);
        }
    };

    return (
        <div className="h-[100%] bg-int-gray-main rounded-[8px] w-[169px] p-[16px]">
            <div className="text-int-white-main text-[14px] leading-[24px] font-Lexend500 pb-[10px] border-b w-[100%] border-int-gray-secondary flex justify-between">
                <ProjectManagementUserSortBlock
                    isProjectDropdown
                    handleDefaultSortClick={handleDefaultSortClick}
                    handleAZSortClick={handleAZSortClick}
                    handleZASortClick={handleZASortClick}
                    handleNewToOldSortClick={handleNewToOldSortClick}
                    handleOldToNewSortClick={handleOldToNewSortClick}
                    title={name}
                />
            </div>

            <div id="primary-scroll" className={`${maxHeight} h-[100%] overflow-y-auto mt-[10px] mr-[-16px] pr-[16px]`}>
                {data?.length > 0 &&
                    data?.map((item, index) => (
                        <div
                            className={` ${
                                index !== 0 && 'mt-[16px]'
                            } flex items-center justify-between font-Lexend500 text-[14px] leading-[24px] capitalize cursor-pointer ${
                                chosenId === item?.id ? 'text-int-white-main' : 'text-int-white-secondary'
                            }`}
                            onClick={() => handleChoose(item.id)}
                            key={item?.id}
                        >
                            <div>{stringShorter(item?.name, 13)}</div>
                            {/* todo isArchive from backend , ist just example */}
                            {type === 'project' && item?.archived === true && (
                                <div className="w-[16px] h-[14px]">
                                    <AddToArchiveIcon width={16} height={14} fill="#EDF1FD" className="ml-[5px]" />
                                </div>
                            )}
                        </div>
                    ))}
            </div>
        </div>
    );
};

export default ChooseList;

ChooseList.propTypes = {
    chosenId: number,
    name: string.isRequired,
    setChosenId: func.isRequired,
    data: array,
    maxHeight: string,
    changeUrl: func,
    type: string,
    handleNewToOldSortClick: func,
    handleOldToNewSortClick: func,
    handleAZSortClick: func,
    handleZASortClick: func,
    handleDefaultSortClick: func,
};
