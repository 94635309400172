import React, { useMemo, useState } from 'react';
import { bool, func, number } from 'prop-types';
import { FieldArray, Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { array, object, string } from 'yup';
import i18n from 'i18next';
import CloseWarning from '../../../UserManagement/CloseWarning';
import { PrimaryButton } from '../../../../style/Button/Button';
import ModalWindow from '../../../../style/ModalWindow/ModalWindow';
import { ReactComponent as AddUserIcon } from '../../../../assets/icons/addIcon.svg';
import { ReactComponent as SuccessAddExternalUserIcon } from '../../../../assets/icons/successAddExternalUserIcon.svg';
import { i18PAGES } from '../../../../config/i18n/constants';
import { generateKey } from '../../../util/generateKey';
import ExternalAddItem from './ExternalAddItem';
import { projectManagersUserRoles, REGEX } from '../../../util/constants';
import { useAddExternalUserMutation } from '../../../../store/API/projectExternalManagementAPI';
import { useGetPagesOptionsQuery } from '../../../../store/API/userPageAPI';
import Preloader from '../../../Preloader/Preloader';
import { formatAccessDataForApi } from '../formatAccessDataForApi';
import { errorEmailIsUsedStringToArrayEmails } from '../../../util/errorEmailIsUsedStringToArrayEmails';
import { useToast } from '../../../../hoc/toast/ToastProvider';

export const validationReady = string().test('check if ready', 'Pages should be select', (value, context) => {
    if (context.parent.pages !== undefined && context.parent.FullName !== '' && context.parent.email !== '') {
        return (
            [
                ...context.parent.pages
                    .filter((module) => module.data.length === 0)
                    .map((module) => ({
                        title: module.pageEnum.value,
                        availablePage: module.availablePage,
                    })),
                ...context.parent.pages
                    .filter((module) => module.data.length > 0)
                    .map((module) =>
                        module.dynamic === true
                            ? module.data.map((page) => ({
                                  title: page.companyName,
                                  availablePage: page.availableSubpage,
                              }))
                            : module.data.map((page) => ({
                                  title: page.value,
                                  availablePage: page.availableSubpage,
                              }))
                    )
                    .flat(),
            ].filter((module) => module.availablePage === true).length > 0
        );
    }
    return false;
});

const validationSchema = object().shape({
    userList: array().of(
        object().shape({
            fullName: string()
                .required('Full name should be stated')
                .matches(REGEX.STRING_ONLY, 'Invalid full name')
                .max(50, 'Max length is 50 symbols'),
            email: string().required('Email should be stated').email('Invalid email'),
            pages: array().of(object()),
            isReady: validationReady,
        })
    ),
});

const AddExternalUserModalForm = ({ isOpened, setIsOpened, projectId }) => {
    const { t } = useTranslation(i18PAGES.PROJECTS_MANAGEMENT);
    const { successToast, errorToast } = useToast();

    const [addExternalUser] = useAddExternalUserMutation();

    const {
        data: userPageList,
        isLoading: isLoadingUserPageList,
        isFetching: isFetchingUserPageList,
    } = useGetPagesOptionsQuery(projectId, {
        refetchOnMountOrArgChange: true,
        skip: !projectId,
    });

    const [isShowCloseWarning, setIsShowCloseWarning] = useState(false);

    const handleClickCancelModal = () => {
        if (isShowCloseWarning === false) {
            setIsShowCloseWarning(true);
        } else {
            setIsOpened(false);
            setIsShowCloseWarning(false);
        }
    };
    const pagesInitialValue = useMemo(
        () =>
            userPageList?.map((item) => ({
                ...item,
                availablePage: false,
                data: item?.data?.map((data) => ({ ...data, availableSubpage: false })),
            })),
        [userPageList]
    );

    return (
        <ModalWindow
            isOpen={isOpened}
            setIsOpen={setIsOpened}
            handleClickClose={handleClickCancelModal}
            className="w-[1124px]"
        >
            <div className="flex flex-col items-center">
                <div className="text-[24px] leading-[24px] font-Lexend500 text-int-white-main">
                    {t('Add External Users')}
                </div>
                {isLoadingUserPageList || isFetchingUserPageList ? (
                    <div className="flex justify-center items-center pb-[40px] pt-[40px]">
                        <Preloader />
                    </div>
                ) : (
                    <Formik
                        onSubmit={(values, { setSubmitting, setFieldError }) => {
                            const dataExternalUser = values.userList.map((user) => ({
                                fullName: user.fullName,
                                email: user.email,
                                role: user.permissions,
                                access: formatAccessDataForApi(user?.pages),
                            }));

                            addExternalUser({
                                projectId,
                                body: {
                                    externalUsers: dataExternalUser,
                                },
                            })
                                .unwrap()
                                .then(() => {
                                    successToast(
                                        i18n.t(
                                            'Invitations were sent to users. Wait for their logging into the system'
                                        ),
                                        '',
                                        <SuccessAddExternalUserIcon width={20} height={14} fill="#ffffff" />,
                                        6000
                                    );
                                    setIsOpened(false);
                                })
                                .catch((err) => {
                                    if (err.data.message.includes('Emails are already used:')) {
                                        errorToast(
                                            i18n.t(
                                                'This user name is already registered on the platform. You can add this user to the Internal User List'
                                            )
                                        );
                                        const errorArray = errorEmailIsUsedStringToArrayEmails(err.data.message);
                                        values.userList
                                            .map((user, index) => ({ index, ...user }))
                                            .filter((user) => errorArray.includes(user.email))
                                            .forEach((user) => {
                                                setFieldError(
                                                    `userList.${user.index}.email`,
                                                    'Email address already in use'
                                                );
                                            });
                                    } else {
                                        errorToast(err.data?.message);
                                    }
                                })
                                .finally(() => {
                                    setSubmitting(false);
                                });
                        }}
                        validationSchema={validationSchema}
                        initialValues={{
                            userList: [
                                {
                                    fullName: '',
                                    email: '',
                                    pages: pagesInitialValue,
                                    permissions: projectManagersUserRoles.ROLE_REVIEWER,
                                    key: 0,
                                },
                            ],
                        }}
                        enableReinitialize
                    >
                        {({
                            values,
                            touched,
                            errors,
                            setFieldValue,
                            handleSubmit,
                            handleBlur,
                            isSubmitting,
                            handleChange,
                        }) => (
                            <Form className="w-[100%] mt-[40px]">
                                <FieldArray
                                    autoСomplete="off"
                                    name="userList"
                                    render={(ArrayHelpers) => (
                                        <>
                                            <div
                                                className={` ${
                                                    values.userList?.length > 3 && 'overflow-y-auto'
                                                } pt-[20px] max-h-[500px] mr-[-20px] pr-[20px]`}
                                                id="primary-scroll"
                                            >
                                                {values?.userList?.map((item, index) => {
                                                    const usersErrors =
                                                        (errors.userList?.length && errors.userList[index]) || {};
                                                    const usersTouched =
                                                        (touched.userList?.length && touched.userList[index]) || {};

                                                    return (
                                                        <ExternalAddItem
                                                            key={item.key}
                                                            values={values}
                                                            handleBlur={handleBlur}
                                                            setFieldValue={setFieldValue}
                                                            index={index}
                                                            usersTouched={usersTouched}
                                                            usersErrors={usersErrors}
                                                            ArrayHelpers={ArrayHelpers}
                                                            handleChange={handleChange}
                                                        />
                                                    );
                                                })}
                                            </div>
                                            <div className="w-[100%] flex justify-start pt-[31px] pb-[41px] relative">
                                                {values.userList.length < 15 ? (
                                                    <div
                                                        className="flex items-center cursor-pointer"
                                                        onClick={() =>
                                                            ArrayHelpers.push({
                                                                fullName: '',
                                                                email: '',
                                                                pages: pagesInitialValue,
                                                                permissions: projectManagersUserRoles.ROLE_REVIEWER,
                                                                key: generateKey(values.userList.length - 1),
                                                            })
                                                        }
                                                    >
                                                        <div className="mr-[6px]">
                                                            <AddUserIcon width={16} height={16} fill="#9BA1B2" />
                                                        </div>
                                                        <div>{t('Add user')}</div>
                                                    </div>
                                                ) : (
                                                    <div className="h-[24px]" />
                                                )}

                                                {isShowCloseWarning && (
                                                    <CloseWarning
                                                        styles="w-[451px]"
                                                        handleCloseClick={() => setIsShowCloseWarning(false)}
                                                    />
                                                )}
                                            </div>
                                        </>
                                    )}
                                />
                                <div className="w-[100%] flex justify-between">
                                    <PrimaryButton
                                        isGrey
                                        className="w-[170px] h-[50px]"
                                        handleClick={handleClickCancelModal}
                                    >
                                        <div className="text-[18px] font-Lexend400">{t('Cancel')}</div>
                                    </PrimaryButton>
                                    <PrimaryButton
                                        isPink
                                        className="w-[170px] h-[50px]"
                                        handleClick={handleSubmit}
                                        disabled={isSubmitting}
                                    >
                                        <div className="text-[18px] font-Lexend400">{t('Add')}</div>
                                    </PrimaryButton>
                                </div>
                            </Form>
                        )}
                    </Formik>
                )}
            </div>
        </ModalWindow>
    );
};

export default React.memo(AddExternalUserModalForm);

AddExternalUserModalForm.propTypes = {
    isOpened: bool.isRequired,
    setIsOpened: func.isRequired,
    projectId: number.isRequired,
};
