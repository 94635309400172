import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { use } from 'i18next';
import ChooseList from '../components/ProjectsList/ChooseList';
import ProjectsManagementHeader from '../components/ProjectsManagement/ProjectsManagementHeader';
import BackButton from '../components/ProjectsManagement/BackButton';
import InternalUsersTable from '../components/ProjectsManagement/InternalUsersTable/InternalUsersTable';
import UsersPaginator from '../components/ProjectsManagement/UsersPaginator';
import InternalAddButtonContent from '../components/ProjectsManagement/InternalUsersTable/InternalUserAdd/InternalAddButtonContent';
import { i18PAGES } from '../config/i18n/constants';
import ExternalUsersTable from '../components/ProjectsManagement/ExternalUsersTable/ExternalUsersTable';
import { useInternalUsersQuery, useUsersProjectsQuery } from '../store/API/projectInternalManagementAPI';
import { LayoutLoader } from '../components/Preloader/Preloader';
import ExternalAddButtonContent from '../components/ProjectsManagement/ExternalUsersTable/ExternalUserAdd/ExternalAddButtonContent';
import { useExternalUsersQuery } from '../store/API/projectExternalManagementAPI';
import ProjectManagementTabSwitcher from '../components/ProjectsManagement/ProjectManagementTabSwitcher';
import { PROJECT_STATUS } from '../components/util/constants';

const ProjectsManagementPage = () => {
    const { t } = useTranslation(i18PAGES.PROJECTS_MANAGEMENT);

    const [searchParams, setSearchParams] = useSearchParams();
    const projectId = searchParams.get('projectId');
    const tabIdFromUrl = searchParams.get('tabId');
    const pageInternalFromUrl = searchParams.get('pageInternal');
    const pageExternalFromUrl = searchParams.get('pageExternal');
    const sortByInternalFromUrl = searchParams.get('sortByInternal');
    const orderInternalFromUrl = searchParams.get('orderInternal');
    const sortByExternalFromUrl = searchParams.get('sortByExternal');
    const orderExternalFromUrl = searchParams.get('orderExternal');

    const [internalActivePage, setInternalActivePage] = useState(Number(pageInternalFromUrl) || 1);
    const [externalActivePage, setExternalActivePage] = useState(Number(pageExternalFromUrl) || 1);

    const [isFirstLoadingPage, setIsFirstLoadingPage] = useState(true);

    useEffect(() => {
        setIsFirstLoadingPage(false);
    }, []);

    useEffect(() => {
        if (!isFirstLoadingPage) {
            setInternalActivePage(1);
            setExternalActivePage(1);
        }
    }, [projectId]);

    const handleSetSortParam = useCallback(
        (sortBy, sortByValue, order, orderValue) => {
            searchParams.set(sortBy, sortByValue);
            searchParams.set(order, orderValue);
            setSearchParams(searchParams);
        },
        [searchParams, setSearchParams]
    );

    const handleDefaultSort = useCallback(
        (sortBy, order) => {
            searchParams.delete(sortBy);
            searchParams.delete(order);
            setSearchParams(searchParams);
        },
        [searchParams, setSearchParams]
    );

    const [chosenProjectId, setChosenProjectId] = useState(Number(projectId) || null);
    const [sortParams, setSortParams] = useState('');

    const { data: projectsList, isLoading: isProjectsLoading } = useUsersProjectsQuery(
        { sortParams },
        { refetchOnMountOrArgChange: true }
    );
    const completedProjectList = useMemo(
        () =>
            projectsList?.filter(
                (p) => p.status === PROJECT_STATUS.COMPLETED || p.status === PROJECT_STATUS.SENT_TO_PROVIDERS
            ),
        [projectsList]
    );

    const isChosenProjectArchived = useMemo(
        () => completedProjectList?.filter((p) => p.id === chosenProjectId)[0]?.archived,
        [chosenProjectId, completedProjectList]
    );

    useEffect(() => {
        if (completedProjectList?.length && !chosenProjectId) {
            setChosenProjectId(completedProjectList[0].id);
        }
    }, [chosenProjectId, completedProjectList]);

    useEffect(() => {
        searchParams.set('pageInternal', String(internalActivePage));
        searchParams.set('pageExternal', String(externalActivePage));
        searchParams.set('tabId', String(tabIdFromUrl || 0));
        setSearchParams(searchParams);
    }, [searchParams, setSearchParams, internalActivePage, externalActivePage, tabIdFromUrl]);

    const setTabId = (id) => {
        searchParams.set('tabId', String(id));
        setSearchParams(searchParams);
    };

    const TabOptions = useMemo(
        () => [
            { id: 0, name: t('Internal Users') },
            { id: 1, name: t('External Users') },
        ],
        [t]
    );

    const changeUrl = useCallback(
        (id) => {
            setSearchParams({ projectId: String(id) });
        },
        [setSearchParams]
    );

    const { data: internalUsers, isLoading: isInternalUsersLoading } = useInternalUsersQuery(
        {
            projectId: chosenProjectId,
            page: internalActivePage - 1,
            sortBy: sortByInternalFromUrl,
            order: orderInternalFromUrl,
        },
        {
            refetchOnMountOrArgChange: true,
            skip: !chosenProjectId,
        }
    );

    const { data: externalUsers, isLoading: isExternalUsersLoading } = useExternalUsersQuery(
        {
            projectId: chosenProjectId,
            page: externalActivePage - 1,
            sortBy: sortByExternalFromUrl,
            order: orderExternalFromUrl,
        },
        {
            refetchOnMountOrArgChange: true,
            skip: !chosenProjectId,
        }
    );

    const projectListHeight = useMemo(() => {
        if (internalUsers?.content?.length === 8) {
            return 'max-h-[639px]';
        }
        if (internalUsers?.content?.length === 7) {
            return 'max-h-[582px]';
        }
        if (internalUsers?.content?.length === 6) {
            return 'max-h-[525px]';
        }
        return 'max-h-[513px]';
    }, [internalUsers?.content?.length]);

    if (isProjectsLoading || isInternalUsersLoading || isExternalUsersLoading) {
        return <LayoutLoader />;
    }

    return (
        <div className="px-[111px] m-auto mt-[30px] mb-[65px]">
            <ProjectsManagementHeader />

            <div className="mt-[40px]">
                <div className="flex justify-between items-start">
                    <div className="bg-int-gray-main overflow-y-auto rounded-[8px] min-h-[590px] p-[30px] pb-0 w-[1019px]">
                        <BackButton />

                        <div className="mt-[25px] text-[24px] leading-[24px] font-Lexend500 text-int-white-main mb-[33px]">
                            {t('User management')}
                        </div>
                        <div className="flex items-center justify-between mb-[24px] h-[42px]">
                            <ProjectManagementTabSwitcher
                                handleTabChoose={setTabId}
                                itemsArray={TabOptions}
                                chosenId={Number(tabIdFromUrl)}
                            />

                            {!isChosenProjectArchived && (
                                <>
                                    {Number(tabIdFromUrl) === 0 ? (
                                        <InternalAddButtonContent chosenProjectId={chosenProjectId} />
                                    ) : (
                                        <ExternalAddButtonContent chosenProjectId={chosenProjectId} />
                                    )}
                                </>
                            )}
                        </div>

                        {Number(tabIdFromUrl) === 0 ? (
                            <InternalUsersTable
                                users={internalUsers}
                                chosenProjectId={chosenProjectId}
                                isChosenProjectArchived={isChosenProjectArchived}
                                handleSetSortParam={handleSetSortParam}
                                handleDefaultSort={handleDefaultSort}
                            />
                        ) : (
                            <ExternalUsersTable
                                externalUsers={externalUsers}
                                chosenProjectId={chosenProjectId}
                                isChosenProjectArchived={isChosenProjectArchived}
                                handleSetSortParam={handleSetSortParam}
                                handleDefaultSort={handleDefaultSort}
                            />
                        )}
                    </div>

                    <ChooseList
                        chosenId={chosenProjectId}
                        setChosenId={setChosenProjectId}
                        data={completedProjectList}
                        name="Projects"
                        changeUrl={changeUrl}
                        maxHeight={projectListHeight}
                        type="project"
                        handleDefaultSortClick={() => setSortParams('')}
                        handleAZSortClick={() => setSortParams('name,ASC')}
                        handleZASortClick={() => setSortParams('name,DESC')}
                        handleNewToOldSortClick={() => setSortParams('createdDate,DESC')}
                        handleOldToNewSortClick={() => setSortParams('createdDate,ASC')}
                    />
                </div>
                <UsersPaginator
                    externalUsers={externalUsers}
                    internalUsers={internalUsers}
                    internalActivePage={internalActivePage}
                    externalActivePage={externalActivePage}
                    setInternalActivePage={setInternalActivePage}
                    setExternalActivePage={setExternalActivePage}
                    openedTab={Number(tabIdFromUrl)}
                />
            </div>
        </div>
    );
};

export default ProjectsManagementPage;
