import { useDispatch, useSelector } from 'react-redux';
import { setTabsMenu } from '../reducers/tabsMenuReducer';

export const useTabsMenu = () => {
    const dispatch = useDispatch();

    const tabsMenuData = useSelector((state) => state.tabsMenu.data);

    const _setTabsMenu = (payload) => {
        dispatch(setTabsMenu(payload));
    };

    return {
        tabsMenuData,
        setTabsMenu: _setTabsMenu,
    };
};
