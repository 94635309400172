import { array, object, string } from 'yup';
import { REGEX } from '../util/constants';
import { generateKey } from '../util/generateKey';

export const validationSchema = object({
    role: string().required('Role is required').max(50, 'Max length is 50').nullable(),
    companyId: string().required('Company should be selected').nullable(),
    fte: string()
        .required('Number should be stated')
        .matches(REGEX.DECIMAL, 'Invalid value')
        .test('Cant be zero only', 'Invalid value', (value) => value !== '0')
        .nullable(),
    countryId: string().required('Country should be selected').nullable(),
    cityId: string().required('City should be selected').nullable(),
    officeId: string().required('Office should be selected').nullable(),
    workingTimeDistributionDTOS: array().of(
        object().shape({
            buildingBlocks: array().of(
                object().shape({
                    workPercent: string()
                        .test('Empty or pass regex', 'Invalid value', (value) => !(value && !REGEX.DECIMAL.test(value)))
                        .nullable(),
                })
            ),
        })
    ),
});

export const emptyModuleDistributionItem = {
    moduleId: null,
    moduleName: '',
    buildingBlocks: [],
    key: generateKey(-1),
    isSaved: false,
};

export const checkIfPersonalInformationFilled = (values) =>
    !!values?.role &&
    !!values?.fullName &&
    !!values?.email &&
    !!values?.cityId &&
    !!values?.companyId &&
    !!values?.countryId &&
    !!values?.officeId;
