import React from 'react';
import { number, string } from 'prop-types';

const OfficeModuleItem = ({ name, count, className }) => (
    <div
        className={`flex px-[8px] py-[4px] rounded ${
            className || ''
        } text-int-white-main text-[14px] leading-[16px] font-Lexend300 tracking-[1px]`}
    >
        {name}
        <div className="ml-[5px] text-int-white-secondary">({count})</div>
    </div>
);

export default OfficeModuleItem;

OfficeModuleItem.propTypes = {
    name: string.isRequired,
    count: number.isRequired,
    className: string,
};
