import { useTranslation } from 'react-i18next';
import { string } from 'prop-types';
import { i18PAGES } from '../../../config/i18n/constants';

const SubscriptionDateHelper = ({ isoDate }) => {
    const { t } = useTranslation(i18PAGES.SUBSCRIPTION_MANAGEMENT);
    const date = new Date(isoDate);
    const currentDate = new Date();
    const mSecondsDifference = Math.abs(currentDate - date);
    const diffMinutes = mSecondsDifference / (1000 * 60);
    const diffHours = diffMinutes / 60;
    const diffDays = diffHours / 24;

    if (diffDays > 30) {
        return <div>{t('Active')}</div>;
    }
    if (diffDays <= 30 && diffDays >= 2) {
        return <div>{`${t('Expires in')} ${Math.round(diffDays)} ${t('days')}`}</div>;
    }
    if (diffDays < 2 && diffDays > 1) {
        return <div>{`${t('Expires in')} ${Math.floor(diffDays)} ${t('day')}`}</div>;
    }
    return <div>{t('Expires today')}</div>;
};

export default SubscriptionDateHelper;

SubscriptionDateHelper.propTypes = {
    isoDate: string,
};
