import React from 'react';
import { node } from 'prop-types';
import { useAuth } from '../store/hooks/useAuth';
import PlatformLayout from './Platform/PlatformLayout';
import ExternalLayout from './External/ExternalLayout';

const MainLayout = ({ children }) => {
    const { isAuth } = useAuth();

    if (isAuth) {
        return <PlatformLayout>{children}</PlatformLayout>;
    }

    return <ExternalLayout>{children}</ExternalLayout>;
};

export default MainLayout;

MainLayout.propTypes = {
    children: node,
};
