import http from '../../util/http';

export const downloadPdfInvoice = (id, name, error, setIsLoadingPdf) => {
    setIsLoadingPdf(true);
    http.get(`/download/invoices/${id}`, { responseType: 'blob' })
        .then((response) => {
            const hiddenElement = document.createElement('a');
            const url = window.URL || window.webkitURL;
            hiddenElement.href = url.createObjectURL(response.data);
            hiddenElement.target = '_blank';
            hiddenElement.download = `${name}.pdf`;
            hiddenElement.click();
        })
        .catch(() => {
            error();
        })
        .finally(() => {
            setIsLoadingPdf(false);
        });
};

export const downloadPdfInvoiceByToken = (token, name, error, setIsLoadingPdf) => {
    setIsLoadingPdf(true);
    http.get(`/download/invoices?key=${token}`, { responseType: 'blob' })
        .then((response) => {
            const hiddenElement = document.createElement('a');
            const url = window.URL || window.webkitURL;
            hiddenElement.href = url.createObjectURL(response.data);
            hiddenElement.target = '_blank';
            hiddenElement.download = `${name}.pdf`;
            hiddenElement.click();
        })
        .catch(() => {
            error();
        })
        .finally(() => {
            setIsLoadingPdf(false);
        });
};
