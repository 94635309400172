import React from 'react';
import { bool, func, node, string } from 'prop-types';
import { ReactComponent as CloseModalIcon } from '../../assets/icons/closeToastIcon.svg';

const CompaniesListToastModal = ({
    children,
    isOpen,
    className,
    isShowCloseIcon = true,
    handleCloseIconClick,
    isGreen,
}) => (
    <>
        {isOpen && (
            <div className="fixed top-[calc(theme(spacing.header-height)-10px)] left-[calc(50vw-321px)]">
                <div
                    className={`p-[10px] w-[643px] rounded-lg relative ${className || 'bg-[#5679F7]'}`}
                    style={
                        isGreen && {
                            background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), #47E273',
                        }
                    }
                >
                    {isShowCloseIcon && (
                        <div
                            className="w-[10px] h-[10px] cursor-pointer absolute top-[10px] right-[10px]"
                            onClick={handleCloseIconClick}
                            aria-hidden="true"
                        >
                            <CloseModalIcon />
                        </div>
                    )}

                    {children}
                </div>
            </div>
        )}
    </>
);

CompaniesListToastModal.propTypes = {
    children: node.isRequired,
    isOpen: bool.isRequired,
    className: string,
    isShowCloseIcon: bool,
    isGreen: bool,
    handleCloseIconClick: func,
};

export default CompaniesListToastModal;
