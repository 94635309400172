import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { arrayOf, bool, func, number, shape, string } from 'prop-types';
import i18n from 'i18next';
import { useSearchParams } from 'react-router-dom';
import ThreeDotsMenu from '../../../../style/ThreeDotsMenu/ThreeDotsMenu';
import { ReactComponent as ArrowBottomIcon } from '../../../../assets/icons/bottom-arrow.svg';
import { ReactComponent as ArrowRight } from '../../../../assets/icons/arrowRight.svg';
import { ReactComponent as ArrowUpIcon } from '../../../../assets/icons/up-arrow.svg';
import { i18PAGES } from '../../../../config/i18n/constants';
import WarningModal from '../../../../style/WarningModal/WarningModal';
import { useShowMenu } from '../../../../store/hooks/useShowMenu';
import { useDeleteVariationMutation } from '../../../../store/API/optionsAPI';
import { useToast } from '../../../../hoc/toast/ToastProvider';
import { downloadPresentation } from '../GenerateVariationsOfOptions/helper';
import { useAuth } from '../../../../store/hooks/useAuth';

const VariationCard = (props) => {
    const { t } = useTranslation(i18PAGES.OPTIONS);
    const { data, variationIndex, oneEditVariationClick } = props;
    const { showFullMenu } = useShowMenu();
    const [deleteVariant, { isLoading }] = useDeleteVariationMutation();
    const { errorToast } = useToast();
    const { isEditablePagesProject } = useAuth();

    const [searchParams] = useSearchParams();
    const projectId = searchParams.get('projectId');

    const [isDetailInfoOpened, setIsDetailedInfoOpened] = useState(false);
    const [isShowDeleteWindow, setIsShowDeleteWindow] = useState(false);

    const blockContent = useRef(null);
    const [width, setWidth] = useState(0);

    const onToggleDetailInfo = useCallback(() => {
        setIsDetailedInfoOpened((prev) => !prev);
    }, []);

    const onCloseDeleteWindow = useCallback(() => {
        setIsShowDeleteWindow(false);
    }, []);

    const onOpenDeleteWindow = useCallback(() => {
        setIsShowDeleteWindow(true);
    }, []);

    const onDeleteVariations = useCallback(() => {
        deleteVariant({ projectId, variationId: data?.variationId })
            .unwrap()
            .then(() => {
                onCloseDeleteWindow();
            })
            .catch(() => {
                errorToast(i18n.t('Something went wrong, try again later'));
            });
    }, [data?.variationId, deleteVariant, errorToast, onCloseDeleteWindow, projectId]);

    const menuOptions = useMemo(() => {
        if (isEditablePagesProject) {
            return [
                {
                    text: t('Download presentation'),
                    handleClick: () =>
                        downloadPresentation(projectId, data?.variationId, () =>
                            errorToast(i18n.t('Something went wrong, try again later'))
                        ),
                },
                { text: t('Delete variation'), handleClick: onOpenDeleteWindow },
            ];
        }

        return [
            {
                text: t('Download presentation'),
                handleClick: () =>
                    downloadPresentation(projectId, data?.variationId, () =>
                        errorToast(i18n.t('Something went wrong, try again later'))
                    ),
            },
        ];
    }, [data?.variationId, errorToast, isEditablePagesProject, onOpenDeleteWindow, projectId, t]);

    useEffect(() => {
        if (blockContent?.current?.offsetWidth < blockContent?.current?.scrollWidth) {
            setWidth(blockContent?.current?.scrollWidth);
        } else {
            setWidth(0);
        }
    }, [isDetailInfoOpened, data]);

    return (
        <div className="w-[100%] bg-[#1F2937] p-[30px] rounded-[8px] mb-[30px]">
            <div className="flex justify-between">
                <div>
                    <div className="font-Lexend400 text-[18px] leading-[18px] text-int-white-main">
                        {t('Variation')} {variationIndex + 1}
                    </div>
                    <div className="mt-[16px] font-Lexend300 text-[16px] leading-[24px] text-int-white-secondary">
                        {t(
                            'Die Ist-Situation und Systeme werden nicht verändert. Es finden keine Investitionen statt.'
                        )}
                    </div>
                    <div
                        className="mt-[30px] font-Lexend500 text-[16px] leading-[24px] text-int-white-main inline-flex items-center cursor-pointer"
                        onClick={onToggleDetailInfo}
                    >
                        {isDetailInfoOpened ? (
                            <>
                                <div className="mr-[13px]">{t('Hide detailed information')}</div>
                                <ArrowUpIcon width={8} height={5} fill="#EDF1FD" />
                            </>
                        ) : (
                            <>
                                <div className="mr-[13px]">{t('Show detailed information')}</div>
                                <ArrowBottomIcon fill="#EDF1FD" width={8} height={5} />
                            </>
                        )}
                    </div>
                </div>
                <div className="flex flex-col items-end">
                    <ThreeDotsMenu menuOptions={menuOptions} />
                    {isEditablePagesProject && (
                        <div
                            className="flex items-center justify-center px-[72px] h-[50px] bg-int-gray-secondary rounded-[8px] cursor-pointer"
                            onClick={() => oneEditVariationClick(data.variationId)}
                        >
                            <div className="mr-[15px] text-int-white-main font-Lexend400 text-[16px] leading-[16px]">
                                {t('Select')}
                            </div>
                            <ArrowRight />
                        </div>
                    )}
                </div>
            </div>

            {isDetailInfoOpened && (
                <div
                    className={`overflow-auto ${
                        showFullMenu ? 'max-w-[calc(100vw-460px)]' : 'max-w-[calc(100vw-215px)]'
                    } pb-[30px] mb-[-30px] pl-[30px] ml-[-30px]`}
                    id="bold-scroll"
                    ref={blockContent}
                >
                    {data?.options?.map((optionData, index) => (
                        <div className="mt-[20px] w-[100%]" key={index}>
                            <div>
                                <div className="text-[18px] leading-[18px] font-Lexend400 text-int-white-main">
                                    {t('Option')} {index + 1}
                                </div>
                                <div className="flex">
                                    {optionData?.map((o, ind) => (
                                        <div key={ind} className="mr-[130px]">
                                            <div className="font-Lexend500 text-[16px] leading-[24px] my-[20px] flex">
                                                {o.buildingBlockName.split(' ').map((word, ind) => (
                                                    <div className="mr-[5px]" key={ind}>
                                                        {word}
                                                    </div>
                                                ))}
                                            </div>
                                            <div>
                                                {o.providers?.map((p, i) => {
                                                    if (p.active) {
                                                        return (
                                                            <div className="mt-[20px] flex" key={i}>
                                                                {p.providerName.split(' ').map((word, ind) => (
                                                                    <div className="mr-[5px]" key={ind}>
                                                                        {word}
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        );
                                                    }
                                                    return <Fragment key={i} />;
                                                })}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            {index !== data?.options?.length - 1 && (
                                <div
                                    style={{
                                        width: width || '100%',
                                    }}
                                    className="w-[100%] mt-[20px] bg-int-gray-secondary h-[1px] "
                                />
                            )}
                        </div>
                    ))}
                </div>
            )}
            {isShowDeleteWindow && (
                <WarningModal
                    isOpen={isShowDeleteWindow}
                    setIsOpen={setIsShowDeleteWindow}
                    warningText={t(
                        'Are you sure you want to delete variation? All the information related to this variation will be lost'
                    )}
                    headerText={t('Delete variation')}
                    acceptButtonText={t('Delete')}
                    declineButtonText={t('Cancel')}
                    accept={onDeleteVariations}
                    decline={onCloseDeleteWindow}
                    isAcceptDisabled={isLoading}
                />
            )}
        </div>
    );
};

export default VariationCard;

VariationCard.propTypes = {
    data: shape({
        variationId: number,
        options: arrayOf(
            arrayOf(
                shape({
                    buildingBlockName: string,
                    buildingBlockId: number,
                    providers: arrayOf(
                        shape({
                            providerId: number,
                            providerName: string,
                            active: bool,
                        })
                    ),
                })
            )
        ).isRequired,
    }).isRequired,
    variationIndex: number.isRequired,
    oneEditVariationClick: func,
};
