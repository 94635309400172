import React, { useCallback, useState } from 'react';
import { array, func, number, object } from 'prop-types';
import SearchDropDown from '../../../../style/SearchDropDown/SearchDropDown';
import Checkbox from '../../../../style/Checkbox/Checkbox';
import { ReactComponent as DeleteIcon } from '../../../../assets/icons/userManagementBigDeleteIcon.svg';

const InternalAddItem = ({
    values,
    handleBlur,
    setFieldValue,
    index,
    options,
    usersErrors,
    usersTouched,
    setUsedUsers,
    ArrayHelpers,
}) => {
    const [chosenId, setChosenId] = useState(null);

    const deleteChosenId = useCallback(
        (deleteId) => {
            setUsedUsers((prev) => prev.filter((id) => id !== deleteId));
        },
        [setUsedUsers]
    );

    const onCurrentValueChange = useCallback(
        (id) => {
            if (chosenId) {
                deleteChosenId(chosenId);

                setUsedUsers((prev) => [...prev, id]);
                setChosenId(id);
            } else {
                setChosenId(id);
                setUsedUsers((prev) => [...prev, id]);
            }
        },
        [chosenId, deleteChosenId, setUsedUsers]
    );

    const changeHandler = useCallback(
        (opt) => {
            if (opt.value) {
                setFieldValue(`users.${index}.fullName`, opt.label);
                setFieldValue(`users.${index}.id`, opt.value);
                onCurrentValueChange(opt.value);
            } else {
                onCurrentValueChange(112);
            }
        },
        [index, onCurrentValueChange, setFieldValue]
    );

    const removeHandler = useCallback(() => {
        deleteChosenId(values.users[index].id);
        ArrayHelpers.remove(index);
    }, [ArrayHelpers, deleteChosenId, index, values.users]);

    return (
        <div className="z-[50]">
            <div className="flex justify-between items-center">
                <SearchDropDown
                    name={`users.${index}.fullName`}
                    placeholder="Select"
                    value={values.users[index].fullName}
                    handleBlur={handleBlur}
                    handleChange={changeHandler}
                    className="w-[400px]"
                    type="text"
                    label="Select user from list"
                    isError={!!(usersTouched.fullName && usersErrors.fullName)}
                    errorText={usersErrors.fullName}
                    options={options}
                    maxHeightDropDown={228}
                />

                {index > 0 ? (
                    <div
                        className="flex justify-center items-center w-[20px] h-[42px] mr-[12px] cursor-pointer"
                        onClick={removeHandler}
                    >
                        <DeleteIcon />
                    </div>
                ) : (
                    <div className="w-[20px] h-[42px]" />
                )}
            </div>

            <Checkbox
                name={`users.${index}.projectManager`}
                id={`users.${index}.projectManager`}
                label="Assign as Project Manager"
                className="text-[14px]"
                value={values.users[index].projectManager}
            />
        </div>
    );
};

export default React.memo(InternalAddItem);

InternalAddItem.propTypes = {
    values: object.isRequired,
    handleBlur: func.isRequired,
    setFieldValue: func.isRequired,
    index: number,
    options: array.isRequired,
    usersErrors: object.isRequired,
    usersTouched: object.isRequired,
    setUsedUsers: func.isRequired,
    ArrayHelpers: object.isRequired,
};
