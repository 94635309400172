import React, { useState } from 'react';
import { node, string } from 'prop-types';
import { ReactComponent as MinusIcon } from '../../assets/icons/minusIcon.svg';
import { ReactComponent as PlusIcon } from '../../assets/icons/plusIcon.svg';

const HRCompareInfoAccordion = ({ children, headerText }) => {
    const [isOpened, setIsOpened] = useState(false);

    const openToggle = () => {
        setIsOpened((prev) => !prev);
    };

    return (
        <div className="mb-[30px]">
            <div
                className="flex justify-between items-center
                text-[18px] font-Lexend600 text-int-white-main
                px-[20px] py-[12px] bg-int-gray-main rounded-[6px]
                cursor-pointer min-w-[1140px]"
                onClick={openToggle}
            >
                <div>{headerText}</div>
                {isOpened ? <MinusIcon /> : <PlusIcon />}
            </div>

            {isOpened && <div className="mt-[30px]">{children}</div>}
        </div>
    );
};

export default HRCompareInfoAccordion;

HRCompareInfoAccordion.propTypes = {
    children: node.isRequired,
    headerText: string.isRequired,
};
