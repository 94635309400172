import React, { useEffect, useState } from 'react';
import { FieldArray, Form, Formik } from 'formik';
import { func } from 'prop-types';
import { useTranslation } from 'react-i18next';
import StepLayout from './StepLayout';
import FInput from '../../style/Input/Input';
import SearchDropDown from '../../style/SearchDropDown/SearchDropDown';
import { countryOptions, SETUP_PAGES, STEP_TYPE } from '../util/constants';
import { useCompanySetup } from '../../store/hooks/useCompanySetup';
import { step2InitialValues, step2ValidationSchema } from './stepValidation';
import ModalWindow from '../../style/ModalWindow/ModalWindow';
import ChangeStepInfoModalContent from './ChangeStepInfoModalContent';
import { useCreateCountriesMutation } from '../../store/API/companiesAPI';
import http from '../util/http';
import { i18PAGES } from '../../config/i18n/constants';
import i18n from '../../config/i18n/i18n';
import { useToast } from '../../hoc/toast/ToastProvider';

const Step2Countries = ({ setSetupCurrentPage }) => {
    const { t } = useTranslation(i18PAGES.SETUP_COMPANIES);

    const { companySetupInfo, setCountries, clearCities, setCompanyTree } = useCompanySetup();
    const [createCountries] = useCreateCountriesMutation();
    const { errorToast } = useToast();

    const [isModalOpened, setIsModalOpened] = useState(false);
    const [isChangeAgree, setIsChangeAgree] = useState(false);

    const [isBackButtonPressed, setIsBackButtonPressed] = useState(false);
    const [isStepBackAgree, setIsStepBackAgree] = useState(false);

    const showModalOnFocusNewData = () => {
        if (companySetupInfo?.citiesInfo?.length > 0 && !isChangeAgree) {
            setIsModalOpened(true);
        } else {
            setIsModalOpened(false);
        }
    };

    const onBackButtonPress = (countries) => {
        setIsBackButtonPressed(true);
        setCountries(countries);
    };

    useEffect(() => {
        if (isStepBackAgree === true) {
            setSetupCurrentPage(SETUP_PAGES.STEP_1);
        }
    }, [isStepBackAgree, setSetupCurrentPage]);

    return (
        <StepLayout currentStepNumber={2} height={662} type={STEP_TYPE.COMPANIES}>
            <Formik
                onSubmit={async (values, { setSubmitting }) => {
                    if (
                        (companySetupInfo.companyTree?.countries[0]?.cities?.length && isChangeAgree) ||
                        !companySetupInfo.companyTree?.countries[0]?.cities?.length
                    ) {
                        await createCountries({
                            companyId: companySetupInfo?.companyTree.id,
                            countries: values.countries.map((c) => ({ ...c, countryId: c.countryId || null })),
                        })
                            .unwrap()
                            .then((data) => {
                                setCountries(data);

                                http.get(`/companies/${companySetupInfo.companyTree.id}`)
                                    .then((res) => {
                                        setCompanyTree(res.data);
                                        setSetupCurrentPage(SETUP_PAGES.STEP_3);
                                    })
                                    .catch((err) => {
                                        errorToast(err.response.data.message);
                                    })
                                    .finally(() => {
                                        setSubmitting(false);
                                    });
                            })
                            .catch((err) => {
                                errorToast(err.data.message);
                            });
                    } else {
                        setSubmitting(false);
                        setSetupCurrentPage(SETUP_PAGES.STEP_3);
                    }
                }}
                validationSchema={step2ValidationSchema}
                initialValues={{
                    countries: step2InitialValues(companySetupInfo).countries,
                }}
            >
                {({ values, touched, setFieldValue, errors, isSubmitting, handleSubmit, handleBlur, handleChange }) => (
                    <Form onSubmit={handleSubmit}>
                        <div className="flex justify-between items-center">
                            <div className="font-Lexend500 text-[24px] leading-[24px] text-int-white-main">
                                {t('Setup Countries')}
                            </div>
                            <div>
                                <button
                                    className="text-int-white-main border border-int-gray-secondary rounded-[8px] w-[140px] h-[42px] font-Lexend400"
                                    type="button"
                                    onClick={() =>
                                        onBackButtonPress({
                                            companyId: companySetupInfo.companyId,
                                            countries: values.countries.map((c) => ({
                                                ...c,
                                                countryId: null,
                                            })),
                                        })
                                    }
                                >
                                    {t('Go Back')}
                                </button>

                                <button
                                    className={`text-int-white-main border border-int-grapefruit bg-int-grapefruit ml-[20px] rounded-[8px] w-[140px] h-[42px] font-Lexend400 ${
                                        isSubmitting && 'opacity-[0.5]'
                                    }`}
                                    type="submit"
                                    disabled={isSubmitting}
                                >
                                    {t('Save & Next')}
                                </button>
                            </div>
                        </div>
                        <div className="text-[14px] leading-[22px] mt-[8px] mb-[20px]">
                            {t('All fields are required')}
                        </div>

                        <div id="primary-scroll" className="overflow-y-auto h-[500px] mr-[-20px]">
                            <FieldArray name="countries">
                                {() =>
                                    values.countries.map((item, index) => {
                                        const countriesErrors =
                                            (errors.countries?.length && errors.countries[index]) || {};

                                        const countriesTouched =
                                            (touched.countries?.length && touched.countries[index]) || {};

                                        return (
                                            <div className="mt-[30px]" key={index}>
                                                <div className="text-int-white-main font-Lexend400 text-[18px] leading-[16px]">
                                                    Country {index + 1}
                                                </div>

                                                <div className="mt-[40px] flex justify-between ">
                                                    <SearchDropDown
                                                        name={`countries.${index}.name`}
                                                        placeholder="Select"
                                                        value={values.countries[index].name}
                                                        isError={!!(countriesTouched.name && countriesErrors.name)}
                                                        errorText={countriesErrors.name}
                                                        className="w-[468px]"
                                                        options={
                                                            countryOptions?.filter(
                                                                (option) =>
                                                                    !values?.countries
                                                                        ?.map((c) => c.name)
                                                                        .includes(option.label)
                                                            ) || []
                                                        }
                                                        label="Select Country"
                                                        handleChange={(opt) =>
                                                            setFieldValue(`countries.${index}.name`, opt.value)
                                                        }
                                                        handleBlur={handleBlur}
                                                        handleFocus={showModalOnFocusNewData}
                                                    />

                                                    <FInput
                                                        name={`countries.${index}.citiesQuantity`}
                                                        placeholder="Enter cities quantity"
                                                        value={String(values.countries[index].citiesQuantity)}
                                                        handleBlur={handleBlur}
                                                        handleChange={handleChange}
                                                        handleFocus={showModalOnFocusNewData}
                                                        className="w-[468px] mr-[20px]"
                                                        type="text"
                                                        label="Quantity cities"
                                                        isError={
                                                            !!(
                                                                countriesTouched.citiesQuantity &&
                                                                countriesErrors.citiesQuantity
                                                            )
                                                        }
                                                        errorText={countriesErrors.citiesQuantity}
                                                        setFieldValue={setFieldValue}
                                                        limit={3}
                                                    />
                                                </div>
                                            </div>
                                        );
                                    })
                                }
                            </FieldArray>
                        </div>
                    </Form>
                )}
            </Formik>

            <ModalWindow isOpen={isModalOpened} setIsOpen={setIsModalOpened}>
                <ChangeStepInfoModalContent
                    setIsAgree={setIsChangeAgree}
                    setIsModalOpened={setIsModalOpened}
                    clearNextSteps={clearCities}
                />
            </ModalWindow>

            <ModalWindow isOpen={isBackButtonPressed} setIsOpen={setIsBackButtonPressed}>
                <ChangeStepInfoModalContent
                    setIsAgree={setIsStepBackAgree}
                    setIsModalOpened={setIsBackButtonPressed}
                    text={i18n.t('Are you sure you want to go back? Your current data can be lost')}
                />
            </ModalWindow>
        </StepLayout>
    );
};
export default Step2Countries;

Step2Countries.propTypes = {
    setSetupCurrentPage: func,
};
