import React, { useCallback, useEffect, useState } from 'react';
import { arrayOf, bool, func, number, oneOfType, shape, string } from 'prop-types';
import { FloatingFocusManager, FloatingPortal } from '@floating-ui/react';
import { ReactComponent as ArrowBottomIcon } from '../../../../assets/icons/bottom-arrow.svg';
import { ReactComponent as ArrowUpIcon } from '../../../../assets/icons/up-arrow.svg';
import { ReactComponent as ErrorIcon } from '../../../../assets/icons/errorIcon.svg';
import { useChangeDirectionDropdown } from '../../../../hooks/useChangeDirectionDropdown';

export default function TableSearchDropDown({
    options,
    placeholder,
    handleChange,
    handleBlur,
    name,
    isError,
    errorText,
    className,
    value,
    isSearch = true,
    handleFocus,
    maxHeightDropDown = 360,
    isChangeOnValue = false,
}) {
    const [selected, setSelected] = useState(value || '');
    const [isOpen, setIsOpen] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [optionsArr, setOptionsArr] = useState(options || []);
    const [isOpenHelpErrorInfo, setIsOpenHelpErrorInfo] = useState(false);

    const { getReferenceProps, getFloatingProps, getItemProps, refs, context, floatingStyles, onInputClick } =
        useChangeDirectionDropdown({
            isOpen,
            setIsOpen,
            maxHeightDropDown,
        });

    const onFocus = () => {
        if (handleFocus) {
            handleFocus();
        }
    };

    const handleClick = ({ label, value }) => {
        if (handleChange) {
            handleChange({ label, value });
        }
        setSelected(label);
        setSearchText('');
        setIsOpen(false);
    };

    const onSearch = useCallback(
        (e) => {
            setSearchText(e.target.value);
            setSelected('');
            if (handleChange) {
                handleChange({ label: '', value: '' });
            }
        },
        [handleChange]
    );

    useEffect(() => {
        if (isChangeOnValue) {
            setSelected(value);
        }
    }, [isChangeOnValue, value]);

    useEffect(() => {
        setOptionsArr(options);
    }, [options]);

    useEffect(() => {
        const arr = options.filter((o) => o.label.toLowerCase().includes(searchText.toLowerCase()));
        setOptionsArr(arr);
    }, [searchText, errorText, isError, options]);

    const onBlur = (e) => {
        if (!isOpen) {
            handleBlur(e);
        }
    };

    return (
        <div className={`relative ${className || ''}`}>
            <input
                {...getReferenceProps({
                    ref: refs.setReference,
                })}
                name={name}
                type="text"
                onClick={onInputClick}
                className={`border pr-[25px] ${
                    isError ? 'border-error' : `${isOpen ? 'border-int-white-main' : 'border-[#2A3143]'}`
                } 
                   cursor-pointer bg-[#2A3143] rounded-[4px] px-[8px] py-[12px] w-[100%]`}
                style={{
                    border: `${isError ? '1px solid #FF5F5F' : ''}`,
                    caretColor: `${!isSearch && 'transparent'}`,
                    cursor: `${!isSearch && 'pointer'}`,
                }}
                value={searchText || selected}
                placeholder={placeholder}
                onChange={isSearch ? onSearch : () => {}}
                onBlur={onBlur}
                onFocus={onFocus}
                role="presentation"
                autoComplete="off"
            />
            {isOpen && (
                <FloatingPortal>
                    <FloatingFocusManager context={context} modal={false}>
                        <ul
                            ref={refs.setFloating}
                            {...getFloatingProps()}
                            id="primary-scroll"
                            className="bg-[#2A3143] left-0 text-int-grey-40 rounded z-50 overflow-auto shadow-dropdown"
                            style={{ ...floatingStyles, outline: 'none' }}
                        >
                            {optionsArr &&
                                optionsArr.map(({ value, label, icon }, index) => (
                                    <li
                                        key={index}
                                        className="hover:bg-[#354053] py-[8px] px-[13px] cursor-pointer flex items-center text-int-white-main font-Lexend400 overflow-hidden"
                                        {...getItemProps({
                                            onClick() {
                                                handleClick({ value, label });
                                            },
                                        })}
                                    >
                                        {label}
                                    </li>
                                ))}
                        </ul>
                    </FloatingFocusManager>
                </FloatingPortal>
            )}
            <div className="pointer-events-none absolute right-0 top-[20px] flex items-center px-2">
                {isOpen ? <ArrowUpIcon fill="#EDF1FD" /> : <ArrowBottomIcon fill="#EDF1FD" />}
            </div>
            {isError && (
                <div className="absolute right-[25px] top-[14px]">
                    <div
                        className={errorText && 'cursor-pointer'}
                        onMouseEnter={() => setIsOpenHelpErrorInfo(true)}
                        onMouseLeave={() => setIsOpenHelpErrorInfo(false)}
                    >
                        <ErrorIcon />
                    </div>
                    {isOpenHelpErrorInfo && errorText && (
                        <div
                            className="text-[12px] text-error font-Lexend300 absolute bg-[#222730]
                         p-[10px] rounded-[2px] z-10 bottom-[23px] right-0 whitespace-nowrap"
                        >
                            {errorText}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

TableSearchDropDown.propTypes = {
    options: arrayOf(
        shape({
            value: oneOfType([string, number]).isRequired,
            label: string.isRequired,
            icon: string,
        })
    ).isRequired,
    placeholder: string,
    handleChange: func,
    name: string,
    isError: bool,
    isSearch: bool,
    errorText: string,
    className: string,
    handleBlur: func,
    handleFocus: func,
    value: oneOfType([string, number]),
    maxHeightDropDown: number,
    isChangeOnValue: bool,
};
