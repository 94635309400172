import React from 'react';
import { useTranslation } from 'react-i18next';
import { i18PAGES } from '../../../../config/i18n/constants';

const ActionsTableLabels = () => {
    const { t } = useTranslation(i18PAGES.MODULES_AND_STRATEGY);
    return (
        <div className="flex text-int-white-secondary font-Lexend400 text-[16px] leading-[16px] mb-[12px]">
            <div>{t('Action')}</div>
            <div className="ml-[330px]">{t('Description')}</div>
        </div>
    );
};

export default ActionsTableLabels;
