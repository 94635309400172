import React, { useEffect, useMemo, useState } from 'react';
import PropTypes, { shape } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { object, string } from 'yup';
import CompaniesCountryItem from './CompaniesCountryItem';
import { i18PAGES } from '../../config/i18n/constants';
import NotFilledCompany from './NotFilledCompany';
import { COMPANY_STATUS } from '../util/constants';
import { useSetCompanyNameByIdMutation } from '../../store/API/companiesAPI';
import FInput from '../../style/Input/Input';
import i18n from '../../config/i18n/i18n';
import { useToast } from '../../hoc/toast/ToastProvider';
import { PrimaryButton } from '../../style/Button/Button';

const validationSchema = object({
    name: string().required('Company name should be stated').max(50, 'Max length is 50 symbols'),
});

const CountriesList = ({ currentCompany }) => {
    const { t } = useTranslation(i18PAGES.COMPANIES_LIST);

    const [isEditMode, setIsEditMode] = useState(false);
    const [openedTabCityId, setOpenedTabCityId] = useState(undefined);
    const { successToast, errorToast } = useToast();

    const [setCompanyNameById] = useSetCompanyNameByIdMutation();

    useEffect(() => {
        setOpenedTabCityId(undefined);
        setIsEditMode(false);
    }, [currentCompany?.id]);

    const isCompanyFilled = useMemo(() => {
        if (currentCompany && currentCompany?.status) {
            return currentCompany?.status === COMPANY_STATUS.COMPLETED;
        }
        return undefined;
    }, [currentCompany]);

    const setOpenTabIndex = (index) => {
        setOpenedTabCityId(index);
    };

    return (
        <div className="bg-int-gray-main w-[100%] p-[30px] min-h-[590px] rounded-[8px]">
            <Formik
                onSubmit={(values, { setSubmitting }) => {
                    if (currentCompany?.name !== values?.name) {
                        setCompanyNameById({ companyId: currentCompany?.id, body: values })
                            .unwrap()
                            .then(() => {
                                setIsEditMode(false);
                                successToast(i18n.t('Company name were successfully updated'));
                            })
                            .catch(() => {
                                errorToast(i18n.t('Something went wrong with companies changes. Try again later'));
                            })
                            .finally(() => {
                                setSubmitting(false);
                            });
                    } else {
                        setIsEditMode(false);
                        setSubmitting(false);
                    }
                }}
                validationSchema={validationSchema}
                initialValues={{
                    name: currentCompany?.name,
                }}
                enableReinitialize
            >
                {({ values, touched, setFieldValue, errors, isSubmitting, handleSubmit, handleBlur, handleChange }) => (
                    <Form onSubmit={handleSubmit}>
                        <div className="mt-[10px] flex justify-between items-center">
                            {isEditMode ? (
                                <FInput
                                    name="name"
                                    placeholder={t('Enter company name')}
                                    value={values.name}
                                    handleBlur={handleBlur}
                                    handleChange={handleChange}
                                    className="w-[440px]"
                                    type="text"
                                    label=""
                                    isError={!!(touched.name && errors.name)}
                                    errorText={errors.name}
                                    setFieldValue={setFieldValue}
                                    limit={50}
                                />
                            ) : (
                                <div className="text-int-white-main font-Lexend500 text-[24px] leading-[24px] mr-[20px]">
                                    {currentCompany?.name}
                                </div>
                            )}

                            {isCompanyFilled === true && (
                                <>
                                    {isEditMode ? (
                                        <PrimaryButton
                                            className="h-[50px] font-Lexend400 w-[170px]"
                                            isPink
                                            disabled={isSubmitting}
                                            handleClick={() => {
                                                handleSubmit();
                                            }}
                                        >
                                            <div className="font-Lexend400">{t('Finish editing')}</div>
                                        </PrimaryButton>
                                    ) : (
                                        <PrimaryButton
                                            className="h-[50px] font-Lexend400 w-[170px]"
                                            isGrey
                                            disabled={isSubmitting}
                                            handleClick={() => {
                                                setIsEditMode(true);
                                            }}
                                        >
                                            <div className="font-Lexend400">{t('Edit')}</div>
                                        </PrimaryButton>
                                    )}
                                </>
                            )}
                        </div>
                    </Form>
                )}
            </Formik>

            <div id="primary-scroll" className="mt-[15px] overflow-y-auto mr-[-20px] pr-[20px]">
                {isCompanyFilled === true && (
                    <>
                        {currentCompany?.countries.length > 0 &&
                            currentCompany?.countries?.map((country, index) => (
                                <div key={index}>
                                    {country.cities.map((city, i) => (
                                        <div key={city.id}>
                                            <CompaniesCountryItem
                                                countryName={country.name}
                                                cityItem={city}
                                                isEditMode={isEditMode}
                                                companyChanged={currentCompany?.id}
                                                setOpenTabIndex={setOpenTabIndex}
                                                openedTabCityId={openedTabCityId}
                                            />
                                        </div>
                                    ))}
                                </div>
                            ))}
                    </>
                )}
                {isCompanyFilled === false && (
                    <div className="h-[462px]">
                        <NotFilledCompany companyId={currentCompany?.id} />
                    </div>
                )}
            </div>
        </div>
    );
};

export default CountriesList;

CountriesList.propTypes = {
    currentCompany: shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        countriesQuantity: PropTypes.number.isRequired,
        countries: PropTypes.array,
    }),
};
