import React, { useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import UserConfirmationChoice from '../components/ConfirmTransaction/UserConfirmation/UserConfirmationChoice';
import { CONFIRMATION_STEPS } from '../components/ConfirmTransaction/constants';
import UserConfirmationCanceled from '../components/ConfirmTransaction/UserConfirmation/UserConfirmationCanceled';
import UserConfirmationSuccess from '../components/ConfirmTransaction/UserConfirmation/UserConfirmationSuccess';
import { useGetInvoiceInfoQuery, useGetNotCompletedUserInvoiceQuery } from '../store/API/invoiceAPI';
import { LayoutLoader } from '../components/Preloader/Preloader';
import FailedConfirmation from '../components/Confirmation/FailedConfirmation';

const UserConfirmTransactionsPage = () => {
    const [searchParams] = useSearchParams();
    const key = searchParams.get('key');
    const { t } = useTranslation();

    const [userConfirmationStep, setUserConfirmationStep] = useState(CONFIRMATION_STEPS.CHOICE_SCREEN);
    const { data, isLoading, isError } = useGetInvoiceInfoQuery(key, { refetchOnMountOrArgChange: true, skip: !key });

    const {
        data: notCompletedUserInvoiceData,
        isLoading: isLoadingNotCompletedUserInvoice,
        isError: isErrorNotCompletedUserInvoice,
    } = useGetNotCompletedUserInvoiceQuery({}, { refetchOnMountOrArgChange: true, skip: key });

    const invoiceData = useMemo(() => {
        if (notCompletedUserInvoiceData) {
            return {
                invoiceNumber: notCompletedUserInvoiceData?.invoiceNumber,
                invoiceTotalAmount: notCompletedUserInvoiceData?.subscriptionAmount,
            };
        }

        if (data) {
            return {
                invoiceNumber: data?.invoiceNumber,
                invoiceTotalAmount: data?.invoiceTotalAmount,
            };
        }
    }, [data, notCompletedUserInvoiceData]);

    if (isLoading || isLoadingNotCompletedUserInvoice) {
        return <LayoutLoader />;
    }

    if (isError) {
        return (
            <div className="min-h-[calc(100vh-300px)] h-[100%] flex items-center justify-center">
                <FailedConfirmation
                    buttonText={t('Go to Homepage')}
                    linkPath="/"
                    text={t('Token is expired or not valid')}
                />
            </div>
        );
    }

    if (isErrorNotCompletedUserInvoice) {
        return (
            <div className="min-h-[calc(100vh-300px)] h-[100%] flex items-center justify-center">
                <FailedConfirmation
                    buttonText={t('Go to Homepage')}
                    linkPath="/"
                    text={t("Can't get your invoice, try again later")}
                />
            </div>
        );
    }

    return (
        <div className="min-h-[calc(100vh-300px)] h-[100%] flex items-center justify-center">
            <div className="py-[100px]">
                <div className="bg-int-gray-main m-auto w-[500px] p-[30px] relative rounded-[20px]">
                    {userConfirmationStep === CONFIRMATION_STEPS.CHOICE_SCREEN && (
                        <UserConfirmationChoice
                            tokenKey={key}
                            invoiceData={invoiceData}
                            setUserConfirmationStep={setUserConfirmationStep}
                        />
                    )}
                    {userConfirmationStep === CONFIRMATION_STEPS.DECLINED_SCREEN && (
                        <UserConfirmationCanceled setUserConfirmationStep={setUserConfirmationStep} />
                    )}
                    {userConfirmationStep === CONFIRMATION_STEPS.ACCEPTED_SCREEN && <UserConfirmationSuccess />}
                </div>
            </div>
        </div>
    );
};

export default UserConfirmTransactionsPage;
