import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { HEADERS } from '../../components/util/constants';

export const surveysAPI = createApi({
    reducerPath: 'surveysAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api',
        prepareHeaders: HEADERS,
    }),
    tagTypes: ['surveysList'],
    endpoints: (build) => ({
        getSurveyInfo: build.query({
            query: (key) => `/surveys?key=${key}`,
        }),
        getSurveyOptions: build.query({
            query: (key) => `/surveys/options?key=${key}`,
        }),
        sendSurveyPersonalInformation: build.mutation({
            query: ({ key, body }) => ({
                url: `/surveys/personal-information?key=${key}`,
                method: 'POST',
                body,
            }),
        }),
        sendSurveyWorkingTimeDistributions: build.mutation({
            query: ({ key, body }) => ({
                url: `/surveys/working-time-distributions?key=${key}`,
                method: 'POST',
                body,
            }),
        }),
        sendSurveyResult: build.mutation({
            query: ({ key }) => ({
                url: `/surveys/send-results?key=${key}`,
                method: 'POST',
            }),
        }),
    }),
});

export const {
    useGetSurveyInfoQuery,
    useGetSurveyOptionsQuery,
    useSendSurveyPersonalInformationMutation,
    useSendSurveyWorkingTimeDistributionsMutation,
    useSendSurveyResultMutation,
} = surveysAPI;
