import React from 'react';
import { bool, string } from 'prop-types';

const ReadOnlyRadioItem = ({ isActive = false, isEnabled = true, label }) => (
    <div className="flex items-center">
        <div
            className={`w-[18px] h-[18px] rounded-[30px]  ${
                isActive ? 'border-[2px] bg-int-white-secondary' : 'border-[1px] border-int-gray-secondary'
            }`}
        />
        <div
            className={`ml-[12px] font-Lexend400 text-[14px] leading-[14px] ${
                isEnabled ? 'text-int-white-main' : 'text-int-gray-secondary'
            }`}
        >
            {label}
        </div>
    </div>
);

export default ReadOnlyRadioItem;

ReadOnlyRadioItem.propTypes = {
    isActive: bool,
    isEnabled: bool,
    label: string.isRequired,
};
