import React from 'react';
import { useTranslation } from 'react-i18next';
import { func, number, shape, string } from 'prop-types';
import { PrimaryButton } from '../../../style/Button/Button';
import { i18PAGES } from '../../../config/i18n/constants';
import { CONFIRMATION_STEPS } from '../constants';
import { useSendTransactionStatusAsWahlerMutation } from '../../../store/API/invoiceAPI';
import { useToast } from '../../../hoc/toast/ToastProvider';
import i18n from '../../../config/i18n/i18n';

const OwnerConfirmationChoice = ({ setOwnerConfirmationStep, tokenKey, invoiceData }) => {
    const { t } = useTranslation(i18PAGES.AUTH);
    const [sendRequest, { isLoading }] = useSendTransactionStatusAsWahlerMutation();
    const { errorToast } = useToast();

    const onGrantAccess = () => {
        sendRequest({
            key: tokenKey,
            status: 'CONFIRMED',
        })
            .unwrap()
            .then(() => {
                setOwnerConfirmationStep(CONFIRMATION_STEPS.ACCEPTED_SCREEN);
            })
            .catch(() => {
                errorToast(i18n.t('Something went wrong, try again later'));
            });
    };

    const onDeny = () => {
        sendRequest({
            key: tokenKey,
            status: 'DENY',
        })
            .unwrap()
            .then(() => {
                setOwnerConfirmationStep(CONFIRMATION_STEPS.DECLINED_SCREEN);
            })
            .catch(() => {
                errorToast(i18n.t('Something went wrong, try again later'));
            });
    };

    return (
        <>
            <div className="text-int-white-main font-Lexend500 text-[24px] leading-[24px] text-center">
                {t('Platform access')}
            </div>
            <div className="mt-[16px] w-[440px] text-center text-int-white-main font-Lexend300 text-[14px] leading-[22px]">
                {t('Please confirm the transaction from')} {invoiceData.userFullName} {invoiceData.userEmail}
                {` ${t('on Subscription')} `}
                {invoiceData.invoiceNumber} {t('on amount of')} {invoiceData.invoiceTotalAmount} EUR
            </div>

            <div className="mt-[50px] flex items-center justify-between">
                <PrimaryButton disabled={isLoading} className="w-[48%] h-[50px]" isGrey handleClick={onDeny}>
                    {t('Deny')}
                </PrimaryButton>
                <PrimaryButton disabled={isLoading} className="w-[48%] h-[50px]" isPink handleClick={onGrantAccess}>
                    {t('Grant access')}
                </PrimaryButton>
            </div>
        </>
    );
};

export default OwnerConfirmationChoice;

OwnerConfirmationChoice.propTypes = {
    setOwnerConfirmationStep: func.isRequired,
    invoiceData: shape({
        invoiceNumber: number.isRequired,
        invoiceTotalAmount: number.isRequired,
        userEmail: string.isRequired,
        userFullName: string.isRequired,
    }).isRequired,
    tokenKey: string,
};
