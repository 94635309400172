import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isShowFullMenu: true,
};

const isShowFullMenuSlice = createSlice({
    name: 'show-full-menu',
    initialState,
    reducers: {
        setShowFullMenu(state) {
            state.isShowFullMenu = true;
        },
        setShowShortMenu(state) {
            state.isShowFullMenu = false;
        },
    },
});

export const { setShowFullMenu, setShowShortMenu } = isShowFullMenuSlice.actions;

export default isShowFullMenuSlice.reducer;
