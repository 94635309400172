import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { HEADERS, WORKERS_PAGINATION_PAGE_SIZE } from '../../components/util/constants';

export const workersInformationAPI = createApi({
    reducerPath: 'workersInformationAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api',
        prepareHeaders: HEADERS,
    }),
    tagTypes: ['workersInformation'],
    endpoints: (build) => ({
        getWorkersInformation: build.query({
            query: ({ projectId, page = 0, sortBy, order }) =>
                `/projects/${projectId}/worker-details?size=${WORKERS_PAGINATION_PAGE_SIZE}&page=${page}&sort=${
                    sortBy || 'createdDate'
                },${order || 'DESC'}`,
            keepUnusedDataFor: 0.0001,
            providesTags: (result) =>
                result?.content
                    ? [
                          ...result.content.map(({ id }) => ({ type: 'workersInformation', id })),
                          { type: 'workersInformation', id: 'LIST' },
                      ]
                    : [{ type: 'workersInformation', id: 'LIST' }],
        }),
        createWorkersInformation: build.mutation({
            query: ({ projectId, body }) => ({
                url: `/projects/${projectId}/worker-details/`,
                method: 'POST',
                body,
            }),
            invalidatesTags: [{ type: 'workersInformation', id: 'LIST' }],
        }),
        editWorkersInformation: build.mutation({
            query: ({ projectId, workerDetailsId, body }) => ({
                url: `/projects/${projectId}/worker-details/${workerDetailsId}`,
                method: 'PUT',
                body,
            }),
            invalidatesTags: [{ type: 'workersInformation', id: 'LIST' }],
        }),
        deleteWorkersInformation: build.mutation({
            query: ({ projectId, workerDetailsId }) => ({
                url: `/projects/${projectId}/worker-details/${workerDetailsId}`,
                method: 'DELETE',
            }),
            invalidatesTags: [{ type: 'workersInformation', id: 'LIST' }],
        }),
        resendWorkerById: build.mutation({
            query: ({ projectId, workerDetailsId }) => ({
                url: `/projects/${projectId}/worker-details/${workerDetailsId}/resend-surveys`,
                method: 'POST',
            }),
            invalidatesTags: [{ type: 'workersInformation', id: 'LIST' }],
        }),
        sendSurveysAllWorker: build.mutation({
            query: ({ projectId }) => ({
                url: `/projects/${projectId}/worker-details/send-surveys`,
                method: 'POST',
            }),
            invalidatesTags: [{ type: 'workersInformation', id: 'LIST' }],
        }),
        getProgressWorker: build.query({
            query: ({ projectId }) => ({
                url: `/projects/${projectId}/worker-details/progress`,
                keepUnusedDataFor: 0.0001,
            }),
        }),
    }),
});

export const {
    useGetWorkersInformationQuery,
    useCreateWorkersInformationMutation,
    useEditWorkersInformationMutation,
    useDeleteWorkersInformationMutation,
    useResendWorkerByIdMutation,
    useSendSurveysAllWorkerMutation,
    useGetProgressWorkerQuery,
} = workersInformationAPI;
