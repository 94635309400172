import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { HEADERS } from '../../components/util/constants';

export const createProjectAPI = createApi({
    reducerPath: 'createProjectAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api',
        prepareHeaders: HEADERS,
    }),

    tagTypes: ['projectThree'],
    endpoints: (build) => ({
        getChosenProject: build.query({
            query: (projectId) => `/projects/${projectId}`,
        }),
        getAllProvidersSubmitted: build.query({
            query: (projectId) => `/projects/${projectId}/all-providers-submitted`,
        }),
        getSentBuildingBlocks: build.query({
            query: (projectId) => `/projects/${projectId}/sent-building-blocks`,
        }),
        createProject: build.mutation({
            query: (body) => ({
                url: '/projects',
                method: 'POST',
                body,
            }),
        }),
        linkCompaniesProject: build.mutation({
            query: (body) => ({
                url: '/projects/link-companies',
                method: 'POST',
                body,
            }),
        }),
        linkOfficesProject: build.mutation({
            query: (body) => ({
                url: '/projects/link-offices',
                method: 'POST',
                body,
            }),
        }),
        editProject: build.mutation({
            query: (body) => ({
                url: '/projects',
                method: 'PUT',
                body,
            }),
        }),
        editLinkCompaniesProject: build.mutation({
            query: (body) => ({
                url: '/projects/link-companies',
                method: 'PUT',
                body,
            }),
        }),
        editLinkOfficesProject: build.mutation({
            query: (body) => ({
                url: '/projects/link-offices',
                method: 'PUT',
                body,
            }),
        }),
    }),
});

export const {
    useCreateProjectMutation,
    useGetAllProvidersSubmittedQuery,
    useGetSentBuildingBlocksQuery,
    useLinkCompaniesProjectMutation,
    useLinkOfficesProjectMutation,
    useEditProjectMutation,
    useEditLinkCompaniesProjectMutation,
    useEditLinkOfficesProjectMutation,
    useGetChosenProjectQuery,
} = createProjectAPI;
