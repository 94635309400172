import React from 'react';
import { arrayOf, shape, string } from 'prop-types';
import HrStrategyTableLabels from '../HrStrategyTableLabels';
import HrStrategyReadItem from './HrStrategyReadItem';
import { hrStrategiesEmptyObject } from '../../Common/constants';

const HrStrategyReadMode = ({ hrStrategies }) => (
    <div className="bg-int-gray-main p-[28px] pr-[56px] rounded-[8px]">
        <HrStrategyTableLabels />
        {hrStrategies?.length > 0 && hrStrategies?.map((p, index) => <HrStrategyReadItem key={index} hrStrategy={p} />)}
        {hrStrategies?.length === 0 && <HrStrategyReadItem hrStrategy={hrStrategiesEmptyObject} />}
    </div>
);

export default HrStrategyReadMode;

HrStrategyReadMode.propTypes = {
    hrStrategies: arrayOf(
        shape({
            name: string.isRequired,
            strategyPoints: string.isRequired,
        }).isRequired
    ),
};
