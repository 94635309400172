import React, { useEffect, useMemo, useState } from 'react';
import { arrayOf, func, number, oneOfType, shape, string } from 'prop-types';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import AccordionItem from '../../../style/AccordionItem/AccordionItem';
import { step4ValidationSchema } from '../stepValidation';
import OfficesFieldArray from './OfficesFieldArray';
import { useCompanySetup } from '../../../store/hooks/useCompanySetup';
import { useCreateOfficesMutation } from '../../../store/API/companiesAPI';
import http from '../../util/http';
import { i18PAGES } from '../../../config/i18n/constants';
import { useToast } from '../../../hoc/toast/ToastProvider';

const SetupOfficeItem = ({ officeItem, index, checkAsReady }) => {
    const { t } = useTranslation(i18PAGES.SETUP_COMPANIES);

    const [createOffices] = useCreateOfficesMutation();
    const { companySetupInfo, setOffices, setCompanyTree } = useCompanySetup();
    const { errorToast } = useToast();

    const [currentTab, setCurrentTab] = useState(0);
    const [isTabReady, setIsTabReady] = useState(false);

    const handleTabChoose = (tabIndex) => {
        setCurrentTab(tabIndex);
    };

    const onNextButtonPress = () => {
        setCurrentTab((prev) => prev + 1);
    };

    const isAllOfficesFilled = useMemo(() => {
        const readyOffices = [];
        officeItem.offices.forEach((o) => {
            if (o?.id && !readyOffices.includes(o?.id)) {
                readyOffices.push(o.id);
            }
        });
        return readyOffices.length === officeItem.offices.length;
    }, [officeItem.offices]);

    useEffect(() => {
        if (isAllOfficesFilled === true) {
            setIsTabReady(true);
            checkAsReady(index);
        }
    }, [checkAsReady, index, isAllOfficesFilled]);

    return (
        <Formik
            onSubmit={async (values, { setSubmitting }) => {
                await createOffices({
                    cityOffices: [values.offices],
                })
                    .unwrap()
                    .then((data) => {
                        const cityIdArray = [];
                        companySetupInfo.officesInfo.forEach((item) => {
                            if (!cityIdArray.includes(item.cityId)) {
                                cityIdArray.push(item.cityId);
                            }
                        });
                        if (!cityIdArray.includes(data.cityOffices[0].cityId)) {
                            const offices = [...companySetupInfo.officesInfo, data.cityOffices[0]];
                            setOffices(offices);
                        } else {
                            const offices = [...companySetupInfo.officesInfo, data.cityOffices[0]];
                            setOffices(offices);
                        }

                        http.get(`/companies/${companySetupInfo.companyTree.id}`)
                            .then((res) => {
                                setCompanyTree(res.data);
                                setIsTabReady(true);
                            })
                            .catch((err) => {
                                errorToast(err.response.data.message);
                            })
                            .finally(() => {
                                setSubmitting(false);
                            });
                    })
                    .catch((err) => {
                        errorToast(err.data.message);
                    });
            }}
            validationSchema={step4ValidationSchema}
            initialValues={{
                offices: officeItem,
            }}
            enableReinitialize
        >
            {({ values, touched, errors, isSubmitting, handleSubmit, handleBlur, handleChange, setFieldValue }) => (
                <Form onSubmit={handleSubmit}>
                    <AccordionItem
                        isOpenedTab={index === 0}
                        officesCount={officeItem.offices.length}
                        cityName={officeItem.cityName}
                        countryName={officeItem.countryName}
                        isTabReady={isTabReady}
                    >
                        <div className="p-[30px] min-h-[440px]">
                            <div className="flex font-Lexend400 text-[16px] leading-[18px]">
                                {officeItem.offices.map((_, _index) => (
                                    <div
                                        key={_index}
                                        onClick={() => handleTabChoose(_index)}
                                        className={`px-[16px] cursor-pointer ${
                                            _index === currentTab
                                                ? 'text-int-white-main border-b-[3px] border-int-white-main pb-[19px] rounded-[3px]'
                                                : 'text-int-white-secondary'
                                        }`}
                                    >
                                        {t('Office')} {_index + 1}
                                    </div>
                                ))}
                            </div>
                            <div className="mt-[-2px] m-auto bg-int-gray-secondary h-[1px] w-[100%]" />
                            <OfficesFieldArray
                                isLastTab={currentTab === officeItem.offices.length - 1}
                                handleChange={handleChange}
                                values={values}
                                errors={errors}
                                handleBlur={handleBlur}
                                touched={touched}
                                currentTab={currentTab}
                                isSubmitting={isSubmitting}
                                onNextButtonPress={onNextButtonPress}
                                setFieldValue={setFieldValue}
                            />
                        </div>
                    </AccordionItem>
                </Form>
            )}
        </Formik>
    );
};

export default SetupOfficeItem;

SetupOfficeItem.propTypes = {
    officeItem: shape({
        cityName: string.isRequired,
        countryName: string.isRequired,
        cityId: oneOfType([string, number]),
        offices: arrayOf(
            shape({
                name: string.isRequired,
                address: string.isRequired,
                employeeQuantityTotal: oneOfType([string, number]).isRequired,
                yearFounded: oneOfType([string, number]).isRequired,
                employeeQuantityWc: oneOfType([string, number]).isRequired,
                employeeQuantityBc: oneOfType([string, number]).isRequired,
            })
        ),
    }).isRequired,
    index: number.isRequired,
    checkAsReady: func,
};
