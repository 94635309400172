import React from 'react';
import { useTranslation } from 'react-i18next';
import { number } from 'prop-types';
import { i18PAGES } from '../../../config/i18n/constants';

const FteResultsBlock = ({ completed, all }) => {
    const { t } = useTranslation(i18PAGES.FTE_PROCESS);

    if (completed === all) return null;

    return (
        <div className="h-[58px] mb-[30px] flex items-center px-[16px] bg-[#2D3963] rounded-[8px] inline-flex">
            {t('Results are based on')} {completed}/{all} {t('responses, and are not complete')}
        </div>
    );
};

export default FteResultsBlock;

FteResultsBlock.propTypes = {
    completed: number.isRequired,
    all: number.isRequired,
};
