import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CompanyPointIcon } from '../../assets/icons/companyPointIcon.svg';
import AddEntityBlock from './AddEntityBlock';
import { i18PAGES } from '../../config/i18n/constants';

const EmptyCompanyShows = () => {
    const { t } = useTranslation(i18PAGES.COMPANIES_LIST);

    const navigate = useNavigate();

    return (
        <div className="w-[1019px] m-auto">
            <div className="text-[24px] text-int-white-main font-Lexend500 mt-[30px] mb-[24px]">
                {t('List of companies')}
            </div>
            <AddEntityBlock
                buttonText="Setup company"
                description="Create and add company information"
                icon={<CompanyPointIcon />}
                handleClick={() => navigate('/setup-companies')}
            />
        </div>
    );
};

export default EmptyCompanyShows;
