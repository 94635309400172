import React from 'react';
import { string, func } from 'prop-types';
import { ReactComponent as CloseToastIcon } from '../../../../../assets/icons/closeToastIcon.svg';

const FteSelectedFilterItem = ({ title, handleClick }) => (
    <div className="flex items-center text-[14px] leading-[24px] font-Lexend400 h-[24px] mr-[20px] my-[10px]">
        <div>{title}</div>
        <div
            className="w-[18px] w-[18px] flex items-center justify-center ml-[8px] cursor-pointer"
            onClick={handleClick}
        >
            <CloseToastIcon />
        </div>
    </div>
);

export default FteSelectedFilterItem;

FteSelectedFilterItem.propTypes = {
    title: string,
    handleClick: func,
};
