import React from 'react';
import { FieldArray } from 'formik';
import { func, object } from 'prop-types';
import ProblemsOverviewEditItem from './ProblemsOverviewEditItem';
import ProblemsOverviewTableLabels from '../ProblemsOverviewTableLabels';
import ModulesStrategyAddLineButton from '../../Common/Modules&StrategyAddLineButton';
import { problemsOverviewEmptyObject } from '../../Common/constants';

const ProblemsOverviewEditMode = ({ values, errors, touched, handleBlur, handleChange, setFieldValue }) => (
    <div className="bg-int-gray-main p-[28px] pr-[56px] rounded-[8px]">
        <ProblemsOverviewTableLabels />
        <FieldArray
            name="problems"
            render={(ArrayHelpers) => (
                <>
                    {values.problems?.map((p, index) => {
                        const problemsErrors = (errors.problems?.length && errors.problems[index]) || {};

                        const problemsTouched = (touched.problems?.length && touched.problems[index]) || {};

                        return (
                            <ProblemsOverviewEditItem
                                key={index}
                                problemsTouched={problemsTouched}
                                problemsErrors={problemsErrors}
                                handleBlur={handleBlur}
                                handleChange={handleChange}
                                ArrayHelpers={ArrayHelpers}
                                values={values}
                                index={index}
                                setFieldValue={setFieldValue}
                            />
                        );
                    })}

                    <ModulesStrategyAddLineButton
                        pushObject={problemsOverviewEmptyObject}
                        ArrayHelpers={ArrayHelpers}
                    />
                </>
            )}
        />
    </div>
);

export default ProblemsOverviewEditMode;

ProblemsOverviewEditMode.propTypes = {
    values: object.isRequired,
    errors: object.isRequired,
    touched: object.isRequired,
    handleBlur: func.isRequired,
    handleChange: func.isRequired,
    setFieldValue: func.isRequired,
};
