import React from 'react';
import { shape, string } from 'prop-types';
import { OnlyViewFieldTextArea } from '../../../../Profile/OnlyViewFieldInput';

const ActionsReadItem = ({ action }) => (
    <div className="flex mb-[16px]">
        <div className="w-[370px] mr-[10px]">
            <OnlyViewFieldTextArea styles="h-[84px]" label="" value={action.action || ''} />
        </div>
        <div className="w-[990px]">
            <OnlyViewFieldTextArea styles="h-[84px]" label="" value={action.description || ''} />
        </div>
    </div>
);

export default ActionsReadItem;

ActionsReadItem.propTypes = {
    action: shape({
        action: string.isRequired,
        description: string.isRequired,
    }),
};
