import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { bool, func } from 'prop-types';
import { i18PAGES } from '../../../config/i18n/constants';
import { ReactComponent as FteTableIcon } from '../../../assets/icons/fteTableIcon.svg';
import { ReactComponent as FteGraphIcon } from '../../../assets/icons/fteGraphIcon.svg';

const FteToggleSwitcher = ({ isTableView, setIsTableView }) => {
    const { t } = useTranslation(i18PAGES.FTE_PROCESS);

    const onToggle = useCallback(() => {
        setIsTableView((prev) => !prev);
    }, []);

    return (
        <div
            className="h-[48px] bg-[#2A3143] flex items-center rounded-full px-[6px] text-[16px] leading-[26px] font-Lexend500 cursor-pointer"
            onClick={onToggle}
        >
            <div
                className={`h-[36px] w-[110px] ${
                    isTableView ? 'bg-[#354153]' : 'bg-[#2A3143] '
                } rounded-full px-[22px] flex items-center justify-center`}
            >
                <div className="mr-[6px]">
                    <FteTableIcon width={17} height={16} fill="#EDF1FD" />
                </div>
                <div>{t('Table')}</div>
            </div>
            <div
                className={`h-[36px] w-[110px] ${
                    !isTableView ? 'bg-[#354153]' : 'bg-[#2A3143] '
                } rounded-full px-[22px] flex items-center justify-center`}
            >
                <div className="mr-[6px]">
                    <FteGraphIcon width={17} height={16} fill="#EDF1FD" />
                </div>
                <div>{t('Graph')}</div>
            </div>
        </div>
    );
};

export default FteToggleSwitcher;

FteToggleSwitcher.propTypes = {
    isTableView: bool.isRequired,
    setIsTableView: func.isRequired,
};
