import React from 'react';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import ModalWindow from '../../../style/ModalWindow/ModalWindow';
import { i18PAGES } from '../../../config/i18n/constants';
import { useToast } from '../../../hoc/toast/ToastProvider';
import FInput from '../../../style/Input/Input';
import { PrimaryButton } from '../../../style/Button/Button';
import { workersEditValidationSchema } from './workersValidation';
import { useEditWorkersInformationMutation } from '../../../store/API/workersInformationAPI';

const EditWorkersModal = ({
    isOpenEditWorkersModal,
    setIsOpenEditWorkersModal,
    id,
    fullName,
    role,
    email,
    projectId,
}) => {
    const { t } = useTranslation(i18PAGES.WORKERS_EFFICIENCY);
    const { successToast, errorToast } = useToast();

    const [editWorkers] = useEditWorkersInformationMutation();

    return (
        <ModalWindow
            isOpen={isOpenEditWorkersModal}
            setIsOpen={setIsOpenEditWorkersModal}
            isCloseClickOutside
            className="-top-[130px] w-[1064px]"
        >
            <div className="flex flex-col items-center">
                <div className="text-[24px] leading-[24px] font-Lexend500 text-int-white-main">{t('Edit worker')}</div>
                <Formik
                    onSubmit={(values, { setSubmitting }) => {
                        editWorkers({
                            projectId,
                            workerDetailsId: id,
                            body: values,
                        })
                            .unwrap()
                            .then(() => {
                                successToast(t('Workers was successfully edited'));
                            })
                            .catch(() => {
                                errorToast(t('Error workers edit'));
                            })
                            .finally(() => {
                                setIsOpenEditWorkersModal(false);
                                setSubmitting(setSubmitting);
                            });
                    }}
                    validationSchema={workersEditValidationSchema}
                    initialValues={{ fullName, email, role }}
                    enableReinitialize
                >
                    {({
                        values,
                        touched,
                        errors,
                        handleSubmit,
                        handleBlur,
                        handleChange,
                        isSubmitting,
                        setFieldValue,
                    }) => (
                        <Form className="w-[100%] mt-[40px]">
                            <div>
                                <div className="flex justify-between items-center">
                                    <FInput
                                        name="fullName"
                                        placeholder="Type"
                                        value={values.fullName}
                                        handleBlur={handleBlur}
                                        handleChange={handleChange}
                                        className="min-w-[320px]"
                                        type="text"
                                        label="Name"
                                        isError={!!(touched.fullName && errors.fullName)}
                                        errorText={errors.fullName}
                                        setFieldValue={setFieldValue}
                                        limit={50}
                                    />
                                    <FInput
                                        name="role"
                                        placeholder="Type"
                                        value={values.role}
                                        handleBlur={handleBlur}
                                        handleChange={handleChange}
                                        className="min-w-[320px]"
                                        type="text"
                                        label="Role"
                                        isError={!!(touched.role && errors.role)}
                                        errorText={errors.role}
                                        setFieldValue={setFieldValue}
                                        limit={50}
                                    />
                                    <FInput
                                        name="email"
                                        placeholder="Type"
                                        value={values.email}
                                        handleBlur={handleBlur}
                                        handleChange={handleChange}
                                        className="min-w-[320px]"
                                        type="text"
                                        label="Email"
                                        isError={!!(touched.email && errors.email)}
                                        errorText={errors.email}
                                        setFieldValue={setFieldValue}
                                    />
                                </div>
                            </div>
                            <div className="w-[100%] flex justify-between mt-[30px]">
                                <PrimaryButton
                                    isGrey
                                    className="w-[170px] h-[50px]"
                                    handleClick={() => setIsOpenEditWorkersModal(false)}
                                >
                                    <div className="text-[18px] font-Lexend400">{t('Cancel')}</div>
                                </PrimaryButton>
                                <PrimaryButton
                                    isPink
                                    className="w-[170px] h-[50px]"
                                    handleClick={handleSubmit}
                                    disabled={isSubmitting}
                                >
                                    <div className="text-[18px] font-Lexend400">{t('Save changes')}</div>
                                </PrimaryButton>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </ModalWindow>
    );
};

EditWorkersModal.propTypes = {
    isOpenEditWorkersModal: PropTypes.bool.isRequired,
    setIsOpenEditWorkersModal: PropTypes.func.isRequired,
    id: PropTypes.number,
    fullName: PropTypes.string,
    role: PropTypes.string,
    email: PropTypes.string,
    projectId: PropTypes.string.isRequired,
};

export default EditWorkersModal;
